import moment from 'moment';
import React from 'react';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CalendarCustom from '@components/fw/Calendar/CalendarCustom';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';

import { transportRegisterStore } from '@stores/transportRegisterStore';

import { TransportRegisterHeaderProps } from './types/header-props';

export const TransportRegisterHeader = ({ dataSource }: TransportRegisterHeaderProps) => {
  const onDatesChange = (event: [moment.Moment, moment.Moment]) => {
    transportRegisterStore.setReportRange(event);
    dataSource.reload();
  };

  return (
    <AdaptivePageHeader
      extra={<CollapsedToolbar content={[<CalendarCustom onChange={onDatesChange} values={transportRegisterStore.reportRange} />]} />}
      title={'Реестр перевозок'}
    />
  );
};
