import { Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

import NumberFilterForm from './NumberFilterForm';

interface Props {
  primaryKey: string;
  store: any;
  onChange(numbers: any[]): void;
}

interface State {}

class NumberFilterModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  form: NumberFilterForm;

  render() {
    const visible = this.props.store.numbersFilterModalVisible;
    return (
      <Modal
        centered={designStore.isMobile}
        width={designStore.isMobile ? '100%' : 800}
        title={'Укажите номера'}
        open={visible}
        destroyOnClose={true}
        //cancelText={'закрыть'}
        //okButtonProps={{htmlType:"submit", form:"numbers_form"}}
        onOk={() => {
          this.form.submit();
        }}
        onCancel={() => {
          this.props.store.numbersFilterModalVisible = false;
        }}>
        <NumberFilterForm
          ref={(ref) => (this.form = ref)}
          store={this.props.store}
          onChange={this.props.onChange}
          primaryKey={this.props.primaryKey}
        />
      </Modal>
    );
  }
}
export default observer(NumberFilterModal);
