import { Drawer, DrawerProps } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

class AdaptiveDrawer extends Component<DrawerProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const isMobile = designStore.isMobile;
    let footer;
    if (Array.isArray(this.props.footer)) {
      footer = (
        <div style={{ textAlign: 'right' }}>
          {this.props.footer.map((f, index) => (
            <span style={{ marginLeft: 10 }} key={index}>
              {f}
            </span>
          ))}
        </div>
      );
    } else if (this.props.footer) {
      footer = <div style={{ textAlign: 'right' }}>{this.props.footer}</div>;
    }

    //let bodyStyle = this.props.bodyStyle;
    //if (!bodyStyle) bodyStyle = {};
    //if (isMobile && !bodyStyle.padding) {
    //bodyStyle.padding = 4;
    //}

    return (
      <Drawer
        {...this.props}
        //bodyStyle={bodyStyle}
        width={isMobile ? '100%' : this.props.width}
        footer={footer}>
        {this.props.children}
      </Drawer>
    );
  }
}

export default observer(AdaptiveDrawer);
