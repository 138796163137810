import { DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import designStore from '@stores/designStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { VersionContentInfo } from '@stores/systemInfoStore/systemInfoData';
import systemInfoStore from '@stores/systemInfoStore/systemInfoStore';
import userStore from '@stores/userStore/userStore';

import VersionEditor from './VersionEditor';

interface State {
  edit: boolean;
  version: VersionContentInfo;
}

@observer
class AboutPage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      edit: false,
      version: null,
    };
  }

  async componentDidMount() {
    await systemInfoStore.getVersionList();
    document.title = 'Список версий';
  }

  async handleRemoveConfirm(item: VersionContentInfo) {
    await systemInfoStore.delete(item.version);
    await systemInfoStore.getVersionList();
  }

  render() {
    const isBusy = systemInfoStore.isBusy;
    const items = systemInfoStore.items;
    const canEdit = userStore.userData.roleCode === 'admin';
    return (
      <ScrolledContainer className={'scrolledContainer'} style={{ height: 'calc(100vh - var(--header-height))' }}>
        <Spin spinning={isBusy}>
          <AdaptiveCard
            extra={
              designStore.logoType !== 'ILS' && (
                <Button
                  type={'link'}
                  icon={<FilePdfOutlined />}
                  onClick={() => {
                    window.open(
                      'https://docs.google.com/document/d/1jT6Kj6YT26pWVVBHrjRiZtfO-_SQXvPcUffricU9mDk/export?format=pdf',
                      '_blank',
                    );
                  }}>
                  Руководство пользователя (35MБ)
                </Button>
              )
            }>
            <Tabs
              style={{
                lineHeight: 3,
                margin: '0',
              }}
              tabPosition={'left'}>
              {items.map((item) => {
                return (
                  <Tabs.TabPane tab={item.version} key={item.version} style={{ margin: '0' }}>
                    {canEdit && (
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <VersionEditor />
                        <Popconfirm title="Удалить？" okText="Да" cancelText="Нет" onConfirm={() => this.handleRemoveConfirm(item)}>
                          <Button icon={<DeleteOutlined />} title={'Удалить'} size={'small'} type={'text'}>
                            Удалить
                          </Button>
                        </Popconfirm>
                        <VersionEditor version={item} />
                      </Row>
                    )}
                    <div>
                      <b>{moment(item.updateDate).format(GLOBAL_DATE_FORMAT)}</b>
                    </div>
                    <pre>{item.content}</pre>
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </AdaptiveCard>
        </Spin>
      </ScrolledContainer>
    );
  }
}

export default AboutPage;
