import * as http from 'node:http';

import HttpClient from '@utils/httpClient';
import httpClient from '@utils/httpClient';

import { TariffRequestModel } from '../historyTariffInfo/historyTariffInfoStore';
import { TemplateOrgExpeditionExtParam } from '../organizationsStore/organizationsStoreData';
import { TariffCalcResponse, TariffProducerRequest, TariffProducerResponse, TariffTemplate } from './optionsStoreData';

const optionsService = {
  getDictionary() {
    return HttpClient.get(`/api/Dictionary/dictionary`).then((resp) => {
      return resp.data;
    });
  },

  getTariffDictionary() {
    return HttpClient.get(`/api/Dictionary/tariffDictionary`).then((resp) => {
      return resp.data;
    });
  },

  checkTariff(data: TariffRequestModel): Promise<TariffCalcResponse> {
    return HttpClient.post(`/api/Tariff/calculatetariff`, data).then((resp) => {
      return resp.data;
    });
  },

  async getReport(month: number, year: number, progressFunc: ((e: ProgressEvent) => void) | undefined = undefined): Promise<Blob> {
    return (
      await HttpClient.get(`/api/tariff/report/total/download`, {
        params: { month, year },
        onDownloadProgress: progressFunc,
        responseType: 'blob',
      })
    ).data;
  },

  tariffProducer(data: { loadStationCode: string; etsngCode: string }): Promise<TariffProducerResponse[]> {
    return HttpClient.post(`/api/tariffProducer/calc`, data).then((resp) => {
      return resp.data;
    });
  },
  tariffProducerExt(data: TariffProducerRequest): Promise<TariffProducerResponse[]> {
    return HttpClient.post(`/api/tariffProducer/ext-calc`, data).then((resp) => {
      return resp.data;
    });
  },

  tariffExpeditorSettings(companyId: string, data: TariffRequestModel): Promise<TemplateOrgExpeditionExtParam[]> {
    return HttpClient.post(`/api/tariff/tariff-expeditor-settings/${companyId}`, data).then((resp) => {
      return resp.data;
    });
  },

  getTemplates() {
    return httpClient.get<TariffTemplate[]>('/api/tariff/template').then(({ data }) => data);
  },

  createTemplate(body: TariffTemplate) {
    return httpClient.post('/api/tariff/template', body);
  },

  updateTemplate(body: TariffTemplate) {
    return httpClient.put('/api/tariff/template', body);
  },

  deleteTemplate(templateId: string) {
    return HttpClient.delete(`/api/tariff/template/${templateId}`);
  },
};

export default optionsService;
