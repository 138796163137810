import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, MoreOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import PdfViewModal from '@components/fw/DocumentViewer/PdfViewModal';
import EcpModal from '@components/routed/EcpPage/EcpModal';

import designStore from '@stores/designStore/designStore';
import organizationsStoreService from '@stores/organizationsStore/organizationsStore.service';
import { TemplateOrgContractModel } from '@stores/organizationsStore/organizationsStoreData';

import { showSuccessNotify } from '@utils/notify';

import ContractEditor from './ContractEditor';

interface ComponentProps {
  id: string;
  type: string;
  organizationId: string;
  contractTemplateList: any;
  extTemplateList: any;
  getData(): void;
  contract?: TemplateOrgContractModel;
  hasSigners: boolean;
}

interface ComponentState {
  showEditor: boolean;
  loadingDoc: boolean;
  showExtEditor: boolean;
  showAttachmentView: boolean;
  loadingDelete: boolean;
  attachment: Blob;
  loadingAttachment: boolean;
  signVisible: boolean;
}

@observer
class ContractActionMenu extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      showEditor: false,
      loadingDoc: false,
      showExtEditor: false,
      loadingDelete: false,
      attachment: null,
      loadingAttachment: false,
      showAttachmentView: false,
      signVisible: false,
    };
  }

  onGetData = () => {
    this.setState({ showEditor: false, showExtEditor: false });
    this.props.getData();
  };

  onDownload = (e) => {
    e.domEvent.stopPropagation();
    this.setState({ loadingDoc: true });
    organizationsStoreService
      .contractGetFile(this.props.id, this.props.type)
      .then((doc) => {
        if (doc && doc.size) {
          let mockEvent = document.createEvent('MouseEvents');
          let mockElement = document.createElement('a');
          let type = this.props.type == 'contract' ? 'Договор ' : 'Доп.соглашение ';
          mockElement.download = type + '_' + this.props.id + '.pdf';
          mockElement.href = window.URL.createObjectURL(new Blob([doc]));
          mockElement.dataset.downloadurl = ['blob', mockElement.download, mockElement.href].join(':');
          mockEvent.initEvent('click');
          mockElement.dispatchEvent(mockEvent);
        }
      })
      .finally(() => this.setState({ loadingDoc: false }));
  };

  onDelete = (e) => {
    e.domEvent.stopPropagation();
    this.setState({ loadingDelete: true });
    organizationsStoreService
      .deleteContract(this.props.id, this.props.type)
      .then(() => {
        this.props.getData();
      })
      .finally(() => this.setState({ loadingDelete: false }));
  };

  loadAttachment = () => {
    this.setState({ loadingAttachment: true });
    organizationsStoreService
      .getAttachment(this.props.id, this.props.type)
      .then((doc) => {
        if (doc && doc.size) {
          this.setState({ attachment: doc });
        }
      })
      .finally(() => this.setState({ loadingAttachment: false }));
  };

  onUploadAttachment = (e) => {
    e.domEvent.stopPropagation();
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, application/pdf';

    input.onchange = (e) => {
      const file = input.files[0];
      const fm = new FormData();
      fm.append('file', file);

      this.setState({ loadingAttachment: true });
      organizationsStoreService
        .updateAttachment(fm, this.props.id, this.props.type)
        .then((ans) => {
          showSuccessNotify('', 'Файл загружен');
          this.loadAttachment();
        })
        .finally(() => this.setState({ loadingAttachment: false }));
    };
    input.click();
  };

  onDeleteAttachment = (e) => {
    e.domEvent.stopPropagation();
    this.setState({ loadingAttachment: true });
    organizationsStoreService
      .deleteAttachment(this.props.id, this.props.type)
      .finally(() => this.setState({ loadingAttachment: false }))
      .then((result: any) => {
        showSuccessNotify('', 'Файл удален');
        this.setState({ attachment: null });
      });
  };

  onDownloadAttachment = (e) => {
    e.domEvent.stopPropagation();
    const url = window.URL.createObjectURL(this.state.attachment);
    const link = document.createElement('a');
    link.href = url;
    const type = this.state.attachment.type == 'image/jpeg' ? 'jpeg' : 'pdf';
    link.setAttribute('download', `${this.props.id}.${type}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  onExtEditor = (e) => {
    e.domEvent.stopPropagation();
    this.setState({ showExtEditor: true });
  };

  onEditor = (e) => {
    e.domEvent.stopPropagation();
    this.setState({ showEditor: true });
  };

  onAttachmentView = (e) => {
    e.domEvent.stopPropagation();
    this.setState({ showAttachmentView: true });
  };

  onSign = (e) => {
    e.domEvent.stopPropagation();
    this.setState({ signVisible: true });
  };

  onAttachmentViewClose = (e) => {
    if (e.domEvent) e.domEvent.stopPropagation();
    if (e.stopPropagation) e.stopPropagation();
    this.setState({ showAttachmentView: false });
  };

  render() {
    const isContract = this.props.type == 'contract';
    const attachment = this.state.attachment;
    const attType = attachment && attachment.type.includes('image') ? 'jpeg' : 'pdf';
    return (
      <>
        {this.state.showEditor && (
          <ContractEditor
            type={this.props.type}
            contract={this.props.contract}
            templateList={isContract ? this.props.contractTemplateList : this.props.extTemplateList}
            organizationId={this.props.organizationId}
            id={this.props.id}
            onClose={() => this.setState({ showEditor: false })}
            onFinish={this.onGetData}
          />
        )}
        {this.state.showExtEditor && (
          <ContractEditor
            type={'ext'}
            contract={this.props.contract}
            templateList={this.props.extTemplateList}
            organizationId={this.props.organizationId}
            id={null}
            onClose={() => this.setState({ showExtEditor: false })}
            onFinish={this.onGetData}
          />
        )}
        {this.state.showAttachmentView && (
          <PdfViewModal
            data={this.state.attachment}
            type={attType}
            visible={this.state.showAttachmentView}
            onClose={this.onAttachmentViewClose}
          />
        )}
        {this.state.signVisible && (
          <EcpModal
            documentId={this.props.id}
            documentName={this.props.contract.name}
            onOk={() => {
              this.setState({ signVisible: false });
              this.props.getData();
            }}
            onCancel={() => this.setState({ signVisible: false })}
          />
        )}
        <Dropdown
          onVisibleChange={(e) => {
            if (e) this.loadAttachment();
          }}
          overlay={
            <Menu>
              <Menu.Item key="1" disabled={this.props.hasSigners} icon={<EditOutlined />} onClick={this.onEditor}>
                Изменить
              </Menu.Item>
              <Menu.Item key="3" icon={<DownloadOutlined />} onClick={this.onDownload}>
                Скачать
              </Menu.Item>
              {isContract && (
                <Menu.Item key="4" icon={<PlusOutlined />} onClick={this.onExtEditor}>
                  Добавить доп. соглашение
                </Menu.Item>
              )}
              {!designStore.isMobile && (
                <Menu.Item
                  key="sign"
                  title={''}
                  //icon={<EditOutlined />}
                  onClick={this.onSign}>
                  Подписать ЭЦП
                </Menu.Item>
              )}
              <Menu.Item key="5" icon={<DeleteOutlined />} onClick={this.onDelete}>
                Удалить
              </Menu.Item>
              <Menu.SubMenu title={'Вложение'}>
                <Menu.Item key="1" icon={<DownloadOutlined />} onClick={this.onDownloadAttachment} disabled={!attachment}>
                  Скачать
                </Menu.Item>
                <Menu.Item key="2" icon={<EyeOutlined />} onClick={this.onAttachmentView} disabled={!attachment}>
                  Посмотреть
                </Menu.Item>
                <Menu.Item key="3" disabled={!!attachment} icon={<UploadOutlined />} onClick={this.onUploadAttachment}>
                  Загрузить
                </Menu.Item>

                <Menu.Item key="5" icon={<DeleteOutlined />} onClick={this.onDeleteAttachment} disabled={!attachment}>
                  Удалить
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          }>
          <Button type={'text'} onClick={(e) => e.stopPropagation()} icon={<MoreOutlined />} />
        </Dropdown>
      </>
    );
  }
}

export default ContractActionMenu;
