import { Row } from 'antd';
import DataGrid, { Column, FilterRow, RowDragging } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import FieldElement from '@components/routed/DispatcherPage/FieldElement';

import { CustomReportDetailsModel, InfoBlockItemFields } from '@stores/dispatcherStore/DispatcherData';

interface DraggableListProps {
  dataSource: InfoBlockItemFields[];

  onRemove(field): void;

  onChange(): void;

  editedReport?: CustomReportDetailsModel | null;
}

interface DraggableListState {
  search: string | null;
}

class DraggableList extends Component<DraggableListProps, DraggableListState> {
  constructor(props) {
    super(props);
    this.onReorder = this.onReorder.bind(this);

    this.state = {
      search: null,
    };
  }

  dragArea: DataGrid;

  onReorder(e) {
    const dataSource = this.props.dataSource;
    let visibleRows = e.component.getVisibleRows(),
      toIndex = dataSource.indexOf(visibleRows[e.toIndex].data),
      fromIndex = dataSource.indexOf(e.itemData);

    dataSource.splice(fromIndex, 1);
    dataSource.splice(toIndex, 0, e.itemData);

    e.component.refresh();
    this.props.onChange();
  }

  renderData = (field) => {
    const data = field.data;
    const editedReport = this.props.editedReport;
    return (
      <Row key={data.id} style={{ margin: '0' }}>
        <FieldElement editedReport={editedReport} dataSource={data} onRemove={() => this.handleRemove(data)} key={data.id} />
      </Row>
    );
  };

  handleRemove(field) {
    this.props.onRemove(field);
  }

  componentWillReceiveProps(nextProps: Readonly<DraggableListProps>, nextContext: any) {
    this.dragArea.instance.refresh();
  }

  render() {
    const dataSource = this.props.dataSource; //.filter((f) => !search || !search || (f.description + f.abbreviation).toLowerCase().includes(search.toLowerCase()));
    const search = this.state.search;

    return (
      <>
        {/*<Search
          placeholder="поиск"
          onChange={(e) =>
            this.setState({
              search: e.target.value,
            })
          }
        />*/}
        <DataGrid
          rowAlternationEnabled={true}
          showRowLines={true}
          className={'dragableList'}
          showColumnHeaders={false}
          dataSource={dataSource}
          showBorders={true}
          paging={{ enabled: false, pageSize: 5000 }}
          ref={(ref) => (this.dragArea = ref)}>
          <RowDragging allowReordering={true} onReorder={this.onReorder} showDragIcons={false} />
          <FilterRow visible={true} />
          <Column allowFixing={true} dataField="description" caption={' '} cellRender={this.renderData} />
        </DataGrid>
      </>
    );
  }
}

export default observer(DraggableList);
