import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { animateScroll } from 'react-scroll';

import messengerStore from '@stores/messengerStore/messengerStore';
import userStore from '@stores/userStore/userStore';

import styles from './DialogContent.module.scss';
import Message from './Message';

@observer
class DialogContent extends Component {
  messageList: any;

  componentDidMount() {
    this.scrollToBottom2();
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
    this.scrollToBottom2();
  }

  scrollToBottom2() {
    animateScroll.scrollToBottom({
      containerId: 'messageList',
      smooth: 'easeInOutQuint',
      duration: 100,
    });
  }

  render() {
    if (!messengerStore.selectedDialog) return null;
    const messages = messengerStore.messages;
    const isBusyMessages = messengerStore.isBusyMessages;
    const assistant: boolean = messengerStore.selectedDialog.dialogType == 'Assist';
    const users = messengerStore.selectedDialog ? messengerStore.selectedDialog.users : [];

    const userId = userStore.userData.userId;
    return (
      <Spin spinning={isBusyMessages}>
        <div
          className={styles.message_list}
          ref={(div) => {
            this.messageList = div;
          }}>
          {messages.map((item, index) => {
            const user = users.find((x) => x.userId === item.userId);
            return (
              <Message
                key={item.messageId}
                message={item}
                author={assistant ? 'Виртуальный помощник' : user.name + (user.companyName ? ' (' + user.companyName + ')' : '')}
                isMy={userId === item.userId}
                time={item.createDate}
              />
            );
          })}

          {!isBusyMessages && messages.length === 0 && !assistant ? (
            <Message
              key={'test_message'}
              message={{ content: 'Напишите первым 😃', dialogId: null }}
              author={null}
              isMy={false}
              time={null}
            />
          ) : null}
        </div>
      </Spin>
    );
  }
}

export default DialogContent;
