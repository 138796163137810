import { Button, Calendar, Form, Input, InputNumber, Modal, ModalProps } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import BorderWrapper from '@components/fw/FloatLabel/BorderWrapper';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';
import RequiredPlaceholder from '@components/fw/RequiredPlaceholder';
import { RpsFeatureSelect, RpsModelSelect } from '@components/fw/RpsSelect';

import dictService from '@stores/dictStore/dictStore.service';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import requestIlsStore from '@stores/requestIlsStore/requestIlsStore';
import { ClaimReport, LoadPlan } from '@stores/requestIlsStore/requestIlsStoreData';

import HttpClient from '@utils/httpClient';

import './IlsClaimForm.less';

interface ComponentProps extends ModalProps {
  closeModal: () => void;
  value?: ClaimReport;
  editSendId?: null | string;
  history?: any;
  isCopy?: boolean;
}

interface ComponentState {
  optionRoads: { value: string; label: string }[];
  reqValues: null | ClaimReport;

  wagonModelsFieldDisabled: boolean;
  wagonProps: string | null;
  parentEtsngName: string | null;
}

@observer
class IlsSendForm extends Component<ComponentProps, ComponentState> {
  static defaultProps = { isCopy: false };
  formRef: FormInstance;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      optionRoads: [],
      reqValues: this.props.value,

      wagonModelsFieldDisabled: true,
      wagonProps: null,
      parentEtsngName: null,
    };
  }

  componentDidMount() {
    HttpClient.get<any[]>(`/api/station/roads`).then((res) => {
      const options = res.data.map((item) => ({ value: item.code, label: item.fullName }));
      this.setState({ optionRoads: options });
    });
  }

  digitsFormatter = (type: any, typing: any) => typing.input?.toString().replace(/[^+\d]/g, '');

  createLoadPlans = (calendar): LoadPlan[] => {
    const loadPlans = [];
    for (const [key, value] of Object.entries(calendar)) {
      const valueAs = value as { wagonCount: number | string; weight: number | string };
      if (valueAs?.wagonCount || valueAs?.weight) {
        loadPlans.push({ date: moment(key, 'DD.MM.YYYY').toISOString(true), ...valueAs });
      }
    }
    return loadPlans;
  };

  changeWagonProps = (value: string) => {
    if (this.formRef) {
      this.formRef.setFieldValue('wagonProps', value);
      this.formRef.setFieldValue('wagonModels', []);
      this.setState({ wagonProps: value, wagonModelsFieldDisabled: false });
    }
  };

  resetCalendar = () => {
    const data = this.state.reqValues;
    const days = moment(data.endDate).diff(moment(data.beginDate), 'day');
    if (!days) return;
    let currentDate = moment(this.state.reqValues.beginDate);
    while (currentDate <= moment(this.state.reqValues.endDate)) {
      const dateString = currentDate.startOf('day').format(GLOBAL_DATE_FORMAT);
      this.formRef.setFieldValue(['calendar', dateString, 'wagonCount'], null);
      this.formRef.setFieldValue(['calendar', dateString, 'weight'], null);
      currentDate.add(1, 'day');
    }
  };

  onAutoAllocation = () => {
    const singleWagonLoad = this.formRef.getFieldValue('singleWagonLoad');
    const wagonCount = this.formRef.getFieldValue('wagonCount');
    const data = this.state.reqValues;
    const days = moment(data.endDate).diff(moment(data.beginDate), 'day');
    if (!wagonCount || !days) return;
    this.resetCalendar();
    /*
        Если количество дней в периоде меньше, чем вагонов:
        Делим вагоны на дни.
        Если делится без остатка, то распределяем получившееся число вагонов посуточно на весь период.
        Если делится с остатком, то "Вагоны в сутки" = вагоны/(дни-1),
        округляем в меньшую сторону, полученное число устанавливаем в каждый день периода, кроме последнего.
        "Остаток вагонов" = вагоны - (вагоны в сутки * (дни-1)), полученное число устанавливаем в последний день период.

       */
    if (wagonCount >= days) {
      const wagonPerDay = Math.floor(wagonCount / days);
      let currentDate = moment(this.state.reqValues.beginDate);
      let wagonsInPlan = 0;
      while (currentDate < moment(this.state.reqValues.endDate)) {
        const dateString = currentDate.startOf('day').format(GLOBAL_DATE_FORMAT);
        this.formRef.setFieldValue(['calendar', dateString, 'wagonCount'], wagonPerDay);
        this.formRef.setFieldValue(['calendar', dateString, 'weight'], wagonPerDay * singleWagonLoad);
        currentDate.add(1, 'day');
        wagonsInPlan += wagonPerDay;
      }
      const lastDayCount = wagonCount - wagonsInPlan;
      const lastDayDateString = moment(this.state.reqValues.endDate).startOf('day').format(GLOBAL_DATE_FORMAT);
      this.formRef.setFieldValue(['calendar', lastDayDateString, 'wagonCount'], lastDayCount);
      this.formRef.setFieldValue(['calendar', lastDayDateString, 'weight'], lastDayCount * singleWagonLoad);
    }
    /*
          Если количество дней в периоде больше, чем вагонов:
          Период (сутки) = дни/вагоны
          Округляем период до целого числа вниз.
          Распределяем каждый период (сутки) по 1 вагону
        */

    if (wagonCount < days) {
      const dayPerWagon = Math.floor(days / wagonCount);
      let wagonsInPlan = 0;
      let currentDate = moment(this.state.reqValues.beginDate);
      while (wagonsInPlan < wagonCount) {
        const dateString = currentDate.startOf('day').format(GLOBAL_DATE_FORMAT);
        this.formRef.setFieldValue(['calendar', dateString, 'wagonCount'], 1);
        this.formRef.setFieldValue(['calendar', dateString, 'weight'], singleWagonLoad);
        currentDate.add(dayPerWagon, 'day');
        wagonsInPlan++;
      }
    }
  };

  changeEtsngCode = (value: any) => {
    this.setState({ parentEtsngName: value.parentFullName });
  };

  onWagonCountChange = (value: any) => {
    if (!this.formRef) return;

    const wagonCount = Number(value);
    const singleWagonLoad = Number(this.formRef.getFieldValue('singleWagonLoad'));

    if (wagonCount && singleWagonLoad) {
      this.formRef.setFieldValue('weightTotal', wagonCount * singleWagonLoad);
    }

    this.formRef.resetFields(['loadPlansValidator']);
    this.formRef.validateFields(['wagonCount', 'singleWagonLoad', 'weightTotal']);
  };

  onSingleWagonLoadChange = (value: any) => {
    if (!this.formRef) return;

    const wagonCount = Number(this.formRef.getFieldValue('wagonCount'));
    const singleWagonLoad = Number(value);

    if (wagonCount && singleWagonLoad) {
      this.formRef.setFieldValue('weightTotal', wagonCount * singleWagonLoad);
    }

    this.formRef.resetFields(['loadPlansValidator']);
    this.formRef.validateFields(['wagonCount', 'singleWagonLoad', 'weightTotal']);
  };

  onWeightTotalChange = (value: any) => {
    if (!this.formRef) return;

    const singleWagonLoad = Number(this.formRef.getFieldValue('singleWagonLoad'));
    const weightTotal = Number(value);

    if (weightTotal && singleWagonLoad) {
      const wagonCount = Math.ceil(weightTotal / singleWagonLoad);
      this.formRef.setFieldValue('wagonCount', wagonCount);

      if (wagonCount === 1 && singleWagonLoad > weightTotal) {
        this.formRef.setFieldValue('singleWagonLoad', weightTotal);
      }
    }
    this.formRef.resetFields(['loadPlansValidator']);
    this.formRef.validateFields(['wagonCount', 'singleWagonLoad', 'weightTotal']);
  };

  loadPlansValidator = () => {
    if (!this.formRef) return Promise.reject();

    const plans = this.createLoadPlans(this.formRef.getFieldValue('calendar'));
    const wagonCount = Number(this.formRef.getFieldValue('wagonCount'));
    const weightTotal = Number(this.formRef.getFieldValue('weightTotal'));

    let plansWagonCount = 0;
    let plansWeight = 0;

    for (let i = 0; i < plans.length; i++) {
      plansWagonCount += plans[i].wagonCount;
      plansWeight += plans[i].weight;
    }

    if (plansWagonCount !== wagonCount || plansWeight !== weightTotal) {
      return Promise.reject();
    }

    return Promise.resolve();
  };

  sendingHandler = (): void => {
    const { closeModal, editSendId, isCopy } = this.props;
    const { reqValues } = this.state;
    this.formRef.validateFields().then((e) => {
      const loadPlans = this.createLoadPlans(e.calendar);
      delete e.calendar;
      delete e.loadPlansValidator;

      let promise: any;

      if (editSendId) {
        if (isCopy) {
          promise = requestIlsStore.createSendIls({ ...e, ilsClaimId: reqValues.ilsClaimId, loadPlans });
        } else {
          const wagonSend = reqValues?.sends.find((item) => item.ilsSendId === editSendId) || {};
          promise = requestIlsStore.updateSendIls({ ...wagonSend, ...e, loadPlans });
        }
      } else {
        promise = requestIlsStore.createSendIls({ ...e, ilsClaimId: reqValues.ilsClaimId, loadPlans });
      }
      promise.then((ans) => {
        this.props.history.replace(`/view-ils-send/${ans.ilsClaimId}`);
        closeModal();
      });
    });
  };

  render() {
    const { reqValues } = this.state;
    const { value, editSendId } = this.props;
    const { optionRoads } = this.state;

    const wagonSend = editSendId ? value?.sends?.find((item) => item.ilsSendId === editSendId) : null;
    const wagonModelDisabled = wagonSend ? false : this.state.wagonModelsFieldDisabled;
    const parentEtsngName = wagonSend && !this.state.parentEtsngName ? wagonSend.parentEtsngName : this.state.parentEtsngName;

    return (
      <Modal
        width={'100%'}
        className={'RequestIlsForm'}
        title={'Заявка'}
        centered={true}
        open={this.props.open}
        onCancel={this.props.closeModal}
        footer={
          <div style={{ marginTop: 20, display: 'flex', gap: 10 }}>
            <Button type={'primary'} htmlType={'button'} onClick={this.sendingHandler}>
              Сохранить
            </Button>

            <Button htmlType={'button'} onClick={this.props.closeModal}>
              Отменить
            </Button>
          </div>
        }>
        <p className={`status sending`} style={{ margin: '10px 0 0 0' }}>
          <span className={'request'}>Заявка {reqValues ? `№${reqValues.ilsClaimNumber}` : null}</span>/{' '}
          <span className={'sending'}>Отправка</span>
        </p>

        <Form
          layout={'vertical'}
          className={'ilsFormSending'}
          scrollToFirstError={true}
          ref={(ref) => (this.formRef = ref)}
          initialValues={{
            planType: 'load',
            wagonDistributionType: 'manual',
            ...wagonSend,
          }}>
          <div className={'grid col-3'}>
            {(reqValues?.destinationType === 'station' || value?.destinationType === 'station') && (
              <Form.Item name={'destStationCode'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                <RemoteSelect
                  float={true}
                  autoInitDict={true}
                  primitiveResult={true}
                  placeholder={<RequiredPlaceholder value="Станция назначения" />}
                  valueExpr={'stationCode'}
                  displayExpr={'fullName'}
                  dataSource={dictService.stationDict}
                />
              </Form.Item>
            )}
            {(reqValues?.destinationType === 'road' || value?.destinationType === 'road') && (
              <Form.Item name={'destRoadCode'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                <FloatSelect options={optionRoads} placeholder={<RequiredPlaceholder value="Дорога назначения" />} />
              </Form.Item>
            )}
          </div>
          <div className={'grid col-2'}>
            <Form.Item name={'etsngCode'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
              <RemoteSelect
                float={true}
                autoInitDict={true}
                primitiveResult={true}
                maxTagCount="responsive"
                aValueExpr={null}
                placeholder={<RequiredPlaceholder value="Груз" />}
                valueExpr={'freightEtsngCode'}
                displayExpr={'fullName'}
                dataSource={dictService.freightEtsngDict}
                onSelectFull={this.changeEtsngCode}
                itemTemplate={(item) => (
                  <div style={{ whiteSpace: 'normal', height: 'auto' }}>
                    <span style={{ color: 'lightGray' }}>{item['freightEtsngCode']} </span>
                    {item['fullName']}
                  </div>
                )}
              />
            </Form.Item>
            <BorderWrapper>
              <div className={parentEtsngName ? 'selected parent-etsng-group' : 'parent-etsng-group'}>
                <span className="value">{parentEtsngName}</span>
                <span className="placeholder">Группа груза</span>
              </div>
            </BorderWrapper>
          </div>
          <div className={'grid col-2'}>
            <Form.Item name={'receiverId'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
              <RemoteSelect
                dataSource={dictService.okpoDict}
                valueExpr={'okpoId'}
                displayExpr={'shortName'}
                float={true}
                autoInitDict={true}
                primitiveResult={true}
                placeholder={<RequiredPlaceholder value="Грузополучатель" />}
              />
            </Form.Item>
            <div className={'grid col-2'}>
              <Form.Item name={'loadPayer'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                <FloatSelect
                  options={[
                    { value: 'client', label: 'Клиент' },
                    { value: 'operator', label: 'Транспортная компания' },
                  ]}
                  placeholder={<RequiredPlaceholder value="Плательщик груженого рейса" />}
                />
              </Form.Item>
              <Form.Item name={'emptyPayer'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                <FloatSelect
                  options={[
                    { value: 'client', label: 'Клиент' },
                    { value: 'operator', label: 'Транспортная компания' },
                  ]}
                  placeholder={<RequiredPlaceholder value="Плательщик порожнего рейса" />}
                />
              </Form.Item>
            </div>
          </div>
          <h3>Технические требования к вагону</h3>
          <div className={'grid col-2'}>
            <Form.Item name={'wagonProps'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
              <RpsFeatureSelect rps={value.rps} onChange={this.changeWagonProps} />
            </Form.Item>
            <Form.Item name={'wagonModels'}>
              <RpsModelSelect disabled={wagonModelDisabled} rps={value.rps} feature={this.state.wagonProps} />
            </Form.Item>
          </div>
          <div className={'grid col-4'}>
            <Form.Item name={'wagonLoadMin'}>
              <FloatInputNumber placeholder={'Грузоподъемность ОТ'} min={0} maxLength={3} formatter={this.digitsFormatter} />
            </Form.Item>
            <Form.Item name={'wagonLoadMax'}>
              <FloatInputNumber placeholder={'Грузоподъемность ДО'} min={0} maxLength={3} formatter={this.digitsFormatter} />
            </Form.Item>
            <Form.Item name={'wagonVolumeMin'}>
              <FloatInputNumber placeholder={'Объем кузова ОТ'} min={0} maxLength={3} formatter={this.digitsFormatter} />
            </Form.Item>
            <Form.Item name={'wagonVolumeMax'}>
              <FloatInputNumber placeholder={'Объем кузова ДО'} min={0} maxLength={3} formatter={this.digitsFormatter} />
            </Form.Item>
          </div>
          <div className={'grid col-2'}>
            <Form.Item name={'comment'} label={'Комментарий'}>
              <Input.TextArea placeholder={'Комментарий'} />
            </Form.Item>
          </div>
          <h3>График подач</h3>
          <div className={'grid col-2'}>
            <div>
              <div className={'grid col-3'}>
                <Form.Item name={'wagonCount'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                  <FloatInputNumber
                    placeholder={<RequiredPlaceholder value="Количество вагонов" />}
                    min={0}
                    onChange={this.onWagonCountChange}
                  />
                </Form.Item>
                <Form.Item name={'singleWagonLoad'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                  <FloatInputNumber
                    placeholder={<RequiredPlaceholder value="Загрузка одного вагона, тн" />}
                    min={0}
                    onChange={this.onSingleWagonLoadChange}
                  />
                </Form.Item>
                <Form.Item name={'weightTotal'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                  <FloatInputNumber
                    placeholder={<RequiredPlaceholder value="Планируемый общий вес груза" />}
                    min={0}
                    onChange={this.onWeightTotalChange}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={'loadPlansValidator'}
                initialValue={' '}
                rules={[
                  {
                    required: true,
                    validator: this.loadPlansValidator,
                    message: 'Количество вагонов и общий вес должны совпадать с графиком',
                  },
                ]}
              />
            </div>
            <div>
              <Form.Item name={'guNumber'}>
                <FloatInput placeholder={'Номер Заявки ГУ'} />
              </Form.Item>
            </div>
          </div>
          <h3>Распределение графика погрузки</h3>
          <div style={{ display: 'flex', width: '100%' }}>
            <Button htmlType={'button'} onClick={this.onAutoAllocation}>
              Автораспределение
            </Button>
            <Button htmlType={'button'} onClick={this.resetCalendar}>
              Очистить график
            </Button>
            {/*    <Form.Item style={{ width: '49%' }} name={'wagonDistributionType'}>
                        <Radio.Group style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <Radio value={'auto'}>Равномерно</Radio>
                            <Radio value={'manual'}>Вручную</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item style={{ width: '49%' }} name={'planType'}>
                        <Radio.Group style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <Radio value={'load'}>График погрузки</Radio>
                            <Radio value={'delivery'}>График подачи</Radio>
                        </Radio.Group>
                    </Form.Item>*/}
          </div>
          <Calendar
            defaultValue={moment(reqValues.beginDate)}
            headerRender={() => null}
            disabledDate={(currentDate) => {
              return this.isValidDate(currentDate, reqValues.beginDate, reqValues.endDate);
            }}
            dateFullCellRender={(date) => {
              if (!this.isValidDate(date, reqValues.beginDate, reqValues.endDate)) {
                const dateString = date.startOf('day').format(GLOBAL_DATE_FORMAT);
                const ilsPlan = wagonSend
                  ? wagonSend?.loadPlans?.find((plan) => moment(plan.date).startOf('day').format(GLOBAL_DATE_FORMAT) === dateString)
                  : null;
                return (
                  <div className={'calendar-cell'} style={{ backgroundColor: ilsPlan ? '#F5FFF3' : null }}>
                    <div>{date.format('DD')}</div>
                    <Form.Item name={['calendar', dateString]}>
                      <Form.Item
                        className={'cell-calendar-form-item'}
                        label={'Вагонов'}
                        name={['calendar', dateString, 'wagonCount']}
                        initialValue={ilsPlan ? ilsPlan.wagonCount : 0}>
                        <InputNumber min={0} controls={false} />
                      </Form.Item>

                      <Form.Item
                        className={'cell-calendar-form-item'}
                        name={['calendar', dateString, 'weight']}
                        initialValue={ilsPlan ? ilsPlan.weight : 0}
                        label={'Тонн'}>
                        <InputNumber min={0} controls={false} />
                      </Form.Item>
                    </Form.Item>
                  </div>
                );
              } else {
                return (
                  <div className={'calendar-cell'}>
                    <div>{date.format('DD')}</div>
                  </div>
                );
              }
            }}
          />
          Всего в графике {wagonSend?.loadPlans ? wagonSend?.loadPlans.length : 0}
        </Form>
      </Modal>
    );
  }

  isValidDate(currentDate, startDate, endDate) {
    return (
      moment(currentDate).startOf('day') < moment(startDate).startOf('day') || currentDate.startOf('day') > moment(endDate).startOf('day')
    );
  }
}

export default IlsSendForm;
