import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';

import companiesStore from '@stores/companiesStore/companiesStore';
import userStore from '@stores/userStore/userStore';

import OperationsList from './OperationsList';

interface State {}

class BankPage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    document.title = 'Банк';
    if (!userStore.isOrgOrUser) companiesStore.getCompanies();
  }

  render() {
    return (
      <>
        <AdaptivePageHeader title={'Банк'} />
        <AdaptiveCard>
          <OperationsList controller={'companyBanks'} height={'calc(100vh - 130px)'} />
        </AdaptiveCard>
      </>
    );
  }
}

export default BankPage;
