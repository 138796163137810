import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import TrackingHistoryTable from '@components/fw/TrackingHistoryTable';

import trackingContainerStore from '@stores/trackingStore/container/trackingContainerStore';

@observer
class TrackingContainersHistory extends Component<RouteComponentProps> {
  componentDidMount(): void {
    trackingContainerStore.loadHistory();
  }

  render() {
    const isBusy = trackingContainerStore.isBusyHistory;
    const data = trackingContainerStore.history;
    return (
      <>
        <TrackingHistoryTable value={data} isBusy={isBusy} type={'container'} />
      </>
    );
  }
}

export default TrackingContainersHistory;
