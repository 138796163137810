import { Checkbox, Col, Divider, Row, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import dictStore from '@stores/dictStore';
import { CompanySourceCountryModel, SourceModel } from '@stores/dictStore/dictStore';

interface ComponentProps {
  value?: CompanySourceCountryModel[];
  onChange?(e: CompanySourceCountryModel[]): void;
  countries: {
    src: SourceModel[];
    countryCode: number;
    countryName: string;
  }[];
}

interface ComponentState {}

@observer
class CompanySourceEditorExt extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  onChangeSource = (sourceId: string, countryCode: number) => {
    let value = this.props.value;
    if (!value) value = [];
    const v: CompanySourceCountryModel = value.find((s) => s.countryCode == countryCode);
    if (v) {
      value.splice(value.indexOf(v), 1);
    }
    if (sourceId) {
      value.push({
        sourceId: sourceId,
        countryCode: countryCode,
        rateWagonRu: 0.0,
        rateContainerRu: 0.0,
        containerSupport: false,
        wagonSupport: false,
        passportSupport: false,
        maintenanceSupport: false,
        detailSupport: false,
        rateTechRu: 0.0,
        currencyCode: '810',
      });
    }
    this.onChange(value);
  };

  onChange = (value: CompanySourceCountryModel[]) => {
    if (this.props.onChange) {
      this.props.onChange(JSON.parse(JSON.stringify(value)));
    }
  };

  render() {
    const countries = this.props.countries;
    const value = this.props.value;

    return (
      <div style={{ minHeight: 200 }}>
        {countries.map((c) => {
          const srcList = c.src;
          const countryCode = c.countryCode;
          let v: CompanySourceCountryModel = null;
          let isFirst = false;
          let selectedSource: SourceModel = null;
          let thisSourceValues: CompanySourceCountryModel[] = [];
          if (value) {
            v = value.find((s) => s.countryCode == countryCode);
            if (v) {
              thisSourceValues = value.filter((val) => val.sourceId == v.sourceId).sort((a, b) => a.countryCode - b.countryCode);
              isFirst = thisSourceValues.indexOf(v) == 0;
              selectedSource = srcList.find((src) => src.id == v.sourceId);
            }
          }
          return (
            <React.Fragment key={countryCode}>
              <Row className={'company-source-editor'} key={c.countryCode}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Divider>
                    <span className={'chapter'}>{c.countryName}</span>
                  </Divider>
                </Col>
                <Col xs={24} sm={5} md={5} lg={5}>
                  <FloatSelect
                    allowClear
                    onClear={() => this.onChangeSource(null, countryCode)}
                    placeholder={'Источник'}
                    value={v ? v.sourceId : null}
                    onSelect={(e) => this.onChangeSource(e, countryCode)}>
                    {srcList.map((src) => (
                      <Select.Option value={src.id} key={src.id}>
                        {src.name}
                      </Select.Option>
                    ))}
                  </FloatSelect>
                </Col>
                {selectedSource?.wagonSupport && (
                  <>
                    <Col xs={2} sm={1} md={1} lg={1}>
                      <Checkbox
                        checked={v ? v.wagonSupport : false}
                        disabled={!v}
                        onChange={(e) => {
                          v.wagonSupport = e.target.checked;
                          this.onChange(value);
                        }}
                      />
                    </Col>
                    <Col xs={22} sm={3} md={3} lg={3}>
                      <FloatInputNumber
                        value={v ? v.rateWagonRu : null}
                        placeholder={'Вагоны'}
                        disabled={!v}
                        onChange={(e) => {
                          v.rateWagonRu = e as number;
                          this.onChange(value);
                        }}
                      />
                    </Col>
                  </>
                )}
                {selectedSource?.containerSupport && (
                  <>
                    <Col xs={2} sm={1} md={1} lg={1}>
                      <Checkbox
                        checked={v ? v.containerSupport : false}
                        disabled={!v}
                        onChange={(e) => {
                          v.containerSupport = e.target.checked;
                          this.onChange(value);
                        }}
                      />
                    </Col>
                    <Col xs={22} sm={4} md={4} lg={4}>
                      <FloatInputNumber
                        value={v ? v.rateContainerRu : null}
                        placeholder={'Контейнеры'}
                        disabled={!v}
                        onChange={(e) => {
                          v.rateContainerRu = e as number;
                          this.onChange(value);
                        }}
                      />
                    </Col>
                  </>
                )}
                {isFirst && (selectedSource?.detailSupport || selectedSource?.passportSupport || selectedSource?.maintenanceSupport) ? (
                  <>
                    <Col xs={12} sm={8} md={8} lg={8}></Col>
                    <Col xs={12} sm={8} md={8} lg={8}></Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <div className={'supports-wrapper'}>
                        {selectedSource?.detailSupport && (
                          <Checkbox
                            checked={v ? v.detailSupport : false}
                            disabled={!v}
                            onChange={(e) => {
                              thisSourceValues.forEach((s) => (s.detailSupport = e.target.checked));
                              this.onChange(value);
                            }}>
                            Детали
                          </Checkbox>
                        )}
                        {selectedSource?.passportSupport && (
                          <Checkbox
                            checked={v ? v.passportSupport : false}
                            disabled={!v}
                            onChange={(e) => {
                              thisSourceValues.forEach((s) => (s.passportSupport = e.target.checked));
                              this.onChange(value);
                            }}>
                            Паспорт
                          </Checkbox>
                        )}
                        {selectedSource?.maintenanceSupport && (
                          <Checkbox
                            checked={v ? v.maintenanceSupport : false}
                            disabled={!v}
                            onChange={(e) => {
                              thisSourceValues.forEach((s) => (s.maintenanceSupport = e.target.checked));
                              this.onChange(value);
                            }}>
                            Ремонты
                          </Checkbox>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <FloatInputNumber
                        value={v ? v.rateTechRu : null}
                        placeholder={'Тех. информация'}
                        disabled={!v}
                        onChange={(e) => {
                          thisSourceValues.forEach((s) => (s.rateTechRu = e as number));
                          this.onChange(value);
                        }}
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={12} sm={3} md={3} lg={3}></Col>
                  </>
                )}
                {selectedSource && (
                  <Col xs={24} sm={5} md={5} lg={5}>
                    <FloatSelect
                      placeholder={'Валюта'}
                      defaultValue={'810'}
                      value={v ? v.currencyCode : '810'}
                      onSelect={(e) => {
                        v.currencyCode = e;
                        this.onChange(value);
                      }}>
                      {dictStore.currencyList.map((src) => (
                        <Select.Option value={src.currencyCode} key={src.currencyCode}>
                          {src.currencyName}
                        </Select.Option>
                      ))}
                    </FloatSelect>
                  </Col>
                )}
              </Row>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default CompanySourceEditorExt;
