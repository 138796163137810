import { Spin } from 'antd';
import DataGrid, { Column, Export, FilterRow, GroupPanel, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import { FinanceOperationModel } from '@stores/financeStore/FinanceData';
import financeStoreService from '@stores/financeStore/financeStore.service';

interface State {
  data: FinanceOperationModel[];
  isBusy: boolean;
}

@observer
class FinanceOperations extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      data: [],
      isBusy: true,
    };
  }

  componentDidMount() {
    financeStoreService
      .getFinanceOperationList()
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => this.setState({ isBusy: false }));
    document.title = 'Финансы Операции';
  }

  render() {
    const isBusy = this.state.isBusy;
    return (
      <AdaptiveCard>
        <Spin spinning={isBusy}>
          <DataGrid
            height={'calc(100vh - 132px)'}
            showBorders={true}
            columnResizingMode={'widget'}
            columnAutoWidth={true}
            showColumnLines={true}
            columnMinWidth={30}
            showRowLines={true}
            dataSource={this.state.data}>
            <Column allowFixing={true} caption={'Дата'} dataField={'operationDate'} dataType={'datetime'} />
            <Column allowFixing={true} caption={'Пользователь'} dataField={'userName'} dataType={'string'} />
            <Column allowFixing={true} caption={'Действие'} dataField={'content'} dataType={'string'} />
            <Export enabled={true} excelWrapTextEnabled={true} />
            <GroupPanel visible={true} />
            <HeaderFilter visible={true} allowSearch={true} />
            <Paging enabled={true} defaultPageSize={25} />
            <Pager
              allowedPageSizes={[25, 50, 100]}
              showPageSizeSelector={true}
              showNavigationButtons={true}
              showInfo={true}
              infoText={'Всего: {2}'}
            />
            <FilterRow visible={true} />
          </DataGrid>
        </Spin>
      </AdaptiveCard>
    );
  }
}

export default FinanceOperations;
