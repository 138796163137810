import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import { EtranInvoiceDocModel } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';

import { filterOptionFn } from '@utils/sort';

interface Props {
  data?: EtranInvoiceDocModel;
  onChange(data: EtranInvoiceDocModel): void;
}

interface State {
  visible: boolean;
  data: EtranInvoiceDocModel;
  search: string;
}

@observer
class EtranInvoiceCarSpcMarksModal extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      data: null,
      search: null,
    };
  }

  handleFormReset = () => {
    this.setState({ data: this.props.data ? this.props.data : null }, () => {
      if (this.formRef) this.formRef.resetFields();
      setTimeout(() => this.forceUpdate(), 200);
    });
  };

  handleConfirm = (e: EtranInvoiceDocModel) => {
    const selected = this.selected;
    const result: EtranInvoiceDocModel = {
      docTypeId: e.documentId,
      docType: selected.description, //TODO
      docNumber: e.docNumber,
      docDate: e.docDate,
      dayCount: e.dayCount,
      modelWag: e.modelWag,
      docVet: e.docVet,
      documentId: e.documentId,
      invoiceId: null,
    };
    this.setState({ visible: false });
    this.props.onChange(result);
  };

  onOpen = () => {
    this.setState({ visible: true }, () => {
      this.handleFormReset();
    });
  };

  get isEdit() {
    return !!this.props.data && this.props.data;
  }

  onClose = () => {
    this.handleFormReset();
    this.setState({ visible: false });
  };

  get selected() {
    if (!this.formRef) return;
    const selectedId = this.formRef.getFieldValue('docTypeId');
    if (!selectedId) return;
    return etranStore.dictionaryInfo.docTranspClauseDictionary.find((s) => s.transpClauseId == selectedId);
  }

  render() {
    const data = this.state.data;
    const marks = etranStore.dictionaryInfo.docTranspClauseDictionary.filter((d) => [7, 13].includes(d.transpClauseTypeId));
    const dayCount = this.formRef ? this.formRef.getFieldValue('dayCount') : null;
    const modelWag = this.formRef ? this.formRef.getFieldValue('modelWag') : null;
    return (
      <>
        <Button onClick={this.onOpen}>{this.isEdit ? 'Изменить' : 'Добавить'}</Button>
        <Modal
          title="Документы"
          destroyOnClose={true}
          open={this.state.visible}
          onCancel={this.onClose}
          className={'etran-modal'}
          footer={
            <Button
              onClick={() => {
                this.formRef.submit();
              }}>
              {this.isEdit ? 'Изменить' : 'Добавить'}
            </Button>
          }
          style={{ maxHeight: '90Vw' }}>
          <ScrolledContainer style={{ maxHeight: 'calc(100vh - 295px)', overflowX: 'hidden' }}>
            <Form
              layout="horizontal"
              ref={(ref) => (this.formRef = ref)}
              size={'small'}
              name={'etran-editor'}
              labelCol={{ span: 8 }}
              initialValues={{
                ...data,
              }}
              onFinish={this.handleConfirm}>
              <Form.Item name="docTypeId" label={'Документ'} rules={[{ required: true }]}>
                <Select
                  showSearch
                  optionFilterProp={'children'}
                  filterOption={filterOptionFn}
                  onSelect={(e) => {
                    this.forceUpdate(() => {
                      this.formRef.resetFields(['documentId', 'docVet']);
                    });
                  }}>
                  {marks.map((m) => (
                    <Select.Option value={m.transpClauseId} key={m.transpClauseId}>
                      {m.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={'docNumber'} label={'№ Документа'} rules={[{ required: true }]}>
                <Input maxLength={255} />
              </Form.Item>
              {!modelWag ? (
                <Form.Item name="dayCount" label={'Cрок транспортабельности/Количество дополнительных суток'}>
                  <InputNumber max={99999999} min={0} step={1} onChange={() => this.forceUpdate()} />
                </Form.Item>
              ) : null}
              {!dayCount ? (
                <Form.Item name="modelWag" label={'Модель вагона'}>
                  <Input maxLength={50} onChange={() => this.forceUpdate()} />
                </Form.Item>
              ) : null}
              {this.selected && [1291, 1292].includes(this.selected.transpClauseId) ? (
                <Form.Item
                  name="docVet"
                  label={'Идентификатор для электронных ветеринарных сопроводительных документов'}
                  rules={[{ required: true }]}>
                  <Input maxLength={39} />
                </Form.Item>
              ) : null}
              {this.selected && [1338].includes(this.selected.transpClauseId) ? (
                <Form.Item name="documentId" label={'Идентификатор согласия владельца жд. ПНП на отстой'} rules={[{ required: true }]}>
                  <InputNumber max={99999999} min={0} step={1} />
                </Form.Item>
              ) : null}
            </Form>
          </ScrolledContainer>
        </Modal>
      </>
    );
  }
}

export default EtranInvoiceCarSpcMarksModal;
