import { action, makeObservable, observable } from 'mobx';

import { FileInputModel, FileStorageFilter, SourceLogsTableData } from './sourceLogsData';
import service from './sourceLogsStore.service';

class TagStore {
  @observable isBusy: boolean = false;
  @observable items: FileInputModel[];
  @observable tableData: SourceLogsTableData = new SourceLogsTableData([]);

  constructor() {
    makeObservable(this);
  }

  @action async getData(
    type: 'input' | 'output',
    filter: FileStorageFilter = { searchString: null, dateFrom: null, dateTo: null, sourceId: null },
  ) {
    this.isBusy = !this.items;
    service
      .getData(type, filter)
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.items = result;
        this.tableData = new SourceLogsTableData(this.items);
      });
  }
  @action async getContent(fileId: string): Promise<FileInputModel> {
    return await service.getContent(fileId);
  }
}

export default new TagStore();
