import DataGrid, {
  Column,
  Export,
  FilterPanel,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  StateStoring,
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import TagDxCell from '@components/fw/DxCellTemplates/TagDxCell';

import { ignoreCaseFilter } from '@stores/dispatcherStore/DispatcherData';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import sendsStore from '@stores/sendsStore/sendsStore';
import tagStore from '@stores/tagStore/tagStore';
import userStore from '@stores/userStore/userStore';

import SendBoardDetail from './SendBoardDetail';

interface TableViewProps {
  onSelectionChanged(row: any): void;
  onRowDblClick(row: any): void;
  onDelete(row: any): void;
}

interface TableViewState {}

function testTag(rowData) {
  return rowData.tags.map((x) => x.tagId).join(' ');
}

@observer
class TableView extends Component<TableViewProps, TableViewState> {
  dataGrid: DataGrid;
  componentWillUnmount(): void {
    this.dataGrid.instance.clearSelection();
  }

  render() {
    const sends = sendsStore.sends;
    const isBusy = sendsStore.isBusy;

    return (
      <AdaptiveCard loading={isBusy}>
        <DataGrid
          style={{ height: 'calc(100vh - var(--padding))' }}
          allowColumnReordering={true}
          dataSource={sends}
          ref={(ref) => (this.dataGrid = ref)}
          loadPanel={{ enabled: false }}
          selection={{ mode: 'single' }}
          allowColumnResizing={true}
          remoteOperations={{ paging: true, filtering: true, sorting: true, grouping: true, summary: true }}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          onSelectionChanged={this.handleSelectionChanged}
          onRowDblClick={this.handleRowDoubleClick}
          onContextMenuPreparing={this.handleContextMenuPreparing}
          onExporting={this.onExporting}>
          <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'sendsTableView'} />
          <FilterPanel visible={true} />
          <Export enabled={true} excelWrapTextEnabled={true} />
          <GroupPanel visible={false} emptyPanelText={'Поле группировки'} />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} allowSearch={true} />
          {/*<StateStoring enabled={true} type="sessionStorage" storageKey="sends-grid" />*/}
          <Column allowFixing={true} dataField="sendName" caption={'Название отправки'} calculateFilterExpression={ignoreCaseFilter} />
          <Column allowFixing={true} dataField="statusName" caption={'Статус'} calculateFilterExpression={ignoreCaseFilter} />
          <Column
            allowFixing={true}
            dataField="claimName"
            caption={'Наименование заявки'}
            groupIndex={0}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="producerName" caption={'Постановщик'} calculateFilterExpression={ignoreCaseFilter} />
          <Column allowFixing={true} dataField="executorName" caption={'Исполнитель'} calculateFilterExpression={ignoreCaseFilter} />
          <Column allowFixing={true} dataField="createDate" caption={'Дата создания'} dataType={'date'} />
          <Column allowFixing={true} dataField="executionDate" caption={'Дата исполнения'} dataType={'date'} />
          <Column
            allowFixing={true}
            dataField="tags"
            caption={'Теги'}
            headerFilter={{
              dataSource: tagStore.tags.map((x) => {
                return { value: x.tagId, text: x.name };
              }),
            }}
            calculateFilterExpression={(value, selectedFilterOperations, target) => {
              if (target === 'headerFilter') {
                return [testTag, 'contains', value, 'ignorecase'];
              }
            }}
            cellRender={TagDxCell}
          />
          <Column allowFixing={true} dataField="commentCount" caption={'Коментарии'} calculateFilterExpression={ignoreCaseFilter} />
          {/*<MasterDetail enabled={true} component={(e) => <SendRowDetail onRowDblClick={() => {}} {...e} />} />*/}
          <MasterDetail enabled={true} component={(e) => <SendBoardDetail send={e.data.data} />} />
        </DataGrid>
      </AdaptiveCard>
    );
  }

  handleSelectionChanged = ({ selectedRowsData }: any) => {
    const data = selectedRowsData[0];
    this.props.onSelectionChanged(data);
  };

  handleRowDoubleClick = ({ data, rowType }: any) => {
    if (rowType === 'data') {
      this.props.onRowDblClick(data);
    }
  };
  onExporting = async (e) => {
    const ExcelJs = await import('exceljs');
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Лист1');
    const name = 'Отправки';

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: { row: 1, column: 1 },
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;
        if (gridCell.value && gridCell.column.dataField.toLowerCase().includes('tags')) {
          const v = gridCell.value;
          excelCell.value = v.map((f) => f.name).join(String.fromCharCode(10)); //Символ переноса Для excell таблиц
          excelCell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true }; //Настройки ячейки Excell для применения переносов строки
        }
      },
    }).then(function () {
      const date = moment().format(GLOBAL_DATE_FORMAT).toString();
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), date + `-Отчёт-${name}.xlsx`);
      });
    });
    e.cancel = true;
  };

  handleContextMenuPreparing = (e) => {
    if (!userStore.canAccess('sends/edit')) return;
    if (e.target === 'content') {
      if (e.row && e.row.rowType === 'data') {
        const row = e.row.data;
        if (!e.items) e.items = [];

        e.items.push(
          {
            text: 'Изменить',
            onItemClick: () => {
              this.props.onRowDblClick(row);
            },
          },
          {
            text: 'Удалить',
            onItemClick: () => {
              this.props.onDelete(row);
            },
          },
        );
      }
    }
  };
}

export default TableView;
