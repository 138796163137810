import { LoadOptions } from 'devextreme/data/load_options';

import HttpClient from '@utils/httpClient';

import { BidRegistryDto } from './registerOfBetsStore.types';

const service = {
  getReport(begin: string, end: string, loadOptions: LoadOptions) {
    return HttpClient.post(`/api/bidRegistry/report?begin=${begin}&end=${end}`, loadOptions).then((resp) => {
      return resp.data;
    });
  },
  createBidRegistry(body: BidRegistryDto) {
    return HttpClient.post(`/api/bidRegistry`, body).then((resp) => {
      return resp.data;
    });
  },
  updateBidRegistry(body: BidRegistryDto) {
    return HttpClient.put(`/api/bidRegistry`, body).then((resp) => {
      return resp.data;
    });
  },
  async deleteBidRegistry(id: string) {
    return (await HttpClient.delete(`/api/bidRegistry/${id}`)).data;
  },
  getBidRegistryById(id: string) {
    return HttpClient.get(`/api/bidRegistry/${id}`).then((resp) => {
      return resp.data;
    });
  },
  changeRegistryState(id: string, state: string) {
    return HttpClient.post(`/api/bidRegistry/status/${id}/${state}`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
