import { Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import EtranStatusViewer from '@components/routed/EtranPage/EtranStatusViewer';

import { EtranClaimInfo } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

import styles from './EtranClaimViewer.module.scss';

interface EtranClaimViewerState {
  isBusy: boolean;
  currentClaim: EtranClaimInfo;
}

class EtranClaimViewer extends Component<RouteComponentProps, EtranClaimViewerState> {
  formRef: FormInstance;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isBusy: true,
      currentClaim: null,
    };
  }

  componentDidMount(): void {
    const id = this.id;
    if (id) {
      etranStore
        .getEtranClaim(id)
        .then((ans) => {
          this.setState({ currentClaim: ans });
          document.title = `ГУ-12 № ${ans.claimNumber}`;
        })
        .finally(() => this.setState({ isBusy: false }));
    } else {
      this.setState({ isBusy: false });
    }
  }

  get id(): string {
    const { params } = this.props.match;
    return params['claimId'];
  }

  render() {
    const isBusy = this.state.isBusy;
    const t = this.state.currentClaim;
    return (
      <>
        <AdaptivePageHeader
          title={
            <>
              ГУ-12 № {t?.claimNumber} <EtranStatusViewer id={this.id} type={'etranClaim'} />
            </>
          }
          className={styles.pageHeader}
        />
        <ScrolledContainer style={{ height: 'calc(100vh - var(--header-height))' }}>
          <div className={styles.mainContent}>
            <div className={styles.containerFluid}>
              <div className={styles.row}>
                <div className={styles.colMd12}>
                  <Spin spinning={isBusy}>
                    {!!t && (
                      <>
                        <div className={styles.etranContent}>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>
                              Дата начала действия изменений:
                            </label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>
                                {!!t.clmChangeDate ? moment(t.clmChangeDate).format(GLOBAL_DATE_FORMAT) : null}
                              </span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Дата регистрации:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{moment(t.claimRegDate).format(GLOBAL_DATE_FORMAT)}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Период перевозок:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              с <span className={styles.comboEdit}>{moment(t.clmStartDate).format(GLOBAL_DATE_FORMAT)}</span>
                              по <span className={styles.comboEdit}>{moment(t.clmFinishDate).format(GLOBAL_DATE_FORMAT)}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Вид сообщения:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmMsgKindName}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Признак отправки:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmSendKind}</span>
                            </div>
                          </div>

                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Страна отправления:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmFromCountryName}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Станция отправления:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmFromStationName}</span>
                              Код дороги: <span className={styles.textEdit}> {t.stDpRwId}</span>
                              Код станции: <span className={styles.textEdit}> {t.clmFromStationCode}</span>
                              Параграфы: <span className={styles.textEdit}> {t.fromStationParagraphs}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Грузоотправитель:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmSenderName}</span>
                              ОКПО: <span className={styles.textEdit}> {t.clmSenderOkpo}</span>
                              Код ТГНЛ: <span className={styles.textEdit}> {t.clmSenderTgnl}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Адрес:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmSenderAddress}</span>
                            </div>
                          </div>

                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Принадлежность вагонов:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmCarOwnerType}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Номер договора:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmDogNumber}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>
                              Владелец жд. пути необщего пользования:
                            </label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmLoadWayOwnerName}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>
                              Отметка о согласовании владельцем пути:
                            </label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmLoadWayOwnerNote}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>
                              Дата согласования владельцем пути:
                            </label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{moment(t.clmLoadWayOwnerAgreementDate).format(GLOBAL_DATE_FORMAT)}</span>
                            </div>
                          </div>

                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Группа груза:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmFreightGroupName}</span>
                              Код группы груза: <span className={styles.textEdit}> {t.clmFreightGroupCode}</span>
                              {/*Мин. норма загр.: <span className={styles.textEdit}> {t.clmSenderTgnl}</span> т.
                                Макс. норма загр.: <span className={styles.textEdit}> {t.clmSenderTgnl}</span> т.*/}
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Способ подачи:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.clmTypePod}</span>
                            </div>
                          </div>

                          <div className={styles.row}>
                            <div className={`${styles.colSm12} ${styles.tableResponsive}`}>
                              <br />
                              <table className={styles.etranTableInvoice} style={{ border: 'solid 1px black', width: '100%' }}>
                                <caption>Отправки</caption>
                                <thead className={styles.textPrimary}>
                                  <tr>
                                    <th>Номер отправки</th>
                                    <th>Код груза</th>
                                    <th>Груз</th>
                                    <th>Род подвижного состава</th>
                                    <th>Количество вагонов/контейнеров</th>
                                    <th>Вес груза</th>
                                    <th>Станция назначения</th>
                                    <th>Страна назначения</th>
                                    <th>Плата</th>
                                    <th>Валюта</th>
                                    <th>Примечание</th>
                                    <th>Расстояние, км</th>
                                    <th>Срок дост. груза</th>
                                    <th>Собственник вагона</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {t.otprInfos.map((item, index) => (
                                    <tr key={`infos_${index}`}>
                                      <td>{index + 1}</td>
                                      <td>{item.otprFreightCode}</td>
                                      <td>{item.otprFreightName}</td>
                                      <td>{item.otprCarType}</td>
                                      <td>{item.otprCarCount}</td>
                                      <td>{item.otprFreightWeight}</td>
                                      <td>{item.otprToStationName}</td>
                                      <td>{item.otprToCountryName}</td>
                                      <td>{item.otprAmount}</td>
                                      <td>{item.otprCurrency}</td>
                                      <td>{item.otprNote}</td>
                                      <td>{item.otprDistWay}</td>
                                      <td>{item.otprDaysDelivery}</td>
                                      <td>{item.otprCarOwnerName}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <br />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={`${styles.colSm12} ${styles.tableResponsive}`}>
                              <br />
                              <table className={styles.etranTableInvoice} style={{ border: 'solid 1px black', width: '100%' }}>
                                <caption>График подач</caption>
                                <thead className={styles.textPrimary}>
                                  <tr>
                                    <th>№</th>
                                    <th>№ отправки</th>
                                    <th>Дата подачи</th>
                                    <th>Количество вагонов</th>
                                    <th>Вес(тонн)</th>
                                    <th>Срок доставки</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {t.graphPodInfos.map((item, index) => (
                                    <tr key={`podinfos_${index}`}>
                                      <td>{item.gpPodNum}</td>
                                      <td>{item.otprNom}</td>
                                      <td>{item.gpPodDate}</td>
                                      <td>{item.gpCarCount}</td>
                                      <td>{item.gpWeight}</td>
                                      <td></td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <br />
                            </div>
                          </div>

                          <div className={styles.row}>
                            <div className={`${styles.colSm12} ${styles.tableResponsive}`}>
                              <br />
                              <table className={styles.etranTableInvoice} style={{ border: 'solid 1px black', width: '100%' }}>
                                <caption>Плательщики / Экспедиторы</caption>
                                <thead className={styles.textPrimary}>
                                  <tr>
                                    <th>Код плательщика</th>
                                    <th>ОКПО</th>
                                    <th>Наименование</th>
                                    <th>Страна</th>
                                    <th>Номер отправки</th>
                                    <th>Код перевозчика</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {t.payerInfos.map((item, index) => (
                                    <tr key={`payers_${index}`}>
                                      <td>{item.payerCode}</td>
                                      <td>{item.payerOkpo}</td>
                                      <td>{item.payerName}</td>
                                      <td>{item.payerCountryName}</td>
                                      <td>{item.payerOtprNom}</td>
                                      <td>{item.payerCarrierCode}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <br />
                            </div>
                          </div>

                          <div className={styles.row}>
                            <div className={`${styles.colSm12} ${styles.tableResponsive}`}>
                              <br />
                              <table className={styles.etranTableInvoice} style={{ border: 'solid 1px black', width: '100%' }}>
                                <caption>Ход согласования</caption>
                                <thead className={styles.textPrimary}>
                                  <tr>
                                    <th>Согласование</th>
                                    <th>Наименование организации</th>
                                    <th>Состояние</th>
                                    <th>Дата согласования</th>
                                    <th>Комментарии</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {t.agreements.map((item, index) => (
                                    <tr key={`agreements_${index}`}>
                                      <td>{item.agrName}</td>
                                      <td>{item.agrOrgName}</td>
                                      <td>{item.agrState}</td>
                                      <td>{item.agrDate}</td>
                                      <td>{item.agrRemark}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </ScrolledContainer>
      </>
    );
  }
}

export default observer(EtranClaimViewer);
