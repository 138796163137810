import { CloseCircleOutlined, EditOutlined, MenuOutlined, MinusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ClientFieldRequest, CustomReportDetailsModel, InfoBlockItemFields } from '@stores/dispatcherStore/DispatcherData';
import dispatcherService from '@stores/dispatcherStore/dispatcherStore.service';

interface ComponentProps {
  dataSource: InfoBlockItemFields;
  onRemove(): void;
  editedReport?: CustomReportDetailsModel | null;
}

interface ComponentState {
  isEditField: boolean;
  currentName: string;
  isNameSaveBusy: boolean;
}

class FieldElement extends Component<ComponentProps, ComponentState> {
  formRef: FormInstance;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      isEditField: false,
      currentName: props.dataSource.name,
      isNameSaveBusy: false,
    };
  }

  saveHandle = (event) => {
    const name = event.description;
    const field: ClientFieldRequest = {
      fieldId: this.props.dataSource.id,
      customName: name,
      customReportId: this.props.editedReport.id,
    };
    this.setState({ isNameSaveBusy: true });
    dispatcherService
      .saveField(field)
      .then((ans) => {
        this.props.dataSource.abbreviation = name;
      })
      .finally(() => {
        this.setState({ isEditField: false, isNameSaveBusy: false });
      });
  };

  editHandle = () => {
    if (this.props.editedReport) {
      this.setState({ isEditField: true });
    }
  };

  cancelHandle = () => {
    this.setState({ isEditField: false });
  };

  handleRemove = () => {
    this.cancelHandle();
    this.props.onRemove();
  };

  render() {
    const field = this.props.dataSource;
    const isEditMode = !!this.props.editedReport;
    const isNameSaveBusy = this.state.isNameSaveBusy;

    return (
      <>
        {this.state.isEditField && (
          <Col span={24}>
            <Form
              name="basic"
              layout={'inline'}
              size={'small'}
              onFinish={this.saveHandle}
              initialValues={{ description: field.abbreviation }}
              ref={(ref) => (this.formRef = ref)}>
              <Button
                onClick={this.handleRemove}
                type="text"
                shape="circle"
                size={'large'}
                icon={<MinusCircleOutlined className={'dispatcher-icon dispatcher-icon-red'} />}
              />
              <Button htmlType="submit" type="text" shape="circle" size={'large'} icon={<SaveOutlined className={'dispatcher-icon'} />} />
              <Button
                onClick={this.cancelHandle}
                type="text"
                danger
                shape="circle"
                size={'large'}
                icon={<CloseCircleOutlined className={'dispatcher-icon'} />}
              />
              <Form.Item
                name="description"
                style={{ marginRight: '1px', marginBottom: '1px', padding: '0 0.7rem', fontSize: '14px' }}
                rules={[{ required: true, message: '' }]}>
                <Input />
              </Form.Item>
              <Spin spinning={isNameSaveBusy} />
            </Form>
          </Col>
        )}

        {!this.state.isEditField && (
          <>
            <Col span={24}>
              <span style={{ marginRight: '1px', marginBottom: '1px', float: 'left' }}>
                <Button
                  onClick={this.handleRemove}
                  type="text"
                  shape="circle"
                  icon={<MinusCircleOutlined className={'dispatcher-icon dispatcher-icon-red'} />}
                />
                <Button
                  onClick={() => this.editHandle()}
                  type="text"
                  shape="circle"
                  icon={<EditOutlined className={'dispatcher-icon'} />}
                />
                <span
                  style={{ marginRight: '1px', marginBottom: '1px', padding: '0 0.7rem', fontSize: '14px' }}
                  onClick={() => this.editHandle()}>
                  {field.description + '(' + field.abbreviation + ')'}
                </span>
              </span>
              <Button type="text" shape="circle" style={{ float: 'right' }} icon={<MenuOutlined className={'dispatcher-icon'} />} />
            </Col>
          </>
        )}
      </>
    );
  }
}

export default observer(FieldElement);
