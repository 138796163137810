import { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import EtranAdress from '@components/routed/EtranPage/CommonInput/EtranAdress';
import EtranCountry from '@components/routed/EtranPage/CommonInput/EtranCountry';
import EtranOrganization from '@components/routed/EtranPage/CommonInput/EtranOrganization';
import EtranPayer from '@components/routed/EtranPage/CommonInput/EtranPayer';
import EtranStation from '@components/routed/EtranPage/CommonInput/EtranStation';
import EtranDevsTable from '@components/routed/EtranPage/Devs/EtranDevsTable';
import EtranDistancesTable from '@components/routed/EtranPage/Distances/EtranDistancesTable';
import EtranDocsTable from '@components/routed/EtranPage/Docs/EtranDocsTable';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';
import EtranStatusViewer from '@components/routed/EtranPage/EtranStatusViewer';
import EtranFreightsTable from '@components/routed/EtranPage/Freights/EtranFreightsTable';
import EtranSPFsTable from '@components/routed/EtranPage/SPFs/EtranSPFsTable';
import EtranSpecialMarksTable from '@components/routed/EtranPage/SpecialMarks/EtranSpecialMarksTable';
import EtranWagonsTable from '@components/routed/EtranPage/Wagons/EtranWagonsTable';

import etranStore from '@stores/etranStore/etranStore';
import etranService from '@stores/etranStore/etranStore.service';
import { GLOBAL_DATETIME_FORMAT } from '@stores/optionsStore/optionsStoreData';

import { showSuccessNotify } from '@utils/notify';
import { filterOptionFn } from '@utils/sort';

import styles from './EtranViewer.module.scss';

const { Option } = Select;

interface EtranViewerState {
  payerLoading: boolean;
}

class EtranViewer extends Component<RouteComponentProps, EtranViewerState> {
  formRef: FormInstance;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      payerLoading: false,
    };
    etranStore.resetFormData();
  }

  componentDidMount(): void {
    etranStore.getEtranDictionary().then();
    const invoiceId = this.invoiceId;
    if (invoiceId) {
      this.loader(invoiceId);
      document.title = `Накладная № ${this.invoiceId}`;
    } else {
      document.title = `Создание накладной`;
    }
  }

  loader = (id: string) => {
    etranStore.getEtranInvoice(id).then(() => {
      if (this.formRef) {
        this.formRef.resetFields();
      }
    });
  };

  get isEditMode(): boolean {
    return this.props.match.path !== '/etran/create-invoice';
  }

  get invoiceId(): string {
    const { params } = this.props.match;
    return params['invoiceId'];
  }

  handleConfirm = (e) => {
    const promise = this.isEditMode ? etranStore.updateInvoice(e) : etranStore.createInvoice(e);
    promise.then(() => {
      showSuccessNotify('Документ сохранен');
    });
  };

  render() {
    const isBusy = etranStore.loadingInvoice;
    const isEdit = this.isEditMode;

    const height = 'calc(100vh - var(--header-height))';
    const invoice = etranStore.invoceFormData;
    const dict = etranStore.dictionaryInfo;
    /// Тип накладной
    const invoiceTypeDictionary = dict.invoiceTypeDictionary;
    /// Тип бланка
    const blankTypeDictionary = dict.blankTypeDictionary;
    /// Вид отправки
    const sendTypeDictionary = dict.sendTypeDictionary;
    /// Грузоотправитель
    /// Вид грузовых работ
    const loadTypeDictionary = dict.loadTypeDictionary;
    /// Скорость
    const speedDictionary = dict.speedDictionary;
    /// Место оплаты
    const payPlaceDictionary = dict.payPlaceDictionary;
    /// Форма оплаты
    const payFormDictionary = dict.payFormDictionary;
    /// Планируемый тип собственности
    const plannedOwningTypeDictionary = dict.plannedOwningTypeDictionary;
    /// Вид сообщения
    const messageTypeDictionary = dict.messageTypeDictionary;
    /// Плательщик
    const orgDictionary = etranService.getEtranOrganizationDictionary;
    /// Планируемы род вагона
    const wagonTypeDictionary = dict.wagonTypeDictionary;
    /// Планируемы род вагона
    const someDictionary = [
      { id: 0, name: '1' },
      { id: 1, name: '2' },
      { id: 2, name: '3' },
      { id: 4, name: '...' },
    ];

    const invblankTypeId = this.formRef ? this.formRef.getFieldValue('invBlankTypeId') : null;
    const invBlankType = invblankTypeId ? blankTypeDictionary.find((b) => b.blancId == invblankTypeId) : null;

    const invFromCountryCode = this.formRef ? this.formRef.getFieldValue('invFromCountryCode') : null;

    const invToCountryCode = this.formRef ? this.formRef.getFieldValue('invToCountryCode') : null;

    const invToCountryEmptyCode = this.formRef ? this.formRef.getFieldValue('invToCountryEmptyCode') : null;

    return (
      <>
        <AdaptivePageHeader
          title={
            this.isEditMode ? (
              <span>
                Накладная № {this.invoiceId} <EtranStatusViewer id={this.invoiceId} type={'etranInvoice'} />{' '}
              </span>
            ) : (
              <span>Создание накладной</span>
            )
          }
          //extra={<EtranStatusViewer id={this.invoiceId} type={'etranInvoice'} />}
          className={styles.pageHeader}
          extra={
            <Button onClick={() => this.formRef.submit()} loading={etranStore.loadingSaveInvoice}>
              Сохранить
            </Button>
          }
        />
        <ScrolledContainer style={{ height: 'calc(100vh - var(--header-height))' }}>
          <Form
            layout="horizontal"
            ref={(ref) => (this.formRef = ref)}
            size={'small'}
            name={'etran-editor'}
            //labelCol={{ span: 8 }}
            initialValues={invoice}
            onFinish={this.handleConfirm}>
            <div className={styles.mainContent}>
              <div className={styles.containerFluid}>
                <div className={styles.row}>
                  <div className={styles.colMd12}>
                    <Spin spinning={isBusy}>
                      <div className={styles.etranContent}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Тип накладной:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invTypeId'}>
                              <Select className={'comboEditSearchOther'} optionFilterProp="children" filterOption={filterOptionFn}>
                                {invoiceTypeDictionary.map((i) => (
                                  <Option value={i.id} key={'invoiceTypeDictionary' + i.id}>
                                    {i.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Тип бланка:</label>
                          </Col>
                          <Col xs={20} sm={20} md={20} lg={16}>
                            <Form.Item name={'invBlankTypeId'}>
                              <Select
                                className={'comboEditSearchOther'}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOptionFn}
                                onChange={() => this.forceUpdate()}
                                suffixIcon={<SearchOutlined />}>
                                {blankTypeDictionary.map((i) => (
                                  <Option value={i.blancId} key={i.blancId}>
                                    {i.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col className={'invBlankType'} xs={4} sm={4} md={4} lg={4}>
                            <Form.Item name={' '}>
                              <span className={styles.textEditThree}>{invBlankType ? invBlankType.mnemo : ''}</span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Вид отправки:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invSendKindId'}>
                              <Select
                                className={'comboEditSearchOther'}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOptionFn}
                                suffixIcon={<SearchOutlined />}>
                                {sendTypeDictionary.map((i) => (
                                  <Option value={i.kindSend} key={i.kindSend}>
                                    {i.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Грузоотправитель:</label>
                          </Col>
                          <Col className={'comboEditSearchOther'} xs={24} sm={24} md={24} lg={20}>
                            <Form.Item name={'invSender'}>
                              <EtranOrganization
                                address={true}
                                formRef={this.formRef}
                                name={'invSender'}
                                onChange={() => this.forceUpdate()}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <EtranAdress name={'invSender'} formRef={this.formRef} />
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Заявка:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invClaimNumber'}>
                              <Input className={'comboEditOther'} maxLength={20} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Грузополучатель:</label>
                          </Col>
                          <Col className={'comboEditSearchOther'} xs={24} sm={24} md={24} lg={20}>
                            <Form.Item name={'invRecip'}>
                              <EtranOrganization
                                address={true}
                                formRef={this.formRef}
                                name={'invRecip'}
                                onChange={() => this.forceUpdate()}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <EtranAdress name={'invRecip'} formRef={this.formRef} />
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Вид грузовых работ:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={20}>
                            <Form.Item name={'invLoadTypeId'}>
                              <Select
                                showSearch
                                className={'comboEditSearchOther'}
                                optionFilterProp="children"
                                filterOption={filterOptionFn}
                                suffixIcon={<SearchOutlined />}>
                                {loadTypeDictionary.map((i) => (
                                  <Option value={i.id} key={'loadTypeDictionary' + i.id}>
                                    {i.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Страна отправления:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={20}>
                            <Form.Item
                              className={'invCountry'}
                              name={'invFromCountryCode'}
                              extra={<Input className={'invCountryExtra'} value={invFromCountryCode} readOnly />}>
                              <EtranCountry onChange={() => this.forceUpdate()} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Страна назначения:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={20}>
                            <Form.Item
                              className={'invCountry'}
                              name={'invToCountryCode'}
                              extra={<Input className={'invCountryExtra'} value={invToCountryCode} readOnly />}>
                              <EtranCountry onChange={() => this.forceUpdate()} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item name={'invFromStation'}>
                          <EtranStation label={'Станция отправления/входа в СНГ:'} />
                        </Form.Item>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Подъездной путь станции отправления:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invFromLoadWay'}>
                              <Input className={'comboEditOther'} maxLength={40} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item name={'invToStation'}>
                          <EtranStation label={'Станция назначения/входа в СНГ:'} />
                        </Form.Item>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Подъездной путь станции назначения:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invToLoadWay'}>
                              <Input className={'comboEditOther'} maxLength={40} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Страна назначения порожняка:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={20}>
                            <Form.Item
                              className={'invCountry'}
                              name={'invToCountryEmptyCode'}
                              extra={<Input className={'invCountryExtra'} value={invToCountryEmptyCode} readOnly />}>
                              <EtranCountry onChange={() => this.forceUpdate()} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Скорость:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invSendSpeedId'}>
                              <Select
                                className={'comboEditSearchOther'}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOptionFn}>
                                {speedDictionary.map((item) => (
                                  <Option value={item.speedCode} key={'speedDictionary' + item.speedCode}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Место оплаты:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invPayPlaceId'}>
                              <Select
                                className={'comboEditSearchOther'}
                                optionFilterProp="children"
                                filterOption={filterOptionFn}
                                showSearch
                                placeholder={'Поиск'}
                                suffixIcon={<SearchOutlined />}>
                                {payPlaceDictionary.map((item) => (
                                  <Option value={item.payPlace} key={'payPlaceDictionary' + item.payPlace}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Форма оплаты:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={8}>
                            <Form.Item name={'invPayFormId'}>
                              <Select
                                className={'comboEditSearchOther'}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOptionFn}
                                placeholder={'Поиск'}
                                suffixIcon={<SearchOutlined />}>
                                {payFormDictionary.map((item) => (
                                  <Option value={item.payForm} key={'payFormDictionary' + item.payForm}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item name={'invPayer'}>
                          <EtranPayer />
                        </Form.Item>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Подкод экспедитора:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invFrwsubCode'}>
                              <Input className={'comboEditOther'} maxLength={12} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Код исключительного тарифа:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invIxTariffCode'}>
                              <InputNumber className={'comboEditOther'} maxLength={5} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Планируемый род вагона:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={7}>
                            <Form.Item name={'invPlanCarTypeId'}>
                              <Select
                                className={'comboEditSearchOther'}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOptionFn}
                                placeholder={'Поиск'}
                                suffixIcon={<SearchOutlined />}>
                                {wagonTypeDictionary.map((item) => (
                                  <Option value={item.wagTypeId} key={'wagonTypeDictionary' + item.wagTypeId}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Планируемый тип собственности вагона:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invPlanCarOwnerTypeId'}>
                              <Select
                                className={'comboEditSearchOther'}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOptionFn}
                                placeholder={'Поиск'}
                                suffixIcon={<SearchOutlined />}>
                                {plannedOwningTypeDictionary.map((item) => (
                                  <Option value={item.id} key={'plannedOwningTypeDictionary' + item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Планируемое количество вагонов:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={20}>
                            <Form.Item name={'invPlanCarCount'}>
                              {/*Плановый тип собственности вагона (ID по НСИ - COMMON, тип 7)
                                Значение по умолчанию – тип собственности вагона N1 из блока вагоны
                                * тип данных: целое число*/}
                              <InputNumber className={'textSelect'} maxLength={3} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Планируемая негабаритность:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invPlanOutsizeCode'}>
                              <Input className={'comboEditOther'} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <EtranFreightsTable />
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Заявка на перевозку груза:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invLoadClaimNumber'}>
                              <Input className={'comboEditOther'} maxLength={10} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Объявленная ценность (руб):</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invAnnounceValue'}>
                              <InputNumber min={0} max={999999999999999} step={0.01} className={'comboEditOther'} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Погрузка средствами:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invLoadAssetId'}>
                              <Select className={'comboEditSearchOther'} suffixIcon={<SearchOutlined />}>
                                {dict.loadAssetTypeDictionary.map((item) => (
                                  <Option value={item.id} key={'loadAssetTypeDictionary' + item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={24}>
                            <EtranDistancesTable />
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Вид сообщения:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invDispKindId'}>
                              <Select
                                className={'comboEditSearchOther'}
                                showSearch
                                disabled //а дизаблед ли?
                                optionFilterProp="children"
                                filterOption={filterOptionFn}
                                placeholder={'Поиск'}
                                suffixIcon={<SearchOutlined />}>
                                {messageTypeDictionary.map((item) => (
                                  <Option value={item.kindDisp} key={'plannedOwningTypeDictionary' + item.asoupCode}>
                                    <span>{item.kindDisp} </span>
                                    <span> {item.name}</span>
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <EtranSpecialMarksTable />
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Ответственный за внесение данных:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={5}>
                            <Form.Item name={'invRespPerson'}>
                              <Input className={'comboEditOther'} type="text" maxLength={255} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>№ визы согласования:</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={2}>
                            <Form.Item name={'invVizaNumber'}>
                              <InputNumber className={styles.textEdit} min={0} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={24}>
                            <EtranDocsTable />
                          </Col>
                          <Col span={24}>
                            <EtranDevsTable />
                          </Col>
                          <Col span={24}>
                            <EtranWagonsTable />
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Фактическая дата и время погрузки MCK</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <Form.Item name={'invFactDateToLoad'}>
                              <DatePicker showTime={{ format: 'HH:mm' }} className={'etranDatePickerMSK'} format={GLOBAL_DATETIME_FORMAT} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            Фактическая дата и время погрузки МЕСТНЫЕ
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <Form.Item name={'invFactDateToLoadLocal'}>
                              <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                className={'etranDatePickerLOCAL'}
                                format={GLOBAL_DATETIME_FORMAT}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Заполнение данных о погрузке закончено</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={20}>
                            <Form.Item name={'?'}>
                              {/*@TODO неизвесно как инициализируется данное поле*/}
                              <Input className={styles.textEdit} min={0} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <EtranCaption title={'Данные о маршруте'} />
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Маршрут</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={20}>
                            <Row style={{ width: '100%' }}>
                              <Form.Item className={'etran-form-route'} name={'?'}>
                                {/*@TODO название поля?*/}
                                <Col xs={24} sm={24} md={24} lg={5}>
                                  <Input className={styles.textEdit} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10}>
                                  <Input className={styles.textEdit} />
                                </Col>
                                <Col className={'etran-form-label-wrapper'} xs={24} sm={24} md={24} lg={12}>
                                  <label className={'etranFormLabel'}>На путях общего пользования</label>
                                  <Checkbox />
                                </Col>
                              </Form.Item>
                            </Row>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>На путях общего пользования</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={20}>
                            <Form.Item className={'etran-form-route'} name={'roads'}>
                              {/*@TODO название поля? Нужен словарь элементов списка*/}
                              <Row style={{ width: '100%' }}>
                                <Col className={'etran-form-label-wrapper'} xs={24} sm={24} md={24} lg={2}>
                                  <Checkbox className={'comboEditSearchOther'} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4}>
                                  <label className={'etranFormLabel'}>Тип маршрута</label>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4}>
                                  <Select className={'comboEditSearchOther'} placeholder={'Выберите элемент списка'}>
                                    <Option key={'formWaySelect'} value={'Нет данных'}>
                                      <span>Нет данных</span>
                                    </Option>
                                  </Select>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={4}>
                            <label className={'etranFormLabel'}>Маршрут</label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={20}>
                            <Form.Item className={'etran-form-route'} name={'?'}>
                              {/*@TODO название поля?*/}
                              <Row style={{ width: '100%' }}>
                                <Col xs={24} sm={24} md={24} lg={4}>
                                  <Select
                                    className={'comboEditSearchOther'}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOptionFn}
                                    placeholder={'Поиск'}
                                    suffixIcon={<SearchOutlined />}>
                                    {someDictionary.map((item) => (
                                      <Option value={item.name} key={'someDictionary' + item.id}>
                                        {item.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10}>
                                  <label className={'etranFormLabel'}>Номер нового отправительского маршрута</label>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={8}>
                                  <Input className={styles.textEdit} />
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className={'etranButtonsGroup'}>
                          <Button disabled={false}>Привязать</Button>
                          <Button disabled={true}>Отвязать</Button>
                          <Button disabled={true}>Закрыть</Button>
                          <Button disabled={true}>Открыть</Button>
                        </div>
                        <EtranSPFsTable />
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </ScrolledContainer>
      </>
    );
  }
}

export default observer(EtranViewer);
