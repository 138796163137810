import { Alert, Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import designStore from '@stores/designStore/designStore';
import service from '@stores/userStore/userStore.service';

import { setAuthority } from '@utils/authority';

import styles from './LoginPage.module.scss';

const { Content } = Layout;

interface ComponentState {
  error: string;
}

class CodeLoginPage extends Component<RouteComponentProps, ComponentState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  render() {
    const isMobile = designStore.height > designStore.width;
    return (
      <Layout className={styles.container}>
        <Content className={styles.content} style={isMobile ? { marginTop: '0' } : { marginTop: '100px' }}>
          <AdaptiveCard
            title={'ВХОД'}
            className={styles.card}
            style={isMobile ? { background: 'white', height: '100vh' } : { background: 'white' }}>
            {this.state.error ? <Alert message={this.state.error} type="warning" /> : null}
          </AdaptiveCard>
        </Content>
      </Layout>
    );
  }

  get code() {
    return this.props.location.pathname.replace(this.props.match.path + '/', '');
  }

  componentDidMount() {
    if (!this.code) {
      this.setState({ error: 'Код доступа не найден' });
      return;
    }
    service.getTokenByCode(this.code).then((result) => {
      if (!result) {
        this.setState({ error: 'Код не корректный или срок доступа истек' });
      } else {
        setAuthority({ access_token: result.access_token, refresh_token: result.refresh_token, tmpUser: true });
        this.props.history.push('/options');
        //logStore.in().then(() => {});
      }
    });
  }
}

export default observer(CodeLoginPage);
