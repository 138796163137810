import { CSSProperties, FC } from 'react';
import React from 'react';

import styles from './flex.module.scss';
import { FlexProps } from './flex.types';

export const Flex: FC<FlexProps> = ({ children, flex, justify: justifyContent, gap, wrap: flexWrap, vertical, align: alignItems }) => {
  const style: CSSProperties = {
    flex,
    justifyContent,
    gap,
    flexWrap,
    alignItems,
    flexDirection: vertical ? 'column' : 'row',
  };

  return (
    <div className={styles.flex} style={style}>
      {children}
    </div>
  );
};
