import { Tooltip } from 'antd';
import React from 'react';

import { registerOfBetsStore } from '@stores/registerOfBetsStore';

export const renderCurrency = () => {
  return (
    <Tooltip title={registerOfBetsStore.currency.name}>
      <span>{registerOfBetsStore.currency.shortName}</span>
    </Tooltip>
  );
};
