import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, List, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { DocTypeContext, DocTypeModel } from '@stores/contractTemplateStore/ContractTemplatesData';
import companyTemplateService from '@stores/contractTemplateStore/companyTemplates.service';
import supplierTemplateService from '@stores/contractTemplateStore/supplierTemplates.service';

import ContractTemplateEditor from './ContractTemplateEditor';

interface ComponentProps {
  parentId: string;
  dataContext: DocTypeContext;
  type: 'company' | 'supplier';
}

interface ComponentState {
  data: DocTypeModel[];
  loading: boolean;
}

@observer
class ContractTemplateList extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      data: [],
      loading: true,
    };
  }

  get service() {
    if (this.props.type == 'company') return companyTemplateService;
    if (this.props.type == 'supplier') return supplierTemplateService;
  }

  componentDidMount() {
    this.getData();
  }

  handleDelete = (item: DocTypeModel) => {
    this.setState({ loading: true });
    this.service
      .deleteContract(item.docTypeId)
      .then(() => {
        this.getData();
      })
      .finally(() => this.setState({ loading: false }));
  };

  getData = () => {
    this.setState({ loading: true });
    this.service
      .getContractList(this.props.parentId, this.props.dataContext)
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const data = this.state.data;
    return (
      <List
        header={
          <ContractTemplateEditor
            type={this.props.type}
            id={null}
            parentId={this.props.parentId}
            onFinish={this.getData}
            dataContext={this.props.dataContext}
          />
        }
        loading={this.state.loading}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Popconfirm title="Удалить？" okText="Да" cancelText="Нет" onConfirm={() => this.handleDelete(item)}>
                <Button size={'small'} type={'text'} icon={<DeleteOutlined />} />
              </Popconfirm>,
              <ContractTemplateEditor
                type={this.props.type}
                dataContext={this.props.dataContext}
                key={index + 'editor'}
                id={item.docTypeId}
                parentId={this.props.parentId}
                onFinish={this.getData}
              />,
            ]}>
            <List.Item.Meta avatar={<FileTextOutlined />} title={index + 1 + '. ' + item.name} />
          </List.Item>
        )}
      />
    );
  }
}

export default ContractTemplateList;
