import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin } from 'antd';
import DataGrid, {
  Column,
  Export,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import companiesStore from '@stores/companiesStore/companiesStore';
import { InfologFinanceModel } from '@stores/financeStore/FinanceData';
import financeStore from '@stores/financeStore/financeStore';

import InfologFinanceDocumentsMenu from './InfologFinanceDocumentsMenu';
import InfologFinanceEditor from './InfologFinanceEditor';

export const currencyRuDxCell = (e) => (e && e.value && e.value.toLocaleString ? e.value.toLocaleString('ru') : '');

@observer
class InfologFinance extends Component<RouteComponentProps> {
  componentDidMount() {
    document.title = 'Финансы Инфолог';
    financeStore.loadInfologFinances();
    companiesStore.getCompanies();
  }

  handleDelete = (e: InfologFinanceModel) => {
    financeStore.delete(e.financeId, e.accountNumber);
  };

  render() {
    const isBusy = financeStore.isBusy;
    return (
      <AdaptiveCard title={<InfologFinanceEditor data={null} />}>
        <Spin spinning={isBusy}>
          <DataGrid
            height={'calc(100vh - 186px)'}
            showBorders={true}
            rowAlternationEnabled={true}
            columnResizingMode={'widget'}
            columnAutoWidth={true}
            showColumnLines={true}
            columnMinWidth={30}
            showRowLines={true}
            dataSource={financeStore.infologFinances}>
            <Column
              allowFixing={true}
              allowFiltering={false}
              allowExporting={false}
              allowHeaderFiltering={false}
              allowGrouping={false}
              allowSearch={false}
              allowSorting={false}
              allowReordering={false}
              allowHiding={false}
              caption={' '}
              dataField={'financeId'}
              cellRender={(e) => {
                return (
                  <span>
                    <InfologFinanceEditor data={e.data} />
                    <Popconfirm title="Удалить？" okText="Да" cancelText="Нет" onConfirm={() => this.handleDelete(e.data)}>
                      <Button size={'small'} type={'text'} icon={<DeleteOutlined />} />
                    </Popconfirm>
                    <InfologFinanceDocumentsMenu data={e.data} />
                  </span>
                );
              }}
            />
            <Column
              allowFixing={true}
              alignment={'center'}
              caption={'Месяц'}
              dataField={'monthYear'}
              dataType={'date'}
              customizeText={(e) => {
                if (e.target == 'row') return moment(e.value).format('MMM YYYY');
                else return e.value;
              }}
            />
            <Column
              allowFixing={true}
              caption={'Тип'}
              dataField={'profit'}
              dataType={'string'}
              customizeText={(e) => (e.value == 'profit' ? 'Доход' : 'Расход')}
            />
            <Column allowFixing={true} caption={'Статья'} dataField={'articleName'} dataType={'string'} />
            <Column allowFixing={true} caption={'Название контрагента'} dataField={'customCompanyName'} dataType={'string'} />
            <Column allowFixing={true} caption={'Номер счета'} dataField={'accountNumber'} dataType={'string'} />
            <Column allowFixing={true} caption={'Описание работ из cчёта'} dataField={'workDescription'} dataType={'string'} />
            <Column allowFixing={true} caption={'Сумма без НДС'} dataField={'sumWithoutNds'} customizeText={currencyRuDxCell} />
            <Column allowFixing={true} caption={'НДС'} dataField={'nds'} customizeText={currencyRuDxCell} />
            <Column allowFixing={true} caption={'Сумма c НДС'} dataField={'sumWithNds'} customizeText={currencyRuDxCell} />
            <Column allowFixing={true} caption={'Комментарий'} dataField={'note'} />
            <Column allowFixing={true} caption={'Создал'} dataField={'createUserName'} />
            <Column allowFixing={true} caption={'Дата создания'} dataField={'createDate'} dataType={'datetime'} />
            <Export enabled={true} excelWrapTextEnabled={true} />
            <GroupPanel visible={true} />
            <HeaderFilter visible={true} allowSearch={true} />
            <Paging enabled={true} defaultPageSize={25} />
            <Pager
              allowedPageSizes={[25, 50, 100]}
              showPageSizeSelector={true}
              showNavigationButtons={true}
              showInfo={true}
              infoText={'Всего: {2}'}
            />
            <FilterRow visible={true} />
            <Summary calculateCustomSummary={this.calculateSelectedRow}>
              <TotalItem name="sumWithNds" summaryType="custom" customizeText={currencyRuDxCell} showInColumn="sumWithNds" />
              <TotalItem customizeText={currencyRuDxCell} name="sumWithoutNds" summaryType="custom" showInColumn="sumWithoutNds" />
              <TotalItem customizeText={currencyRuDxCell} name="nds" summaryType="custom" showInColumn="nds" />
            </Summary>
          </DataGrid>
        </Spin>
      </AdaptiveCard>
    );
  }

  calculateSelectedRow(options) {
    if (options.summaryProcess === 'start') {
      options.totalValue = 0;
    } else if (options.summaryProcess === 'calculate') {
      if (options.value.profit == 'profit') {
        options.totalValue = options.totalValue + options.value[options.name];
      } else {
        options.totalValue = options.totalValue - options.value[options.name];
      }
    }
  }
}

export default InfologFinance;
