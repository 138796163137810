import { Button, Divider, Spin } from 'antd';
import { Template } from 'devextreme-react';
import DataGrid, { Column, GroupPanel, HeaderFilter, Scrolling } from 'devextreme-react/data-grid';
import L from 'leaflet';
import React, { Component } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Circle, MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet/';

import pointPic from '@assets/point.png';
import wagonPic from '@assets/wagon.svg';

import WagonAutoselectionTariffDxCell from '@components/fw/DxCellTemplates/WagonAutoselectionTariffDxCell';

import dictStoreService from '@stores/dictStore/dictStore.service';
import { StationModel } from '@stores/dictStore/dictStoreData';
import { SendLimitationsModel, SendModel, WagonAutoSelectInfo } from '@stores/sendsStore/sendData';
import sendsStore from '@stores/sendsStore/sendsStore';

import { showErrorNotify, showSuccessNotify } from '@utils/notify';

import styles from './SendInfo.module.scss';

interface Props {
  send: SendModel;
  loadStationCode: string;
  onChange(): void;
}

interface State {
  isBusy: boolean;
  visible: boolean;
  loadingConfirm: boolean;
  data: WagonAutoSelectInfo[];
  selected: WagonAutoSelectInfo[];
  isTariffBusy: boolean;
  isBusySend: boolean;
  limitations: SendLimitationsModel;
  station: StationModel;
}

const iconPoint = new L.Icon({
  iconRetinaUrl: pointPic,
  iconUrl: pointPic,
  shadowUrl: null,
  iconSize: new L.Point(10, 10),
});

const iconWagons = new L.Icon({
  iconRetinaUrl: wagonPic,
  iconUrl: wagonPic,
  shadowUrl: null,
  iconSize: new L.Point(25, 25),
});

class WagonsAutoSelection extends Component<Props, State> {
  dataGrid: DataGrid;

  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: false,
      visible: false,
      loadingConfirm: false,
      data: [],
      selected: [],
      isTariffBusy: false,
      isBusySend: false,
      limitations: null,
      station: null,
    };
  }

  handleOpen = () => {
    this.setState({
      isBusy: true,
      visible: true,
      selected: [],
    });
    //Заглушка для тестов под админом
    //if (userStore.userData.roleCode != 'admin') {
    sendsStore
      .wagonsAutoSelection(this.props.send.freightEtsngCode, this.props.send.loadStationCode)
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => this.setState({ isBusy: false }));
    // }
    sendsStore.loadGeoInfo(Number(this.props.send.loadStationCode));
    this.getLimitation();
    this.loadStation();
  };

  getLimitation() {
    sendsStore.getLimitation(this.props.send.sendId, null).then((limitations) => {
      this.setState({ limitations: limitations });
    });
  }

  loadStation = () => {
    dictStoreService.stationDict(this.props.send.loadStationCode).then((ans) => {
      if (ans.length) {
        const find = ans.find((s) => s.stationCode == this.props.send.loadStationCode);
        this.setState({ station: ans[0] });
      }
    });
  };
  handleClose = () => {
    this.setState({
      isBusy: false,
      visible: false,
    });
  };

  onSelectionChanged = (e) => {
    this.setState({ selected: e.selectedRowsData });
  };

  //https://wiki.openstreetmap.org/wiki/RU:Zoom_levels
  get zoom() {
    if (!sendsStore.geoInfo) return 1;
    const mInPx = (sendsStore.geoInfo.radius * 2000) / 400;
    let target = 0;
    let z = 78200;
    while (z / mInPx > 1) {
      target++;
      z = z / 2;
    }
    return target;
  }

  render() {
    const visible = this.state.visible;
    const isBusy = this.state.isBusy;
    const data = this.state.data;
    const geoInfo = sendsStore.geoInfo;
    const zoom = this.zoom;
    const station = this.state.station;
    const createClusterCustomIcon = (cluster) => {
      const count = cluster.getChildCount();
      const size = 25 + count;
      const toDiv = () => {
        return <div className={styles.markerIcon}></div>;
      };
      const toSpan = () => {
        return <span className={styles.markerSpan}></span>;
      };

      return L.divIcon({
        //iconRetinaUrl: '/static/media/wagon.d0fb8e62.svg',
        //iconUrl: '/static/media/wagon.d0fb8e62.svg',
        //shadowUrl: null,
        iconSize: [0, 0],
        //html: `<span style="background-color: white; font-size: 1em ; border-radius: 15px; width: 15px; height: 15px; display: flex; justify-content: center; align-items: center;">${count}</span>`,
        html: `<div class=${toDiv().props.className} style="width: ${size}px;height: ${size}px;">
                <span class=${toSpan().props.className}>${count}</span>
               </div>`,
        //className: `${size}`,
        //riseOnHover: true,
        //icon: iconWagons,
      });
    };

    return (
      <>
        {!visible && (
          <Button type={'link'} size={'small'} onClick={this.handleOpen}>
            Подобрать вагоны
          </Button>
        )}

        {visible && (
          <Button type={'link'} size={'small'} onClick={this.handleClose}>
            Закрыть автободбор
          </Button>
        )}
        {visible && (
          <Spin spinning={isBusy}>
            <DataGrid
              allowColumnReordering={true}
              ref={(r) => (this.dataGrid = r)}
              dataSource={data}
              allowColumnResizing={true}
              showBorders={true}
              columnResizingMode={'widget'}
              columnAutoWidth={true}
              showColumnLines={true}
              columnMinWidth={10}
              showRowLines={true}
              remoteOperations={false}
              onSelectionChanged={(e) => this.onSelectionChanged(e)}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}
              selection={{ mode: 'multiple' }}>
              <GroupPanel visible={true} />
              <HeaderFilter visible={true} allowSearch={true} />
              <Scrolling mode="virtual" />
              <Template name="tariffButton" render={this.calcTariffButton} />
              <Template name="autoSelectionButton" render={this.sendAutoSelectedButton} />
              <Column allowFixing={true} dataField="wagonNumber" caption={'Вагон'} />
              <Column allowFixing={true} dataField="model" caption={'Модель'} />
              <Column allowFixing={true} dataField="volume" caption={'Объем кузова (м3)'} />
              <Column allowFixing={true} dataField="lifting" caption={'Грузоподъемность (т)'} />
              <Column allowFixing={true} dataField="nextPlanRepairDays" caption={'Срок до планового ремонта (сут)'} />
              <Column allowFixing={true} dataField="planRepairName" caption={'Тип планового ремонта'} />
              <Column allowFixing={true} dataField="dislStationName" caption={'Станция дислокации'} />
              <Column allowFixing={true} dataField="destStationName" caption={'Станция назначения'} />
              <Column allowFixing={true} dataField="needWash" caption={'Требует промывки'} />
              <Column allowFixing={true} dataField="currentToTargetDistance" caption={'Расстояние подсыла (км)'} />
              <Column allowFixing={true} dataField="receivePlanDate" caption={'Дата планируемого прибытия'} dataType={'date'} />
              <Column allowFixing={true} dataField="commonTariff" caption={'Тариф (руб)'} cellRender={WagonAutoselectionTariffDxCell} />
            </DataGrid>
            {geoInfo ? (
              <div>
                <Divider />
                <MapContainer style={{ height: '400px' }} center={[geoInfo.latitude, geoInfo.longitude]} zoom={zoom} maxZoom={18}>
                  <Circle center={[geoInfo.latitude, geoInfo.longitude]} radius={geoInfo.radius * 1000} />
                  <MarkerClusterGroup
                    iconCreateFunction={createClusterCustomIcon}
                    //spiderLegPolylineOptions={{ weight: 1 }}
                  >
                    {data.map((d) => {
                      return (
                        <Marker icon={iconWagons} position={[d.latitude ? d.latitude : 0, d.longitude ? d.longitude : 0]}>
                          <Popup autoPan={false}>{d.wagonNumber}</Popup>
                        </Marker>
                      );
                    })}
                  </MarkerClusterGroup>
                  <Marker icon={iconWagons} position={[geoInfo.latitude, geoInfo.longitude]}>
                    <Tooltip permanent opacity={0.7}>
                      {station ? station.stationCode + ' ' + station.fullName : this.props.send.loadStationCode}
                    </Tooltip>
                  </Marker>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </MapContainer>
              </div>
            ) : null}
          </Spin>
        )}
      </>
    );
  }

  calcTariffButton = () => {
    const isBusy = this.state.isTariffBusy;
    const disabled = !this.state.selected.length;
    return (
      <Button size={'small'} onClick={() => this.handleCalcTariff()} loading={isBusy} disabled={disabled}>
        Расчет тарифа
      </Button>
    );
  };
  sendAutoSelectedButton = () => {
    const isBusy = this.state.isBusySend;
    const disabled = !this.state.selected.length || !!this.state.selected.find((s) => !s.isTariffCalculated);
    return (
      <Button size={'small'} onClick={() => this.handleSedAutoSelected()} loading={isBusy} disabled={disabled}>
        Сформировать график подач
      </Button>
    );
  };

  async handleCalcTariff() {
    this.setState({ isTariffBusy: true });
    await Promise.all(this.state.selected.map((m) => this.loadTariff(m)));
    if (this.dataGrid) this.dataGrid.instance.refresh();
    this.setState({ isTariffBusy: false });
    showSuccessNotify('Расчет тарифа', 'Тариф расчитан');
  }

  handleSedAutoSelected = () => {
    if (this.state.limitations && this.state.selected.length > this.state.limitations.wagonCount) {
      showErrorNotify('Проверьте число вагонов', `не более  ${this.state.limitations.wagonCount}`);
      return;
    }
    const selected = this.state.selected.map((s) => s.wagonNumber);
    const data = this.state.data.filter((w) => selected.includes(w.wagonNumber));
    this.setState({ isBusySend: true });
    sendsStore
      .sendAutoSelected(data, this.props.send.sendId)
      .then(() => {
        this.props.onChange();
      })
      .finally(() => this.setState({ isBusySend: false }));
  };

  async loadTariff(m: WagonAutoSelectInfo) {
    let data = await sendsStore.tariffForAutoSelected({
      freightCapacity: m.lifting,
      loadStationCode: m.destStationCode,
      destStationCode: this.props.send.loadStationCode,
      freightEtsngCode: m.currentFreightCode,
      freightWeight: 0,
      wagonAffiliationTypeEnum: 'CommonPark',
      isEmpty: true,
    });
    let d = this.state.data;
    let find = d.find((d) => d.wagonNumber === m.wagonNumber);
    if (find) {
      find.commonTariff = data.commonParkFreightChargeRuWithVat;
      find.isTariffCalculated = true;
      this.setState({ data: d });
    }
  }

  handleConfirm = () => {};

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'tariffButton',
      },
      {
        location: 'before',
        template: 'autoSelectionButton',
      },
    );
  }
}

export default WagonsAutoSelection;
