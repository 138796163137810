import { FormInstance } from 'antd';
import React from 'react';

import { SupplierBidRegistryCreateBasicTab as BasicTab } from '../basic-tab';
import { SupplierBidRegistryCreateCalculationTab as CalculationTab } from '../calculation-tab';

export const getTabsItem = (form: FormInstance, isEdit?: boolean) => {
  return [
    { label: 'Основное', key: 'basic', children: <BasicTab form={form} /> },
    { label: 'Расчет', key: 'calculation', children: <CalculationTab form={form} />, disabled: !isEdit },
  ];
};
