import { Button, Form, Input, Spin } from 'antd';
import React from 'react';

import { TagModel } from '@stores/tagStore';

import ColorSelector from './ColorSelector';

interface TagCreatorProps {
  onFinish(values);
  tag?: TagModel;
  isBusy: boolean;
}

const TagCreator: React.FC<TagCreatorProps> = (props) => {
  const init = props.tag ? { name: props.tag.name, color: props.tag.metadata.color } : null;
  return (
    <Spin spinning={props.isBusy}>
      <Form onFinish={props.onFinish} layout={'vertical'} initialValues={init} size={'small'}>
        <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Цвет" name="color">
          <ColorSelector />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={props.isBusy}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default TagCreator;
