import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import RemoteSelect from '@components/fw/RemoteSelect';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';
import { etranEtsngGngTemplate } from '@components/routed/EtranPage/Wagons/EtranInvoceCarInfoModal';

import {
  EtranInvoiceFreight,
  EtranInvoiceFreightForm,
  emptyEtranInvoiceFreight,
  etranInvoiceFreightFormToModel,
  etranInvoiceFreightModelToForm,
} from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';
import etranStoreService from '@stores/etranStore/etranStore.service';

import { filterOptionFn } from '@utils/sort';

import EtranFreightsClsTable from './EtranFreightClsTable';

interface Props {
  data?: EtranInvoiceFreight;
  onChange(e: EtranInvoiceFreight): void;
}

interface State {
  visible: boolean;
  wagonInfoLoading: boolean;
  data: EtranInvoiceFreightForm;
  freightDangerSearch: string;
}

@observer
class EtranInvoceFreightsInfoModal extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      data: etranInvoiceFreightModelToForm(emptyEtranInvoiceFreight),
      wagonInfoLoading: false,
      freightDangerSearch: null,
    };
  }

  handleFormReset = () => {
    const data = this.props.data ? this.props.data : emptyEtranInvoiceFreight;
    this.setState({ data: etranInvoiceFreightModelToForm(data) }, () => {
      if (this.formRef) {
        this.formRef.resetFields();
        this.forceUpdate();
      }
    });
  };

  handleConfirm = (e: EtranInvoiceFreightForm) => {
    const dict = etranStore.dictionaryInfo;
    const find = dict.freightDangerDictionary.find((d) => d.id === e.freightDangerSignId);
    if (find) {
      e.freightDangerSignName = find.name;
    }
    this.props.onChange(etranInvoiceFreightFormToModel(e));
    this.setState({ visible: false });
    this.formRef.resetFields();
  };

  onOpen = () => {
    this.setState({ visible: true }, () => {
      this.handleFormReset();
    });
  };

  get isEdit() {
    return !!this.props.data;
  }

  onClose = () => {
    this.handleFormReset();
    this.setState({ visible: false });
  };

  render() {
    const dict = etranStore.dictionaryInfo;
    const freightDangerDictionary = dict.freightDangerDictionary;
    const data = this.state.data;
    const freightGngObj = this.formRef ? this.formRef.getFieldValue('freightGngObj') : null;
    const freightEtsngObj = this.formRef ? this.formRef.getFieldValue('freightEtsngObj') : null;

    return (
      <>
        {!this.isEdit ? <Button onClick={this.onOpen}>Добавить</Button> : <Button onClick={this.onOpen}>Изменить</Button>}
        <Modal
          title="Груз"
          open={this.state.visible}
          onCancel={this.onClose}
          className={'etran-modal'}
          footer={<Button onClick={() => this.formRef.submit()}>{this.isEdit ? 'Сохранить' : 'Добавить'}</Button>}>
          <ScrolledContainer>
            <Form
              layout="horizontal"
              ref={(ref) => (this.formRef = ref)}
              size={'small'}
              name={'etran-editor'}
              labelCol={{ span: 4 }}
              initialValues={data}
              onFinish={this.handleConfirm}>
              <EtranCaption title={'Опасный груз'} />
              <Form.Item name={'freightDangerSignId'} label={'Признак опасного груза'}>
                <Select showSearch optionFilterProp={'children'} filterOption={filterOptionFn}>
                  {freightDangerDictionary.map((i) => (
                    <Select.Option value={i.id} key={i.id}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <EtranCaption title={'Код ГНГ'} />
              <Form.Item name={'freightGngObj'} label={'Груз ГНГ'}>
                <RemoteSelect
                  placeholder={'поиск'}
                  valueExpr={'code'}
                  displayExpr={'name'}
                  onChange={() => this.forceUpdate()}
                  dataSource={etranStoreService.getEtranGng}
                  itemTemplate={etranEtsngGngTemplate}
                />
              </Form.Item>
              <Form.Item label={'Старый код груза ГНГ'}>
                <span>{freightGngObj ? freightGngObj.oldCode : null}</span>
              </Form.Item>
              <EtranCaption title={'Код ЕТ СНГ'} />
              <Form.Item name={'freightEtsngObj'} label={'Груз ЕТ СНГ'}>
                <RemoteSelect
                  placeholder={'поиск'}
                  valueExpr={'code'}
                  displayExpr={'name'}
                  onChange={() => this.forceUpdate()}
                  dataSource={etranStoreService.getEtranEtsng}
                  itemTemplate={etranEtsngGngTemplate}
                />
              </Form.Item>
              {/*<Form.Item label={'Код груза'}>
                <span>{freightEtsngObj ? freightEtsngObj.freightEtsngCode : null}</span>
              </Form.Item>
              <Form.Item label={'Точное наименование груза'}>
                <span>{freightEtsngObj ? freightEtsngObj.fullName : null}</span>
              </Form.Item>*/}

              <Form.Item name={'freightAdditional'} label={'Дополнительные сведения о грузе'}>
                <TextArea />
              </Form.Item>
              <Form.Item label={'Масса груза кг'} name={'freightWeight'}>
                <InputNumber min={0} max={99999} step={1} />
              </Form.Item>
              <Form.Item label={'Количество мест'} name={'freightPackageCount'}>
                <InputNumber min={0} max={9999} step={1} />
              </Form.Item>
              <Form.Item label={'Знаки и марки'} name={'freightSendersMark'}>
                <Input maxLength={200} />
              </Form.Item>
              <Form.Item label={'Объем'} name={'freightVolume'}>
                <InputNumber min={0} max={999} step={0.01} />
              </Form.Item>
              <Form.Item>
                <EtranFreightsClsTable
                  data={data.specialMarks}
                  onChange={(e) => {
                    this.state.data.specialMarks = e;
                    this.forceUpdate();
                  }}
                />
              </Form.Item>
            </Form>
          </ScrolledContainer>
        </Modal>
      </>
    );
  }
}

export default EtranInvoceFreightsInfoModal;
