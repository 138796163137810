import { action, makeObservable } from 'mobx';

import userStore from '../userStore/userStore';
import { TaskAdminDisplayModel } from './monitorData';
import service from './monitorStore.service';

class MonitorStore {
  constructor() {
    makeObservable(this);
  }

  @action async getData(): Promise<TaskAdminDisplayModel[]> {
    if (userStore.userData.roleCode == 'admin') return await service.getAdminTasks();
    else return await service.getUserTasks();
  }
}

export default new MonitorStore();
