export enum PageType {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum BidKind {
  itinerary = 'itinerary',
  time = 'time',
}

export enum BidTeuType {
  ps = 'ps',
  ton = 'ton',
}
