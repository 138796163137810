import HttpClient from '@utils/httpClient';

import { TariffRequestModel } from '../historyTariffInfo/historyTariffInfoStore';
import {
  CompanyTariffModel,
  CompanyTariffRequestKpModel,
  CompanyTariffResponseModel,
  CompanyTariffSettingsModel,
  TariffCompanyEnabledModel,
} from './CompanyTariffData';

const service = {
  /// Получить настройки тарифа компании
  async getSettings(companyId: string): Promise<CompanyTariffSettingsModel> {
    return (await HttpClient.get(`/api/companyTariff/settings/${companyId}`)).data;
  },

  /// Сохранить настройки тарифа компании
  async updateSettings(companyId: string, data: CompanyTariffSettingsModel): Promise<void> {
    return (await HttpClient.put(`/api/companyTariff/settings/${companyId}`, data)).data;
  },

  /// Сохранить тариф компании
  async addTariff(companyId: string, data: CompanyTariffModel): Promise<void> {
    return (await HttpClient.post(`/api/companyTariff`, data, { params: { companyId } })).data;
  },

  /// Получить список тарифов компании
  async getTariffList(companyId: string): Promise<CompanyTariffModel[]> {
    return (await HttpClient.get(`/api/companyTariff`, { params: { companyId } })).data;
  },

  /// Получить список компаний с включенным тарифом
  async getCompaniesWithTariffEnable(): Promise<TariffCompanyEnabledModel[]> {
    return (await HttpClient.get(`/api/companyTariff/enabled`)).data;
  },

  /// Удалить тариф компании
  async deleteTariff(companyId: string, companyTariffId: string): Promise<void> {
    return (await HttpClient.delete(`/api/companyTariff/${companyTariffId}`, { params: { companyId } })).data;
  },

  /// Обновить тариф компании
  async updateTariff(companyId: string, companyTariffId: string, data: CompanyTariffModel): Promise<void> {
    return (await HttpClient.put(`/api/companyTariff/${companyTariffId}`, data, { params: { companyId } })).data;
  },

  async calcTariff(companyId: string, data: TariffRequestModel): Promise<CompanyTariffResponseModel> {
    return (await HttpClient.post(`/api/companyTariff/calc`, data, { params: { companyId } })).data;
  },

  async requestKp(data: CompanyTariffRequestKpModel): Promise<string> {
    return (await HttpClient.post(`/api/companyTariff/kp`, data)).data;
  },
};

export default service;
