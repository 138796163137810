import { Tabs } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import OperationsList from '@components/routed/BankPage/OperationsList';

import adminBillingStore from '@stores/adminBilling/adminBillingStore';
import designStore from '@stores/designStore/designStore';
import userStore from '@stores/userStore/userStore';

import AdminBillingServices from './AdminBillingServices';
import AdminBillingSuppliers from './AdminBillingSuppliers';
import AdminBillingTariffs from './AdminBillingTariffs';

interface State {}

class AdminBillingPage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  componentDidMount() {
    adminBillingStore.loadSuppliers();
  }

  render() {
    document.title = 'Биллинг';
    const isMobile = designStore.isMobile;
    const padding = isMobile ? '8.7rem' : '12.2rem';
    return (
      <ScrolledContainer>
        <AdaptiveCard style={{ height: '100%' }}>
          <Tabs tabPosition={'top'}>
            <Tabs.TabPane tab="Услуги" key="1">
              <AdminBillingServices />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Поставщики" key="2">
              <AdminBillingSuppliers />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Банковская выписка" key="3">
              <OperationsList controller={'supplierBanks'} height={`calc(100vh - ${padding})`} />
            </Tabs.TabPane>
            {userStore.userData.roleCode == 'admin' ? (
              <Tabs.TabPane tab="Тарифы" key="4">
                <AdminBillingTariffs />
              </Tabs.TabPane>
            ) : null}
          </Tabs>
        </AdaptiveCard>
      </ScrolledContainer>
    );
  }
}

export default AdminBillingPage;
