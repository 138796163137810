export const getRpsOptions = (rpsList: string[]) =>
  rpsList.map((value) => ({
    label: value,
    value,
  }));

export const getRpsFeatureOptions = (rpsFeatureList: string[]) =>
  rpsFeatureList.map((value) => ({
    label: value,
    value,
  }));

export const getRpsWagonModelOptions = (rpsWagonModelList: string[]) =>
  rpsWagonModelList.map((value) => ({
    label: value,
    value,
  }));
