import HttpClient from '@utils/httpClient';

import { CompanyBankAccountModel, CompanyBankModel } from './BankData';

export class SupplierBankService {
  async getBanks(supplierId: string): Promise<CompanyBankModel[]> {
    return (await HttpClient.get(`/api/supplierBanks`, { params: { supplierId } })).data.map((b) => {
      //ох, опять бэк чудит, приходится id костылить
      b.companyBankReferenceId = b.supplierBankReferenceId;
      return b;
    });
  }

  async getBank(companyBankReferenceId: string): Promise<CompanyBankModel> {
    return (await HttpClient.get(`/api/supplierBanks`, { params: { companyBankReferenceId } })).data;
  }

  async addBank(supplierId: string, data: CompanyBankModel): Promise<void> {
    data.supplierBankReferenceId = data.companyBankReferenceId;
    return (await HttpClient.post(`/api/supplierBanks`, data, { params: { supplierId } })).data;
  }

  async setMainBank(supplierId: string, bank: CompanyBankModel): Promise<void> {
    return (await HttpClient.post(`/api/supplierBanks/${bank.supplierBankReferenceId}/setmain`, null, { params: { supplierId } })).data;
  }

  async updateBank(data: CompanyBankModel): Promise<void> {
    data.supplierBankReferenceId = data.companyBankReferenceId;
    return (await HttpClient.put(`/api/supplierBanks`, data)).data;
  }

  async deleteBank(companyBankRefId: string): Promise<void> {
    return (await HttpClient.delete(`/api/supplierBanks/${companyBankRefId}`)).data;
  }

  async getBankAccount(accountId: string): Promise<CompanyBankAccountModel> {
    return (await HttpClient.get(`/api/supplierBanks/accounts/${accountId}`)).data;
  }

  async addBankAccount(companyBankReferenceId: string, data: CompanyBankAccountModel): Promise<void> {
    return (await HttpClient.post(`/api/supplierBanks/${companyBankReferenceId}/accounts_ext`, data)).data;
  }

  async updateBankAccount(data: CompanyBankAccountModel): Promise<void> {
    return (await HttpClient.put(`/api/supplierBanks/accounts_ext`, data)).data;
  }

  async deleteBankAccount(bankAccountId: string): Promise<void> {
    return (await HttpClient.delete(`/api/supplierBanks/accounts/${bankAccountId}`)).data;
  }

  async setMainAccount(accountId: string): Promise<void> {
    return (await HttpClient.post(`/api/supplierBanks/accounts/${accountId}/setmain`, null)).data;
  }

  async getBankOperations(data: any): Promise<any> {
    return (await HttpClient.post(`/api/supplierBanks/operations`, data)).data;
  }
}

export default new SupplierBankService();
