import { Button } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import packageInfo from 'package.json';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import systemInfoStore from '@stores/systemInfoStore/systemInfoStore';

interface ComponentProps {
  width: string | number;
  collapsed: boolean;
}

interface ComponentState {}

class VersionButton extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    systemInfoStore.getCurrentVersion();
  }

  render() {
    const v = systemInfoStore.currentVersion;
    const collapsed = this.props.collapsed;
    const width = this.props.width;

    if (!v) return null;
    return (
      <div className={'ant-layout-sider-trigger'} style={{ width: collapsed ? '80px' : width }}>
        <div style={{ fontSize: '12' }}>
          <Link to={'/dashboard/about'}>
            <Button type={'link'} size={'small'} style={{ fontSize: collapsed ? '9px' : 'unset', padding: collapsed ? '0.3rem' : 'unset' }}>
              v {v.version} ({packageInfo.version})
            </Button>
          </Link>
        </div>
        <div>{moment(v.updateDate).format(GLOBAL_DATE_FORMAT)}</div>
      </div>
    );
  }
}

export default observer(VersionButton);
