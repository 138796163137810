import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react';
import React from 'react';

import { FieldFloatInputNumber } from '@components/fw/Fields/float-input-number';
import { FieldFloatSelect } from '@components/fw/Fields/float-select';
import { Flex } from '@components/fw/Flex';

import optionsStore from '@stores/optionsStore';

import { filterOptionFn } from '@utils/sort';

import { getAffiliationCodeOptions, getWagonGroupCodeOptions, getWagonTypeCodeOptions } from './basic-tab-wagon-group-fields.lib';
import { BasicTabWagonGroupFieldsProps } from './basic-tab-wagon-group-fields.types';

export const BasicTabWagonGroupFields = observer(({ isShow, form }: BasicTabWagonGroupFieldsProps) => {
  const wagonGroupCode = useWatch('wagonGroupCode', form);

  if (!isShow) {
    return null;
  }

  return (
    <>
      <h4>Группа подвижного состава</h4>
      <Flex gap="var(--gap) 32px" wrap="wrap" justify="space-between">
        <FieldFloatSelect
          name="wagonGroupCode"
          options={getWagonGroupCodeOptions(optionsStore.wagonTypeGroupDictionary)}
          placeholder="Тип"
        />
        <FieldFloatSelect
          showSearch
          optionFilterProp={'children'}
          filterOption={filterOptionFn}
          name="wagonTypeCode"
          options={getWagonTypeCodeOptions(
            optionsStore.wagonTypesDictionary.filter((wagonType) => !wagonGroupCode || wagonType.groupCode === wagonGroupCode),
          )}
          placeholder="Подвижной состав"
        />
        <FieldFloatSelect
          name="affiliationCode"
          options={getAffiliationCodeOptions(optionsStore.wagonAffiliationTypeDictionary)}
          placeholder="Принадлежность"
        />
      </Flex>
      <Flex gap="var(--gap) 32px" wrap="wrap" justify="space-between">
        <FieldFloatInputNumber name="freightWeight" placeholder="Вес груза (т)" step={0.001} min={0.001} decimalSeparator="." />
      </Flex>
    </>
  );
});
