import { Empty } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

class MobileNotSupport extends Component<any> {
  constructor(props) {
    super(props);
  }

  render() {
    const isMobile = designStore.isMobile;
    if (isMobile)
      return <Empty description={'Эта функция работает только на экране компьютера'}>Мы работаем над развитием функционала</Empty>;
    else return this.props.children;
  }
}

export default observer(MobileNotSupport);
