import HttpClient from '@utils/httpClient';

const itineraryService = {
  async itinerary(loadOptions, type: 'wagons' | 'containers'): Promise<any> {
    return (await HttpClient.post(`/api/${type.replace('s', '')}Itinerary/list`, loadOptions)).data;
  },

  async reportToXlsx(
    loadOptions,
    columnOptions,
    type: 'wagons' | 'containers',
    progressFunc: ((e: ProgressEvent) => void) | undefined = undefined,
  ): Promise<Blob> {
    return (
      await HttpClient.post(
        `/api/${type.replace('s', '')}Itinerary/export`,
        { loadOptions, columnOptions },
        {
          onDownloadProgress: progressFunc,
          responseType: 'blob',
        },
      )
    ).data;
  },

  async itineraryShort(loadOptions, type: 'wagons' | 'containers', withCatalogs: boolean): Promise<any> {
    return (await HttpClient.post(`/api/${type.replace('s', '')}Itinerary/listShort`, loadOptions, { params: { withCatalogs } })).data;
  },

  async hold(loadOptions, type: 'wagons' | 'containers'): Promise<any> {
    return (await HttpClient.post(`/api/${type.replace('s', '')}Operation/hold`, loadOptions)).data;
  },

  itineraryById(wagonId: number, type: 'wagons' | 'containers'): Promise<any> {
    return HttpClient.get(`/api/${type.replace('s', '')}Itinerary/list/${wagonId}`).then((resp) => {
      return resp.data;
    });
  },

  operationById(itineraryId, type: 'wagons' | 'containers'): Promise<any> {
    return HttpClient.get(`/api/${type.replace('s', '')}Operation/list`, { params: { itineraryId } }).then((resp) => {
      return resp.data;
    });
  },
};

export default itineraryService;
