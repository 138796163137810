import { Button, Modal, Spin } from 'antd';
import DataGrid, { Column, FilterRow, HeaderFilter, Sorting } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import requestIlsStore from '@stores/requestIlsStore/requestIlsStore';
import { IlsClaimHistoryReport } from '@stores/requestIlsStore/requestIlsStoreData';

interface ComponentProps {
  ilsClaimId: string;
}

interface ComponentState {
  data: IlsClaimHistoryReport[];
  loading: boolean;
  isOpen: boolean;
}

class IlsClaimHistory extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      isOpen: false,
    };
  }

  componentDidMount() {}
  onOpen = () => {
    this.setState({ isOpen: true, loading: true });
    requestIlsStore
      .history(this.props.ilsClaimId)
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => this.setState({ loading: false }));
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <Button type={'text'} onClick={this.onOpen}>
          История
        </Button>
        <Modal
          width={'700px'}
          title={'История заявки'}
          cancelText={'Закрыть'}
          okButtonProps={{ style: { display: 'none' } }}
          centered={true}
          open={this.state.isOpen}
          onCancel={this.onClose}>
          <Spin spinning={this.state.loading}>
            <DataGrid
              dataSource={this.state.data}
              showBorders={true}
              allowColumnReordering={true}
              height={'100%'}
              width={'100%'}
              rowAlternationEnabled={true}
              remoteOperations={false}
              showRowLines={true}
              allowColumnResizing={true}
              columnResizingMode={'nextColumn'}
              columnAutoWidth={true}
              columnMinWidth={20}
              wordWrapEnabled={true}
              paging={{ enabled: false }}>
              <HeaderFilter visible={true} allowSearch={true} />
              <FilterRow visible={true} />
              <Sorting mode="multiple" />
              {INDEX_COLUMN}

              <Column caption="Дата" dataField="date" dataType={'datetime'} />
              <Column caption="Пользователь" dataField="userName" dataType={'string'} />
              <Column caption="Тип изменения" dataField="changeTypeName" dataType={'string'} />
              <Column
                caption="Статус"
                dataField="claimJson"
                dataType={'string'}
                cellRender={(e) => {
                  if (e.data && e.data.claimJson && e.data.claimJson.startsWith('{')) {
                    const claim = JSON.parse(e.data.claimJson);
                    return claim.StatusName;
                  }
                  return '';
                }}
              />
            </DataGrid>
          </Spin>
        </Modal>
      </>
    );
  }
}

export default observer(IlsClaimHistory);
