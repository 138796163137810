import { TemplateOrgContractModel } from '@stores/organizationsStore/organizationsStoreData';
import { UserModel } from '@stores/usersStore/usersStore';

export const getExecutorOptions = (users: UserModel[]) =>
  users.map(({ name: label, userId: value }) => ({
    label,
    value,
  }));

export const getContractOptions = (contractList: TemplateOrgContractModel[]) =>
  contractList
    .map(({ contractNumber: label, templateMetadataId: value, createDate }) => ({
      label: `${label}, ${getDateFromDateTimeString(createDate)}`,
      value,
    }))
    .concat([{ label: 'Без договора', value: null }]);

const getDateFromDateTimeString = (dateString: string) => new Date(dateString).toLocaleDateString();
