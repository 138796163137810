import { DeleteOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';

import PdfViewModal from '@components/fw/DocumentViewer/PdfViewModal';
import { Flex } from '@components/fw/Flex';

import { BidRegistryDocumentStore } from '@stores/bidRegistryDocumentStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';

export const GetOffer = () => {
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [previewBlob, setPreviewBlob] = useState<Blob>();

  const onDownload = async () => {
    const blob = await BidRegistryDocumentStore.getOffer(registerOfBetsStore.bidRegistryId);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${'коммерческое_предложение.docx'}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const onShowPreview = async () => {
    const blob = await BidRegistryDocumentStore.getOffer(registerOfBetsStore.bidRegistryId);
    setPreviewBlob(blob);
    setIsShowPreview(true);
  };

  return (
    <>
      <Flex gap="20px" align="center">
        <Button size={'small'} type={'link'} icon={<DownloadOutlined />} onClick={onDownload}>
          Скачать
        </Button>
        <Button size={'small'} type={'link'} icon={<EyeOutlined />} onClick={onShowPreview}>
          Посмотреть
        </Button>
        <Button
          key="4"
          icon={<DeleteOutlined />}
          shape="round"
          danger
          onClick={() => BidRegistryDocumentStore.removeOffer(registerOfBetsStore.bidRegistryId)}>
          Удалить
        </Button>
      </Flex>
      <PdfViewModal data={previewBlob} type={'docx'} visible={isShowPreview} onClose={() => setIsShowPreview(false)} />
    </>
  );
};
