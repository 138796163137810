import { action, computed, makeObservable, observable } from 'mobx';

import service from './claimCommentsStore.service';

export type CommentModel = {
  commentId: number;
  userId: string;
  userName: string;
  comment: string;
  position: string;
  createDate: string;
};

class ClaimCommentsStore {
  @observable isBusy: boolean = false;
  @observable isBusyConfirm: boolean = false;
  @observable private _claimComments: CommentModel[];
  @observable private _sendComments: CommentModel[];

  constructor() {
    makeObservable(this);
  }

  /*
   * комментарии заявки
   *  */
  @action getClaimComments(claimId: string, clear: boolean = true) {
    this.isBusy = true;
    if (clear) {
      this._claimComments = [];
    }
    service
      .getClaimComments(claimId)
      .finally(() => (this.isBusy = false))
      .then((result: CommentModel[]) => {
        this._claimComments = result;
      });
  }

  /*
   * комментарии отправки
   *  */
  @action getSendComments(sendId: string, clear: boolean = true) {
    this.isBusy = true;
    if (clear) {
      this._sendComments = [];
    }
    service
      .getSendComments(sendId)
      .finally(() => (this.isBusy = false))
      .then((result: CommentModel[]) => {
        this._sendComments = result;
      });
  }

  /* добавление комментария заявки */
  @action addClaimComment(comment: any) {
    this.isBusyConfirm = true;
    service
      .addClaimComment(comment)
      .finally(() => (this.isBusyConfirm = false))
      .then(() => {
        this.getClaimComments(comment.claimId, false);
      });
  }

  /* добавление комментария отправки */
  @action addSendComment(comment: any) {
    this.isBusyConfirm = true;
    service
      .addSendComment(comment)
      .finally(() => (this.isBusyConfirm = false))
      .then(() => {
        this.getSendComments(comment.sendId, false);
      });
  }

  /* удаление комментария заявки */
  @action deleteClaimComment(comment: CommentModel, claimId: string) {
    this.isBusy = true;
    service
      .deleteClaimComment(comment.commentId)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getClaimComments(claimId, false);
      });
  }

  /* удаление комментария отправки */
  @action deleteSendComment(comment: CommentModel, sendId: string) {
    this.isBusy = true;
    service
      .deleteSendComment(comment.commentId)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getSendComments(sendId, false);
      });
  }

  @computed get claimComments() {
    if (!this._claimComments) {
      return [];
    }
    return [...this._claimComments];
  }

  @computed get sendComments() {
    if (!this._sendComments) {
      return [];
    }
    return [...this._sendComments];
  }
}

export default new ClaimCommentsStore();
