import { Empty, Popover, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import notificationStore, { NotificationEntry } from '@stores/notification/notificationStore';
import { GLOBAL_DATETIME_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface State {
  isBusy: boolean;
  data: NotificationEntry;
}

interface Props {
  data: { TemplateCode: string; TemplateName: string };
  type: string;
  companyId: string | null;
  numberOrClaimId: string;
}

class NotificationPopover extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: true,
      data: null,
    };
  }

  render() {
    const isBusy = this.state.isBusy;
    const data = this.state.data;
    return (
      <>
        <Popover
          trigger="hover"
          onVisibleChange={(e) => this.showMe(e)}
          title={this.props.data.TemplateName}
          content={
            <Spin spinning={isBusy}>
              {data ? (
                <>
                  <Typography.Text mark>{data?.message}</Typography.Text>
                  <br />
                  Создано: <Typography.Text>{data ? moment(data?.createDate).format(GLOBAL_DATETIME_FORMAT) : ''}</Typography.Text>
                </>
              ) : !isBusy ? (
                <Empty />
              ) : null}
            </Spin>
          }>
          <span style={{ cursor: 'pointer' }}>{this.props.data.TemplateName}</span>
        </Popover>
      </>
    );
  }

  showMe = (e) => {
    if (!e) return;
    this.setState({ isBusy: true });
    notificationStore
      .getDescription(this.props.type, this.props.numberOrClaimId, this.props.data.TemplateCode, this.props.companyId)
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => {
        this.setState({ isBusy: false });
      });
  };
}

export default NotificationPopover;
