import { Button, Checkbox, Col, Form, Modal, Row, Spin, Typography } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatDatePicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import MarkSelect from '@components/fw/MarkSelect';
import RemoteSelect from '@components/fw/RemoteSelect';

import dictService from '@stores/dictStore/dictStore.service';
import {
  CompanyLoadRegistryListUpdateModel,
  RateType,
  Vat,
  rateTypeOptions,
  vatOptions,
  vatToNumber,
} from '@stores/loadRegistryStore/loadRegistryData';
import loadRegistryStore from '@stores/loadRegistryStore/loadRegistryStore';
import loadRegistryStoreService from '@stores/loadRegistryStore/loadRegistryStore.service';
import markStore from '@stores/markStore';
import organizationsStore from '@stores/organizationsStore';
import userStore from '@stores/userStore';

import { filterOptionFn } from '@utils/sort';

interface LoadingRegisterFormProps {
  onFinish(): void;
  onCancel(): void;
}

interface LoadingRegisterFormState {
  data: CompanyLoadRegistryListUpdateModel;
  loading: boolean;
}

@observer
class LoadingRegisterForm extends Component<LoadingRegisterFormProps, LoadingRegisterFormState> {
  formRef: FormInstance;

  constructor(props: LoadingRegisterFormProps) {
    super(props);
    this.state = {
      data: new CompanyLoadRegistryListUpdateModel(loadRegistryStore.edit),
      loading: false,
    };
  }

  get isListEdit() {
    return loadRegistryStore.edit.length > 1;
  }

  onFinish = (e: CompanyLoadRegistryListUpdateModel) => {
    this.setState({ loading: true });
    e.netto = e.netto * 1000;
    e.loadRegistryIds = this.state.data.loadRegistryIds;

    loadRegistryStoreService
      .updateListCompanyLoadRegistry(e)
      .then(() => {
        this.props.onFinish();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount(): void {
    markStore.getNotes(userStore.userData.companyId);
  }

  onChangeNetto = (value: any) => {
    if (!this.formRef) return;

    const rate = Number(this.formRef.getFieldValue('rate') ?? 0);
    const rateType = this.formRef.getFieldValue('rateType') as RateType;

    const supplierRate = Number(this.formRef.getFieldValue('supplierRate') ?? 0);
    const supplierRateType = this.formRef.getFieldValue('supplierRateType') as RateType;

    if (rateType === RateType.Ton) this.onChangeRate(value === null ? null : rate);
    if (supplierRateType === RateType.Ton) this.onChangeSupplierRate(value === null ? null : supplierRate);
  };

  //#region Handlers Финансы клиента
  onChangeRate = (value: any) => {
    if (!this.formRef) return;

    const newValue = Number(value > 0 ? value.toFixed(2) : 0);
    const rateType = this.formRef.getFieldValue('rateType') as RateType;
    const netto = Number(Number(this.formRef.getFieldValue('netto') ?? 0).toFixed(2));

    if (value !== null) {
      this.formRef.setFieldValue('rate', newValue);
    }

    if (!rateType) return;

    switch (rateType) {
      case RateType.Ton:
        this.onChangeServicesAmount(netto * newValue);
        break;
      case RateType.Wagon:
        this.onChangeServicesAmount(newValue);
        break;
    }
  };

  onChangeRateType = () => {
    if (!this.formRef) return;

    this.onChangeRate(this.formRef.getFieldValue('rate'));
  };

  onChangeServicesAmount = (value: any) => {
    if (!this.formRef) return;

    const newValue = Number(value > 0 ? value.toFixed(2) : 0);
    if (value !== null) {
      this.formRef.setFieldValue('servicesAmount', newValue);
    }

    this.onChangeVatRate();
  };

  onChangeVatRate = () => {
    if (!this.formRef) return;

    const vatRate = this.formRef.getFieldValue('vatRate');
    const servicesAmount = Number(this.formRef.getFieldValue('servicesAmount') ?? 0);

    if (!servicesAmount) {
      this.formRef.setFieldValue('vatAmount', 0);
      this.formRef.setFieldValue('servicesAmountWithVat', 0);
      return;
    }

    const vatAmount = vatRate === Vat.Without || vatRate === Vat.Vat0 ? 0 : servicesAmount * (vatToNumber(vatRate) / 100.0);
    const servicesAmountWithVat = servicesAmount + vatAmount;

    this.formRef.setFieldValue('vatAmount', vatAmount.toFixed(2));
    this.formRef.setFieldValue('servicesAmountWithVat', servicesAmountWithVat.toFixed(2));
  };

  onChangeVatAmount = (value: any) => {
    if (!this.formRef) return;

    const servicesAmount = Number(this.formRef.getFieldValue('servicesAmount'));
    const newValue = Number(value > 0 ? value.toFixed(2) : 0);
    if (value !== null) {
      this.formRef.setFieldValue('vatAmount', newValue);
    }

    this.formRef.setFieldValue('servicesAmountWithVat', (servicesAmount + newValue).toFixed(2));
  };
  //#endregion

  //#region Handlers Финансы поставщика
  onChangeSupplierRate = (value: any) => {
    if (!this.formRef) return;

    const newValue = Number(value > 0 ? value.toFixed(2) : 0);
    const rateType = this.formRef.getFieldValue('supplierRateType') as RateType;
    const netto = Number(Number(this.formRef.getFieldValue('netto') ?? 0).toFixed(2));

    if (value !== null) {
      this.formRef.setFieldValue('supplierRate', newValue);
    }

    if (!rateType) return;

    switch (rateType) {
      case RateType.Ton:
        this.onChangeSupplierServicesAmount(netto * newValue);
        break;
      case RateType.Wagon:
        this.onChangeSupplierServicesAmount(newValue);
        break;
    }
  };

  onChangeSupplierRateType = () => {
    if (!this.formRef) return;

    this.onChangeSupplierRate(this.formRef.getFieldValue('supplierRate'));
  };

  onChangeSupplierServicesAmount = (value: any) => {
    if (!this.formRef) return;

    const newValue = Number(value > 0 ? value.toFixed(2) : 0);
    if (value !== null) {
      this.formRef.setFieldValue('supplierServicesAmount', newValue);
    }

    this.onChangeSupplierVatRate();
  };

  onChangeSupplierVatRate = () => {
    if (!this.formRef) return;

    const vatRate = this.formRef.getFieldValue('supplierVatRate');
    const servicesAmount = Number(this.formRef.getFieldValue('supplierServicesAmount') ?? 0);

    if (!servicesAmount) {
      this.formRef.setFieldValue('supplierVatAmount', 0);
      this.formRef.setFieldValue('supplierServicesAmountWithVat', 0);
      return;
    }

    const vatAmount = vatRate === Vat.Without || vatRate === Vat.Vat0 ? 0 : servicesAmount * (vatToNumber(vatRate) / 100.0);
    const servicesAmountWithVat = servicesAmount + vatAmount;

    this.formRef.setFieldValue('supplierVatAmount', vatAmount.toFixed(2));
    this.formRef.setFieldValue('supplierServicesAmountWithVat', servicesAmountWithVat.toFixed(2));
  };

  onChangeSupplierVatAmount = (value: any) => {
    if (!this.formRef) return;

    const servicesAmount = Number(this.formRef.getFieldValue('supplierServicesAmount'));
    const newValue = Number(value > 0 ? value.toFixed(2) : 0);
    if (value !== null) {
      this.formRef.setFieldValue('supplierVatAmount', newValue);
    }

    this.formRef.setFieldValue('supplierServicesAmountWithVat', (servicesAmount + newValue).toFixed(2));
  };

  //#endregion

  render() {
    const { onCancel } = this.props;
    const isListEdit = this.isListEdit;
    const data = this.state.data;
    const options = organizationsStore.organizations.map((organization) => {
      return { label: organization.name, value: organization.organizationId };
    });

    const changeCheckbox = (name: string, label: string) => {
      return (
        <Form.Item style={{ display: isListEdit ? null : 'none' }} name={name} valuePropName={'checked'}>
          <Checkbox onChange={() => this.forceUpdate()}>{label}</Checkbox>
        </Form.Item>
      );
    };

    return (
      <Modal
        centered={true}
        open={true}
        onCancel={onCancel}
        footer={null}
        title={`Редактирование ${this.isListEdit ? 'группы' : ''}`}
        width={1150}
        style={{ maxWidth: '100%' }}>
        <Spin spinning={this.state.loading}>
          <Form
            // form={this.formRef}
            ref={(ref) => (this.formRef = ref)}
            style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
            layout="vertical"
            initialValues={{
              ...data,
              netto: +data.netto / 1000,
              sendDate: data.sendDate ? moment(data.sendDate) : null,
            }}
            onFinish={this.onFinish}>
            <Row className={'loading-register--form-row'} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className={'loading-register--form-row--column'} xs={24} sm={24} md={8} lg={8}>
                <Typography.Title level={5}>Дислокация</Typography.Title>

                {changeCheckbox('changeSendNumber', 'Изменить номер накладной')}
                <Form.Item name={'sendNumber'}>
                  <FloatInput disabled={this.formRef && !this.formRef.getFieldValue('changeSendNumber')} placeholder={'Номер накладной'} />
                </Form.Item>

                {changeCheckbox('changeSendDate', 'Изменить дату погрузки')}
                <Form.Item name={'sendDate'}>
                  <FloatDatePicker disabled={this.formRef && !this.formRef.getFieldValue('changeSendDate')} placeholder={'Дата погрузки'} />
                </Form.Item>

                {changeCheckbox('changeNetto', 'Изменить Вес груза')}
                <Form.Item name={'netto'}>
                  <FloatInputNumber
                    disabled={this.formRef && !this.formRef.getFieldValue('changeNetto')}
                    placeholder={'Вес груза'}
                    onChange={this.onChangeNetto}
                  />
                </Form.Item>

                {changeCheckbox('changeDontShowClientInReport', 'Изменить')}
                <Form.Item name={'dontShowClientInReport'} valuePropName={'checked'}>
                  <FloatCheckbox disabled={this.formRef && !this.formRef.getFieldValue('changeDontShowClientInReport')}>
                    Скрыть клиента в отчете
                  </FloatCheckbox>
                </Form.Item>

                {changeCheckbox('changeLoadStationCode', 'Изменить станцию погрузки')}
                <Form.Item
                  rules={[{ required: this.formRef && this.formRef.getFieldValue('changeLoadStationCode') }]}
                  name={'loadStationCode'}>
                  <RemoteSelect
                    float={true}
                    disabled={this.formRef && !this.formRef.getFieldValue('changeLoadStationCode')}
                    autoInitDict={true}
                    primitiveResult={true}
                    placeholder={'Станция погрузки'}
                    valueExpr={'stationCode'}
                    displayExpr={'fullName'}
                    dataSource={dictService.stationDict}
                    itemTemplate={(s) => (
                      <span>
                        {' '}
                        {s.stationCode} {s.fullName}{' '}
                      </span>
                    )}
                  />
                </Form.Item>

                {changeCheckbox('changeDestStationCode', 'Изменить станцию выгрузки')}
                <Form.Item
                  rules={[{ required: this.formRef && this.formRef.getFieldValue('changeDestStationCode') }]}
                  name={'destStationCode'}>
                  <RemoteSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeDestStationCode')}
                    float={true}
                    autoInitDict={true}
                    primitiveResult={true}
                    placeholder={'Станция выгрузки'}
                    valueExpr={'stationCode'}
                    displayExpr={'fullName'}
                    dataSource={dictService.stationDict}
                    itemTemplate={(s) => (
                      <span>
                        {' '}
                        {s.stationCode} {s.fullName}{' '}
                      </span>
                    )}
                  />
                </Form.Item>

                {changeCheckbox('changeEtsngCode', 'Изменить груз')}
                <Form.Item rules={[{ required: this.formRef && this.formRef.getFieldValue('changeEtsngCode') }]} name={'etsngCode'}>
                  <RemoteSelect
                    float={true}
                    disabled={this.formRef && !this.formRef.getFieldValue('changeEtsngCode')}
                    primitiveResult={true}
                    autoInitDict={true}
                    placeholder={'Наименование или код ЕТСНГ'}
                    valueExpr={'freightEtsngCode'}
                    displayExpr={'fullName'}
                    aValueExpr={null}
                    dataSource={dictService.freightEtsngDict}
                    itemTemplate={(item) => (
                      <div style={{ whiteSpace: 'normal', height: 'auto' }}>
                        <span style={{ color: 'lightGray' }}>{item['freightEtsngCode']} </span>
                        {item['fullName']}
                      </div>
                    )}
                  />
                </Form.Item>

                {changeCheckbox('changeNotes', 'Изменить поставщика')}
                <Form.Item name={'notes3'}>
                  <MarkSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeNotes3')}
                    placeholder={'Поставщик'}
                    options={markStore.notes}
                  />
                </Form.Item>
                {changeCheckbox('changeNotes1', 'Изменить метки 1')}
                <Form.Item name={'notes1'}>
                  <MarkSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeNotes1')}
                    placeholder={'Метки 1'}
                    options={markStore.notes}
                  />
                </Form.Item>
                {changeCheckbox('changeNotes2', 'Изменить метки 2')}
                <Form.Item name={'notes2'}>
                  <MarkSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeNotes2')}
                    placeholder={'Метки 2'}
                    options={markStore.notes}
                  />
                </Form.Item>
              </Col>

              <Col className={'loading-register--form-row--column'} xs={24} sm={24} md={8} lg={8}>
                <Typography.Title level={5}>Клиент</Typography.Title>

                {changeCheckbox('changeClientId', 'Изменить клиента')}
                <Form.Item name={'clientId'}>
                  <FloatSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeClientId')}
                    placeholder={'Выберите клиента'}
                    options={options}
                    showSearch={true}
                    filterOption={filterOptionFn}
                  />
                </Form.Item>

                {changeCheckbox('changeRateType', 'Изменить тип ставки')}
                <Form.Item name={'rateType'}>
                  <FloatSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeRateType')}
                    placeholder={'Ставка за'}
                    options={rateTypeOptions}
                    onChange={this.onChangeRateType}
                  />
                </Form.Item>

                {changeCheckbox('changeRate', 'Изменить ставку')}
                <Form.Item name={'rate'}>
                  <FloatInputNumber
                    disabled={this.formRef && !this.formRef.getFieldValue('changeRate')}
                    placeholder={'Ставка'}
                    type={'number'}
                    onChange={this.onChangeRate}
                  />
                </Form.Item>

                {changeCheckbox('changeServicesAmount', 'Изменить стоимость услуг')}
                <Form.Item name={'servicesAmount'}>
                  <FloatInputNumber
                    disabled={this.formRef && !this.formRef.getFieldValue('changeServicesAmount')}
                    placeholder={'Стоимость услуг'}
                    onChange={this.onChangeServicesAmount}
                    type={'number'}
                  />
                </Form.Item>

                {changeCheckbox('changeVatRate', 'Ставка НДС')}
                <Form.Item name={'vatRate'}>
                  <FloatSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeVatRate')}
                    placeholder={'Ставка НДС'}
                    options={vatOptions}
                    onChange={this.onChangeVatRate}
                  />
                </Form.Item>

                {changeCheckbox('changeVatAmount', 'Сумма НДС')}
                <Form.Item name={'vatAmount'}>
                  <FloatInputNumber
                    disabled={this.formRef && !this.formRef.getFieldValue('changeVatAmount')}
                    placeholder={'Сумма НДС'}
                    type={'number'}
                    onChange={this.onChangeVatAmount}
                  />
                </Form.Item>

                {changeCheckbox('changeServicesAmountWithVat', 'Стоимость услуг с НДС')}
                <Form.Item name={'servicesAmountWithVat'}>
                  <FloatInputNumber
                    disabled={this.formRef && !this.formRef.getFieldValue('changeServicesAmountWithVat')}
                    placeholder={'Стоимость услуг с НДС'}
                    type={'number'}
                  />
                </Form.Item>
              </Col>

              <Col className={'loading-register--form-row--column'} xs={24} sm={24} md={8} lg={8}>
                <Typography.Title level={5}>Поставщик</Typography.Title>

                {changeCheckbox('changeSupplierId', 'Изменить поставщика')}
                <Form.Item name={'supplierId'}>
                  <FloatSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeSupplierId')}
                    placeholder={'Выберите поставщика'}
                    options={options}
                    showSearch={true}
                    filterOption={filterOptionFn}
                  />
                </Form.Item>

                {changeCheckbox('changeSupplierRateType', 'Изменить тип ставки')}
                <Form.Item name={'supplierRateType'}>
                  <FloatSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeSupplierRateType')}
                    placeholder={'Ставка за'}
                    options={rateTypeOptions}
                    onChange={this.onChangeSupplierRateType}
                  />
                </Form.Item>

                {changeCheckbox('changeSupplierRate', 'Изменить ставку Поставщика')}
                <Form.Item name={'supplierRate'}>
                  <FloatInputNumber
                    disabled={this.formRef && !this.formRef.getFieldValue('changeSupplierRate')}
                    placeholder={'Ставка Поставщика'}
                    type={'number'}
                    onChange={this.onChangeSupplierRate}
                  />
                </Form.Item>

                {changeCheckbox('changeSupplierServicesAmount', 'Изменить стоимость услуг Поставщика')}
                <Form.Item name={'supplierServicesAmount'}>
                  <FloatInputNumber
                    disabled={this.formRef && !this.formRef.getFieldValue('changeSupplierServicesAmount')}
                    placeholder={'Стоимость услуг Поставщика'}
                    onChange={this.onChangeSupplierServicesAmount}
                    type={'number'}
                  />
                </Form.Item>

                {changeCheckbox('changeSupplierVatRate', 'Ставка НДС Поставщика')}
                <Form.Item name={'supplierVatRate'}>
                  <FloatSelect
                    disabled={this.formRef && !this.formRef.getFieldValue('changeSupplierVatRate')}
                    placeholder={'Ставка НДС Поставщика'}
                    options={vatOptions}
                    onChange={this.onChangeSupplierVatRate}
                  />
                </Form.Item>

                {changeCheckbox('changeSupplierVatAmount', 'Сумма НДС Поставщика')}
                <Form.Item name={'supplierVatAmount'}>
                  <FloatInputNumber
                    disabled={this.formRef && !this.formRef.getFieldValue('changeSupplierVatAmount')}
                    placeholder={'Сумма НДС Поставщика'}
                    type={'number'}
                    onChange={this.onChangeSupplierVatAmount}
                  />
                </Form.Item>

                {changeCheckbox('changeSupplierServicesAmountWithVat', 'Стоимость услуг Поставщика с НДС')}
                <Form.Item name={'supplierServicesAmountWithVat'}>
                  <FloatInputNumber
                    disabled={this.formRef && !this.formRef.getFieldValue('changeSupplierServicesAmountWithVat')}
                    placeholder={'Стоимость услуг Поставщика с НДС'}
                    type={'number'}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ marginLeft: 'auto' }}>
              <Button htmlType="submit" style={{ marginRight: 10 }}>
                Сохранить
              </Button>
              <Button htmlType="button" onClick={onCancel} danger>
                Отменить
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default LoadingRegisterForm;
