import { DeleteOutlined, DownOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { FinanceDocModel, InfologFinanceModel, mimes } from '@stores/financeStore/FinanceData';
import financeStoreService from '@stores/financeStore/financeStore.service';

import { showSuccessNotify } from '@utils/notify';

interface ComponentProps {
  data: InfologFinanceModel;
}

interface ComponentState {
  docs: FinanceDocModel[];
  loadingDoc: boolean;
  loadingAttachment: boolean;
}

@observer
class InfologFinanceDocumentsMenu extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      loadingDoc: false,
      loadingAttachment: false,
      docs: [],
    };
  }

  loadDocsMetadata = () => {
    this.setState({ loadingAttachment: true });
    financeStoreService
      .getFinanceDocList(this.props.data.financeId)
      .then((docs) => {
        this.setState({ docs: docs });
      })
      .finally(() => this.setState({ loadingAttachment: false }));
  };

  onUploadAttachment = (e) => {
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, application/pdf';

    input.onchange = (e) => {
      //@ts-ignore
      var file = e.path[0].files[0];
      let fm = new FormData();
      fm.append('file', file);

      this.setState({ loadingAttachment: true });
      financeStoreService
        .addFinanceDoc(fm, this.props.data.financeId, this.props.data.accountNumber)
        .then((ans) => {
          showSuccessNotify('', 'Файл загружен');
          this.loadDocsMetadata();
        })
        .finally(() => this.setState({ loadingAttachment: false }));
    };
    input.click();
  };

  onDeleteAttachment = (e: FinanceDocModel) => {
    this.setState({ loadingAttachment: true });
    e.accountNumber = this.props.data.accountNumber;
    financeStoreService
      .deleteFinanceDoc(e)
      .finally(() => this.setState({ loadingAttachment: false }))
      .then((result: any) => {
        showSuccessNotify('', 'Файл удален');
        this.loadDocsMetadata();
      });
  };

  onDownloadAttachment = (e: FinanceDocModel) => {
    this.setState({ loadingAttachment: true });
    financeStoreService.getFinanceDoc(e.docId).then((data) => {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      const type = mimes[e.contentType];
      link.setAttribute('download', `${e.name}.${type}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  render() {
    return (
      <>
        <Dropdown
          onVisibleChange={(e) => {
            if (e) this.loadDocsMetadata();
          }}
          overlay={
            <Menu>
              <Menu.Item key="3" icon={<UploadOutlined />} onClick={this.onUploadAttachment}>
                Загрузить документ
              </Menu.Item>
              {this.state.docs.map((d) => (
                <Menu.SubMenu title={d.name} key={d.docId}>
                  <Menu.Item key="1" icon={<DownloadOutlined />} onClick={() => this.onDownloadAttachment(d)}>
                    Скачать
                  </Menu.Item>
                  <Menu.Item key="5" icon={<DeleteOutlined />} onClick={() => this.onDeleteAttachment(d)}>
                    Удалить
                  </Menu.Item>
                </Menu.SubMenu>
              ))}
            </Menu>
          }>
          <Button size={'small'} type={'text'}>
            Вложения <DownOutlined />
          </Button>
        </Dropdown>
      </>
    );
  }
}

export default InfologFinanceDocumentsMenu;
