import DataGrid, {
  Column,
  Editing,
  Export,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  StateStoring,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';
import { NsiField } from '@stores/nsiStore/nsiData';
import userStore from '@stores/userStore/userStore';

interface State {
  dataSource: DataSource;
}
interface Props {
  data: NsiField[];
  store: any;
}

@observer
class NsiEditor extends Component<Props, State> {
  dataGrid: DataGrid;
  constructor(props: Props) {
    super(props);
    this.state = {
      dataSource: this.getDs,
    };
  }

  componentDidMount() {}

  get getDs() {
    const store = this.props.store;
    const data = this.props.data;
    const keyField = data[0].dataField;
    return new DataSource({
      store: new CustomStore({
        key: keyField,
        load: function (loadOptions) {
          return store
            .load(loadOptions)
            .then((ans) => {
              return ans;
            })
            .catch(() => {
              throw 'Ошибка загрузки данных';
            });
        },
        insert: function (values) {
          throw 'Unimplemented';
        },
        update: function (key, values) {
          return store.update(key, values, keyField);
        },
        remove: function (key) {
          throw 'Unimplemented';
        },
      }),
    });
  }

  render() {
    const isMobile = designStore.isMobile;
    const padding = isMobile ? '8.7rem' : '12.2rem';

    return (
      <DataGrid
        allowColumnReordering={true}
        dataSource={this.state.dataSource}
        ref={(ref) => (this.dataGrid = ref)}
        height={`calc(100vh - ${padding})`}
        //onInitNewRow={this.onInitNewRow}
        remoteOperations={true}
        allowColumnResizing={true}
        showBorders={true}
        columnResizingMode={'widget'}
        columnAutoWidth={true}
        showColumnLines={true}
        columnMinWidth={20}
        showRowLines={true}
        wordWrapEnabled={true}
        onRowPrepared={this.onRowPrepared}
        //onToolbarPreparing={this.onToolbarPreparing}
        //onExporting={this.onExporting}
      >
        <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'nsi-stations-editor'} />
        <Editing mode="cell" allowUpdating={true} allowAdding={false} allowDeleting={false} useIcons={true} />
        <Paging enabled={true} defaultPageSize={25} />
        <Pager
          allowedPageSizes={[25, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
          infoText={'Всего: {2}'}
        />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Export enabled={true} excelWrapTextEnabled={true} />
        <FilterRow visible={true} />
        {this.props.data.map((d) => (
          <Column
            allowFixing={true}
            headerFilter={d.headerFilter}
            dataField={d.dataField}
            caption={d.caption}
            visible={d.visible}
            allowEditing={d.editable}
            dataType={d.dataType}>
            {/*<RequiredRule />*/}
          </Column>
        ))}
      </DataGrid>
    );
  }

  onRowPrepared = (e) => {
    if (e.data && e.data.current === false) {
      e.rowElement.style.color = '#9b9ba1';
    }
  };
}

export default NsiEditor;
