import { action, computed, makeObservable, observable } from 'mobx';

import {
  SendGeoInfo,
  SendLimitationsModel,
  SendModel,
  SendSupplyScheduleModel,
  TariffAggregationModel,
  TariffCountryDetailModel,
  WagonAutoSelectInfo,
} from './sendData';
import service from './sendsStore.service';

class SendsStore {
  @observable isBusy: boolean = false;
  @observable isBusySendSchedules: boolean = false;
  @observable isBusySendTariffDetail: boolean = false;
  @observable private _sends: SendModel[];
  @observable sendLimitation: any;
  @observable sendSchedules: SendSupplyScheduleModel[];
  @observable sendTariffDetail: TariffCountryDetailModel[];
  @observable sendTariffDetailRequest: SendModel;
  @observable geoInfo: SendGeoInfo;
  @observable wagonNumbers: number[] = [];

  constructor() {
    makeObservable(this);
  }

  @action getSends() {
    this.isBusy = true;
    service
      .getSends()
      .finally(() => (this.isBusy = false))
      .then((result: any[]) => {
        this._sends = result;
      });
  }

  @action getSendSchedules(sendId: string) {
    this.isBusySendSchedules = true;
    service
      .getSendSchedules(sendId)
      .finally(() => (this.isBusySendSchedules = false))
      .then((result: SendSupplyScheduleModel[]) => {
        this.sendSchedules = result;
      });
  }

  /* отправка по ID */
  @action async getSend(sendId: string) {
    return await service.getSend(sendId);
  }

  /* создание отправки */
  @action async createSend(data: any) {
    return await service.create(data);
  }

  /* сохранение отправки */
  @action async updateSend(data: any) {
    return await service.update(data);
  }

  /* удаление отправки */
  @action async deleteSend(sendId: string) {
    return await service.delete(sendId);
  }

  /* отправки заявки */
  @action async getLimitation(sendId: string, scheduleId: string): Promise<SendLimitationsModel> {
    return await service.limitations(sendId, scheduleId);
  }

  /* создание графика подач */
  @action async createSchedule(data: any) {
    return (await service.createSchedule(data)).data;
  }

  /* сохранение графика подач */
  @action async updateSchedule(data: any) {
    return (await service.updateSchedule(data)).data;
  }

  /* смена статуса */
  @action async setStatus(sendId: string, statusCode: string) {
    return await service.setStatus(sendId, statusCode);
  }

  /* удаление грфика подач */
  @action async deleteSchedule(scheduleId: string) {
    return await service.deleteSchedule(scheduleId);
  }

  /* вагоны для грфика подач */
  @action async wagonsForScheduler() {
    this.wagonNumbers = await service.getWagonsForScheduler();
    return this.wagonNumbers;
  }

  /* автоподбор вагонов */
  @action async wagonsAutoSelection(etsngCode: string, loadStationCode: string): Promise<WagonAutoSelectInfo[]> {
    return await service.wagonsAutoSelection(etsngCode, loadStationCode);
  }

  /* автоподбор вагонов */
  @action async wagonsAutoSelectionByRps(
    rps: string,
    loadStationCode: number,
    radius: number,
    daysBeforeRepair: number,
  ): Promise<WagonAutoSelectInfo[]> {
    return await service.wagonsAutoSelectionByRps(rps, loadStationCode, radius, daysBeforeRepair);
  }

  /* расчет тарифа для подобраных */
  @action async tariffForAutoSelected(data): Promise<TariffAggregationModel> {
    return await service.tariffForAutoSelected(data);
  }

  /* сформировать график из подобранных */
  @action async sendAutoSelected(data: WagonAutoSelectInfo[], sendId: string): Promise<void> {
    return await service.sendAutoSelected(data, sendId);
  }

  @action async loadGeoInfo(loadStationCode: number): Promise<SendGeoInfo> {
    this.geoInfo = null;
    return await service.geoInfo(loadStationCode).then((ans) => {
      this.geoInfo = ans;
      return ans;
    });
  }

  @computed get sends() {
    if (!this._sends) {
      return [];
    }
    return [...this._sends];
  }

  @action async getSendTariffDetail(sendModel: SendModel) {
    this.isBusySendTariffDetail = true;
    await service
      .getSendTariffDetail(sendModel)
      .finally(() => (this.isBusySendTariffDetail = false))
      .then((result) => {
        this.sendTariffDetailRequest = sendModel;
        this.sendTariffDetail = result;
      });
  }

  @computed get tariffPrice() {
    let result = 0;
    this.sendTariffDetail.forEach((t) => {
      result += t.tariffCostRu;
    });
    return this.sendTariffDetailRequest && this.sendTariffDetailRequest.wagonCount
      ? result * this.sendTariffDetailRequest.wagonCount
      : result;
  }
  @computed get tariffDistance() {
    let result = 0;
    this.sendTariffDetail.forEach((t) => {
      result += t.tariffDistance;
    });
    return result;
  }

  @computed get sendsForBoard(): { creat; onper; done } {
    if (!this._sends) {
      return { creat: [], onper: [], done: [] };
    }
    const creat = [...this._sends.filter((x) => x.statusId === 'creat')];
    const onper = [...this._sends.filter((x) => x.statusId === 'onper')];
    const done = [...this._sends.filter((x) => x.statusId === 'done')];

    return { creat, onper, done };
  }
}

export default new SendsStore();
