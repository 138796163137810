import HttpClient from '@utils/httpClient';

const service = {
  getTags() {
    return HttpClient.get(`/api/tag/claimsend/list`).then((resp) => {
      return resp.data;
    });
  },

  create(data: any) {
    return HttpClient.post(`/api/tag/claimsend/create`, data).then((resp) => {
      return resp.data;
    });
  },

  update(data: any) {
    return HttpClient.post(`/api/tag/update`, data).then((resp) => {
      return resp.data;
    });
  },

  remove(tagId: string) {
    return HttpClient.delete(`/api/tag/remove/${tagId}`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
