import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import dictStore from '@stores/dictStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import organizationsStore from '@stores/organizationsStore/organizationsStore';
import { supplierBidRegistryStore } from '@stores/supplierBidRegistryStore';
import usersStore from '@stores/usersStore/usersStore';

import styles from './supplier-bid-registry-create-page.module.scss';
import { PageType } from './supplier-bid-registry-create-page.types';
import { SupplierBidRegistryCreateHeader as Header } from './ui/header';
import { SupplierBidRegistryCreateTabs as Tabs } from './ui/tabs';

export const SupplierBidRegistryCreatePage = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [_, PAGE_TYPE, bidId] = pathname.split('/').filter(Boolean);
  const isEdit = PAGE_TYPE === PageType.EDIT;

  useEffect(() => {
    setIsLoading(true);

    const additionalPromises = isEdit
      ? [supplierBidRegistryStore.getSupplierBidRegistryById(bidId), supplierBidRegistryStore.getDirections(bidId)]
      : null;

    Promise.all([
      usersStore.getUsers({ notRoles: ['org'] }),
      organizationsStore.getUserOrganizations(),
      optionsStore.getTariffDictionary(),
      dictStore.getDictionaries(),
      dictStore.countryDict(null),
      additionalPromises,
    ]).finally(() => setIsLoading(false));

    return supplierBidRegistryStore.clearSupplierBidRegistry;
  }, []);

  return (
    <>
      <Header isEdit={isEdit} />
      <Layout.Content style={{ height: HEIGHT }}>
        <AdaptiveCard loading={isLoading} className={styles.turnOffAlignment}>
          <Tabs pageType={PAGE_TYPE as PageType} />
        </AdaptiveCard>
      </Layout.Content>
    </>
  );
});

const HEIGHT = 'calc(100vh - var(--header-height))';
