import { Spin } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import dictService from '@stores/dictStore/dictStore.service';
import { StationModel } from '@stores/dictStore/dictStoreData';

interface StationProps {
  view?: 'code';
  code: string;
}

interface StationState {
  stations: StationModel[];
  isBusy: boolean;
}

class Station extends Component<StationProps, StationState> {
  state = {
    stations: [],
    isBusy: false,
  };

  componentDidMount(): void {
    const code = this.props.code;
    this.setState({ isBusy: true });
    if (code) {
      this.setState({ isBusy: true });
      dictService
        .stationWithParagraphDict(code, moment().toISOString(true))
        .then((station) => {
          this.setState({ stations: station }, () => {
            this.setState({ isBusy: false });
          });
        })
        .catch(() => this.setState({ isBusy: false }));
    } else {
      this.setState({ stations: [] });
    }
  }

  render() {
    const { view, code } = this.props;
    const { isBusy } = this.state;
    const stations = this.state.stations.length > 0 ? this.state.stations.filter((l) => l.stationCode === Number(code)) : [];

    return (
      <Spin spinning={isBusy}>
        {!!stations &&
          stations.map((station: StationModel, index) => {
            if (!!view && view == 'code') {
              if (!!station.stationCodeCheckSum) {
                return station.stationCodeCheckSum.toString().padStart(6, '0');
              } else {
                return station.stationCode.toString().padStart(5, '0');
              }
            } else {
              return (
                <p className={'ellipsis'} key={'item_' + index}>
                  {station.fullName + ' ' + station.countryName}
                </p>
              );
            }
          })}
      </Spin>
    );
  }
}

export default Station;
