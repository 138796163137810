import Icon from '@ant-design/icons';
import { Col, Form, Popover } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { Component } from 'react';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import RemoteSelect from '@components/fw/RemoteSelect';

import designStore from '@stores/designStore/designStore';
import dictService from '@stores/dictStore/dictStore.service';

interface FreightSelectProps {
  field: any;
  form: FormInstance;
  gngName: string;
  etsngName: string;
  arrayName: string;
  primitiveResult?: boolean;
  span: number;
  disabled?: boolean;
}

interface FreightSelectState {
  gngOrEtsng: 'gng' | 'etsng';
  etsng: string;
  gng: string;
}

class FreightSelect extends Component<FreightSelectProps, FreightSelectState> {
  constructor(props: FreightSelectProps) {
    super(props);
    this.state = {
      gngOrEtsng: null,
      gng: null,
      etsng: null,
    };
  }

  componentDidMount() {
    const etsng = this.props.form.getFieldValue([this.props.arrayName, this.props.field.name, this.props.etsngName]);
    const gng = this.props.form.getFieldValue([this.props.arrayName, this.props.field.name, this.props.gngName]);
    const gngOrEtsng = etsng ? 'etsng' : null;
    this.setState({ etsng, gng, gngOrEtsng });
  }

  onEtsngChange = (value) => {
    const gngOrEtsng = this.state.gngOrEtsng;
    if (!gngOrEtsng || gngOrEtsng == 'etsng') {
      this.setState({ gngOrEtsng: 'etsng', gng: null, etsng: this.props.primitiveResult ? value : value ? value.freightEtsngCode : null });
      this.props.form.resetFields([[this.props.arrayName, this.props.field.name, this.props.gngName]]);
    }
  };

  onGngChange = (value) => {
    const gngOrEtsng = this.state.gngOrEtsng;
    if (!gngOrEtsng || gngOrEtsng == 'gng') {
      this.setState({ gngOrEtsng: 'gng', gng: this.props.primitiveResult ? value : value ? value.gngCode : null, etsng: null });
      this.props.form.resetFields([[this.props.arrayName, this.props.field.name, this.props.etsngName]]);
    }
  };

  get embedded() {
    return !!this.props.field;
  }

  render() {
    const { field, gngName, etsngName, primitiveResult, span, disabled } = this.props;
    const gngOrEtsng = this.state.gngOrEtsng;
    const selectedEtsng = this.state.etsng;
    const selectedGng = this.state.gng;
    const isMobile = designStore.isMobile;

    return (
      <>
        <Col xs={24} sm={24} md={span} lg={span} xl={span}>
          <Form.Item
            name={[field.name, etsngName]}
            fieldKey={[field.key, etsngName]}
            rules={[{ required: true, message: 'Укажите ЕТСНГ' }]}>
            <RemoteSelect
              position={isMobile ? 'top' : 'left'}
              float={true}
              primitiveResult={primitiveResult}
              autoInitDict={primitiveResult}
              onChange={this.onEtsngChange}
              placeholder={'Наименование или код ЕТСНГ'}
              valueExpr={'freightEtsngCode'}
              displayExpr={'fullName'}
              disabled={disabled}
              aValueExpr={gngOrEtsng == 'gng' ? selectedGng : null}
              dataSource={dictService.freightEtsngDict}
              itemTemplate={(item) => (
                <div style={{ whiteSpace: 'normal', height: 'auto' }}>
                  <span style={{ color: 'lightGray' }}>{item['freightEtsngCode']} </span>
                  {item['fullName']}
                </div>
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={span} lg={span} xl={span}>
          <Form.Item
            name={[field.name, gngName]}
            fieldKey={[field.key, gngName]}
            rules={[{ required: gngOrEtsng == 'gng', message: 'Необходимо заполнить' }]}>
            <RemoteSelect
              position={'middle'}
              float={true}
              onChange={this.onGngChange}
              allowClear={true}
              primitiveResult={primitiveResult}
              autoInitDict={primitiveResult}
              disabled={disabled}
              placeholder={
                <div className="placeholder-gng">
                  <p>ГНГ (для перевозок по РФ заполнять не обязательно)&nbsp;</p>
                </div>
              }
              aValueExpr={gngOrEtsng == 'etsng' ? selectedEtsng : null}
              valueExpr={'gngCode'}
              displayExpr={'fullName'}
              dataSource={dictService.getFreightGngDictionary}
              itemTemplate={(item) => (
                <div style={{ whiteSpace: 'normal', height: 'auto' }}>
                  <span style={{ color: 'lightGray' }}>{item['gngCode']} </span>
                  {item['fullName']}
                </div>
              )}
            />
          </Form.Item>
        </Col>
      </>
    );
  }
}

export default FreightSelect;
