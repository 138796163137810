import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Flex } from '@components/fw/Flex';

import { BidRegistryDocumentStore } from '@stores/bidRegistryDocumentStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import { CreateOffer } from './ui/create-offer';
import { CreateProtocol } from './ui/create-protocol';
import { GetOffer } from './ui/get-offer';
import { GetProtocol } from './ui/get-protocol';

export const DocsTab = observer(() => {
  useEffect(() => {
    BidRegistryDocumentStore.getExistsDocuments(registerOfBetsStore.bidRegistryId);

    return () => BidRegistryDocumentStore.clearData();
  }, []);

  const offer = BidRegistryDocumentStore.isExistDocuments.commercialOfferExists ? (
    <GetOffer />
  ) : (
    <CreateOffer isLoading={BidRegistryDocumentStore.isLoading} />
  );

  const protocol = BidRegistryDocumentStore.isExistDocuments.protocolExists ? (
    <GetProtocol />
  ) : (
    <CreateProtocol isLoading={BidRegistryDocumentStore.isLoading} />
  );

  return (
    <Spin spinning={BidRegistryDocumentStore.isLoading}>
      <Flex vertical gap="20px">
        <Flex vertical gap="10px">
          <h4 style={{ marginBottom: 0 }}>Коммерческое предложение</h4>
          {offer}
        </Flex>
        <Flex vertical gap="10px">
          <h4 style={{ marginBottom: 0 }}>Протокол согласования договорной цены</h4>
          {protocol}
        </Flex>
      </Flex>
    </Spin>
  );
});
