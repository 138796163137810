import { CheckOutlined, ExportOutlined, ReconciliationOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';

import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';

import { BidRegistryState, registerOfBetsStore } from '@stores/registerOfBetsStore';

export const ExtraHeader = observer(() => {
  const { bidRegistry, isLoading } = registerOfBetsStore;

  if (isEmpty(bidRegistry)) {
    return null;
  }

  const { state } = bidRegistry;

  return (
    <Space>
      <CollapsedToolbar
        content={[
          <Tooltip title="На согласование" key={1}>
            <Button
              icon={<ReconciliationOutlined />}
              onClick={() => registerOfBetsStore.changeRegistryState(BidRegistryState.onagr)}
              hidden={state === BidRegistryState.onagr}
              loading={isLoading}
            />
          </Tooltip>,
          <Tooltip title="Согласовать" key={2}>
            <Button
              icon={<CheckOutlined />}
              onClick={() => registerOfBetsStore.changeRegistryState(BidRegistryState.agr)}
              hidden={state === BidRegistryState.agr}
              loading={isLoading}
            />
          </Tooltip>,
          <Tooltip title="Вернуть в Черновик" key={3}>
            <Button
              icon={<ExportOutlined />}
              onClick={() => registerOfBetsStore.changeRegistryState(BidRegistryState.draft)}
              hidden={state === BidRegistryState.draft}
              loading={isLoading}
            />
          </Tooltip>,
        ]}
      />
    </Space>
  );
});
