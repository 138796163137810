import { EtranOrganizationPassportInfo } from './EtranData';
import { EtsngFreightServiceModel, GngFreightServiceModel } from './EtranDictData';

export const emptyEtranInvoiceCarForm: EtranInvoiceCarForm = {
  carAddDevWithGoods: null,
  carClsList: [],
  carDepArc: null,
  carDepCond: null,
  carDepNormDocName: null,
  carDepPart: null,
  carDepSec: null,
  carFrameWagNum: null,
  carMtuScheme: null,
  carNtuClearance: null,
  carNtuMtuNumber: null,
  carOutsizeCode: null,
  carOwnerCountryName: null,
  carOwnerName: null,
  carOwnerOkpo: null,
  carOwnerTypeId: null,
  carOwnerTypeName: null,
  carPriorFreightCode: null,
  carPriorFreightName: null,
  carRefNum: null,
  carSign: null,
  carTankType: null,
  carTenantName: null,
  carTenantOkpo: null,
  carTypeName: null,
  fasteners: [],
  guides: [],
  specialMarks: [],
  carNtuMtuDate: null,
  carNtuMtuId: null,
  carFreightGNGCode: null,
  carFreightGNGId2001: null,
  carOwnerObj: null,
  carPriorFreightGNGObj: null,
  carPriorFreightObj: null,
  carNumber: null,
};

export interface EtranInvoiceCarInfo {
  carAddDevWithGoods: number;
  carAxles?: number;
  carClaimId?: number;
  carClaimNumOpt?: number;
  carClaimNumPod?: number;
  carClsList: EtranCarClsModel[];
  carConnectCode?: number;
  carDepArc: string;
  carDepCond: string;
  carDepNormDocId?: number;
  carDepNormDocName: string;
  carDepPart: string;
  carDepSec: string;
  carForestVolume?: number;
  carFrameWagNum: string;
  carFrameWeight?: number;
  carGuideCount?: number;
  carHeadShtabQuantity?: number;
  carId?: number;
  carIsCover?: number;
  carLength?: number;
  carLiquidDensity?: number;
  carLiquidHeight?: number;
  carLiquidTemperature?: number;
  carLiquidVolume?: number;
  carMainShtabHeight?: number;
  carMainShtabQuantity?: number;
  carMtuScheme: string;
  carNtuClearance: string;
  carNtuMtuDate?: any;
  carNtuMtuNumber: string;
  carNumber?: number;
  carOrder?: number;
  carOutsizeCode: string;
  carOwnerCountryCode?: number;
  carOwnerCountryName: string;
  carOwnerId?: number;
  carOwnerName: string;
  carOwnerOkpo: string;
  carOwnerTypeId?: number;
  carOwnerTypeName: string;
  carPlacesCount?: number;
  carPocketCount?: number;
  carPriorFreightCode: string;
  carPriorFreightName: string;
  carRefCount?: number;
  carRefNum: string;
  carRolls?: number;
  carSign: string;
  carTankType: string;
  carTenantId?: number;
  carTenantName: string;
  carTenantOkpo: string;
  carTonnage?: number;
  carTopHeight?: number;
  carTypeCode?: number;
  carTypeId?: number;
  carTypeName: string;
  carVolume?: number;
  carWeightAddDev?: number;
  carWeightDep?: number;
  carWeightDepReal?: number;
  carWeightGross?: number;
  carWeightNet?: number;
  fasteners: EtranCarFastenerInfo[];
  guides: EtranCarGuideModel[];
  invoiceId?: number;
  specialMarks: EtranCarSpcModel[];

  /// <!-- Примечание: В связи с переходом на 8-значный код данное поле оставляется
  /// для совместимости – по нему будет выбираться связанный по коду груз
  /// из GNG_FREIGHT_2001 с минимальным ID.
  /// В дальнейшем необходимо использовать поле carFreightGNGID2001 -->
  carFreightGNGCode: number;
  /// ID Груз по ГНГ (ID ГНГ по НСИ - GNG_FREIGHT_2001)
  carFreightGNGId2001: number;
  /// ИД НТУ/МТУ АСКМ (NM_ID по НСИ ASKM_TU_LIST)
  carNtuMtuId: number;

  //Арендатор - используется только для получения инфы по инвентаринку
  /// Арендатор – ID
  carArendatorId?: number;
  /// Арендатор ОКПО
  carArendatorOkpo?: string;
  /// Арендатор имя
  carArendatorName?: string;
  /// Дата окончания аренды
  carEndArendaDate?: any;
}

export interface EtranCarClsModel {}
export interface EtranCarFastenerInfo {}
export interface EtranCarGuideModel {
  /// ФИО проводника
  guideFio: string;
  /// Серия паспорта проводника
  guidePassportSer: string;
  /// Номер паспорта проводника
  guidePassportNum: string;
  /// Номер командировочного удостоверения проводника
  guideWarrantNum: string;
}
export interface EtranCarSpcModel {
  /// Отметка
  spcTranspClauseId: number;
  /// Наименование отметки
  spcTranspClauseName: string;
  /// Тип отметки
  spcTypeName: string;
  /// Замечание
  spcCustomText: string;
}

export interface EtranInvoiceCarForm extends EtranInvoiceCarInfo {
  carOwnerObj?: EtranOrganizationPassportInfo;
  carTenantObj?: EtranOrganizationPassportInfo;
  carPriorFreightObj?: EtsngFreightServiceModel;
  carPriorFreightGNGObj?: GngFreightServiceModel;
  carConnectCodeBool?: boolean;
  carIsCoverBool?: boolean;
}

export const etranInvoiceCarInfoToForm = (data) => {
  let result = data as EtranInvoiceCarForm;
  if (result.carOwnerId) {
    result.carOwnerObj = {
      orgId: result.carOwnerId,
      orgOkpo: result.carOwnerOkpo,
      orgName: result.carOwnerName,
    };
  } else if (result.carArendatorId) {
    result.carOwnerObj = {
      orgId: result.carArendatorId,
      orgOkpo: result.carArendatorOkpo,
      orgName: result.carArendatorName,
    };
  } else {
    result.carOwnerObj = null;
  }
  if (result.carTenantId) {
    result.carTenantObj = {
      orgId: result.carTenantId,
      orgOkpo: result.carTenantOkpo,
      orgName: result.carTenantName,
    };
  } else {
    result.carTenantObj = null;
  }
  if (result.carPriorFreightCode) {
    result.carPriorFreightObj = {
      code: result.carPriorFreightCode,
      name: result.carPriorFreightName,
      shortName: result.carPriorFreightName,
    };
  } else {
    result.carPriorFreightObj = null;
  }
  if (result.carFreightGNGCode) {
    result.carPriorFreightGNGObj = {
      code: result.carFreightGNGCode?.toString(),
      name: result.carPriorFreightName,
      oldCode: result.carFreightGNGId2001?.toString(),
    };
  } else {
    result.carPriorFreightGNGObj;
  }
  result.carIsCoverBool = !!result.carIsCover;
  result.carConnectCodeBool = !!result.carConnectCode;

  return result;
};

export const etranInvoiceCarFormToInfo = (data: EtranInvoiceCarForm) => {
  let result = data as EtranInvoiceCarInfo;
  if (data.carOwnerObj) {
    result.carOwnerId = data.carOwnerObj.orgId;
    result.carOwnerOkpo = data.carOwnerObj.orgOkpo;
    result.carOwnerName = data.carOwnerObj.orgName;
  }
  if (data.carTenantObj) {
    result.carTenantId = data.carTenantObj.orgId;
    result.carTenantOkpo = data.carTenantObj.orgOkpo;
    result.carTenantName = data.carTenantObj.orgName;
  }
  if (data.carPriorFreightObj) {
    result.carPriorFreightCode = data.carPriorFreightObj.code;
    result.carPriorFreightName = data.carPriorFreightObj.name;
  }
  if (data.carPriorFreightGNGObj) {
    const gngCode = Number(data.carPriorFreightGNGObj.code);
    result.carFreightGNGCode = isNaN(gngCode) ? null : gngCode;
    const oldGngCode = Number(data.carPriorFreightGNGObj.oldCode);
    result.carFreightGNGId2001 = isNaN(oldGngCode) ? null : oldGngCode;
  }
  if (data.carIsCoverBool) {
    result.carIsCover = 1;
  }
  if (data.carConnectCodeBool) {
    result.carConnectCode = 1;
  }
  return result;
};
