import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';

import {
  ClaimGroupModel,
  ClaimModel,
  ClaimPerformanceInfo,
  ClaimTableModel,
  claimCountryInteractionsModelToFormData,
  claimCountryInteractionsModelToTree,
  emptyClaim,
} from './ClaimData';
import claimCountryInteractionsStore from './claimCountryInteractionsStore';
import service from './claimsStore.service';

class ClaimsStore {
  @observable isBusy: boolean = false;
  @observable private _claims: ClaimModel[];
  @observable private _claimsBoard: ClaimTableModel[];
  @observable private _claimGroups: any[];
  @observable priorities: any[] = [];
  @observable tmpClaimData: ClaimTableModel[];
  @observable claimPerformance: ClaimPerformanceInfo = { claimPerformance: null };

  @observable currentClaim: ClaimModel = emptyClaim;
  @observable currentClaimChanged: boolean = false;
  @observable currentClaimLoading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action getClaims(filter: any = {}) {
    this.isBusy = true;
    service
      .getClaims(filter)
      .finally(() => (this.isBusy = false))
      .then((result: ClaimModel[]) => {
        this._claims = result;
      });
  }

  @action async loadPlan(filter: any): Promise<any> {
    let data = await service.loadPlan(filter);
    this.tmpClaimData = data.data;
    return data;
  }

  @action loadPlanForBoard() {
    this.isBusy = true;
    service
      .loadPlan({
        group: null,
        requireTotalCount: true,
        searchOperation: 'contains',
        searchValue: null,
        skip: 0,
        sort: null,
        userData: {},
      })
      .then((ans) => {
        this._claimsBoard = [];
        ans.data.forEach((d) => {
          if (!this._claimsBoard.find((c) => c.claimid === d.claimid)) {
            this._claimsBoard.push(d);
          }
        });
      })
      .finally(() => (this.isBusy = false));
  }

  @action getClaimGroups() {
    service.getClaimGroups().then((result: ClaimGroupModel[]) => {
      this._claimGroups = result;
    });
  }

  @action getPriorities() {
    service.priorities().then((result: any[]) => {
      this.priorities = result;
    });
  }

  @computed get ifClientPay() {
    return this.currentClaim && this.currentClaim.loadTariffIcId == 'client';
  }

  /* заявка по ID */
  @action async getClaim(claimId: string) {
    this.currentClaimLoading = true;
    return await service
      .getClaim(claimId)
      .then((claim) => {
        claimCountryInteractionsStore.countryPaidTerritories = claim.countryPaidTerritories;
        claimCountryInteractionsStore.countryPaidTerritoriesTree = claimCountryInteractionsModelToTree(claim.countryPaidTerritories);
        claimCountryInteractionsStore.initFormData = claimCountryInteractionsModelToFormData(claim.countryPaidTerritories);
        claim.validityFrom = claim.validityFrom ? moment(claim.validityFrom) : null;
        claim.validityTo = claim.validityTo ? moment(claim.validityTo) : null;
        this.currentClaim = claim;
        return this.currentClaim;
      })
      .finally(() => (this.currentClaimLoading = false));
  }

  /* отправки заявки */
  @action async getClaimSends(claimId: string) {
    return await service.sends(claimId);
  }

  /* отправки заявки */
  @action async getLimitation(claimId: string, sendId: string) {
    return await service.limitations(claimId, sendId);
  }

  /* валидация заявки */
  @action async validateClaim(data: any) {
    return await service.validate(data);
  }

  /* создание заявки */
  @action async createClaim(data: ClaimModel) {
    return await service.create(data);
  }

  /* сохранение заявки */
  @action async updateClaim(data: ClaimModel): Promise<string> {
    return await service.update(data);
  }

  /* удаление заявки */
  @action async delete(claimId: string) {
    return await service.delete(claimId);
  }

  /* установить статус */
  @action async setStatus(claimId: string, statusCode: string) {
    return await service.setStatus(claimId, statusCode).then(() => {
      this.currentClaim.statusId = statusCode;
    });
  }

  @action async loadClaimPerformance(claimId: string) {
    return await service.getClaimPerformance(claimId).then((ans) => {
      this.claimPerformance = ans;
      return this.claimPerformance;
    });
  }

  @computed get claims() {
    if (!this._claims) {
      return [];
    }
    return [...this._claims];
  }

  @computed get claimGroups() {
    if (!this._claimGroups) {
      return [];
    }
    return [...this._claimGroups];
  }

  @computed get claimsForBoard(): { creat; onagr; agr; onper; done } {
    if (!this._claimsBoard) {
      return { creat: [], onagr: [], agr: [], onper: [], done: [] };
    }
    const creat = [...this._claimsBoard.filter((x) => x.statusid === 'creat')];
    const onagr = [...this._claimsBoard.filter((x) => x.statusid === 'onagr')];
    const agr = [...this._claimsBoard.filter((x) => x.statusid === 'agr')];
    const onper = [...this._claimsBoard.filter((x) => x.statusid === 'onper')];
    const done = [...this._claimsBoard.filter((x) => x.statusid === 'done')];

    return { creat, onagr, agr, onper, done };
  }
}

export default new ClaimsStore();
