import { Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AdaptiveHeaderTabs from '@components/fw/AdaptiveElements/AdaptiveHeaderTabs';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import AccessRoute from '@components/fw/RoutingContext/AccessRoute';

import routingStore from '@stores/fw/routingStore/routingStore';

import FinanceOperations from './FinanceOperations/FinanceOperations';
import InfologFinance from './InfologFinance/InfologFinance';

class FinancePage extends Component<RouteComponentProps> {
  render() {
    const path = routingStore.location.pathname;
    document.title = 'Финансы';
    return (
      <>
        <AdaptivePageHeader
          subTitle={
            <AdaptiveHeaderTabs defaultActiveKey={path} onTabClick={(e) => this.props.history.push(e)}>
              <Tabs.TabPane tab={' Финансы Инфолог'} key="/finance-infolog/list" />
              <Tabs.TabPane tab={'Операции финансов'} key="/finance-infolog/operations" />
            </AdaptiveHeaderTabs>
          }
        />
        <Switch>
          <Route exact path="/finance-infolog">
            <Redirect to={'/finance-infolog/list'} />
          </Route>
          <AccessRoute path="/finance-infolog/list" component={InfologFinance} canAccess={'finance-infolog'} />
          <AccessRoute path="/finance-infolog/operations" component={FinanceOperations} canAccess={'finance-infolog'} />
        </Switch>
      </>
    );
  }
}

export default observer(FinancePage);
