import HttpClient from '@utils/httpClient';

const service = {
  getNotes(companyId: string) {
    return HttpClient.get(`/api/companyNote/list/${companyId}`).then((resp) => {
      return resp.data;
    });
  },

  add(data: any) {
    return HttpClient.post(`/api/companyNote/add`, data).then((resp) => {
      return resp.data;
    });
  },

  update(data: any) {
    return HttpClient.post(`/api/CompanyNote/save`, data).then((resp) => {
      return resp.data;
    });
  },

  delete(noteId: string) {
    return HttpClient.delete(`/api/CompanyNote/delete/${noteId}`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
