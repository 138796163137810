import { Button, Form, Skeleton, notification } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { Flex } from '@components/fw/Flex';
import { BidRegistryServiceType } from '@components/routed/RegisterOfBetsCreatePage/ui/basic-tab-basics-fields/basic-tab-basics-fields.types';
import { ExpeditionService } from '@components/routed/RegisterOfBetsCreatePage/ui/expedition-service';
import { TotalModal } from '@components/routed/RegisterOfBetsCreatePage/ui/total-modal';
import { WagonsService } from '@components/routed/RegisterOfBetsCreatePage/ui/wagons-service';

import { BidRegistryCommercialStore } from '@stores/bidRegistryCommercialStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';
import sendsStore from '@stores/sendsStore';

import { CalculationTabProps } from './calculation-tab.types';

export const RegisterOfBetsCreateCalculationTab = observer(({ form }: CalculationTabProps) => {
  const [isShowTotal, setIsShowTotal] = useState(false);
  const isWagons = registerOfBetsStore.bidRegistryServiceType === BidRegistryServiceType.wagons;
  const isExpedition = registerOfBetsStore.bidRegistryServiceType === BidRegistryServiceType.expedition;
  const isWagonsExpedition = registerOfBetsStore.bidRegistryServiceType === BidRegistryServiceType.wagons_expedition;

  useEffect(() => {
    BidRegistryCommercialStore.getCommercial(registerOfBetsStore.bidRegistryId);
    sendsStore.wagonsForScheduler();

    return () => BidRegistryCommercialStore.clearStore();
  }, []);

  if (BidRegistryCommercialStore.isLoading) {
    return <Skeleton />;
  }

  let initialValues = {};

  if (isWagons) {
    initialValues = BidRegistryCommercialStore.flatRollingStocks;
  }

  if (isWagonsExpedition) {
    initialValues = {
      ...BidRegistryCommercialStore.flatCountryPaidTree,
      ...BidRegistryCommercialStore.extraServices,
      ...BidRegistryCommercialStore.flatRollingStocks,
    };
  }

  if (isExpedition) {
    initialValues = { ...BidRegistryCommercialStore.flatCountryPaidTree, ...BidRegistryCommercialStore.extraServices };
  }

  const onFinish = async (values) => {
    await BidRegistryCommercialStore.updateCommercial(values);
    return notification.success({ message: 'Ставка успешно обновлена' });
  };

  const onModalOpen = async () => {
    await BidRegistryCommercialStore.totalCommercial(form.getFieldsValue());
    setIsShowTotal(true);
  };

  return (
    <>
      <Form form={form} initialValues={initialValues} scrollToFirstError={true} onFinish={onFinish}>
        <Flex vertical gap="20px">
          <Flex justify="flex-end">
            <Button type="primary" onClick={onModalOpen}>
              Итого
            </Button>
          </Flex>
          {isWagons && <WagonsService />}
          {isExpedition && <ExpeditionService />}
          {isWagonsExpedition && (
            <>
              <WagonsService />
              <ExpeditionService />
            </>
          )}
        </Flex>
      </Form>
      <TotalModal
        onOk={() => form.submit()}
        isExpedition={isExpedition}
        isWagonsExpedition={isWagonsExpedition}
        isWagons={isWagons}
        open={isShowTotal}
        onCancel={() => setIsShowTotal(false)}
      />
    </>
  );
});
