export interface NsiField {
  caption: string;
  dataField: string;
  dataType: string;
  visible: boolean;
  editable: boolean;
  headerFilter?: any;
}

export const NSI_STATIONS_FIELDS: NsiField[] = [
  { caption: 'ЕСР', dataField: 'stationcode', dataType: 'number', visible: true, editable: false, headerFilter: { searchMode: 'equals' } },
  { caption: 'Наименование', dataField: 'fullname', dataType: 'string', visible: true, editable: true },
  { caption: 'Сокр. наименование', dataField: 'shortname', dataType: 'string', visible: true, editable: true },
  { caption: 'Широта', dataField: 'latitude', dataType: 'number', visible: true, editable: true },
  { caption: 'Долгота', dataField: 'longitude', dataType: 'number', visible: true, editable: true },
  { caption: 'Сбой координат', dataField: 'failcoordinates', dataType: 'boolean', visible: true, editable: true },

  { caption: 'Код дороги', dataField: 'roadcode', dataType: 'number', visible: true, editable: true },
  { caption: 'Наименование дороги', dataField: 'roadshortname', dataType: 'string', visible: true, editable: false },
  { caption: 'Код страны', dataField: 'countrycode', dataType: 'number', visible: true, editable: false },
  { caption: 'Наименование страны', dataField: 'countryname', dataType: 'string', visible: true, editable: false },
];

export const NSI_OKPO_FIELDS: NsiField[] = [
  { caption: 'key', dataField: 'okpoid', dataType: 'string', visible: false, editable: false },
  { caption: 'ОКПО', dataField: 'okpo', dataType: 'string', visible: true, editable: true },
  { caption: 'Локальный код', dataField: 'localcode', dataType: 'number', visible: true, editable: false },
  { caption: 'Наименование', dataField: 'fullname', dataType: 'string', visible: true, editable: true },
  { caption: 'Сокр. наименование', dataField: 'shortname', dataType: 'string', visible: true, editable: true },
  { caption: 'ИНН', dataField: 'inn', dataType: 'number', visible: true, editable: true },
  { caption: 'КПП', dataField: 'kpp', dataType: 'number', visible: true, editable: true },
];
