import { FormInstance } from 'antd';
import React from 'react';

import { DocsTab } from '@components/routed/RegisterOfBetsCreatePage/ui/docs-tab';

import { RegisterOfBetsCreateBasicTab as BasicTab } from '../basic-tab';
import { RegisterOfBetsCreateCalculationTab as CalculationTab } from '../calculation-tab';
import { ExtraActionsTabs as ExtraActions } from '../extra-actions-tabs';

export const getTabsItem = (form: FormInstance, isEdit?: boolean) => {
  return [
    { label: 'Основное', key: 'basic', children: <BasicTab form={form} /> },
    { label: 'Расчет', key: 'calculation', children: <CalculationTab form={form} />, disabled: !isEdit },
    { label: 'Документы', key: 'docs', children: <DocsTab />, disabled: !isEdit },
  ];
};

export const getExtraTabActions = (onCancel?: () => void, onSuccess?: () => void, activeKey?: string, isLoading?: boolean) => {
  if (activeKey === 'docs') {
    return null;
  }

  return {
    right: <ExtraActions onCancel={onCancel} onSuccess={onSuccess} isLoading={isLoading} />,
  };
};
