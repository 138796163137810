import HttpClient from '@utils/httpClient';

const service = {
  getClaimComments(claimId) {
    return HttpClient.get(`/api/claimcomment/list/${claimId}`).then((resp) => {
      return resp.data;
    });
  },

  addClaimComment(data: any) {
    return HttpClient.post(`/api/claimcomment/add`, data).then((resp) => {
      return resp.data;
    });
  },

  deleteClaimComment(commentId: number) {
    return HttpClient.delete(`/api/claimcomment/delete/${commentId}`).then((resp) => {
      return resp.data;
    });
  },

  getSendComments(sendId) {
    return HttpClient.get(`/api/sendcomment/list/${sendId}`).then((resp) => {
      return resp.data;
    });
  },

  addSendComment(data: any) {
    return HttpClient.post(`/api/sendcomment/add`, data).then((resp) => {
      return resp.data;
    });
  },

  deleteSendComment(commentId: number) {
    return HttpClient.delete(`/api/sendcomment/delete/${commentId}`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
