import { Spin } from 'antd';
import React, { Component } from 'react';

import { FreightEtsngModel } from '@stores/claimsStore/ClaimData';
import dictService from '@stores/dictStore/dictStore.service';

interface Props {
  code: string;
}

interface State {
  freightEtsngs: FreightEtsngModel[];
  isBusy: boolean;
}

class Etsng extends Component<Props, State> {
  state = {
    freightEtsngs: [],
    isBusy: false,
  };

  componentDidMount(): void {
    const code = this.props.code;
    this.setState({ isBusy: true });
    dictService
      .freightEtsngDict(code)
      .then((resp) => {
        if (resp.length == 1) {
          this.setState({ freightEtsngs: resp });
          this.forceUpdate();
        }
      })
      .finally(() => this.setState({ isBusy: false }));
  }

  render() {
    const { freightEtsngs, isBusy } = this.state;

    return (
      <Spin spinning={isBusy}>
        {freightEtsngs.map((freightEtsng: FreightEtsngModel, index) => (
          <p className={'ellipsis'} key={'freightEtsng' + index}>
            {/*{freightEtsng.freightEtsngCode}*/}
            {/*{freightEtsng.freightEtsngShortCode}*/}
            {freightEtsng.fullName}
            {/*{freightEtsng.shortName}*/}
          </p>
        ))}
      </Spin>
    );
  }
}

export default Etsng;
