import { MessageOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Layout, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ScrollBar from 'react-perfect-scrollbar';

import designStore from '@stores/designStore/designStore';
import messengerStore from '@stores/messengerStore/messengerStore';

const { Header, Content, Footer, Sider } = Layout;

interface DialogsListState {
  openSubs: any[];
}

interface DialogsListProps {
  width: any;
}

@observer
class DialogsList extends Component<DialogsListProps, DialogsListState> {
  constructor(props: DialogsListProps) {
    super(props);
    this.state = {
      openSubs: [],
    };
  }

  componentDidMount() {
    messengerStore.getDialogs();
  }

  componentWillUnmount() {
    messengerStore.selectDialog(null);
  }

  render() {
    const dialogs = messengerStore.dialogs;
    const openSubs = this.state.openSubs;
    const selectedDialog = messengerStore.selectedDialog;
    const unreadCommonMessages = messengerStore.unreadCommonMessages;
    const unreadTechMessages = messengerStore.unreadTechMessages;
    const width = this.props.width;
    const common = dialogs.filter((item) => item.dialogType.includes('Common'));
    const assist = dialogs.filter((item) => item.dialogType.includes('Assist'));
    const tech = dialogs.filter((item) => item.dialogType.includes('Tech'));
    const isMobile = designStore.isMobile;
    const collapsed = !!messengerStore.selectedDialog;

    return (
      <Sider
        className="site-layout-background"
        collapsedWidth={0}
        width={isMobile ? '100%' : width}
        collapsible
        collapsed={isMobile && collapsed}
        zeroWidthTriggerStyle={{
          display: 'none',
        }}
        //breakpoint={'md'}
      >
        <ScrollBar component="div" style={{ width: '100%' }}>
          <Menu
            mode="inline"
            selectedKeys={[selectedDialog ? `${selectedDialog.dialogId}` : null]}
            defaultOpenKeys={['Common']}
            onOpenChange={(e) => this.setState({ openSubs: e })}
            style={{ height: '100%' }}>
            <Menu.Item
              icon={<PlusCircleOutlined style={{ paddingLeft: '6px' }} />}
              onClick={this.handleAddClick}
              style={{ textAlign: 'center' }}>
              <span style={{ paddingRight: 12 }}>Добавить диалог</span>
            </Menu.Item>
            {assist.map((item, index) => {
              return (
                <Menu.Item
                  icon={
                    <>
                      <Badge size={'default'} count={item.unreadMessageCount} />
                      <UserOutlined style={{ paddingLeft: '6px' }} />
                    </>
                  }
                  key={item.dialogId}
                  onClick={() => this.handleClickDialog(item)}>
                  <span style={{ paddingRight: 12 }}>{item.name}</span>
                </Menu.Item>
              );
            })}
            {common.length ? (
              <SubMenu
                icon={
                  <>
                    <Badge size={'default'} count={unreadCommonMessages} />
                    <MessageOutlined style={{ paddingLeft: '6px' }} />
                  </>
                }
                key="Common"
                title={
                  !openSubs.includes('Common') ? (
                    <>
                      <span style={{ paddingRight: 15 }}>Общий</span>
                    </>
                  ) : (
                    'Общий'
                  )
                }>
                {common.map((item, index) => {
                  return (
                    <Menu.Item
                      icon={
                        <>
                          <Badge size={'default'} count={item.unreadMessageCount} />
                          <UserOutlined style={{ paddingLeft: '6px' }} />
                        </>
                      }
                      key={item.dialogId}
                      onClick={() => this.handleClickDialog(item)}>
                      <span style={{ paddingRight: 12 }}>{item.name}</span>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            ) : null}
            {tech.length ? (
              <SubMenu
                icon={
                  <>
                    <Badge size={'default'} count={unreadTechMessages} showZero={false} />
                    <MessageOutlined style={{ paddingLeft: '6px' }} />
                  </>
                }
                key="Tech"
                title={!openSubs.includes('Tech') ? <span>Техподдержка</span> : 'Техподдержка'}>
                {tech.map((item, index) => {
                  return (
                    <Menu.Item
                      icon={
                        <>
                          <Badge size={'default'} count={item.unreadMessageCount} showZero={false} />
                          <UserOutlined style={{ paddingLeft: '6px' }} />
                        </>
                      }
                      key={item.dialogId}
                      onClick={() => this.handleClickDialog(item)}>
                      <span>{item.name}</span>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            ) : null}
          </Menu>
        </ScrollBar>
      </Sider>
    );
  }

  handleAddClick = () => {
    messengerStore.selectDialog(null);
    messengerStore.editDialog = true;
  };

  handleClickDialog = (dialog) => {
    messengerStore.selectDialog(dialog);
  };
}

export default DialogsList;
