import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select } from 'antd';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';

import dictService from '@stores/dictStore/dictStore.service';
import optionsStore from '@stores/optionsStore';

import { filterOptionFn } from '@utils/sort';

interface ComponentProps {}

interface ComponentState {}

class ContractExtWagons extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  render() {
    const wagonTypesDictionary = optionsStore.wagonTypesDictionary;
    return (
      <Form.List name="wagonParams">
        {(fields, { add, remove }) => (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item>
                <Button style={{ margin: 5 }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить
                </Button>
              </Form.Item>
            </Col>
            {fields.map((field, index) => (
              <Col xs={24} sm={24} md={24} lg={12} xxl={8} key={index}>
                <AdaptiveCard
                  title={'Параметры перевозки'}
                  extra={[
                    <Button key={'delete'} size={'small'} type={'text'} onClick={() => remove(field.name)} icon={<DeleteOutlined />} />,
                  ]}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'loadStation']} fieldKey={[field.key, 'loadStation']}>
                        <RemoteSelect
                          float={true}
                          placeholder={'Отправление'}
                          valueExpr={'stationCode'}
                          displayExpr={'fullName'}
                          dataSource={dictService.stationDict}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'destStation']} fieldKey={[field.key, 'destStation']}>
                        <RemoteSelect
                          float={true}
                          placeholder={'Назначение'}
                          valueExpr={'stationCode'}
                          displayExpr={'fullName'}
                          dataSource={dictService.stationDict}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                      <Form.Item name={[field.name, 'etsngModel']} fieldKey={[field.key, 'etsngModel']}>
                        <RemoteSelect
                          float={true}
                          placeholder={'Наименование или код ЕТСНГ'}
                          valueExpr={'freightEtsngCode'}
                          displayExpr={'fullName'}
                          aValueExpr={null}
                          dataSource={dictService.freightEtsngDict}
                          itemTemplate={(item) => (
                            <div style={{ whiteSpace: 'normal', height: 'auto' }}>
                              <span style={{ color: 'lightGray' }}>{item['freightEtsngCode']} </span>
                              {item['fullName']}
                            </div>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                      <Form.Item name={[field.name, 'gngModel']} fieldKey={[field.key, 'gngModel']}>
                        <RemoteSelect
                          allowClear={true}
                          placeholder={'Наименование или код ГНГ'}
                          valueExpr={'gngCode'}
                          displayExpr={'fullName'}
                          dataSource={dictService.getFreightGngDictionary}
                          itemTemplate={(item) => (
                            <div style={{ whiteSpace: 'normal', height: 'auto' }}>
                              <span style={{ color: 'lightGray' }}>{item['gngCode']} </span>
                              {item['fullName']}
                            </div>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item name={[field.name, 'priceWithoutNds']} fieldKey={[field.key, 'priceWithoutNds']}>
                        <FloatInputNumber placeholder={'За 1 вагон без НДС'} max={99999999} min={0} step={100} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item name={[field.name, 'nds']} fieldKey={[field.key, 'nds']}>
                        <FloatInputNumber placeholder={'Размер НДС'} max={99999999} min={0} step={100} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item name={[field.name, 'priceWithNds']} fieldKey={[field.key, 'priceWithNds']}>
                        <FloatInputNumber placeholder={'Стоимость c НДС'} max={99999999} min={0} step={100} />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name={[field.name, 'wagonTypeCode']} rules={[{ required: true }]}>
                        <FloatSelect
                          placeholder="Подвижной состав"
                          dropdownMatchSelectWidth={false}
                          showSearch
                          filterOption={filterOptionFn}>
                          {wagonTypesDictionary.map((item, index) => {
                            return (
                              <Select.Option value={item.wagonTypeCode} key={`speedCategory_${index}`}>
                                {item.wagonTypeName}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                  </Row>
                </AdaptiveCard>
              </Col>
            ))}
          </Row>
        )}
      </Form.List>
    );
  }
}

export default ContractExtWagons;
