import * as Sentry from '@sentry/browser';
import * as H from 'history';
import { action, computed, makeObservable, observable } from 'mobx';

import { getAuthority, setAuthority } from '@utils/authority';
import { buildTree } from '@utils/roleAccess';

import logStore from '../logStore/logStore';
import { ChatDialog } from '../messengerStore/messengerStoreData';
import service from './userStore.service';

export type UserData = {
  userId: string;
  roleCode: string;
  roleName: string;
  userName: string;
  sessionId: string;
  userAccessList: string[];
  companyAccessList: string[];
  companyId: string | null;
  companyName: string | null;
  companyCurrencyCode: string | null;
  dialogType: ChatDialog;
  startPage: string;
  otherTariffEnable: boolean;
  organizationId: string;
  organizationName: string;
  organizationTypeName: string;
};

export const START_PAGES: {
  name: string;
  path: string;
  rule: string;
}[] = [
  { name: 'Заявки', path: '/claims', rule: 'claims' },
  { name: 'Отправки', path: '/sends', rule: 'sends' },
  { name: 'Мои отчеты', path: '/my-reports/reports', rule: 'dispatcher/reports' },
  { name: 'Мои отчеты-аналитика', path: '/my-reports/itineraries', rule: 'dispatcher/itineraries' },
  { name: 'Мои отчеты-реестр погрузки', path: '/my-reports/loading-register', rule: 'dispatcher/loading-register' },
  { name: 'Расчет тарифа', path: '/options', rule: 'tariff' },
  { name: 'Справки', path: '/requests', rule: 'requests' },
  { name: 'Администрирование', path: '/administration', rule: 'administration' },
];

export interface RestoreResponse {
  isSuccess: boolean;
  message: string;
}

class UserStore {
  @observable userData: UserData;
  @observable logonBusy = false;

  constructor() {
    makeObservable(this);
  }

  @computed get tmpUser() {
    return getAuthority().tmpUser;
  }

  @action login(username: string, password: string, history: H.History) {
    this.logonBusy = true;
    const data = {
      login: username,
      password: password,
    };

    service
      .logon(data)
      .finally(() => (this.logonBusy = false))
      .then((result) => {
        setAuthority(result);
        history.push('/');
        logStore.in().then(() => {});
      });
  }

  @action refresh() {
    service.refresh().then((result) => {
      setAuthority(result);
    });
  }

  @action async getUserInfo() {
    await service.userInfo().then((result) => {
      this.userData = result;
      Sentry.configureScope(function (scope) {
        scope.setUser({
          email: '',
          id: result.userId,
        });
        scope.setExtra('userName', result.userName);
        scope.setExtra('roleName', result.roleName);
      });
    });
  }

  canAccess(rule: string | string[]): boolean {
    if (Array.isArray(rule)) {
      return !!(this.userData && this.userData.userAccessList && this.userData.userAccessList.find((r) => rule.includes(r)));
    } else {
      return !!this.userData && this.userData.userAccessList && !!this.userData.userAccessList.includes(rule);
    }
  }

  // get canEditClaimsSends() {
  //   return !['manager', 'tech', 'admin'].includes(this.userData.roleCode);
  // }
  //
  // get canEditSends() {
  //   return !['manager', 'tech', 'admin', 'org'].includes(this.userData.roleCode);
  // }

  get canEditClaimExecutor() {
    return !['org'].includes(this.userData.roleCode);
  }

  get isOrg() {
    return ['org'].includes(this.userData.roleCode);
  }

  get isOrgOrUser() {
    return this.userData.roleCode.includes('user') || this.userData.roleCode.includes('org');
  }

  get isAdmin() {
    return ['admin', 'manager', 'tech'].includes(this.userData.roleCode);
  }

  @computed get userAccessTree(): any[] {
    const tree = buildTree(this.userData ? this.userData.userAccessList : []);
    return tree;
  }

  @computed get sidebarAvailableItems(): string[] {
    return this.userAccessTree.map((x) => x.key);
  }

  @computed get tabsAvailableItems(): string[] {
    const items = [].concat.apply(
      [],
      this.userAccessTree.map((x) => x.children),
    );
    return items.map((x) => x.key);
  }
}

export default new UserStore();
