import moment from 'moment';
import Qs from 'qs';
import { StringDecoder } from 'string_decoder';

import HttpClient from '@utils/httpClient';

import { CountryModel } from '../companyStore/CompanyData';
import { CurrencyModel } from '../optionsStore/optionsStoreData';
import { CompanySourceCountryModel, SourceModel } from './dictStore';
import { GlobosoftCountryModel, ParagraphModel, StationModel, WagonModelInfo } from './dictStoreData';

const dictService = {
  systemInfo() {
    return HttpClient.get(`/api/system/version/last`).then((resp) => {
      return resp.data;
    });
  },

  getDictionaries() {
    return HttpClient.get(`/api/dictionary/dictionary`).then((resp) => {
      return resp.data;
    });
  },

  countryDict(filter: string): Promise<CountryModel[]> {
    return HttpClient.get(`/api/country/countryDict`, { params: { filter } }).then((resp) => {
      return resp.data;
    });
  },

  async globalCountries(filter: string): Promise<GlobosoftCountryModel[]> {
    return (await HttpClient.get(`/api/country/globalCountries`, { params: { filter } })).data;
  },

  stationDict(srcFilter: string): Promise<StationModel[]> {
    const filterNumber = Number(srcFilter);
    const filter = isNaN(filterNumber) ? srcFilter : filterNumber.toString();
    return HttpClient.get(`/api/station/stationDict`, { params: { filter } }).then((resp) => {
      let result: StationModel[] = resp.data;
      if (srcFilter) {
        let lowerFilter = srcFilter.toLowerCase();
        result.sort((a, b) => {
          const aLower = a.fullName?.toLowerCase();
          const bLower = b.fullName?.toLowerCase();
          if (aLower === lowerFilter) return -1;
          if (bLower === lowerFilter) return 1;
          const aIndex = aLower?.indexOf(lowerFilter);
          const bIndex = bLower?.indexOf(lowerFilter);
          if (aIndex == -1) return 1;
          if (bIndex == -1) return -1;
          return aLower?.indexOf(lowerFilter) < bLower?.indexOf(lowerFilter) ? -1 : 0;
        });
      }
      return result;
    });
  },

  stationInfoByCode(filter: string): Promise<StationModel[]> {
    return HttpClient.get(`/api/station/stationDict`, { params: { filter } }).then((resp) => {
      let result: StationModel[] = resp.data;
      return result;
    });
  },

  async stationDictForCriteria(filter: string) {
    return (await this.stationDict(filter)).map((d) => {
      return { value: d.stationCode.toString(), description: d.stationCode.toString() + ' ' + d.fullName };
    });
  },

  stationWithParagraphDict(srcFilter: string, dateTime: any): Promise<StationModel[]> {
    const filterNumber = Number(srcFilter);
    const filter = isNaN(filterNumber) ? srcFilter : filterNumber.toString();
    return HttpClient.get(`/api/station/stationWithParagraphDict`, { params: { filter, dateTime } }).then((resp) => {
      let result: StationModel[] = resp.data;
      if (srcFilter) {
        let lowerFilter = srcFilter.toLowerCase();
        result.sort((a, b) => {
          const aLower = a.fullName?.toLowerCase();
          const bLower = b.fullName?.toLowerCase();
          if (aLower === lowerFilter) return -1;
          if (bLower === lowerFilter) return 1;
          const aIndex = aLower?.indexOf(lowerFilter);
          const bIndex = bLower?.indexOf(lowerFilter);
          if (aIndex == -1) return 1;
          if (bIndex == -1) return -1;
          return aLower?.indexOf(lowerFilter) < bLower?.indexOf(lowerFilter) ? -1 : 0;
        });
      }
      return result;
    });
  },

  getStationParagraphs(stationCode: string, date: any): Promise<ParagraphModel[]> {
    return HttpClient.get(`/api/paragraph/stationparagraphs`, { params: { stationCode, date } }).then((resp) => {
      return resp.data;
    });
  },

  freightDict(filter: string) {
    return HttpClient.get(`/api/freight/freightDict`, { params: { filter } }).then((resp) => {
      return resp.data;
    });
  },

  async freightDictForCriteria(filter: string) {
    return (await this.freightDict(filter)).map((d) => {
      return { value: d.freightEtsngShortCode, description: d.freightEtsngShortCode + ' ' + d.shortName };
    });
  },

  freightEtsngDict(filter: string, gng?: string) {
    return HttpClient.get(`/api/freight/freightEtsngDict`, { params: { filter, gng } }).then((resp) => {
      return resp.data;
    });
  },

  okpoDict(filter: string) {
    return HttpClient.get(`/api/okpo/okpoDict`, { params: { filter } }).then((resp) => {
      console.log(resp.data);
      return resp.data;
    });
  },

  async okpoDictForCriteria(filter: string) {
    return (await this.okpoDict(filter)).map((d) => {
      return { value: d.okpo, description: d.okpo + ' ' + d.shortName };
    });
  },

  wagonModelDict(filter: string): Promise<WagonModelInfo[]> {
    return HttpClient.get(`/api/wagonModel/wagonModelDict`, { params: { filter } }).then((resp) => {
      return resp.data;
    });
  },

  wagonModelDictByTypeCode(filter: string): Promise<WagonModelInfo[]> {
    return HttpClient.get(`/api/wagonModel/wagonModelDict`, { params: { filter } }).then((resp) => {
      return resp.data.filter((i: WagonModelInfo) => i.wagonTypeCode === Number(filter));
    });
  },

  getFreightGngDictionary(filter: string, etsng: string) {
    return HttpClient.get(`/api/freight/freightGngDict`, { params: { etsng, filter } }).then((resp) => {
      return resp.data;
    });
  },

  //START --- ILS RPS DICT
  async getRpsDict(): Promise<string[]> {
    return (await HttpClient.get('/api/ilsrps/rpsDict')).data;
  },

  async getRpsFeaturesDict(rps: string): Promise<string[]> {
    return (await HttpClient.get('/api/ilsrps/rpsWagonFeaturesDict', { params: { rps } })).data;
  },

  async getRpsWagonModels(rps: string, feature: string): Promise<string[]> {
    return (await HttpClient.get('/api/ilsrps/rpsWagonModelsDict', { params: { rps, feature } })).data;
  },

  async getRpsWagonModelsByFeatures(rps: string, features: string[]): Promise<string[]> {
    return (
      await HttpClient.get('/api/ilsrps/rpsWagonModelsDictByFeatures', {
        params: { rps, features },
        paramsSerializer: (params) => {
          return Qs.stringify(params, { arrayFormat: 'indices' });
        },
      })
    ).data;
  },

  async getRpsWagonModelsByRps(rps: StringDecoder): Promise<string[]> {
    return (await HttpClient.get('/api/ilsrps/rpsWagonModelsDictByRps', { params: { rps } })).data;
  },

  async getRpsWagonModelsByFeature(feature: string): Promise<string[]> {
    return (await HttpClient.get('/api/ilsrps/rpsWagonModelsDictByFeature', { params: { feature } })).data;
  },
  //END --- ILS RPS DICT

  async companyInfoblocksDict() {
    return (await HttpClient.get(`/api/infoblock/list`)).data;
  },

  async companySourcesDict(): Promise<SourceModel[]> {
    return (await HttpClient.get(`/api/source/list`)).data;
  },

  async countriesBySources(sourceId: string): Promise<CompanySourceCountryModel[]> {
    return (await HttpClient.get(`/api/source/countries`, { params: { sourceId } })).data;
  },

  async borderStations(filter: string): Promise<StationModel[]> {
    if (!filter) {
      return (await HttpClient.get(`/api/borderStation/stationDict`, { params: { filter } })).data;
    } else return dictService.stationWithParagraphDict(filter, moment().toISOString(true));
  },

  async currencyDict(): Promise<CurrencyModel[]> {
    return (await HttpClient.get(`/api/globosoftCurrency/list`)).data;
  },
};

export default dictService;
