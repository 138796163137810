import { DefaultOptionType } from 'antd/lib/select';
import { dxElement } from 'devextreme/core/element';
import { dxToolbarOptions } from 'devextreme/ui/toolbar';

import { ContractElement, DocTypeContext, DocTypeTemplateModel } from '@stores/contractTemplateStore/ContractTemplatesData';

export const docContextOptions: Array<DefaultOptionType & { value: DocTypeContext }> = [
  { value: 'contract', label: 'Договор' },
  { value: 'ext', label: 'Доп. соглашение' },
  { value: 'attachment', label: 'Приложение ' },
];

export const copyTemplate = (template: DocTypeTemplateModel): DocTypeTemplateModel => {
  return JSON.parse(JSON.stringify(template));
};

export const convertTemplateToContract = (template: DocTypeTemplateModel | null): ContractElement | null => {
  if (!template) return null;

  return JSON.parse(
    JSON.stringify({
      nestedElements: template.data,
      relativePath: null,
      value: template.name,
      elementType: 'title',
    }),
  );
};

export const DxCellDocContextRender = (params: any) => {
  return docContextOptions.find((option) => option.value === params.value)?.label;
};

export const onToolbarPreparing = (e: { component?: any; element?: dxElement; model?: any; toolbarOptions?: dxToolbarOptions }) => {
  e.toolbarOptions.items.unshift(
    {
      location: 'after',
      template: 'create',
    },
    {
      location: 'after',
      template: 'edit',
    },
    {
      location: 'after',
      template: 'delete',
    },
    {
      location: 'after',
      template: 'refresh',
    },
  );
};
