import { IColumnProps } from 'devextreme-react/data-grid';
import DataGrid from 'devextreme/ui/data_grid';

import { DX_GRID_GLOBAL_DATETIME_FORMAT, DX_GRID_GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

export const setDataGridDateFormat = () => {
  DataGrid.defaultOptions({
    options: {
      customizeColumns: (columns: Array<IColumnProps>) => {
        for (let i = 0; i < columns.length; i++) {
          if (columns[i].dataType === 'date' && !columns[i].cellRender) {
            columns[i].format = DX_GRID_GLOBAL_DATE_FORMAT;
          }

          if (columns[i].dataType === 'datetime' && !columns[i].cellRender) {
            columns[i].format = DX_GRID_GLOBAL_DATETIME_FORMAT;
          }
        }
      },
    },
  });
};
