export type EtranDictionaryInfo = {
  /// Тип накладной
  invoiceTypeDictionary: EtranCommonDictionaryModel[];
  /// Тип бланка
  blankTypeDictionary: EtranBlankTypeDictionaryModel[];
  /// Вид отправки
  sendTypeDictionary: EtranSendTypeDictionaryModel[];
  //погрузка сердствами
  loadAssetTypeDictionary: EtranCommonDictionaryModel[];
  /// Вид грузовых работ
  loadTypeDictionary: EtranCommonDictionaryModel[];
  /// Скорость
  speedDictionary: EtranSpeedDictionaryModel[];
  /// Место оплаты
  payPlaceDictionary: EtranPayPlaceDictionaryModel[];
  /// Форма оплаты
  payFormDictionary: EtranPayFormDictionaryModel[];
  /// Планируемый тип собственности
  plannedOwningTypeDictionary: EtranCommonDictionaryModel[];
  /// Вид сообщения
  messageTypeDictionary: EtranMessageTypeDictionaryModel[];
  /// Специальные отметки
  transpClauseDictionary: EtranTranspClauseDictionaryModel[];
  /// Специальные отметки для груза
  freightTranspClauseDictionary: EtranTranspClauseDictionaryModel[];
  /// Признак опасного груза
  freightDangerDictionary: EtranCommonDictionaryModel[];
  /// Тип собственности ЗПУ (нужен для вагона. таблица ЗПУ)
  sealTypeOwnDictionary: EtranCommonDictionaryModel[];
  /// Тип ЗПУ (нужен для вагона. таблица ЗПУ)
  sealTypeDictionary: EtranInvSealTypeDictionaryModel[];
  /// Тип упаковки
  packTypeDictionary: EtranInvPackTypeModel[];
  /// Тоннажность контейнера
  containerTonnageDictionary: EtranContainerTonnageModel[];
  /// Классификатор размеров крупнотоннажных контейнеров.
  containerSizeBigDictionary: EtranContainerSizeBigModel[];
  /// ЖД дороги
  railwayDictionary: EtranRailwayModel[];

  /// Классификатор перевозчиков
  carriersDictionary: EtranCarrierModel[];

  /// Справочник типов перевозочных приспособлений, которые могут быть установлены
  /// в вагоне и подлежат возврату собственнику.
  devTypeDictionary: EtranDevTypeModel[];

  ///
  contTypeBigDictionary: EtranContTypeBigModel[];
  /// Планируемы род вагона
  wagonTypeDictionary: EtranWagTypeDictionaryInfo[];
  docTranspClauseDictionary: EtranDocTranspClauseDictionaryInfo[];
};

export interface EtranDocTranspClauseDictionaryInfo {
  ccId: number;
  checkRequired: number;
  customTextDscr: string;
  description: string;
  editMask: string;
  errorText: string;
  recDateBegin: any;
  recDateEnd: any;
  shortName: string;
  stampPrintPlaceId: number;
  tarifMark: string;
  transId: number;
  transpClauseId: number;
  transpClauseTypeId: number;
}

export interface EtranContainerTonnageModel {
  contTonnageId: null;
  tonnage: number;
  tonnageTypeId: null;
  shortName: string;
  kolUsl: number;
}

export interface EtranContainerSizeBigModel {
  containerSizeId: number;
  height: number;
  widthF: number;
  code: string;
  heightTxt: string;
  comments: string;
}

export interface EtranRailwayModel {
  transId: number;
  rwId: number;
  recDateBegin: any;
  recDateEnd: any;
  recDateNew: any;
  orderNo: string;
  rwCode: string;
  rwShortName: string;
  rwName: string;
  rwCnId: number;
  rwTelegraphName: string;
}

export interface EtranCarrierModel {
  transId: number;
  shortName: string;
  recDateBegin: any;
  recDateEnd: any;
  recDateNew: any;
  orgId: number;
  carrierId: number;
  carrierCode: number;
}

export interface EtranDevTypeModel {
  devType: number;
  name: string;
  shortName: string;
}

export interface EtranContTypeBigModel {
  contTypeBig: number;
  typeName: string;
  detail: string;
  code: string;
  kindId: number;
  priznSk: number;
}

export interface EtranWagTypeDictionaryInfo {
  /// ID рода вагона
  wagTypeId: number;
  /// Код рода вагона
  wagTypeCode: number;
  /// Наименование рода вагона
  name: string;
  shortName: string;
  wagTypeCode1: number;
  wagTypeVpu: number;
  rindCarId: number;
  /// Наличие ЗПУ в вагоне (0 - не требуется, 1-разрешено, 2 - обязательно)
  wantSeals: number;
  /// Разрешена или нет промывка  (0 – запрещена, 1- разрешена)
  wash: number;
  /// Мнемокод
  mnemoCode: string;
}

export const emptyDictionary: EtranDictionaryInfo = {
  invoiceTypeDictionary: [],
  blankTypeDictionary: [],
  sendTypeDictionary: [],
  loadTypeDictionary: [],
  speedDictionary: [],
  payPlaceDictionary: [],
  payFormDictionary: [],
  plannedOwningTypeDictionary: [],
  messageTypeDictionary: [],
  transpClauseDictionary: [],
  freightTranspClauseDictionary: [],
  freightDangerDictionary: [],
  sealTypeOwnDictionary: [],
  sealTypeDictionary: [],
  packTypeDictionary: [],
  containerTonnageDictionary: [],
  containerSizeBigDictionary: [],
  carriersDictionary: [],
  contTypeBigDictionary: [],
  railwayDictionary: [],
  devTypeDictionary: [],
  wagonTypeDictionary: [],
  docTranspClauseDictionary: [],
  loadAssetTypeDictionary: [],
};

export type EtranCommonDictionaryModel = {
  /// Номер транзакции для репликаций
  transId: number;
  /// Идентификатор параметра
  paramId: number;
  ///
  id: number;
  /// Дата ввода в действие записи
  recDateBegin: Date;
  /// Значение параметра
  name: string;
  /// Дата вывода записи из действия
  redDateEnd: Date;
  description: string;
};
export type EtranBlankTypeDictionaryModel = {
  /// Дата появления записи в таблице
  recDateNew: Date | null;
  /// Дата вывода из действия
  recDateEnd: Date | null;
  /// Дата ввода в действие
  recDateBegin: Date | null;
  /// Признак номерной отправки
  numbered: number | null;
  /// Наименование
  name: string;
  /// Мнемокод
  mnemo: string;
  /// Код
  code: number | null;
  /// Идентификатор
  blancId: number | null;
};

export type EtranSendTypeDictionaryModel = {
  /// Справочников видов отправки.
  kindSend: number;
  /// Наименование
  name: string;
  /// Код
  iodv: number;
  /// Аббревиатура вида отправки
  shortName: string;
};

export type EtranSpeedDictionaryModel = {
  /// Идентификатор типа скорости перевозки.
  speed: number | null;
  /// Наименование скорости перевозки.
  name: string;
  /// Код скорости
  speedCode: number | null;
};

export type EtranPayPlaceDictionaryModel = {
  /// Идентификатор места, где будет произведен расчет.
  payPlace: number | null;
  /// Наименование места расчета.
  name: string;
  /// Код места расчета.
  code: number | null;
};

export type EtranPayFormDictionaryModel = {
  /// Идентификатор формы расчета за перевозку.
  payForm: number | null;
  /// Наименование формы расчета за перевозку.
  name: string;
  /// Код формы расчета за перевозку.
  code: number | null;
  /// Сокр. наименование
  shortName: string;
  /// Дата начала действия
  recDateBegin: Date | null;
  /// Дата конца действия
  recDateEnd: Date | null;
  /// Дата появления записи в таблице
  recDateNew: Date | null;
  /// Номер транзакции
  transId: number | null;
};

export type EtranMessageTypeDictionaryModel = {
  /// Идентификатор
  kindDisp: number | null;
  /// Наименование
  name: string;
  /// Код АСОУП
  asoupCode: string;
  /// Код для расчета провозной платы
  rppCode: string;
  /// Комментарии
  comments: string;
};

export type EtranTranspClauseDictionaryModel = {
  /// Идентификатор условия перевозки
  transpClauseId: number | null;
  /// Идентификатор образующего документа
  ccId: number | null;
  /// Идентификатор типа условия перевозки.
  transpClauseTypeId: number | null;
  /// Описание
  description: string;
  /// Дата начала действия
  recDateBegin: Date | null;
  /// Дата конца действия
  recDateEnd: Date | null;
  /// Код тарифной отметки.штемпеля
  tarifMark: string;
  /// Место печати отметки/штемпеля.
  stampPrintPlaceId: number | null;
  /// Сокращенное наименование
  shortName: string;
  /// Описание произвольного текста
  customTextDscr: string;
  /// Номер транзакции
  transId: number | null;
  /// Окончание текста выводимой ошибки.
  errorText: string;
  /// Требует проверки.
  checkRequired: number | null;
  /// Маска для ввода если требуются данные
  editMask: string;
  //наименование типа
  transpClauseTypeName?: string;
};

export type EtranInvSealTypeDictionaryModel = {
  ///
  sealType: number;
  /// Название
  name: string;
  /// Код ЗПУ
  sealCode: number | null;
  /// Мнемокод
  mnemoCode: string;
  /// Дополнительный код ЗПУ
  sealCode2: number | null;
  /// Код завода
  factoryCode: number | null;
  /// Дата появления записи в таблице
  recDateNew: Date | null;
  /// Дата ввода в действие
  recDateBegin: Date | null;
  /// Дата вывода из действия
  recDateEnd: Date | null;
};

export type EtranInvPackTypeModel = {
  pocket: number;
  name: string;
  shortName: string;
  loadType: number | null;
  iodvCode: string;
  codeOon: string;
  recDateBegin: Date | null;
  recDateEnd: Date | null;
  recDateNew: Date | null;
  transId: number;
};

export interface EtranFasteningModel {
  transId: number;
  id: number;
  recDateBegin: any;
  recDateEnd: any;
  prevId: number;
  cathegory: string;
  bookType: number;
  data: string;
  depNormDocId: number;
  structId: number;
}

export interface EtranCarDictionaryModel {
  /// Номер вагона
  carNumber: number;
  /// Дата обновления НСИ
  carNsiDate: any;
  /// Дата исключения вагона
  carIsklDate?: any;
  /// Источник значений (0–значения по умолчанию, 1–АБДПВ, 2–225 массив)
  carResponse?: number;
  /// Род вагона (ID по НСИ – WAG_TYPE)
  carTypeId?: number;
  /// Род вагона код
  carTypeCode?: number;
  /// Род вагона
  carTypeName: string;
  /// Кол-во осей
  carAxles?: number;
  /// Страна-собственник код
  carOwnerCountryCode: string;
  /// Страна-собственник
  carOwnerCountryName: string;
  /// Тип собственности (ID по НСИ - COMMON, тип 7)
  carOwnerTypeId?: number;
  /// Тип собственности )
  carOwnerTypeName: string;
  /// Собственник ID
  carOwnerId?: number;
  /// Собственник ОКПО
  carOwnerOkpo: string;
  /// Собственник Наименование
  carOwnerName: string;
  /// Грузоподъемность в т.
  carTonnage?: number;
  /// Массы тары ц
  carWeightDep?: number;
  /// Тип цистерны
  carTankType: string;
  /// Объем кузова
  carVolume?: number;
  /// Признак нерабочего парка
  carNerab?: number;
  /// Подъезной путь
  carApproachLine: string;
  /// Арендатор – ID
  carArendatorId?: number;
  /// Арендатор ОКПО
  carArendatorOkpo: string;
  /// Арендатор имя
  carArendatorName: string;
  /// Дата окончания аренды
  carEndArendaDate?: any;
  /// Год выпуска вагона
  carYear?: number;
  /// Тип последнего ремонта (ID по НСИ - COMMON, тип 278)
  carTypeRepair?: number;
  /// Дата последнего ремонта
  carLastRepair?: number;
  /// Дата следующего ремонта
  carNextRepair?: any;
  /// Запрет эксплуатации на инфраструктуре ОАО РЖД (0-Эксплуатация разрешена, 1-Эксплуатация запрещена)
  carBanned?: number;
  /// Пробег (0-в пробеге(нормальное состояние), 1-достигнут желтый порог,2 - достигнут красный порог,3-Не в пробеге(Пробег не считается))
  carSign?: number;
  /// Длина вагона в м.
  carLength?: number;
  /// Признак соответствия грузоподьемности и тары значениям, переписанным с борта вагона
  carWeightDepSign?: number;
  /// Грузоподъемность по НСИ в т.
  carTonnageNsi?: number;
  /// Массы тары по НСИ ц
  carWeightDepNsi?: number;
  /// Пробег в км.
  carRace?: number;
  /// Норма пробега в км.
  carNorma?: number;
  /// Операции на станции - для станций использующих обмен между Этран и АСУ ст.
  /// Дата последней операции на станции
  carStateDate?: any;
  /// Дата перевода вагона в собственность
  carDateSob?: any;
  /// Модель вагона
  carModel: string;
  /// Дата телеграммы
  dateTeleg?: any;
  /// Номер, текст телеграммы
  numTeleg?: string;
  /// Принадлежность к консолидированному парку (0 или 1)
  carConsPark?: number;
  /// Оператор по доверенности, наименование организации
  carTrustedOperator?: string;
  /// Записи региона курсирования
  carConditions?: EtranCarConditionDictionaryModel[];
  /// Перечень кодов грузов ЕТСНГ и ГНГ, допустимых к перевозке в данной модели
  carFreights?: EtranCarFreightDictionaryModel[];
}

export interface EtranCarConditionDictionaryModel {
  carNumber?: number;
  /// Признак погрузки (1–отпр., 2–назнач., 3–отправ. и назнач.
  condSign?: number;
  /// Cтанция
  condStationCode: string;
  /// Cтанция имя
  condStationName: string;
  /// Дорога
  condRwCode: string;
  /// Дорога имя
  condRwName: string;
  /// Страна
  condCountryCode: string;
  /// Страна имя
  condCountryName: string;
  /// Груз по ЕТ СНГ код
  condFreightCode: string;
  /// Груз по ЕТ СНГ
  condFreightName: string;
}

export interface EtranCarFreightDictionaryModel {
  carNumber: number;
  /// Груз етснг
  freightEtsng: string;
  /// Груз ГНГ
  freightGng: string;
}

export interface EtranCountryModel {
  code: string;
  fullName: string;
  shortName: string;
}

export interface EtranStationDictionaryInfo {
  /// Номер транзакции
  transId?: number;
  /// Условный идентификатор станции
  stId?: number;
  /// Дата появления записи в таблице
  recDateNew?: any;
  /// Дата ввода в действие
  recDateBegin?: any;
  /// Дата вывода из действия
  recDateEnd?: any;
  /// Номер приказа на изменение классификатора
  oOrderNo?: string;
  /// Код ЕСР
  stCode: string;
  /// Условный идентификатор отделения
  stDpId?: number;
  /// Идентификатор типа разд. пункта
  stSttId?: number;
  /// Условный идентификатор региона
  stRgId?: number;
  /// Условный идентификатор дороги
  stDpRwId?: number;
  /// Идентификатор дороги
  rwCode?: number;
  /// Ссылка на страну
  stCnId?: number;
  /// 12-символьное наименование станции
  stName12Char?: string;
  ///
  stName: string;
  /// Полное наименование раздельного пункта
  stBorderSign?: string;
  /// Признак перевалки на море
  stSeaSign?: string;
  /// Признак перевалки на реку
  stRiverSign?: string;
  /// Признак перевалки на море (имп-экс)
  stSeaxImE?: string;
  /// Признак перевалки на реку (имп-экс)
  stRiverImEx?: string;
  /// Признак перевалки на автотранспорт
  stCarSign?: string;
  /// Признак перевалки на паромную переправу
  stFerrySign?: string;
  /// Признак перевалки на паромную переправу (имп-экс)
  stFerryImEx?: string;
  /// Признак перевалки на новостроящуюся ж.д. линию
  stNewBuildSign?: string;
  /// Тип стыкового пункта
  stJointSign?: number;
  /// Для межгосуд.стыка-смежн. гос-во
  stJcnId?: number;
  /// Для междорожн.стыка-смежн. дорога
  stJwrId?: number;
  /// Признак станции, открытой для грузовой работы
  stFreighSign?: string;
  /// Признак экспорта международного
  stJointEx?: string;
  /// Для межотделенч.стыка-смежн. отделение
  stJdpId?: number;
  /// Внешний переход
  stInterSign?: string;
  /// Ссылка на ж/д узел (if any)
  uzel?: number;
  /// ID станции обработки документов (вышестоящей)
  stHeadStId?: number;
  /// Страна реальная (не Ж/Д), в которой расположена станция
  stCnIdReal?: number;
  /// Опорная станция (ссылка на ST_ID)
  oporStationId?: number;
  /// Признак опорной станции
  oporStationSign?: string;
  /// Станция примыкания для новостройки
  newStJointId?: number;
  /// Код станции с контрольным знаком - обязателен и для СНГ и для ДЗ
  stCodeOsJd?: string;
  /// Параграфы станции
  fromStationParagraphs: string;
}

export interface EtsngFreightServiceModel {
  code: string;
  name: string;
  shortName: string;
}

export interface GngFreightServiceModel {
  code: string;
  oldCode: string;
  name: string;
}
