import { Button, Form, Input, Modal, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import { EtranFreightClsModel } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';

import { filterOptionFn } from '@utils/sort';

interface Props {
  data?: EtranFreightClsModel;
  onChange(data: EtranFreightClsModel): void;
}

interface State {
  visible: boolean;
  data: EtranFreightClsModel;
}

@observer
class EtranInvoceFreightsClsModal extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      data: null,
    };
  }

  handleFormReset = () => {
    this.setState({ data: this.props.data }, () => {
      if (this.formRef) this.formRef.resetFields();
    });
  };

  handleConfirm = (e: EtranFreightClsModel) => {
    const selected = this.selected;
    const result: EtranFreightClsModel = {
      clsTranspClauseId: selected.transpClauseTypeId,
      clsTranspClauseName: selected.description,
      clsCustomText: e.clsCustomText,
    };
    this.setState({ visible: false });
    this.props.onChange(result);
  };

  onOpen = () => {
    this.setState({ visible: true }, () => {
      this.handleFormReset();
    });
  };

  get isEdit() {
    return !!this.props.data;
  }

  onClose = () => {
    this.handleFormReset();
    this.setState({ visible: false });
  };

  get selected() {
    if (!this.formRef) return;
    const selectedId = this.formRef.getFieldValue('clsTranspClauseId');
    if (!selectedId) return;
    return etranStore.dictionaryInfo.transpClauseDictionary.find((s) => s.transpClauseId == selectedId);
  }

  render() {
    const data = this.state.data;
    const marks = etranStore.dictionaryInfo.freightTranspClauseDictionary.filter(
      (d) => [1, 4, 5].includes(d.transpClauseTypeId) && d.stampPrintPlaceId == 5,
    );
    return (
      <>
        <Button onClick={this.onOpen}>{this.isEdit ? 'Изменить' : 'Добавить'}</Button>
        <Modal
          title="Отметки на груз"
          destroyOnClose={true}
          open={this.state.visible}
          onCancel={this.onClose}
          className={'etran-modal'}
          footer={
            <Button
              onClick={() => {
                this.formRef.submit();
              }}>
              {this.isEdit ? 'Изменить' : 'Добавить'}
            </Button>
          }>
          <ScrolledContainer style={{ maxHeight: 'calc(100vh - 265px)', overflowX: 'hidden' }}>
            <Form
              layout="horizontal"
              ref={(ref) => (this.formRef = ref)}
              size={'small'}
              name={'etran-editor'}
              labelCol={{ span: 8 }}
              initialValues={{
                clsTranspClauseId: data ? data.clsTranspClauseId : null,
                clsCustomText: this.selected ? this.selected.editMask : null,
              }}
              onFinish={this.handleConfirm}>
              <Form.Item name="clsTranspClauseId" label={'Отметка'}>
                <Select
                  showSearch
                  optionFilterProp={'children'}
                  filterOption={filterOptionFn}
                  onSelect={(e) => {
                    this.forceUpdate(() => {
                      this.formRef.resetFields(['clsCustomText']);
                    });
                  }}>
                  {marks.map((m) => (
                    <Select.Option value={m.transpClauseId} key={m.transpClauseId}>
                      {m.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {this.selected && this.selected.editMask ? (
                <Form.Item name="clsCustomText" label={'Замечание'}>
                  <Input.TextArea maxLength={510} />
                </Form.Item>
              ) : null}
            </Form>
          </ScrolledContainer>
        </Modal>
      </>
    );
  }
}

export default EtranInvoceFreightsClsModal;
