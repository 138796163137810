import { Layout } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { OrganizationContractServiceModel } from '@stores/leasingStore/LeasingData';
import leasingStoreService from '@stores/leasingStore/leasingStore.service';
import organizationsStore from '@stores/organizationsStore/organizationsStore';
import userStore from '@stores/userStore/userStore';

import LeasingContractDrawer from './LeasingContractDrawer';

interface State {
  isBusy: boolean;
  dataSource: OrganizationContractServiceModel[];
}

class LeasingPage extends Component<RouteComponentProps, State> {
  dataGrid: DataGrid;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      dataSource: [],
    };
  }

  componentDidMount() {
    document.title = 'Аренда';
    this.setState({ isBusy: true });
    organizationsStore.getUserOrganizations().then();
    leasingStoreService
      .getContractForRent()
      .then((ans) => {
        ans.forEach((i) => {
          if (i.company) {
            i.companyName = i.company.companyName;
          }
        });
        this.setState({ dataSource: ans });
      })
      .finally(() => this.setState({ isBusy: false }));
  }

  handleClickRow = (data) => {
    if (data.rowType == 'data') {
      this.props.history.push(`/leasingDetails/${data.data.contractId}`);
    }
  };

  render() {
    const dataSource = this.state.dataSource;

    return (
      <>
        <AdaptivePageHeader title={'Аренда'} extra={<LeasingContractDrawer companyId={userStore.userData.companyId} />} />
        <Layout.Content style={{ height: 'calc(100vh - var(--header-height))' }}>
          <AdaptiveCard>
            <DataGrid
              allowColumnReordering={true}
              ref={(ref) => (this.dataGrid = ref)}
              dataSource={dataSource}
              remoteOperations={false}
              //loadPanel={{ enabled: false }}
              allowColumnResizing={true}
              showBorders={true}
              height={'calc(100vh - var(--padding))'}
              columnResizingMode={'widget'}
              columnAutoWidth={true}
              showColumnLines={true}
              columnMinWidth={30}
              showRowLines={true}
              rowAlternationEnabled={true}
              onRowDblClick={(data) => this.handleClickRow(data)}
              selection={{ mode: 'single' }}>
              <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'leasingPage'} />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <GroupPanel visible={true} />
              <Grouping autoExpandAll={false} />
              <Paging enabled={true} defaultPageSize={25} />
              <Pager
                allowedPageSizes={[25, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
                infoText={'Всего: {2}'}
              />
              {/*<Editing allowDeleting={true} mode="row" texts={{ deleteRow: 'Удалить' }} />*/}
              {INDEX_COLUMN}
              <Column allowFixing={true} dataField={'organizationName'} caption={'Организация'} dataType={'string'} />
              <Column
                allowFixing={true}
                dataField={'contractName'}
                caption={'Договор'}
                dataType={'string'}
                cellRender={(e) => {
                  return (
                    <div>
                      <a onClick={() => this.handleClickRow(e)}>{e.text}</a>
                      <LeasingContractDrawer
                        companyId={e.data.company.companyId}
                        contractId={e.data.contractId}
                        orgId={e.data.organizationId}
                      />
                    </div>
                  );
                }}
              />
              <Column allowFixing={true} dataField={'dateFrom'} caption={'Дeйствует с'} dataType={'date'} />
              <Column allowFixing={true} dataField={'dateTo'} caption={'Дeйствует по'} dataType={'date'} />
              <Column allowFixing={true} dataField={'wagonsCount'} caption={'Вагонов'} dataType={'number'} />
              <Column allowFixing={true} dataField={'companyName'} caption={'Компания'} dataType={'string'} />
            </DataGrid>
          </AdaptiveCard>
        </Layout.Content>
      </>
    );
  }
}

export default observer(LeasingPage);
