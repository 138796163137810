import { Checkbox, Col, Empty, Modal, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import designStore from '@stores/designStore/designStore';
import optionsStore, { currencyNameCropper } from '@stores/optionsStore/optionsStore';
import { TariffCountryDetailModel } from '@stores/sendsStore/sendData';

import CurrencyDictionary from '../TariffForm/CurrencyDictionary';

interface State {
  data: TariffCountryDetailModel[];
  type: { [key: string]: boolean };
}

interface Props {
  type: number;
}

class OptionsAdditionalChargesViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: optionsStore.result ? optionsStore.result.countryDetails : [],
      type: { [this.props.type]: true },
    };
  }

  handleSubmit = () => {
    optionsStore.result.countryDetails = this.state.data;
    optionsStore.request.tariffData.additionalCharges = [];
    this.state.data
      .filter((c) => c.countryDetailData && c.countryDetailData.additionalCharges && c.countryDetailData.additionalCharges.length)
      .forEach((c) => {
        optionsStore.request.tariffData.additionalCharges = optionsStore.request.tariffData.additionalCharges.concat(
          c.countryDetailData.additionalCharges,
        );
      });
    optionsStore.calcTariff().then(() => {
      optionsStore.showAdditionalCharges = false;
      optionsStore.showAdditionalCharges168 = false;
    });
  };

  handleChange = (e, item) => {
    item.isEnabled = e.target.checked ? 1 : 0;
    this.forceUpdate();
  };

  componentDidMount() {
    this.setState({
      data: optionsStore.result ? optionsStore.result.countryDetails : [],
      type: { [this.props.type]: true },
    });
  }

  handleTypeChange = (n: number, event: boolean) => {
    const type = this.state.type;
    type[n] = event;
    this.setState({ type });
  };

  render() {
    const isBusy = optionsStore.isBusy;
    const data = this.state.data;
    const type = this.state.type;
    const currency = optionsStore.selectedCurrency;
    const hasData = data.find(
      (c) =>
        c.countryDetailData &&
        c.countryDetailData.additionalCharges &&
        c.countryDetailData.additionalCharges.length &&
        c.countryDetailData.additionalCharges.find(
          (a) => type[a.chargeAmountUnit] || (type[999] && ![1, 168].includes(a.chargeAmountUnit)),
        ),
    );
    const isMobile = designStore.isMobile;
    return (
      <>
        <Modal
          title="Коэффициенты"
          open={true}
          onCancel={() => {
            optionsStore.showAdditionalCharges = false;
            optionsStore.showAdditionalCharges168 = false;
          }}
          centered={isMobile}
          onOk={() => this.handleSubmit()}
          width={isMobile ? '100%' : '60%'}
          okButtonProps={{ loading: isBusy }}>
          <div style={{ margin: '4px' }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col sm={24} md={24} xl={12}>
                <CurrencyDictionary
                  value={optionsStore.selectedCurrency}
                  onChange={(e) => {
                    optionsStore.selectedCurrency = e;
                  }}
                />
              </Col>
              <Col sm={24} md={24} xl={12}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col>
                    <Checkbox checked={type[168]} onChange={(e) => this.handleTypeChange(168, e.target.checked)}>
                      За тонну
                    </Checkbox>
                  </Col>
                  <Col>
                    <Checkbox checked={type[1]} onChange={(e) => this.handleTypeChange(1, e.target.checked)}>
                      За подвижной состав
                    </Checkbox>
                  </Col>
                  <Col>
                    <Checkbox checked={type[999]} onChange={(e) => this.handleTypeChange(999, e.target.checked)}>
                      Прочее
                    </Checkbox>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {hasData ? (
            <ScrolledContainer className={'scrolledContainer'} style={{ width: '100%' }}>
              <table style={{ width: '100%' }}>
                <thead className={'ant-table-thead'}>
                  <tr>
                    <th className="ant-table-cell">Страна</th>
                    <th className="ant-table-cell">
                      <Checkbox checked={false} />
                    </th>
                    <th className="ant-table-cell">Сбор</th>
                    <th className="ant-table-cell">Коэффициент</th>
                    <th className="ant-table-cell">Описание</th>
                    <th className="ant-table-cell">Валюта</th>
                    <th className="ant-table-cell">Единица</th>
                    <th className="ant-table-cell">НДС</th>
                  </tr>
                </thead>
                {data
                  .filter(
                    (c) => c.countryDetailData && c.countryDetailData.additionalCharges && c.countryDetailData.additionalCharges.length,
                  )
                  .map((item) => (
                    <tbody key={item.countryCode} className={'ant-table-tbody'}>
                      {item.countryDetailData.additionalCharges
                        .filter((c) => type[c.chargeAmountUnit] || (type[999] && ![1, 168].includes(c.chargeAmountUnit)))
                        .map((c, index) => (
                          <tr className="ant-table-row ant-table-row-level-0" key={'c' + index + item.countryCode}>
                            <td className="ant-table-cell">
                              {item.countryName} {item.isEmpty ? '(возврат)' : null}
                            </td>
                            <td className="ant-table-cell">
                              <Checkbox checked={c.isEnabled == 1} onChange={(e) => this.handleChange(e, c)} />
                            </td>
                            <td className="ant-table-cell">{this.getNumber(c['charge' + currency.filedPostfix + 'Amount'])}</td>
                            <td className="ant-table-cell">{c.rate}</td>
                            <td className="ant-table-cell">{c.description}</td>
                            <td className="ant-table-cell">
                              {currency.variable ? currencyNameCropper(item[currency.nameFiled]) : currency.nameFiled}
                            </td>
                            <td className="ant-table-cell">{this.getTariffUnitName(c.chargeAmountUnit)}</td>
                            <td className="ant-table-cell">{this.getNumber(c['charge' + currency.filedPostfix + 'Vat'])}</td>
                          </tr>
                        ))}
                    </tbody>
                  ))}
              </table>
            </ScrolledContainer>
          ) : (
            <Empty />
          )}
        </Modal>
      </>
    );
  }

  getWagonTypeName = (id: number) => {
    let find = optionsStore.wagonTypesDictionary.find((s) => s.wagonTypeCode == id);
    if (find) return find.shortName;
    else return '';
  };

  getTariffUnitName = (id: number) => {
    let find = optionsStore.tariffUnitDictionary.find((s) => s.tariffUnitId == id);
    if (find) return find.shortName;
    else return '';
  };

  getNumber = (n: number) => {
    if (!n) return '';
    return n.toLocaleString('ru', { maximumFractionDigits: 2 });
  };
}

export default observer(OptionsAdditionalChargesViewer);
