import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import References from '@components/fw/Drawers/CompanyEditor/References';

interface TreatiesProps {
  isEdit: boolean;
  companyId: string;
}

interface TreatiesState {
  visible: boolean;
}

@observer
class Treaties extends Component<TreatiesProps, TreatiesState> {
  constructor(props: TreatiesProps) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  render() {
    const { companyId, isEdit } = this.props;
    const { visible } = this.state;
    const title = isEdit ? 'Изменить' : 'Создать';

    return (
      <>
        <Button onClick={() => this.setState({ visible: true })} icon={<EditOutlined />} title={title} size={'small'} type={'text'} />
        <AdaptiveDrawer destroyOnClose title={'Договоры'} width={'30%'} onClose={() => this.setState({ visible: false })} visible={visible}>
          <References companyId={companyId} />
        </AdaptiveDrawer>
      </>
    );
  }
}

export default Treaties;
