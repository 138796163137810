import { dxElement } from 'devextreme/core/element';
import { dxToolbarOptions } from 'devextreme/ui/toolbar';

export const toolbarPreparing = (
  onRestore: Function,
  onRefresh: Function,
  e: {
    component?: any;
    element?: dxElement;
    model?: any;
    toolbarOptions?: dxToolbarOptions;
  },
) => {
  e.toolbarOptions.items.unshift(
    {
      location: 'after',
      template: 'exclude',
    },

    {
      location: 'after',
      widget: 'dxButton',
      showText: 'inMenu',
      locateInMenu: 'auto',
      options: {
        icon: 'redo',
        text: 'Сбросить',
        hint: 'Сбросить',
        onClick: () => onRestore(),
      },
    },

    {
      location: 'after',
      widget: 'dxButton',
      showText: 'inMenu',
      locateInMenu: 'auto',
      options: {
        icon: 'refresh',
        text: 'Oбновить',
        hint: 'Oбновить',
        onClick: () => onRefresh(),
      },
    },
  );
};
