import { LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import React from 'react';

import designStore from '@stores/designStore/designStore';

const Greeting: React.FC<any> = (props) => {
  return <Result data-test="greeting" style={{ marginTop: '20%', opacity: 0.4 }} icon={<LoadingOutlined />} title={designStore.greeting} />;
};

export default Greeting;
