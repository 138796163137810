import { action, makeObservable, observable } from 'mobx';

import {
  EtranInvoiceDev,
  EtranInvoiceDistance,
  EtranInvoiceDocModel,
  EtranInvoiceForm,
  EtranInvoiceFreight,
  EtranInvoiceSpcModel,
  emptyEtranInvoice,
  etranIvoceFormDataToInfo,
  etranIvoceInfoToFormData,
} from './EtranData';
import { EtranCountryModel, EtranDictionaryInfo, emptyDictionary } from './EtranDictData';
import { EtranInvoiceCarInfo } from './EtranInvoiceCarData';
import service from './etranStore.service';

class EtranStore {
  @observable isBusy: boolean = false;
  @observable dictionaryInfo: EtranDictionaryInfo = emptyDictionary;
  @observable invoceFormData: EtranInvoiceForm = emptyEtranInvoice;
  @observable countries: EtranCountryModel[] = [];
  @observable loadingInvoice: boolean = false;
  @observable loadingSaveInvoice: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action resetFormData() {
    this.invoceFormData = emptyEtranInvoice;
  }

  @action async getEtranDictionary() {
    this.isBusy = true;
    await Promise.all([
      service.getEtranDictionary().then((result) => (this.dictionaryInfo = result)),
      service.getCountryList().then((ans) => {
        ans.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
        this.countries = ans;
      }),
    ]).finally(() => (this.isBusy = false));
  }

  @action async createInvoice(e) {
    Object.keys(e).forEach((key) => {
      this.invoceFormData[key] = e[key];
    });
    this.loadingSaveInvoice = true;
    return service
      .createInvoice(etranIvoceFormDataToInfo(this.invoceFormData))
      .then(() => {})
      .finally(() => (this.loadingSaveInvoice = false));
  }

  @action async updateInvoice(e) {
    Object.keys(e).forEach((key) => {
      this.invoceFormData[key] = e[key];
    });
    this.loadingSaveInvoice = true;
    return service
      .updateInvoice(etranIvoceFormDataToInfo(this.invoceFormData))
      .then(() => {})
      .finally(() => (this.loadingSaveInvoice = false));
  }

  @action async getEtranInvoiceList(LoadOptions: any): Promise<any> {
    return await service.getEtranInvoiceList(LoadOptions);
  }

  @action addFreight(newData: EtranInvoiceFreight, oldData: EtranInvoiceFreight) {
    let item = this.invoceFormData.freights;
    if (oldData) {
      const index = item.indexOf(oldData);
      if (index != -1) {
        item.splice(index, 1, newData);
      } else {
        item.push(newData);
      }
    } else {
      item.push(newData);
    }
    this.invoceFormData.freights = item;
  }

  @action removeFreight(data: EtranInvoiceFreight) {
    let item = this.invoceFormData.freights;
    const index = item.indexOf(data);
    if (index != -1) {
      item.splice(index, 1);
      this.invoceFormData.freights = item;
    }
  }

  @action upFreight(data: EtranInvoiceFreight) {
    let item = this.invoceFormData.freights;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index > 0) {
        item.splice(index, 1);
        item.splice(index - 1, 0, data);
      }
      this.invoceFormData.freights = item;
    }
  }

  @action downFreight(data: EtranInvoiceFreight) {
    let item = this.invoceFormData.freights;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index < item.length - 1) {
        item.splice(index, 1);
        item.splice(index + 1, 0, data);
      }
      this.invoceFormData.freights = item;
    }
  }

  @action copyFreight(data: EtranInvoiceFreight) {
    let item = this.invoceFormData.freights;
    const newData = JSON.parse(JSON.stringify(data));
    newData.mockId = Math.random();
    item.push(newData);
    this.invoceFormData.freights = item;
  }

  @action addDistance(newData: EtranInvoiceDistance, oldData: EtranInvoiceDistance) {
    let item = this.invoceFormData.distances;
    if (oldData) {
      const index = item.indexOf(oldData);
      if (index != -1) {
        item.splice(index, 1, newData);
      } else {
        item.push(newData);
      }
    } else {
      item.push(newData);
    }
    this.invoceFormData.distances = item;
  }

  @action removeDistance(data: EtranInvoiceDistance) {
    let item = this.invoceFormData.distances;
    const index = item.indexOf(data);
    if (index != -1) {
      item.splice(index, 1);
      this.invoceFormData.distances = item;
    }
  }

  @action upDistance(data: EtranInvoiceDistance) {
    let item = this.invoceFormData.distances;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index > 0) {
        item.splice(index, 1);
        item.splice(index - 1, 0, data);
      }
      this.invoceFormData.distances = item;
    }
  }

  @action downDistance(data: EtranInvoiceDistance) {
    let item = this.invoceFormData.distances;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index < item.length - 1) {
        item.splice(index, 1);
        item.splice(index + 1, 0, data);
      }
      this.invoceFormData.distances = item;
    }
  }

  @action copyDistance(data: EtranInvoiceDistance) {
    let item = this.invoceFormData.distances;
    const newData = JSON.parse(JSON.stringify(data));
    newData.mockId = Math.random();
    item.push(newData);
    this.invoceFormData.distances = item;
  }

  @action addSpecialMarks(newData: EtranInvoiceSpcModel, oldData: EtranInvoiceSpcModel) {
    let item = this.invoceFormData.specialMarks;
    if (oldData) {
      const index = item.indexOf(oldData);
      if (index != -1) {
        item.splice(index, 1, newData);
      } else {
        item.push(newData);
      }
    } else {
      item.push(newData);
    }
    this.invoceFormData.specialMarks = item;
  }

  @action removeSpecialMarks(data: EtranInvoiceSpcModel) {
    let item = this.invoceFormData.specialMarks;
    const index = item.indexOf(data);
    if (index != -1) {
      item.splice(index, 1);
      this.invoceFormData.specialMarks = item;
    }
  }

  @action upSpecialMarks(data: EtranInvoiceSpcModel) {
    let item = this.invoceFormData.specialMarks;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index > 0) {
        item.splice(index, 1);
        item.splice(index - 1, 0, data);
      }
      this.invoceFormData.specialMarks = item;
    }
  }

  @action downSpecialMarks(data: EtranInvoiceSpcModel) {
    let item = this.invoceFormData.specialMarks;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index < item.length - 1) {
        item.splice(index, 1);
        item.splice(index + 1, 0, data);
      }
      this.invoceFormData.specialMarks = item;
    }
  }

  @action copySpecialMarks(data: EtranInvoiceSpcModel) {
    let item = this.invoceFormData.specialMarks;
    const newData = JSON.parse(JSON.stringify(data));
    newData.mockId = Math.random();
    item.push(newData);
    this.invoceFormData.specialMarks = item;
  }

  @action addDev(newData: EtranInvoiceDev, oldData: EtranInvoiceDev) {
    let item = this.invoceFormData.devs;
    if (oldData) {
      const index = item.indexOf(oldData);
      if (index != -1) {
        item.splice(index, 1, newData);
      } else {
        item.push(newData);
      }
    } else {
      item.push(newData);
    }
    this.invoceFormData.devs = item;
  }

  @action removeDev(data: EtranInvoiceDev) {
    let item = this.invoceFormData.devs;
    const index = item.indexOf(data);
    if (index != -1) {
      item.splice(index, 1);
      this.invoceFormData.devs = item;
    }
  }

  @action upDev(data: EtranInvoiceDev) {
    let item = this.invoceFormData.devs;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index > 0) {
        item.splice(index, 1);
        item.splice(index - 1, 0, data);
      }
      this.invoceFormData.devs = item;
    }
  }

  @action downDev(data: EtranInvoiceDev) {
    let item = this.invoceFormData.devs;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index < item.length - 1) {
        item.splice(index, 1);
        item.splice(index + 1, 0, data);
      }
      this.invoceFormData.devs = item;
    }
  }

  @action copyDev(data: EtranInvoiceDev) {
    let item = this.invoceFormData.devs;
    const newData = JSON.parse(JSON.stringify(data));
    newData.mockId = Math.random();
    item.push(newData);
    this.invoceFormData.devs = item;
  }

  @action addCar(newData: EtranInvoiceCarInfo, oldData: EtranInvoiceCarInfo) {
    let cars = this.invoceFormData.cars;
    if (oldData) {
      const index = cars.indexOf(oldData);
      if (index != -1) {
        cars.splice(index, 1, newData);
      } else {
        cars.push(newData);
      }
    } else {
      cars.push(newData);
    }
    this.invoceFormData.cars = cars;
  }

  @action removeCar(data: EtranInvoiceCarInfo) {
    let cars = this.invoceFormData.cars;
    const index = cars.indexOf(data);
    if (index != -1) {
      cars.splice(index, 1);
      this.invoceFormData.cars = cars;
    }
  }

  @action upCar(data: EtranInvoiceCarInfo) {
    let cars = this.invoceFormData.cars;
    const index = cars.indexOf(data);
    if (index != -1) {
      if (index > 0) {
        cars.splice(index, 1);
        cars.splice(index - 1, 0, data);
      }
      this.invoceFormData.cars = cars;
    }
  }

  @action downCar(data: EtranInvoiceCarInfo) {
    let cars = this.invoceFormData.cars;
    const index = cars.indexOf(data);
    if (index != -1) {
      if (index < cars.length - 1) {
        cars.splice(index, 1);
        cars.splice(index + 1, 0, data);
      }
      this.invoceFormData.cars = cars;
    }
  }

  @action copyCar(data: EtranInvoiceCarInfo) {
    const cars = this.invoceFormData.cars;
    const newData = JSON.parse(JSON.stringify(data));
    newData.mockId = Math.random();
    cars.push(newData);
    this.invoceFormData.cars = cars;
  }

  @action addDoc(newData: EtranInvoiceDocModel, oldData: EtranInvoiceDocModel) {
    let item = this.invoceFormData.docs;
    if (oldData) {
      const index = item.indexOf(oldData);
      if (index != -1) {
        item.splice(index, 1, newData);
      } else {
        item.push(newData);
      }
    } else {
      item.push(newData);
    }
    this.invoceFormData.docs = item;
  }

  @action removeDoc(data: EtranInvoiceDocModel) {
    let item = this.invoceFormData.docs;
    const index = item.indexOf(data);
    if (index != -1) {
      item.splice(index, 1);
      this.invoceFormData.docs = item;
    }
  }

  @action upDoc(data: EtranInvoiceDocModel) {
    let item = this.invoceFormData.docs;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index > 0) {
        item.splice(index, 1);
        item.splice(index - 1, 0, data);
      }
      this.invoceFormData.docs = item;
    }
  }

  @action downDoc(data: EtranInvoiceDocModel) {
    let item = this.invoceFormData.docs;
    const index = item.indexOf(data);
    if (index != -1) {
      if (index < item.length - 1) {
        item.splice(index, 1);
        item.splice(index + 1, 0, data);
      }
      this.invoceFormData.docs = item;
    }
  }

  @action copyDoc(data: EtranInvoiceDocModel) {
    let item = this.invoceFormData.docs;
    const newData = JSON.parse(JSON.stringify(data));
    newData.mockId = Math.random();
    item.push(newData);
    this.invoceFormData.docs = item;
  }

  @action async getEtranInvoice(invoiceId: string) {
    this.loadingInvoice = true;
    return await service
      .getEtranInvoice(invoiceId)
      .then((ans) => {
        this.invoceFormData = etranIvoceInfoToFormData(ans);
        return this.invoceFormData;
      })
      .finally(() => (this.loadingInvoice = false));
  }

  @action async getEtranTelegramList(LoadOptions: any): Promise<any> {
    return await service.getEtranTelegramList(LoadOptions);
  }

  @action async getEtranTelegram(telegramId: string) {
    return await service.getEtranTelegram(telegramId);
  }

  @action async getEtranClaimList(LoadOptions: any): Promise<any> {
    return await service.getEtranClaimList(LoadOptions);
  }

  @action async getEtranClaim(claimId: string) {
    return await service.getEtranClaim(claimId);
  }
}

export default new EtranStore();
