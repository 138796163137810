import { observer } from 'mobx-react';
import React, { Component } from 'react';

import TariffDetailViewerShort from '@components/fw/TarifDetail/TartiffDetailViewerShort';

import optionsStore from '@stores/optionsStore/optionsStore';
import { TariffCountryDetailModel } from '@stores/sendsStore/sendData';

interface State {}

interface Props {
  onOpenCoefficients?(): void;

  onOpenAdditionalCharges?(): void;

  onOpenAdditionalCharges168?(): void;

  countryDetails: TariffCountryDetailModel[];
  deliveryPeriod?: number;
  emptyDeliveryPeriod?: number;
  showTotal: boolean;
}

class OptionsResultViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const request = optionsStore.request;
    const calculateEmptyReturn = request.calculateEmptyReturn;
    const showTotal = this.props.showTotal;
    return (
      <>
        {request && request.freightWeight > 0 ? (
          <h2 style={{ marginLeft: 20 }}>Груженый рейс</h2>
        ) : (
          <h2 style={{ marginLeft: 20 }}>Порожний рейс</h2>
        )}
        <TariffDetailViewerShort
          value={this.props.countryDetails.filter((c) => !c.isEmpty)}
          deliveryPeriod={this.props.deliveryPeriod}
          showHeader={true}
          showBody={true}
          showTotal={showTotal}
          onOpenCoefficients={this.props.onOpenCoefficients}
          onOpenAdditionalCharges={this.props.onOpenAdditionalCharges}
          onOpenAdditionalCharges168={this.props.onOpenAdditionalCharges168}
        />
        {calculateEmptyReturn && (
          <>
            <tr>
              <td colSpan={16}>
                <h2 style={{ marginLeft: 20 }}>Порожний возврат</h2>
              </td>
            </tr>
            <TariffDetailViewerShort
              value={this.props.countryDetails.filter((c) => c.isEmpty)}
              deliveryPeriod={this.props.emptyDeliveryPeriod}
              showHeader={true}
              showBody={true}
              showTotal={showTotal}
              onOpenCoefficients={this.props.onOpenCoefficients}
              onOpenAdditionalCharges={this.props.onOpenAdditionalCharges}
              onOpenAdditionalCharges168={this.props.onOpenAdditionalCharges168}
            />
            <tr>
              <td colSpan={16}>
                <h2 style={{ marginLeft: 20 }}>Груженый рейс + Порожний возврат</h2>
              </td>
            </tr>
            <TariffDetailViewerShort value={this.props.countryDetails} showHeader={false} showBody={false} showTotal={showTotal} />
          </>
        )}
      </>
    );
  }
}

export default observer(OptionsResultViewer);
