import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, Popconfirm, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import { UserModel } from '@stores/usersStore';
import usersService from '@stores/usersStore/usersStore.service';

import { showSuccessNotify } from '@utils/notify';

interface ComponentProps {
  user: UserModel;
}

interface ComponentState {
  isBusy: boolean;
  days: number;
  code: string;
  date: any;
}

class CodeGenerator extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isBusy: false,
      days: 14,
      code: this.props.user.tariffSession ? this.props.user.tariffSession.code : null,
      date: this.props.user.tariffSession ? this.props.user.tariffSession.dateTimeTo : null,
    };
  }

  get url() {
    const code = this.state.code;
    if (!code) return null;
    return window.location.origin + '/app#/code-login/' + code;
  }

  doThat = () => {
    this.setState({ isBusy: true });
    usersService
      .generateCode(this.props.user.userId, moment().add(this.state.days, 'days').toISOString(true))
      .then((code) => {
        this.setState({ code: code, date: moment().add(this.state.days, 'days') });
      })
      .finally(() => this.setState({ isBusy: false }));
  };

  copy = () => {
    if (!this.url) return;
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = this.url;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      showSuccessNotify('Скопировано');
    } catch (err) {
      console.log('Oops, unable to copy');
    }
    document.body.removeChild(textArea);
  };

  onDelete = () => {
    usersService.deleteCode(this.state.code).then(() => {
      this.setState({ code: null, date: null });
    });
  };

  render() {
    if (!this.props.user.userId) return null;
    const code = this.state.code;
    const date = this.state.date;
    return (
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <h4>Ссылка для временного доступа</h4>
        {!code ? (
          <Col span={24}>
            <span>Доступ на</span>
            <InputNumber style={{ width: 100 }} value={this.state.days} onChange={(e) => this.setState({ days: Number(e) })} />
            <span>суток</span>
            <Button type={'primary'} loading={this.state.isBusy} onClick={this.doThat}>
              создать
            </Button>
          </Col>
        ) : (
          <Col span={24}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={22}>
                <Input
                  style={{ height: 48 }}
                  readOnly
                  value={this.url}
                  suffix={<CopyOutlined onClick={this.copy} disabled={!this.url} />}
                />
              </Col>
              <Col className={'timeLink'} span={2}>
                <Popconfirm title={'Удалить?'} onConfirm={this.onDelete}>
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <h5>{moment(date).valueOf() < moment().valueOf() ? 'Просрочен' : 'осталось ' + moment(date).fromNow(true)}</h5>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    );
  }
}

export default CodeGenerator;
