import { Col, Row } from 'antd';
import React, { Component } from 'react';

import { RecursiveSvp } from '@stores/requestsStore/RequestsData';

interface Props {
  data: RecursiveSvp;
}

interface State {}

export const renderRecursiveSpv = (data: RecursiveSvp) => {
  if (data.type.name == 'Request') {
    return (
      <>
        <tr style={{ background: 'none' }}>
          <td style={{ textAlign: 'center' }} colSpan={2}>
            <h2>{data.value}</h2>
          </td>
        </tr>
        {data.items && data.items.map((item, index) => <RecursiveSpvElement data={item} key={index} />)}
      </>
    );
  }
  if (data.type.name == 'Group') {
    return (
      <>
        <tr style={{ background: 'none' }}>
          <td colSpan={2}>
            <h3>{data.value}</h3>
          </td>
        </tr>
        {data.items && data.items.map((item, index) => <RecursiveSpvElement data={item} key={index} />)}
      </>
    );
  }
  if (data.type.name == 'Field') {
    return (
      <>
        <tr style={{ background: 'none' }}>
          <td>
            <span style={{ marginLeft: 5 }}>{data.name}</span>
          </td>
          <td>{data.value}</td>
        </tr>
        {data.items && data.items.map((item, index) => <RecursiveSpvElement data={item} key={index} />)}
      </>
    );
  }
  if (data.type.name == 'MergedGroup') {
    const span = Math.round(24 / (data.items.length + 1));
    const item0 = data.items.find((i) => i.order == 0);
    return (
      <>
        <tr style={{ background: 'none' }}>
          <td colSpan={2}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={span}>
                {' '}
                <h3>{data.value}</h3>
              </Col>
              {data.items &&
                data.items.map((item, index) => (
                  <Col span={span} key={index}>
                    {' '}
                    <h3>{item.value}</h3>{' '}
                  </Col>
                ))}
            </Row>
          </td>
        </tr>
        {item0 && item0.items
          ? item0.items.map((item_name) => (
              <tr style={{ background: 'none' }}>
                <td colSpan={2}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={span}>{item_name.name}</Col>
                    {data.items &&
                      data.items.map((item_value, index) => {
                        const find =
                          item_value.items && item_value.items.length ? item_value.items.find((i) => i.order == item_name.order) : null;
                        return (
                          <Col span={span} key={index}>
                            {' '}
                            <span>{find?.value}</span>{' '}
                          </Col>
                        );
                      })}
                  </Row>
                </td>
              </tr>
            ))
          : null}
      </>
    );
  }
};

class RecursiveSpvElement extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const data = this.props.data;
    return <>{renderRecursiveSpv(data)}</>;
  }
}

export default RecursiveSpvElement;
