import { action, makeObservable, observable } from 'mobx';

import { ICreateTariffInfologModel, ITariffInfologModel, ITariffInfologResponseItem } from './tariffInfologData';
import service from './tariffInfologStore.service';

class TariffInfologStore {
  @observable isBusy: boolean = false;
  @observable tariffs: ITariffInfologResponseItem[] = [];
  @observable availableTariffs: ITariffInfologResponseItem[] = [];
  @observable userTariffs: ITariffInfologResponseItem[] = [];
  @observable tariff: ITariffInfologModel = null;

  constructor() {
    makeObservable(this);
  }

  @action async createTariff(data: ICreateTariffInfologModel) {
    return await service.createTariff(data);
  }

  @action async getTariffs() {
    this.isBusy = true;
    try {
      this.tariffs = await service.getTariffs();
    } catch (e) {
    } finally {
      this.isBusy = false;
    }
  }

  @action async getTariffById(id: string) {
    this.isBusy = true;
    try {
      this.tariff = await service.getTariffById(id);
    } catch (e) {
    } finally {
      this.isBusy = false;
    }
  }

  @action async deleteTariff(id: string) {
    this.isBusy = true;
    try {
      await service.deleteTariff(id);
    } catch (e) {
    } finally {
      this.isBusy = false;
    }
  }

  @action async updateTariff(id: string, data: ICreateTariffInfologModel) {
    this.isBusy = true;
    try {
      await service.updateTariff(id, data);
    } catch (e) {
    } finally {
      this.isBusy = false;
    }
  }

  @action async getAvailableTariff() {
    this.isBusy = true;
    try {
      this.availableTariffs = await service.getAvailableTariff();
    } catch (e) {
    } finally {
      this.isBusy = false;
    }
  }

  @action async getUserTariff(userId: string) {
    this.isBusy = true;
    try {
      this.userTariffs = await service.getUserTariff(userId);
    } catch (e) {
    } finally {
      this.isBusy = false;
    }
  }

  @action clearTariffs() {
    this.tariffs = [];
  }
}

export default new TariffInfologStore();
