import { Button, Form, FormInstance, Input, Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import designStore from '@stores/designStore/designStore';
import userStore from '@stores/userStore/userStore';

import styles from './LoginPage.module.scss';
import PasswordResetModal from './PasswordResetModal';

const { Content } = Layout;

interface State {
  showPasswordChange: boolean;
  loginForPasswordChange: string;
}

class LoginPage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      showPasswordChange: false,
      loginForPasswordChange: '',
    };
  }

  form: FormInstance;
  render() {
    const isMobile = designStore.height > designStore.width;
    const logonBusy = userStore.logonBusy;
    return (
      <Layout data-test="auth-layout" className={styles.container}>
        <Content className={styles.content} style={isMobile ? { paddingTop: '20px' } : { paddingTop: '100px' }}>
          <AdaptiveCard title={<img height={40} src={designStore.logoMax} />} className={'login-page'}>
            <Form
              hideRequiredMark
              ref={(ref) => (this.form = ref)}
              layout={'horizontal'}
              labelCol={{ span: 6 }}
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}>
              <Form.Item
                style={{ margin: 10 }}
                label="Логин"
                name="username"
                rules={[{ required: true, message: 'Необходимо заполнить поле "Логин"' }]}>
                <Input size={'large'} data-test="login" />
              </Form.Item>
              <Form.Item
                style={{ margin: 10 }}
                label="Пароль"
                name="password"
                hasFeedback
                rules={[{ required: true, message: 'Необходимо заполнить поле "Пароль"' }]}>
                <Input.Password maxLength={15} size={'large'} style={{ height: 40 }} data-test="password" />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6, span: 17 }} style={{ margin: 10 }}>
                <Button type="primary" htmlType="submit" loading={logonBusy} data-test="submit">
                  Войти
                </Button>
                <Button type="link" onClick={() => this.showPasswordChange()}>
                  забыли пароль?
                </Button>
              </Form.Item>
            </Form>
          </AdaptiveCard>
          <PasswordResetModal
            showPasswordChange={this.state.showPasswordChange}
            loginForPasswordChange={this.state.loginForPasswordChange}
            onClose={() => this.setState({ showPasswordChange: false, loginForPasswordChange: null })}
          />
        </Content>
      </Layout>
    );
  }

  handleSubmit = (event) => {
    userStore.login(event.username, event.password, this.props.history);
    // setAuthority(event.username, event.password);
    // this.props.history.push('/');
  };

  showPasswordChange = () => {
    this.setState({
      showPasswordChange: true,
      loginForPasswordChange: this.form ? this.form.getFieldValue('username') : '',
    });
  };

  onFinishFailed = (error) => {
    console.log('Failed:', error);
  };
}

export default observer(LoginPage);
