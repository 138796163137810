import { Card, CardProps } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

class AdaptiveCard extends Component<CardProps & { disablePadding?: boolean }> {
  constructor(props) {
    super(props);
  }

  render() {
    const isMobile = designStore.isMobile;
    const height = this.props.style ? this.props.style.height : null;
    let className = null;
    if (!this.props.disablePadding) {
      className = isMobile ? `ant-card-mobile ${this.props.className}` : `ant-card-padding ${this.props.className}`;
    }
    return (
      <div className={className} style={{ height }}>
        <Card {...this.props}>{this.props.children}</Card>
      </div>
    );
  }
}

export default observer(AdaptiveCard);
