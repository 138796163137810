import { CheckOutlined, DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Row, Spin, Tag } from 'antd';
import Search from 'antd/es/input/Search';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';
import styles from '@components/fw/TagEditor/TagEditor.module.scss';

import { MarkModel } from '@stores/markStore';
import userStore from '@stores/userStore/userStore';

import './markEditor.less';

interface MarkListProps {
  notes: MarkModel[];
  selected: MarkModel[];
  isBusy: boolean;

  onClick(tag: MarkModel): void;

  onAddNew(): void;

  onEdit(tag: MarkModel): void;

  onDelete(tag: MarkModel): void;
}

interface MarkListState {
  note: any;
  search: string;
}

class MarkList extends Component<MarkListProps, MarkListState> {
  constructor(props: MarkListProps) {
    super(props);
    this.state = {
      note: null,
      search: null,
    };
  }

  render() {
    const search = this.state.search;
    const notes = this.props.notes.filter((p) => {
      return !search || p.title.toLowerCase().includes(search.toLowerCase());
    });

    return (
      <div className={'mark-list'}>
        <Spin spinning={this.props.isBusy}>
          <Search
            placeholder="поиск"
            onChange={(e) =>
              this.setState({
                search: e.target.value,
              })
            }
          />
          <Row className={'mark-list--head'} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <b>Название</b>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <b>Цвет строки</b>
            </Col>
            <Col span={4} />
          </Row>
          <ScrolledContainer style={{ maxHeight: 400 }}>
            {notes.map((note, index) => {
              const canEdit = note.creatorId == userStore.userData.userId || !note.isBelongsCreator;
              const isContains = this.props.selected.map((x) => x.id).includes(note.id);
              return (
                <Row key={`mark_all_${index}`}>
                  <Col span={12}>
                    <Tag
                      color={note.description}
                      style={{ cursor: 'pointer' }}
                      icon={note.isBelongsCreator ? <UserOutlined /> : null}
                      onClick={() => this.props.onClick(note)}>
                      {isContains ? <CheckOutlined style={{ marginRight: 5 }} /> : null}
                      {note.title}
                    </Tag>
                  </Col>
                  <Col span={8} className={'mark-list--report-row-color'}>
                    {note.reportRowColor ? 'Да' : '-'}
                  </Col>
                  <Col span={4}>
                    <span className={styles.tag_list_icons_block}>
                      {canEdit && <EditOutlined className={styles.tag_list_icon} onClick={() => this.props.onEdit(note)} />}
                      {canEdit && (
                        <Popconfirm title={`Метка "${note.title}" будет удалёна. Продолжить?`} onConfirm={() => this.props.onDelete(note)}>
                          <DeleteOutlined className={styles.tag_list_icon} />
                        </Popconfirm>
                      )}
                      {!canEdit && <span>Метка пользователя {note.creatorName}</span>}
                    </span>
                  </Col>
                </Row>
              );
            })}
          </ScrolledContainer>
        </Spin>
        <Divider />
        <div>
          <Button type="dashed" block onClick={this.props.onAddNew}>
            Добавить
          </Button>
        </div>
      </div>
    );
  }
}

export default observer(MarkList);
