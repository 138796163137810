import { Tabs } from 'antd';
import qs from 'qs';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import DispatcherInfoDetail from './DispatcherInfoDetail';

interface State {}

class DispatcherInfoPage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  get type(): 'wagon' | 'container' {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).type as 'wagon' | 'container';
  }

  get list(): string[] {
    let filter = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).w;
    if (filter) {
      if (Array.isArray(filter)) {
        return filter as string[];
      } else {
        return [filter] as string[];
      }
    } else return [];
  }

  componentDidMount() {
    document.title = 'Информация ' + this.list.join(' ');
  }

  render() {
    return (
      <AdaptiveCard>
        <Tabs style={{ padding: '6px 18px' }}>
          {this.list.map((w) => (
            <Tabs.TabPane key={w} tab={w}>
              <DispatcherInfoDetail type={this.type} number={w} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </AdaptiveCard>
    );
  }
}

export default DispatcherInfoPage;
