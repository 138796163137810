import { Button, List, Modal, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import EtranRemoteSelect from '@components/routed/EtranPage/EtranRemoteSelect.';

import designStore from '@stores/designStore/designStore';
import { EtranOrganizationPassportInfo } from '@stores/etranStore/EtranData';
import etranService from '@stores/etranStore/etranStore.service';

import { showErrorNotify } from '@utils/notify';

interface Props {
  onChange?: (value: any) => void;
  value?: any;
  name?: any;
  formRef: FormInstance;
  width?: number;
  address?: boolean;
  label?: string;
}

interface State {
  loading: boolean;
  showModal: boolean;
  org: EtranOrganizationPassportInfo;
}

@observer
class EtranOrganization extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      org: null,
    };
  }

  onSelectAddress = (address) => {
    const org = this.state.org;
    org.addresses = [address];
    if (this.props.onChange) {
      this.props.onChange(org);
    }
    this.setState({ showModal: false, org: null });
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Modal
          width={designStore.isMobile ? '100%' : '60%'}
          open={this.state.showModal}
          onCancel={() => this.setState({ showModal: false })}
          title={'Выберите адрес'}
          okButtonProps={{ style: { display: 'none' } }}>
          {this.state.showModal && (
            <List
              dataSource={this.state.org.addresses}
              renderItem={(item) => (
                <List.Item actions={[<Button onClick={() => this.onSelectAddress(item)}>Выбрать</Button>]}>
                  <List.Item.Meta description={item.addressText} title={'тгнл: ' + item.addressTgnl} />
                </List.Item>
              )}
            />
          )}
        </Modal>
        <EtranRemoteSelect
          form={this.props.formRef}
          data={etranService.getEtranOrganizationDictionary}
          valueExpr={'orgId'}
          displayExpr={'orgName'}
          label={this.props.label}
          name={this.props.name}
          extra={'orgOkpo'}
          width={this.props.width}
          onSelect2={(e) => {
            this.setState({ loading: true });
            etranService
              .getEtranOrganizationByOrgId(e.orgId)
              .then((ans) => {
                if (!ans) {
                  showErrorNotify(e.orgName, 'Организация не найдена');
                  this.props.formRef.resetFields([this.props.name]);
                }
                if (!this.props.address || !ans.addresses || ans.addresses.length < 2) {
                  if (this.props.onChange) this.props.onChange(ans);
                } else {
                  this.setState({ showModal: true, org: ans });
                }
              })
              .finally(() => this.setState({ loading: false }));
          }}
        />
      </Spin>
    );
  }
}

export default EtranOrganization;
