import { Checkbox, Form } from 'antd';
import React from 'react';

import styles from '@components/fw/Fields/field.module.scss';

import { FieldCheckboxProps } from './field-checkbox.types';

export const FieldCheckbox = ({ rules, name, ...checkboxProps }: FieldCheckboxProps) => {
  return (
    <Form.Item className={styles.fieldFlexAuto} rules={rules} name={name} valuePropName="checked">
      <Checkbox {...checkboxProps} />
    </Form.Item>
  );
};
