import HttpClient from '@utils/httpClient';

const service = {
  getLogs(data: any) {
    return HttpClient.post(`/api/log/list`, data).then((resp) => {
      return resp.data;
    });
  },

  in() {
    return HttpClient.post(`/api/log/in`).then((resp) => {
      return resp.data;
    });
  },

  out() {
    return HttpClient.post(`/api/log/out`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
