import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface AccessRouteProps {
  canAccess: boolean;
  component: any;
}

const AccessRoute = ({ canAccess, component, ...rest }) => {
  return canAccess ? (
    <Route {...rest} component={component} />
  ) : (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
};

export default AccessRoute;
