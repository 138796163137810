import { Spin } from 'antd';
import React, { Component } from 'react';

import { CompanyEditorProps } from '@components/fw/Drawers/CompanyEditor/CompanyEditor';

const CompanyEditor = React.lazy(() => import('./CompanyEditor'));

class CompanyEditorWrapper extends Component<CompanyEditorProps> {
  constructor(props: CompanyEditorProps) {
    super(props);
  }

  render() {
    return (
      <React.Suspense fallback={<Spin spinning={true} />}>
        <CompanyEditor {...this.props} />
      </React.Suspense>
    );
  }
}

export default CompanyEditorWrapper;
