import { Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import RemoteSelect from '@components/fw/RemoteSelect';

interface EtranRemoteSelectProps {
  form?: FormInstance;
  label?: string;
  data: any;
  valueExpr: string;
  displayExpr: string;
  extra?: string;
  itemTemplate?(item: any): void;
  name: string;
  onChange?(value): void;
  onSelect2?(value): void;
  width?: number;
}

interface EtranRemoteSelectState {}

class EtranRemoteSelect extends Component<EtranRemoteSelectProps, EtranRemoteSelectState> {
  constructor(props: EtranRemoteSelectProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, valueExpr, displayExpr, label, itemTemplate, extra, name, form, width } = this.props;
    const fieldValue = form ? form.getFieldValue(name) : null;
    const extraValue = fieldValue ? fieldValue[extra] : null;

    return (
      <Form.Item
        name={name}
        label={label}
        className={!!extra ? 'etran-form-item etran-extra' : 'etran-form-item'}
        extra={!!extra ? <Input value={extraValue} readOnly /> : undefined}>
        <RemoteSelect
          placeholder={''}
          dataSource={data}
          valueExpr={valueExpr}
          displayExpr={displayExpr}
          itemTemplate={itemTemplate}
          onChange={this.onChange}
          onSelect2={(e) => {
            if (this.props.onSelect2) {
              this.props.onSelect2(e);
            }
          }}
        />
      </Form.Item>
    );
  }

  onChange = (value) => {
    if (this.props.onChange) this.props.onChange(value);
    this.forceUpdate();
  };
}

export default observer(EtranRemoteSelect);
