const TOKEN_KEY = 'strada-authority';

export function getAuthority(): { access_token: string; refresh_token: string; tmpUser?: boolean } {
  const authorityString = localStorage.getItem(TOKEN_KEY);
  let authority;
  try {
    if (authorityString) {
      authority = JSON.parse(authorityString);
    }
  } catch (e) {}
  return authority;
}

export function setAuthority(token: { access_token: string; refresh_token: string; tmpUser?: boolean }): void {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
}

export function logout() {
  localStorage.removeItem(TOKEN_KEY);
  window.location.reload();
}
