import { Button, Col, Form, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import BorderWrapper from '@components/fw/FloatLabel/BorderWrapper';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputPassword from '@components/fw/FloatLabel/FloatInputPassword';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import SwitchWithLabel from '@components/fw/FloatLabel/SwitchWithLabel';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import userStore, { START_PAGES } from '@stores/userStore/userStore';
import { UserModel } from '@stores/usersStore';
import usersStore from '@stores/usersStore/usersStore';

import { showSuccessNotify } from '@utils/notify';

import UserProfileDeleteModal from './UserProfileDeleteModal';
import UserProfileWorkplaceSettingsDrawer from './UserProfileWorkplaceSettingsDrawer';

interface UserProfileState {
  userData: UserModel;
  isBusy: boolean;
  uploading: boolean;
}

class UserProfile extends Component<RouteComponentProps, UserProfileState> {
  formRef: FormInstance;
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      userData: null,
      isBusy: true,
      uploading: false,
    };
  }

  componentDidMount(): void {
    const user = userStore.userData;
    usersStore
      .getUser(user.userId)
      .finally(() => this.setState({ isBusy: false }))
      .then((result) => {
        this.setState({ userData: result });
        this.formRef.resetFields();
      });
    document.title = 'Профиль';
  }

  render() {
    const user = userStore.userData;
    const userData = this.state.userData;
    const avlbStartPages = userData ? START_PAGES.filter((p) => userData.roleAccessList.includes(p.rule)) : [];

    if (userData) {
      Object.assign(userData, {
        birthdayDate: userData.birthdayDate ? moment(userData.birthdayDate) : null,
      });
    }
    const isBusy = this.state.isBusy;

    return (
      <>
        <AdaptivePageHeader
          title={user.userName}
          subTitle={<span style={{ marginLeft: 10 }}>{user.roleName}</span>}
          className={'page_header'}
        />
        <ScrolledContainer style={{ height: 'calc(100vh - var(--header-height))' }}>
          <AdaptiveCard>
            <Spin spinning={isBusy}>
              <Form
                layout="vertical"
                name={'profile-editor'}
                initialValues={userData}
                size="large"
                ref={(ref) => (this.formRef = ref)}
                onFinish={this.handleConfirmUpdateForm}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24}>
                    <Form.Item name="name" rules={[{ required: true, message: 'Необходимо заполнить ФИО' }]}>
                      <FloatInput placeholder="ФИО" />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: 'Необходимо заполнить email' },
                        {
                          type: 'email',
                          message: 'Невалидный Email',
                        },
                      ]}>
                      <FloatInput placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="password" rules={[{ required: false, message: 'Необходимо заполнить пароль' }]}>
                      <FloatInputPassword maxLength={25} placeholder="Новый пароль" autoComplete={'new-password'} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="confirm-password"
                      dependencies={['password']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (getFieldValue('password') && !value) {
                              return Promise.reject('Необходимо подтвердить пароль');
                            }
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject('Пароли не совпадают');
                          },
                        }),
                      ]}>
                      <FloatInputPassword maxLength={15} placeholder="Подтвердить пароль" autoComplete={'new-password'} />
                    </Form.Item>
                  </Col>
                </Row>
                <h4>Телефоны</h4>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={8} md={8}>
                    <Form.Item name="mobilePhoneNumber">
                      <InputMask mask="+9999999999999">{(inputProps) => <FloatInput placeholder="Мобильный" type={'tel'} />}</InputMask>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} md={8}>
                    <Form.Item name="workPhoneNumber">
                      <InputMask mask="+9999999999999">
                        {(inputProps) => <FloatInput placeholder="Рабочий телефон" type={'tel'} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} md={8}>
                    <Form.Item name="otherPhoneNumber">
                      <InputMask mask="+9999999999999">{(inputProps) => <FloatInput placeholder="Другой" type={'tel'} />}</InputMask>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item name="birthdayDate">
                      <FloatDatepicker format={GLOBAL_DATE_FORMAT} placeholder={'Дата рождения'} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={16}>
                    <Form.Item name="post">
                      <FloatInput placeholder="Должность" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name="startPage">
                      <FloatSelect allowClear placeholder={'Стартовая страница'}>
                        {avlbStartPages.map((p) => (
                          <Select.Option key={p.path} value={p.path}>
                            {p.name}
                          </Select.Option>
                        ))}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <BorderWrapper>
                      <Form.Item name="assistant" style={{ marginLeft: '10px' }} valuePropName={'checked'}>
                        <SwitchWithLabel>Получать сообщения виртуального помощника</SwitchWithLabel>
                      </Form.Item>
                    </BorderWrapper>
                  </Col>
                  <Col xs={24} md={12}>
                    <BorderWrapper>
                      <Form.Item name="otherTariffEnable" style={{ marginLeft: '10px' }} valuePropName={'checked'}>
                        <SwitchWithLabel>Показывать ставки компаний в разделе "Расчет тарифа"</SwitchWithLabel>
                      </Form.Item>
                    </BorderWrapper>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={12} md={12}>
                    <Form.Item style={{ marginTop: '30px' }}>
                      <Button type="primary" htmlType="submit">
                        Обновить данные
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12}>
                    <Form.Item style={{ marginTop: '30px' }}>
                      <UserProfileDeleteModal />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <UserProfileWorkplaceSettingsDrawer />
            </Spin>
          </AdaptiveCard>
        </ScrolledContainer>
      </>
    );
  }

  handleConfirmUpdateForm = (event) => {
    this.setState({ isBusy: true });
    const result = { ...event };
    usersStore
      .updateProfile(result)
      .finally(() => this.setState({ isBusy: false }))
      .then(() => {
        showSuccessNotify('Профиль сохранён');
        window.location.reload();
      });
  };
}

export default observer(UserProfile);
