import React from 'react';

const CatalogDxCell: React.FC<any> = (props) => {
  const value = props.value;
  return value && Array.isArray(value) ? (
    <span>
      {value.map((tag, index) => {
        return (
          <div key={`tag_${index}`}>
            {tag.type == 'Rent' ? '(A)' : ''} {tag.title}
          </div>
        );
      })}
    </span>
  ) : null;
};

export default CatalogDxCell;
