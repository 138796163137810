import { Divider, Form, Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import { Flex } from '@components/fw/Flex';

import { SupplierBidRegistryDirection, supplierBidRegistryStore } from '@stores/supplierBidRegistryStore';

import { COLUMNS } from './lib/columns';

export const DirectionTable = observer(() => {
  const { supplierBidRegistry, sortedDirections } = supplierBidRegistryStore;

  const Title = ({ freight }: { freight: string }) => {
    return (
      <Flex vertical justify="center">
        {supplierBidRegistry.rps}
        <Divider style={{ margin: 0 }} />
        {freight}
      </Flex>
    );
  };

  return (
    <>
      {Object.entries(sortedDirections).map(([freight, directions]) => (
        <AdaptiveCard hoverable key={freight} title={<Title freight={freight} />}>
          <Form.Item name={freight}>
            <Table rowKey={getRowKey} bordered={true} pagination={false} columns={COLUMNS} dataSource={directions} />
          </Form.Item>
        </AdaptiveCard>
      ))}
    </>
  );
});

const getRowKey = (record: SupplierBidRegistryDirection) => record.supplierBidDirectionId;
