import { Spin } from 'antd';
import DataGrid, { Column, GroupPanel, HeaderFilter, StateStoring } from 'devextreme-react/data-grid';
import React, { Component } from 'react';

import trackingContainerStore from '@stores/trackingStore/container/trackingContainerStore';
import { TrackingHistoryBase } from '@stores/trackingStore/trackingStoreBase';
import trackingWagonStore from '@stores/trackingStore/wagon/trackingWagonStore';
import userStore from '@stores/userStore/userStore';

interface TrackingHistoryTableProps {
  value: TrackingHistoryBase[];
  isBusy: boolean;
  type: 'wagon' | 'container';
}

interface TrackingHistoryTableState {}

class TrackingHistoryTable extends Component<TrackingHistoryTableProps, TrackingHistoryTableState> {
  dataGrid: DataGrid;
  componentDidMount(): void {
    trackingContainerStore.clearSelected();
    trackingWagonStore.clearSelected();
  }

  render() {
    const data = this.props.value;
    const isBusy = this.props.isBusy;
    const showCompany = !userStore.isOrgOrUser;
    const height = 'calc(100vh - 130px)';

    return (
      <Spin spinning={isBusy}>
        <DataGrid
          height={height}
          allowColumnReordering={true}
          dataSource={data}
          ref={(ref) => (this.dataGrid = ref)}
          loadPanel={{ enabled: false }}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={10}
          showRowLines={true}
          onSelectionChanged={this.handleSelectionChanged}
          onRowDblClick={this.handleRowDoubleClick}
          onContextMenuPreparing={this.handleContextMenuPreparing}
          selection={{ mode: 'single' }}>
          <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'trackingHistoryTable'} />
          <GroupPanel visible={true} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Column allowFixing={true} dataField="id" caption={'ID'} />
          <Column allowFixing={true} dataField={this.props.type == 'wagon' ? 'wagonNumber' : 'containerNumber'} caption={'Номер'} />
          <Column allowFixing={true} dataField="userName" caption={'Пользователь'} />
          {showCompany && <Column allowFixing={true} dataField="companyName" caption={'Компания'} groupIndex={0} />}
          <Column allowFixing={true} dataField="actionName" caption={'Действие'} />
          <Column allowFixing={true} dataField="dateTime" caption={'Дата'} dataType={'date'} />
        </DataGrid>
      </Spin>
    );
  }
  handleSelectionChanged = (e) => {
    if (this.props.type == 'wagon')
      trackingWagonStore.selected = e.selectedRowsData.map((w) => {
        return { number: w.wagonNumber };
      });
    else
      trackingContainerStore.selected = e.selectedRowsData.map((w) => {
        return { number: w.containerNumber };
      });
  };

  handleRowDoubleClick = () => {};

  handleContextMenuPreparing = () => {};
}
export default TrackingHistoryTable;
