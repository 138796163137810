import Icon from '@ant-design/icons';
import { Button, notification } from 'antd';
import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Column,
  Export,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  StateStoring,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import saveAs from 'file-saver';
import moment from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ReactComponent as filter } from '@assets/icons/filter.svg';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import FilterEditor from '@components/fw/FiltersEditor/FilterEditor';
import NumberFilterModal from '@components/fw/NumbersFilter/NumberFilterModal';
import { INDEX_COLUMN, getCalculateFilter, getFilter, getRender } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import filterStore, { FilterContentModel, ReportFilterType } from '@stores/FilterStore/filterStore';
import equipmentStore from '@stores/equipmentStore/equipmentStore';
import equipmentStoreService from '@stores/equipmentStore/equipmentStore.service';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface State {
  dataSource: DataSource;
  reloading: boolean;
}

class EquipmentPage extends Component<RouteComponentProps, State> {
  dataGrid: DataGrid;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      dataSource: this.dataSource,
      reloading: false,
    };
  }

  componentDidMount(): void {
    equipmentStore.filteredWagons = [];
  }

  get dataSource() {
    const pKey = 'wagonnumber';
    return new DataSource({
      store: new CustomStore({
        key: pKey,
        load: function (loadOptions: any) {
          return equipmentStore
            .load(loadOptions)
            .then((data) => {
              if (data.data && data.data.length) {
                data.data.forEach((d) => {
                  if (d.document) {
                    d.document = JSON.parse(d.document);
                  }
                  if (d.bolsters) {
                    d.bolsters = JSON.parse(d.bolsters);
                  }
                  if (d.frames) {
                    d.frames = JSON.parse(d.frames);
                  }
                  if (d.wheelpairs) {
                    d.wheelpairs = JSON.parse(d.wheelpairs);
                  }
                });
              }
              return data;
            })
            .catch(() => {
              throw 'Data Loading Error';
            });
        },
        update: (key, values) => {
          return Promise.all([]);
        },
      }),
    });
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') e.rowElement.style.height = '75px';
  }

  get filterType(): ReportFilterType {
    return 'equipment';
  }

  hardReload(item: FilterContentModel | null) {
    if (this.dataGrid) {
      if (!item) {
        this.dataGrid.instance.clearFilter();
        this.dataGrid.instance.clearGrouping();
        this.dataGrid.instance.clearSorting();
        equipmentStore.filteredWagons = [];
      } else {
        equipmentStore.filteredWagons = filterStore.getNumbers(this.filterType);
      }
      this.setState({ reloading: true });
      setTimeout(() => this.setState({ reloading: false }), 1);
    }
  }

  filterSelectorTemplate = () => {
    return <FilterEditor reportType={this.filterType} reportId={null} onChange={(item) => this.hardReload(item)} />;
  };

  numberFilterButtonTemplate = () => {
    const hasFilter = !!equipmentStore.filteredWagons.length;
    return (
      <Button
        title={'Фильтр по списку номеров'}
        type={hasFilter ? 'primary' : 'text'}
        icon={<Icon style={{ color: hasFilter ? null : '#4977e9' }} component={filter} />}
        onClick={() => (equipmentStore.numbersFilterModalVisible = true)}
      />
    );
  };

  handleStorageSave = (e) => {
    filterStore.setContent(e, this.filterType);
  };

  handleStorageLoad = (e) => {
    return filterStore.getContent(this.filterType);
  };

  render() {
    const jsonFields = {
      wagoncatalogs: 'Подразделения',
      wagonnotes1: 'Метки1',
      wagonnotes2: 'Метки2',
      wagonnotes3: 'Метки3',
    };
    return (
      <>
        <AdaptivePageHeader title={'Комплектация'} extra={null} />
        <AdaptiveCard>
          <DataGrid
            className="equipmentTable"
            onRowPrepared={this.onRowPrepared}
            allowColumnReordering={true}
            ref={(r) => (this.dataGrid = r)}
            dataSource={this.state.dataSource}
            onExporting={this.onExporting}
            columnFixing={{ enabled: true }}
            height={'calc(100vh - 128px)'}
            allowColumnResizing={true}
            remoteOperations={true}
            showBorders={true}
            columnResizingMode={'widget'}
            columnAutoWidth={true}
            showColumnLines={true}
            columnMinWidth={30}
            onToolbarPreparing={this.onToolbarPreparing.bind(this)}
            showRowLines={true}
            rowAlternationEnabled={true}
            onOptionChanged={(e) => {
              if (e.name === 'searchPanel') {
                this.state.dataSource.loadOptions().filter = ['metadata', 'contains', e.value];
              }
            }}>
            <StateStoring enabled={true} type="custom" customSave={(e) => this.handleStorageSave(e)} customLoad={this.handleStorageLoad} />

            <Paging enabled={true} defaultPageSize={10} />
            <Pager
              allowedPageSizes={[5, 10, 20]}
              showPageSizeSelector={true}
              showNavigationButtons={true}
              showInfo={true}
              infoText={'Всего: {2}'}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Export enabled={true} excelWrapTextEnabled={true} />
            {INDEX_COLUMN}
            <Column dataField={'wagonnumber'} dataType={'number'} caption={'Вагон'} />
            {Object.keys(jsonFields).map((f) => (
              <Column
                dataField={f}
                calculateFilterExpression={(dxValue, selectedFilterOperations, target) =>
                  getCalculateFilter(dxValue, selectedFilterOperations, target, f)
                }
                filterOperations={['contains', 'notcontains']}
                cellRender={getRender({ name: f })}
                groupCellRender={getRender({ name: f })}
                headerFilter={getFilter(f)}
                dataType={'string'}
                caption={jsonFields[f]}
              />
            ))}
            <Column caption={'Документы'}>
              <Column
                width={103}
                dataField={'document.BuildDate'}
                dataType={'number'}
                caption={'Год Создания'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
              />
              <Column
                width={103}
                dataField={'document.BuildDepot'}
                dataType={'string'}
                caption={'Изготовитель'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
              />
              <Column
                width={103}
                dataField={'document.MaintenanceType'}
                dataType={'string'}
                caption={'Вид ремонта'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
              />
              <Column
                width={103}
                dataField={'document.MaintenanceDate'}
                dataType={'date'}
                caption={'Дата ремонта'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
              />
              <Column
                width={103}
                dataField={'document.MaintenanceDepot'}
                dataType={'string'}
                caption={'Депо ремонта'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
              />
            </Column>
            <Column caption={'Надрессорные балки'}>
              <Column
                width={64}
                caption={'Номер'}
                dataField={'bolsters'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.DetailNumber}
                    </div>
                  ))
                }
              />
              <Column
                width={72}
                caption={'Позиция'}
                dataField={'bolsters'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) => {
                  if (!e.value.length) return null;
                  return (
                    <div className={'alignCenter'}>
                      <div>1</div> <div>2</div>
                    </div>
                  );
                }}
              />
              <Column
                width={135}
                caption={'Завод изготовитель'}
                dataField={'bolsters'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.BuildDepot}
                    </div>
                  ))
                }
              />
              <Column
                width={125}
                caption={'Год изготовления'}
                dataField={'bolsters'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.BuildYear}
                    </div>
                  ))
                }
              />
            </Column>
            <Column caption={'Боковые рамы'}>
              <Column
                width={64}
                caption={'Номер'}
                dataField={'frames'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.DetailNumber}
                    </div>
                  ))
                }
              />
              <Column
                width={72}
                caption={'Позиция'}
                dataField={'frames'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) => {
                  if (!e.value.length) return null;
                  return (
                    <div className={'alignCenter'}>
                      <div>1</div> <div>2</div> <div>3</div>
                      <div>4</div>
                    </div>
                  );
                }}
              />
              <Column
                width={135}
                caption={'Завод изготовитель'}
                dataField={'frames'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.BuildDepot}
                    </div>
                  ))
                }
              />
              <Column
                width={126}
                caption={'Год изготовления'}
                dataField={'frames'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.BuildYear}
                    </div>
                  ))
                }
              />
            </Column>

            <Column caption={'Колесные пары'}>
              <Column
                width={64}
                caption={'Номер'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) => {
                  return e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.DetailNumber}
                    </div>
                  ));
                }}
              />
              <Column
                width={64}
                caption={'Ось'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) => {
                  if (!e.value.length) return null;
                  return (
                    <div className={'alignCenter'}>
                      <div>1</div> <div>2</div> <div>3</div>
                      <div>4</div>
                    </div>
                  );
                }}
              />

              <Column
                caption={'Полное освидетельствование'}
                dataType={'date'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => {
                    const date = moment(m.InspectionDate, 'YYYY-MM-DD').format(GLOBAL_DATE_FORMAT);
                    return (
                      <div className={'alignCenter'} key={m.DetailNumber}>
                        {date}
                      </div>
                    );
                  })
                }
              />
              <Column
                caption={'Предприятие полного освидетельствования'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.InspectionDepot}
                    </div>
                  ))
                }
              />
              <Column
                width={125}
                caption={'Год изготовления'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.BuildYear}
                    </div>
                  ))
                }
              />
              <Column
                width={135}
                caption={'Завод изготовитель'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.BuildDepot}
                    </div>
                  ))
                }
              />
              <Column
                caption={'Толщина обода правого колеса'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.RimThicknessOfTheRightWheel}
                    </div>
                  ))
                }
              />
              <Column
                caption={'Толщина обода левого колеса'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.RimThicknessOfTheLeftWheel}
                    </div>
                  ))
                }
              />
              <Column
                caption={'Толщина гребня правого колеса'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.CrestThicknessOfTheRightWheel}
                    </div>
                  ))
                }
              />
              <Column
                caption={'Толщина гребня левого колеса'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.CrestThicknessOfTheLeftWheel}
                    </div>
                  ))
                }
              />
              <Column
                caption={'Прокат правого колеса'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.RightWheelRun}
                    </div>
                  ))
                }
              />

              <Column
                caption={'Прокат левого колеса'}
                dataField={'wheelpairs'}
                allowSorting={false}
                allowGrouping={false}
                allowSearch={false}
                allowFiltering={false}
                allowReordering={false}
                cellRender={(e) =>
                  e.value.map((m) => (
                    <div className={'alignCenter'} key={m.DetailNumber}>
                      {m.LeftWheelRun}
                    </div>
                  ))
                }
              />
            </Column>
            <Template name={'filter'} render={() => this.filterSelectorTemplate()} />
            <Template name={'numberFilter'} render={() => this.numberFilterButtonTemplate()} />
          </DataGrid>
          <NumberFilterModal
            store={equipmentStore}
            primaryKey={'wagonnumber'}
            onChange={(numbers) => {
              filterStore.setNumbers(numbers, this.filterType);
              this.forceUpdate();
            }}
          />
        </AdaptiveCard>
      </>
    );
  }

  onExporting = (e) => {
    const options = { ...equipmentStore.loadOptions, isLoadingAll: true };
    notification.open({
      key: 'progress',
      btn: null,
      message: 'Подготовка файла ...',
      placement: 'bottomRight',
      duration: 0,
    });
    equipmentStoreService
      .equipmentToXlsx(options, (e: ProgressEvent) => {
        if (e.lengthComputable) {
          notification.open({
            key: 'progress',
            btn: null,
            message: 'Загрузка файла ' + Math.round((100 * e.loaded) / e.total) + '%',
            placement: 'bottomRight',
            duration: 0,
          });
        }
      })
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });

        const date = moment().format(GLOBAL_DATE_FORMAT).toString();
        saveAs(blob, date + `-Комплектация.xlsx`);
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        notification.destroy();
      });

    e.cancel = true;
  };

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'numberFilter',
      },
      {
        location: 'after',
        template: 'filter',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.state.dataSource.reload();
          },
        },
      },
    );
  }
}

export default EquipmentPage;
