import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import { RpsSelect } from '@components/fw/RpsSelect';

interface ComponentProps {}

interface ComponentState {}

class ContractDowntimeAppendix extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Form.List name="downtimeDataList">
        {(fields, { add, remove }) => (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item>
                <Button style={{ margin: 5 }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить
                </Button>
              </Form.Item>
            </Col>
            {fields.map((field, index) => (
              <Col xs={24} sm={24} md={24} lg={24} xxl={24} key={index}>
                <AdaptiveCard
                  title={'Параметры простоя'}
                  extra={[
                    <Button key={'delete'} size={'small'} type={'text'} onClick={() => remove(field.name)} icon={<DeleteOutlined />} />,
                  ]}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item rules={[{ required: true }]} name={[field.name, 'rps']}>
                        <RpsSelect />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'loadNormDays']}>
                        <FloatInputNumber max={99999999} min={1} step={1} placeholder={'Норматив простоя под погрузкой, сут.'} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'destNormDays']}>
                        <FloatInputNumber max={99999999} min={1} step={1} placeholder={'Норматив простоя под выгрузкой, сут.'} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'doubleOperationNormDays']}>
                        <FloatInputNumber max={99999999} min={1} step={1} placeholder={'Норматив простоя под сдвоенной операцией, сут.'} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'price']}>
                        <FloatInputNumber
                          max={99999999}
                          min={0}
                          step={0.1}
                          placeholder={'Стоимость сверхнормативного простоя, руб./сут.'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </AdaptiveCard>
              </Col>
            ))}
          </Row>
        )}
      </Form.List>
    );
  }
}

export default ContractDowntimeAppendix;
