import { observer } from 'mobx-react';
import React, { PureComponent } from 'react';

import loadRegistryStore from '@stores/loadRegistryStore/loadRegistryStore';

interface LoadingRegisterSummaryProps {}

class LoadingRegisterSummary extends PureComponent<LoadingRegisterSummaryProps> {
  constructor(props: LoadingRegisterSummaryProps) {
    super(props);
  }

  getInfo = () => {
    let wagons = 0;
    let weight = 0;

    let totalWagons = loadRegistryStore.statistic?.count;
    let totalWeight = loadRegistryStore.statistic?.netto;

    for (let i = 0; i < loadRegistryStore.selectedRowData.length; i++) {
      wagons += 1;
      weight += loadRegistryStore.selectedRowData[i].netto;
    }

    return {
      wagons,
      weight: (weight / 1000).toFixed(3),
      totalWagons,
      totalWeight: (totalWeight / 1000).toFixed(3),
    } as const;
  };

  render() {
    const { wagons, weight, totalWagons, totalWeight } = this.getInfo();

    return (
      <div className={'summary-info'}>
        <div style={{ marginRight: 'auto' }}>
          Выделено: {wagons} ваг.; {weight} т.
        </div>
        <div>
          Всего: {totalWagons} ваг.; {totalWeight} т.
        </div>
      </div>
    );
  }
}
export default observer(LoadingRegisterSummary);
