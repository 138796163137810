import * as Sentry from '@sentry/browser';
import axios from 'axios';

import config from '../config';
import { getAuthority, logout, setAuthority } from './authority';
import { showErrorNotify } from './notify';

const { urlApi } = config;
const HttpClient = axios.create({
  baseURL: urlApi,
});

HttpClient.interceptors.request.use(
  (config) => {
    const token = getAuthority();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token.access_token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

HttpClient.interceptors.response.use(
  (responce) => {
    return responce;
  },
  (error) => {
    Sentry.captureException(error);
    // если сервер недоступен
    if (!error.response) {
      if (error.message) {
        showErrorNotify(error.message, null);
      }
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      return resetTokenAndReattemptRequest(error);
    }

    if (error.response.data && typeof error.response.data === 'string') {
      showErrorNotify(error.response.data, null);
    } else {
      showErrorNotify('Ошибка', error.response.data.Message);
    }

    return Promise.reject(error);
  },
);

let isAlreadyFetchingAccessToken = false;
let subscribers = [];
async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    const tokens = getAuthority(); // Your own mechanism to get the refresh token to refresh the JWT token
    if (!tokens) {
      // We can't refresh, throw the error anyway
      logout();
      return Promise.reject(error);
    }
    /* Proceed to the token refresh procedure
        We create a new Promise that will retry the request,
        clone all the request configuration from the failed
        request in the error object. */
    const retryOriginalRequest = new Promise((resolve) => {
      /* We need to add the request retry to the queue
            since there another request that already attempt to
            refresh the token */
      addSubscriber((access_token) => {
        errorResponse.config.headers.Authorization = 'Bearer ' + access_token.access_token;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = axios({
        method: 'get',
        url: urlApi + '/api/auth/refresh',
        headers: { Authorization: 'Bearer ' + tokens.refresh_token },
      })
        .then((responce) => {
          if (!responce.data) {
            logout();
            return Promise.reject(error);
          }

          const newToken = responce.data;
          newToken.tmpUser = tokens.tmpUser;
          setAuthority(newToken); // save the newly refreshed token for other requests to use
          isAlreadyFetchingAccessToken = false;
          onAccessTokenFetched(newToken);
        })
        .catch((error) => {
          logout();
        });
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

function onAccessTokenFetched(access_token) {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default HttpClient;
