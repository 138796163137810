import HttpClient from '@utils/httpClient';

import { CompanyBankAccountModel, CompanyBankModel } from './BankData';

export class CompanyBankService {
  async getBanks(companyId: string): Promise<CompanyBankModel[]> {
    return (await HttpClient.get(`/api/companyBanks`, { params: { companyId } })).data;
  }

  async getBank(companyBankReferenceId: string): Promise<CompanyBankModel> {
    return (await HttpClient.get(`/api/companyBanks`, { params: { companyBankReferenceId } })).data;
  }

  async addBanks(companyId: string, data: CompanyBankModel[]): Promise<void> {
    return (await HttpClient.post(`/api/companyBanks/heaps`, data, { params: { companyId } })).data;
  }

  async addBank(companyId: string, data: CompanyBankModel): Promise<void> {
    return (await HttpClient.post(`/api/companyBanks`, data, { params: { companyId } })).data;
  }

  async setMainBank(companyId: string, bank: CompanyBankModel): Promise<void> {
    return (await HttpClient.post(`/api/companyBanks/${bank.companyBankReferenceId}/setmain`, null, { params: { companyId } })).data;
  }

  async updateBank(data: CompanyBankModel): Promise<void> {
    return (await HttpClient.put(`/api/companyBanks`, data)).data;
  }

  async deleteBank(companyBankRefId: string): Promise<void> {
    return (await HttpClient.delete(`/api/companyBanks/${companyBankRefId}`)).data;
  }

  async getBankAccount(accountId: string): Promise<CompanyBankAccountModel> {
    return (await HttpClient.get(`/api/companyBanks/accounts/${accountId}`)).data;
  }

  async addBankAccount(companyBankReferenceId: string, data: CompanyBankAccountModel): Promise<void> {
    return (await HttpClient.post(`/api/companyBanks/${companyBankReferenceId}/accounts_ext`, data)).data;
  }

  async updateBankAccount(data: CompanyBankAccountModel): Promise<void> {
    return (await HttpClient.put(`/api/companyBanks/accounts_ext`, data)).data;
  }

  async deleteBankAccount(bankAccountId: string): Promise<void> {
    return (await HttpClient.delete(`/api/companyBanks/accounts/${bankAccountId}`)).data;
  }

  async setMainAccount(accountId: string): Promise<void> {
    return (await HttpClient.post(`/api/companyBanks/accounts/${accountId}/setmain`, null)).data;
  }

  async getBankOperations(data: any): Promise<any> {
    return (await HttpClient.post(`/api/companyBanks/operations`, data)).data;
  }
}

export default new CompanyBankService();
