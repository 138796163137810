import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { showErrorNotify, showSuccessNotify } from '@utils/notify';

import userStore from '../userStore/userStore';
import { StatisticCompanyTrackingInfo, StatisticDocInfo, StatisticExceptionInfo } from './statisticData';
import service from './statisticStore.service';

class StatisticStore {
  @observable data: StatisticCompanyTrackingInfo[] = [];

  @observable isBusy: boolean = false;
  @observable type: 0 | 1 | 2 | 3 = null;
  @observable companyId: string = userStore.userData?.companyId;
  @observable date: any = moment();
  @observable begin: any = moment();
  @observable end: any = moment();
  @observable periodType: 'month' | 'date' = 'month';
  @observable isBusyDocs: boolean = false;
  @observable isBusyGetDocs: boolean = false;
  @observable docsData: StatisticDocInfo[] = [];

  constructor() {
    makeObservable(this);
  }

  @action initData() {
    this.type = null;
    this.companyId = userStore.userData.companyId;
    this.date = moment();
  }

  private aggregateWagons(data: StatisticCompanyTrackingInfo[]) {
    const result: { [key: string]: StatisticCompanyTrackingInfo } = {};
    data.forEach((d) => {
      let bywagon = result[d.number];
      if (!bywagon) {
        bywagon = d;
        result[d.number] = bywagon;
      } else {
        bywagon.totalTechRate += d.totalTechRate;
        bywagon.totalWagonRate += d.totalWagonRate;
        bywagon.dayCount += d.dayCount;
      }
    });
    this.data = Object.values(result);
  }

  @action custom(type: 0 | 1 | 2 | 3) {
    if (!this.companyId) return;
    this.isBusy = true;
    service
      .getCustomData(moment(this.begin).toISOString(true), moment(this.end).toISOString(true), type == 3 ? 0 : type, this.companyId)
      .then((ans) => {
        if (type == 3) {
          this.aggregateWagons(ans);
        } else {
          this.data = ans;
        }
        this.type = type;
      })
      .finally(() => {
        this.isBusy = false;
      });
  }

  @action company(type: 0 | 1 | 2 | 3) {
    if (!this.companyId) return;
    this.isBusy = true;
    service
      .getCompanyData(moment(this.date).get('month') + 1, moment(this.date).get('year'), type == 3 ? 0 : type)
      .then((ans) => {
        if (type == 3) {
          this.aggregateWagons(ans);
        } else {
          this.data = ans;
        }
        this.type = type;
      })
      .finally(() => {
        this.isBusy = false;
      });
  }

  @action admin(type: 0 | 1 | 2 | 3) {
    if (!this.companyId) return;
    this.isBusy = true;
    service
      .getAdminData(moment(this.date).get('month') + 1, moment(this.date).get('year'), type == 3 ? 0 : type, this.companyId)
      .then((ans) => {
        if (type == 3) {
          this.aggregateWagons(ans);
        } else {
          this.data = ans;
        }

        this.type = type;
      })
      .finally(() => {
        this.isBusy = false;
      });
  }

  @action async setExcept(number: any, set: boolean) {
    let find = this.data.find((d) => d.number == number);
    if (find) {
      find.isExcept = set;
      let data: StatisticExceptionInfo = {
        containerNumber: this.type == 1 ? number : null,
        wagonNumber: this.type == 0 ? number : null,
        companyId: this.companyId,
        date: find.date,
        startInterval: find.beginInterval,
        set: set,
      };
      await service.setException(this.type, data);
    } else showErrorNotify('Ошибка', 'Не найден вагон или контейнер');
  }
  @action createDocs() {
    if (!this.companyId) return;
    this.isBusyDocs = true;
    let promise =
      this.periodType == 'date'
        ? service.createDocs({
            companyId: this.companyId,
            date: null,
            end: this.end.toISOString(true),
            begin: this.begin.toISOString(true),
          })
        : service.createDocs({
            companyId: this.companyId,
            date: moment(this.date).startOf('month').add(12, 'hour').toISOString(true),
            begin: null,
            end: null,
          });

    promise
      .then(() => {
        showSuccessNotify('Документы сформированы');
      })
      .finally(() => (this.isBusyDocs = false));
  }

  @action getDocsData(types: string[]) {
    this.isBusyGetDocs = true;
    service
      .getDocsData(types)
      .then((ans) => {
        this.docsData = ans;
      })
      .finally(() => (this.isBusyGetDocs = false));
  }
}

export default new StatisticStore();
