import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select } from 'antd';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import dictStore from '@stores/dictStore/dictStore';
import optionsStore from '@stores/optionsStore';

import { filterOptionFn } from '@utils/sort';

interface ComponentProps {}

interface ComponentState {}

class ContractExtExtraServices extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  render() {
    const dict = dictStore.extraServicesDictionary;
    const wagonTypesDictionary = optionsStore.wagonTypesDictionary;

    return (
      <Form.List name="extraServicesParams">
        {(fields, { add, remove }) => (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item>
                <Button style={{ margin: 5 }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить
                </Button>
              </Form.Item>
            </Col>
            {fields.map((field, index) => (
              <Col xs={24} sm={24} md={24} lg={12} xxl={8} key={index}>
                <AdaptiveCard
                  title={'Дополнительная услуга'}
                  extra={[
                    <Button key={'delete'} size={'small'} type={'text'} onClick={() => remove(field.name)} icon={<DeleteOutlined />} />,
                  ]}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24}>
                      <Form.Item name={[field.name, 'serviceId']} fieldKey={[field.key, 'serviceId']} rules={[{ required: true }]}>
                        <FloatSelect showSearch filterOption={filterOptionFn} style={{ width: '100%' }} placeholder={'Тип услуги'}>
                          {dict.map((s) => (
                            <Select.Option value={s.serviceId} key={s.serviceId}>
                              {s.name}
                            </Select.Option>
                          ))}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name={[field.name, 'wagonTypeCode']} rules={[{ required: true }]}>
                        <FloatSelect
                          placeholder="Подвижной состав"
                          dropdownMatchSelectWidth={false}
                          showSearch
                          filterOption={filterOptionFn}>
                          {wagonTypesDictionary.map((item, index) => {
                            return (
                              <Select.Option value={item.wagonTypeCode} key={`speedCategory_${index}`}>
                                {item.wagonTypeName}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name={[field.name, 'price']} fieldKey={[field.key, 'price']} rules={[{ required: true }]}>
                        <FloatInputNumber placeholder={'Стоимость c НДС'} max={99999999} min={0} step={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                </AdaptiveCard>
              </Col>
            ))}
          </Row>
        )}
      </Form.List>
    );
  }
}

export default ContractExtExtraServices;
