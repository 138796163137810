import { action, makeObservable, observable } from 'mobx';

import dispatcherFilterService from './dispatcherFilterStore.service';
import userReportsService from './userReportsfilterStore.service';

export interface FilterContentModel {
  filterId: number;
  name: string;
  content?: string;
  reportId?: string;
  reportType?: ReportFilterType;
  createDate?: string;
  numbers?: string;
}

export type ReportFilterType = 'load_plan' | 'itinerary_wagons' | 'itinerary_containers' | 'dispatcher' | 'equipment' | 'loadRegistry';

class FilterStore {
  @observable isBusy: boolean = false;
  @observable filters: FilterContentModel[] = [];
  @observable selected: FilterContentModel | null = null;
  private currentContent: { [key: string]: any } = {};
  private numbers: { [key: string]: any[] } = {};

  constructor() {
    makeObservable(this);
  }

  service(reportIdOrType: string) {
    if (['load_plan', 'itinerary_wagons', 'itinerary_containers', 'equipment', 'loadRegistry'].includes(reportIdOrType)) {
      return userReportsService;
    } else {
      return dispatcherFilterService;
    }
  }

  @action load(reportIdOrType: string) {
    this.isBusy = true;
    this.service(reportIdOrType)
      .getList(reportIdOrType)
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.filters = result;
      });
  }

  @action async create(reportIdOrType: string, data: FilterContentModel) {
    this.isBusy = true;
    data.content = JSON.stringify(this.currentContent[reportIdOrType]);
    if (this.numbers[reportIdOrType]) {
      data.numbers = JSON.stringify(this.numbers[reportIdOrType]);
    }
    await this.service(reportIdOrType)
      .create(reportIdOrType, data)
      .then(() => {
        this.load(reportIdOrType);
      });
  }

  @action async update(reportIdOrType: string, data: FilterContentModel) {
    this.isBusy = true;
    data.content = JSON.stringify(this.currentContent[reportIdOrType]);
    if (this.numbers[reportIdOrType]) {
      data.numbers = JSON.stringify(this.numbers[reportIdOrType]);
    }
    await this.service(reportIdOrType)
      .update(data)
      .then(() => {
        this.load(reportIdOrType);
      });
  }

  @action remove(reportIdOrType: string, id: number) {
    this.isBusy = true;
    this.service(reportIdOrType)
      .remove(id)
      .then(() => {
        this.load(reportIdOrType);
      });
  }

  @action async setSelected(reportIdOrType: string, item: FilterContentModel | null): Promise<FilterContentModel | null> {
    if (!item) {
      this.setContent(null, reportIdOrType);
      this.selected = null;
      return null;
    }
    this.isBusy = true;
    return await this.service(reportIdOrType)
      .getById(item.filterId)
      .finally(() => (this.isBusy = false))
      .then((ans) => {
        this.selected = ans;
        this.currentContent[reportIdOrType] = JSON.parse(ans.content);
        if (ans.numbers) {
          this.numbers[reportIdOrType] = JSON.parse(ans.numbers);
        }
        return ans;
      });
  }

  @action saveSelected(reportIdOrType: string) {
    this.update(reportIdOrType, this.selected);
  }

  @action setContent(data: any, reportIdOrType: string) {
    this.currentContent[reportIdOrType] = data;
  }

  @action setNumbers(numbers: any[], reportIdOrType: string) {
    this.numbers[reportIdOrType] = numbers;
  }

  @action getContent(reportIdOrType: string) {
    return this.currentContent[reportIdOrType];
  }

  @action getNumbers(reportIdOrType: string) {
    return this.numbers[reportIdOrType];
  }

  @action clearContent() {
    this.currentContent = {};
    this.numbers = {};
  }
}

export default new FilterStore();
