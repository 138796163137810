const sortArrayByStringProp = (a: any, b: any, propName: string) => {
  if (a[propName] < b[propName]) {
    return -1;
  }
  if (a[propName] > b[propName]) {
    return 1;
  }
  return 0;
};

export { sortArrayByStringProp };

export const filterOptionFn = (input, option) => {
  if (!option) return false;
  if (typeof option == 'string' && option.toLowerCase().includes(input.toLowerCase())) {
    return true;
  }
  if (option.key && option.key.toString().toLowerCase().includes(input.toLowerCase())) return true;
  if (option.label && option.label.toString().toLowerCase().includes(input.toLowerCase())) return true;

  const children = option.children;
  if (!children) return false;
  if (typeof children == 'string') {
    return children.toLowerCase().includes(input.toLowerCase());
  }
  let result = false;
  if (Array.isArray(children)) {
    children.forEach((o) => {
      result = result || filterOptionFn(input, o);
    });
  }
  return result;
};

export const headerFilterSort = (a: { value: string | number; text: string }, b: { value: string | number; text: string }) => {
  if (a.text < b.text) return -1;
  if (a.text > b.text) return 1;
  return 0;
};
