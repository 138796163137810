import HttpClient from '@utils/httpClient';

import { NotificationEntry, NotificationModel, NotificationTemplateModel } from './notificationStore';

const service = {
  // Получить шаблоны уведомлений
  async getNotificationTemplates(): Promise<NotificationTemplateModel[]> {
    return await HttpClient.get(`/api/notification/templates/`).then((resp) => {
      return resp.data;
    });
  },

  // Получить уведомления на компанию
  async getNotificationByCompanyId(companyId: string): Promise<NotificationModel[]> {
    return await HttpClient.get(`/api/notification/${companyId}`).then((resp) => {
      return resp.data;
    });
  },

  // Добавить/обновить уведомление на компанию
  async addNotificationByCompanyId(companyId: string, data: NotificationModel): Promise<NotificationModel> {
    return (await HttpClient.put(`/api/notification/${companyId}`, data)).data;
  },

  // Удалить уведомление компании
  async deleteNotification(companyId: string, templateCode: string): Promise<any> {
    return await HttpClient.delete(`/api/notification/${companyId}/${templateCode}`).then((resp) => {
      return resp.data;
    });
  },

  /// Получить описание уведомления по вагону
  async getWagonNotifyDescription(companyId: string, templateCode: string, number: string): Promise<NotificationEntry> {
    return await HttpClient.get(`/api/notification/description/wagon/${companyId}/${templateCode}/${number}`).then((resp) => {
      return resp.data;
    });
  },

  /// Получить описание уведомления по контейнеру
  async getContainerNotifyDescription(companyId: string, templateCode: string, number: string): Promise<NotificationEntry> {
    return await HttpClient.get(`/api/notification/description/container/${companyId}/${templateCode}/${number}`).then((resp) => {
      return resp.data;
    });
  },

  /// Получить описание уведомления по заявке
  async getClaimNotifyDescription(templateCode: string, number: string): Promise<NotificationEntry> {
    return await HttpClient.get(`/api/notification/description/claim/${number}/${templateCode}`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
