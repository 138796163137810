import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

interface CollapsedButtonProps {
  content: any[];
  hideName?: boolean;
}

class CollapsedToolbar extends Component<CollapsedButtonProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const content = this.props.content;
    if (!designStore.isMobile) return <Space>{content.map((c, index) => c)}</Space>;
    else
      return (
        <Dropdown
          key="more"
          trigger={['hover', 'click']}
          overlay={
            <Menu>
              {content.map((c, index) => (
                <Menu.Item key={index}>{c}</Menu.Item>
              ))}
            </Menu>
          }>
          <Button size={'large'} type={'text'} icon={<MoreOutlined />} />
        </Dropdown>
      );
  }
}

export default observer(CollapsedToolbar);
