import { Button, Col, Form, FormInstance, Row, Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import PdfViewModal from '@components/fw/DocumentViewer/PdfViewModal';
import { getBase64 } from '@components/fw/Drawers/CompanyEditor/SinglePictureUpload';
import ContractDowntimeAppendix from '@components/fw/Drawers/OrganizationEditor/ContractList/Appendixies/ContractDowntimeAppendix';
import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import claimsDocumentsStoreService from '@stores/claimsStore/claimsDocumentsStore.service';
import { DocTypeModel } from '@stores/contractTemplateStore/ContractTemplatesData';
import dictStore from '@stores/dictStore/dictStore';
import { CURRENCY_LIST } from '@stores/expeditionsStore/expeditionsData';
import optionsStore from '@stores/optionsStore/optionsStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import organizationsStoreService from '@stores/organizationsStore/organizationsStore.service';
import {
  TemplateOrgContractAppendixType,
  TemplateOrgContractModel,
  TemplateOrgExtContractModelForm,
  defaultTemplateOrgExtContractModelForm,
  templateOrgFormDataToModel,
  templateOrgModelToFormData,
} from '@stores/organizationsStore/organizationsStoreData';

import { showErrorNotify } from '@utils/notify';

import ContractExtExpedition from './ExtContractParts/ContractExtExpedition';
import ContractExtExtraServices from './ExtContractParts/ContractExtExtraServices';
import ContractExtRent from './ExtContractParts/ContractExtRent';
import ContractExtRentBack from './ExtContractParts/ContractExtRentBack';
import ContractExtWagons from './ExtContractParts/ContractExtWagons';

interface ComponentProps {
  id: string;
  organizationId: string;
  contract?: TemplateOrgContractModel;
  onFinish(contractId: string): void;
  onClose(): void;
  templateList: DocTypeModel[];
  type: string;
  claimId?: string;
}

interface ComponentState {
  data: TemplateOrgExtContractModelForm;
  loading: boolean;
  loadingDoc: boolean;
  visible: boolean;
  doc: string;
  blob: Blob;
  extTypeCode: string;
  hasDowntimeAppendix: boolean;
}

const conditionDowntimeOptions = [
  { label: 'Штраф за сверхнормативный простой ПС (не облагается НДС)', value: 'fine' },
  { label: 'Услуга за сверхнормативное использование ПС (облагается НДС)', value: 'service' },
];

const appendixOptions: { label: string; value: TemplateOrgContractAppendixType }[] = [
  { label: 'Простои', value: 'downtime' },
  { label: 'Протокол согласования договорной цены (расширенный)', value: 'priceAgreeing' },
  { label: 'Протокол согласования договорной цены (сокращенный)', value: 'priceAgreeingShort' },
  { label: 'Форма заявки', value: 'claimForm' },
];

const downtimeCalculationOptions = [
  {
    label: 'С момента прибытия с округлением до целых суток в большую сторону',
    value: 'strategy1',
  },
  {
    label: 'С 0:00 суток, следующих за сутками прибытиями, с округлением до целых суток в большую сторону',
    value: 'strategy2',
  },
  { label: 'С момента прибытия без округления', value: 'strategy3' },
  {
    label: 'С 0:00 суток, следующих за сутками прибытиями, без округления',
    value: 'strategy4',
  },
];

@observer
class ContractEditor extends Component<ComponentProps, ComponentState> {
  submitForView: boolean = false;
  form: FormInstance;
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      data: defaultTemplateOrgExtContractModelForm,
      loading: false,
      visible: false,
      loadingDoc: false,
      doc: null,
      blob: null,
      extTypeCode: null,
      hasDowntimeAppendix: false,
    };
  }

  componentDidMount() {
    this.setState({ visible: true });
    //Загрузка справочника типов ПС
    optionsStore.loadTariffDictionary();
    dictStore.countryDict(null);
    if (this.props.id || this.props.claimId) {
      this.setState({ loading: true });
      let promise;
      if (this.props.id) {
        promise = organizationsStoreService.getOneContract(this.props.id, this.props.type);
      } else {
        promise = claimsDocumentsStoreService.createDs(this.props.claimId);
      }
      promise
        .then((ans) => {
          this.setState(
            {
              data: templateOrgModelToFormData(ans),
              //@ts-ignore
              extTypeCode: ans.extTypeCode,
              hasDowntimeAppendix: ans.appendixes?.find((ap) => ap.type === 'downtime'),
            },
            () => this.form.resetFields(),
          );
        })
        .finally(() => this.setState({ loading: false }));
    }
  }

  onClose = (e) => {
    e.stopPropagation();
    if (this.form) this.form.resetFields();
    this.setState({ visible: false });
    this.props.onClose();
  };

  onSubmit = (e: TemplateOrgExtContractModelForm) => {
    if (e.extTypeCode == 'expedition') {
      if (!e.expeditionParams.length) {
        showErrorNotify('Укажите хотябы одно направление', '');
        return;
      }
      const crossDate = e.expeditionParams.find((p1) => {
        return e.expeditionParams.find((p2) => {
          return (
            p2 != p1 &&
            p1.countryCode == p2.countryCode &&
            (moment(p2.periodFrom).isBetween(p1.periodFrom, p1.periodTo) ||
              moment(p2.periodTo).isBetween(p1.periodFrom, p1.periodTo) ||
              moment(p1.periodFrom).isBetween(p2.periodFrom, p2.periodTo) ||
              moment(p1.periodTo).isBetween(p2.periodFrom, p2.periodTo))
          );
        });
      });
      if (crossDate) {
        const country = dictStore.countriesDictionary.find((c) => c.iso3166 === crossDate.countryCode);
        showErrorNotify('Найдены пересечения по времени действия', 'Страна ' + country.fullName);
        return;
      }
      e.expeditionParams.forEach((p) => {
        const country = dictStore.countriesDictionary.find((c) => c.iso3166 === p.countryCode);
        if (country) {
          p.countryName = country.fullName;
        }
        const currency = CURRENCY_LIST.find((c) => c.code === p.currencyCode);
        if (currency) {
          p.currencyName = currency.name;
        }
        p.organizationId = this.props.organizationId;
      });
    }
    if (e.extTypeCode == 'wagons') {
      if (!e.wagonParams.length) {
        showErrorNotify('Укажите хотябы одно направление', '');
        return;
      }
    }
    if (this.submitForView) {
      this.getView(e);
    } else {
      this.save(e);
    }
  };

  save = (e: TemplateOrgExtContractModelForm) => {
    const { contract, organizationId, type } = this.props;
    const { data } = this.state;
    e.organizationId = organizationId;
    if (contract) {
      e.contractId = contract.templateMetadataId;
    }
    e.commercialOfferId = this.state.data.commercialOfferId;
    if (!e.statusId) e.statusId = 'onagr';
    if (data) e.templateMetadataId = data.templateMetadataId;
    this.setState({ loading: true });
    organizationsStoreService
      .saveContract(templateOrgFormDataToModel(e), type)
      .then((ans) => {
        this.setState({ visible: false });
        if (this.form) this.form.resetFields();
        this.props.onFinish(ans);
      })
      .finally(() => this.setState({ loading: false }));
  };

  getView = (e: TemplateOrgExtContractModelForm) => {
    const { contract, organizationId, type } = this.props;
    this.setState({ loadingDoc: true });
    this.submitForView = false;
    e.organizationId = organizationId;
    if (contract) {
      e.contractId = contract.templateMetadataId;
    }
    if (!e.statusId) e.statusId = 'onagr';
    organizationsStoreService
      .contractGetDemoFile(templateOrgFormDataToModel(e), type)
      .then((doc) => {
        if (doc && doc.size) {
          getBase64(doc, (docUrl) => this.setState({ doc: docUrl, blob: doc }));
        }
      })
      .finally(() => this.setState({ loadingDoc: false }));
  };

  onSaveAndClose = (e) => {
    e.stopPropagation();
    if (this.form) {
      this.form.submit();
    }
  };

  onViewClick = (e) => {
    e.stopPropagation();
    this.submitForView = true;
    if (this.form) this.form.submit();
  };

  render() {
    const { contract, type, templateList } = this.props;
    const createDate = !!contract ? contract.createDate : null;
    const endDate = !!contract ? contract.endDate : null;
    const prolongationIsAuto = !!contract ? contract.prolongationIsAuto : false;
    const { data, extTypeCode, loadingDoc, visible, loading, blob, doc } = this.state;
    const isExt = type == 'ext';
    const isExtWagons = isExt && extTypeCode == 'wagons';
    const isExtExpedition = isExt && extTypeCode == 'expedition';
    const isExtRent = isExt && extTypeCode == 'rent';
    const isExtRentBack = isExt && extTypeCode == 'rentBack';
    const hasDowntime = (!isExt && this.state.hasDowntimeAppendix) || (isExt && extTypeCode == 'downtime');
    return (
      <>
        <AdaptiveDrawer
          width={'90%'}
          title={isExt && contract ? `дополнительное соглашение к договору ${contract.name ? contract.name : ''}` : 'договор'}
          visible={visible}
          onClose={this.onClose}
          footer={[
            <Button loading={loadingDoc} onClick={this.onViewClick} key={'saveAndView'}>
              посмотреть
            </Button>,
            <Button onClick={this.onSaveAndClose} key={'saveAndClose'} type={'primary'}>
              Сохранить и закрыть
            </Button>,
          ]}>
          <Spin spinning={loading}>
            <Form
              onFinish={(e) => this.onSubmit(e)}
              size={'large'}
              ref={(e) => (this.form = e)}
              initialValues={{ ...data }}
              layout={'vertical'}
              onClick={(e) => e.stopPropagation()}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24} lg={12} xxl={isExt ? 12 : 8}>
                  <Form.Item name={'docTypeId'} rules={[{ required: true }]}>
                    <FloatSelect placeholder={'Шаблон'}>
                      {templateList.map((t) => (
                        <Select.Option key={t.docTypeId} value={t.docTypeId}>
                          {t.name}
                        </Select.Option>
                      ))}
                    </FloatSelect>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xxl={isExt ? 12 : 9}>
                  <Form.Item name={'name'} rules={[{ required: true }]}>
                    <FloatInput placeholder={isExt ? 'Наименование соглашения' : 'Наименование договора'} />
                  </Form.Item>
                </Col>
                {isExt && (
                  <Col xs={24} sm={24} md={24} lg={12} xxl={8}>
                    <Form.Item name={'extTypeCode'} rules={[{ required: true }]}>
                      <FloatSelect
                        placeholder={'Тип соглашения'}
                        onChange={(e) => {
                          if (e == 'rent') {
                            data.rentParams = {
                              catalogId: null,
                              templateMetadataId: null,
                              type: 'wagon',
                              price: null,
                              withExclusion: false,
                              changeStationCode: null,
                            };
                            this.setState({ extTypeCode: 'rent', data: data }, () => this.form.resetFields(['rentParams']));
                          } else {
                            this.setState({ extTypeCode: e ? e.toString() : null });
                          }
                        }}>
                        <Select.Option value={'expedition'}>Экспедирование</Select.Option>
                        <Select.Option value={'wagons'}>Предоставление вагонов</Select.Option>
                        <Select.Option value={'rent'}>Аренда</Select.Option>
                        <Select.Option value={'downtime'}>Простои</Select.Option>
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} sm={24} md={24} lg={12} xxl={7}>
                  <Form.Item name={isExt ? 'extContractNumber' : 'contractNumber'} rules={[{ required: true }]}>
                    <FloatInput placeholder={'Номер'} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xxl={8}>
                  <Form.Item name={'address'} rules={[{ required: true }]}>
                    <FloatInput placeholder={'Город'} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={isExt ? 12 : 10} xxl={isExt ? 4 : 3}>
                  <Form.Item
                    name={'beginDate'}
                    rules={[
                      { required: true, message: '' },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!isExt) return Promise.resolve();
                          if (!value) {
                            return Promise.reject(`Не указана дата`);
                          } else if (
                            !!getFieldValue('endDate') &&
                            moment(value, GLOBAL_DATE_FORMAT).isAfter(moment(getFieldValue('endDate'), GLOBAL_DATE_FORMAT), 'day')
                          ) {
                            return Promise.reject(
                              `Дата не может быть позднее ${moment(getFieldValue('endDate')).format(GLOBAL_DATE_FORMAT)}`,
                            );
                          } else if (!prolongationIsAuto && moment(value, GLOBAL_DATE_FORMAT).isAfter(moment(endDate), 'day')) {
                            return Promise.reject(`Дата не может быть позднее ${moment(endDate).format(GLOBAL_DATE_FORMAT)}`);
                          } else if (moment(value, GLOBAL_DATE_FORMAT).isBefore(moment(createDate), 'day')) {
                            return Promise.reject(`Дата не может быть ранее ${moment(createDate).format(GLOBAL_DATE_FORMAT)}`);
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}>
                    <FloatDatepicker placeholder={'Дата'} format={GLOBAL_DATE_FORMAT} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={isExt ? 12 : 10} xxl={isExt ? 4 : 3}>
                  <Form.Item
                    name={'endDate'}
                    rules={[
                      { required: true, message: '' },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!isExt) return Promise.resolve();
                          if (!value) {
                            return Promise.reject(`Не указана дата`);
                          } else if (
                            !!getFieldValue('beginDate') &&
                            moment(value, GLOBAL_DATE_FORMAT).isBefore(moment(getFieldValue('beginDate'), GLOBAL_DATE_FORMAT), 'day')
                          ) {
                            return Promise.reject(
                              `Дата не может быть ранее ${moment(getFieldValue('beginDate')).format(GLOBAL_DATE_FORMAT)}`,
                            );
                          } else if (moment(value, GLOBAL_DATE_FORMAT).isAfter(moment(endDate), 'day') && !prolongationIsAuto) {
                            return Promise.reject(`Дата не может быть позднее ${moment(endDate).format(GLOBAL_DATE_FORMAT)}`);
                          } else if (moment(value, GLOBAL_DATE_FORMAT).isBefore(moment(createDate), 'day')) {
                            return Promise.reject(`Дата не может быть ранее ${moment(createDate).format(GLOBAL_DATE_FORMAT)}`);
                          } else if (moment(value, GLOBAL_DATE_FORMAT).isAfter(moment(endDate), 'day') && prolongationIsAuto) {
                            return Promise.resolve();
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}>
                    <FloatDatepicker format={GLOBAL_DATE_FORMAT} placeholder={'Действует до'} />
                  </Form.Item>
                </Col>
                {!isExt && (
                  <Col xs={24} sm={24} md={24} lg={4} xxl={3}>
                    <Form.Item name={'prolongationIsAuto'} valuePropName={'checked'}>
                      <FloatCheckbox>Автопролонгация</FloatCheckbox>
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} sm={24} md={24} lg={12} xxl={7}>
                  <Form.Item name={'statusId'} rules={[{ required: true }]}>
                    <FloatSelect placeholder={'Статус'}>
                      <Select.Option value={'onagr'}>На согласовании</Select.Option>
                      <Select.Option value={'agr'}>Согласовано</Select.Option>
                    </FloatSelect>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xxl={8}>
                  <Form.Item name={'foreignId'}>
                    <FloatInput placeholder={'Идентификатор из другой программы'} />
                  </Form.Item>
                </Col>
                {!isExt && (
                  <Col xs={24} sm={24} md={24} lg={12} xxl={8}>
                    <Form.Item name={'appendixTypes'}>
                      <FloatSelect
                        mode={'multiple'}
                        placeholder={'Включить приложения'}
                        onChange={(e: TemplateOrgContractAppendixType[]) => {
                          this.setState({ hasDowntimeAppendix: e.includes('downtime') });
                        }}>
                        {appendixOptions.map((option) => (
                          <Select.Option value={option.value}>{option.label}</Select.Option>
                        ))}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {hasDowntime && (
                <div>
                  <h4>Простои</h4>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={12} xxl={12}>
                      <Form.Item name={'conditionDowntime'} rules={[{ required: true }]}>
                        <FloatSelect placeholder={'Условия выставления простоев'}>
                          {conditionDowntimeOptions.map((option) => (
                            <Select.Option value={option.value}>{option.label}</Select.Option>
                          ))}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xxl={12}>
                      <Form.Item name={'downtimeCalculation'} rules={[{ required: true }]}>
                        <FloatSelect placeholder={'Принцип расчета простоя'}>
                          {downtimeCalculationOptions.map((option) => (
                            <Select.Option value={option.value}>{option.label}</Select.Option>
                          ))}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                  </Row>
                  <ContractDowntimeAppendix />
                </div>
              )}
              {isExtWagons && (
                <div>
                  <h4>Подвижной состав</h4>
                  <ContractExtWagons />
                  <h4>Дополнительные услуги</h4>
                  <ContractExtExtraServices />
                </div>
              )}
              {isExtExpedition && <ContractExtExpedition />}
              {isExtRent && (
                <ContractExtRent initStationCode={data.rentParams ? data.rentParams.changeStationCode : null} form={this.form} />
              )}
              {isExtRentBack && <ContractExtRentBack />}
            </Form>
          </Spin>
        </AdaptiveDrawer>
        <PdfViewModal data={blob} visible={!!doc} onClose={() => this.setState({ doc: null })} />
      </>
    );
  }
}

export default ContractEditor;
