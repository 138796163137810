import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Empty, Input, InputNumber, Popconfirm, Row } from 'antd';
import React, { Component } from 'react';

import optionsStore from '@stores/optionsStore/optionsStore';
import { OversizePoints } from '@stores/optionsStore/optionsStoreData';

import { oversizeStr } from './OptionsSizeOverage';

const classifyPoint = require('robust-point-in-polygon');

interface Props {
  onChange?(v: string): void;
}

interface State {
  point: number[][];
  hPoint: number;
  wPoint: number;
  floor: boolean;
  floorPoint: number;
}

const zeroY = 5700;
const zeroX = 2600;
const scale = 15;
const gabarit = [
  [0, 5300],
  [620, 5300],
  [1625, 4000],
  [1625, 340],
  [1420, 340],
  [1420, 150],
  [0, 150],
];
const side1 = [
  [1625, 4000],
  [1625, 1400],
  [1700, 1400],
  [1700, 4000],
];
const bottom1 = [
  [1625, 1400],
  [1625, 410],
  [1700, 410],
  [1700, 1400],
];
const top1 = [
  [620, 5300],
  [1625, 4000],
  [1700, 4000],
  [880, 5300],
];
const top1side1 = [
  [1625, 4150],
  [1625, 4000],
  [1700, 4000],
];

const side2 = [
  [1700, 1400],
  [1700, 4000],
  [1800, 4000],
  [1800, 1400],
];
const bottom2 = [
  [1700, 410],
  [1700, 1400],
  [1760, 1400],
  [1760, 410],
];
const top2 = [
  [880, 5300],
  [1020, 5300],
  [1700, 4350],
  [1800, 4000],
  [1700, 4000],
];
const top2side2 = [
  [1625, 4150],
  [1700, 4000],
  [1800, 4000],
  [1700, 4350],
  [1625, 4480],
];

const top3 = [
  [1020, 5300],
  [1700, 4350],
  [1800, 4000],
  [1850, 4000],
  [1700, 4500],
  [1120, 5300],
];

const top3side3 = [
  [1625, 4620],
  [1700, 4500],
  [1850, 4000],
  [1800, 4000],
  [1700, 4350],
  [1625, 4150],
];

const top8side8 = [
  [1625, 4620],
  [1700, 4500],
  [1850, 4000],
  [4000, 4000],
  [4000, 4620],
];

const side3 = [
  [1850, 1400],
  [1850, 4000],
  [1800, 4000],
  [1800, 1400],
];
const bottom3 = [
  [1760, 1400],
  [1760, 1230],
  [1850, 1230],
  [1850, 1400],
];
const side4 = [
  [1850, 4000],
  [1850, 1400],
  [2000, 1400],
  [2000, 3700],
];
const bottom4 = [
  [1850, 1400],
  [2000, 1400],
  [2000, 1230],
  [1850, 1230],
];
const side5 = [
  [2000, 1400],
  [2080, 1400],
  [2080, 3400],
  [2000, 3700],
];
const bottom5 = [
  [2080, 1400],
  [2000, 1400],
  [2000, 1230],
  [2080, 1230],
];
const side6 = [
  [2240, 1400],
  [2080, 1400],
  [2080, 3400],
  [2240, 2800],
];
const bottom6 = [
  [2240, 1400],
  [2080, 1400],
  [2080, 1230],
  [2240, 1230],
];

const bottom8 = [
  [1745, 0],
  [1745, 200],
  [1920, 200],
  [1920, 1100],
  [2450, 1100],
  [2450, 1400],
  [2240, 1400],
  [2240, 1230],
  [1800, 1230],
  [1800, 410],
  [1625, 410],
  [1625, 340],
  [1420, 340],
  [1420, 150],
  [0, 150],
  [0, 0],
];

const side8 = [
  [2450, 1400],
  [2240, 1400],
  [2240, 2800],
  [2080, 3400],
  [2000, 3700],
  [1850, 4000],
  [2450, 4000],
];

const top8 = [
  [2450, 4300],
  [1600, 5550],
  [0, 5550],
  [0, 5300],
  [1020, 5300],
  [1625, 4620],
  [1850, 4000],
  [2450, 4000],
];

const gabaritPsRight = [
  [0, 5550],
  [1600, 5550],
  [2450, 4300],
  [2450, 1070],
  [1745, 0],
  [1, 0],
];
const gabaritPsLeft = [
  [0, 5550],
  [1600, 5550],
  [2450, 4300],
  [2450, 1100],
  [1920, 1100],
  [1920, 200],
  [1745, 200],
  [1745, 0],
  [1, 0],
];

const arrowRight = [
  [-150, 0],
  [-150, 70],
  [0, 35],
];

const arrowLeft = [
  [150, 0],
  [150, 70],
  [0, 35],
];

const arrowTop = [
  [0, -1],
  [-35, -50],
  [35, -50],
];

const arrowBottom = [
  [0, 1],
  [-35, 50],
  [35, 50],
];

const arrowVerticalLines = [
  //высота, верхняя точка, длина,
  [340, 1480, 340],
  [150, 100, 150],
  [410, 1760, 410],
  [1230, 400, 1230],
  [1400, 2080, 1400],
  [5300, 2450, 5300],
];

const arrowHorizontalLines = [
  //высота, длина, отчетная точка,
  [1195, 1760, 0],
  [2300, 2240, 0],
  [2600, 2080, 0],
  [2900, 2000, 0],
  [3200, 1850, 0],
  [3500, 1800, 0],
  [3700, 1700, 0],
  [4000, 1625, 0],
  [4500, 1700, 0],
  [4300, 1480, 0],
  [5270, 620, 0],
  [700, 1920, 1920],
  [2000, 2450, 0],
];

class OptionsOversizeImg extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      point: [],
      hPoint: 0,
      wPoint: 0,
      floor: true,
      floorPoint: 1400,
    };
  }

  componentDidMount() {
    if (optionsStore.request.oversizePoints) {
      const data: OversizePoints = JSON.parse(optionsStore.request.oversizePoints);
      this.setState({ point: data.points, floor: data.floor, floorPoint: data.floorPoint });
    }
  }

  polygonToSvg(n: number[][]) {
    let r = n
      .map((it) =>
        it
          .map((s) => {
            if (it.indexOf(s) == 1) return ((zeroY - s) / scale).toString();
            else return (s / scale).toString();
          })
          .join(','),
      )
      .join(' ');
    return r;
  }

  getLeft(n: number[][]) {
    return n.map((it) =>
      it.map((i) => {
        if (it.indexOf(i) == 0) return zeroX - i;
        else return i;
      }),
    );
  }

  getRight(n: number[][]) {
    return n.map((it) =>
      it.map((i) => {
        if (it.indexOf(i) == 0) return zeroX + i;
        else return i;
      }),
    );
  }

  setLevel(n: number[][], l, s) {
    return n.map((it) =>
      it.map((i) => {
        if (it.indexOf(i) == 1) return l + i;
        else return i - s;
      }),
    );
  }

  setRightLevel(n: number[][], l) {
    return n.map((it) =>
      it.map((i) => {
        if (it.indexOf(i) == 0) return l + i;
        else return i;
      }),
    );
  }

  setTopLevel(n: number[][], l) {
    return n.map((it) =>
      it.map((i) => {
        if (it.indexOf(i) == 1) return i + l;
        else return i;
      }),
    );
  }

  getX(n: number) {
    return ((zeroX + n) * -1) / scale;
  }

  getY(n: number) {
    return ((zeroY - n) * -1) / scale;
  }

  render() {
    const { hPoint, wPoint, floor, floorPoint } = this.state;
    const points = this.state.point.map((p) => {
      const point = [p[0], floor ? p[1] + floorPoint : p[1]];
      return {
        point: point,
        oversize: this.overflow(point),
      };
    });
    const oversizeCode = this.formula;
    const ovr1 = '#FFFFC6';
    const ovr2 = '#FFD7B9';
    const ovr3 = '#FEAE74';
    const ovr4 = '#FE8974';
    const ovr5 = '#FF7F27';
    const ovr6 = '#FF0000';
    const vSuperOversize = points.filter((p) => p.oversize[3] == 8);
    const vOversize = points.filter((p) => [1, 2, 3, 4, 5, 6, 8].includes(p.oversize[0]));
    const sideOversize = points.filter((p) => [1, 2, 3, 4, 5, 6, 8].includes(p.oversize[1]));
    const topOversize = points.filter((p) => [1, 2, 3, 8].includes(p.oversize[2]));
    const withoutOversize = points.filter((p) => !p.oversize.find((t) => t != 0));
    const overGabaritPs = points.filter((p) => p.oversize.find((t) => t == 9));
    return (
      <>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col sm={24} md={24} xl={10}>
            <svg height={7000 / scale} width={6000 / scale}>
              <polygon
                points={this.polygonToSvg(this.getLeft(gabaritPsLeft))}
                style={{ fill: 'white', stroke: 'red', strokeWidth: '0.5' }}
              />
              <polygon
                points={this.polygonToSvg(this.getRight(gabaritPsRight))}
                style={{ fill: 'white', stroke: 'red', strokeWidth: '0.5' }}
              />
              <polygon points={this.polygonToSvg(this.getLeft(gabarit))} style={{ fill: 'white', stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(side1))} style={{ fill: ovr1, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(top1))} style={{ fill: ovr1, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(bottom1))} style={{ fill: ovr1, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(top2))} style={{ fill: ovr2, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(side2))} style={{ fill: ovr2, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(bottom2))} style={{ fill: ovr2, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(top3))} style={{ fill: ovr3, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(side3))} style={{ fill: ovr3, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(bottom3))} style={{ fill: ovr3, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(side4))} style={{ fill: ovr4, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(bottom4))} style={{ fill: ovr4, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(side5))} style={{ fill: ovr5, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(bottom5))} style={{ fill: ovr5, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(side6))} style={{ fill: ovr6, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getLeft(bottom6))} style={{ fill: ovr6, stroke: 'black', strokeWidth: '0.5' }} />

              <polygon points={this.polygonToSvg(this.getRight(gabarit))} style={{ fill: 'white', stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(side1))} style={{ fill: ovr1, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(top1))} style={{ fill: ovr1, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(bottom1))} style={{ fill: ovr1, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(top2))} style={{ fill: ovr2, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(side2))} style={{ fill: ovr2, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(bottom2))} style={{ fill: ovr2, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(top3))} style={{ fill: ovr3, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(side3))} style={{ fill: ovr3, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(bottom3))} style={{ fill: ovr3, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(side4))} style={{ fill: ovr4, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(bottom4))} style={{ fill: ovr4, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(side5))} style={{ fill: ovr5, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(bottom5))} style={{ fill: ovr5, stroke: 'black', strokeWidth: '0.5' }} />
              <polygon points={this.polygonToSvg(this.getRight(side6))} style={{ fill: ovr6, stroke: 'black', strokeWidth: '0.5' }} />
              <text x={(zeroX - 1800) / scale} y={(zeroY - 5500 - 100) / scale} style={{ fontSize: '8px' }}>
                Габарит приближения строений
              </text>
              <text x={(zeroX - 400) / scale} y={(zeroY - floorPoint - 10) / scale}>
                Пол вагона
              </text>
              <line
                x1={(zeroX - 2240) / scale}
                y1={(zeroY - floorPoint) / scale}
                x2={(zeroX + 2240) / scale}
                y2={(zeroY - floorPoint) / scale}
                style={{ strokeDasharray: '20 5', stroke: 'black', strokeWidth: '1px' }}
              />
              <line
                x1={zeroX / scale}
                y1={0}
                x2={zeroX / scale}
                y2={zeroY / scale + 10}
                style={{ stroke: 'black', strokeWidth: '0.5px' }}
              />
              <text x={20} y={zeroY / scale - 2}>
                УГР
              </text>
              <line
                x1={(zeroX - 2500) / scale}
                y1={zeroY / scale}
                x2={(zeroX + 2500) / scale}
                y2={zeroY / scale}
                style={{ stroke: 'black', strokeWidth: '3px' }}
              />
              <line
                //Линия потолка
                x1={(zeroX + 1100) / scale}
                y1={(zeroY - 5300) / scale}
                x2={(zeroX + 2500) / scale}
                y2={(zeroY - 5300) / scale}
                style={{ stroke: 'black', strokeWidth: '1px' }}
              />
              {arrowHorizontalLines.map((l) => (
                <>
                  <polygon points={this.polygonToSvg(this.getRight(this.setLevel(arrowLeft, l[0], l[2])))} fill="black" />
                  <text x={(zeroX + l[1] / 3 - l[2]) / scale} y={(zeroY - l[0] - 60) / scale} style={{ fontSize: '8px' }}>
                    {l[1]}
                  </text>
                  <line
                    x1={(zeroX - l[2] + 150) / scale}
                    x2={(zeroX + l[1] - l[2] - 150) / scale}
                    y1={(zeroY - l[0] - 35) / scale}
                    y2={(zeroY - l[0] - 35) / scale}
                    style={{ stroke: 'black', strokeWidth: '1px' }}
                  />
                  <polygon
                    points={this.polygonToSvg(this.getRight(this.setLevel(this.setRightLevel(arrowRight, l[1]), l[0], l[2])))}
                    fill="black"
                  />
                </>
              ))}
              {arrowVerticalLines.map((l) => (
                <>
                  <polygon
                    points={this.polygonToSvg(this.getRight(this.setLevel(this.setRightLevel(arrowTop, l[1]), l[0], 0)))}
                    fill="black"
                  />
                  <text x={(zeroX + l[1] + 35) / scale} y={(zeroY - l[0] / 5) / scale} style={{ fontSize: '8px' }}>
                    {l[2]}
                  </text>
                  <line
                    x1={(zeroX + l[1]) / scale}
                    x2={(zeroX + l[1]) / scale}
                    y1={(zeroY - l[0] + 50) / scale}
                    y2={(zeroY - l[2] + l[0] - 50) / scale}
                    style={{ stroke: 'black', strokeWidth: '1px' }}
                  />
                  <polygon points={this.polygonToSvg(this.getRight(this.setRightLevel(arrowBottom, l[1])))} fill="black" />
                </>
              ))}
              {points.map((p, index) => (
                <circle key={'p' + index} cx={(zeroX - p.point[0]) / scale} cy={(zeroY - p.point[1]) / scale} r={4} />
              ))}
            </svg>
          </Col>
          <Col sm={24} md={24} xl={14}>
            <h5>Ввод координат точек груза, выходящих за очертание основного габарита погрузки</h5>
            <div className="ant-table-content">
              <table style={{ tableLayout: 'auto', width: '100%' }}>
                <tbody className="ant-table-tbody">
                  <tr key={0} className="ant-table-row ant-table-row-level-0">
                    <td className="ant-table-cell">
                      <div>
                        Высота точки (от {floor ? 0 : 150} до {floor ? 5300 - floorPoint : 5300} мм)
                      </div>
                    </td>
                    <td className="ant-table-cell">
                      <InputNumber min={0} max={6000} defaultValue={0} onChange={(value) => this.setState({ wPoint: Number(value) })} />
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      <Button onClick={() => this.onAddPoints(hPoint, wPoint)}>Добавить </Button>
                    </td>
                  </tr>
                  <tr key={2} className="ant-table-row ant-table-row-level-0">
                    <td className="ant-table-cell">
                      <div>Полуширина</div>
                    </td>
                    <td className="ant-table-cell">
                      <InputNumber min={0} max={2500} defaultValue={0} onChange={(value) => this.setState({ hPoint: Number(value) })} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>Распределение точек по зонам негабаритности</div>
            <div className="ant-table-content">
              <table className="table" style={{ tableLayout: 'auto', border: '3px solid black', width: '100%' }}>
                <thead className="ant-table-thead" style={{ border: '3px solid black' }}>
                  <tr>
                    <th className="ant-table-cell" style={{ textAlign: 'center' }}>
                      Высота
                    </th>
                    <th className="ant-table-cell" style={{ textAlign: 'center' }}>
                      Полуширина
                    </th>
                    <th className="ant-table-cell" style={{ textAlign: 'center' }}>
                      Степень
                    </th>
                    <th className="ant-table-cell"> </th>
                  </tr>
                </thead>
                {overGabaritPs.length > 0 && (
                  <tbody>
                    <tr>
                      <td className="ant-table-cell" style={{ color: 'white', backgroundColor: '#9c0122', padding: '5px' }} colSpan={4}>
                        <span>{'За габаритом приближения строений'}</span>
                      </td>
                    </tr>
                    {overGabaritPs.map((p, i) => this.pointTr(p.point, points.indexOf(p), null))}
                  </tbody>
                )}
                {vSuperOversize.length > 0 && (
                  <tbody>
                    <tr>
                      <td className="ant-table-cell" style={{ color: 'white', backgroundColor: '#4977E9', padding: '5px' }} colSpan={4}>
                        <span>{'Вертикальная свехнегабаритность'}</span>
                      </td>
                    </tr>
                    {vSuperOversize.map((p, i) => this.pointTr(p.point, points.indexOf(p), 8))}
                  </tbody>
                )}
                {vOversize.length > 0 && (
                  <tbody>
                    <tr>
                      <td className="ant-table-cell" style={{ color: 'white', backgroundColor: '#4977E9', padding: '5px' }} colSpan={4}>
                        <span>{'Нижняя негабаритность'}</span>
                      </td>
                    </tr>
                    {vOversize.map((p) => this.pointTr(p.point, points.indexOf(p), p.oversize[0]))}
                  </tbody>
                )}
                {sideOversize.length > 0 && (
                  <tbody>
                    <tr>
                      <td className="ant-table-cell" style={{ color: 'white', backgroundColor: '#4977E9', padding: '5px' }} colSpan={4}>
                        <span>{'Боковая негабаритность'}</span>
                      </td>
                    </tr>
                    {sideOversize.map((p) => this.pointTr(p.point, points.indexOf(p), p.oversize[1]))}
                  </tbody>
                )}
                {topOversize.length > 0 && (
                  <tbody>
                    <tr>
                      <td className="ant-table-cell" style={{ color: 'white', backgroundColor: '#4977E9', padding: '5px' }} colSpan={4}>
                        <span>{'Верхняя негабаритность'}</span>
                      </td>
                    </tr>
                    {topOversize.map((p) => this.pointTr(p.point, points.indexOf(p), p.oversize[2]))}
                  </tbody>
                )}
                {withoutOversize.length > 0 && (
                  <tbody>
                    <tr>
                      <td className="ant-table-cell" style={{ color: 'white', backgroundColor: '#4977E9', padding: '5px' }} colSpan={4}>
                        <span>{'Габарит'}</span>
                      </td>
                    </tr>
                    {withoutOversize.map((p) => this.pointTr(p.point, points.indexOf(p), null))}
                  </tbody>
                )}
              </table>
              {points.length == 0 && <Empty description={'Укажите точки'} />}
            </div>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: '4px' }}>
          <Col span={24}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={8}>
                <Checkbox checked={floor} onChange={() => this.setState({ floor: !floor })} />
                <span style={{ paddingLeft: '5px' }}>Высота груза относительно пола вагона</span>
              </Col>
              <Col span={16}>
                <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>Высота до уровня пола вагона</span>
                <InputNumber
                  min={150}
                  max={2500}
                  style={{ paddingLeft: '5px', paddingRight: '5px' }}
                  defaultValue={1400}
                  onChange={(value) => this.setState({ floorPoint: Number(value) })}
                />
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Divider>Результат</Divider>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={2}>
                    <Input value={'H' + oversizeCode} readOnly style={{ paddingLeft: '5px', paddingRight: '5px' }} />
                  </Col>
                  <Col span={22}>
                    <Input value={oversizeStr(oversizeCode)} readOnly style={{ paddingLeft: '5px', paddingRight: '5px' }} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  overflow(p: number[]) {
    if (classifyPoint(gabaritPsLeft, p) == 1) return [9, 9, 9, 9];
    if (p[1] > 5300) return [0, 0, 0, 8];
    if (classifyPoint(bottom8, p) <= 0) return [8, 0, 0, 0];
    if (classifyPoint(side8, p) <= 0) return [0, 8, 0, 0];
    if (classifyPoint(top8side8, p) <= 0) return [0, 8, 8, 0];
    if (classifyPoint(top8, p) <= 0) return [0, 0, 8, 0];
    if (classifyPoint(bottom6, p) <= 0) return [6, 0, 0, 0];
    if (classifyPoint(side6, p) <= 0) return [0, 6, 0, 0];
    if (classifyPoint(bottom5, p) <= 0) return [5, 0, 0, 0];
    if (classifyPoint(side5, p) <= 0) return [0, 5, 0, 0];
    if (classifyPoint(bottom4, p) <= 0) return [4, 0, 0, 0];
    if (classifyPoint(side4, p) <= 0) return [0, 4, 0, 0];

    if (classifyPoint(top3side3, p) <= 0) return [0, 3, 3, 0];
    if (classifyPoint(bottom3, p) <= 0) return [3, 0, 0, 0];
    if (classifyPoint(side3, p) <= 0) return [0, 3, 0, 0];
    if (classifyPoint(top3, p) <= 0) return [0, 0, 3, 0];

    if (classifyPoint(top2side2, p) <= 0) return [0, 2, 2, 0];
    if (classifyPoint(bottom2, p) <= 0) return [2, 0, 0, 0];
    if (classifyPoint(side2, p) <= 0) return [0, 2, 0, 0];
    if (classifyPoint(top2, p) <= 0) return [0, 0, 2, 0];

    if (classifyPoint(top1side1, p) <= 0) return [0, 1, 1, 0];
    if (classifyPoint(bottom1, p) <= 0) return [1, 0, 0, 0];
    if (classifyPoint(side1, p) <= 0) return [0, 1, 0, 0];
    if (classifyPoint(top1, p) <= 0) return [0, 0, 1, 0];
    return [0, 0, 0, 0];
  }

  pointTr(p: number[], i, mark: number) {
    return (
      <tr key={i} className="ant-table-row ant-table-row-level-0">
        <td className="ant-table-cell" style={{ textAlign: 'center' }}>
          {p[1]}
        </td>
        <td className="ant-table-cell" style={{ textAlign: 'center' }}>
          {p[0]}
        </td>
        <td className="ant-table-cell" style={{ textAlign: 'center' }}>
          {mark}
        </td>
        <td className="ant-table-cell" style={{ textAlign: 'center' }}>
          <Popconfirm title="Удалить точку？" okText="Да" cancelText="Нет" onConfirm={() => this.handleRemoveConfirm(i)}>
            <DeleteOutlined title="Удалить точку" />
          </Popconfirm>
        </td>
      </tr>
    );
  }

  get formula() {
    const points = this.state.point.map((p) => [p[0], this.state.floor ? p[1] + this.state.floorPoint : p[1]]);
    let result = [0, 0, 0, 0];
    points.forEach((p) => {
      const s = this.overflow(p);
      if (result[0] < s[0]) result[0] = s[0];
      if (result[1] < s[1]) result[1] = s[1];
      if (result[2] < s[2]) result[2] = s[2];
      if (result[3] < s[3]) result[3] = s[3];
    });
    if (result.find((it) => it == 9)) return null;
    return result.join('');
  }

  handleRemoveConfirm = (key) => {
    const point = this.state.point;
    point.splice(key, 1);
    this.setState({
      point: point,
    });
    this.emit();
  };

  emit() {
    const p: OversizePoints = {
      points: this.state.point,
      floor: this.state.floor,
      floorPoint: this.state.floorPoint,
    };
    optionsStore.request.oversizePoints = JSON.stringify(p);
    this.props.onChange(this.formula);
  }

  onAddPoints = (h, w) => {
    const point = this.state.point;
    point.push([h, w]);
    this.setState({
      point: point,
    });
    this.emit();
  };
}

export default OptionsOversizeImg;
