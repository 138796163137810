import { observer } from 'mobx-react';
import moment from 'moment/moment';
import React, { useEffect } from 'react';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CalendarCustom from '@components/fw/Calendar/CalendarCustom';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';

import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import { registerOfBetsHeaderExtra as headerExtra } from '../header-extra';
import { RegisterOfBetsHeaderProps } from './types/header-props';

export const RegisterOfBetsHeader = observer(({ dataSource }: RegisterOfBetsHeaderProps) => {
  {
    /* TODO: Указать access */
  }
  const access = true;

  const onDatesChange = (event: [moment.Moment, moment.Moment]) => {
    registerOfBetsStore.setReportRange(event);
    dataSource.reload();
  };
  const title = 'Реестр ставок';
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <AdaptivePageHeader
      extra={
        <CollapsedToolbar
          content={
            access
              ? [...headerExtra, <CalendarCustom key={'calendar_1'} onChange={onDatesChange} values={registerOfBetsStore.reportRange} />]
              : [<CalendarCustom key={'calendar_2'} onChange={onDatesChange} values={registerOfBetsStore.reportRange} />]
          }
        />
      }
      title={title}
    />
  );
});
