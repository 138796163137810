import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, Popover } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';

import designStore from '@stores/designStore/designStore';
import messengerStore from '@stores/messengerStore/messengerStore';

import { getTitle } from '@utils/roleAccess';

import styles from './DialogHeader.module.scss';

const { Search } = Input;

@observer
class DialogHeader extends Component {
  render() {
    const assistant = !!messengerStore.selectedDialog && messengerStore.selectedDialog.dialogType == 'Assist';
    const dialog = messengerStore.selectedDialog;
    const users = dialog ? dialog.users : [];
    const title = dialog ? dialog.name : 'Сообщения';
    document.title = getTitle('messenger');
    return (
      <AdaptivePageHeader
        className={styles.content}
        title={title}
        subTitle={
          users.length > 0 ? (
            <>
              <Popover
                className={'messageUsers'}
                content={
                  <div>
                    {users.map((item, index) => (
                      <div key={item.userId}>{item.name}</div>
                    ))}
                  </div>
                }
                title="Участники"
                trigger="hover">
                <span style={{ cursor: 'pointer' }}>
                  <span>{users.length}</span>
                  <UserOutlined />
                </span>
              </Popover>
            </>
          ) : null
        }
        extra={
          dialog ? (
            <CollapsedToolbar
              content={[
                <Search
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={'searchMessage'}
                  key={'dialog_search'}
                  placeholder="Поиск"
                  allowClear={true}
                  onSearch={(value) => this.handleSearch(value)}
                />,
                !assistant ? (
                  <Button block={designStore.isMobile} key={'dialog_menu_btn'} icon={<MenuOutlined />} onClick={this.handleEditDialog}>
                    {designStore.isMobile ? 'изменить' : null}
                  </Button>
                ) : null,
              ]}
            />
          ) : (
            []
          )
        }
      />
    );
  }

  handleSearch = (e) => {
    messengerStore.searchStr = e;
  };

  handleEditDialog = () => {
    messengerStore.editDialog = true;
  };
}

export default DialogHeader;
