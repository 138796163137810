import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ActiveWagon } from '@stores/trackingStore/wagon/WagonTypes';
import trackingWagonStore from '@stores/trackingStore/wagon/trackingWagonStore';

import { showSuccessNotify } from '@utils/notify';

interface WagonGroupRemoveProps {
  onDataReady?: () => void;
  data: ActiveWagon[] | null;
}

interface WagonGroupRemoveState {}

@observer
class WagonGroupRemove extends Component<WagonGroupRemoveProps, WagonGroupRemoveState> {
  constructor(props: WagonGroupRemoveProps) {
    super(props);

    this.state = {};
  }

  postData() {
    trackingWagonStore
      .remove({
        numbers: this.props.data.map((w) => Number(w.number)),
      })
      .then(() => {
        this.setState({ visible: false });
        this.props.onDataReady();
        showSuccessNotify('Снятие вагонов', 'Выбранные вагоны сняты со слежения');
      });
  }

  render() {
    const hasData = !!this.props.data && this.props.data.length;
    return (
      <>
        <Popconfirm title={'Снять вагоны?'} onConfirm={this.postData.bind(this)}>
          <Button size={'small'} type={'link'} icon={<DeleteOutlined />} disabled={!hasData}>
            cнять выбранные
          </Button>
        </Popconfirm>
      </>
    );
  }
}

export default WagonGroupRemove;
