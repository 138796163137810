import { Tag } from 'antd';
import React from 'react';

const TagDxCell: React.FC<any> = (props) => {
  const value = Array.isArray(props.value) ? props.value : typeof props.value == 'string' ? JSON.parse(props.value) : null;
  return value ? (
    <span>
      {value.map((tag, index) => {
        return (
          <Tag key={`tag_${index}`} color={tag.metadata ? tag.metadata.color : null}>
            {tag.name}
          </Tag>
        );
      })}
    </span>
  ) : null;
};

export default TagDxCell;
