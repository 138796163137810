import { LoadOptions } from 'devextreme/data/load_options';

import HttpClient from '@utils/httpClient';

const service = {
  getReport(begin: string, end: string, loadOptions: LoadOptions) {
    return HttpClient.post(`/api/transportRegistry/report?begin=${begin}&end=${end}`, loadOptions).then((resp) => {
      return resp.data;
    });
  },

  excludeItineraries(itineraryIds: Array<string>) {
    return HttpClient.post('/api/transportRegistry/exclude-itineraries', itineraryIds).then((resp) => {
      return resp.data;
    });
  },
};
export default service;
