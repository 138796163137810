import { Checkbox, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import { TariffCountryDetailModel } from '@stores/sendsStore/sendData';

interface State {
  data: TariffCountryDetailModel[];
}

interface Props {}

class OptionsCoefficientViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: optionsStore.result ? optionsStore.result.countryDetails : [],
    };
  }

  handleSubmit = () => {
    optionsStore.result.countryDetails = this.state.data;
    optionsStore.request.tariffData.coefficients = [];
    this.state.data
      .filter((c) => c.countryDetailData && c.countryDetailData.coefficients && c.countryDetailData.coefficients.length)
      .forEach((c) => {
        optionsStore.request.tariffData.coefficients = optionsStore.request.tariffData.coefficients.concat(
          c.countryDetailData.coefficients,
        );
      });
    optionsStore.calcTariff().then(() => {
      optionsStore.showCoefficient = false;
    });
  };

  handleChange = (e, item) => {
    item.isEnabled = e.target.checked ? 1 : 0;
    this.forceUpdate();
  };

  componentDidMount() {
    this.setState({ data: optionsStore.result ? optionsStore.result.countryDetails : [] });
  }

  render() {
    const isBusy = optionsStore.isBusy;
    const data = this.state.data;
    const isMobile = designStore.isMobile;
    return (
      <>
        <Modal
          title={'Коэффициенты'}
          open={true}
          onCancel={() => {
            optionsStore.showCoefficient = false;
          }}
          onOk={() => this.handleSubmit()}
          centered={isMobile}
          width={isMobile ? '100%' : '60%'}
          okButtonProps={{ loading: isBusy }}>
          <ScrolledContainer className={'scrolledContainer'} style={{ width: '100%' }}>
            <table>
              <thead className={'ant-table-thead'}>
                <tr>
                  <th className="ant-table-cell">Страна</th>
                  <th className="ant-table-cell">
                    <Checkbox checked={false} />
                  </th>
                  <th className="ant-table-cell">Коэффициент</th>
                  <th className="ant-table-cell">Описание</th>
                  <th className="ant-table-cell">П/С</th>
                </tr>
              </thead>
              {data
                .filter((c) => c.countryDetailData && c.countryDetailData.coefficients && c.countryDetailData.coefficients.length)
                .map((item) => (
                  <tbody key={item.countryCode} className={'ant-table-tbody'}>
                    {item.countryDetailData.coefficients.map((c, index) => (
                      <tr className="ant-table-row ant-table-row-level-0" key={'c' + index + item.countryCode}>
                        <td className="ant-table-cell">
                          {item.countryName} {item.isEmpty ? '(возврат)' : null}
                        </td>
                        <td className="ant-table-cell">
                          <Checkbox checked={c.isEnabled == 1} onChange={(e) => this.handleChange(e, c)} />
                        </td>
                        <td className="ant-table-cell">{c.coefficient}</td>
                        <td className="ant-table-cell">{c.description}</td>
                        <td className="ant-table-cell">{this.getWagonTypeName(c.globosoftWagonTypeCode)}</td>
                      </tr>
                    ))}
                  </tbody>
                ))}
            </table>
          </ScrolledContainer>
        </Modal>
      </>
    );
  }

  getWagonTypeName = (id: number) => {
    let find = optionsStore.wagonTypesDictionary.find((s) => s.wagonTypeCode == id);
    if (find) return find.shortName;
    else return '';
  };
}

export default observer(OptionsCoefficientViewer);
