import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Badge, List, Popconfirm, Tooltip } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import TagDxCell from '@components/fw/DxCellTemplates/TagDxCell';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import { SendModel } from '@stores/sendsStore/sendData';
import sendsStore from '@stores/sendsStore/sendsStore';
import userStore from '@stores/userStore/userStore';

import styles from './BoardView.module.scss';

const ClaimsList = (props: {
  list: any[];
  handleEdit(item: any): void;
  handleDelete(item: any): void;
  canEdit: boolean;
  canDelete: boolean;
}) => {
  return (
    <List
      dataSource={props.list}
      renderItem={(item) => (
        <List.Item
          actions={[
            props.canEdit ? (
              <Tooltip title={'Редактировать'}>
                <EditOutlined onClick={() => props.handleEdit(item)} />
              </Tooltip>
            ) : null,
            props.canDelete ? (
              <Tooltip title={'Удалить'}>
                <Popconfirm title="Удалить отправку？" okText="Да" cancelText="Нет" onConfirm={() => props.handleDelete(item)}>
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            ) : null,
          ]}>
          <List.Item.Meta
            title={item.sendName}
            description={
              <>
                <TagDxCell value={item.tags} />
                <div>
                  {item.costFact ? (
                    <span>
                      Ставка факт: <b>{item.costFact}₽</b>
                    </span>
                  ) : null}
                </div>
              </>
            }
          />
        </List.Item>
      )}
    />
  );
};

const CardTitle: React.FC<{ name: string; count: number; totalFact: number }> = (props) => {
  return (
    <>
      <Badge count={props.count}>
        <div style={{ paddingRight: 8 }}>{props.name}</div>
      </Badge>
      <div style={{ fontSize: 12 }}>Итого факт: {props.totalFact} ₽</div>
    </>
  );
};

interface BoardViewProps {
  onEdit(row: SendModel): void;
  onDelete(row: SendModel): void;
}

@observer
class BoardView extends Component<BoardViewProps> {
  render() {
    const { creat, onper, done } = sendsStore.sendsForBoard;
    const isBusy = sendsStore.isBusy;
    const canEdit = userStore.canAccess('sends/edit');
    const canDelete = userStore.canAccess('sends/delete');
    return (
      <ScrolledContainer style={{ height: 'calc(100vh - var(--header-height))' }}>
        <div className={styles.lists}>
          <AdaptiveCard
            size={'small'}
            title={<CardTitle name={'Создана'} count={creat.length} totalFact={this.countTotal(creat, 'costFact')} />}
            bordered={false}
            className={styles.list}>
            <ClaimsList
              list={creat}
              handleEdit={this.handleEdit}
              handleDelete={this.handleDelete}
              canEdit={canEdit}
              canDelete={canDelete}
            />
          </AdaptiveCard>
          <AdaptiveCard
            size={'small'}
            title={<CardTitle name={'На исполнении'} count={onper.length} totalFact={this.countTotal(onper, 'costFact')} />}
            bordered={false}
            className={styles.list}>
            <ClaimsList
              list={onper}
              handleEdit={this.handleEdit}
              handleDelete={this.handleDelete}
              canEdit={canEdit}
              canDelete={canDelete}
            />
          </AdaptiveCard>
          <AdaptiveCard
            size={'small'}
            title={<CardTitle name={'Выполнено'} count={done.length} totalFact={this.countTotal(done, 'costFact')} />}
            bordered={false}
            className={styles.list}>
            <ClaimsList list={done} handleEdit={this.handleEdit} handleDelete={this.handleDelete} canEdit={canEdit} canDelete={canDelete} />
          </AdaptiveCard>
        </div>
      </ScrolledContainer>
    );
  }

  countTotal(values: any[], propName: string) {
    const vals = values.filter((x) => x[propName]).map((x) => x[propName]);
    return _.sum(vals);
  }

  handleEdit = (item: SendModel) => {
    this.props.onEdit(item);
  };

  handleDelete = (item: SendModel) => {
    this.props.onDelete(item);
  };
}

export default BoardView;
