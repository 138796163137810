import Icon from '@ant-design/icons';
import { Checkbox, Col, Form, Popover, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import optionsStore from '@stores/optionsStore';

import { countries } from '../ui/options-escort-editor';

interface Props {
  form: FormInstance;
}

const HELP_TEXT = 'Укажите тарифное расстояние для уточнения расстояния перевозки по одной из стран маршрута';

const TariffDistance = observer(({ form }: Props) => {
  const [visible, setVisible] = useState(false);
  const { tariffDistance, tariffDistanceCountryCode } = optionsStore.request;

  function onVisibleChange(value: boolean) {
    setVisible(value);
    form.setFieldsValue({ tariffDistance: null, tariffDistanceCountryCode: null });
  }

  useEffect(() => {
    setVisible(!!tariffDistance || !!tariffDistanceCountryCode);
  }, [tariffDistance, tariffDistanceCountryCode]);

  return (
    <div>
      <Checkbox style={{ marginTop: 14 }} checked={visible} onChange={({ target }) => onVisibleChange(target.checked)}>
        Тарифное расстояние{' '}
        <Popover style={{ width: '200px' }} trigger="hover" content={<div style={{ width: '200px' }}>{HELP_TEXT}</div>}>
          <Icon component={iconQuestion} style={{ cursor: 'pointer', height: 24 }} />
        </Popover>
      </Checkbox>
      {visible && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={12} style={{ paddingBottom: '6px' }}>
            <Form.Item name={'tariffDistance'} rules={[{ required: true, message: 'Укажите расстояние' }]}>
              <FloatInputNumber min={0} max={40000} step={1} placeholder={'Расстояние'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} style={{ paddingBottom: '6px' }}>
            <Form.Item name={'tariffDistanceCountryCode'} rules={[{ required: true, message: 'Укажите гос-во' }]}>
              <FloatSelect placeholder={'Гос-во'}>
                {countries.map((item, index) => {
                  return (
                    <Select.Option value={item.code} key={`tariffDistanceCountryCode${index}`}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </FloatSelect>
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
});

export default TariffDistance;
