import { DownloadOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { Component } from 'react';

import PdfViewModal from '@components/fw/DocumentViewer/PdfViewModal';
import EcpModal from '@components/routed/EcpPage/EcpModal';

import designStore from '@stores/designStore/designStore';
import docRegistryStoreService from '@stores/docRegistryStore/docRegistryStore.service';

import { showErrorNotify } from '@utils/notify';

interface Props {
  name: string;
  id: string;
  ecp?: boolean;
  onChange(): void;
}

interface State {
  isBusyDownload: boolean;
  isBusyPreview: boolean;
  blob: Blob;
  signVisible: boolean;
}

class DocRegistryActions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusyDownload: false,
      isBusyPreview: false,
      blob: null,
      signVisible: false,
    };
  }

  render() {
    const name = this.props.name;
    const id = this.props.id;
    return (
      <span>
        <Button
          size={'small'}
          title={'Скачать'}
          loading={this.state.isBusyDownload}
          icon={<DownloadOutlined />}
          onClick={() => this.onDownload()}
        />
        <Button
          size={'small'}
          title={'Посмотреть'}
          loading={this.state.isBusyPreview}
          icon={<EyeOutlined />}
          onClick={() => this.onPreview()}
        />
        {this.props.ecp && !designStore.isMobile && (
          <Button size={'small'} title={'Подписать ЭЦП'} icon={<EditOutlined />} onClick={() => this.setState({ signVisible: true })} />
        )}
        {!!this.state.blob && (
          <PdfViewModal data={this.state.blob} visible={!!this.state.blob} onClose={() => this.setState({ blob: null })} />
        )}
        {this.state.signVisible && (
          <EcpModal
            documentId={id}
            documentName={name}
            onOk={() => {
              this.setState({ signVisible: false });
              this.props.onChange();
            }}
            onCancel={() => this.setState({ signVisible: false })}
          />
        )}
      </span>
    );
  }

  onDownload = () => {
    this.setState({ isBusyDownload: true });
    docRegistryStoreService
      .download(this.props.id)
      .then((ans) => {
        if (!ans || !ans.size) {
          showErrorNotify('Документ не найден', '');
          return;
        }
        let mockEvent = document.createEvent('MouseEvents');
        let mockElement = document.createElement('a');
        mockElement.download = this.props.id + '.pdf';
        mockElement.href = window.URL.createObjectURL(new Blob([ans]));
        mockElement.dataset.downloadurl = ['blob', mockElement.download, mockElement.href].join(':');
        mockEvent.initEvent('click');
        mockElement.dispatchEvent(mockEvent);
      })
      .finally(() => {
        this.setState({ isBusyDownload: false });
      });
  };

  onPreview = () => {
    this.setState({ isBusyPreview: true });
    docRegistryStoreService
      .download(this.props.id)
      .then((ans) => {
        if (!ans || !ans.size) {
          showErrorNotify('Документ не найден', '');
          return;
        }
        this.setState({ blob: ans });
      })
      .finally(() => {
        this.setState({ isBusyPreview: false });
      });
  };
}

export default DocRegistryActions;
