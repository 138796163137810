import { Column } from 'devextreme-react/data-grid';
import moment from 'moment/moment';
import React from 'react';

import CapitalizeDxCell from '@components/fw/DxCellTemplates/CapitalizeDxCell';
import CatalogDxCellReport from '@components/fw/DxCellTemplates/CatalogDxCellReport';
import ClaimDxCellReport from '@components/fw/DxCellTemplates/ClaimDxCellReport';
import ClientLrDxCellReport from '@components/fw/DxCellTemplates/ClientLrDxCellReport';
import CurrencyDxCell from '@components/fw/DxCellTemplates/CurrencyDxCell';
import NotesDxCellReport from '@components/fw/DxCellTemplates/NotesDxCellReport';
import NotificationDxCellReport from '@components/fw/DxCellTemplates/NotificationDxCellReport';
import SendDxCellReport from '@components/fw/DxCellTemplates/SendDxCellReport';
import StationDxCell from '@components/fw/DxCellTemplates/StationDxCell';

import companyCatalogStore from '@stores/companyCatalogStore/companyCatalogStore';
import designStore from '@stores/designStore';
import dispatcherStore from '@stores/dispatcherStore/dispatcherStore';
import markStore from '@stores/markStore/markStore';
import notificationStore from '@stores/notification/notificationStore';
import { GLOBAL_DATETIME_FORMAT } from '@stores/optionsStore/optionsStoreData';
import organizationsStore from '@stores/organizationsStore';
import userStore from '@stores/userStore/userStore';

export const reportJsonFields = [
  'wagonnotes',
  'wagonnotes1',
  'wagonnotes2',
  'wagonnotes3',
  'containernotes',
  'containernotes1',
  'containernotes2',
  'containernotes3',
  'plansend',
  'planclaim',
  'currentsend',
  'currentclaim',
  'wagoncatalogs',
  'containercatalogs',
  'wagonnotification',
  'containenotification',
  'currentclientlr',
];

const stationNameFields = [
  'operationstationfullname',
  'operationstationshortname',
  'loadstationfullname',
  'loadstationshortname',
  'formstationfullname',
  'unformstationfullname',
  'destinationstationfullname',
  'destinationstationshortname',
];

const otherNameFields = [
  'operationroadfullname',
  'operationroadshortname',
  'operationcountryname',
  'loadroadfullname',
  'loadroadshortname',
  'loadcountryname',
  'destinationroadfullname',
  'destinationroadshortname',
  'destinationcountryname',
  'previtineraryetsngname',
  'freightname',
];

export const getCalculateFilter = (dxValue, selectedFilterOperations, target, fieldName) => {
  if (reportJsonFields.includes(fieldName)) {
    if (dxValue) return [fieldName, selectedFilterOperations.replace('=', 'contains'), dxValue];
  }
  return [fieldName, selectedFilterOperations, dxValue];
};

export const getRender = (field) => {
  const fieldName = field.name;

  if (fieldName == 'wagoncatalogs' || fieldName == 'containercatalogs') {
    return CatalogDxCellReport;
  }
  if (fieldName.includes('wagonnotes') || fieldName.includes('containernotes')) {
    return NotesDxCellReport;
  }
  if (fieldName.includes('tariff') && !fieldName.includes('repcomtariffclass')) {
    return CurrencyDxCell;
  }
  if (fieldName.includes('planclaim') || fieldName.includes('currentclaim')) {
    return ClaimDxCellReport;
  }
  if (fieldName.includes('plansend') || fieldName.includes('currentsend')) {
    return SendDxCellReport;
  }
  if (fieldName == 'wagonnotification' || fieldName == 'containernotification') {
    return NotificationDxCellReport;
  }
  if (fieldName == 'currentclientlr') {
    return ClientLrDxCellReport;
  }
  if (fieldName == 'repcomsum') {
    return CurrencyDxCell;
  }
  if (designStore.isFormattedNameCells && stationNameFields.some((i) => i === fieldName)) {
    return StationDxCell;
  }
  if (designStore.isFormattedNameCells && otherNameFields.some((i) => i === fieldName)) {
    return CapitalizeDxCell;
  }

  if (field.filterType == 'superdate') {
    return (props) => {
      return <span>{props.value ? moment(props.value).format(GLOBAL_DATETIME_FORMAT) : ''}</span>;
    };
  }

  return undefined;
};

export const getFilterDatasource = (fieldName: string) => {
  if (fieldName == 'wagoncatalogs' || fieldName == 'containercatalogs') {
    return {
      load: (loadOptions) => {
        return companyCatalogStore.getCompanyCatalogs(userStore.userData.companyId, loadOptions.searchValue);
      },
      paginate: false,
    };
  }
  if (fieldName.includes('wagonnotes') || fieldName.includes('containernotes')) {
    return {
      load: (loadOptions) => {
        return markStore.getNotes(userStore.userData.companyId, loadOptions.searchValue, loadOptions.take, loadOptions.skip);
      },
      paginate: false,
    };
  }
  if (fieldName.includes('wagonnotification') || fieldName.includes('containernotification')) {
    return {
      load: (loadOptions) => {
        return notificationStore.getNotificationTemplates(loadOptions.searchValue);
      },
      paginate: false,
    };
  }
  if (fieldName.includes('currentclientlr')) {
    return {
      load: (loadOptions) => {
        return organizationsStore.getOrganizationsHeaderFilter(loadOptions.searchValue);
      },
      paginate: false,
    };
  }
  if (fieldName.includes('tariff') && !fieldName.includes('repcomtariffclass')) {
    return [
      {
        text: '< 30 000₽',
        value: [fieldName, '<', 30000],
      },
      {
        text: '30 000₽ - 50 000₽',
        value: [
          [fieldName, '>=', 30000],
          [fieldName, '<', 50000],
        ],
      },
      {
        text: '50 000₽ - 100 000₽',
        value: [
          [fieldName, '>=', 50000],
          [fieldName, '<', 10000],
        ],
      },
      {
        text: '100 000₽ - 200 000₽',
        value: [
          [fieldName, '>=', 100000],
          [fieldName, '<', 200000],
        ],
      },
      {
        text: '> 200 000₽',
        value: [fieldName, '>=', 200000],
      },
    ];
  }

  if (fieldName == 'repcomsum') {
    return [
      {
        text: '< 30 000₽',
        value: [fieldName, '<', 30000],
      },
      {
        text: '30 000₽ - 50 000₽',
        value: [
          [fieldName, '>=', 30000],
          [fieldName, '<', 50000],
        ],
      },
      {
        text: '50 000₽ - 100 000₽',
        value: [
          [fieldName, '>=', 50000],
          [fieldName, '<', 10000],
        ],
      },
      {
        text: '100 000₽ - 200 000₽',
        value: [
          [fieldName, '>=', 100000],
          [fieldName, '<', 200000],
        ],
      },
      {
        text: '> 200 000₽',
        value: [fieldName, '>=', 200000],
      },
    ];
  }
  return undefined;
};

export const MAX_EXCEL_ROWS_FOR_CLIENT_GENERATING = 1000;
export const MAX_EXCEL_ROWS = 1000000;
export const MAX_REPORT_ROWS = 2100000000;

export const INDEX_COLUMN = (
  <Column
    allowFixing={true}
    caption={'№'}
    //cssClass={styles.dispatcherRow}
    allowEditing={false}
    allowReordering={false}
    allowFiltering={false}
    allowSearch={false}
    allowGrouping={false}
    allowSorting={false}
    allowHeaderFiltering={false}
    allowExporting={false}
    width={30}
    cellRender={(cellElement) => {
      const offset = cellElement.component.getVisibleRows().find((x) => x.rowType === 'group')
        ? 0
        : cellElement.component.pageSize() * cellElement.component.pageIndex();
      return <span>{cellElement.row.dataIndex + 1 + offset}</span>;
    }}
  />
);

export const calculateFilter = (value, dxValue, selectedFilterOperations, target) => {
  let result;
  if (target === 'headerFilter') {
    result = getCalculateFilter(dxValue, selectedFilterOperations, target, value.name);
  } else {
    if (value.filterType == 'superdate') {
      if (selectedFilterOperations === '=') {
        return [
          [value.name, '>=', moment(dxValue).startOf('day').toDate()],
          'and',
          [value.name, '<=', moment(dxValue).endOf('day').toDate()],
        ];
      }
      if (selectedFilterOperations === '<>') {
        return [[value.name, '<', moment(dxValue).startOf('day').toDate()], 'or', [value.name, '>', moment(dxValue).endOf('day').toDate()]];
      }
      if (['>', '<='].includes(selectedFilterOperations)) {
        return [value.name, selectedFilterOperations, moment(dxValue).endOf('day').toDate()];
      }
      if (['<', '>='].includes(selectedFilterOperations)) {
        return [value.name, selectedFilterOperations, moment(dxValue).startOf('day').toDate()];
      }
      if (selectedFilterOperations === 'between') {
        if (dxValue[0] && dxValue[1])
          return [
            [value.name, '>=', moment(dxValue[0]).startOf('day').toDate()],
            'and',
            [value.name, '<=', moment(dxValue[1]).endOf('day').toDate()],
          ];
        if (dxValue[0]) return [value.name, '>=', moment(dxValue[0]).startOf('day').toDate()];
        if (dxValue[1]) return [value.name, '<=', moment(dxValue[1]).endOf('day').toDate()];
      }
    } else if (selectedFilterOperations == 'between') {
      if (dxValue[0] && dxValue[1]) return [[value.name, '>=', dxValue[0]], 'and', [value.name, '<=', dxValue[1]]];
      if (dxValue[0]) return [value.name, '>=', dxValue[0]];
      if (dxValue[1]) return [value.name, '<=', dxValue[1]];
    } else {
      result = [value.name, selectedFilterOperations, dxValue];
    }
  }
  if (value.filterType == 'string' && !reportJsonFields.includes(value.name)) {
    result.push('ignorecase');
  }
  return result;
};

export const getFilter = (fieldName: string) => {
  const result = {
    allowSearch: true,
    groupInterval: undefined,
    height: undefined,
    searchMode: undefined,
    width: undefined,
    dataSource: (e) => {
      if (dispatcherStore.jsonFieldsMode == 'available' && reportJsonFields.includes(fieldName)) {
        const oldLoadFn = e.dataSource.load;
        e.dataSource.load = (loadOptions) => {
          return oldLoadFn(loadOptions).then((ans) => {
            return ans.map((it) => {
              //Логика для json массива
              if (it.key && typeof it.key == 'string' && it.key.startsWith('[')) {
                const model = JSON.parse(it.key);
                return {
                  key: it.key,
                  value: it.value,
                  text: model.map((m) => m.title).join(','),
                  count: it.count,
                  summary: it.summary,
                };
                //Логика для json объекта
              } else if (it.key && typeof it.key == 'string' && it.key.startsWith('{')) {
                const model = JSON.parse(it.key);
                return {
                  key: it.key,
                  value: it.value,
                  text: model.name ?? model.title,
                  count: it.count,
                  summary: it.summary,
                };
              }
              //Логика для обычного поля
              else {
                return it;
              }
            });
          });
        };
      }
      return undefined;
    },
  };
  //@ts-ignore
  if (dispatcherStore.jsonFieldsMode == 'all') result.dataSource = getFilterDatasource(fieldName);
  return result;
};
