import { Col, Form, Row, Select } from 'antd';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';

import dictService from '@stores/dictStore/dictStore.service';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface ComponentProps {}

interface ComponentState {}

class ContractExtRentBack extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Form.List name="rentBackParams">
        {(fields, { add, remove }) => (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {/*<Col span={24}>
              <Form.Item>
                <Button style={{ margin: 5 }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить
                </Button>
              </Form.Item>
            </Col>*/}
            {fields.map((field, index) => (
              <Col xs={24} lg={24} key={index}>
                <AdaptiveCard
                  //title={'Параметры аренды ' + (index + 1)}
                  extra={
                    [
                      //<Button key={'delete'} size={'small'} type={'text'} onClick={() => remove(field.name)} icon={<DeleteOutlined />} />,
                    ]
                  }>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item rules={[{ required: true }]} name={[field.name, 'type']} fieldKey={[field.key, 'type']}>
                      <FloatSelect placeholder={'Тип ПС'}>
                        <Select.Option value={'wagon'}>Вагон</Select.Option>
                        <Select.Option value={'container'}>Контейнер</Select.Option>
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'date']} fieldKey={[field.key, 'date']} rules={[{ required: true }]}>
                        <FloatDatepicker placeholder={'Дата'} format={GLOBAL_DATE_FORMAT} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <Form.Item rules={[{ required: true }]} name={[field.name, 'station']} fieldKey={[field.key, 'station']}>
                        <RemoteSelect
                          float={true}
                          placeholder={'Станция сдачи'}
                          valueExpr={'stationCode'}
                          displayExpr={'fullName'}
                          dataSource={dictService.stationDict}
                          itemTemplate={(s) => (
                            <span>
                              {s.stationCode} {s.fullName}
                            </span>
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </AdaptiveCard>
              </Col>
            ))}
          </Row>
        )}
      </Form.List>
    );
  }
}

export default ContractExtRentBack;
