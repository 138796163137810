import { Button } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import statisticStore from '@stores/statisticStore/statisticStore';
import userStore from '@stores/userStore/userStore';

interface Props {}

interface State {}

class CreateButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const canChange = ['admin', 'manager', 'tech', 'dev'].includes(userStore.userData.roleCode);
    const isBusyDocs = statisticStore.isBusyDocs;

    if (!canChange) return null;
    return (
      <Button disabled={!statisticStore.companyId} loading={isBusyDocs} onClick={this.handleDocsCreate}>
        Сформировать документы
      </Button>
    );
  }
  handleDocsCreate = () => {
    statisticStore.createDocs();
  };
}

export default observer(CreateButton);
