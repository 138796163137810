import { Modal } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, Grouping, HeaderFilter, Paging } from 'devextreme-react/data-grid';
import React, { Component } from 'react';

import { showErrorNotify, showSuccessNotify } from '@utils/notify';

interface Props {
  id: string;
  onChange(): void;
  onClose(): void;
}

interface State {
  isBusy: boolean;
  selected: any[];
  wagonData: {
    wagonNumber: string;
    dislStationName: string;
    dislStationCode: string;
    lastOperation: string;
    lastOperationDate: string;
  }[];
}

class LasingTransferActModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: [],
      isBusy: true,
      wagonData: [],
    };
  }

  componentDidMount() {
    const wagonData = [
      {
        wagonNumber: '66545478',
        dislStationName: 'Лянгасово',
        dislStationCode: '27000',
        lastOperation: 'Прибытие на станцию назначения',
        lastOperationDate: '2021-08-08T00:00:00',
      },
      {
        wagonNumber: '52545478',
        dislStationName: 'Лянгасово',
        dislStationCode: '27000',
        lastOperation: 'Прибытие на станцию назначения',
        lastOperationDate: '2021-08-09T00:00:00',
      },
    ];
    this.setState({ isBusy: false, wagonData: wagonData });
  }
  onSelectionChanged = (e) => {
    this.setState({ selected: e.selectedRowKeys });
  };

  onSave = () => {
    if (!this.state.selected.length) {
      showErrorNotify('Выберите вагоны', '');
      return;
    } else {
      this.setState({ isBusy: true });
      showSuccessNotify('Документ сформирован');
      this.props.onChange();
    }
  };

  render() {
    const data = this.state.wagonData;
    return (
      <Modal onOk={this.onSave} onCancel={() => this.props.onClose()} open={true} width={900} title={'Выберите' + ' вагоны'} centered>
        <DataGrid
          allowColumnReordering={true}
          dataSource={data}
          remoteOperations={true}
          //loadPanel={{ enabled: false }}
          allowColumnResizing={true}
          showBorders={true}
          height={'calc(100vh - var(--padding))'}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          selection={{ mode: 'multiple' }}
          onSelectionChanged={this.onSelectionChanged}>
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={false} />
          <Paging enabled={false} />
          <Column allowFixing={true} dataField={'wagonNumber'} caption={'Номер вагона'} dataType={'string'} />
          <Column allowFixing={true} dataField={'dislStationName'} caption={'Станция дислокации'} dataType={'string'} />
          <Column allowFixing={true} dataField={'dislStationCode'} caption={'ЕСР станции дислокации'} dataType={'number'} />
          <Column allowFixing={true} dataField={'lastOperation'} caption={'Последняя операция'} dataType={'number'} />
          <Column allowFixing={true} dataField={'lastOperationDate'} caption={'Дата операции'} dataType={'date'} />
        </DataGrid>
      </Modal>
    );
  }
}

export default LasingTransferActModal;
