import { Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react';
import React from 'react';

import { supplierBidRegistryStore } from '@stores/supplierBidRegistryStore';

import { PageType } from '../../supplier-bid-registry-create-page.types';
import { ExtraActionsTabs as ExtraActions } from '../extra-actions-tabs';
import { getTabsItem } from './tab.lib';
import { TabsProps } from './tabs.types';

export const SupplierBidRegistryCreateTabs = observer(({ pageType }: TabsProps) => {
  const [form] = useForm();
  const isEdit = pageType === PageType.EDIT;
  const isLoading = supplierBidRegistryStore.isLoading;

  const onSuccess = () => {
    form.submit();
  };

  const onCancel = () => {
    form.resetFields();
  };

  return (
    <Tabs
      items={getTabsItem(form, isEdit)}
      tabBarExtraContent={<ExtraActions onCancel={onCancel} onSuccess={onSuccess} isLoading={isLoading} />}
      destroyInactiveTabPane
    />
  );
});
