import { action, computed, makeObservable, observable } from 'mobx';
import { DefaultOptionType } from 'rc-select/lib/Select';

import { CountryModel } from '../companyStore/CompanyData';
import { CurrencyModel } from '../optionsStore/optionsStoreData';
import dictService from './dictStore.service';

export type ViewType = 'table' | 'board';

export type DictionaryModel = {
  wagonTypesDictionary: any[];
  shippingTypeDictionary: any[];
  scaleShippingTypeDictionary: any[];
  statusDictionary: any[];
  tariffIcDictionary: any[];
  sendStatusDictionary: any[];
  wagonAffiliationDictionary: any[];
  extraServiceDictionary: ExtraServiceModel[];
};

export interface ExtraServiceModel {
  serviceId: number;
  name: string;
}

export type SourceModel = {
  /**
   * Id
   * @value  The identifier.
   */
  id: string;

  /**
   * Название
   * @value  The name.
   */
  name: string;

  /**Активность источника */
  isActive: boolean;

  /**Поддержка источником контейнеров */
  containerSupport: boolean;

  /**Поддержка источником вагонов */
  wagonSupport: boolean;

  /**Приоритет источника перед другими */
  priority: number;

  /**Поддержка паспортов */
  passportSupport: boolean;

  /**Поддержка ремонтов */
  maintenanceSupport: boolean;

  /**Поддержка литых деталей и/или колесных пар */
  detailSupport: boolean;

  /// Поддержка внешних подходов по подразделениям
  catalogSupport: boolean;
};

export type CompanySourceCountryModel = {
  companyId?: string | null;
  sourceId: string;
  sourceName?: string;
  countryCode: number;
  countryName?: string;
  rateWagonRu: number;
  rateContainerRu: number;
  /**Включение контейнеров */
  containerSupport: boolean;

  /**Включение вагонов */
  wagonSupport: boolean;

  /**Включение паспортов */
  passportSupport: boolean;

  /**Включение ремонтов */
  maintenanceSupport: boolean;

  /**Включение литых деталей и/или колесных пар */
  detailSupport: boolean;

  rateTechRu: number;

  /**Код валюты расчетов с ТК*/
  currencyCode: string;
};

const PREFER_CURRENCY = ['810'];

class DictStore {
  @observable private _dictionaries: DictionaryModel;
  @observable companyInfoblocksDictionary: any[];
  @observable companySourcesDictionary: SourceModel[] = [];
  @observable countriesBySourcesDictionary: CompanySourceCountryModel[];
  @observable countriesDictionary: CountryModel[] = [];
  @observable companySourceLoading: boolean = false;

  @observable currencyList: CurrencyModel[] = [];
  @observable currencyOptions: DefaultOptionType[] = [];

  constructor() {
    makeObservable(this);
  }

  @action getDictionaries() {
    dictService.getDictionaries().then((result: DictionaryModel) => {
      this._dictionaries = result;
    });
  }

  @action loadInfoblocksDict() {
    dictService.companyInfoblocksDict().then((result) => {
      this.companyInfoblocksDictionary = result;
    });
  }

  @action loadCurrencyList() {
    dictService.currencyDict().then((ans) => {
      ans.sort((a, b) => {
        if (PREFER_CURRENCY.includes(a.currencyCode)) return -1;
        if (PREFER_CURRENCY.includes(b.currencyCode)) return 1;
        return 0;
      });
      this.currencyList = ans;
      this.currencyOptions = this.currencyList.map(({ currencyCode, currencyName, isoCode }) => ({
        value: currencyCode,
        label: `${isoCode} - ${currencyName}`,
      }));
    });
  }

  @action
  async loadSourcesDict() {
    this.companySourceLoading = true;
    return await dictService
      .companySourcesDict()
      .then((ans) => {
        this.companySourcesDictionary = ans;
        return ans;
      })
      .finally(() => (this.companySourceLoading = false));
  }

  @action
  async countriesBySources(sourceId: string) {
    this.companySourceLoading = true;
    try {
      this.countriesBySourcesDictionary = await dictService.countriesBySources(sourceId);
    } finally {
      this.companySourceLoading = false;
    }
  }

  /* типы вагонов */
  @computed get wagonTypesDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.wagonTypesDictionary];
    }
  }

  /* типы доставки */
  @computed get shippingTypeDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.shippingTypeDictionary];
    }
  }

  /* типы доставки2 */
  @computed get scaleShippingTypeDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.scaleShippingTypeDictionary];
    }
  }

  /* статусы */
  @computed get statusDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.statusDictionary];
    }
  }

  /* статусы отправок */
  @computed get sendStatusDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.sendStatusDictionary];
    }
  }

  /* ?? */
  @computed get tariffIcDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.tariffIcDictionary];
    }
  }

  @computed get extraServicesDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.extraServiceDictionary];
    }
  }

  /* принадлежность вагона */
  @computed get wagonAffiliationDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.wagonAffiliationDictionary];
    }
  }

  @action
  async countryDict(filter: string) {
    const c = await dictService.countryDict(filter);
    this.countriesDictionary = c.sort((a, b) => (a.fullName < b.fullName ? -1 : 1));
    return this.countriesDictionary;
  }

  @action
  async getBorderStations(filter: string) {
    return await dictService.borderStations(filter);
  }
}

export default new DictStore();
