import { action, computed, makeObservable, observable } from 'mobx';

class ResultViewStore {
  @observable private _totalSummary: any[];
  @observable private _groupSummary: any[];

  constructor() {
    makeObservable(this);
  }

  @action AddTotalSummary(summary: any) {
    let ts = this.totalSummary;
    ts.push(summary);
    this._totalSummary = ts;
  }

  @action RemoveTotalSummary(selector: string) {
    let ts = this.totalSummary;
    this._totalSummary = ts.filter((x) => x.selector !== selector);
  }

  @action RemoveGroupSummary(selector: string) {
    let gs = this.groupSummary;
    this._groupSummary = gs.filter((x) => x.selector !== selector);
  }

  @action AddGroupSummary(summary: any) {
    let gs = this.groupSummary;
    gs.push(summary);
    this._groupSummary = gs;
  }

  @action clearGroupSummary() {
    this._groupSummary = null;
  }

  @action clearTotalSummary() {
    this._totalSummary = null;
  }

  @computed get totalSummary() {
    if (!this._totalSummary) {
      return [];
    } else {
      return [...this._totalSummary];
    }
  }

  @computed get groupSummary() {
    if (!this._groupSummary) {
      return [];
    } else {
      return [...this._groupSummary];
    }
  }

  @action doTotalSummary(type: any, dataField: string) {
    this.AddTotalSummary({ summaryType: type, selector: dataField });
  }

  @action private doGroupSummary(type: any, dataField: string, isHeader: boolean) {
    this.AddGroupSummary({ summaryType: type, selector: dataField, isHeader: isHeader });
  }

  isGroupsAny(dataGrid): boolean {
    const rows = dataGrid.instance.getVisibleRows();
    if (rows.find((x) => x.rowType === 'group')) {
      return true;
    } else {
      this.clearGroupSummary();
      return false;
    }
  }

  handleItemContextMenu = (e, dataGrid) => {
    const row = e.row;
    if (!row) return;
    if (row.rowType === 'header') {
      const columntType = e.column.dataType;
      const alreadyGroupAgg = this.groupSummary.find((g) => g.selector === e.column.dataField);
      const alreadyTotalAgg = this.totalSummary.find((g) => g.selector === e.column.dataField);
      if (alreadyTotalAgg && (alreadyGroupAgg || !this.isGroupsAny(dataGrid))) return;
      e.items.push({
        beginGroup: true,
        text: 'Агрегация',
        icon: 'formula',
        items: [
          {
            text: 'По группам',
            visible: this.isGroupsAny(dataGrid) && !alreadyGroupAgg,
            items: [
              {
                text: 'сумма',
                visible: ['number'].includes(columntType),
                onItemClick: () => this.doGroupSummary('sum', e.column.dataField, !dataGrid.instance.isRowExpanded(e.rowIndex)),
              },
              {
                text: 'минимум',
                onItemClick: () => this.doGroupSummary('min', e.column.dataField, !dataGrid.instance.isRowExpanded(e.rowIndex)),
              },
              {
                text: 'максимум',
                onItemClick: () => this.doGroupSummary('max', e.column.dataField, !dataGrid.instance.isRowExpanded(e.rowIndex)),
              },
              {
                text: 'среднее',
                visible: ['number'].includes(columntType),
                onItemClick: () => this.doGroupSummary('avg', e.column.dataField, !dataGrid.instance.isRowExpanded(e.rowIndex)),
              },
              {
                text: 'количество',
                onItemClick: () => this.doGroupSummary('count', e.column.dataField, !dataGrid.instance.isRowExpanded(e.rowIndex)),
              },
            ],
          },
          {
            text: 'Итого',
            visible: !alreadyTotalAgg,
            items: [
              {
                text: 'сумма',
                visible: ['number'].includes(columntType),
                onItemClick: () => this.doTotalSummary('sum', e.column.dataField),
              },
              {
                text: 'минимум',
                onItemClick: () => this.doTotalSummary('min', e.column.dataField),
              },
              {
                text: 'максимум',
                onItemClick: () => this.doTotalSummary('max', e.column.dataField),
              },
              {
                text: 'среднее',
                visible: ['number'].includes(columntType),
                onItemClick: () => this.doTotalSummary('avg', e.column.dataField),
              },
              {
                text: 'количество',
                onItemClick: () => this.doTotalSummary('count', e.column.dataField),
              },
            ],
          },
        ],
      });
    }

    if (row.rowType === 'totalFooter') {
      const columns = row.cells.filter((x) => x.summaryItems.length > 0);
      if (columns.length > 0) {
        e.items = [];
        const subitems = [
          ...columns.map((x) => {
            return {
              text: x.column.caption,
              onItemClick: () => this.RemoveTotalSummary(x.column.dataField),
            };
          }),
        ];
        e.items.push({
          text: 'Убрать агрегацию',
          icon: 'clearformat',
          items: subitems,
        });
      }
    }

    if (row.rowType === 'group') {
      const columns = row.cells.filter((x) => x.summaryItems && x.summaryItems.length > 0);
      if (columns.length > 0) {
        const subitems = [
          ...columns.map((x) => {
            return {
              text: x.column.caption,
              onItemClick: () => this.RemoveGroupSummary(x.column.dataField),
            };
          }),
        ];
        e.items.push({
          beginGroup: true,
          text: 'Убрать агрегацию',
          icon: 'clearformat',
          items: subitems,
        });
      }
    }
  };

  groupSummaryList = (dataGrid) => {
    let result = [];
    this.groupSummary.forEach((x, index) => {
      if (x.summaryType !== 'count') {
        result.push({
          column: x.selector,
          summaryType: x.summaryType,
          alignByColumn: true,
          showInColumn: !dataGrid.instance.isRowExpanded(x),
          showInGroupFooter: dataGrid.instance.isRowExpanded(x),
        });
      } else {
        result.push({
          column: x.selector,
          summaryType: x.summaryType,
          alignByColumn: true,
          valueFormat: { type: 'fixedPoint' },
          showInColumn: !dataGrid.instance.isRowExpanded(x),
          showInGroupFooter: dataGrid.instance.isRowExpanded(x),
        });
      }
    });
    return result;
  };
  totalSummaryList = () => {
    let result = [];
    this.totalSummary.forEach((x) => {
      if (x.summaryType !== 'count') {
        result.push({ column: x.selector, summaryType: x.summaryType });
      } else {
        result.push({ column: x.selector, summaryType: x.summaryType, valueFormat: { type: 'fixedPoint' } });
      }
    });
    return result;
  };
}

export default new ResultViewStore();
