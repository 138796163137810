import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';

import { RequestModel } from '@stores/requestsStore/RequestsData';
import requestsStore from '@stores/requestsStore/requestsStore';

import RequestForm from './RequestForm';
import RequestsDataGrid from './RequestsDataGrid';

interface ComponentState {
  loaded: boolean;
}

class RequestsPage extends Component<RouteComponentProps, ComponentState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loaded: false,
    };
    document.title = 'Справки';
  }

  componentDidMount(): void {}

  render() {
    return (
      <>
        <AdaptivePageHeader title={'Справки'} extra={<RequestForm onDataReady={this.handleCreate} />} />
        <AdaptiveCard>
          <RequestsDataGrid />
        </AdaptiveCard>
      </>
    );
  }

  handleCreate = (e: RequestModel) => {
    requestsStore.dataSource.reload();
  };
}

export default observer(RequestsPage);
