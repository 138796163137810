import { observer } from 'mobx-react';
import React, { Component } from 'react';

import CalendarCustom from '@components/fw/Calendar/CalendarCustom';

import loadRegistryStore from '@stores/loadRegistryStore/loadRegistryStore';

import './LoadingRegister.scss';

interface State {}

interface Props {
  onChange(): void;
}

class LoadingRegisterCalendarWrapper extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CalendarCustom
        onChange={(e, picker) => {
          loadRegistryStore.setDates(e, picker);
          this.props.onChange();
        }}
        values={loadRegistryStore.dates}
      />
    );
  }
}

export default observer(LoadingRegisterCalendarWrapper);
