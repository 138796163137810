import { Col, Divider, Row, Steps, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import { TariffCountryDetailModel } from '@stores/sendsStore/sendData';

const { Step } = Steps;

interface FwProps {
  value: TariffCountryDetailModel[];
}

interface ComponentState {
  visible: boolean;
}

class TariffDetailViewer extends Component<FwProps, ComponentState> {
  constructor(props: FwProps) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  state = { visible: false };

  currencyNameCropper = (name: string) => {
    if (!name) return '-';
    let result = '';
    const split = name.split(' ');
    split.forEach((s) => {
      result += s.substring(0, 1);
    });
    return result.toUpperCase();
  };

  render() {
    return (
      <>
        {this.props.value.map((value, index) => {
          return (
            <AdaptiveCard size="default" bordered={true} hoverable key={index}>
              <h1>{value.countryName}</h1>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Steps progressDot current={2}>
                    <Step title={value.importStationName} description={index == 0 ? 'Отправление' : 'Импорт'} />
                    <Step description={value.tariffDistance + ' км.'} />
                    <Step title={value.exportStationName} description={index + 1 == this.props.value.length ? 'Назначение' : 'Экспорт'} />
                  </Steps>
                </Col>
              </Row>
              <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}>
                Детали тарифа
              </Divider>
              <Row gutter={[0, 8]} style={{ textAlign: 'center' }}>
                <Col span={1}> </Col>
                <Col span={3}>Тариф</Col>
                <Col span={4}>Плата-тариф</Col>
                <Col span={4}>Сборы за тонну</Col>
                <Col span={4}>За подвижной состав</Col>
                <Col span={4}>Плата-сборы</Col>
                <Col span={4}>Провозная плата</Col>
              </Row>
              <Row gutter={[0, 8]} style={{ textAlign: 'center' }}>
                <Col span={1}>₽</Col>
                <Col span={3} style={{ textAlign: 'right' }}>
                  {value.tariffCostRu?.toLocaleString('ru')}
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  {value.tariffChargeRu?.toLocaleString('ru')}
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  {value.chargePerTonRu?.toLocaleString('ru')}
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  {value.wagonTypeChargeRu?.toLocaleString('ru')}
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  {value.feeChargeRu?.toLocaleString('ru')}
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  {value.freightChargeRu?.toLocaleString('ru')}
                </Col>
              </Row>
              {value.tariffCurrencyCode && value.tariffCurrencyCode !== '810' && (
                <Row gutter={[0, 8]} style={{ textAlign: 'center' }}>
                  <Col span={1}>
                    <Tooltip title={value.tariffCurrencyName}>
                      <span>{this.currencyNameCropper(value.tariffCurrencyName)}</span>
                    </Tooltip>
                  </Col>
                  <Col span={3}>{value.tariffCostCurrency?.toLocaleString('ru')}</Col>
                  <Col span={4}>{value.tariffChargeCurrency?.toLocaleString('ru')}</Col>
                  <Col span={4}>{value.chargePerTonCurrency?.toLocaleString('ru')}</Col>
                  <Col span={4}>{value.wagonTypeChargeCurrency?.toLocaleString('ru')}</Col>
                  <Col span={4}>{value.feeChargeCurrency?.toLocaleString('ru')}</Col>
                  <Col span={4}>{value.freightChargeCurrency?.toLocaleString('ru')}</Col>
                </Row>
              )}
            </AdaptiveCard>
          );
        })}
      </>
    );
  }
}
export default observer(TariffDetailViewer);
