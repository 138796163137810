import { Select, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import { InputPosition } from '@stores/designStore/designData';
import exchangeRateStore from '@stores/exchangeRateStore/exchangeRateStore';
import optionsStore, { currencyNameCropper } from '@stores/optionsStore/optionsStore';
import { CURRENCY_FIELDS, Currency } from '@stores/sendsStore/sendData';

interface CurrencyDictionaryProps {
  value?: Currency;
  onChange?(value: Currency): void;
  position?: InputPosition;
  countryCurrency?: { name: string; code: string };
  tariffCurrency?: { name: string; code: string };
  type?: 'float' | 'small';
}

interface CurrencyDictionaryState {}

class CurrencyDictionary extends Component<CurrencyDictionaryProps, CurrencyDictionaryState> {
  constructor(props: CurrencyDictionaryProps) {
    super(props);
    this.state = {};
  }

  handleCurrencyChange = (event) => {
    if (this.props.onChange) {
      const find = this.currencyDict.find((c) => c.id == event);
      if (find) {
        this.props.onChange(find);
      }
    }
  };

  get currencyDict() {
    const dict: Currency[] = JSON.parse(JSON.stringify(CURRENCY_FIELDS));
    if (this.props.tariffCurrency) {
      dict[0].name += ` (${currencyNameCropper(this.props.tariffCurrency.name)})`;
      dict[0].filedPostfixCode = this.props.tariffCurrency.code;
      dict[0].symbol = this.props.tariffCurrency.name;
    }
    if (this.props.countryCurrency) {
      dict[5].name += ` (${currencyNameCropper(this.props.countryCurrency.name)})`;
      dict[5].filedPostfixCode = this.props.countryCurrency.code;
      dict[5].symbol = this.props.tariffCurrency.name;
    }
    return dict;
  }

  render() {
    const selectedCurrency = this.props.value;
    const exchanges = exchangeRateStore.exchanges;
    const isSmall = this.props.type === 'small';
    const SelectElement = isSmall ? Select : FloatSelect;

    return (
      <div style={{ width: isSmall ? 'unset' : '100%' }}>
        <SelectElement
          position={this.props.position}
          value={selectedCurrency ? selectedCurrency.id : optionsStore.selectedCurrency.id}
          size={isSmall ? 'small' : null}
          onChange={(item) => this.handleCurrencyChange(item)}
          placeholder="Вид учета">
          {this.currencyDict.map((item, index) => {
            let ex = '';
            if (item.filedPostfixCode !== selectedCurrency.filedPostfixCode) {
              const find = exchanges.find(
                (e) => e.sourceCurrencyCode == item.filedPostfixCode && e.targetCurrencyCode == selectedCurrency.filedPostfixCode,
              );
              if (find) {
                ex = find.amount + currencyNameCropper(selectedCurrency.symbol);
              }
            }
            return (
              <Select.Option value={item.id} key={item.id}>
                <Tooltip title={item.symbol}>
                  {isSmall ? (
                    <div>
                      <div style={{ float: 'left' }}>{item.name}</div>
                      <br />
                      <div style={{ fontSize: 9, color: 'darkgrey' }}>{ex}</div>
                    </div>
                  ) : (
                    <div>
                      <span style={{ float: 'left' }}>{item.name}</span>
                      <span style={{ float: 'right' }}>{ex}</span>
                    </div>
                  )}
                </Tooltip>
              </Select.Option>
            );
          })}
        </SelectElement>
      </div>
    );
  }
}

export default observer(CurrencyDictionary);
