import { notification } from 'antd';
import { action, computed, makeObservable, observable } from 'mobx';

import { existDocuments } from '@stores/bidRegistryDocumentStore/bidRegistryDocumentStore.types';

import service from './bidRegistryDocumentStore.service';

class BidRegistryDocumentStoreClass {
  @observable private _isExistDocuments: existDocuments = { commercialOfferExists: false, protocolExists: false };
  @observable private _isLoading: boolean = false;
  @observable private _offerBlob: Blob;
  @observable private _protocolBlob: Blob;

  constructor() {
    makeObservable(this);
  }

  @action
  async getExistsDocuments(bidRegistryId: string) {
    this._isLoading = true;
    try {
      this._isExistDocuments = await service.getExistsDocuments(bidRegistryId);
    } catch (e) {
      notification.error({ message: 'Ошибка при получение документов' });
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async createProtocol(bidRegistryId: string) {
    this._isLoading = true;
    try {
      this._isExistDocuments = await service.createProtocol(bidRegistryId);
      await this.getExistsDocuments(bidRegistryId);
    } catch (e) {
      notification.error({ message: 'Ошибка при создании протокола' });
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async createOffer(bidRegistryId: string) {
    this._isLoading = true;
    try {
      this._isExistDocuments = await service.createOffer(bidRegistryId);
      await this.getExistsDocuments(bidRegistryId);
    } catch (e) {
      notification.error({ message: 'Ошибка при создании коммерческого предложения' });
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async getProtocol(bidRegistryId: string) {
    this._isLoading = true;
    try {
      if (!this._protocolBlob) {
        this._protocolBlob = await service.getProtocol(bidRegistryId);
      }
      return this._protocolBlob;
    } catch (e) {
      notification.error({ message: 'Ошибка при скачивание протокола' });
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async getOffer(bidRegistryId: string) {
    this._isLoading = true;
    try {
      if (!this._offerBlob) {
        this._offerBlob = await service.getOffer(bidRegistryId);
      }
      return this._offerBlob;
    } catch (e) {
      notification.error({ message: 'Ошибка при скачивание коммерческого предложения' });
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async removeProtocol(bidRegistryId: string) {
    this._isLoading = true;
    try {
      this._isExistDocuments = await service.removeProtocol(bidRegistryId);
      await this.getExistsDocuments(bidRegistryId);
    } catch (e) {
      notification.error({ message: 'Ошибка при удаление коммерческого предложения' });
    } finally {
      this._protocolBlob = null;
      this._isLoading = false;
    }
  }

  @action
  async removeOffer(bidRegistryId: string) {
    this._isLoading = true;
    try {
      this._isExistDocuments = await service.removeOffer(bidRegistryId);
      await this.getExistsDocuments(bidRegistryId);
    } catch (e) {
      notification.error({ message: 'Ошибка при удаление протокола' });
    } finally {
      this._offerBlob = null;
      this._isLoading = false;
    }
  }

  @action
  clearData() {
    this._protocolBlob = undefined;
    this._offerBlob = undefined;
    this._isExistDocuments = { commercialOfferExists: false, protocolExists: false };
  }

  @computed
  get isLoading() {
    return this._isLoading;
  }

  @computed
  get isExistDocuments() {
    return this._isExistDocuments;
  }

  @computed
  get offerBlob() {
    return this._offerBlob;
  }

  @computed
  get protocolBlob() {
    return this._protocolBlob;
  }
}

export const BidRegistryDocumentStore = new BidRegistryDocumentStoreClass();
