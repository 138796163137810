import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, SelectProps } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import organizationsStore from '@stores/organizationsStore/organizationsStore';

import { filterOptionFn } from '@utils/sort';

interface Props extends SelectProps {
  primitiveResult?: boolean;
  type?: 'contractor' | 'branch' | 'supplier';
  float?: boolean;
  onEdit?(orgId: string): void;
}

interface State {}

@observer
class OrganizationSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  onChange = (value, options) => {
    if (this.props.primitiveResult) {
      if (this.props.onChange) {
        this.props.onChange(value, options);
      }
    } else {
      const isMultiple = ['multiple', 'tag'].includes(this.props.mode);
      const find = isMultiple
        ? organizationsStore.contragent.filter((o) => value.includes(o.organizationId))
        : organizationsStore.contragent.find((o) => o.organizationId === value);
      if (this.props.onChange && find) {
        this.props.onChange(find, options);
      }
    }
  };

  getDatasource = () => {
    if (!this.props.type) return organizationsStore.contragent;

    switch (this.props.type) {
      case 'supplier':
        return organizationsStore.getSupplierArray();
      case 'branch':
        return organizationsStore.getBranchArray();
      case 'contractor':
        return organizationsStore.getContragentArray();
      default:
        return organizationsStore.contragent;
    }
  };

  render() {
    const SelectComponent = this.props.float ? FloatSelect : Select;
    const isMultiple = ['multiple', 'tag'].includes(this.props.mode);
    const value = this.props.value
      ? this.props.primitiveResult
        ? this.props.value
        : isMultiple
          ? this.props.value.map((v) => v.organizationId)
          : this.props.value.organizationId
      : null;
    const dataSource = this.getDatasource();

    return (
      <SelectComponent
        {...this.props}
        value={value}
        disabled={this.props.disabled}
        allowClear
        onChange={this.onChange}
        showSearch
        optionFilterProp="children"
        filterOption={filterOptionFn}
        suffixIcon={<SearchOutlined />}>
        {dataSource.map((d) => {
          const canEdit = !!value && (value === d.organizationId || value.includes(d.organizationId)) && this.props.onEdit;
          return (
            <Select.Option value={d.organizationId} key={d.organizationId}>
              {d.name}
              {canEdit ? (
                <EditOutlined
                  style={{ margin: 3 }}
                  title={'Изменить выбранную'}
                  onClick={(e) => {
                    this.props.onEdit(d.organizationId);
                  }}
                />
              ) : null}
            </Select.Option>
          );
        })}
      </SelectComponent>
    );
  }
}

export default OrganizationSelector;
