import { ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';
import messengerStore from '@stores/messengerStore/messengerStore';

import FileAttacher from './FileAttacher';

const { TextArea } = Input;

@observer
class MessageInput extends Component {
  render() {
    const assistant = !!messengerStore.selectedDialog && messengerStore.selectedDialog.dialogType == 'Assist';
    const message = messengerStore.message;
    return (
      <div style={{ display: 'flex' }}>
        {designStore.isMobile && <Button icon={<ArrowLeftOutlined />} type={'text'} onClick={() => messengerStore.selectDialog(null)} />}
        <FileAttacher />
        <Input
          placeholder="Сообщение"
          size={'large'}
          // style={{ width: 400 }}
          value={message}
          onKeyDown={this.handleKeyDown}
          onChange={(e) => (messengerStore.message = e.target.value)}
        />
        {/*<TextArea*/}
        {/*  placeholder="Сообщение"*/}
        {/*  value={message}*/}
        {/*  onChange={(e) => (messengerStore.message = e.target.value)}*/}
        {/*  autoSize*/}
        {/*/>*/}

        <Button
          type="link"
          size={'large'}
          icon={<SendOutlined />}
          title={'Отправить'}
          onClick={() => messengerStore.trySend()}
          disabled={(!message || message.trim() == '') && messengerStore.attachments.length === 0}
        />
      </div>
    );
  }

  handleAttach = () => {};

  handleKeyDown = (e) => {
    if (!messengerStore.message || messengerStore.message.trim() == '') return;
    if (e.key === 'Enter') {
      messengerStore.trySend();
    }
  };
}

export default MessageInput;
