import { action, makeObservable, observable } from 'mobx';

import {
  CompanyServiceModel,
  CompanyServiceSetModel,
  ServiceStrategyModel,
  SupplierModel,
  SupplierRequisitesModel,
} from './adminBillingData';
import service from './adminBillingStore.service';

class AdminBillingStore {
  @observable strategies: ServiceStrategyModel[] = [];
  @observable services: CompanyServiceModel[] = [];
  @observable isBusy: boolean = false;

  @observable isBusySuppliers: boolean = false;
  @observable suppliers: SupplierModel[] = [];

  @observable isBusySet: boolean = false;
  @observable references: CompanyServiceSetModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action async loadStrategies() {
    this.strategies = await service.getStrategies();
  }

  @action async loadServices() {
    this.services = await service.getServices();
  }

  @action async loadServicesAndStrategies() {
    this.isBusy = true;
    try {
      await Promise.all([this.loadServices(), this.loadStrategies()]);
    } finally {
      this.isBusy = false;
    }
  }

  @action async editService(data: CompanyServiceModel) {
    if (data.id) await service.updateService(data);
    else await service.addService(data);
    await this.loadServices();
  }

  @action async deleteService(data: CompanyServiceModel) {
    if (!data.id) return;
    this.isBusy = true;
    try {
      await service.removeService(data.id);
      await this.loadServices();
    } finally {
      this.isBusy = false;
    }
  }

  @action async loadSuppliers() {
    this.isBusySuppliers = true;
    try {
      this.suppliers = await service.getSuppliers();
    } finally {
      this.isBusySuppliers = false;
    }
  }

  @action async editSupplier(data: SupplierRequisitesModel) {
    if (data.supplierId) await service.updateSupplier(data);
    else await service.addSupplier(data);
    await this.loadSuppliers();
  }

  @action async deleteSupplier(data: SupplierModel) {
    if (!data.supplierId) return;
    this.isBusySuppliers = true;
    try {
      await service.removeSupplier(data.supplierId);
      await this.loadSuppliers();
    } finally {
      this.isBusySuppliers = false;
    }
  }

  @action async loadServiceSets(companyId: string) {
    this.isBusySet = true;
    try {
      this.references = await service.getServiceSets(companyId);
    } finally {
      this.isBusySet = false;
    }
  }

  @action async editServiceSet(data: CompanyServiceSetModel) {
    if (!data.companyId) return;
    if (data.id) await service.updateServiceSet(data);
    else await service.addServiceSet(data);
    await this.loadServiceSets(data.companyId);
  }

  @action async deleteServiceSet(data: CompanyServiceSetModel) {
    if (!data.id) return;
    this.isBusySet = true;
    try {
      await service.removeServiceSet(data.id);
      await this.loadServiceSets(data.companyId);
    } finally {
      this.isBusySet = false;
    }
  }
}

export default new AdminBillingStore();
