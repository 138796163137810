import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, HeaderFilter, Paging, Summary } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';

import { EtranInvoiceSpcModel } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';
import { GLOBAL_DATETIME_FORMAT } from '@stores/optionsStore/optionsStoreData';

import EtranInvoceSpecialMarksInfoModal from './EtranInvoceSpecialMarksInfoModal';

interface Props {}

interface State {
  selected: EtranInvoiceSpcModel;
}

@observer
class EtranSpecialMarksTable extends Component<Props, State> {
  dataGrid: DataGrid;
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  onAdd = (newData: EtranInvoiceSpcModel, oldData: EtranInvoiceSpcModel) => {
    etranStore.addSpecialMarks(newData, oldData);
    this.dataGrid.instance.refresh();
  };

  onRemove = () => {
    etranStore.removeSpecialMarks(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onCopy = () => {
    etranStore.copySpecialMarks(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onUp = () => {
    etranStore.upSpecialMarks(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onDown = () => {
    etranStore.downSpecialMarks(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  render() {
    const data = etranStore.invoceFormData.specialMarks;
    const selected = this.state.selected;
    return (
      <div className={'etranTable'}>
        <EtranCaption title={'Специальные отметки'} />
        <div className={'etranButtonsGroup'}>
          <EtranInvoceSpecialMarksInfoModal onChange={(e) => this.onAdd(e, null)} />
          {selected ? (
            <EtranInvoceSpecialMarksInfoModal data={selected} onChange={(e) => this.onAdd(e, selected)} />
          ) : (
            <Button disabled={true}>Изменить</Button>
          )}
          <Button disabled={!selected} onClick={this.onRemove}>
            Удалить
          </Button>
          <Button disabled={!selected} onClick={this.onCopy}>
            Дублировать
          </Button>
          <Button disabled={!selected} onClick={this.onUp} icon={<ArrowUpOutlined />} />
          <Button disabled={!selected} onClick={this.onDown} icon={<ArrowDownOutlined />} />
        </div>
        <DataGrid
          onSelectionChanged={(e) => {
            const key = e.selectedRowsData[0];
            this.setState({ selected: key });
          }}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={false}
          dataSource={data}
          style={{ minHeight: '110px' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          selection={{ mode: 'single' }}>
          <Paging enabled={false} />
          <GroupPanel visible={false} />
          <HeaderFilter visible={false} allowSearch={false} />
          <FilterRow visible={false} />
          {INDEX_COLUMN}
          <Column allowFixing={true} dataField="transpClauseTypeName" caption={'Тип'} />
          <Column allowFixing={true} dataField="spcTranspClauseDesc" caption={'Отметка'} />
          <Column
            allowFixing={true}
            dataField="spcCustomText"
            caption={'Замечание'}
            customizeText={(text) => {
              const date = moment(text.value, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
              if (text.value && date.isValid()) {
                return date.format(GLOBAL_DATETIME_FORMAT);
              } else return text.value;
            }}
          />

          <Summary></Summary>
        </DataGrid>
      </div>
    );
  }
}

export default EtranSpecialMarksTable;
