import { Col, Form, FormInstance, Input, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { Container } from '@stores/trackingStore/container/ContainerTypes';
import Wagon from '@stores/trackingStore/wagon/WagonTypes';

interface Props {
  primaryKey: string;
  store: any;
  onChange(numbers: any[]): void;
}

interface State {
  wagons: Wagon[];
  containers: Container[];
  str: string;
}

@observer
class NumberFilterForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      wagons: [],
      containers: [],
      str: null,
    };
  }

  public form: FormInstance;

  componentDidMount() {
    if (this.isWagons && this.props.store.filteredWagons && this.props.store.filteredWagons.length) {
      this.setState({ str: this.props.store.filteredWagons.map((w) => w.number).join(' '), wagons: this.props.store.filteredWagons }, () =>
        this.form.resetFields(),
      );
    }

    if (this.isContainers && this.props.store.filteredContainers && this.props.store.filteredContainers.length) {
      this.setState(
        { str: this.props.store.filteredContainers.map((w) => w.number).join(' '), containers: this.props.store.filteredContainers },
        () => this.form.resetFields(),
      );
    }
  }

  get isWagons() {
    return this.props.primaryKey == 'wagonnumber';
  }

  get isContainers() {
    return this.props.primaryKey == 'containernumber';
  }

  handleTexAreaChange = (event) => {
    event.persist();
    this.setState({ str: event.target.value });
    if (this.isWagons) {
      const wagons: Wagon[] = [];
      const regex = /[0-9]{8}/g;
      const lines = event.target.value.match(regex);
      lines?.forEach((l) => {
        if (l != '') wagons.push(new Wagon(l));
      });
      this.setState({ wagons });
    }

    if (this.isContainers) {
      const containers: Container[] = [];
      const lines = event.target.value.replace(/\n/g, ' ').split(' ');
      for (let i = 0; i < lines.length; i++) {
        if (lines[i] !== '') containers.push(new Container(lines[i]));
      }
      this.setState({ containers });
    }
  };

  submit = () => {
    if (this.isWagons) {
      this.props.store.filteredWagons = this.state.wagons;
      this.props.onChange(this.state.wagons);
    }
    if (this.isContainers) {
      this.props.store.filteredContainers = this.state.containers ?? [];
      this.props.onChange(this.state.containers);
    }
    this.props.store.numbersFilterModalVisible = false;
  };

  render() {
    const numbers = this.isWagons ? this.state.wagons : this.state.containers;

    return (
      <Form ref={(ref) => (this.form = ref)} name={'numbers_form'} layout={'vertical'} initialValues={{ str: this.state.str }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} md={16}>
            <Form.Item label="Введите список номеров:" name={'str'}>
              <Input.TextArea rows={6} onChange={this.handleTexAreaChange} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {
                // @ts-ignore
                numbers.map((value, index) => {
                  return (
                    <Col xs={12} key={index} style={{ color: !value.isvalid ? 'red' : null }}>
                      {value.number}
                    </Col>
                  );
                })
              }
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}
export default NumberFilterForm;
