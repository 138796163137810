import { Spin } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import messengerStoreService from '@stores/messengerStore/messengerStore.service';
import { ChatMessage } from '@stores/messengerStore/messengerStoreData';

import NotificationViewerActions from './NotificationViewerActions';

interface State {
  isBusy: boolean;
  data: ChatMessage[];
}

interface Props {}

class NotificationsViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: true,
      data: [],
    };
  }

  componentDidMount() {
    this.loadData().then();
  }

  async loadData() {
    try {
      const dialogs = await messengerStoreService.getDialogs();
      const assist = dialogs.find((d) => d.dialogType === 'Assist');
      if (assist) {
        const data = await messengerStoreService.getMessages(assist.dialogId);
        this.setState({ data });
      }
    } finally {
      this.setState({ isBusy: false });
    }
  }

  render() {
    const { isBusy, data } = this.state;

    return (
      <Spin spinning={isBusy}>
        <DataGrid
          height={'calc(100vh - var(--padding))'}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          showBorders={true}
          //ref={(ref) => (this.dataGrid = ref)}
          dataSource={data}
          //onRowDblClick={this.handleRowDoubleClick}
          loadPanel={{ enabled: false }}>
          <GroupPanel visible={true} />
          <HeaderFilter visible={true} />
          <FilterRow visible={true} />
          <Paging enabled={true} defaultPageSize={25} />
          <Pager
            allowedPageSizes={[25, 50, 100]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            infoText={'Всего: {2}'}
          />

          <Column allowFixing={true} dataField="createDate" width={110} caption={'Дата'} dataType={'datetime'} />
          <Column allowFixing={true} dataField="content" caption={'Уведомление'} />
          <Column
            allowFixing={true}
            width={70}
            alignment={'center'}
            dataField="id"
            caption={'Действия'}
            allowGrouping={false}
            allowHeaderFiltering={false}
            allowSearch={false}
            allowSorting={false}
            allowFiltering={false}
            allowExporting={false}
            cellRender={(e) => {
              return <NotificationViewerActions messageId={e.data.messageId} onChange={() => this.loadData()} />;
            }}
          />
        </DataGrid>
      </Spin>
    );
  }
}

export default observer(NotificationsViewer);
