import { Checkbox, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';

import optionsStore from '@stores/optionsStore';

interface Props {
  form: FormInstance;
}

const WeightStepper = observer(({ form }: Props) => {
  const [visible, setVisible] = useState(false);
  const { weightStepperStart, weightStepperEnd, weightStepperStep } = optionsStore.request;

  function onVisibleChange(value: boolean) {
    setVisible(value);
    if (!value) {
      form.setFieldsValue({ weightStepperStart: null, weightStepperEnd: null, weightStepperStep: null });
    } else {
      form.setFieldsValue({
        weightStepperStart: null,
        weightStepperEnd: form.getFieldValue('freightCapacity'),
        weightStepperStep: null,
      });
    }
  }

  useEffect(() => {
    setVisible(!!weightStepperStart || !!weightStepperEnd || !!weightStepperStep);
  }, [weightStepperStart, weightStepperEnd, weightStepperStep]);

  return (
    <div>
      <Checkbox style={{ marginTop: 14 }} checked={visible} onChange={({ target }) => onVisibleChange(target.checked)}>
        Рассчитать с шагом загрузки
      </Checkbox>
      {visible && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
            <Form.Item name={'weightStepperStart'} rules={[{ required: true, message: 'Укажите загрузку минимальную' }]}>
              <FloatInputNumber placeholder={'Загрузка минимальная'} max={110} min={0.01} step={0.01} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
            <Form.Item
              name={'weightStepperStep'}
              rules={[
                { required: true, message: 'Укажите шаг' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    const start: number = getFieldValue('weightStepperStart');
                    const end: number = getFieldValue('weightStepperEnd');
                    const step: number = value;
                    if (start && step && end) {
                      const dif = end - start;
                      if (dif < 0) {
                        return Promise.reject('Невозможно рассчитать. Проверьте начальный и конечный вес');
                      }
                      if (dif / step > 19) {
                        return Promise.reject('Не более 20 шагов');
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}>
              <FloatInputNumber placeholder={'Шаг веса груза(т)'} max={110} min={0.01} step={0.01} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
            <Form.Item name={'weightStepperEnd'} rules={[{ required: true, message: 'Укажите загрузку максимальную' }]}>
              <FloatInputNumber placeholder={'Загрузка максимальная'} max={110} min={0.01} step={0.01} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
});

export default WeightStepper;
