import { observer } from 'mobx-react';
import React, { Component } from 'react';

import TrackingActiveTable from '@components/fw/TrackingActiveTable';

import trackingContainerStore from '@stores/trackingStore/container/trackingContainerStore';

interface ComponentProps {}

interface ComponentState {}

@observer
class TrackingContainersActive extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    trackingContainerStore.loadActive();
  }
  handleDataChanged() {
    trackingContainerStore.loadActive();
  }

  handleSelectionChanged = (data: any) => {
    trackingContainerStore.selected = data;
  };

  render() {
    const isBusy = trackingContainerStore.isBusyActive;
    const data = trackingContainerStore.active;
    return (
      <>
        <TrackingActiveTable value={data} isBusy={isBusy} type={'container'} onSelectionChanged={this.handleSelectionChanged} />
      </>
    );
  }
}

export default TrackingContainersActive;
