import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Col, List, Popconfirm, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import TagDxCell from '@components/fw/DxCellTemplates/TagDxCell';

import { ClaimModel, ClaimTableModel } from '@stores/claimsStore/ClaimData';
import { SendModel } from '@stores/sendsStore/sendData';
import userStore from '@stores/userStore/userStore';

interface Props {
  selected: ClaimModel;
  handleSelectionChanged(ClaimModel): void;
  list: ClaimTableModel[];
}

interface State {
  claimToDel: ClaimTableModel;
}

@observer
class ClaimsList extends Component<RouteComponentProps & Props, State> {
  constructor(props: RouteComponentProps<{ history }> & Props) {
    super(props);

    this.state = {
      claimToDel: null,
    };
  }
  render() {
    const list = this.props.list;
    const canEdit = userStore.canAccess('claims/edit');
    const canDelete = userStore.canAccess('claims/delete');
    const selectedClaim = this.props.selected;

    return (
      <List
        size={'small'}
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            onClick={() => this.props.handleSelectionChanged({ claimId: item.claimid })}
            style={
              selectedClaim != null && selectedClaim.claimId == item.claimid
                ? { border: '1px solid #515769' }
                : { borderBottom: '1px solid #f0f0f0' }
            }>
            <List.Item.Meta
              title={
                <>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={18}>
                      <span>{item.claimname}</span>
                    </Col>
                    <Col span={6}>
                      {canEdit ? (
                        <Tooltip title={'Редактировать'}>
                          <EditOutlined onClick={() => this.handleRowDblClick({ claimId: item.claimid, claimName: item.claimname })} />
                        </Tooltip>
                      ) : null}
                      {canDelete ? (
                        <Tooltip title={'Удалить'}>
                          <Popconfirm
                            title="Удалить заявку？"
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={() => this.handleDelete({ claimId: item.claimid, claimName: item.claimname })}>
                            <DeleteOutlined />
                          </Popconfirm>
                        </Tooltip>
                      ) : null}
                    </Col>
                  </Row>
                </>
              }
              description={
                <>
                  <TagDxCell value={JSON.parse(item.tags)} />
                  <div>
                    {item.plancost ? (
                      <div>
                        Ставка план: <b>{item.plancost}₽</b>
                      </div>
                    ) : null}
                    {item.costfact ? (
                      <div>
                        Ставка факт: <b>{item.costfact}₽</b>
                      </div>
                    ) : null}
                    {item.markedwagoncount ? (
                      <div>
                        Размечено: <b>{item.markedwagoncount}</b>
                      </div>
                    ) : null}
                    {item.sentwagoncountfact ? (
                      <div>
                        Отгружено: <b>{item.sentwagoncountfact}</b>
                      </div>
                    ) : null}
                    {item.remainingquantity ? (
                      <div>
                        Остаток: <b>{item.remainingquantity}</b>
                      </div>
                    ) : null}
                    {item.provisionpersentage ? (
                      <div>
                        Обеспечение: <b>{item.provisionpersentage}%</b>
                      </div>
                    ) : null}
                    {item.realizationpersentage ? (
                      <div>
                        Выполнение: <b>{item.realizationpersentage}%</b>
                      </div>
                    ) : null}
                  </div>
                </>
              }
            />
          </List.Item>
        )}
      />
    );
  }

  handleRowDblClick = (item: any) => {
    this.props.history.push(`/claims/edit-claim/${item.claimId}`);
  };

  handleMasterRowDblClick = (item: SendModel) => {
    this.props.history.push(`/sends/edit-send/${item.sendId}`);
  };

  handleDelete = (item: any) => {
    this.setState({ claimToDel: item });
  };
}

export default ClaimsList;
