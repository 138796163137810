import { makeObservable, observable } from 'mobx';

import { DocTypeModel, DocTypeTemplateModel } from './ContractTemplatesData';
import templatesService from './contractTemplates.service';
import supplierService from './supplierTemplates.service';

class ContractTemplateStore {
  @observable supplierContracts: DocTypeModel[] = [];
  @observable supplierExts: DocTypeModel[] = [];
  @observable templates: DocTypeTemplateModel[] = [];

  @observable isBusyTemplates: boolean = false;

  constructor() {
    makeObservable(this);
  }

  async loadTemplates(context?: string) {
    try {
      this.isBusyTemplates = true;
      this.templates = await templatesService.getTemplates(context);
    } catch {
    } finally {
      this.isBusyTemplates = false;
    }
  }

  async createOrUpdateTemplate(template?: DocTypeTemplateModel) {
    try {
      this.isBusyTemplates = true;
      await templatesService.createOrUpdateTemplate(template);
    } catch {
    } finally {
      this.isBusyTemplates = false;
    }
  }

  async deleteTemplate(templateId: string) {
    try {
      this.isBusyTemplates = true;
      await templatesService.deleteTemplate(templateId);
    } catch {
    } finally {
      this.isBusyTemplates = false;
    }
  }

  loadSupplierContracts(supplierId: string) {
    return supplierService.getContractList(supplierId, 'contract').then((ans) => {
      this.supplierContracts = ans;
      return ans;
    });
  }
  loadSupplierExt(supplierId: string) {
    return supplierService.getContractList(supplierId, 'ext').then((ans) => {
      this.supplierExts = ans;
      return ans;
    });
  }
  clearSupplierTemplates() {
    this.supplierExts = [];
    this.supplierContracts = [];
  }
}

export default new ContractTemplateStore();
