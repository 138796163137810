import { Button, Form, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';

import { RequestModel, RequestTypeInfo } from '@stores/requestsStore/RequestsData';
import requestsStore from '@stores/requestsStore/requestsStore';
import requestsService from '@stores/requestsStore/requestsStore.service';

import { showSuccessNotify } from '@utils/notify';

import RequestFormRow from './RequestFormRow';

interface Props {
  onDataReady(e: RequestModel): void;
}

interface State {
  selected: RequestTypeInfo | null;
  confirmBusy: boolean;
  visible: boolean;
}

class RequestForm extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);

    this.state = {
      selected: null,
      confirmBusy: false,
      visible: false,
    };
  }

  componentDidMount(): void {
    requestsStore.loadTypes();
  }

  onOpen = () => {
    this.setState({ visible: true }, () => {
      if (this.formRef) {
        this.formRef.resetFields();
      }
    });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  render() {
    const types = requestsStore.types;
    const isBusy = requestsStore.isBusy;
    const selected = this.state.selected;
    const confirmBusy = this.state.confirmBusy;
    return (
      <>
        <AdaptiveDrawer
          title={'Новая справка'}
          width={'600px'}
          onClose={this.onClose}
          visible={this.state.visible}
          footer={[
            <Button htmlType={'submit'} loading={confirmBusy} key={'close'} onClick={this.onClose}>
              Закрыть
            </Button>,
            <Button type={'primary'} htmlType={'submit'} loading={confirmBusy} key={'submit'} onClick={() => this.formRef.submit()}>
              Отправить
            </Button>,
          ]}>
          <Spin spinning={isBusy}>
            <Form layout="vertical" onFinish={this.handleFinish} ref={(ref) => (this.formRef = ref)} size={'large'}>
              <Form.Item name={'type'} label={'Справка'} rules={[{ required: true, message: 'Необходимо заполнить' }]}>
                <Select onChange={this.handleTypeChange} placeholder="Выберите справку" style={{ width: '100%' }}>
                  {types.map((t) => {
                    return (
                      <Select.Option key={t.code} value={t.code}>
                        {t.code}-{t.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {selected ? selected.meta.elements.map((e) => <RequestFormRow key={e.name} data={e} />) : null}
            </Form>
          </Spin>
        </AdaptiveDrawer>
        <Button onClick={this.onOpen}>Новая справка</Button>
      </>
    );
  }

  handleTypeChange = (e) => {
    const selected = requestsStore.types.find((s) => s.code === e);
    this.setState({ selected });
  };

  handleFinish = (e) => {
    this.setState({ confirmBusy: true });
    requestsService
      .createRequest(this.state.selected.code, e)
      .then((ans) => {
        showSuccessNotify(this.state.selected.name, 'Запрос создан');
        this.props.onDataReady(ans);
        this.onClose();
      })
      .finally(() => {
        this.setState({ confirmBusy: false });
      });
  };
}

export default observer(RequestForm);
