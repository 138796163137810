import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, InputNumber, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import React, { Component } from 'react';

import companiesStore from '@stores/companiesStore/companiesStore';
import designStore from '@stores/designStore';
import { InfologFinanceModel, articlelist } from '@stores/financeStore/FinanceData';
import financeStore from '@stores/financeStore/financeStore';

interface Props {
  data: InfologFinanceModel;
}

interface State {
  visible: boolean;
  search: string;
}

class InfologFinanceEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      search: null,
    };
  }

  formRef: FormInstance;
  render() {
    const isBusy = financeStore.isBusyEdit;
    const data = this.props.data;
    if (data && data.monthYear) data.monthYear = moment(data.monthYear);
    const visible = this.state.visible;
    const companies = this.state.search
      ? companiesStore.companies.filter((c) => c.name.toLowerCase().includes(this.state.search.toLowerCase()))
      : companiesStore.companies;
    return (
      <>
        {this.isEditMode ? (
          <Button onClick={this.openDrawer} icon={<EditOutlined />} title={'Изменить'} size={'small'} type={'text'} />
        ) : (
          <Button onClick={this.openDrawer} icon={<PlusOutlined />} title={'Создать'} type={'text'} size={'small'}>
            создать
          </Button>
        )}
        <Drawer
          width={designStore.isMobile ? '100%' : 800}
          title={this.isEditMode ? 'Редактирование' : 'Создание '}
          onClose={this.handelClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}>
              <Button onClick={this.handelClose} style={{ marginRight: 8 }}>
                Отмена
              </Button>
              <Button type="primary" loading={isBusy} htmlType={'submit'} form={'version-editor'}>
                {this.isEditMode ? 'Сохранить' : 'Добавить'}
              </Button>
            </div>
          }>
          <Form
            size={'large'}
            layout="horizontal"
            labelCol={{ span: 8 }}
            name={'version-editor'}
            ref={(ref) => (this.formRef = ref)}
            onFinish={(e) => this.handleConfirm(e)}
            initialValues={data}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24}>
                <Form.Item name={'monthYear'} label={'Месяц'}>
                  <DatePicker defaultValue={moment()} picker={'month'} allowClear={false} format={'MMMM YYYY'} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="article" label="Статья расходов/доходов" rules={[{ required: true }]}>
                  <Select getPopupContainer={(trigger) => trigger.parentElement}>
                    {articlelist.map((a) => (
                      <Select.Option key={a.value} value={a.value}>
                        {a.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="customCompanyName" label="Контрагент" rules={[{ required: true }]}>
                  <Select showSearch onSearch={(search) => this.setState({ search: search })}>
                    {this.state.search ? <Select.Option value={this.state.search}>{this.state.search}</Select.Option> : null}
                    {companies.map((r) => (
                      <Select.Option value={r.name} key={'user' + r.companyId}>
                        {r.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="accountNumber" label="Номер счета" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="workDescription" label="Описание работ из счета">
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="sumWithoutNds" label="Сумма без НДС" rules={[{ required: true }]}>
                  <InputNumber min={0} max={99999999} step={1} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="nds" label="НДС" rules={[{ required: true }]}>
                  <InputNumber min={0} max={99999999} step={1} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="sumWithNds" label="Сумма с НДС" rules={[{ required: true }]}>
                  <InputNumber min={0} max={99999999} step={1} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="note" label="Комментарий">
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }

  get isEditMode(): boolean {
    return !!this.props.data && !!this.props.data.financeId;
  }

  openDrawer = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: true, search: null });
  };

  handelClose = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: false });
  };

  async handleConfirm(e: InfologFinanceModel) {
    e.financeId = this.props.data ? this.props.data.financeId : null;
    e.month = moment(e.monthYear).month();
    e.year = moment(e.monthYear).year();
    const findArticle = articlelist.find((l) => l.value == e.article);
    e.profit = findArticle ? findArticle.type : 'profit';
    financeStore.editInfologFinance(e).then(() => this.handelClose());
  }
}

export default InfologFinanceEditor;
