import { Observer } from 'mobx-react';
import React from 'react';

import { FieldCheckbox } from '@components/fw/Fields/checkbox';
import { FieldInput } from '@components/fw/Fields/input';
import { FieldInputNumber } from '@components/fw/Fields/input-number';
import { FieldSelect } from '@components/fw/Fields/select';

import { BidRegistryCommercialStore } from '@stores/bidRegistryCommercialStore';
import { wagonProvisionGroupName } from '@stores/bidRegistryCommercialStore/bidRegistryCommercialStore.lib';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';
import { BidRegistry } from '@stores/registerOfBetsStore/registerOfBetsStore.namespace';
import sendsStore from '@stores/sendsStore';

export const renderIsActive = (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
  const field = `${wagonProvisionGroupName}-isActive-${freightEtsngCode}-${loadStationCode}-${destStationCode}`;
  return (
    <FieldCheckbox
      onChange={(value) => BidRegistryCommercialStore.changeFieldValueFlatRollingStocks(field, value.target.checked)}
      name={field}
    />
  );
};

export const renderNDS = (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
  return (
    <Observer>
      {() => {
        const priceWithoutNdsField = `${wagonProvisionGroupName}-priceWithoutNds-${freightEtsngCode}-${loadStationCode}-${destStationCode}`;
        const priceWithoutNds = BidRegistryCommercialStore.flatRollingStocks[priceWithoutNdsField];
        const ndsRate = registerOfBetsStore.bidRegistry.ndsRate;

        return <>{BidRegistry.calcNds(ndsRate, priceWithoutNds)}</>;
      }}
    </Observer>
  );
};

export const renderPriceWithoutNDS = (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
  return (
    <Observer>
      {() => {
        const fieldIsActive = `${wagonProvisionGroupName}-isActive-${freightEtsngCode}-${loadStationCode}-${destStationCode}`;
        const field = `${wagonProvisionGroupName}-priceWithoutNds-${freightEtsngCode}-${loadStationCode}-${destStationCode}`;
        const isActive = BidRegistryCommercialStore.flatRollingStocks[fieldIsActive];

        return (
          <FieldInputNumber
            rules={[{ required: isActive, message: 'Укажите ставку' }]}
            onChange={(value) => BidRegistryCommercialStore.changeFieldValueFlatRollingStocks(field, value)}
            name={field}
            min={0}
            style={NDS_STYLE}
          />
        );
      }}
    </Observer>
  );
};

export const renderPriceWithNDS = (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
  return (
    <Observer>
      {() => {
        const priceWithoutNdsField = `${wagonProvisionGroupName}-priceWithoutNds-${freightEtsngCode}-${loadStationCode}-${destStationCode}`;
        const priceWithoutNds = BidRegistryCommercialStore.flatRollingStocks[priceWithoutNdsField];
        const ndsRate = registerOfBetsStore.bidRegistry.ndsRate;

        return <>{BidRegistry.calcPriceWithNds(ndsRate, priceWithoutNds).toLocaleString('ru', { maximumFractionDigits: 2 })}</>;
      }}
    </Observer>
  );
};

export const renderLoadNormDays = (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
  return <FieldInputNumber name={`${wagonProvisionGroupName}-loadNormDays-${freightEtsngCode}-${loadStationCode}-${destStationCode}`} />;
};

export const renderDestNormDays = (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
  return <FieldInputNumber name={`${wagonProvisionGroupName}-destNormDays-${freightEtsngCode}-${loadStationCode}-${destStationCode}`} />;
};

export const renderDowntimePrice = (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
  return <FieldInputNumber name={`${wagonProvisionGroupName}-downtimePrice-${freightEtsngCode}-${loadStationCode}-${destStationCode}`} />;
};

export const renderWagonNumbers = (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
  return (
    <Observer>
      {() => {
        if (!sendsStore.wagonNumbers) {
          return null;
        }

        return (
          <FieldSelect
            mode="multiple"
            options={sendsStore.wagonNumbers.map((number) => ({ label: number, value: number }))}
            name={`${wagonProvisionGroupName}-wagonNumbers-${freightEtsngCode}-${loadStationCode}-${destStationCode}`}
          />
        );
      }}
    </Observer>
  );
};

export const renderComment = (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
  return <FieldInput name={`${wagonProvisionGroupName}-comment-${freightEtsngCode}-${loadStationCode}-${destStationCode}`} />;
};

const NDS_STYLE = { minWidth: 80 };

const toLocalString = (value: number) => value.toLocaleString('ru', { maximumFractionDigits: 2 });
