import HttpClient from '@utils/httpClient';

import { UserModel } from './usersStore';

const service = {
  getUsers(data: any) {
    return HttpClient.post(`/api/user/list`, data).then((resp) => {
      return resp.data;
    });
  },

  getUsersWithChief(data: any) {
    return HttpClient.post(`/api/user/list/withchief`, data).then((resp) => {
      return resp.data;
    });
  },

  getHierarchy() {
    return HttpClient.get(`/api/user/hierarchy`).then((resp) => {
      return resp.data;
    });
  },

  getUser(userId: string) {
    return HttpClient.get(`/api/user/list/${userId}`).then((resp) => {
      return resp.data;
    });
  },

  getRoles() {
    return HttpClient.get(`/api/user/roles/lower`).then((resp) => {
      return resp.data;
    });
  },

  getRoleAccess(roleCode: string) {
    return HttpClient.get(`/api/user/role/access`, { params: { roleCode } }).then((resp) => {
      return resp.data;
    });
  },

  insert(data: any) {
    return HttpClient.post(`/api/user/insert`, data).then((resp) => {
      return resp.data;
    });
  },

  async updateUser(data: UserModel) {
    const resp = await HttpClient.post(`/api/user/update`, data);
    return resp.data;
  },

  updateProfile(data: any) {
    return HttpClient.post(`/api/user/profile/update`, data).then((resp) => {
      return resp.data;
    });
  },

  remove(userId: string) {
    return HttpClient.delete(`/api/user/remove/${userId}`).then((resp) => {
      return resp.data;
    });
  },

  /// Сгенерировать код для временного доступа
  generateCode(userId: string, toDate: any): Promise<string> {
    return HttpClient.get(`/api/tariffCross/generate`, { params: { userId, toDate } }).then((resp) => {
      return resp.data;
    });
  },

  deleteCode(code: string): Promise<void> {
    return HttpClient.delete(`/api/tariffCross/${code}`).then((resp) => {
      return resp.data;
    });
  },

  generatekey(): Promise<string> {
    return HttpClient.get(`/api/user/generatekey`).then((resp) => {
      return resp.data;
    });
  },

  removeRequest(): Promise<string> {
    return HttpClient.delete(`/api/user/removeRequest`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
