import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { Component } from 'react';

import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import RemoteSelect from '@components/fw/RemoteSelect';

import { CompanyBankModel } from '@stores/bankStore/BankData';
import { CompanyBankService } from '@stores/bankStore/companyBank.service';
import { SupplierBankService } from '@stores/bankStore/supplierBank.service';
import designStore from '@stores/designStore';
import organizationsStore from '@stores/organizationsStore/organizationsStore';

import { showErrorNotify } from '@utils/notify';

interface Props {
  data: CompanyBankModel;
  service: CompanyBankService | SupplierBankService;
  parentId: string;
  onChange(): void;
}

interface State {
  isBusy: boolean;
  isBusyInit: boolean;
  visible: boolean;
  data: CompanyBankModel;
}

class BankEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: false,
      isBusyInit: true,
      visible: false,
      data: null,
    };
  }

  formRef: FormInstance;

  handleBikChange = (e) => {
    this.setState({ isBusy: true });
    organizationsStore
      .getBankRequisitesByBIK(e)
      .then((ans) => {
        if (ans.bankName) {
          const bank: CompanyBankModel = {
            name: ans.bankName,
            rcbic: ans.bankBik,
            address: ans.bankAddress,
            corrAccount: ans.bankCorBill,
            isMain: false,
            accounts: [],
          };
          this.setState({ data: bank }, () => {
            this.formRef.resetFields();
          });
        } else {
          showErrorNotify('Компания не найдена', 'Не удалось найти копанию с инн ' + e);
        }
      })
      .finally(() => this.setState({ isBusy: false }));
  };

  bikTemplate = (item) => {
    return (
      <>
        <p style={{ margin: 0 }}>{item.bik}</p>
        <p style={{ fontSize: '10px', margin: 0 }}>{item.name}</p>
      </>
    );
  };

  render() {
    const isBusy = this.state.isBusy;
    const data = this.state.data;
    const visible = this.state.visible;
    return (
      <>
        {this.isEditMode ? (
          <Button onClick={this.openDrawer} icon={<EditOutlined />} title={'Изменить'} size={'small'} type={'text'} />
        ) : (
          <Button onClick={this.openDrawer} icon={<PlusOutlined />} title={'Создать'} size={'small'}>
            Добавить банк
          </Button>
        )}
        <Drawer
          width={designStore.isMobile ? '100%' : '500'}
          title={this.isEditMode ? 'Редактирование банка' : 'Создание банка'}
          onClose={this.handelClose}
          visible={visible}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}>
              <Button onClick={this.handelClose} style={{ marginRight: 8 }} size={'middle'}>
                Отмена
              </Button>
              <Button type="primary" loading={isBusy} htmlType={'submit'} form={'bank-editor'} size={'middle'}>
                {this.isEditMode ? 'Сохранить' : 'Добавить'}
              </Button>
            </div>
          }>
          {visible && (
            <Form
              layout="vertical"
              size={'large'}
              name={'bank-editor'}
              ref={(ref) => (this.formRef = ref)}
              onFinish={(e) => this.handleConfirm(e)}
              initialValues={data}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24}>
                  <Form.Item>
                    <RemoteSelect
                      float={true}
                      popupContainer={document.body}
                      placeholder={'Найдите банк, чтобы добавить его в список'}
                      dataSource={organizationsStore.findBankSearchRequisites}
                      autoClearSearchValue={true}
                      valueExpr={'bik'}
                      displayExpr={'name'}
                      primitiveResult={true}
                      onChange={this.handleBikChange}
                      itemTemplate={this.bikTemplate}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item name={'name'} rules={[{ required: true, message: 'Укажите название банка' }]}>
                    <FloatInput placeholder={'Название банка'} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item name={'rcbic'} rules={[{ required: true, message: 'Укажите БИК' }]}>
                    <FloatInput placeholder={'БИК'} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item name={'corrAccount'} rules={[{ required: true, message: 'Укажите Корреспондентский счет' }]}>
                    <FloatInput placeholder={'Корреспондентский счет'} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item name={'address'} rules={[{ required: true, message: 'Укажите адрес банка' }]}>
                    <FloatInput placeholder={'Адрес банка'} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item valuePropName={'checked'} name={'isMain'}>
                    <FloatCheckbox disabled={this.props.data && this.props.data.isMain}>Основной</FloatCheckbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Drawer>
      </>
    );
  }

  get isEditMode(): boolean {
    return this.props.data && !!this.props.data.companyBankReferenceId;
  }

  openDrawer = () => {
    this.setState(
      {
        visible: true,
        data: this.props.data
          ? this.props.data
          : { isMain: true, name: null, address: null, corrAccount: null, rcbic: null, accounts: null },
      },
      () => {
        if (this.formRef) this.formRef.resetFields();
      },
    );
  };

  handelClose = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: false });
  };

  async handleConfirm(e) {
    this.setState({ isBusy: true });
    try {
      if (this.isEditMode) {
        e.companyBankReferenceId = this.props.data.companyBankReferenceId;
        await this.props.service.updateBank(e);
      } else await this.props.service.addBank(this.props.parentId, e);
      this.props.onChange();
      this.handelClose();
    } catch (e) {
    } finally {
      this.setState({ isBusy: false });
    }
  }
}

export default BankEditor;
