import React from 'react';

import { vatOptions } from '@stores/loadRegistryStore/loadRegistryData';

const VatDxCell = (props) => {
  const value = props.value;
  const option = vatOptions.find((i) => i.value === value);

  return <span>{option ? option.label : ''}</span>;
};
export default VatDxCell;
