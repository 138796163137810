import { Button, Popconfirm } from 'antd';
import React, { Component } from 'react';

import userStore from '@stores/userStore/userStore';
import userService from '@stores/userStore/userStore.service';

import { setAuthority } from '@utils/authority';

interface ComponentProps {
  userId: string;
}

interface ComponentState {
  isBusy: boolean;
}

class LoginAsUser extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isBusy: false,
    };
  }

  adminLogin = () => {
    this.setState({ isBusy: true });
    userService
      .adminLogon(this.props.userId)
      .finally(() => this.setState({ isBusy: false }))
      .then((code) => {
        setAuthority(code);
        window.location.reload();
      });
  };

  render() {
    if (!userStore.isAdmin || !this.props.userId) return null;
    return (
      <Popconfirm title="Страница будет перезагружена. Продолжить？" okText="Да" cancelText="Нет" onConfirm={() => this.adminLogin()}>
        <Button type={'primary'} danger={true}>
          Зайти как пользователь
        </Button>
      </Popconfirm>
    );
  }
}

export default LoginAsUser;
