import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import filterStore, { FilterContentModel, ReportFilterType } from '@stores/FilterStore/filterStore';

import FilterCreator from './FilterCreator';
import FilterList from './FilterList';
import { DATA_NAME, EMPTY_FILTER } from './words';

interface Props {
  reportType?: ReportFilterType;
  onChange?: (value: FilterContentModel) => void;
  readonly?: boolean;
  reportId: string;
}

interface State {
  name: string;
  stopItem: string;
  value: string[];
  filters: FilterContentModel[];
  selected: FilterContentModel;
  editMode: boolean;
  edited: FilterContentModel;
}

class FilterEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      stopItem: null,
      value: [],
      filters: [],
      selected: null,
      editMode: false,
      edited: null,
    };
  }

  render() {
    const isBusy = filterStore.isBusy;
    const data = filterStore.filters;
    const selected = filterStore.selected;
    const editMode = this.state.editMode;
    const edited = this.state.edited;
    const reportId = this.reportIdOrType;
    return (
      <span className={'dx-button-mode-contained dx-button'}>
        <Popover
          onVisibleChange={(e) => {
            if (e) filterStore.load(reportId);
          }}
          title={
            editMode ? (
              <>
                <LeftOutlined
                  style={{ cursor: 'pointer', marginRight: 5 }}
                  onClick={() => this.setState({ editMode: false, edited: null })}
                />{' '}
                {DATA_NAME}
              </>
            ) : (
              DATA_NAME
            )
          }
          content={
            editMode ? (
              <FilterCreator onFinish={this.onFormFinish} filter={edited} isBusy={isBusy} />
            ) : (
              <FilterList
                data={data}
                isBusy={isBusy}
                selected={selected}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                onAddNew={this.onAddNew}
                onClick={(item) => this.onTagClick(item)}
              />
            )
          }
          trigger="click">
          <Button type={'text'} size={'small'}>
            {selected ? selected.name : EMPTY_FILTER}
          </Button>
        </Popover>
        {!!selected && (
          <Button
            size={'small'}
            type={'text'}
            icon={<SaveOutlined />}
            onClick={() => filterStore.saveSelected(reportId)}
            loading={isBusy}
          />
        )}
      </span>
    );
  }

  onFormFinish = (e) => {
    const newItem: FilterContentModel = {
      filterId: 0,
      name: e.name,
      content: null,
      reportId: this.props.reportId,
      reportType: this.props.reportType,
      createDate: new Date().toISOString(),
    };

    if (this.state.edited) {
      newItem.filterId = this.state.edited.filterId;
      filterStore.update(this.reportIdOrType, newItem).then(() => {
        this.setState({ editMode: false, edited: null });
      });
    } else {
      filterStore.create(this.reportIdOrType, newItem).then(() => {
        this.setState({ editMode: false, edited: null });
      });
    }
  };

  get reportIdOrType() {
    return this.props.reportType == 'dispatcher' ? this.props.reportId : this.props.reportType;
  }

  onDelete = (item: FilterContentModel) => {
    filterStore.remove(this.reportIdOrType, item.filterId);
  };

  onEdit = (item: FilterContentModel) => {
    this.setState({
      edited: item,
      editMode: true,
    });
  };

  onAddNew = () => {
    this.setState({
      editMode: true,
    });
  };

  onTagClick(item: FilterContentModel | null) {
    filterStore.setSelected(this.reportIdOrType, item).then((filter) => {
      this.props.onChange(filter);
    });
  }
}

export default observer(FilterEditor);
