import { Form } from 'antd';
import React from 'react';

import FloatInput from '@components/fw/FloatLabel/FloatInput';

import styles from '../field.module.scss';
import { FieldFloatInputProps as Props } from './field-float-input.types';

export const FieldFloatInput = ({ name, ...inputProps }: Props) => {
  return (
    <Form.Item className={styles.fieldFlexAuto} name={name}>
      <FloatInput {...inputProps} />
    </Form.Item>
  );
};
