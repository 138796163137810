import { SortedDirections, SupplierBidRegistryDirection } from './supplierBidRegistryStore.types';

export const sortDirections = (directions: SupplierBidRegistryDirection[]): SortedDirections => {
  return directions.reduce((acc, direction) => {
    const { freightEtsngName } = direction;
    if (!!acc[freightEtsngName]) {
      acc[freightEtsngName].push(direction);
    } else acc[freightEtsngName] = [direction];
    return acc;
  }, {});
};

export const unpackSortedDirections = (values: SortedDirections): SupplierBidRegistryDirection[] => {
  return Object.entries(values).reduce((acc, [_, directions]) => acc.concat(directions), []);
};
