import { Layout } from 'antd';
import React from 'react';

import { dataSource } from './lib/data-source';
import { RegisterOfBetsHeader as Header } from './ui/header';
import { RegisterOfBetsTable as Table } from './ui/table';

export const RegisterOfBetsPage = () => {
  return (
    <div data-test="list-of-rates-page">
      <Header dataSource={dataSource} />
      <Layout.Content style={{ height: HEIGHT }}>
        <Table dataSource={dataSource} />
      </Layout.Content>
    </div>
  );
};

const HEIGHT = 'calc(100vh - var(--header-height))';
