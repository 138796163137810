import DataGrid, { Column, Export, FilterRow, GroupPanel, HeaderFilter, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import { ignoreCaseFilter } from '@stores/dispatcherStore/DispatcherData';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import requestsStore from '@stores/requestsStore/requestsStore';
import userStore from '@stores/userStore/userStore';

import RequestActionDxCell from './RequestActionDxCell';
import RequestNameDxCell from './RequestNameDxCell';

interface Props {}

interface State {}

class RequestsDataGrid extends Component<Props, State> {
  dataGrid: DataGrid;
  ExcelJs: any;
  constructor(props: Props) {
    super(props);
    requestsStore.createDataSource();
    import('exceljs').then((e) => {
      this.ExcelJs = e;
    });

    this.state = {};
  }

  render() {
    const data = requestsStore.dataSource;
    const isAdmin = userStore.userData.roleCode == 'admin';

    return (
      <DataGrid
        allowColumnReordering={true}
        dataSource={data}
        ref={(ref) => (this.dataGrid = ref)}
        height={`calc(100vh - var(--padding))`}
        remoteOperations={true}
        allowColumnResizing={true}
        showBorders={true}
        columnResizingMode={'widget'}
        columnAutoWidth={true}
        showColumnLines={true}
        columnMinWidth={30}
        showRowLines={true}
        //rowAlternationEnabled={true}
        onExporting={this.onExporting}
        onRowPrepared={this.onRowPrepared}>
        <Paging enabled={true} defaultPageSize={25} />
        <Pager
          allowedPageSizes={[25, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
          infoText={'Всего: {2}'}
        />
        <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'requestsDataGrid'} />
        <Paging enabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Export enabled={true} excelWrapTextEnabled={true} />
        <FilterRow visible={true} />
        <Column
          allowFixing={true}
          dataField="requestid"
          caption={'Возможности'}
          allowSorting={false}
          allowSearch={false}
          allowExporting={false}
          allowGrouping={false}
          allowHeaderFiltering={false}
          allowFiltering={false}
          cellRender={(props) => {
            return <RequestActionDxCell dxCell={props} />;
          }}
        />
        <Column
          allowFixing={true}
          calculateFilterExpression={ignoreCaseFilter}
          dataField="description"
          dataType="string"
          caption={'Запрос'}
          cellRender={(data) => (
            <>
              {data.value.split('\n').map((m) => (
                <p style={{ marginBottom: 0 }} key={m}>
                  {m}
                </p>
              ))}
            </>
          )}
        />
        <Column
          allowFixing={true}
          dataField="requesttypecode"
          dataType="string"
          caption={'Справка'}
          cellRender={this.requestNameRender()}
          calculateFilterExpression={ignoreCaseFilter}
        />
        <Column
          allowFixing={true}
          dataField="createdate"
          dataType="datetime"
          caption={'Создан'}
          defaultSortIndex={1}
          defaultSortOrder="desc"
        />
        <Column
          allowFixing={true}
          dataField="requeststatusname"
          dataType="string"
          caption={'статус'}
          calculateFilterExpression={ignoreCaseFilter}
        />
        {isAdmin && (
          <Column
            allowFixing={true}
            dataField="companyname"
            dataType="string"
            caption={'Компания'}
            calculateFilterExpression={ignoreCaseFilter}
          />
        )}
      </DataGrid>
    );
  }
  onExporting = (e) => {
    const workbook = new this.ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Лист1');
    const name = 'Справки';

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: { row: 1, column: 1 },
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;
      },
    }).then(function () {
      const date = moment().format(GLOBAL_DATE_FORMAT).toString();
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), date + `-Отчёт-${name}.xlsx`);
      });
    });
    e.cancel = true;
  };

  requestNameRender = () => {
    return RequestNameDxCell;
  };

  onRowPrepared = (e) => {
    if (e.data && e.data.iswatched == false) {
      e.rowElement.style.backgroundColor = '#dbd7ef';
    }
  };
}

export default observer(RequestsDataGrid);
