import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, HeaderFilter, Paging, Summary } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';

import { EtranInvoiceCarInfo } from '@stores/etranStore/EtranInvoiceCarData';
import etranStore from '@stores/etranStore/etranStore';

import EtranInvoceCarInfoModal from './EtranInvoceCarInfoModal';

interface Props {}

interface State {
  selected: EtranInvoiceCarInfo;
}

@observer
class EtranWagonsTable extends Component<Props, State> {
  dataGrid: DataGrid;
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  onRemove = () => {
    etranStore.removeCar(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onCopy = () => {
    etranStore.copyCar(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onUp = () => {
    etranStore.upCar(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onDown = () => {
    etranStore.downCar(this.state.selected);
    this.dataGrid.instance.refresh();
  };
  render() {
    const data = etranStore.invoceFormData.cars;
    const selected = this.state.selected;
    return (
      <div className={'etranTable'}>
        <EtranCaption title={'Вагоны'} />
        <div className={'etranButtonsGroup'}>
          <EtranInvoceCarInfoModal onChange={() => this.dataGrid.instance.refresh()} />
          {selected ? (
            <EtranInvoceCarInfoModal data={selected} onChange={() => this.dataGrid.instance.refresh()} />
          ) : (
            <Button disabled={true}>Изменить</Button>
          )}
          <Button disabled={!selected} onClick={this.onRemove}>
            Удалить
          </Button>
          <Button disabled={!selected} onClick={this.onCopy}>
            Дублировать
          </Button>
          <Button disabled={!selected} onClick={this.onUp} icon={<ArrowUpOutlined />} />
          <Button disabled={!selected} onClick={this.onDown} icon={<ArrowDownOutlined />} />
        </div>
        <DataGrid
          onSelectionChanged={(e) => {
            const key = e.selectedRowsData[0];
            this.setState({ selected: key });
          }}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={false}
          dataSource={data}
          style={{ minHeight: '110px' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          selection={{ mode: 'single' }}>
          <Paging enabled={false} />
          <GroupPanel visible={false} />
          <HeaderFilter visible={false} allowSearch={false} />
          <FilterRow visible={false} />
          {INDEX_COLUMN}
          <Column allowFixing={true} dataField="carNumber" caption={'Номер вагона'} />
          <Column allowFixing={true} dataField="carOwnerCountryName" caption={'Страна собственника'} />
          <Column allowFixing={true} dataField="carOwnerTypeName" caption={'Собств.'} />
          <Column allowFixing={true} dataField="carTypeName" caption={'Род вагона'} />
          <Column allowFixing={true} dataField="carTonnage" caption={'Грузоподъемность'} />
          <Column allowFixing={true} dataField="carWeightDep" caption={'Масса тары с бруса ц.'} />
          <Column allowFixing={true} dataField="carWeightDepReal" caption={'Масса тары пров. ц.'} />
          <Column allowFixing={true} dataField="carRolls" caption={'Рол.'} />
          <Column allowFixing={true} dataField="carAxles" caption={'Кол-во осей'} />
          <Column allowFixing={true} dataField="carWeightGross" caption={'Масса брутто'} />
          <Column allowFixing={true} dataField="carWeightNet" caption={'Масса нетто'} />
          <Column allowFixing={true} dataField="carPlacesCount" caption={'Мест'} />
          <Column allowFixing={true} dataField="carPocketCount" caption={'Кол-во пакетов'} />
          <Column allowFixing={true} dataField="carGuideCount" caption={'Пров.'} />

          <Summary></Summary>
        </DataGrid>
      </div>
    );
  }
}

export default EtranWagonsTable;
