import { Table, Tooltip } from 'antd';
import React from 'react';

import { registerOfBetsStore } from '@stores/registerOfBetsStore';

export const renderSummary = (data) => {
  let totalTariffDistance = 0;
  let totalFreightChargeTk = 0;
  let totalFreightChargeTkVat = 0;
  let totalFreightChargeTkWithVat = 0;

  data.forEach(({ tariffDistance, freightChargeTk, freightChargeTkVat, freightChargeTkWithVat }) => {
    totalTariffDistance += tariffDistance;
    totalFreightChargeTk += freightChargeTk;
    totalFreightChargeTkVat += freightChargeTkVat;
    totalFreightChargeTkWithVat += freightChargeTkWithVat;
  });

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={3}>
          Итого
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={4}>
          {totalTariffDistance}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} colSpan={5} />
        <Table.Summary.Cell index={3}>
          {totalFreightChargeTk.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4}>
          {totalFreightChargeTkVat.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          {totalFreightChargeTkWithVat.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="center">
          <Tooltip title={registerOfBetsStore.currency.name}>
            <span>{registerOfBetsStore.currency.shortName}</span>
          </Tooltip>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};
