export const COLUMNS = [
  {
    title: 'Маршрут',
    dataIndex: 'bidRegistryId',
    key: 'bidRegistryId',
    render: (_, { loadStationName, destStationName }) => {
      return `${loadStationName}-${destStationName}`;
    },
  },
  {
    title: 'Ставка без НДС',
    dataIndex: 'commission',
    key: 'commission',
    width: 130,
    render: (sum) => {
      return sum.toFixed(2);
    },
  },
  {
    title: 'НДС',
    dataIndex: 'commissionNds',
    key: 'commissionNds',
    width: 120,
    render: (sum) => {
      return sum.toFixed(2);
    },
  },
  {
    title: 'Ставка с НДС',
    dataIndex: 'commissionWithNds',
    key: 'commissionWithNds',
    width: 130,
    render: (sum) => {
      return sum.toFixed(2);
    },
  },
];
