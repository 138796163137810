import { Spin } from 'antd';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CalendarCustom from '@components/fw/Calendar/CalendarCustom';

import { HoldModel } from '@stores/holdsStore/data';
import holdsStore from '@stores/holdsStore/holdsStore';
import holdsStoreService from '@stores/holdsStore/holdsStore.service';

import HoldsForm from './HoldsForm';
import HoldsTable from './HoldsTable';
import './styles.scss';

@observer
export default class HoldsPage extends Component<RouteComponentProps, HoldsPageState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      dates: [moment().startOf('month'), moment().endOf('month')],
      edit: null,
      dataSource: [],
    };
  }

  componentDidMount() {
    document.title = 'Штрафы';

    this.loadHolds(...this.state.dates);
  }

  /*#region Actions */
  loadHolds = (start: Moment, end: Moment) => {
    this.setState({ isBusy: true });
    holdsStoreService
      .holdsList(start.startOf('day').toISOString(), end.endOf('day').toISOString())
      .then((result) => {
        this.setState({ dataSource: result });
      })
      .catch((event) => {
        console.log(event);
      })
      .finally(() => {
        this.setState({ isBusy: false });
      });
  };
  /*#endregion*/

  /*#region Handlers */
  onEdit = () => {
    if (!holdsStore.selectedRowKeys.length) return;

    const elements = this.state.dataSource.filter((item) =>
      holdsStore.selectedRowKeys.find((f) => f.loadRegistryId === item.loadRegistryId),
    );
    this.setState({ edit: elements });
  };

  onRefresh = () => {
    this.loadHolds(...this.state.dates);
  };

  onCalendarChanged = (value: [Moment, Moment] | null) => {
    this.setState({ dates: value });
    this.loadHolds(...value);
  };

  onCancelEdit = () => {
    this.setState({ edit: null });
  };

  onFinishEdit = () => {
    this.setState({ edit: null });
    this.loadHolds(...this.state.dates);
  };

  /*#endregion*/

  render() {
    const { isBusy } = this.state;

    return (
      <>
        <AdaptivePageHeader title={'Штрафы'} extra={<CalendarCustom onChange={this.onCalendarChanged} values={this.state.dates} />} />
        <Spin spinning={isBusy}>
          <AdaptiveCard className={'holdsPage'}>
            <HoldsTable
              dataSource={this.state.dataSource}
              onRefresh={this.onRefresh}
              onEdit={this.onEdit}
              isGroupSelected={!!holdsStore.selectedRowKeys.length}
            />
          </AdaptiveCard>

          {this.state.edit && (
            <HoldsForm onCancel={this.onCancelEdit} onFinish={this.onFinishEdit} value={this.state.edit} isBusy={isBusy} />
          )}
        </Spin>
      </>
    );
  }
}

/*#region Typing */
interface HoldsPageState {
  isBusy: boolean;
  dates: [Moment, Moment];
  edit: Array<HoldModel> | null;
  dataSource: Array<HoldModel>;
}

/*#endregion*/
