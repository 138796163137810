import { DefaultOptionType } from 'rc-select/lib/Select';

import { BidRegistryWayKind } from '@stores/registerOfBetsStore/registerOfBetsStore.types';

export namespace BidRegistry {
  export const wayKindLabels = {
    [BidRegistryWayKind.Inside]: 'Внутреннее',
    [BidRegistryWayKind.Import]: 'Импорт',
    [BidRegistryWayKind.Export]: 'Экспорт',
    [BidRegistryWayKind.Transit]: 'Транзит',
    [BidRegistryWayKind.OutsideRf]: 'Вне территории РФ',
  };

  export const wayKindOptions: DefaultOptionType[] = [
    { value: BidRegistryWayKind.Inside, label: wayKindLabels[BidRegistryWayKind.Inside] } as const,
    { value: BidRegistryWayKind.Import, label: wayKindLabels[BidRegistryWayKind.Import] } as const,
    { value: BidRegistryWayKind.Export, label: wayKindLabels[BidRegistryWayKind.Export] } as const,
    { value: BidRegistryWayKind.Transit, label: wayKindLabels[BidRegistryWayKind.Transit] } as const,
    { value: BidRegistryWayKind.OutsideRf, label: wayKindLabels[BidRegistryWayKind.OutsideRf] } as const,
  ];

  export const getNdsLabel = (ndsRate?: number) => {
    if (ndsRate == null) return 'Без НДС';

    return `НДС ${ndsRate * 100}%`;
  };

  export const calcNds = (ndsRate?: number, price?: number) => {
    if (!ndsRate || !price) return 0;

    return price * ndsRate;
  };

  export const calcPriceWithNds = (ndsRate?: number, price?: number) => {
    return (price ?? 0) + calcNds(ndsRate, price);
  };
}
