import HttpClient from '@utils/httpClient';

import { WagonBros } from './BrosData';

const dispatcherService = {
  wagonGetBros(): Promise<WagonBros[]> {
    return HttpClient.get(`/api/wagonOperation/bros`).then((resp) => {
      return resp.data;
    });
  },
};

export default dispatcherService;
