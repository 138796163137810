import { DefaultOptionType } from 'antd/lib/select';
import React, { Component } from 'react';

import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RequiredPlaceholder from '@components/fw/RequiredPlaceholder';

import dictService from '@stores/dictStore/dictStore.service';

interface State {
  options: DefaultOptionType[];
  loading: boolean;
}

interface RpsSelectProps {
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
}

class RpsSelect extends Component<RpsSelectProps, State> {
  constructor(props: RpsSelectProps) {
    super(props);

    this.state = {
      options: undefined,
      loading: true,
    };
  }

  onChange = (value: string) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  loadData = () => {
    dictService
      .getRpsDict()
      .then((resp) => {
        this.setState({ options: resp.map((item: string) => ({ value: item, label: item })) });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <FloatSelect
        placeholder={<RequiredPlaceholder value="Род подвижного состава" />}
        value={this.props.value}
        defaultValue={this.props.defaultValue}
        options={this.state.options}
        onChange={this.onChange}
        loading={this.state.loading}
      />
    );
  }
}
export default RpsSelect;
