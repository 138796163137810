import { action, makeObservable, observable } from 'mobx';

import { HoldModel } from './data';

class HoldsStore {
  @observable selectedRowKeys: Array<HoldModel> = [];

  constructor() {
    makeObservable(this);
  }

  @action setSelectedRowKeys(data: Array<HoldModel>) {
    this.selectedRowKeys = data;
  }
}

export default new HoldsStore();
