import HttpClient from '@utils/httpClient';

import { FilterContentModel } from './filterStore';

/**
 * Для сохранения фильтров со типом 'dispatcher'
 */

const service = {
  async getList(reportId: string): Promise<FilterContentModel[]> {
    return (await HttpClient.get(`/api/reportFilter/list/${reportId}`)).data;
  },
  async getById(filterId: number): Promise<FilterContentModel> {
    return (await HttpClient.get(`/api/reportFilter/content/${filterId}`)).data;
  },
  async create(reportId: string, data: FilterContentModel): Promise<FilterContentModel> {
    return (await HttpClient.post(`/api/reportFilter/add/${reportId}`, data)).data;
  },
  async update(data: FilterContentModel): Promise<any> {
    await HttpClient.post(`/api/reportFilter/update`, data);
  },
  async remove(id: number): Promise<any> {
    await HttpClient.delete(`/api/reportFilter/delete/${id}`);
  },
};

export default service;
