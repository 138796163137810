import { EditOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Modal, Row, Tabs, Typography } from 'antd';
import React, { Component } from 'react';

import MobileNotSupport from '@components/fw/AdaptiveElements/MobileNotSupport';
import FloatInput from '@components/fw/FloatLabel/FloatInput';

import designStore from '@stores/designStore/designStore';

import OptionsOversizeImg from './OptionsOversizeImg';

interface Props {
  disabled: boolean;
  value?: string;
  onChange?(e: string): void;
}

interface State {
  visible: boolean;
  selected: string;
}

const OVERSIZE_LIST = [
  { name: 'Габаритный', code: '0000' },
  { name: '1 нижняя', code: '1000' },
  { name: '2 нижняя', code: '2000' },
  { name: '3 нижняя', code: '3000' },
  { name: '4 нижняя', code: '4000' },
  { name: '5 нижняя', code: '5000' },
  { name: '6 нижняя', code: '6000' },
  { name: 'Нижняя сверхнегабаритность', code: '8000' },
  { name: '1 боковая', code: '0100' },
  { name: '2 боковая', code: '0200' },
  { name: '3 боковая', code: '0300' },
  { name: '4 боковая', code: '0400' },
  { name: '5 боковая', code: '0500' },
  { name: '6 боковая', code: '0600' },
  { name: 'Боковая сверхнегабаритность', code: '0800' },
  { name: '1 верхняя', code: '0010' },
  { name: '2 верхняя', code: '0020' },
  { name: '3 верхняя', code: '0030' },
  { name: 'Верхняя сверхнегабаритность', code: '0080' },
  { name: 'Вертикальная сверхнегабаритность', code: '0008' },
];

export const OVERSIZE_LIST_SORT = [
  { name: 'Вертикальная сверхнегабаритность', code: '0008' },
  { name: 'Нижняя сверхнегабаритность', code: '8000' },
  { name: 'Боковая сверхнегабаритность', code: '0800' },
  { name: 'Верхняя сверхнегабаритность', code: '0080' },
  { name: '6 нижняя', code: '6000' },
  { name: '6 боковая', code: '0600' },
  { name: '5 нижняя', code: '5000' },
  { name: '5 боковая', code: '0500' },
  { name: '4 нижняя', code: '4000' },
  { name: '4 боковая', code: '0400' },
  { name: '3 нижняя', code: '3000' },
  { name: '3 боковая', code: '0300' },
  { name: '3 верхняя', code: '0030' },
  { name: '2 нижняя', code: '2000' },
  { name: '2 боковая', code: '0200' },
  { name: '2 верхняя', code: '0020' },
  { name: '1 нижняя', code: '1000' },
  { name: '1 боковая', code: '0100' },
  { name: '1 верхняя', code: '0010' },
];

export const oversizeStr = (source: string) => {
  let result = '';
  if (!source || source == '0000') return '';
  OVERSIZE_LIST_SORT.filter((code) => isOversizeChecked(code.code, source)).forEach((code) => {
    result += ' ' + code.name;
  });
  return result;
};

export const oversizeMax = (source: string) => {
  let selected = OVERSIZE_LIST_SORT.filter((code) => isOversizeChecked(code.code, source));
  if (!selected.length) return { name: '', code: '' };
  else return selected[0];
};

export const isOversizeChecked = (code: string, source: string) => {
  if (!code || !source) return false;
  if (source == code) return true;
  for (let i = 0; i < 4; i++) {
    let c = code.charAt(i);
    if (c != '0') {
      return c == source.charAt(i);
    }
  }
};

class OptionsSizeOverage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      selected: '0000',
    };
  }

  handleSelect(code: string, e: any) {
    let s = this.state.selected;
    if (code == '0000') {
      this.setState({ selected: '0000' });
      return;
    }
    for (let i = 0; i < 4; i++) {
      let c = code.charAt(i);
      if (c != '0') {
        s = s.substring(0, i) + (e.target.checked ? c : '0') + s.substring(i + 1);
      }
    }
    this.setState({ selected: s });
  }

  isChecked(code: string) {
    return isOversizeChecked(code, this.state.selected);
  }

  handleOpen() {
    this.setState({
      visible: true,
      selected: this.props.value ? this.props.value : '0000',
    });
  }

  handleConfirm() {
    if (this.props.onChange) {
      this.props.onChange(this.state.selected);
    }

    this.setState({
      visible: false,
    });
  }

  render() {
    const selected = this.state.selected;
    const max = oversizeMax(selected);
    const str = oversizeStr(selected);
    const isMobile = designStore.isMobile;
    const value = this.props.value;

    return (
      <>
        <FloatInput
          position={'middle'}
          placeholder={'Негабаритность'}
          addonAfter={
            <Button
              onClick={() => this.handleOpen()}
              title={'Выбор'}
              icon={<EditOutlined />}
              size={'small'}
              type={'text'}
              disabled={this.props.disabled}
            />
          }
          value={!value || value == '0000' ? 'Нет' : 'H' + value}
          readOnly
        />
        <Modal
          centered={isMobile}
          width={isMobile ? '100%' : '900px'}
          title={<b>Степени негабаритности</b>}
          open={this.state.visible}
          onOk={() => this.handleConfirm()}
          onCancel={() => this.setState({ visible: false })}>
          <Tabs>
            <Tabs.TabPane key={'calc'} tab={'Выбрать'}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={20}>Наименование</Col>
                    <Col span={4}>Код</Col>
                  </Row>
                  {OVERSIZE_LIST.map((item) => (
                    <Row key={item.code}>
                      <Col span={20}>
                        <Checkbox checked={this.isChecked(item.code)} onChange={(e) => this.handleSelect(item.code, e)}>
                          {item.name}
                        </Checkbox>
                      </Col>
                      <Col span={4}>
                        <Typography.Text>{item.code}</Typography.Text>
                      </Col>
                    </Row>
                  ))}
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24}>Код негабаритности</Col>
                    <Col span={6}>
                      <Input value={'H' + selected} readOnly />
                    </Col>
                    <Col span={18}>
                      <Input value={str} readOnly />
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24}>Максимальная</Col>
                    <Col span={6}>
                      <Input value={max.code} readOnly />
                    </Col>
                    <Col span={18}>
                      <Input value={max.name} readOnly />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key={'map'} tab={'На плане'}>
              <MobileNotSupport>
                <OptionsOversizeImg onChange={(e) => this.setState({ selected: e })} />
              </MobileNotSupport>
            </Tabs.TabPane>
          </Tabs>
        </Modal>
      </>
    );
  }
}

export default OptionsSizeOverage;
