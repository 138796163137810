import { ColumnsType } from 'antd/es/table';

import { SupplierBidRegistryDirection } from '@stores/supplierBidRegistryStore';

import { renderNds, renderPrice, renderPriceWithNds } from './columns.ui';

export const COLUMNS: ColumnsType<SupplierBidRegistryDirection> = [
  {
    title: 'Маршрут',
    key: 'supplierBidDirectionId',
    render: (_, { loadStationName, destStationName }) => `${loadStationName} - ${destStationName}`,
  },
  {
    title: 'Ставка без НДС',
    dataIndex: 'freightEtsngName',
    width: 130,
    render: renderPrice,
  },
  {
    title: 'НДС',
    dataIndex: 'freightEtsngName',
    width: 120,
    render: renderNds,
  },
  {
    title: 'Ставка с НДС',
    dataIndex: 'freightEtsngName',
    width: 130,
    render: renderPriceWithNds,
  },
  {
    title: 'Модель вагона',
    dataIndex: 'wagonModel',
    width: 130,
  },
];
