import { DownCircleOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { RouteComponentProps } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

import MessageDialogEditor from '@components/fw/Drawers/MessageDialogEditor';

import messengerStore from '@stores/messengerStore/messengerStore';
import usersStore from '@stores/usersStore/usersStore';

import DialogContent from './DialogContent';
import MessageInput from './DialogContent/MessageInput';
import DialogHeader from './DialogHeader';
import DialogsList from './DialogsList';
import styles from './MessagerPage.module.scss';

const { Content } = Layout;

interface MessengerPageState {
  editedDialog: any;
}

const DIALOG_LIST_WIDTH = 300;

class MessengerPage extends Component<RouteComponentProps, MessengerPageState> {
  componentDidMount() {
    usersStore.getUsersWithChief({ withoutCurrentUser: true });
    this.scrollToBottomMassageList();
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
    this.scrollToBottomMassageList();
  }

  scrollToBottomMassageList() {
    animateScroll.scrollToBottom({
      containerId: 'messageList',
      smooth: 'easeInOutQuint',
      duration: 100,
    });
  }

  render() {
    const selectedDialog = messengerStore.selectedDialog;
    const height = 'calc(100vh - var(--header-height))';

    return (
      <>
        <DialogHeader />
        <Layout className="site-layout-background" style={{ height: height }}>
          <DialogsList width={DIALOG_LIST_WIDTH} />
          <Content style={{ height: 'calc(100vh - var(--header-height))', width: '100%' }}>
            {selectedDialog ? (
              <>
                <ScrollBar component="div" style={{ width: '100%' }} id={'messageList'}>
                  <DialogContent />
                </ScrollBar>
                <DownCircleOutlined className={styles.down} type="primary" onClick={() => this.scrollToBottomMassageList()} />
                <div className={'message-input'}>
                  <MessageInput />
                </div>
              </>
            ) : null}
          </Content>
        </Layout>

        {messengerStore.editDialog ? <MessageDialogEditor /> : null}
      </>
    );
  }

  handleEditDialog = () => {};

  handleClickUser = (user) => {};
}

export default observer(MessengerPage);
