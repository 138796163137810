import { CopyOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';

import AccessTreeEditor from '@components/fw/AccessTreeEditor';
import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputPassword from '@components/fw/FloatLabel/FloatInputPassword';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import FloatSwitch from '@components/fw/FloatLabel/FloatSwitch';

import companiesStore from '@stores/companiesStore/companiesStore';
import companiesStoreService from '@stores/companiesStore/companiesStore.service';
import dictStore from '@stores/dictStore/dictStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { OrganizationModel } from '@stores/organizationsStore';
import organizationsStore from '@stores/organizationsStore/organizationsStore';
import tariffInfologStore from '@stores/tariffInfologStore/tariffInfologStore';
import userStore from '@stores/userStore';
import { START_PAGES } from '@stores/userStore/userStore';
import { UserModel } from '@stores/usersStore';
import usersStore from '@stores/usersStore/usersStore';
import usersStoreService from '@stores/usersStore/usersStore.service';

import { showSuccessNotify } from '@utils/notify';
import { filterOptionFn } from '@utils/sort';

import CodeGenerator from './CodeGenerator';
import CompanyAccesList from './CompanyAccesList';
import LoginAsUser from './LoginAsUser';

interface UserEditorProps {
  onCancel(): void;
  onConfirm(user: UserModel): void;
  user: UserModel;
}

interface UserEditorState {
  checkedRoleAccess: string[];
  isConfirmBusy: boolean;
  roleCode: string;
  user: UserModel;
  hasApiKey: boolean;
  availableReportTypes: string[];
}

export const REPROT_NAMES_MAPPER: { [key: string]: string } = {
  WagonDislocation: 'Дислокация вагонов',
  Passport: 'Паспорта',
  ContainerDislocation: 'Дислокация контейнеров',
  UnitsCurrent: 'Детали текущие',
  UnitsHistory: 'Детали история',
  MaintenanceHistory: 'Ремонты история',
  MaintenanceCurrent: 'Ремонты текущие',
  RentToOrgWagons: 'Аренда/лизинг вагоны(текущие)',
  RentToOrgWagonsHistory: 'Аренда/лизинг вагоны (история)',
  RentToOrgContainers: 'Аренда/лизинг контейнеры (текущие)',
  RentToOrgContainersHistory: 'Аренда/лизинг контейнеры (история)',
};

@observer
class UserEditor extends Component<UserEditorProps, UserEditorState> {
  constructor(props: UserEditorProps) {
    super(props);

    this.state = {
      checkedRoleAccess: this.props.user.userAccessList || [],
      isConfirmBusy: false,
      roleCode: this.props.user.roleCode,
      user: this.props.user,
      hasApiKey: !!this.props.user.externalKey,
      availableReportTypes: [],
    };
  }
  form: FormInstance;

  componentDidMount(): void {
    const { roleCode } = this.props.user;
    usersStore.getRoleAccess(roleCode);
    companiesStore.getCompaniesSort();
    dictStore.loadSourcesDict();
    organizationsStore.getOrganizations({ organizationTypeCode: 'contractor' });
    if (userStore.userData.roleCode === 'admin') {
      tariffInfologStore.getAvailableTariff();

      if (this.props.user.userId)
        tariffInfologStore
          .getUserTariff(this.props.user.userId)
          .then(() => this.form.setFields([{ name: 'tariffsInfolog', value: tariffInfologStore.userTariffs.map((el) => el.id) }]));
    }

    if (this.state.user.companyId) {
      this.loadReportTypes(this.state.user.companyId);
    }
  }

  loadReportTypes = (companyId: string) => {
    companiesStoreService.getReportTypes(companyId).then((ans) => {
      this.setState({ availableReportTypes: ans });
    });
  };

  render() {
    const user = { ...this.state.user, tariffsInfolog: tariffInfologStore.userTariffs.map((el) => el.id) };
    const roleCode = this.state.roleCode;
    const loadingConfirm = usersStore.loadingConfirm;
    const companies = companiesStore.companiesShort;
    const loadingRoleAccess = usersStore.loadingRoleAccess;
    const roles = usersStore.roles;
    const checkedRoleAccess = this.state.checkedRoleAccess;
    const isConfirmBusy = this.state.isConfirmBusy;
    const roleAccess = usersStore.roleAccessTree;
    const organizations = organizationsStore.organizations;
    const avlbStartPages = START_PAGES.filter((p) => checkedRoleAccess.includes(p.rule));
    const srsList = dictStore.companySourcesDictionary ? dictStore.companySourcesDictionary : [];
    const isBusy = tariffInfologStore.isBusy;

    if (user) {
      Object.assign(user, {
        birthdayDate: user.birthdayDate ? moment(user.birthdayDate) : null,
      });
    }
    return (
      <Spin spinning={isBusy}>
        {!isBusy && (
          <AdaptiveDrawer
            title={this.isEditMode ? 'Редактирование пользователя' : 'Создание пользователя'}
            width={'90%'}
            onClose={this.handelClose}
            visible={user !== null}
            footer={[
              <LoginAsUser userId={user.userId} key={'admin-login'} />,
              <Button onClick={this.handelClose} key={'close'}>
                Отмена
              </Button>,
              <Button type="primary" loading={isConfirmBusy} htmlType={'submit'} form={'user-editor'} key={'save'}>
                {this.isEditMode ? 'Сохранить' : 'Добавить'}
              </Button>,
            ]}>
            <Form
              layout="vertical"
              // hideRequiredMark
              name={'user-editor'}
              initialValues={user}
              ref={(e) => (this.form = e)}
              onFinish={this.handleConfirm}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="login" rules={[{ required: true, message: 'Необходимо заполнить логин' }]}>
                    <FloatInput placeholder="Логин" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: 'Необходимо заполнить email' },
                      {
                        type: 'email',
                        message: 'Невалидный Email',
                      },
                    ]}>
                    <FloatInput placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="password" rules={[{ required: !this.isEditMode, message: 'Необходимо заполнить пароль' }]}>
                    <FloatInputPassword
                      maxLength={15}
                      placeholder={this.isEditMode ? 'Новый пароль' : 'Пароль'}
                      autoComplete={'new-password'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="confirm-password"
                    dependencies={['password']}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (getFieldValue('password') && !value) {
                            return Promise.reject('Необходимо подтвердить пароль');
                          }
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Пароли не совпадают');
                        },
                      }),
                    ]}>
                    <FloatInputPassword maxLength={15} placeholder="Подтвердить пароль" autoComplete={'new-password'} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item name="mobilePhoneNumber">
                    <InputMask mask="+7 (999)999 99 99">
                      {(inputProps) => <FloatInput placeholder="Мобильный телефон" type={'tel'} />}
                    </InputMask>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item name="workPhoneNumber">
                    <InputMask mask="+7 (999)999 99 99">
                      {(inputProps) => <FloatInput placeholder="Рабочий телефон" type={'tel'} />}
                    </InputMask>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item name="otherPhoneNumber">
                    <InputMask mask="+7 (999)999 99 99">
                      {(inputProps) => <FloatInput placeholder="Прочий номер телефона" type={'tel'} />}
                    </InputMask>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item name="birthdayDate">
                    <FloatDatepicker format={GLOBAL_DATE_FORMAT} placeholder="Дата рождения" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item name="post">
                    <FloatInput placeholder="Должность" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="name" rules={[{ required: true, message: 'Необходимо заполнить имя' }]}>
                    <FloatInput placeholder="Имя" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item name="roleCode" rules={[{ required: true, message: 'Необходимо заполнить роль' }]}>
                    <FloatSelect
                      placeholder="Роль"
                      onChange={this.handleRoleChanged}
                      value={roleCode}
                      allowClear
                      getPopupContainer={(trigger) => trigger.parentElement}>
                      {roles.map((item, index) => {
                        return (
                          <Select.Option value={item.code} key={`role_${index}`}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </FloatSelect>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  {this.renderCompanyFormItem(roleCode, companies, user, organizations)}
                </Col>
                {this.state.roleCode == 'manager' ? (
                  <Col span={12}>
                    <Form.Item name="availableSources">
                      <FloatSelect mode={'multiple'} placeholder="Доступные источники">
                        {srsList.map((item) => {
                          return (
                            <Select.Option value={item.id} key={`src_${item.id}`}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                    {userStore.userData.roleCode === 'admin' ? (
                      <Form.Item name="tariffsInfolog" style={{ marginTop: '.5rem' }}>
                        <FloatSelect mode={'multiple'} placeholder="Доступные тарифы">
                          {tariffInfologStore.availableTariffs.map((item) => {
                            return (
                              <Select.Option value={item.id} key={`src_${item.id}`}>
                                {item.name}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    ) : null}
                  </Col>
                ) : null}
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <h4>Права</h4>
                  {!loadingRoleAccess && (
                    <AccessTreeEditor tree={roleAccess} checked={checkedRoleAccess} onChange={this.handleTreeNodeCheck} />
                  )}
                </Col>
                {['org', 'user'].includes(roleCode) && (
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <h4>Дополнительно</h4>
                      <Col xs={24} sm={24} md={24}>
                        {this.state.availableReportTypes.length ? (
                          <Form.Item name="createReportTypes">
                            <FloatSelect mode={'multiple'} placeholder="Базовые отчеты">
                              {this.state.availableReportTypes.map((item) => {
                                return (
                                  <Select.Option value={item} key={`report_${item}`}>
                                    {REPROT_NAMES_MAPPER[item]}
                                  </Select.Option>
                                );
                              })}
                            </FloatSelect>
                          </Form.Item>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item name="startPage">
                          <FloatSelect allowClear placeholder="Стартовая страница">
                            {avlbStartPages.map((p) => (
                              <Select.Option key={p.path} value={p.path}>
                                {p.name}
                              </Select.Option>
                            ))}
                          </FloatSelect>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item name="assistant" valuePropName={'checked'}>
                          <FloatSwitch>Получать сообщения виртуального помощника</FloatSwitch>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <h4>
                          API ключ <Checkbox checked={this.state.hasApiKey} onChange={this.onApyKeyChange} />
                        </h4>
                        <Form.Item name="externalKey">
                          <Input style={{ height: 48 }} size={'large'} readOnly suffix={<CopyOutlined onClick={this.copy} />} />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <CodeGenerator user={this.state.user} />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Form>
          </AdaptiveDrawer>
        )}
      </Spin>
    );
  }

  onApyKeyChange = (e) => {
    if (e.target.checked) {
      usersStoreService.generatekey().then((ans) => {
        this.props.user.externalKey = ans;
        if (this.form) {
          this.form.setFieldValue('externalKey', ans);
        }
        this.setState({ hasApiKey: true });
      });
    } else {
      this.props.user.externalKey = null;
      if (this.form) {
        this.form.setFieldValue('externalKey', null);
      }
      this.setState({ hasApiKey: false });
    }
  };

  copy = () => {
    if (!this.form) return;
    let code = this.form.getFieldValue('externalKey');
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = code;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      showSuccessNotify('Скопировано');
    } catch (err) {
      console.log('Oops, unable to copy');
    }
    document.body.removeChild(textArea);
  };

  get isEditMode(): boolean {
    return this.props.user && this.props.user.userId !== null;
  }

  onCompanyChange = (companyId) => {
    this.loadReportTypes(companyId);
    if (this.form) {
      this.form.resetFields(['createReportTypes']);
    }
  };

  renderCompanyFormItem = (roleCode: string, companies: any[], user: UserModel, organizations: OrganizationModel[]) => {
    switch (roleCode) {
      case 'user':
        return (
          <Form.Item name="companyId" rules={[{ required: true, message: 'Необходимо заполнить компанию' }]}>
            <FloatSelect
              placeholder="Компания"
              value={user.companyId}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={filterOptionFn}
              onSelect={(e) => this.onCompanyChange(e)}
              getPopupContainer={(trigger) => trigger.parentElement}>
              {companies.map((item, index) => {
                return (
                  <Select.Option value={item.companyId} key={`company_${index}`}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </FloatSelect>
          </Form.Item>
        );
      case 'manager':
        return (
          <Form.Item name="companyAccessList">
            <CompanyAccesList />
          </Form.Item>
        );
      case 'org':
        return (
          <Form.Item name="organizationId" rules={[{ required: true, message: 'Необходимо заполнить организацию' }]}>
            <FloatSelect
              placeholder="Организация"
              value={user.organizationId}
              allowClear
              getPopupContainer={(trigger) => trigger.parentElement}>
              {organizations.map((item, index) => {
                return (
                  <Select.Option value={item.organizationId} key={`organization_${index}`}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </FloatSelect>
          </Form.Item>
        );
      default:
        return null;
    }
  };

  handleRoleChanged = (e) => {
    usersStore.getRoleAccess(e);
    this.setState({
      checkedRoleAccess: [],
      roleCode: e,
    });
  };

  handelClose = () => {
    this.props.onCancel();
  };

  handleTreeNodeCheck = (checkedKeys: string[]) => {
    const result = [...checkedKeys];
    if (this.form) {
      const sp = this.form.getFieldValue('startPage');
      if (sp) {
        const find = START_PAGES.find((p) => p.path == sp);
        if (find && !result.find((r) => r.includes(find.rule))) {
          const user = this.state.user;
          user.startPage = null;
          this.setState({ user });
          setTimeout(() => this.form.resetFields(['startPage']), 100);
        }
      }
    }
    this.setState({
      checkedRoleAccess: result,
    });
  };

  handleConfirm = (event: any) => {
    const result = { ...event, userAccessList: this.state.checkedRoleAccess };
    if (result.organizationId) {
      const org = organizationsStore.organizations.find((x) => x.organizationId === result.organizationId);
      Object.assign(result, { companyId: org.companyId });
    }
    this.setState({
      isConfirmBusy: true,
    });

    if (this.isEditMode) {
      usersStore
        .updateUser({ ...result, userId: this.props.user.userId })
        .finally(() => this.setState({ isConfirmBusy: false }))
        .then(() => {
          this.props.onConfirm(result);
        });
    } else {
      usersStore
        .insertUser(result)
        .finally(() => this.setState({ isConfirmBusy: false }))
        .then(() => {
          this.props.onConfirm(result);
        });
    }
  };
}

export default UserEditor;
