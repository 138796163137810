import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import CompanyEditor from '@components/fw/Drawers/CompanyEditor';

import companiesStore from '@stores/companiesStore/companiesStore';
import { CompanyModel } from '@stores/companiesStore/companyData';
import userStore from '@stores/userStore/userStore';
import usersStore from '@stores/usersStore/usersStore';

import { showSuccessNotify } from '@utils/notify';

interface State {}

class MyCompanyEditor extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    usersStore.getUsers();
    usersStore.getRoles();
    document.title = 'Изменение компании';
  }

  handleCompanyEditorConfirm = (company: CompanyModel) => {
    companiesStore.update(company).then(() => {
      showSuccessNotify(company.name, 'Изменения сохранены');
    });
  };

  render() {
    return (
      <CompanyEditor
        onCancel={() => {}}
        onConfirm={this.handleCompanyEditorConfirm}
        companyId={userStore.userData.companyId}
        type={'embedded'}
      />
    );
  }
}

export default observer(MyCompanyEditor);
