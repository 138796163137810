import Icon from '@ant-design/icons';
import { List, Popover, Typography } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import dictService from '@stores/dictStore/dictStore.service';
import { ParagraphModel } from '@stores/dictStore/dictStoreData';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface State {
  visible: boolean;
  isBusy: boolean;
  paragraphs: ParagraphModel[];
  prevCode: string;
  prevDate: any;
}

interface Props {
  stationCode: string | null;
  dateTime: string;
}

class OptionsParagraphBtn extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      isBusy: true,
      paragraphs: [],
      prevDate: null,
      prevCode: null,
    };
  }

  render() {
    return (
      <>
        <Popover
          visible={this.state.visible}
          onVisibleChange={(e) => this.setState({ visible: e })}
          content={
            <List
              loading={this.state.isBusy}
              header={<div>на {moment(this.props.dateTime).format(GLOBAL_DATE_FORMAT)}</div>}
              bordered
              size={'small'}
              dataSource={this.state.paragraphs}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text mark>{item.paragraph}</Typography.Text> {item.paragraphName}
                </List.Item>
              )}
            />
          }
        />
        <Icon component={iconQuestion} disabled={!this.props.stationCode} style={{ cursor: 'pointer' }} onClick={() => this.showMe()} />
      </>
    );
  }

  showMe = () => {
    if (!this.props.stationCode) return;
    this.setState({ visible: true, isBusy: true });
    if (this.state.prevCode === this.props.stationCode && this.state.prevDate === this.props.dateTime) {
      this.setState({ isBusy: false });
    } else {
      dictService
        .getStationParagraphs(this.props.stationCode, this.props.dateTime)
        .then((ans) => {
          this.setState({ paragraphs: ans, prevCode: this.props.stationCode, prevDate: this.props.dateTime });
        })
        .finally(() => {
          this.setState({ isBusy: false });
        });
    }
  };
}

export default OptionsParagraphBtn;
