import { CloseOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import optionsStore from '@stores/optionsStore/optionsStore';

interface State {}

interface Props {}

class OptionsAttributesPopover extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const visible = optionsStore.attributesPopoverVisible;
    const attributes = optionsStore.enabledAttributes.filter((a) => !a.isEmpty);
    return (
      <Popover
        visible={visible && !!attributes.length}
        placement={'bottom'}
        getPopupContainer={(trigger) => trigger.parentElement}
        content={
          <div>
            <p style={{ float: 'right' }}>
              <Button
                size={'small'}
                icon={<CloseOutlined />}
                onClick={() => {
                  optionsStore.attributesPopoverVisible = false;
                }}
              />
            </p>
            {attributes.map((a, index) => (
              <p key={index}>{a.attributeName}</p>
            ))}
          </div>
        }>
        {this.props.children}
      </Popover>
    );
  }
}

export default observer(OptionsAttributesPopover);
