import { HistoryOutlined } from '@ant-design/icons';
import { Button, List, Popover, Typography } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import { EtranInvoiceStatusHistory } from '@stores/etranStore/EtranData';
import etranService from '@stores/etranStore/etranStore.service';

interface State {
  visible: boolean;
  isBusy: boolean;
  data: EtranInvoiceStatusHistory[];
}

interface Props {
  id: string;
  type: 'etranInvoice' | 'etranTelegram' | 'etranClaim';
}

class EtranStatusViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      isBusy: true,
      data: [],
    };
  }

  render() {
    return (
      <>
        <Popover
          overlayClassName={'etranStatusViewer'}
          visible={this.state.visible}
          arrowPointAtCenter
          placement="bottom"
          onVisibleChange={(e) => this.setState({ visible: e })}
          content={
            <List
              loading={this.state.isBusy}
              bordered
              size={'small'}
              dataSource={this.state.data}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text mark>{moment(item.createDate).format('YYYY.MM.DD HH:mm')}</Typography.Text> {item.statusName}
                </List.Item>
              )}
            />
          }
        />
        <Button className={'etranStatusViewerButton'} type={'text'} onClick={() => this.showMe()} icon={<HistoryOutlined />} />
      </>
    );
  }

  showMe = () => {
    this.setState({ visible: true, isBusy: true });
    etranService
      .getStatusHistory(this.props.type, this.props.id)
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => {
        this.setState({ isBusy: false });
      });
  };
}

export default EtranStatusViewer;
