import { action, makeObservable, observable } from 'mobx';

import { headerFilterSort } from '@utils/sort';

import service from './markStore.service';

export type MarkModel = {
  id: string;
  title: string;
  description: string;
  isBelongsCreator: boolean;
  creatorId?: string;
  creatorName?: string;
  reportRowColor?: boolean;
};

class CompaniesTagsStore {
  @observable isBusy: boolean = false;
  @observable notes: MarkModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action async getNotes(companyId: string, search?: string, take?: number, skip?: number) {
    if (search) {
      return this.notes
        .filter((n) => n.title.toLowerCase().includes(search.toLowerCase()))
        .map((n) => {
          return { value: n.id, text: n.title };
        });
    }
    this.isBusy = true;
    try {
      this.notes = await service.getNotes(companyId);
      const data = this.notes.map((n) => ({ value: n.id, text: n.title })).sort(headerFilterSort);
      data.unshift({ value: null, text: '(Пустое)' });

      if (!isNaN(take) && !isNaN(skip)) {
        let end = take + skip;
        if (end > data.length) end = data.length;
        return data.slice(skip, end - 1);
      } else {
      }
      return data;
    } finally {
      this.isBusy = false;
    }
  }

  @action async add(data: any, companyId: string) {
    this.isBusy = true;
    await service
      .add(data)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getNotes(companyId);
      });
  }

  @action async update(data: any, companyId: string) {
    this.isBusy = true;
    await service
      .update(data)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getNotes(companyId);
      });
  }

  @action delete(noteId: string, companyId: string) {
    this.isBusy = true;
    service
      .delete(noteId)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getNotes(companyId);
      });
  }
}

export default new CompaniesTagsStore();
