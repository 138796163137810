import { Button, Modal, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { SendModel } from '@stores/sendsStore/sendData';
import sendsStore from '@stores/sendsStore/sendsStore';

import TariffDetailViewer from './TartiffDetailViewer';

interface TariffDetailProps {
  value?: SendModel;
  onOpen?: () => void;
  onDataReady?: (distance: number, price: number) => void;
  readonly?: boolean;
}

interface TariffDetailState {
  visible: boolean;
}

class TariffDetail extends Component<TariffDetailProps, TariffDetailState> {
  constructor(props: TariffDetailProps) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  state = { visible: false };

  showModal = () => {
    this.props.onOpen();
    setTimeout(() => {
      if (!this.props.value) {
        return;
      }

      this.setState({
        visible: true,
      });

      const request = this.props.value;
      if (!!request.freightWeight && !!request.wagonCount) {
        request.freightWeight = request.freightWeight / request.wagonCount;
      }
      sendsStore.getSendTariffDetail(request).then(() => {
        this.props.onDataReady(sendsStore.tariffPrice, sendsStore.tariffDistance);
      });
    }, 100);
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const value = this.props.value;
    return (
      <>
        <Button onClick={this.showModal} size={'small'} type={'link'}>
          {this.props.value && this.props.value.tariffTotalPrice ? 'Детали тарифа' : 'Рассчитать'}
        </Button>
        <Modal
          width={700}
          title={
            value ? 'Тариф за 1 ед.пc. Вес груза ' + value.freightWeight.toLocaleString('ru', { maximumFractionDigits: 2 }) + 'т' : 'Тариф'
          }
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}>
          <Spin spinning={sendsStore.isBusySendTariffDetail}>
            <TariffDetailViewer value={sendsStore.sendTariffDetail ? sendsStore.sendTariffDetail : []} />
          </Spin>
        </Modal>
      </>
    );
  }
}
export default observer(TariffDetail);
