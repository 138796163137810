import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin } from 'antd';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import ViewTypeRadio from '@components/fw/ViewTypeRadio';
import BoardView from '@components/routed/SendsPage/BoardView';
import TableView from '@components/routed/SendsPage/TableView';

import designStore from '@stores/designStore/designStore';
import { ViewType } from '@stores/dictStore';
import { SendModel } from '@stores/sendsStore/sendData';
import sendsStore from '@stores/sendsStore/sendsStore';
import userStore from '@stores/userStore/userStore';

interface SendsPageState {
  viewType: ViewType;
  selectedSend: SendModel;
  sendToDel: SendModel;
  isBusy: boolean;
}

class SendsPage extends Component<RouteComponentProps, SendsPageState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      isBusy: false,
      viewType: 'table',
      selectedSend: null,
      sendToDel: null,
    };
  }

  componentDidMount(): void {
    document.title = 'Отправки';
    sendsStore.getSends();
  }

  render() {
    const viewType = this.state.viewType;
    const isBusy = this.state.isBusy;
    const isMobile = designStore.isMobile;

    return (
      <>
        <AdaptivePageHeader
          title={'Отправки'}
          subTitle={!isMobile && <ViewTypeRadio key={'table_board'} onChange={this.handleViewTypeChanged} type={viewType} size={'small'} />}
          className={'page_header'}
          extra={<CollapsedToolbar content={userStore.canAccess('sends/edit') ? this.renderToolbar(viewType) : null} />}
        />
        <Spin spinning={isBusy}>{this.renderSwitch(viewType)}</Spin>
      </>
    );
  }

  renderToolbar(viewType: ViewType) {
    const selectedSend = this.state.selectedSend;
    const sendToDel = this.state.sendToDel;
    switch (viewType) {
      case 'table':
        return [
          <Link to={'/sends/create-send'} key={'create'}>
            <Button icon={<PlusOutlined />} size={'small'} type={'link'}>
              Создать
            </Button>
          </Link>,
          <Link to={selectedSend ? `/sends/edit-send/${selectedSend.sendId}` : ''} key={'edit'}>
            <Button icon={<EditOutlined />} size={'small'} disabled={!selectedSend} type={'link'}>
              Изменить
            </Button>
          </Link>,
          <Popconfirm
            key={'delete'}
            title={sendToDel ? `Удалить отправку "${sendToDel.sendName}"?` : null}
            visible={sendToDel !== null}
            onConfirm={this.handleRemoveConfirm}
            onCancel={() => this.setState({ sendToDel: null })}
            okText="Да"
            cancelText="Нет">
            <Button
              icon={<DeleteOutlined />}
              size={'small'}
              disabled={!selectedSend}
              type={'link'}
              onClick={() => this.handleDelete(selectedSend)}>
              Удалить
            </Button>
          </Popconfirm>,
        ];
      case 'board':
        return [
          <Link to={'/sends/create-send'} key={'create'}>
            <Button icon={<PlusOutlined />} size={'small'} type={'link'}>
              Создать
            </Button>
          </Link>,
        ];
    }
  }

  renderSwitch(viewType: ViewType) {
    switch (viewType) {
      case 'table':
        return (
          <>
            <TableView
              onSelectionChanged={this.handleSelectionChanged}
              onRowDblClick={this.handleRowDblClick}
              onDelete={this.handleDelete}
            />
          </>
        );
      case 'board':
        return (
          <>
            <BoardView onEdit={this.handleRowDblClick} onDelete={(item) => this.remove(item.sendId)} />
          </>
        );
    }
  }

  handleViewTypeChanged = (value: ViewType) => {
    this.setState({ viewType: value });
  };

  handleSelectionChanged = (data: any) => {
    this.setState({
      selectedSend: data,
    });
  };

  handleRowDblClick = (item: any) => {
    this.props.history.push(`/sends/edit-send/${item.sendId}`);
  };

  handleDelete = (item: any) => {
    this.setState({ sendToDel: item });
  };

  handleRemoveConfirm = () => {
    const sendToDelId = this.state.sendToDel.sendId;
    this.setState({
      isBusy: true,
      sendToDel: null,
      selectedSend: null,
    });
    this.remove(sendToDelId);
  };

  remove(sendToDelId) {
    sendsStore
      .deleteSend(sendToDelId)
      .finally(() => this.setState({ isBusy: false }))
      .then(() => {
        sendsStore.getSends();
      });
  }
}

export default SendsPage;
