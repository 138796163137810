import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getAuthority } from '@utils/authority';

const PrivateRoute = ({ component, ...rest }) =>
  getAuthority() ? (
    <Route {...rest} component={component} />
  ) : (
    <Redirect
      to={{
        pathname: '/sign-in',
      }}
    />
  );

export default PrivateRoute;
