import { Spin } from 'antd';
import DataGrid, { Column, GroupPanel, HeaderFilter } from 'devextreme-react/data-grid';
import React, { Component } from 'react';

import CatalogDxCellReport from '@components/fw/DxCellTemplates/CatalogDxCellReport';
import NotesDxCellReport from '@components/fw/DxCellTemplates/NotesDxCellReport';
import { getFilterDatasource } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import itineraryStore from '@stores/itineraryStore/itineraryStore';

interface Props {
  id: string;
  type: 'wagons' | 'containers';
}

interface State {
  isBusy: boolean;
  data: any[];
}

class OperationsDataGrid extends Component<Props, State> {
  dataGrid: DataGrid;

  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: true,
      data: [],
    };
  }

  componentDidMount(): void {
    itineraryStore
      .operationById(this.props.id, this.props.type)
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => {
        this.setState({ isBusy: false });
      });
  }

  getFilter = (fieldName) => {
    return {
      allowSearch: true,
      dataSource: getFilterDatasource(fieldName.toLowerCase()),
      groupInterval: undefined,
      height: undefined,
      searchMode: undefined,
      width: undefined,
    };
  };

  render() {
    const isBusy = this.state.isBusy;
    const data = this.state.data;
    const isWagons = this.props.type == 'wagons';
    const numberDataField = isWagons ? 'wagonNumber' : 'containerNumber';
    const catalogFieldName = isWagons ? 'wagonCatalogs' : 'containerCatalogs';
    const notes1FieldName = isWagons ? 'wagonNotes1' : 'containerNotes1';
    const notes2FieldName = isWagons ? 'wagonNotes2' : 'containerNotes2';
    const notes3FieldName = isWagons ? 'wagonNotes3' : 'containerNotes3';

    return (
      <Spin spinning={isBusy}>
        <h3>Операции</h3>
        <DataGrid
          width={'80vw'}
          allowColumnReordering={true}
          dataSource={data}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'nextColumn'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}>
          <GroupPanel visible={true} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Column allowFixing={true} dataField={numberDataField} caption={'Номер'} />
          <Column allowFixing={true} dataField="stationCode" caption={'Код станции'} />
          <Column allowFixing={true} dataField="stationName" caption={'Станция'} />
          <Column allowFixing={true} dataField="operationName" caption={'Операция'} />
          <Column
            allowFixing={true}
            sortOrder={'asc'}
            sortIndex={0}
            defaultSortIndex={0}
            defaultSortOrder={'asc'}
            dataField="operationDate"
            dataType="datetime"
            caption={'Дата'}
          />
          <Column allowFixing={true} dataField="sendNumber" dataType="string" caption={'Номер накладной'} />
          <Column allowFixing={true} dataField="runLoaded" caption={'Груженый пробег'} />
          <Column allowFixing={true} dataField="runEmpty" caption={'Порожний пробег'} />
          <Column
            allowFixing={true}
            dataField={catalogFieldName}
            caption={'Подразделения'}
            cellRender={CatalogDxCellReport}
            groupCellRender={CatalogDxCellReport}
            headerFilter={this.getFilter(catalogFieldName)}
          />
          <Column
            allowFixing={true}
            dataField={notes1FieldName}
            caption={'Метки 1'}
            cellRender={NotesDxCellReport}
            groupCellRender={NotesDxCellReport}
            headerFilter={this.getFilter(notes1FieldName)}
          />
          <Column
            allowFixing={true}
            dataField={notes2FieldName}
            caption={'Метки 2'}
            cellRender={NotesDxCellReport}
            groupCellRender={NotesDxCellReport}
            headerFilter={this.getFilter(notes2FieldName)}
          />
          <Column
            allowFixing={true}
            dataField={notes3FieldName}
            caption={'Метки 3'}
            cellRender={NotesDxCellReport}
            groupCellRender={NotesDxCellReport}
            headerFilter={this.getFilter(notes3FieldName)}
          />
        </DataGrid>
      </Spin>
    );
  }
}

export default OperationsDataGrid;
