import { Button, Checkbox, Form, InputNumber, Modal, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import { EtranInvoiceDev } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';

import { filterOptionFn } from '@utils/sort';

interface Props {
  data?: EtranInvoiceDev;
  onChange(data: EtranInvoiceDev): void;
}

interface State {
  visible: boolean;
  data: EtranInvoiceDev;
  search: string;
}

@observer
class EtranInvoceDevsInfoModal extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      data: null,
      search: null,
    };
  }

  handleFormReset = () => {
    this.setState({ data: this.props.data ? this.props.data : null }, () => {
      if (this.formRef) this.formRef.resetFields();
      setTimeout(() => this.forceUpdate(), 200);
    });
  };

  handleConfirm = (e: EtranInvoiceDev) => {
    const selectedDevType = etranStore.dictionaryInfo.devTypeDictionary.find((d) => d.devType == e.devTypeId);
    const selectedOwnerType = etranStore.dictionaryInfo.plannedOwningTypeDictionary.find((d) => d.id == e.devOwnerTypeId);
    const selectedCountry = null; //todo
    const result: EtranInvoiceDev = {
      devId: e.devId,
      devNumber: e.devNumber,
      devTypeId: e.devTypeId,
      devTypeName: selectedDevType?.name,
      devOwnerTypeId: e.devOwnerTypeId,
      devOwnerTypeName: selectedOwnerType?.name,
      devOwnerCountryCode: e.devOwnerCountryCode,
      devOwnerCountryName: selectedCountry?.name,
      devQuantity: e.devQuantity,
      devReturn: e.devReturnBool ? 1 : 0,
      devOwnerOkpo: e.devOwnerOkpo,
      devOwnerName: e.devOwnerName,
    };
    this.setState({ visible: false });
    this.props.onChange(result);
  };

  onOpen = () => {
    this.setState({ visible: true }, () => {
      this.handleFormReset();
    });
  };

  get isEdit() {
    return !!this.props.data && this.props.data;
  }

  onClose = () => {
    this.handleFormReset();
    this.setState({ visible: false });
  };

  render() {
    const data = this.state.data;
    const devsDict = etranStore.dictionaryInfo.devTypeDictionary;
    const dayCount = this.formRef ? this.formRef.getFieldValue('dayCount') : null;
    const modelWag = this.formRef ? this.formRef.getFieldValue('modelWag') : null;
    return (
      <>
        <Button onClick={this.onOpen}>{this.isEdit ? 'Изменить' : 'Добавить'}</Button>
        <Modal
          title="Перевязочные приспособления"
          destroyOnClose={true}
          open={this.state.visible}
          onCancel={this.onClose}
          className={'etran-modal'}
          footer={
            <Button
              onClick={() => {
                this.formRef.submit();
              }}>
              {this.isEdit ? 'Изменить' : 'Добавить'}
            </Button>
          }>
          <ScrolledContainer style={{ maxHeight: 'calc(100vh - 265px)', overflowX: 'hidden' }}>
            <Form
              layout="horizontal"
              ref={(ref) => (this.formRef = ref)}
              size={'small'}
              name={'etran-editor'}
              labelCol={{ span: 8 }}
              initialValues={{
                ...data,
              }}
              onFinish={this.handleConfirm}>
              <Form.Item name="devTypeId" label={'Тип перевозочного приспособления'} rules={[{ required: true }]}>
                <Select showSearch optionFilterProp={'children'} filterOption={filterOptionFn}>
                  {devsDict.map((m) => (
                    <Select.Option value={m.devType} key={m.devType}>
                      <span style={{ color: 'darkgrey' }}>{m.devType}</span> {m.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={'devMass'} label={'Масса'}>
                <InputNumber max={99999999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'devQuantity'} label={'Количество'}>
                <InputNumber max={99999999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'devReturnBool'} label={'Требует возврата'} valuePropName={'checked'}>
                <Checkbox />
              </Form.Item>
            </Form>
          </ScrolledContainer>
        </Modal>
      </>
    );
  }
}

export default EtranInvoceDevsInfoModal;
