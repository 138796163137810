import { PlusCircleOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import OrganizationEditor from '@components/fw/Drawers/OrganizationEditor';

import { OrganizationModel } from '@stores/organizationsStore';
import organizationsStore, { emptyOrganization } from '@stores/organizationsStore/organizationsStore';

import OrganizationSelector from './OrganizationSelector';

interface Props {
  form: FormInstance;
  disabled?: boolean;
  type?: 'contractor' | 'branch' | 'supplier';
  formItemName: string;
  onChange?(e: any): void;
  mode?: any;
  primitiveResult?: boolean;
  float?: boolean;
  placeholder?: any;
  formItemClassName?: string;
  required?: boolean;
}

interface State {
  editedOrganization: OrganizationModel;
}

@observer
class OrganizationFormItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editedOrganization: null,
    };
  }

  handleOrganizationEditorCancel = () => {
    this.setState({ editedOrganization: null });
  };

  handleOrganizationEditorConfirm = (organization: OrganizationModel) => {
    let promise;
    if (organization.organizationId === null) {
      promise = organizationsStore.create(organization);
    } else {
      promise = organizationsStore.update(organization);
    }
    promise.then(() => {
      organizationsStore.getUserOrganizations().then((org) => {
        const find = org.find((o) => o.name == organization.name && o.organizationTypeCode == organization.organizationTypeCode);
        if (find) {
          const isMultiple = ['multiple', 'tag'].includes(this.props.mode);
          const name = this.props.formItemName;
          const newValue = this.props.primitiveResult ? find.organizationId : find;
          let value = this.props.form.getFieldValue(name);
          if (isMultiple) {
            if (Array.isArray(value)) {
              value.push(newValue);
            } else {
              value = [newValue];
            }
          } else {
            value = newValue;
          }
          const valueObject = {};
          valueObject[name] = value;
          this.props.form.setFieldsValue({ ...valueObject });
        }
        this.setState({ editedOrganization: null });
      });
    });
  };

  handleCreateOrg = () => {
    this.setState({ editedOrganization: emptyOrganization });
  };

  handleChangeOrg = (orgId: string) => {
    const find = organizationsStore.contragent.find((c) => c.organizationId === orgId);
    if (find) {
      this.setState({ editedOrganization: find });
    }
  };

  onOrgChange = (e) => {
    this.forceUpdate();
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    const { disabled, primitiveResult, formItemName, float, mode, placeholder, type, formItemClassName, required } = this.props;
    const editedOrganization = this.state.editedOrganization;
    return (
      <Form.Item
        className={formItemClassName}
        name={formItemName}
        rules={[
          {
            validator: async (rule, value) => {
              if (required === false) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(new Error('Укажите организацию'));
              }
            },
          },
        ]}>
        <OrganizationSelector
          onEdit={this.handleChangeOrg}
          float={float}
          mode={mode}
          primitiveResult={primitiveResult}
          type={type}
          disabled={disabled}
          placeholder={
            <span>
              {placeholder}
              {editedOrganization ? (
                <OrganizationEditor
                  key={'edit'}
                  organizationId={editedOrganization.organizationId}
                  onCancel={this.handleOrganizationEditorCancel}
                  onConfirm={this.handleOrganizationEditorConfirm}
                />
              ) : null}
              <PlusCircleOutlined style={{ margin: 3 }} title={'Добавить организацию'} onClick={this.handleCreateOrg} />
            </span>
          }
          allowClear
          onChange={this.onOrgChange}
        />
      </Form.Item>
    );
  }
}

export default OrganizationFormItem;
