import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Tooltip } from 'antd';
import { Template } from 'devextreme-react/core/template';
import DataGrid, { Column, Export, FilterPanel, FilterRow, GroupPanel, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import 'moment/locale/ru';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import CompanyEditor from '@components/fw/Drawers/CompanyEditor';
import Treaties from '@components/fw/Drawers/Treaties';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import companiesStore from '@stores/companiesStore/companiesStore';
import { CompanyModel, emptyCompany } from '@stores/companiesStore/companyData';
import designStore from '@stores/designStore/designStore';
import dictStore from '@stores/dictStore/dictStore';
import userStore from '@stores/userStore/userStore';

interface CompaniesListState {
  editedCompany: CompanyModel;
  searchStr: string;
}

@observer
class CompaniesList extends Component<RouteComponentProps, CompaniesListState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      editedCompany: null,
      searchStr: '',
    };
  }

  componentDidMount(): void {
    companiesStore.getCompanies();
    dictStore.loadInfoblocksDict();
    document.title = 'Транспортные компании';
  }

  render() {
    const companies = [...companiesStore.companies];
    const editedCompany = this.state.editedCompany;
    const loadingCompanies = companiesStore.loadingCompanies;

    return (
      <>
        <AdaptiveCard>
          <Spin spinning={loadingCompanies}>
            <DataGrid
              allowColumnReordering={true}
              dataSource={companies}
              height={'calc(100vh - 128px)'}
              rowAlternationEnabled={true}
              remoteOperations={false}
              showRowLines={true}
              showBorders={true}
              allowColumnResizing={true}
              columnResizingMode={'widget'}
              columnAutoWidth={true}
              columnMinWidth={20}
              onToolbarPreparing={this.onToolbarPreparing}
              wordWrapEnabled={true}>
              <Paging enabled={true} defaultPageSize={50} />
              <Pager
                allowedPageSizes={[25, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
                infoText={'Всего: {2}'}
              />
              <GroupPanel visible={true} emptyPanelText={'Поле группировки'} />
              <HeaderFilter visible={true} allowSearch={true} />
              <Export enabled={true} excelWrapTextEnabled={true} />
              <FilterRow visible={true} />
              <FilterPanel visible={true} />
              {INDEX_COLUMN}
              <Column
                allowFixing={true}
                dataField="name"
                caption={'Название компании'}
                cellRender={(e) => {
                  if (e.rowType == 'data') {
                    return (
                      <span>
                        <span style={{ float: 'left' }}>{e.data.name}</span>
                        <span style={{ float: 'right' }}>
                          <Tooltip title={'Редактировать'}>
                            <EditOutlined onClick={() => this.handleEdit(e.data)} />
                          </Tooltip>
                          <Popconfirm
                            disabled={userStore.userData.roleCode != 'admin'}
                            title="Удалить компанию？"
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={() => this.handleRemoveConfirm(e.data)}>
                            <DeleteOutlined disabled={true} style={{ marginLeft: 5 }} />
                          </Popconfirm>
                        </span>
                      </span>
                    );
                  }
                }}
              />
              <Column allowFixing={true} dataField="managerList" caption={'Менеджер'} dataType="string" />
              <Column allowFixing={true} dataField="activeWagonCount" caption={'Вагоны'} dataType="number" />
              <Column allowFixing={true} dataField="activeContainerCount" caption={'Контейнеры'} dataType="number" />
              <Column allowFixing={true} dataField="isTest" caption={'Тестовая'} dataType="boolean" />
              <Column allowFixing={true} dataField="etranLogin" caption={'Этран'} dataType="string" />
              {designStore.logoType === 'ITK' && (
                <Column allowFixing={true} dataField="railwayLogin" caption={'Railway'} dataType="string" />
              )}
              {/* <Column allowFixing={true} dataField="sigisLogin" caption={'Sigis'} dataType="string" />
              <Column allowFixing={true} dataField="taisLogin" caption={'Tais'} dataType="string" />*/}
              <Column allowFixing={true} dataField="accessEndDate" caption={'Доступ до'} dataType="date" />
              <Column allowFixing={true} dataField="deepCount" caption={'Хранение (дней)'} dataType="number" />
              <Column allowFixing={true} dataField="signer" caption={'ФИО подписанта'} dataType="string" />
              <Column allowFixing={true} dataField="signerPost" caption={'Должность'} dataType="string" />
              <Column allowFixing={true} dataField="companyPhone" caption={'Телефон'} dataType="string" />
              <Column allowFixing={true} dataField="companyEmail" caption={'email'} dataType="string" />
              <Column
                allowFixing={true}
                dataField="serviceStr"
                dataType={'string'}
                caption={'Договоры'}
                cellRender={(e) => {
                  if (e.rowType == 'data') {
                    return (
                      <>
                        <div style={{ whiteSpace: 'pre', minWidth: 'fit-content' }}>{e.data.serviceStr}</div>
                        <div>
                          <Treaties isEdit={e.data.serviceStr} companyId={e.data.companyId} />
                        </div>
                      </>
                    );
                  }
                }}
              />
              <Template name={'create'} render={() => this.createTemplate()} />
            </DataGrid>
          </Spin>
        </AdaptiveCard>
        {editedCompany ? (
          <CompanyEditor
            companyId={editedCompany.companyId}
            onCancel={this.handleCompanyEditorCancel}
            onConfirm={this.handleCompanyEditorConfirm}
          />
        ) : null}
      </>
    );
  }

  createTemplate = () => {
    return (
      <Button icon={<PlusOutlined />} size={'small'} onClick={this.handleCreate}>
        Создать
      </Button>
    );
  };
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'create',
    });
  };

  handleCreate = () => {
    this.setState({
      editedCompany: emptyCompany,
    });
  };

  handleEdit = (company: CompanyModel) => {
    this.setState({ editedCompany: company });
  };

  handleRemoveConfirm = (company: CompanyModel) => {
    companiesStore.remove(company.companyId);
  };

  handleCompanyEditorCancel = () => {
    this.setState({ editedCompany: null });
  };

  handleCompanyEditorConfirm = (company: CompanyModel) => {
    let promise = company.companyId ? companiesStore.update(company) : companiesStore.create(company);
    promise.then(() => this.setState({ editedCompany: null }));
  };
}

export default CompaniesList;
