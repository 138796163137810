import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { InfologFinanceModel, articlelist } from './FinanceData';
import service from './financeStore.service';

class FinanceStore {
  @observable isBusy: boolean = false;
  @observable isBusyEdit: boolean = false;
  @observable infologFinances: InfologFinanceModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action loadInfologFinances() {
    this.isBusy = true;
    service
      .getInfologFinanceList()
      .finally(() => (this.isBusy = false))
      .then((result) => {
        result.forEach((r) => {
          r.monthYear = moment(r.year + '-' + (r.month + 1).toString().padStart(2, '0') + '-01').toDate();
          const findArticle = articlelist.find((l) => l.value == r.article);
          r.articleName = findArticle ? findArticle.name : '';
        });
        this.infologFinances = result;
      });
  }

  @action editInfologFinance(data: InfologFinanceModel) {
    this.isBusyEdit = true;
    const promise = data.financeId ? service.updateInfologFinance(data) : service.addInfologFinance(data);
    return promise
      .then(() => {
        this.loadInfologFinances();
      })
      .finally(() => (this.isBusyEdit = false));
  }

  @action delete(financeId: string, accountNumber: string) {
    this.isBusy = true;
    service
      .deleteInfologFinance(financeId, accountNumber)
      .then(() => {
        this.loadInfologFinances();
      })
      .catch(() => (this.isBusy = false));
  }
}

export default new FinanceStore();
