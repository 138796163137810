import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
import { loadMessages, locale } from 'devextreme/localization';
import ruMessages from 'devextreme/localization/messages/ru.json';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';

import styles from '@components/app/App/App.module.scss';
import AppContent from '@components/fw/AppContent';
import PrintingHeader from '@components/fw/AppContent/PrintingHeader';
import RoutingContext from '@components/fw/RoutingContext';
import PrivateRoute from '@components/fw/RoutingContext/PrivateRoute';
import LoginPage from '@components/routed/LoginPage';
import CodeLoginPage from '@components/routed/LoginPage/CodeLoginPage';
import PasswordRestorePage from '@components/routed/PasswordRestorePage/PasswordResorePage';

import { setDataGridDateFormat } from '@utils/dataGrid';

import packageJson from '../../../../package.json';

loadMessages(ruMessages);
locale('ru');
setDataGridDateFormat();

const App: React.FC = () => (
  <div className={styles.app}>
    <PrintingHeader />
    <Router basename={packageJson.homepage}>
      <RoutingContext>
        <Switch>
          <Route path="/sign-in" component={LoginPage} />
          <Route path="/reset-password/:token" component={PasswordRestorePage} />
          <Route path="/code-login" component={CodeLoginPage} />
          <PrivateRoute path="/" component={AppContent} />
        </Switch>
      </RoutingContext>
    </Router>
  </div>
);

export default App;
