import { Col, Row, Spin } from 'antd';
import 'devextreme/dist/css/dx.light.css';
import moment from 'moment';
import React, { Component } from 'react';
import 'whatwg-fetch';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import { WagonFullInfo } from '@stores/dispatcherStore/DispatcherData';
import dispatcherStoreService from '@stores/dispatcherStore/dispatcherStore.service';
import { GLOBAL_DATETIME_FORMAT, GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

const columns = ['number', 'loadStationName', 'loadStationCode'];

interface Props {
  type: 'wagon' | 'container';
  number: string;
}
interface State {
  isBusy: boolean;
  data: WagonFullInfo;
}

class DispatcherInfoDetail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: true,
      data: null,
    };
  }

  componentDidMount() {
    dispatcherStoreService
      .fullInfo(this.props.type, this.props.number)
      .then((ans) => this.setState({ data: ans }))
      .finally(() => this.setState({ isBusy: false }));
  }

  render() {
    const { isBusy } = this.state;
    const d = this.state.data;

    return (
      <Spin spinning={isBusy}>
        {!!d && (
          <div className={'zebra'}>
            <Col span={24}>РЕЙС</Col>
            <Col span={24}>Погрузка</Col>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Станция погрузки
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {!!d.loadStationName ? d.loadStationName + '(' + d.loadStationCode + '),' + d.loadRoadName : 'Нет данных'}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Дата погрузки
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {!!d.loadDate && moment(d.loadDate).format(GLOBAL_DATETIME_FORMAT)}
              </Col>
            </Row>
            <Col span={24}>Дислокация</Col>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Станция дислокации
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {!!d.dislStationName ? d.dislStationName + '(' + d.dislStationCode + '),' + d.dislRoadName : 'Нет данных'}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Название операции
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {d.operationName}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Дата текущей дислокации
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {!!d.operationDate && moment(d.operationDate).format(GLOBAL_DATETIME_FORMAT)}
              </Col>
            </Row>
            <Col span={24}>Назначение</Col>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Станция назначения
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {!!d.destStationName ? d.destStationName + '(' + d.destStationCode + '),' + d.destRoadName : 'Нет данных'}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Дата прибытия
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {!!d.receiveDate && moment(d.receiveDate).format(GLOBAL_DATE_FORMAT)}
              </Col>
            </Row>
            <Col span={24}>Общая информация рейса</Col>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Название груза
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {d.etsngName}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Вес груза
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {d.etsngNetto}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Номер накладной
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {d.sendNumber}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Индекс поезда
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {d.trainIndex}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Номер поезда
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {d.trainNumber}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Номер пути
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {d.wayNumber}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Номер парка
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {d.parkNumber}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                Оставшееся расстояние
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                {d.distance}
              </Col>
            </Row>
            {this.props.type == 'wagon' && (
              <>
                <Col span={24}>ПАСПОРТ</Col>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Грузоподъемность (центнеры)
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.lifting}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код страны собственника
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.ownerCountryCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название страны собственника
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.ownerCountryName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Дата регистрации
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {!!d.receiveDate && moment(d.registrationDate).format(GLOBAL_DATE_FORMAT)}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Дата постройки вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {!!d.buildDate && moment(d.buildDate).format(GLOBAL_DATE_FORMAT)}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код завода постройки
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.buildFactoryCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название завода постройки
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.buildFactoryName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Дата окончания срока службы
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {!!d.endDate && moment(d.endDate).format(GLOBAL_DATE_FORMAT)}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Род вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.wagonKind}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Тип вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.wagonType}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Длина вагона по осям автосцепки (мм)
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.wagonLength}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Вес тары вагона в центнерах
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.taraWeight}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код модели вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.wagonModel}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название модели вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.wagonModelName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Габарит
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.dimensions}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Калибровка котла (для цистерн)
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.boilerCalibration}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код станции приписки
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.registrationStationCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название станции приписки
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.registrationStationName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код дороги приписки вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.registrationRoadCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название дороги приписки вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.registrationRoadName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код очередного планового ремонта вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.scheduledPlanRepairCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название очередного планового ремонта вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.scheduledPlanRepairName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Дата очередного планового ремонта вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {!!d.scheduledPlanRepairDate && moment(d.scheduledPlanRepairDate).format(GLOBAL_DATE_FORMAT)}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Межрем. норматив пробега
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.runStandard}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Разрешение перевода вагона на пробег собственного вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.runPermission}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код дороги исключения
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.banRoadCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название дороги исключения
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.banRoadName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код предприятия исключения
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.banDepotCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название предприятия исключения
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.banDepotName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Дата исключения вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {!!d.banDate && moment(d.banDate).format(GLOBAL_DATE_FORMAT)}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Признак того, что вагону запр. курс. по путям общ.польз
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.banSign}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Признак продления срока службы
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.signLifeExtension}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Межрем. норматив пробега
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.repairMileageStandard}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Оборудование АСТ
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.astEquipment}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Признак наличия датчиков
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.signSensors}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Принадлежность к оператору
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.signOperator}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Заводской номер
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.factoryNumber}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Дата отпуска номера
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {!!d.issueDate && moment(d.issueDate).format(GLOBAL_DATE_FORMAT)}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Старый номер вагона
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.oldWagonNumber}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Авторежим
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.autoMode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    * Тип тормоза * 1-на тормозной площадке * 2-стояночный * 3-не оборудован"
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.brakeType}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название тормаза
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.brakeName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    * Тип автосцепки * 1-СА-3 * 2-СА-3М
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.couplerType}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название автосцепки
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.couplerName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Возможность постановки буфера
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.bufferOption}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код модели тележек
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.cartsModelCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Подпольные балочки
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.undergroundBeams}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Наличие сливного прибора
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.drainDevice}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Наличие рамы
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.frame}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Конструкция котла
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.boilerDesign}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Локальный код собственника
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.localOwnerCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    ОКПО арендатора
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.rentOkpo}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название арендатора
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.rentName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Срок окончания аренды
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {!!d.rentEndDate && moment(d.rentEndDate).format(GLOBAL_DATE_FORMAT)}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код станции приписки арендатора
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.rentStationCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название станции приписки арендатора
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.rentStationName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код ВЧД последнего деп. ремонта
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.repairDepotCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название ВЧД последнего деп.ремонта
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.repairDepotName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Дата последнего деп. ремонт
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {!!d.repairDepotDate && moment(d.repairDepotDate).format(GLOBAL_DATE_FORMAT)}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Код ВЧД последнего кап.ремонта
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.repairMajorCode}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Название ВЧД последнего кап.ремонта
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.repairMajorName}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Дата последнего кап. ремонт
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {!!d.repairMajorDate && moment(d.repairMajorDate).format(GLOBAL_DATE_FORMAT)}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    Ремонт по пробегу
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {d.repairInRun}
                  </Col>
                </Row>
              </>
            )}
            {this.props.type == 'wagon' && (
              <>
                <Col span={24}>РЕМОНТЫ</Col>
                <ScrolledContainer>
                  <table className="table">
                    <th>Название ремонта</th>
                    <th>Номер ВУ-23</th>
                    <th>Дата ВУ-23</th>
                    <th>Номер ВУ-36</th>
                    <th>Дата ВУ-36</th>
                    <th>Код станции ремонта</th>
                    <th>Название станции ремонта</th>
                    <th>Код депо ремонта</th>
                    <th>Название депо ремонта</th>
                    <th>Код станции перечисления в неисправные</th>
                    <th>Название станции перечисления в неисправные</th>
                    <th>Код неисправности 1</th>
                    <th>Название неисправности 1</th>
                    <th>Код неисправности 2</th>
                    <th>Название неисправности 2</th>
                    <th>Код неисправности 3</th>
                    <th>Название неисправности 3</th>
                    <th>Фактическая дата ремонта</th>

                    {!!d.repairs && d.repairs.length > 0 ? (
                      d.repairs.map((i) => (
                        <tr className="table">
                          <td>{i.repairName}</td>
                          <td>{i.vu23Number}</td>
                          <td>{!!i.vu23Date && moment(i.vu23Date).format(GLOBAL_DATETIME_FORMAT)}</td>
                          <td>{i.vu36Number}</td>
                          <td>{!!i.vu36Date && moment(i.vu36Date).format(GLOBAL_DATETIME_FORMAT)}</td>
                          <td>{i.repairStationCode}</td>
                          <td>{i.repairStationName}</td>
                          <td>{i.repairDepotCode}</td>
                          <td>{i.repairDepotName}</td>
                          <td>{i.failStationCode}</td>
                          <td>{i.failStationName}</td>
                          <td>{i.failCode1}</td>
                          <td>{i.failCodeName1}</td>
                          <td>{i.failCode2}</td>
                          <td>{i.failCodeName2}</td>
                          <td>{i.failCode3}</td>
                          <td>{i.failCodeName3}</td>
                          <td>{!!i.factRepairDate && moment(i.factRepairDate).format(GLOBAL_DATETIME_FORMAT)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr className="table">
                        <td colSpan={8} style={{ textAlign: 'center' }}>
                          Нет Данных
                        </td>
                      </tr>
                    )}
                  </table>
                </ScrolledContainer>
              </>
            )}
            {this.props.type == 'wagon' && (
              <>
                <Col span={24}>ДЕТАЛИ</Col>
                <ScrolledContainer>
                  <table className="table" style={{ width: '100%' }}>
                    <th>Название детали</th>
                    <th>Номер детали</th>
                    <th>Номер тележки</th>
                    <th>Установлена</th>
                    <th>Год изготовления</th>
                    <th>Завод изготовитель</th>
                    <th>Страна</th>
                    <th>Дата сообщения</th>
                    <th>Дата ГВЦ</th>

                    {!!d.units && d.units.length > 0 ? (
                      d.units.map((i) => (
                        <tr className="table">
                          <td>{i.detailName}</td>
                          <td>{i.detailNumber}</td>
                          <td>{i.truckNumber}</td>
                          <td>{i.isActive}</td>
                          <td>{i.manufactureYear}</td>
                          <td>{i.manufactureName}</td>
                          <td>{i.countryName}</td>
                          <td>{!!i.messageDate && moment(i.messageDate).format(GLOBAL_DATE_FORMAT)}</td>
                          <td>{!!i.gvcDate && moment(i.gvcDate).format(GLOBAL_DATETIME_FORMAT)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr className="table">
                        <td colSpan={8} style={{ textAlign: 'center' }}>
                          Нет Данных
                        </td>
                      </tr>
                    )}
                  </table>
                </ScrolledContainer>
              </>
            )}
            <Col span={24}>ДОКУМЕНТЫ</Col>
            <ScrolledContainer>
              <table className="table" style={{ width: '100%' }}>
                <th>Идентификатор договора</th>
                <th>Название договора</th>
                <th>Тип договора</th>
                <th>Статус</th>
                <th>Название организации</th>
                <th>Действует от</th>
                <th>Действует до</th>
                <th>Дата создания</th>
                {!!d.docs && d.docs.length > 0 ? (
                  d.docs.map((i) => (
                    <tr className="table">
                      <td>{i.id}</td>
                      <td>{i.name}</td>
                      <td>{i.type}</td>
                      <td>{i.status}</td>
                      <td>{i.organizationName}</td>
                      <td>{!!i.fromDate && moment(i.fromDate).format(GLOBAL_DATETIME_FORMAT)}</td>
                      <td>{!!i.toDate && moment(i.toDate).format(GLOBAL_DATETIME_FORMAT)}</td>
                      <td>{!!i.createDate && moment(i.createDate).format(GLOBAL_DATETIME_FORMAT)}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="table">
                    <td colSpan={8} style={{ textAlign: 'center' }}>
                      Нет Данных
                    </td>
                  </tr>
                )}
              </table>
            </ScrolledContainer>
            <Col span={24}>ПОСЛЕДНИЕ ОПЕРАЦИИ</Col>
            <ScrolledContainer>
              <table className="table">
                <th>Код станции погрузки</th>
                <th>Название станции погрузки</th>
                <th>Дата погрузки</th>
                <th>Код станции дислокации</th>
                <th>Название станции дислокации</th>
                <th>Дата операции</th>
                <th>Код станции назначения</th>
                <th>Название станции назначения</th>
                <th>Дата прибытия</th>
                <th>Название операции</th>
                <th>Номер накладной</th>
                <th>Название груза</th>
                <th>Вес груза</th>
                <th>ОКПО отправителя</th>
                <th>Название отправителя</th>
                <th>ОКПО получателя</th>
                <th>Название получателя</th>
                {!!d.operations && d.operations.length > 0 ? (
                  d.operations.map((i) => (
                    <tr className="table">
                      <td>{i.loadStationCode}</td>
                      <td>{i.loadStationName}</td>
                      <td>{!!i.loadDateTime && moment(i.loadDateTime).format(GLOBAL_DATETIME_FORMAT)}</td>
                      <td>{i.dislStationCode}</td>
                      <td>{i.dislStationName}</td>
                      <td>{!!i.operationDate && moment(i.operationDate).format(GLOBAL_DATETIME_FORMAT)}</td>
                      <td>{i.destStationCode}</td>
                      <td>{i.destStationName}</td>
                      <td>{!!i.receiveDateTime && moment(i.receiveDateTime).format(GLOBAL_DATE_FORMAT)}</td>
                      <td>{i.operationName}</td>
                      <td>{i.sendNumber}</td>
                      <td>{i.etsngName}</td>
                      <td>{i.etsngNetto}</td>
                      <td>{i.senderOkpo}</td>
                      <td>{i.senderName}</td>
                      <td>{i.receiverOkpo}</td>
                      <td>{i.receiverName}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="table">
                    <td colSpan={17} style={{ textAlign: 'center' }}>
                      Нет Данных
                    </td>
                  </tr>
                )}
              </table>
            </ScrolledContainer>
          </div>
        )}
      </Spin>
    );
  }
}

export default DispatcherInfoDetail;
