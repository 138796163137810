import { Layout, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import { EtranTelegramInfo } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';

import styles from './EtranTelegramViewer.module.scss';

interface EtranTelegramViewerState {
  isBusy: boolean;
  currentTelegram: EtranTelegramInfo;
}

class EtranTelegramViewer extends Component<RouteComponentProps, EtranTelegramViewerState> {
  formRef: FormInstance;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isBusy: true,
      currentTelegram: null,
    };
  }

  componentDidMount(): void {
    const id = this.id;
    if (id) {
      etranStore
        .getEtranTelegram(id)
        .then((ans) => {
          this.setState({ currentTelegram: ans });
        })
        .finally(() => this.setState({ isBusy: false }));
    } else {
      this.setState({ isBusy: false });
    }
  }

  get id(): string {
    const { params } = this.props.match;
    return params['telegramId'];
  }

  render() {
    const isBusy = this.state.isBusy;
    const height = 'calc(100vh - 20px)';
    const t = this.state.currentTelegram;
    document.title = `Телеграмма № ${this.id}`;

    return (
      <>
        <AdaptivePageHeader title={`Телеграмма № ${this.id}`} className={styles.pageHeader} />
        <Layout.Content className={styles.content}>
          <ScrolledContainer className={'scrolledContainer'} style={{ height: height }}>
            <div className={styles.mainContent}>
              <div className={styles.containerFluid}>
                <div className={styles.row}>
                  <div className={styles.colMd12}>
                    <Spin spinning={isBusy}>
                      {!!t && (
                        <div className={styles.etranContent}>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Дата окончания перевозки:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{moment(t.rtFinishDate).format('DD.MM.YYYY, HH:mm')}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Экспедитор:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}> {t.rtPayerName}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>
                              Подписной номер разрешающей телеграммы:
                            </label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}> {t.rtNomStr}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Вид сообщения:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.rtMsgKindName}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>
                              Наименование грузоотправителя:
                            </label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.rtSenderName}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Код ОКПО:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}>{t.rtSenderOkpo}</span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Страна отправления:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.textEdit}> {t.rtFromCountryCode}</span>
                              <span className={styles.comboEdit}> {t.rtFromCountryName} </span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Станция входа в СНГ:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.textEdit}> {t.rtEnterSngStationCode}</span>
                              <span className={styles.comboEdit}> {t.rtEnterSngStationName} </span>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Станция входа в РФ:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.textEdit}> {t.rtEnterStationCode}</span>
                              <span className={styles.comboEdit}> {t.rtEnterStationName} </span>
                            </div>
                          </div>

                          <div className={styles.row}>
                            <div className={`${styles.colSm12} ${styles.tableResponsive}`}>
                              <br />
                              <table className={styles.etranTableInvoice} style={{ border: 'solid 1px black', width: '100%' }}>
                                <caption>Отправки</caption>
                                <thead className={styles.textPrimary}>
                                  <tr>
                                    <th>№ отпр.</th>
                                    <th>Подкод эксп.</th>
                                    <th>КИТ</th>
                                    <th>Наим. груза ЕТСНГ</th>
                                    <th>Наим. груза ГНГ</th>
                                    <th>Вид отправки</th>
                                    <th>Принадл. ваг/конт</th>
                                    <th>Кол-во ваг/конт</th>
                                    <th>Вес, тонн</th>
                                    <th>Страна назн.</th>
                                    <th>Станция назн.</th>
                                    <th>Станция выхода из РФ</th>
                                    <th>Наим. грузополучателя</th>
                                    <th>ОКПО грузополучателя</th>
                                    <th>Провозная плата</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {t.telegramOtprList.map((item, index) => (
                                    <tr key={`freights_${index}`}>
                                      <td>{item.otprNom}</td>
                                      <td>{item.otprSubCodeExp}</td>
                                      <td>{item.otprIxTariffCode}</td>
                                      <td>{item.otprFreightName}</td>
                                      <td>{item.otprFreightGngName}</td>
                                      <td>{item.otprSendKindName}</td>
                                      <td>{item.otprCarContOwnerTypeName}</td>
                                      <td>{item.otprCarCount}</td>
                                      <td>{item.otprFreightWeight}</td>
                                      <td>{item.otprToCountryName}</td>
                                      <td>{item.otprToStationName}</td>
                                      <td>{item.otprExitStationName}</td>
                                      <td>{item.otprRecipName}</td>
                                      <td>{item.otprRecipOkpo}</td>
                                      <td>{item.otprPaySum}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <br />
                            </div>
                          </div>

                          <div className={styles.row}>
                            <div className={`${styles.colSm12} ${styles.tableResponsive}`}>
                              <br />
                              <table className={styles.etranTableInvoice} style={{ border: 'solid 1px black', width: '100%' }}>
                                <caption>Плательщики по другим администрациям</caption>
                                <thead className={styles.textPrimary}>
                                  <tr>
                                    <th>№ п/п</th>
                                    <th>Тип плательщика</th>
                                    <th>Наименование плательщика</th>
                                    <th>Код/ОКПО плательщика</th>
                                    <th>Страна</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {t.telegramPayerList.map((item, index) => (
                                    <tr key={`payers_${index}`}>
                                      <td>{item.payerNom}</td>
                                      <td>{item.payerTypeName}</td>
                                      <td>{item.payerName}</td>
                                      <td>
                                        {item.payerCode}/{item.payerOkpo}
                                      </td>
                                      <td>{item.payerCountryName}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <br />
                            </div>
                          </div>

                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>
                              Примечание (влияет на провозные платежи):
                            </label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}> {t.rtPrim} </span>
                            </div>
                          </div>

                          <div className={styles.row}>
                            <label className={`${styles.colSm3} ${styles.colMd2} ${styles.colFormLabel}`}>Сумма провозных платежей:</label>
                            <div className={`${styles.colSm9} ${styles.colMd10}`}>
                              <span className={styles.comboEdit}> {t.rtPaySumAll} </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </ScrolledContainer>
        </Layout.Content>
      </>
    );
  }
}

export default observer(EtranTelegramViewer);
