import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, HeaderFilter, Paging, Summary } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';

import { EtranInvoiceDocModel } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';

import EtranInvoceDocsInfoModal from './EtranInvoceDocsInfoModal';

interface Props {}

interface State {
  selected: EtranInvoiceDocModel;
}

@observer
class EtranDocsTable extends Component<Props, State> {
  dataGrid: DataGrid;
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  onAdd = (newData: EtranInvoiceDocModel, oldData: EtranInvoiceDocModel) => {
    etranStore.addDoc(newData, oldData);
    this.dataGrid.instance.refresh();
  };

  onRemove = () => {
    etranStore.removeDoc(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onCopy = () => {
    etranStore.copyDoc(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onUp = () => {
    etranStore.upDoc(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onDown = () => {
    etranStore.downDoc(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  render() {
    const data = etranStore.invoceFormData.docs;
    const selected = this.state.selected;
    return (
      <div className={'etranTable'}>
        <EtranCaption title={'Прилагаемые и предъявляемые документы'} />
        <div className={'etranButtonsGroup'}>
          <EtranInvoceDocsInfoModal onChange={(e) => this.onAdd(e, null)} />
          {selected ? (
            <EtranInvoceDocsInfoModal data={selected} onChange={(e) => this.onAdd(e, selected)} />
          ) : (
            <Button disabled={true}>Изменить</Button>
          )}
          <Button disabled={!selected} onClick={this.onRemove}>
            Удалить
          </Button>
          <Button disabled={!selected} onClick={this.onCopy}>
            Дублировать
          </Button>
          <Button disabled={!selected} onClick={this.onUp} icon={<ArrowUpOutlined />} />
          <Button disabled={!selected} onClick={this.onDown} icon={<ArrowDownOutlined />} />
        </div>
        <DataGrid
          onSelectionChanged={(e) => {
            const key = e.selectedRowsData[0];
            this.setState({ selected: key });
          }}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={false}
          dataSource={data}
          style={{ minHeight: '110px' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          selection={{ mode: 'single' }}>
          <Paging enabled={false} />
          <GroupPanel visible={false} />
          <HeaderFilter visible={false} allowSearch={false} />
          <FilterRow visible={false} />
          {INDEX_COLUMN}
          <Column allowFixing={true} dataField="docType" caption={'Тип документа'} />
          <Column allowFixing={true} dataField="documentId" caption={'Документ'} />
          <Column allowFixing={true} dataField="docNumber" caption={'Номер документа'} />
          <Column allowFixing={true} dataField="docDate" caption={'Увеличение срока доставки (сут)'} />
          <Column allowFixing={true} dataField="modelWag" caption={'Модель вагона'} />

          <Summary></Summary>
        </DataGrid>
      </div>
    );
  }
}

export default EtranDocsTable;
