import Icon from '@ant-design/icons';
import { Button, notification } from 'antd';
import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Column,
  ColumnChooser,
  Export,
  FilterPanel,
  FilterRow,
  GroupItem,
  GroupPanel,
  Grouping,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  StateStoring,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import qs from 'qs';
import React, { Component } from 'react';

import { ReactComponent as filter } from '@assets/icons/filter.svg';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import FilterEditor from '@components/fw/FiltersEditor/FilterEditor';
import NumberFilterModal from '@components/fw/NumbersFilter/NumberFilterModal';
import { MAX_EXCEL_ROWS, MAX_EXCEL_ROWS_FOR_CLIENT_GENERATING } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import filterStore, { FilterContentModel } from '@stores/FilterStore/filterStore';
import { ignoreCaseFilter } from '@stores/dispatcherStore/DispatcherData';
import itineraryStore from '@stores/itineraryStore/itineraryStore';
import { GLOBAL_DATETIME_FORMAT, GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import resultViewStore from '@stores/resultViewStore/resultViewStore';

import { showErrorNotify } from '@utils/notify';

import ItineraryClaimDxCell from './ItineraryClaimDxCell';
import ItineraryContractDxCell from './ItineraryContractDxCell';
import ItineraryExtDxCell from './ItineraryExtDxCell';
import OperationsDataGrid from './OperationsDataGrid';

interface Props {
  type: 'wagons' | 'containers';
  location: any;
}

interface State {
  dataSource: DataSource;
  reloading: boolean;
}

class ItineraryDataGrid extends Component<Props, State> {
  dataGrid: DataGrid;
  ExcelJs: any;
  constructor(props: Props) {
    super(props);
    import('exceljs').then((e) => {
      this.ExcelJs = e;
    });

    this.state = {
      dataSource: this.dataSource,
      reloading: false,
    };
  }

  componentDidMount() {
    const filterId = this.getFiler('filterId');
    itineraryStore.filteredWagons = [];
    if (filterId) {
      this.setState({ reloading: true });
      filterStore
        .setSelected(this.filterType, { filterId: Number(filterId), name: '' })
        .then(() => {})
        .finally(() => this.setState({ reloading: false }));
    } else {
      filterStore.setSelected(this.filterType, null);
    }
  }

  get dataSource() {
    const type = this.props.type;
    return new DataSource({
      store: new CustomStore({
        key: 'itineraryid',
        load: function (loadOptions) {
          return itineraryStore
            .itinerary(loadOptions, type)
            .then((data) => {
              return data;
            })
            .catch(() => {
              throw 'Data Loading Error';
            });
        },
      }),
    });
  }

  getFiler = (name: string) => {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const data = query[name];
    if (!data) return null;
    let target = typeof data == 'object' ? (Object.values(data) as string[]) : [data];
    if (name == 'started') {
      return target.map((t) => moment(t.replaceAll(' ', '+')).toDate());
    }
    return target;
  };

  dateFilter = () => {
    return this.getFiler('started');
  };

  render() {
    const data = this.state.dataSource;
    const type = this.props.type;
    const numberDataField = type == 'wagons' ? 'wagonnumber' : 'containernumber';
    const numberDataType = type == 'wagons' ? 'number' : 'string';
    const groupSummary = resultViewStore.groupSummaryList(this.dataGrid);
    const totalSummary = resultViewStore.totalSummaryList();
    const dateFilter = this.dateFilter();
    if (this.state.reloading) return null;
    return (
      <AdaptiveCard>
        <DataGrid
          allowColumnReordering={true}
          dataSource={data}
          ref={(ref) => (this.dataGrid = ref)}
          style={{ height: 'calc(100vh - var(--header-height) - var(--card-padding)' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          repaintChangesOnly={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          onContextMenuPreparing={(e) => resultViewStore.handleItemContextMenu(e, this.dataGrid)}
          onToolbarPreparing={this.onToolbarPreparing.bind(this)}
          onExporting={this.onExporting}
          selection={{ mode: 'single' }}>
          <StateStoring enabled={true} type="custom" customSave={(e) => this.handleStorageSave(e)} customLoad={this.handleStorageLoad} />
          <FilterPanel visible={true} />
          <Paging enabled={true} defaultPageSize={25} />
          <Pager
            allowedPageSizes={[25, 50, 100]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            infoText={'Всего: {2}'}
          />
          <GroupPanel visible={true} />
          <Grouping contextMenuEnabled={true} />
          <HeaderFilter visible={true} allowSearch={true} />
          <ColumnChooser enabled={true} />

          <Export enabled={true} excelWrapTextEnabled={true} />
          <FilterRow visible={true} />
          <Column
            allowFixing={true}
            dataField={'itineraryid'}
            dataType="string"
            caption={'id'}
            visible={false}
            defaultFilterValues={this.getFiler('id')}
          />

          <Column
            allowFixing={true}
            dataField={numberDataField}
            dataType={numberDataType}
            caption={'Номер'}
            defaultFilterValues={this.getFiler('w')}
          />
          {type === 'wagons' && <Column allowFixing={true} dataField="wagonmodel" dataType="string" caption={'Модель вагона'} />}
          {type === 'wagons' && <Column allowFixing={true} dataField="lukorgluh" dataType="string" caption={'Люк/Глух'} />}
          {type === 'wagons' && <Column allowFixing={true} dataField="taraweight" dataType="number" caption={'Тара'} />}
          {type === 'wagons' && <Column allowFixing={true} dataField="volume" dataType="number" caption={'Объем'} />}
          {type === 'wagons' && <Column allowFixing={true} dataField="lifting" dataType="number" caption={'Грузоподъемность'} />}
          <Column allowFixing={true} dataField="loadstationcode" dataType="number" caption={'Код станции погрузки'} />
          <Column
            allowFixing={true}
            dataField="loadstationname"
            dataType="string"
            caption={'Станция погрузки'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="deststationcode" dataType="number" caption={'Код станции назначения'} />
          <Column
            allowFixing={true}
            dataField="deststationname"
            dataType="string"
            caption={'Станции назначения'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="sendnumber" dataType="string" caption={'Накладная'} />
          <Column allowFixing={true} dataField="etsngcode" dataType="string" caption={'Код груза'} />
          <Column
            allowFixing={true}
            dataField="freightname"
            dataType="string"
            caption={'Груз'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="freightnetto" dataType="number" caption={'Вес груза'}>
            <HeaderFilter groupInterval={10000} />
          </Column>
          <Column
            allowFixing={true}
            dataField="senddate"
            dataType="date"
            caption={'Дата погрузки по накладной'}
            selectedFilterOperation={dateFilter && dateFilter.length ? 'between' : null}
            filterValue={dateFilter}
          />
          <Column allowFixing={true} dataField="ended" dataType="date" caption={'Окончание'} />
          <Column allowFixing={true} dataField="iskpdate" dataType="date" caption={'ИСКП'} />
          <Column allowFixing={true} dataField="pvgudate" dataType="date" caption={'ПВПП'} />
          <Column allowFixing={true} dataField="vigdate" dataType="date" caption={'ВЫГР'} />
          <Column allowFixing={true} dataField="uvgudate" dataType="date" caption={'УВПП'} />

          <Column allowFixing={true} dataField="previskpdate" dataType="date" caption={'ОТПР.ИСКП'} />
          <Column allowFixing={true} dataField="prevpvgudate" dataType="date" caption={'ОТПР.ПВПП'} />
          <Column allowFixing={true} dataField="prevvigdate" dataType="date" caption={'ОТПР.ВЫГР'} />
          <Column allowFixing={true} dataField="prevuvgudate" dataType="date" caption={'ОТПР.УВПП'} />
          <Column allowFixing={true} dataField="receivedate" dataType="date" caption={'Расчетное прибытие'} />

          <Column
            allowFixing={true}
            dataField="newitinerarysenddate"
            dataType="date"
            caption={'Дата оформления по накладной со ст. выгрузки'}
          />

          {type === 'wagons' && (
            <Column allowFixing={true} dataField="loadstationhold" dataType="integer" caption={'Простой на ст. погрузки'} />
          )}
          {type === 'wagons' && (
            <Column allowFixing={true} dataField="deststationhold" dataType="integer" caption={'Простой на ст. назначения'} />
          )}

          {type === 'wagons' && (
            <Column allowFixing={true} dataField="loadstationreceivfactdate" dataType="date" caption={'Дата прибытия на ст. погрузки'} />
          )}

          {type === 'wagons' && (
            <Column allowFixing={true} dataField="loadstationsendfactdate" dataType="date" caption={'Дата отправления со ст. погрузки'} />
          )}

          {type === 'wagons' && (
            <Column allowFixing={true} dataField="deststationreceivefactdate" dataType="date" caption={'Дата прибытия на ст. назначения'} />
          )}

          {type === 'wagons' && (
            <Column
              allowFixing={true}
              dataField="deststationsendivefactdate"
              dataType="date"
              caption={'Дата отправления со ст. назначения'}
            />
          )}

          <Column allowFixing={true} dataField="trainindex" dataType="string" caption={'Индекспоезда'} />
          <Column allowFixing={true} dataField="senderokpo" dataType="string" caption={'ОКПО отправителя'} />
          <Column
            allowFixing={true}
            dataField="sendername"
            dataType="string"
            caption={'Отправитель'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="receiverokpo" dataType="string" caption={'ОКПО получателя'} />
          <Column
            allowFixing={true}
            dataField="receivername"
            dataType="string"
            caption={'Получатель'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          {type === 'wagons' && (
            <Column
              allowFixing={true}
              dataField="operatorname"
              dataType="string"
              caption={'Распорядитель'}
              calculateFilterExpression={ignoreCaseFilter}
            />
          )}

          <Column
            allowFixing={true}
            dataField="claimname"
            dataType="string"
            caption={'Заявка'}
            cellRender={ItineraryClaimDxCell}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column
            allowFixing={true}
            calculateFilterExpression={ignoreCaseFilter}
            dataField="contractid"
            dataType="string"
            caption={'Договор'}
            cellRender={ItineraryContractDxCell}
            allowGrouping={false}
            allowFiltering={false}
          />
          <Column
            allowFixing={true}
            calculateFilterExpression={ignoreCaseFilter}
            dataField="extid"
            dataType="string"
            caption={'Доп. соглашение'}
            cellRender={ItineraryExtDxCell}
            allowGrouping={false}
            allowFiltering={false}
          />
          <Column allowFixing={true} dataField="pricewithnds" dataType="string" caption={'Стоимость (с НДС)'} />
          {type === 'wagons' && <Column allowFixing={true} dataField="hasoversend" dataType="boolean" caption={'Досылка'} />}
          {type === 'wagons' && <Column allowFixing={true} dataField="allsendnumbers" dataType="string" caption={'Все накладные рейса'} />}

          <MasterDetail
            enabled={true}
            component={(e) => {
              return <OperationsDataGrid id={e.data.data.itineraryid} type={type} />;
            }}
          />
          <Summary>
            {groupSummary.map((x, index) => (
              <GroupItem key={`GroupItem_${index}`} {...x} />
            ))}
            {totalSummary.map((x, index) => (
              <TotalItem key={`TotalItem_${index}`} {...x} />
            ))}
          </Summary>
          <Template name={'filter'} render={() => this.filterSelectorTemplate()} />
          <Template name={'numberFilter'} render={() => this.numberFilterButtonTemplate()} />
        </DataGrid>
        <NumberFilterModal
          store={itineraryStore}
          primaryKey={numberDataField}
          onChange={() => {
            this.forceUpdate();
          }}
        />
      </AdaptiveCard>
    );
  }

  get filterType() {
    return this.props.type === 'containers' ? 'itinerary_containers' : 'itinerary_wagons';
  }

  filterSelectorTemplate = () => {
    return <FilterEditor reportType={this.filterType} reportId={null} onChange={(item) => this.hardReload(item)} />;
  };
  numberFilterButtonTemplate = () => {
    const hasFilter = !!itineraryStore.filteredWagons.length || !!itineraryStore.filteredContainers.length;
    return (
      <Button
        title={'Фильтр по списку номеров'}
        type={hasFilter ? 'primary' : 'text'}
        icon={<Icon style={{ color: hasFilter ? null : '#4977e9' }} component={filter} />}
        onClick={() => (itineraryStore.numbersFilterModalVisible = true)}
      />
    );
  };

  hardReload(item: FilterContentModel | null) {
    if (this.dataGrid) {
      if (!item) {
        this.dataGrid.instance.clearFilter();
        this.dataGrid.instance.clearGrouping();
        this.dataGrid.instance.clearSorting();
      }
      this.setState({ reloading: true });
      setTimeout(() => this.setState({ reloading: false }), 1);
    }
  }

  handleStorageSave = (e) => {
    filterStore.setContent(e, this.filterType);
  };

  handleStorageLoad = (e) => {
    return filterStore.getContent(this.filterType);
  };

  onExporting = (e) => {
    const workbook = new this.ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Лист1');
    const name = 'Рейсы';

    if (e.component.totalCount() >= MAX_EXCEL_ROWS) {
      showErrorNotify('Невозможно выгрузить данные', 'Превышено максимально допустимое количество строк MS Exel');
      e.cancel = true;
      return;
    }

    //Сервре не умеет группировку. и если длина таблицы маленькая нет смысла на сервер отправлять. Экспорт выделенного тоже клиентский
    let clientRendering =
      !!e.component.getController('export')._selectionOnly ||
      e.component.totalCount() <= MAX_EXCEL_ROWS_FOR_CLIENT_GENERATING ||
      this.props.type == 'containers';
    if (!clientRendering) {
      for (let i = 0; i < e.component.columnCount(); i++) {
        if (e.component.columnOption(i, 'groupIndex') > -1) {
          clientRendering = true;
          break;
        }
      }
    }

    if (clientRendering) {
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {},
      }).then(function () {
        const date = moment().format(GLOBAL_DATETIME_FORMAT).toString();
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), date + `-Отчёт-${name}.xlsx`);
        });
      });
    } else {
      let columns = [];
      const count = e.component.columnCount();
      let i = 0;
      while (i < count) {
        const opts = e.component.columnOption(i);
        if (opts.visible) {
          columns.push(opts);
        }
        i++;
      }
      columns.sort((a, b) => a.visibleIndex - b.visibleIndex);
      notification.open({
        key: 'progress',
        btn: null,
        message: 'Подготовка файла ...',
        placement: 'bottomRight',
        duration: 0,
      });

      itineraryStore
        .itineraryExport(this.props.type, columns, (e: ProgressEvent) => {
          if (e.lengthComputable) {
            notification.open({
              key: 'progress',
              btn: null,
              message: 'Загрузка файла ' + Math.round((100 * e.loaded) / e.total) + '%',
              placement: 'bottomRight',
              duration: 0,
            });
          }
        })
        .then((buffer) => {
          const date = moment().format(GLOBAL_DATE_FORMAT).toString();
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), date + `-Отчёт-${name}.xlsx`);
        })
        .finally(() => {
          notification.destroy();
        });
    }

    e.cancel = true;
  };
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'numberFilter',
      },
      {
        location: 'after',
        template: 'filter',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.state.dataSource.reload();
          },
        },
      },
    );
  }
}

export default observer(ItineraryDataGrid);
