import { Button, Form, Input, Modal } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import { EtranCarGuideModel } from '@stores/etranStore/EtranInvoiceCarData';

interface Props {
  data?: EtranCarGuideModel;
  onChange(data: EtranCarGuideModel): void;
}

interface State {
  visible: boolean;
  data: EtranCarGuideModel;
}

@observer
class EtranInvoceCarGuideModal extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      data: null,
    };
  }

  handleFormReset = () => {
    this.setState({ data: this.props.data ? this.props.data : null }, () => {
      if (this.formRef) this.formRef.resetFields();
      setTimeout(() => this.forceUpdate(), 200);
    });
  };

  handleConfirm = (e: EtranCarGuideModel) => {
    this.setState({ visible: false });
    this.props.onChange(e);
  };

  onOpen = () => {
    this.setState({ visible: true }, () => {
      this.handleFormReset();
    });
  };

  get isEdit() {
    return !!this.props.data && this.props.data;
  }

  onClose = () => {
    this.handleFormReset();
    this.setState({ visible: false });
  };

  render() {
    const data = this.state.data;
    return (
      <>
        <Button onClick={this.onOpen}>{this.isEdit ? 'Изменить' : 'Добавить'}</Button>
        <Modal
          title="Документы"
          open={this.state.visible}
          onCancel={this.onClose}
          className={'etran-modal'}
          footer={
            <Button
              onClick={() => {
                this.formRef.submit();
              }}>
              {this.isEdit ? 'Изменить' : 'Добавить'}
            </Button>
          }>
          <ScrolledContainer style={{ maxHeight: 'calc(100vh - 265px)', overflowX: 'hidden' }}>
            <Form
              layout="horizontal"
              ref={(ref) => (this.formRef = ref)}
              size={'small'}
              name={'etran-editor'}
              labelCol={{ span: 8 }}
              initialValues={data}
              onFinish={this.handleConfirm}>
              <Form.Item name="guideFio" label={'ФИО'}>
                <Input maxLength={200} />
              </Form.Item>
              <Form.Item name="guidePassportSer" label={'Серия паспорта'}>
                <Input maxLength={5} />
              </Form.Item>
              <Form.Item name="guidePassportNum" label={'№ паспорта'}>
                <Input maxLength={8} />
              </Form.Item>
              <Form.Item name="guideWarrantNum" label={'№ командировочного удостоверения'}>
                <Input maxLength={20} />
              </Form.Item>
            </Form>
          </ScrolledContainer>
        </Modal>
      </>
    );
  }
}

export default EtranInvoceCarGuideModal;
