import { MinusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import dispatcherStore from '@stores/dispatcherStore/dispatcherStore';
import { Container } from '@stores/trackingStore/container/ContainerTypes';
import trackingContainerStore from '@stores/trackingStore/container/trackingContainerStore';
import userStore from '@stores/userStore/userStore';

import { showSuccessNotify } from '@utils/notify';

interface ContainerRemoveProps {
  onDataReady?: () => void;
  forDispatcher?: boolean;
  size?: 'regular' | 'small' | 'hidden';
}

interface ContainerRemoveState {
  visible: boolean;
  containers: Container[];
}

@observer
class ContainerRemove extends Component<ContainerRemoveProps, ContainerRemoveState> {
  constructor(props: ContainerRemoveProps) {
    super(props);

    this.state = {
      visible: false,
      containers: [],
    };
  }

  formRef: FormInstance;

  showModal = () => {
    if (this.props.forDispatcher) {
      this.setState({ containers: dispatcherStore.selected.map((l) => new Container(l)) }, () => {
        if (this.formRef) this.formRef.resetFields();
      });
    }
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: true });
  };

  handleOk = (e) => {
    this.formRef.submit();
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleTexAreaChange(event) {
    event.persist();
    const container: Container[] = [];
    const lines = event.target.value.replace(/\n/g, ' ').split(' ');
    for (let i = 0; i < lines.length; i++) {
      if (lines[i] != '') container.push(new Container(lines[i]));
    }
    this.setState({ containers: container });
  }

  handleSubmit = (event) => {
    if (trackingContainerStore.isBusyAction) return;
    this.postData(this.state.containers);
  };

  postData(data: Container[]) {
    trackingContainerStore
      .remove({
        numbers: data.map((w) => w.number),
      })
      .then(() => {
        this.setState({ visible: false });
        this.props.onDataReady();
        showSuccessNotify('Снятие контейнеров', 'Выбранные контейнеры сняты со слежения');
      });
  }

  render() {
    const isBusy = trackingContainerStore.isBusyAction;
    const containers = this.state.containers;
    const defaultValue = this.props.forDispatcher ? dispatcherStore.selected.join(' ') : '';
    const size = this.props.size ? this.props.size : 'regular';
    const isSmall = size == 'small';
    const isHidden = size == 'hidden';
    if (!userStore.canAccess('billing/orders/containers/edit')) return null;
    return (
      <>
        {!isHidden && (
          <Button
            onClick={this.showModal}
            icon={<MinusOutlined />}
            size={'small'}
            type={isSmall ? 'text' : 'link'}
            className={isSmall ? 'dx-button-mode-contained dx-button' : undefined}
            title={'снять со слежения'}>
            {!isSmall && <>снять</>}
          </Button>
        )}
        <Modal
          okButtonProps={{ loading: isBusy }}
          title="Снятие контейнеров со слежения"
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}>
          {this.state.visible && (
            <Form
              layout={'vertical'}
              ref={(ref) => (this.formRef = ref)}
              onFinish={this.handleSubmit}
              initialValues={{ list: defaultValue }}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item label="Список номеров" name="list" rules={[{ required: true, message: 'Введите контейнеры' }]}>
                    <Input.TextArea rows={4} onChange={this.handleTexAreaChange.bind(this)} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label>Список контейнеров:</label>
                  {containers.map((value, index) => {
                    return (
                      <div key={index} style={!value.isvalid ? { color: 'red' } : {}}>
                        {value.number}
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Form>
          )}
        </Modal>
      </>
    );
  }
}
export default ContainerRemove;
