import { Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import { TariffCountryDetailModel } from '@stores/sendsStore/sendData';

interface FwProps {
  value: TariffCountryDetailModel[];
  deliveryPeriod: number;
  tariffDistance: number;
}

interface ComponentState {}

class OptionsResultViewerGroupTotal extends Component<FwProps, ComponentState> {
  constructor(props: FwProps) {
    super(props);

    this.state = {};
  }

  get total() {
    if (!this.props.value || !this.props.value.length) {
      return null;
    }
    let result: TariffCountryDetailModel = JSON.parse(JSON.stringify(this.props.value[0]));
    result.countryName = 'Итого';
    for (let i = 1; i < this.props.value.length; i++) {
      const current = this.props.value[i];
      if (!current) {
        continue;
      }
      Object.keys(current).forEach((key) => {
        if (typeof current[key] === 'number') {
          if (!result[key]) {
            result[key] = 0;
          }
          result[key] += current[key];
        }
      });
    }
    return result;
  }

  render() {
    const isMobile = designStore.isMobile;
    const totalTable = {
      gridTemplateColumns: '211px auto',
    };
    const deliveryPeriod = this.props.deliveryPeriod;
    const tariffDistance = this.props.tariffDistance;
    const data = this.props.value;
    const total = this.total;
    const globalCurrency = optionsStore.selectedCurrency;
    if (!data.length) {
      return <div>Нет данных</div>;
    }

    return (
      <div className={'verticalTable'}>
        <div className={'total'}>
          {!globalCurrency.variable && (
            <div className={'totalTable'} style={totalTable}>
              <div>
                <div>
                  <span className={'caption'}>Итого</span>
                </div>
                <div>Общее расстояние</div>
                <div>Срок доставки</div>
                <div>Провозная плата без НДС</div>
                <div>НДС</div>
                <div>с НДС</div>
              </div>
              <div>
                <div> </div>
                <div>{tariffDistance} км</div>
                <div>{deliveryPeriod ? ' ' + deliveryPeriod + ' дней' : ''}</div>
                <div className={'totalNumber'}>
                  {total['freightCharge' + globalCurrency.filedPostfix]?.toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                  <Tooltip title={globalCurrency.name}>
                    <span>{globalCurrency.nameFiled}</span>
                  </Tooltip>
                </div>
                <div className={'totalNumber'}>
                  {total['freightCharge' + globalCurrency.filedPostfix + 'Vat']?.toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                  <Tooltip title={globalCurrency.name}>
                    <span>{globalCurrency.nameFiled}</span>
                  </Tooltip>
                </div>
                <div className={'totalNumber'}>
                  {total['freightCharge' + globalCurrency.filedPostfix + 'WithVat']?.toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                  <Tooltip title={globalCurrency.name}>
                    <span>{globalCurrency.nameFiled}</span>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
          {globalCurrency.filedPostfix !== 'Ru' && !!globalCurrency.variable && (
            <div className={'totalTable'} style={totalTable}>
              <div>
                <div>
                  <span className={'caption'}>Итого</span>
                </div>
                <div>Общее расстояние</div>
                <div>Срок доставки</div>
                <div>Провозная плата без НДС</div>
                <div>НДС</div>
                <div>с НДС</div>
              </div>
              <div>
                <div style={{ fontWeight: 'bold', textAlign: 'right' }}>{tariffDistance}</div>
                <div>{deliveryPeriod ? 'Срок доставки ' + deliveryPeriod + ' дней' : ''}</div>
                <div className={'totalNumber'}>
                  {total.freightChargeRu.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                  <Tooltip title={'Российский рубль'}>
                    <span>₽</span>
                  </Tooltip>
                </div>
                <div className={'totalNumber'}>
                  {total.freightChargeRuVat.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                  <Tooltip title={'Российский рубль'}>
                    <span>₽</span>
                  </Tooltip>
                </div>
                <div className={'totalNumber'}>
                  {total.freightChargeRuWithVat.toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                  <Tooltip title={'Российский рубль'}>
                    <span>₽</span>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default observer(OptionsResultViewerGroupTotal);
