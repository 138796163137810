export declare type InputPosition =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'middle'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'left'
  | 'right'
  | undefined;

export const positionToBorderRadius = (position: InputPosition) => {
  switch (position) {
    case 'top':
      return '5px 5px 0 0';
    case 'top-left':
      return '5px 0 0 0';
    case 'top-right':
      return '0 5px 0 0';
    case 'middle':
      return '0 0 0 0';
    case 'bottom':
      return '0 0 5px 5px';
    case 'bottom-left':
      return '0 0 0 5px';
    case 'bottom-right':
      return '0 0 5px 0';
    case 'left':
      return '5px 0 0 5px';
    case 'right':
      return '0 5px 5px 0';
    default:
      return '5px';
  }
};
