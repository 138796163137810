import HttpClient from '@utils/httpClient';

import { DocTypeTemplateModel } from './ContractTemplatesData';

const service = {
  async getTemplates(context: string | null): Promise<Array<DocTypeTemplateModel>> {
    return (await HttpClient.get(`/api/docTypeTemplates/list${context ? `?context=${context}` : ''}`)).data;
  },
  async createOrUpdateTemplate(template: DocTypeTemplateModel): Promise<Array<DocTypeTemplateModel>> {
    return await HttpClient.post('/api/docTypeTemplates/createOrUpdate', template);
  },
  async deleteTemplate(templateId: string): Promise<Array<DocTypeTemplateModel>> {
    return (await HttpClient.get(`/api/docTypeTemplates/delete?templateId=${templateId}`)).data;
  },
};
export default service;
