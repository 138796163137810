import moment from 'moment';

import { EtranStationDictionaryInfo, EtsngFreightServiceModel, GngFreightServiceModel } from './EtranDictData';
import { EtranInvoiceCarInfo } from './EtranInvoiceCarData';

export interface EtranOperationSuccessInfo {
  processed: boolean;
  errorMessage: string;
}

export interface EtranInvoiceInfo {
  invDateCustom?: any;
  invDateExpire?: any;
  invDateLoad?: any;
  invFactDateToLoad?: any;
  invFactDateToLoadLocal?: any;
  invRtId?: Date;
  cars: EtranInvoiceCarInfo[];
  devs: EtranInvoiceDev[];
  distances: EtranInvoiceDistance[];
  docs: EtranInvoiceDocModel[];
  dues: EtranInvoiceDueModel;
  fasteners: EtranInvoiceFastenerInfo;
  freights: EtranInvoiceFreight[];
  specialMarks: EtranInvoiceSpcModel[];
  wayCls: EtranWayClsModel;
  type: InvoiceType;
  invoiceId: number;
  invAnnounceValue?: number;
  invBlankType?: number;
  invBlankTypeId?: number;
  invClaimId?: number;
  invContPlanSizeBig?: number;
  invDepNormDocId?: number;
  invDispKindId?: number;
  invFromPortCode?: number;
  invIxTariffCode?: number;
  invIxTariffCodeGdy?: number;
  invLoadAssetId?: number;
  invLoadClaimId?: number;
  invLoadTypeId?: number;
  invOtprNum?: number;
  invPayFormId?: number;
  invPayPlaceId?: number;
  invPayerCode?: number;
  invPlanCarCount?: number;
  invPlanCarOwnerTypeId?: number;
  invPlanCarTypeCode?: number;
  invPlanCarTypeId?: number;
  invPlanContCount?: number;
  invPlanContOwnerTypeId?: number;
  invPlanContTonnage?: number;
  invPodNum?: number;
  invRecipAddressId?: number;
  invRecipId?: number;
  invScalePersonId?: number;
  invScaleTypeId?: number;
  invSendKindId?: number;
  invSendSpeedId?: number;
  invSenderAddressId?: number;
  invSenderId?: number;
  invToPortCode?: number;
  invTypeId?: number;
  fromStationParagraphs: string;
  invBlankTypeName: string;
  invClaimNumber: string;
  invDepCond: string;
  invDepNormDocName: string;
  invDeplPerson: string;
  invDispKind: string;
  invFioAccept: string;
  invFromCountryCode: string;
  invFromCountryName: string;
  invFromLoadWay: string;
  invFromPointCode: string;
  invFromPointName: string;
  invFromStationCode: string;
  invFromStationName: string;
  invFrwsubCode: string;
  invFrwsubCode2: string;
  invLoadAssetName: string;
  invLoadClaimNumber: string;
  invLoadTypeName: string;
  invNumber: string;
  invPayFormName: string;
  invPayPlaceName: string;
  invPayPlaceRwCode: string;
  invPayerAccount: string;
  invPayerBank: string;
  invPayerName: string;
  invPlanCarOwnerTypeName: string;
  invPlanCarTypeName: string;
  invPlanOutsizeCode: string;
  invRecipAddress: string;
  invRecipName: string;
  invRecipOkpo: string;
  invRecipTgnl: string;
  invRespPerson: string;
  invScalePersonName: string;
  invScaleType: string;
  invSendKindName: string;
  invSendSpeedName: string;
  invSenderAddress: string;
  invSenderName: string;
  invSenderOkpo: string;
  invSenderTgnl: string;
  invToCountryCode: string;
  invToCountryEmptyCode: string;
  invToCountryName: string;
  invToLoadWay: string;
  invToPointCode: string;
  invToPointName: string;
  invToStationCode: string;
  invToStationEmptyCode: string;
  invToStationEmptyName: string;
  invToStationName: string;
  invTypeName: string;
  invVizaNumber: string;
  invVizaNumberFull: string;
  toStationParagraphs: string;
}

export const emptyEtranInvoice: EtranInvoiceForm = {
  invDateCustom: null,
  invDateExpire: null,
  invDateLoad: null,
  invFactDateToLoad: null,
  invFactDateToLoadLocal: null,
  invRtId: null,
  cars: [],
  devs: [],
  distances: [],
  docs: [],
  dues: null,
  fasteners: null,
  freights: [],
  specialMarks: [],
  wayCls: null,
  type: null,
  invoiceId: null,
  invAnnounceValue: null,
  invBlankType: null,
  invBlankTypeId: 182,
  invClaimId: null,
  invContPlanSizeBig: null,
  invDepNormDocId: null,
  invDispKindId: null,
  invFromPortCode: null,
  invIxTariffCode: null,
  invIxTariffCodeGdy: null,
  invLoadAssetId: null,
  invLoadClaimId: null,
  invLoadTypeId: null,
  invOtprNum: null,
  invPayFormId: null,
  invPayPlaceId: null,
  invPayerCode: null,
  invPlanCarCount: null,
  invPlanCarOwnerTypeId: null,
  invPlanCarTypeCode: null,
  invPlanCarTypeId: null,
  invPlanContCount: null,
  invPlanContOwnerTypeId: null,
  invPlanContTonnage: null,
  invPodNum: null,
  invRecipAddressId: null,
  invRecipId: null,
  invScalePersonId: null,
  invScaleTypeId: null,
  invSendKindId: null,
  invSendSpeedId: null,
  invSenderAddressId: null,
  invSenderId: null,
  invToPortCode: null,
  invTypeId: null,
  fromStationParagraphs: null,
  invBlankTypeName: null,
  invClaimNumber: null,
  invDepCond: null,
  invDepNormDocName: null,
  invDeplPerson: null,
  invDispKind: null,
  invFioAccept: null,
  invFromCountryCode: '643',
  invFromCountryName: null,
  invFromLoadWay: null,
  invFromPointCode: null,
  invFromPointName: null,
  invFromStationCode: null,
  invFromStationName: null,
  invFrwsubCode: null,
  invFrwsubCode2: null,
  invLoadAssetName: null,
  invLoadClaimNumber: null,
  invLoadTypeName: null,
  invNumber: null,
  invPayFormName: null,
  invPayPlaceName: null,
  invPayPlaceRwCode: null,
  invPayerAccount: null,
  invPayerBank: null,
  invPayerName: null,
  invPlanCarOwnerTypeName: null,
  invPlanCarTypeName: null,
  invPlanOutsizeCode: null,
  invRecipAddress: null,
  invRecipName: null,
  invRecipOkpo: null,
  invRecipTgnl: null,
  invRespPerson: null,
  invScalePersonName: null,
  invScaleType: null,
  invSendKindName: null,
  invSendSpeedName: null,
  invSenderAddress: null,
  invSenderName: null,
  invSenderOkpo: null,
  invSenderTgnl: null,
  invToCountryCode: '643',
  invToCountryEmptyCode: '643',
  invToCountryName: null,
  invToLoadWay: null,
  invToPointCode: null,
  invToPointName: null,
  invToStationCode: null,
  invToStationEmptyCode: null,
  invToStationEmptyName: null,
  invToStationName: null,
  invTypeName: null,
  invVizaNumber: null,
  invVizaNumberFull: null,
  toStationParagraphs: null,
  invSender: null,
  invRecip: null,
  invFromStation: null,
  invToStation: null,
  invPayer: null,
};

export interface EtranInvoiceDev {
  /**Уникальный идентификатор */
  devId: number | null;
  /**Тип перевозочного приспособления Id */
  devTypeId: number | null;
  /**Тип перевозочного приспособления */
  devTypeName: string | null;
  /**Номер */
  devNumber: string;
  /**Страна-собственник code */
  devOwnerCountryCode: string;
  /**Страна-собственник */
  devOwnerCountryName: string;
  /**Тип собственности Id */
  devOwnerTypeId: number;
  /**Тип собственности */
  devOwnerTypeName: string;
  /**Собственник okpo */
  devOwnerOkpo: string;
  /**Собственник */
  devOwnerName: string;
  /**Количество */
  devQuantity: number | null;
  /**Возврат */
  devReturn: number | null;

  devReturnBool?: boolean;
}

export const emptyEtranInvoiceDev: EtranInvoiceDev = {
  /**Уникальный идентификатор */
  devId: null,
  /**Тип перевозочного приспособления Id */
  devTypeId: null,
  /**Тип перевозочного приспособления */
  devTypeName: null,
  /**Номер */
  devNumber: null,
  /**Страна-собственник code */
  devOwnerCountryCode: null,
  /**Страна-собственник */
  devOwnerCountryName: null,
  /**Тип собственности Id */
  devOwnerTypeId: null,
  /**Тип собственности */
  devOwnerTypeName: null,
  /**Собственник okpo */
  devOwnerOkpo: null,
  /**Собственник */
  devOwnerName: null,
  /**Количество */
  devQuantity: null,
  /**Возврат */
  devReturn: null,
};

export interface EtranInvoiceDistance {
  /**Уникальный идентификатор */
  distanceId: number;
  /**Код страны инфраструктуры */
  distCountryCode: number | null;
  /**Идентификатор страны инфраструктуры станции */
  distStationCountryId: string;
  /**Наименование страны инфраструктуры */
  distCountryName: string;
  /**Тарифная станция */
  distStationCode: string;
  /**Станция */
  distStationName: string;
  /**Код порта */
  distPortCode: number | null;
  /**Порт */
  distPortName: string;
  /**Подъездной путь */
  distLoadWay: string;
  /**Кратчайшее расстояние до следующей узловой станции */
  distMinWay: number | null;
  /**Грузополучатель okpo */
  distRecipOkpo: string;
  /**Грузополучатель имя */
  distRecipName: string;
  /**Грузополучатель адрес */
  distRecipAddress: string;
  /**Плательщик Id */
  distPayerId: number | null;
  /**Плательщик Code */
  distPayerCode: string;
  /**Плательщик на предыдущем отрезке */
  distPayerName: string;
  /**Подкод экспедитора */
  distFrwsubCode: string;
  /**Признаки */
  distSign: string;
  /**Идентификатор фактического перевозчика */
  distCarrierId: number | null;
  /**Признак электрификации участка */
  distPowerKind: number | null;
  /**Вид транспорта Id */
  distTranspTypeId: number | null;
  /**Вид транспорта */
  distTranspTypeName: string;
  /**Код колеи Id */
  distTrackTypeId: number | null;
  /**Код колеи */
  distTrackTypeName: string;
  /**Порядковый номер следования в таблице */
  indexNumber: number | null;
  /**Дорога  */
  distPayPlaceRwId: number | null;
  /**Сокращенное наименование дороги */
  railwayShortName: string;
  /**Плательщик по СНГ – идентификатор (EXPED_SNG_ID по НСИ EXPED_SNG) */
  distSngExpedId: string;
  /**Плательщик по СНГ - наименование */
  distSngExpedName: string;
}

export const emptyEtranInvoiceDistance: EtranInvoiceDistance = {
  /**Уникальный идентификатор */
  distanceId: null,
  /**Код страны инфраструктуры */
  distCountryCode: null,
  /**Идентификатор страны инфраструктуры станции */
  distStationCountryId: null,
  /**Наименование страны инфраструктуры */
  distCountryName: null,
  /**Тарифная станция */
  distStationCode: null,
  /**Станция */
  distStationName: null,
  /**Код порта */
  distPortCode: null,
  /**Порт */
  distPortName: null,
  /**Подъездной путь */
  distLoadWay: null,
  /**Кратчайшее расстояние до следующей узловой станции */
  distMinWay: null,
  /**Грузополучатель okpo */
  distRecipOkpo: null,
  /**Грузополучатель имя */
  distRecipName: null,
  /**Грузополучатель адрес */
  distRecipAddress: null,
  /**Плательщик Id */
  distPayerId: null,
  /**Плательщик Code */
  distPayerCode: null,
  /**Плательщик на предыдущем отрезке */
  distPayerName: null,
  /**Подкод экспедитора */
  distFrwsubCode: null,
  /**Признаки */
  distSign: null,
  /**Идентификатор фактического перевозчика */
  distCarrierId: null,
  /**Признак электрификации участка */
  distPowerKind: null,
  /**Вид транспорта Id */
  distTranspTypeId: null,
  /**Вид транспорта */
  distTranspTypeName: null,
  /**Код колеи Id */
  distTrackTypeId: null,
  /**Код колеи */
  distTrackTypeName: null,
  /**Порядковый номер следования в таблице */
  indexNumber: null,
  /**Дорога  */
  distPayPlaceRwId: null,
  /**Сокращенное наименование дороги */
  railwayShortName: null,
  /**Плательщик по СНГ – идентификатор (EXPED_SNG_ID по НСИ EXPED_SNG) */
  distSngExpedId: null,
  /**Плательщик по СНГ - наименование */
  distSngExpedName: null,
};

export interface EtranInvoiceDueModel {}
export interface EtranInvoiceFastenerInfo {}

export interface EtranFreightClsModel {
  /// Отметка
  clsTranspClauseId: number;
  /// Наименование отметки
  clsTranspClauseName: string;
  /// Замечание
  clsCustomText: string;
}

export const emptyEtranInvoiceFreight: EtranInvoiceFreight = {
  specialMarks: [],
  freightWeightStdGross: null,
  freightWeightStdNet: null,
  freightId: null,
  freightAccidentCard: null,
  freightAccidentCardId: null,
  freightBundling: null,
  freightDangerId: null,
  freightDangerSignId: 1,
  freightGngId: null,
  freightGtdPosition: null,
  freightMaxHeight: null,
  freightMaxLength: null,
  freightMaxWidth: null,
  freightPackTypeId: null,
  freightPackageCount: null,
  freightWeight: null,
  freightAdditional: null,
  freightCode: null,
  freightDangerSignName: null,
  freightExactName: null,
  freightGngCode: null,
  freightGngName: null,
  freightGtdNumber: null,
  freightPackTypeName: null,
  freightPocketCount: null,
  freightSendersMark: null,
};

export interface EtranInvoiceFreight {
  /// Уникальный идентификатор
  freightId: string;
  /// Код груза
  freightCode: string;
  /// Груз (наименование)
  freightExactName: string;
  /// Упаковка Id
  freightPackTypeId: number;
  /// Количество пакетов
  freightPocketCount: number;
  /// Упаковка
  freightPackTypeName: string;
  /// Количество мест
  freightPackageCount?: string;
  /// Планируемая масса груза
  freightWeight?: number;
  /// Масса места брутто
  freightWeightStdGross?: number;
  /// Масса места нетто
  freightWeightStdNet?: number;
  /// Максимальные габариты - длина
  freightMaxLength?: number;
  /// Максимальные габариты - ширина
  freightMaxWidth?: number;
  /// Максимальные габариты - высота
  freightMaxHeight?: number;
  /// Id Груза по ГНГ
  freightGngId?: number;
  /// Код Груза по ГНГ
  freightGngCode: string;
  /// Дополнительные сведения о грузе
  freightAdditional: string;
  /// Имя груза по ГНГ
  freightGngName?: string;
  /// Марка отправителя
  freightSendersMark?: string;
  /// Id Аварийность груза
  freightDangerSignId: number;
  /// Аварийный груз
  freightDangerId: number;
  /// Аварийная карта
  freightAccidentCard?: number;
  /// Аварийная карта
  freightAccidentCardId?: number;
  /// Номер декларации на товар
  freightGtdNumber?: string;
  /// Номер позиции груза в декларации
  freightGtdPosition?: number;
  /// Средство пакетирования
  freightBundling?: number;
  /// Аварийность груза
  freightDangerSignName?: string;
  /// Специальные отметки
  specialMarks: EtranFreightClsModel[];
  /// Объём груза
  freightVolume?: number;
  ///  Вид пробега локомотива – только для груза ЕТСНГ 422060 – ID по НСИ COMMON для paramid=971
  freightLocRunType?: number;
  /// Вид пробега локомотива – только для груза ЕТСНГ 422060 – наименовани
  freightLocRunTypeName?: string;
  /// Признак сцепа. 1 – Да, 0 – Нет.
  freightLinked?: string;
}
export interface EtranInvoiceFreightForm extends EtranInvoiceFreight {
  freightGngObj: GngFreightServiceModel;
  freightEtsngObj: EtsngFreightServiceModel;
}

export const etranInvoiceFreightFormToModel = (e: EtranInvoiceFreightForm) => {
  let result: EtranInvoiceFreight = e;
  if (e.freightGngObj) {
    result.freightGngCode = e.freightGngObj.code;
    result.freightGngName = e.freightGngObj.name;
  }
  if (e.freightEtsngObj) {
    result.freightCode = e.freightEtsngObj.code;
    result.freightExactName = e.freightEtsngObj.name;
  }
  return result;
};

export const etranInvoiceFreightModelToForm = (e: EtranInvoiceFreight) => {
  const result: EtranInvoiceFreightForm = e as EtranInvoiceFreightForm;
  if (e.freightGngCode) {
    result.freightGngObj = { code: e.freightGngCode, name: e.freightGngName, oldCode: e.freightGngId?.toString() };
  }
  if (e.freightCode) {
    result.freightEtsngObj = { code: e.freightCode, name: e.freightExactName, shortName: null };
  }
  return result;
};

export interface EtranInvoiceSpcModel {
  /// Уникальный идентификатор
  spcId?: number;
  /// Идентификатор накладной
  invoiceId?: number;
  /// Отметка id
  spcTranspClauseId?: number;
  /// Отметка
  spcTranspClauseDesc: string;
  /// Замечание
  spcCustomText: any;
  /// Номер договора на ремонт/промывку, пропарку, ветеринарно-санитарную обработку
  spcNumber: string;
  /// Датавыдачи
  spcDate: any;
  /// Идентификатор Заявки на привлечение вагонов – поле DocID из getWagReqReply (для spcTranspClause_ID IN (1304, 1325))
  spcWrDocId: number;
  /// Идентификатор графика подачи Заявки на привлечение вагонов – поле WSID из getWagReqReply (для spcTranspClause_ID IN (1304, 1325))
  spcWsId: number;
  //Номер заявки на отстой (для spcTranspClause_ID=1143) тип данных: строка (до 255 символов)
  spcClaimNumber: string;
  //Номер отправки заявки на отстой тип данных: целое число (до 10 знаков)
  spcWNNumLoad: number;

  transpClauseTypeName: string;
}

export interface EtranInvoiceDocModel {
  /**Идентификатор согласия владельца жд. ПНП на отстой, для TRANSPCLAUSE_ID = 1338 */
  documentId: number | null;
  /**Тип докуменда Id */
  docTypeId: number | null;
  /**Тип */
  docType: string;
  /**Номер документа */
  docNumber: string;
  /**Дата выдачи или Срок действия договора для договора на увеличение срока доставки */
  docDate: Date | string | null;
  /**Cрок транспортабельности или Количество дополнительных суток для договора на увеличение срока доставки */
  dayCount: number | null;
  /**Модель вагона */
  modelWag: string;
  /**Идентификатор для электронных ветеринарных сопроводительных документов */
  docVet: number | null;
  /**Номер накладной */
  invoiceId: number | null;
}

export const emptyEtranInvoiceDocModel: EtranInvoiceDocModel = {
  /**Идентификатор согласия владельца жд. ПНП на отстой, для TRANSPCLAUSE_ID = 1338 */
  documentId: null,
  /**Тип докуменда Id */
  docTypeId: null,
  /**Тип */
  docType: null,
  /**Номер документа */
  docNumber: null,
  /**Дата выдачи или Срок действия договора для договора на увеличение срока доставки */
  docDate: null,
  /**Cрок транспортабельности или Количество дополнительных суток для договора на увеличение срока доставки */
  dayCount: null,
  /**Модель вагона */
  modelWag: null,
  /**Идентификатор для электронных ветеринарных сопроводительных документов */
  docVet: null,
  /**Номер накладной */
  invoiceId: null,
};

export interface EtranWayClsModel {}
export interface InvoiceType {}

export interface EtranOrganizationPassportInfo {
  /// ID организации
  orgId: number;
  ///  Код ОКПО организации
  orgOkpo: string;
  /// ИНН организации
  orgInn?: string;
  /// Код причины постановки на учет в налоговой
  orgKpp?: string;
  /// Название организации
  orgName: string;
  /// Сокращенное название организации
  orgShortName?: string;
  /// Тип организации/департамента
  orgType?: string;
  /// Адреса
  addresses?: EtranOrganizationAddressModel[];
}

export interface EtranOrganizationAddressModel {
  /// ID адреса
  addressId: number;

  /// Тип адреса (0-юридический, 1-физический)
  addressType?: number;

  /// Адрес
  addressText: string;

  /// Код ТГНЛ
  addressTgnl: string;

  /// Почтовый индекс
  addressPostInd?: string;

  /// Страна
  adrCountry?: number;

  /// Код региона
  adrRegion?: number;

  /// Признак нас. пункта (1-Город, 2-посёлок, 3-деревня)
  adrCityType?: number;

  /// Город, населённый пункт
  adrCity?: string;

  /// Улица
  adrStreet?: string;

  /// Дом/корпус
  adrBuilding?: string;

  /// Квартира/офис
  adrFlat?: string;

  /// Примечания, не вошедшие в адрес
  adrNotes?: string;

  /// Номер телефона
  adrPhone?: string;

  orgId?: string;
}

export interface EtranOrganizationPayerModel {
  /**Идентификатор организации */
  orgId: number;

  /**ID железной дороги */
  rwId: number;

  /**Код дороги */
  rwCode: string;

  /**Наименование дороги */
  rwName: string;

  /**Тип плательщика */
  payerTypeId: number;

  /**Наименование типа плательщика */
  payerTypeName: string;

  /**Код плательщика */
  payerCode: string;

  /**ОКПО плательщика */
  payerOkpo: string;

  /**Наименование плательщика */
  payerName: string;

  /**Идентификатор опорной станции */
  stId: number;

  /**Наименование опорной станции */
  stName: string;
}

export interface EtranInvoiceForm extends EtranInvoiceInfo {
  invSender: EtranOrganizationPassportInfo;
  invRecip: EtranOrganizationPassportInfo;
  invFromStation: EtranStationDictionaryInfo;
  invToStation: EtranStationDictionaryInfo;
  invPayer: EtranOrganizationPayerModel;
}

export const etranIvoceInfoToFormData = (data: any): EtranInvoiceForm => {
  const result = data as EtranInvoiceForm;
  result.invSender = {
    orgId: result.invSenderId,
    orgOkpo: result.invSenderOkpo,
    orgName: result.invSenderName,
    addresses: [{ addressId: result.invSenderAddressId, addressText: result.invSenderAddress, addressTgnl: result.invSenderTgnl }],
  };

  result.invRecip = {
    orgId: result.invRecipId,
    orgOkpo: result.invRecipOkpo,
    orgName: result.invRecipName,
    addresses: [{ addressId: result.invRecipAddressId, addressText: result.invRecipAddress, addressTgnl: result.invRecipTgnl }],
  };

  result.invFromStation = {
    rwCode: null,
    stCode: result.invFromStationCode,
    stName: result.invFromStationName,
    fromStationParagraphs: result.fromStationParagraphs,
  };

  result.invToStation = {
    rwCode: null,
    stCode: result.invToStationCode,
    stName: result.invToStationName,
    fromStationParagraphs: result.toStationParagraphs,
  };

  result.invPayer = {
    //@ts-ignore
    orgId: result.invPayerAccount,
    rwId: null,
    rwCode: null,
    rwName: null,
    payerTypeId: null,
    payerTypeName: null,
    //@ts-ignore
    payerCode: result.invPayerCode, //#TODO для теста так закасытлил. потом поправить
    payerName: result.invPayerName,
    stId: null,
    stName: null,
    payerOkpo: null, //#TODO это поле некуда положить но выводить надо
  };
  if (result.invFactDateToLoadLocal) result.invFactDateToLoadLocal = moment(result.invFactDateToLoadLocal);
  if (result.invDateCustom) result.invDateCustom = moment(result.invDateCustom);
  if (result.invDateExpire) result.invDateExpire = moment(result.invDateExpire);
  if (result.invDateLoad) result.invDateLoad = moment(result.invDateLoad);
  if (result.invFactDateToLoad) result.invFactDateToLoad = moment(result.invFactDateToLoad);

  return result;
};

export const etranIvoceFormDataToInfo = (data: EtranInvoiceForm): EtranInvoiceInfo => {
  const result = data as EtranInvoiceInfo;
  if (data.invSender) {
    result.invSenderId = data.invSender.orgId;
    result.invSenderOkpo = data.invSender.orgOkpo;
    result.invSenderName = data.invSender.orgName;
    const address = data.invSender.addresses[0];
    if (address) {
      result.invSenderAddressId = address.addressId;
      result.invSenderAddress = address.addressText;
      result.invSenderTgnl = address.addressTgnl;
    }
  }

  if (data.invRecip) {
    result.invRecipId = data.invRecip.orgId;
    result.invRecipOkpo = data.invRecip.orgOkpo;
    result.invRecipName = data.invRecip.orgName;
    const address = data.invRecip.addresses[0];
    if (address) {
      result.invRecipAddressId = address.addressId;
      result.invRecipAddress = address.addressText;
      result.invRecipTgnl = address.addressTgnl;
    }
  }
  if (data.invFromStation) {
    result.invFromStationCode = data.invFromStation.stCode;
    result.invFromStationName = data.invFromStation.stName;
    result.fromStationParagraphs = data.invFromStation.fromStationParagraphs;
  }
  if (data.invToStation) {
    result.invToStationCode = data.invToStation.stCode;
    result.invToStationName = data.invToStation.stName;
    result.toStationParagraphs = data.invToStation.fromStationParagraphs;
  }
  if (data.invPayer) {
    //@ts-ignore
    result.invPayerCode = data.invPayer.payerCode;
    result.invPayerName = data.invPayer.payerName;
  }

  const localDateFormat = 'YYYY-MM-DDTHH:mm:ss';
  if (result.invFactDateToLoadLocal) result.invFactDateToLoadLocal = moment(result.invFactDateToLoadLocal).format(localDateFormat);
  if (result.invDateCustom) result.invDateCustom = moment(result.invDateCustom).toISOString(true);
  if (result.invDateExpire) result.invDateExpire = moment(result.invDateExpire).toISOString(true);
  if (result.invDateLoad) result.invDateLoad = moment(result.invDateLoad).toISOString(true);
  if (result.invFactDateToLoad) result.invFactDateToLoad = moment(result.invFactDateToLoad).format(localDateFormat);

  return result;
};

export interface EtranTelegramInfo {
  /**Идентификатор */
  rtId: number;

  /**Системный номер телеграммы  */
  rtNumber: number | null;

  /**Идентификатор скорректированной (родительской) телеграммы */
  rtParentId: number | null;

  /**Номер скорректированной (родительской) телеграммы */
  rtParentNumber: string;

  /**Состояние РТ Id */
  rtStateId: number | null;

  /**Состояние РТ */
  rtState: string;

  /**Дата и время регистрации РТ в АРМ */
  rtRegDate: Date | string | null;

  /**Дата последней операции по РТ */
  rtLastOper: Date | string | null;

  /**Дата и время создания РТ в АС ЭТРАН */
  rtDateCreate: Date | string | null;

  /**Дата начала действия перевозки */
  rtStartDate: Date | string | null;

  /**Дата окончания перевозки */
  rtFinishDate: Date | string | null;

  /**7-значный код или 10-значный код ЕЛС экспедитора */
  rtPayerCode: number | null;

  /**Id Экспедитора */
  rtPayerId: number | null;

  /**ОКПО экспедитора */
  rtPayerOkpo: string;

  /**Экпедитор */
  rtPayerName: string;

  /**Идентификатор Грузоотправителя в ПУЖТ */
  rtSenderId: number | null;

  /**ОКПО Грузоотправителя */
  rtSenderOkpo: string;

  /**Наименование Грузоотправителя */
  rtSenderName: string;

  /**Идентификатор Вида сообщения */
  rtMsgKindId: number | null;

  /**Вид сообщения */
  rtMsgKindName: string;

  /**Страна отправления код */
  rtFromCountryCode: number | null;

  /**Страна отправления  */
  rtFromCountryName: string;

  /**Станция отправления код */
  rtFromStationCode: string | null;

  /**Станция отправления */
  rtFromStationName: string;

  /**Станция входа в РФ код */
  rtEnterStationCode: string | null;

  /**Станция входа в РФ */
  rtEnterStationName: string;

  /**Станция входа СНГ код */
  rtEnterSngStationCode: string | null;

  /**Станция входа СНГ */
  rtEnterSngStationName: string; //  */

  /**/ rtRegNum: number | null;

  /**Номер телеграммы экспедитора */
  rtNomExp: number | null;

  /**Подписанный номер разрешающей телеграммы */
  rtNomStr: number | null;

  /**Примечание (влияет на провозные платежи) */
  rtPrim: string;

  /**Сумма провозных платежей */
  rtPaySumAll: number | null;

  /**Дата обработки телеграммы исполнителем */
  agrDate: Date | string | null;

  /**Должность исполнителя */
  agrPost: string;

  /**ФИО исполнителя */
  agrPerson: string;

  /**Идентификатор организации исполнителя (НСИ ПУЖТ) */
  agrOrgId: number | null;

  /**Наименование организации исполнителя */
  agrOrgName: string;

  /**Отправки */
  telegramOtprList: EtranTelegramOtprModel[];

  /**Плательщики */
  telegramPayerList: EtranTelegramPayerModel[];
}

export interface EtranTelegramOtprModel {
  /**Номер отправки по порядку */
  otprNom: number;

  /**Подкод экспедитора */
  otprSubCodeExp: string;

  /**Кит */
  otprIxTariffCode: string;

  /**Код груза ЕТСНГ */
  otprFreightCode: string;

  /**Наименование груза */
  otprFreightName: string;

  /**Код груза ГНГ */
  otprFreightGngId: number | null;

  /**Код груза по ГНГ */
  otprFreightGngCode: string;

  /**Наименование груза по ГНГ */
  otprFreightGngName: string;

  /**Код страны назначения */
  otprToCountryCode: string;

  /**Страна назначения */
  otprToCountryName: string;

  /**Код станции назначения */
  otprToStationCode: string;

  /**Станция назначения */
  otprToStationName: string;

  /**Идентификатор области назначения */
  otprOblNaznId: number | null;

  /**Наименование области назначения */
  otprOblNaznName: string;

  /**Станция выхода из РФ код */
  otprExitStationCode: string | null;

  /**Станция выхода из РФ */
  otprExitStationName: string;

  /**Код Станции выхода из СНГ */
  otprExitSngStationCode: string;

  /**Наименование станции выхода из СНГ */
  otprExitSngStationName: string;

  /**Идентификатор грузополучателя */
  otprRecipId: number | null;

  /**ОКПО грузополучателя */
  otprRecipOkpo: string;

  /**Наименование грузополучателя */
  otprRecipName: string;

  /**Идентификатор типа собственности вагонов */
  otprCarOwnerTypeId: number | null;

  /**Наименование типа собственности вагонов */
  otprCarOwnerTypeName: string;

  /**Принадлежность вагонов Id */
  otprCarContOwnerTypeId: number | null;

  /**Принадлежность вагонов */
  otprCarContOwnerTypeName: string;

  /**Идентификатор вида отправки */
  otprSendKindId: number | null;

  //todo load common dict
  /**Вид отправки */
  otprSendKindName: string;

  /**Идентиф. Вида подвиж состава */
  otprCarTypeId: number | null;

  /**Идентификатор Вида подвиж состава  */
  otprCarTypeName: string;

  /**Новый вид подвижного состава код */
  otprCarTypeCode: number | null;

  /**Новый вид подвижного состава */
  otprCarTypeCodeName: string;

  /**Вес груза в тоннах */
  otprFreightWeight: number | null;

  /**Количество вагонов/контейнеров */
  otprCarCount: number | null;

  /**Признак Калининграда (0-нет, 1-да) */
  otprKlgFlag: number | null;

  /**Код станции Калининградской жд */
  otprKlgStationCode: string;

  /**Наименование станции Калининградской жд  */
  otprKlgStationName: string;

  /**Код станции входа с материковой части на КЛГжд */
  otprEnterKlgStationCode: string;

  /**Наименование станции входа с материковой части РФ на КЛГжд */
  otprEnterKlgStationName: string;

  /**Идентификатор вида сопровождения */
  otprAccompId: number | null;

  /**Наименование вида сопровождения */
  otprAccompName: string;

  /**Количество проводников */
  otprGuideCount: number | null;

  /**Порт (пункт перевалки) id */
  otprToPortId: number | null;

  /**Порт (пункт перевалки) code */
  otprToPortCode: string; //  */

  /**/ otprToPortName: string;

  /**Наименование порта */
  otprAccumPort: number | null;

  /**Провозная плата */
  otprPaySum: number | null;

  /**Идентификатор телеграммы */
  telegramId: number;
}

export interface EtranTelegramPayerModel {
  /**Номер плательщика */
  payerNom: number;

  /**Тип плательщика Id */
  payerTypeId: number | null;

  /**Тип плательщика */
  payerTypeName: string; //  */

  /**/ payerCode: string;

  payerId: number | null;

  /**ОКПО плательщика */
  payerOkpo: string;

  /**Наименование плательщика */
  payerName: string;

  /**Код страны отправления */
  payerCountryCode: string;

  /**Страна отправления */
  payerCountryName: string;

  /**Идентификатор телеграммы */
  telegramId: number;
}

export interface EtranInvoiceStatusHistory {
  invoiceId: number;
  createDate: Date;
  statusCode: number;
  statusName: string;
}

export interface EtranClaimInfo {
  /**Идентификатор заявки id     */
  claimId: number;

  /**Номер заявки */
  claimNumber: string;

  /**Тип документа */
  docType: string;

  /**Состояние заявки id */
  claimStateId: number | null;

  /**Дата регистрации */
  claimRegDate: Date | string | null;

  /**Дата начала действия изменений */
  clmChangeDate: Date | string | null;

  /**Период перевозки с */
  clmStartDate: Date | string | null;

  /**Период перевозки до */
  clmFinishDate: Date | string | null;

  /**Вид отправки Id */
  clmSendKindId: number | null;

  /**Вид отправки */
  clmSendKind: string;

  /**Страна отправления code */
  clmFromCountryCode: number | null;

  /**Страна отправления */
  clmFromCountryName: string;

  /**Станция отправления code */
  clmFromStationCode: string;

  /**Станция отправления */
  clmFromStationName: string;

  /**Код дороги */
  stDpRwId: number | null;

  /**Параграфы для станции отправления */
  fromStationParagraphs: string;

  /**Грузоотправитель Id */
  clmSenderId: number | null;

  /**Грузоотправитель okpo */
  clmSenderOkpo: string;

  /**Грузоотправитель */
  clmSenderName: string;

  /**Грузоотправитель адрес id */
  clmSenderAddressId: number | null;

  /**Грузоотправитель адрес */
  clmSenderAddress: string;

  /**Грузоотправитель ТГНЛ */
  clmSenderTgnl: string;

  /**Тип собственности вагонов id */
  clmCarOwnerTypeId: number | null;

  /**Тип собственности вагонов */
  clmCarOwnerType: string;

  /**Номер договора */
  clmDogNumber: string;

  /**Владелец подъездного пути Id */
  clmLoadWayOwnerId: number | null;

  /**Владелец жд. пути необщего пользования */
  clmLoadWayOwnerName: string;

  /**Отметка о согласовании владельцем пути */
  clmLoadWayOwnerNote: string;

  /**Дата согласования владельцем подъездного пути */
  clmLoadWayOwnerAgreementDate: Date | string | null;

  /**Код группы груза */
  clmFreightGroupCode: number | null;

  /**Группа груза */
  clmFreightGroupName: string;

  /**Способ подачи Id */
  clmTypePodId: number | null;

  /**Способ подачи */
  clmTypePod: string;

  /**Представитель отправителя id */
  clmSenderAgentId: string;

  /**Представитель отправителя */
  clmSenderAgentName: string;

  /**Вид сообщения Id */
  clmMsgKindId: number;

  /**Вид сообщения */
  clmMsgKindName: string;

  /**Отправки */
  otprInfos: EtranClaimOtprInfo[];

  /**График подач */
  graphPodInfos: EtranOtprGraphPodInfo[];

  /**Плательщики */
  payerInfos: EtranClaimPayerInfo[];

  /**Ход согласования */
  agreements: EtranClaimAgreementModel[];
}

export interface PageResult {
  groupCount: number | null;
  totalCount: number | null;
  summary: any[];
}

export interface EtranClaimOtprInfo {
  /**Номер отправки */
  otprId: string;

  /**Код груза */
  otprFreightCode: string;

  /**Груз */
  otprFreightName: string;

  /**Род подвижного состава id */
  otprCarTypeId: number | null;

  /**Род подвижного состава */
  otprCarType: string;

  /**Количество вагонов/контейнеров */
  otprCarCount: number | null;

  /**Вес груза */
  otprFreightWeight: number | null;

  /**Станция назначения code */
  otprToStationCode: string;

  /**Станция назначения */
  otprToStationName: string;

  /**Страна назначения code */
  otprToCountryCode: number | null;

  /**Страна назначения */
  otprToCountryName: string;

  /**Плата */
  otprAmount: number | null;

  /**Идентификатор валюты (CUR_ID по НСИ - CURRENCY */
  otprCurrencyId: number | null;

  /**Идентификатор валюты */
  otprCurrency: string;

  /**Примечание */
  otprNote: string;

  /**Расстояние, км */
  otprDistWay: number | null;

  /**Срод дост. груза */
  otprDaysDelivery: number | null;

  /**Собственник вагона */
  otprCarOwnerName: string;
}

export interface EtranOtprGraphPodInfo {
  /**Номер отправки */
  gpPodNum: number;

  /**Номер отправки */
  otprNom: number | null;

  /**Дата подачи */
  gpPodDate: Date | string | null;

  /**Количество вагонов */
  gpCarCount: number | null;

  /**Вес груза */
  gpWeight: number | null;
}

export interface EtranClaimPayerInfo {
  /**Код плательщика */
  payerCode: string;

  /**ОКПО */
  payerOkpo: string;

  /**Наименование */
  payerName: string;

  /**Страна */
  payerCountryName: string;

  /**Номер отправки */
  payerOtprNom: number | null;

  /**Код перевозчика */
  payerCarrierCode: string;
}

export interface EtranClaimAgreementModel {
  /**Согласование */
  agrName: string;

  /**Наименование организации */
  agrOrgName: string;

  /**Состояние */
  agrState: string;

  /**Дата согласования */
  agrDate: Date | string | null;

  /**Комментарии */
  agrRemark: string;

  /**Идентификатор заявка */
  claimId: number;
}

export interface EtranClaimStatusHistory {
  claimId: number;

  createDate: Date | string | null;

  statusCode: number | null;

  statusName: string;
}
