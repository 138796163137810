import * as signalR from '@microsoft/signalr';
import { HubConnectionState, IRetryPolicy, RetryContext } from '@microsoft/signalr';
import { notification } from 'antd';
import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import UIfx from 'uifx';

import beepMp3 from '@assets/piece-of-cake.mp3';

import { getAuthority } from '@utils/authority';
import { b64toBlob } from '@utils/files';
import { newMessagesNotify, showErrorNotify } from '@utils/notify';

import config from '../../config';
import userStore from '../userStore/userStore';
import service from './messengerStore.service';
import { ChatDialog, ChatMessage } from './messengerStoreData';

const { urlApi } = config;
const beep = new UIfx(beepMp3);

class MessengerStore {
  @observable message: string = '';
  @observable _messages: ChatMessage[] = [];
  @observable isBusyMessages: boolean = false;
  @observable dialogType: ChatDialog;
  @observable isBusyDialogs: boolean = false;
  @observable isBusyDeleteConfirm: boolean = false;
  @observable selectedDialog: any;
  @observable editDialog: boolean;
  @observable searchStr: string = '';
  @observable newDialogName: string = '';
  @observable private _dialogs: ChatDialog[];
  @observable isBusyConfirm: boolean = false;
  @observable isBusy: boolean = false;
  @observable attachments: any[] = [];
  @observable isServerAvailable = true;

  connection: signalR.HubConnection;

  constructor() {
    makeObservable(this);
  }

  async connect() {
    const retryPolicy: IRetryPolicy = {
      nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
        return 5000;
      },
    };
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${urlApi}/chat`, { accessTokenFactory: () => getAuthority().access_token })
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect(retryPolicy)
      .build();

    await this.start();

    this.connection.on('Receive', (message, userName) => {
      this.onReceive(message, userName);
    });
    this.connection.onreconnected((e) => {
      if (!this.isServerAvailable) {
        notification.destroy();
        notification.success({
          btn: null,
          message: 'Соединение с сервером восстановлено',
          placement: 'bottomRight',
          duration: 5,
        });
        this.isServerAvailable = true;
      }
    });
    this.connection.onreconnecting((e) => {
      if (e) {
        this.isServerAvailable = false;
        notification.warn({
          btn: null,
          message: 'Сервер временно недоступен',
          placement: 'bottomRight',
          duration: 0,
        });
      }
    });
  }

  async start() {
    try {
      if (this.connection) {
        await this.connection.stop();
      }
      await this.connection.start();
    } catch (err) {
      setTimeout(() => this.start(), 5000);
    }
  }

  onReceive(message: string, userId: string) {
    const currentUser = userStore.userData.userId;
    if (currentUser !== userId) {
      if (!this.selectedDialog) {
        newMessagesNotify(message);
        beep.play(0.5);
      }
      this.getDialogs();
    }
    this.getMessages();
  }

  sendMessage() {
    const dialogId = this.selectedDialog ? this.selectedDialog.dialogId : null;
    const attachmentsToSend = this.attachments.map((file) => {
      return { fileUrl: file.fileUrl, name: file.name, bytes: file.bytes };
    });

    if (!dialogId) {
      this.connection.invoke('SendInTech', this.message.trim(), attachmentsToSend[0]);
      setTimeout(() => {
        this.getDialogs().then(() => {
          //@ts-ignore
          let find = this.dialogs.find((d) => d.dialogType == 'Tech');
          this.selectDialog(find);
        });
      }, 100);
    } else {
      var type = 'Send';
      if (this.selectedDialog.dialogType == 'Assist') type = 'SendInAssist';
      this.connection.invoke(type, this.message.trim(), dialogId, attachmentsToSend[0]).catch((e) => {
        showErrorNotify('Ошибка сообщения', e.message.split(':')[1]);
      });
    }
    this.message = '';
    this.attachments = [];
  }

  trySend() {
    if (this.connection.state === HubConnectionState.Connected) {
      this.sendMessage();
    } else {
      this.start().then(() => {
        this.sendMessage();
      });
    }
  }

  createDialog(name: string, userIds: string[]) {
    this.isBusyConfirm = true;
    const data = {
      name,
      users: userIds.map((x) => {
        return { userId: x };
      }),
    };
    service
      .addDialog(data)
      .then((result) => {
        this.selectedDialog = null;
        this.editDialog = false;
        this.getDialogs();
      })
      .finally(() => (this.isBusyConfirm = false));
  }

  @action selectDialog(dialog) {
    if (dialog) {
      dialog.unreadMessageCount = 0;
    }
    this.selectedDialog = dialog;
    this._messages = [];
    this.getMessages();
  }

  @action resetUnreadMessages(dialog) {
    if (!dialog) return;
    dialog.unreadMessageCount = 0;
  }

  @action getMessages() {
    this.isBusyMessages = true;
    if (this.selectedDialog) {
      service
        .getMessages(this.selectedDialog.dialogId)
        .then((result: ChatMessage[]) => {
          this._messages = result;
        })
        .finally(() => (this.isBusyMessages = false));
    }
  }

  @action updateDialog(name: string, userIds: string[]) {
    this.isBusyConfirm = true;
    const data = {
      dialogId: this.selectedDialog.dialogId,
      name,
      users: userIds.map((x) => {
        return { userId: x };
      }),
    };
    service
      .updateDialog(data)
      .then((result) => {
        this.selectedDialog = null;
        this.editDialog = false;
        this.getDialogs();
      })
      .finally(() => (this.isBusyConfirm = false));
  }

  @action removeDialog() {
    this.isBusyDeleteConfirm = true;
    service
      .removeDialog(this.selectedDialog.dialogId)
      .then(() => {
        this.selectedDialog = null;
        this.editDialog = false;
        this.getDialogs();
      })
      .finally(() => (this.isBusyDeleteConfirm = false));
  }

  downloadAttachment(messageId: number, fileName: string) {
    service.attachment(messageId).then((result: string) => {
      if (!result) return;
      const base64 = result ? result.split('base64,')[1] : '';
      const contentType = result.split(';base64,')[0].split('data:')[1];
      const url = window.URL.createObjectURL(b64toBlob(base64, contentType));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  }

  @action getDialogs() {
    this.isBusyDialogs = true;
    return service
      .getDialogs()
      .then((result: ChatDialog[]) => {
        this._dialogs = result;
        if (this.selectedDialog) {
          const selected = this._dialogs.find((x) => x.dialogId === this.selectedDialog);
          this.resetUnreadMessages(selected);
        }
      })
      .finally(() => (this.isBusyDialogs = false));
  }

  @action createDialogFromClaim(claimName) {
    this.editDialog = true;
    this.newDialogName = claimName;
  }

  @computed get messages() {
    if (!this._messages) {
      return [];
    } else {
      if (this.searchStr && this.searchStr.length > 0) {
        return [...this._messages].filter((x) => x.content && x.content.includes(this.searchStr));
      } else {
        return [...this._messages];
      }
    }
  }

  @computed get dialogs() {
    if (!this._dialogs) {
      return [];
    } else {
      return [...this._dialogs];
    }
  }

  @computed get unreadMessages() {
    const allUnread = this.dialogs.map((x) => x.unreadMessageCount);
    return _.sum(allUnread);
  }

  @computed get unreadTechMessages() {
    const techUnread = this.dialogs.filter((x) => x.dialogType.includes('Tech')).map((x) => x.unreadMessageCount);
    return _.sum(techUnread);
  }

  @computed get unreadCommonMessages() {
    const commonUnread = this.dialogs.filter((x) => x.dialogType.includes('Common')).map((x) => x.unreadMessageCount);
    return _.sum(commonUnread);
  }
}

export default new MessengerStore();
