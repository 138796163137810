import { LeftOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { MarkModel } from '@stores/markStore';
import markStore from '@stores/markStore/markStore';

import MarkCreator from './MarkCreator';
import MarkList from './MarkList';

interface MarkEditorProps {
  companyId: string;
  value?: MarkModel[];
  readonly?: boolean;
}

interface MarkEditorState {
  name: string;
  stopItem: string;
  value: string[];
  tags: MarkModel[];
  selected: MarkModel[];
  editMode: boolean;
  editedTag: MarkModel;
}

class MarkEditor extends Component<MarkEditorProps, MarkEditorState> {
  constructor(props: MarkEditorProps) {
    super(props);

    this.state = {
      name: '',
      stopItem: null,
      value: [],
      tags: [],
      selected: [],
      editMode: false,
      editedTag: null,
    };
  }

  componentDidMount(): void {
    markStore.getNotes(this.props.companyId);
  }

  render() {
    const isBusy = markStore.isBusy;
    const notes = markStore.notes;
    const selected = this.props.value || [];
    const editMode = this.state.editMode;
    const editedTag = this.state.editedTag;
    return (
      <div>
        {editMode ? (
          <>
            <h3>
              <LeftOutlined
                style={{ cursor: 'pointer', marginRight: 5 }}
                onClick={() => this.setState({ editMode: false, editedTag: null })}
              />{' '}
              Метки
            </h3>
          </>
        ) : (
          <h3>Метки</h3>
        )}
        {editMode ? (
          <MarkCreator onFinish={this.onFormFinish} mark={editedTag} isBusy={isBusy} />
        ) : (
          <MarkList
            notes={notes}
            isBusy={isBusy}
            selected={selected}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
            onAddNew={this.onAddNew}
            onClick={this.onTagClick}
          />
        )}
        {selected.map((mark, index) => {
          return (
            <Tag key={`tag_${index}`} color={mark.description} style={{ cursor: 'pointer' }}>
              {mark.title}
            </Tag>
          );
        })}
      </div>
    );
  }

  onFormFinish = (e) => {
    const newTag = {
      id: null,
      companyId: this.props.companyId,
      title: e.name,
      description: e.color,
      isBelongsCreator: e.isBelongsCreator,
      reportRowColor: e.reportRowColor,
    };
    if (this.state.editedTag) {
      newTag.id = this.state.editedTag.id;
      markStore.update(newTag, this.props.companyId).then(() => {
        this.setState({
          editMode: false,
          editedTag: null,
        });
        const value = this.props.value || [];
        const selected = value.find((x) => x.id === newTag.id);
        if (selected) {
          selected.title = newTag.title;
          selected.description = newTag.description;
        }
      });
    } else {
      markStore.add(newTag, this.props.companyId).then(() => {
        this.setState({
          editMode: false,
          editedTag: null,
        });
      });
    }
  };

  onDelete = (mark: MarkModel) => {
    markStore.delete(mark.id, this.props.companyId);
  };

  onEdit = (mark: MarkModel) => {
    this.setState({
      editedTag: mark,
      editMode: true,
    });
  };

  onAddNew = () => {
    this.setState({
      editedTag: null,
      editMode: true,
    });
  };

  onTagClick = (mark: MarkModel) => {
    const selected = [...this.state.selected];
    if (selected.map((x) => x.id).includes(mark.id)) {
      const newList = selected.filter((x) => x.id !== mark.id);
      this.setState({
        selected: newList,
      });
    } else {
      selected.push(mark);
      this.setState({
        selected: selected,
      });
    }
  };

  handleChange = (value: string[]) => {};
}

export default observer(MarkEditor);
