import moment from 'moment';

import { TariffFgkBaseResponseModel } from '../TariffFgkBase/tariffFgkBaseStore';
import { OrganizationRequisites } from '../organizationsStore/organizationsStoreData';
import { TariffAttributeJsonModel, TariffCountryDetailModel } from '../sendsStore/sendData';

export interface TariffCalcResponse {
  countryDetails: TariffCountryDetailModel[];
  tariffDetailData: TariffDetailData;
  pathDetails: PathDetailsInfoModel[];
  deliveryPeriod: number;
  emptyDeliveryPeriod: number;
  errorMessage: string;
  tariffResponseId: string;
  userTariffRequestId: string;
  globosoftRequestId: string;
  fgk: TariffFgkBaseResponseModel;
  loadStationCode?: number;
  loadStationName?: string;
  destStationCode?: number;
  destStationName?: string;
  weightStepperList?: WeightStepperList[];
  groupDetails?: TariffResultWagonGroupDetails[];
}

export interface WeightStepperList {
  aggTariffCountryDetail: TariffCountryDetailModel;
  weight: number;
}

export interface TariffResultWagonGroupDetails {
  freightWeight: number;
  freightCapacity: number;
  countryDetail: TariffCountryDetailModel[];
}

export const EmptyTariffCalcResponse: TariffCalcResponse = {
  countryDetails: null,
  tariffDetailData: null,
  pathDetails: null,
  deliveryPeriod: null,
  emptyDeliveryPeriod: null,
  errorMessage: null,
  tariffResponseId: null,
  userTariffRequestId: null,
  globosoftRequestId: null,
  fgk: null,
};

export interface TariffDetailData {
  attributes: TariffAttributeJsonModel[];
}

export type PathDetailsInfoModel = {
  countryCode: number;
  countryName: string;
  countryOrder: number;
  distance: number;
  pathDetailId: string;
  roadCode: string;
  rowSerialNumber: number;
  stationCode: string;
  stationName: string;
  tariffResponseId: string;
  latitude: number;
  longitude: number;
  failCoordinates: boolean;
};

export type TariffDictionaryModel = {
  wagonTypesDictionary: WagonTypeModel[];
  quantityShippingType: ShippingTypeModel[];
  scaleShippingType: ShippingTypeModel[];
  wagonTypeGroupDictionary: WagonTypeGroupModel[];
  dangerCategoryDictionary: DangerCategoryModel[];
  wagonAffiliationTypeDictionary: WagonAffiliationTypeModel[];
  overloadDictionary: OverloadModel[];
  packagingDictionary: PackagingModel[];
  speedTypeDictionary: SpeedTypeModel[];
  oversizeCategoryDictionary: OversizeCategoryModel[];
  currencyDictionary: CurrencyModel[];
  tariffUnitDictionary: TariffUnitDictionary[];
};
export interface TariffUnitDictionary {
  fullName: string;
  shortName: string;
  tariffUnitId: number;
}

export type DictionaryModel = {
  wagonTypesDictionary: WagonTypeModel[];
  shippingTypeDictionary: ShippingTypeModel[];
  scaleShippingTypeDictionary: ShippingTypeModel[];
  statusDictionary: ClaimStatusModel[];
  sendStatusDictionary: SendStatusModel[];
  tariffIcDictionary: TariffIcModel[];
  wagonAffiliationDictionary: WagonAffiliationTypeModel[];
};

export type WagonTypeModel = {
  wagonTypeCode: number;
  groupCode: number;
  shortName: string;
  wagonTypeName: string;
};

export type ShippingTypeModel = {
  shippingTypeId: string;
  shippingTypeName: string;
  iodv: string;
  shippingTypeCode: number;
};

export type WagonTypeGroupModel = {
  groupCode: number;
  groupName: string;
};

export type DangerCategoryModel = {
  categoryCode: number;
  categoryName: number;
};

export type WagonAffiliationTypeModel = {
  affiliationCode: number;
  affiliationName: string;
};

export type OverloadModel = {
  overloadCode: number;
  overloadName: string;
};

export type PackagingModel = {
  packagingCode: number;
  packagingName: string;
};

export type SpeedTypeModel = {
  speedCode: number;
  speedName: string;
};

export type OversizeCategoryModel = {
  categoryCode: number;
  categoryName: string;
};

export type CurrencyModel = {
  currencyCode: string;
  isoCode: string;
  currencyName: string;
  decimalCount: number;
  coinName: string;
};

export type ClaimStatusModel = {
  statusId: string;
  statusName: string;
};

export type SendStatusModel = {
  statusId: string;
  statusName: string;
};

export type TariffIcModel = {
  tariffIcId: string;
  tariffIcName: string;
};

export interface OversizePoints {
  points: number[][];
  floor: boolean;
  floorPoint: number;
}

export interface TariffProducerResponse extends OrganizationRequisites {
  wagonInfo: TariffProducerWagonInfo[];
}
export interface TariffProducerWagonInfo {
  date: any;
  stations: TariffProducerWagonInfoStation[];
  agg?: TariffProducerWagonInfoStation;
}
export interface TariffProducerWagonInfoStation {
  name: string;
  loaded: number;
  empty: number;
  models: TariffProducerWagonInfoModel[];
}

export interface TariffProducerWagonInfoModel {
  name: string;
  loaded: number;
  empty: number;
}

export interface TariffProducerRequest {
  loadStationCode: string;
  etsngCode: string;
  /// Радиус поиска
  stationRadius: number;
  /// Кол-во станций в радиусе
  stationCount: number;
  producerCount: number;
  /// Дата прибытия - начало
  arrivalDateBegin: any;
  /// Дата прибытия - конец
  arrivalDateEnd: any;
  range?: any;
}

export interface TariffTemplate {
  templateId?: string;
  isDefault?: boolean;
  name: string;
  data: any;
}

export const defaultTariffProducerRequest: TariffProducerRequest = {
  loadStationCode: null,
  etsngCode: null,
  stationRadius: 50,
  stationCount: 100,
  producerCount: 100,
  range: [moment(), moment().add(1, 'day')],
  arrivalDateBegin: moment(),
  /// Дата прибытия - конец
  arrivalDateEnd: moment().add(1, 'day'),
};

export const resultDataProcess = (result: TariffCalcResponse) => {
  if (!result) return {};
  let countryPositions: string[] = [];
  if (result.pathDetails && result.pathDetails.length) {
    const a: { [key: string]: boolean } = {};
    result.pathDetails.forEach((p) => {
      a[p.countryName] = true;
      p.countryOrder = Object.keys(a).indexOf(p.countryName);
    });
    countryPositions = Object.keys(a);
  }
  result.countryDetails.sort((a, b) => {
    const aIndex = countryPositions.indexOf(a.countryName);
    const bIndex = countryPositions.indexOf(b.countryName);
    if (a.isEmpty && !b.isEmpty) return 1;
    if (!a.isEmpty && b.isEmpty) return -1;
    if (a.isEmpty && b.isEmpty) return bIndex - aIndex;
    return aIndex - bIndex;
  });
  if (result.countryDetails && result.countryDetails.length) {
    //result.countryDetails = result.countryDetails.sort(
    // (a, b) => countryPositions.indexOf(a.countryName) - countryPositions.indexOf(b.countryName)
    //);
    result.countryDetails.forEach((it) => {
      expeditorAmountSum(it);
    });
  }
  if (result.weightStepperList && result.weightStepperList.length) {
    result.weightStepperList.forEach((it) => {
      expeditorAmountSum(it.aggTariffCountryDetail);
    });
  }
  return { result, countryPositions };
};

export const expeditorAmountSum = (it: TariffCountryDetailModel) => {
  it.freightChargeChf = it.freightChargeChf + (it.expeditorAmountChf ? it.expeditorAmountChf : 0);
  it.freightChargeChfVat = it.freightChargeChfVat + (it.expeditorNdsAmountChf ? it.expeditorNdsAmountChf : 0);
  it.freightChargeChfWithVat =
    it.freightChargeChfWithVat +
    (it.expeditorNdsAmountChf ? it.expeditorNdsAmountChf : 0) +
    (it.expeditorAmountChf ? it.expeditorAmountChf : 0);

  it.freightChargeRu = it.freightChargeRu + (it.expeditorAmountRu ? it.expeditorAmountRu : 0);
  it.freightChargeRuVat = it.freightChargeRuVat + (it.expeditorNdsAmountRu ? it.expeditorNdsAmountRu : 0);
  it.freightChargeRuWithVat =
    it.freightChargeRuWithVat + (it.expeditorNdsAmountRu ? it.expeditorNdsAmountRu : 0) + (it.expeditorAmountRu ? it.expeditorAmountRu : 0);

  it.freightChargeUsd = it.freightChargeUsd + (it.expeditorAmountUsd ? it.expeditorAmountUsd : 0);
  it.freightChargeUsdVat = it.freightChargeUsdVat + (it.expeditorNdsAmountUsd ? it.expeditorNdsAmountUsd : 0);
  it.freightChargeUsdWithVat =
    it.freightChargeUsdWithVat +
    (it.expeditorNdsAmountUsd ? it.expeditorNdsAmountUsd : 0) +
    (it.expeditorAmountUsd ? it.expeditorAmountUsd : 0);

  it.freightChargeEur = it.freightChargeEur + (it.expeditorAmountEur ? it.expeditorAmountEur : 0);
  it.freightChargeEurVat = it.freightChargeEurVat + (it.expeditorNdsAmountEur ? it.expeditorNdsAmountEur : 0);
  it.freightChargeEurWithVat =
    it.freightChargeEurWithVat +
    (it.expeditorNdsAmountEur ? it.expeditorNdsAmountEur : 0) +
    (it.expeditorAmountEur ? it.expeditorAmountEur : 0);

  it.freightChargeCountry = it.freightChargeCountry + (it.expeditorAmountCountry ? it.expeditorAmountCountry : 0);
  it.freightChargeCountryVat = it.freightChargeCountryVat + (it.expeditorNdsAmountCountry ? it.expeditorNdsAmountCountry : 0);
  it.freightChargeCountryWithVat =
    it.freightChargeCountryWithVat +
    (it.expeditorNdsAmountCountry ? it.expeditorNdsAmountCountry : 0) +
    (it.expeditorAmountCountry ? it.expeditorAmountCountry : 0);

  it.freightChargeCurrency = it.freightChargeCurrency + (it.expeditorAmountCurrency ? it.expeditorAmountCurrency : 0);
  it.freightChargeCurrencyVat = it.freightChargeCurrencyVat + (it.expeditorNdsAmountCurrency ? it.expeditorNdsAmountCurrency : 0);
  it.freightChargeCurrencyWithVat =
    it.freightChargeCurrencyWithVat +
    (it.expeditorNdsAmountCurrency ? it.expeditorNdsAmountCurrency : 0) +
    (it.expeditorAmountCurrency ? it.expeditorAmountCurrency : 0);
};

export const calcTariffTotal = (data: TariffCountryDetailModel[]) => {
  if (!data || !data.length) return null;
  let result: TariffCountryDetailModel = JSON.parse(JSON.stringify(data[0]));
  result.countryName = 'Итого';
  for (let i = 1; i < data.length; i++) {
    const current = data[i];
    if (!current) continue;
    Object.keys(current).forEach((key) => {
      if (typeof current[key] === 'number') {
        if (!result[key]) result[key] = 0;
        result[key] += current[key];
      }
    });
  }
  return result;
};

export const dateParser = (e) => (e ? moment(e).format(GLOBAL_DATE_FORMAT) : '');
export const dateTimeParser = (e) => (e ? moment(e).format(GLOBAL_DATETIME_FORMAT) : '');

export const GLOBAL_DATE_FORMAT = 'DD.MM.YYYY';
export const DX_GRID_GLOBAL_DATE_FORMAT = 'dd.MM.yyyy';
export const GLOBAL_TIME_FORMAT = 'HH:mm:ss';
export const SHORT_TIME_FORMAT = 'HH:mm';
export const GLOBAL_DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DX_GRID_GLOBAL_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const ETRAN_DATETIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';
