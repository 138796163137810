import React, { Component } from 'react';

import { InputPosition, positionToBorderRadius } from '@stores/designStore/designData';

import styles from './FloatLabel.module.scss';

interface State {
  focus: boolean;
}

interface BorderWrapperProps {
  position?: InputPosition;
  className?: any;
  height?: string;
  onFocusChange?(focus: boolean): void;
  disabled?: boolean;
}

class BorderWrapper extends Component<BorderWrapperProps, State> {
  constructor(props: BorderWrapperProps) {
    super(props);

    this.state = {
      focus: false,
    };
  }

  onBlur = () => {
    this.setState({ focus: false });
    if (this.props.onFocusChange) {
      this.props.onFocusChange(false);
    }
  };

  onFocus = () => {
    this.setState({ focus: true });
    if (this.props.onFocusChange) {
      this.props.onFocusChange(true);
    }
  };

  render() {
    return (
      <div
        className={this.props.className ? this.props.className : styles.floatlabel}
        style={{
          background: this.props.disabled ? '#f1f2f6' : '',
          borderRadius: positionToBorderRadius(this.props.position),
          borderColor: this.state.focus ? '#4977E9' : null,
          height: this.props.height == 'adaptive' ? null : this.props.height ? this.props.height : '52px',
          zIndex: this.state.focus ? 999 : null,
        }}
        onBlur={this.onBlur}
        onFocus={this.onFocus}>
        {this.props.children}
      </div>
    );
  }
}
export default BorderWrapper;
