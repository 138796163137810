import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Form, Popconfirm, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import PdfViewModal from '@components/fw/DocumentViewer/PdfViewModal';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import { CompanyServiceSetModel, CompanyServiceSetReferenceModel } from '@stores/adminBilling/adminBillingData';
import adminBillingStore from '@stores/adminBilling/adminBillingStore';
import adminBillingStoreService from '@stores/adminBilling/adminBillingStore.service';
import { DocTypeModel } from '@stores/contractTemplateStore/ContractTemplatesData';
import contractTemplateStore from '@stores/contractTemplateStore/contractTemplateStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface Props {
  data: CompanyServiceSetModel;
  onClose(): void;
  visible: boolean;
}

interface State {
  isBusy: boolean;
  services: CompanyServiceSetReferenceModel[];
  selectedSupplier: string;
  contractList: DocTypeModel[];
  extList: DocTypeModel[];
  formData: any;
  viewSubmitIndex: number;
  doc: Blob;
  loadingDoc: boolean;
}

@observer
class ReferenceEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: false,
      services: this.props.data.services,
      selectedSupplier: props.data.supplierId,
      contractList: [],
      extList: [],
      formData: this.formData(props.data.services),
      viewSubmitIndex: null,
      doc: null,
      loadingDoc: false,
    };
  }

  formData = (services: CompanyServiceSetReferenceModel[]) => {
    const data = JSON.parse(JSON.stringify(this.props.data));
    if (data.offerDate) data.offerDate = moment(data.offerDate);
    const contractData = data.serviceSetContract;
    if (contractData) {
      data['contractName'] = contractData.name;
      data['contractEndDate'] = contractData.endDate ? moment(contractData.endDate) : null;
      data['contractAddress'] = contractData.address;
      data['contractSupplierDocTypeId'] = contractData.supplierDocTypeId;
      data['contractForeignId'] = contractData.foreignId;
      data['contractStatusId'] = contractData.statusId;
    }

    services.forEach((s, index) => {
      data['serviceId:' + index] = s.serviceId;
      data['price:' + index] = s.price;
      const ext = s.extContract;
      if (ext) {
        data['extAddress:' + index] = ext.address;
        data['extSupplierDocTypeId:' + index] = ext.supplierDocTypeId;
        data['extBeginDate:' + index] = ext.beginDate ? moment(ext.beginDate) : null;
        data['extEndDate:' + index] = ext.endDate ? moment(ext.endDate) : null;
        data['extName:' + index] = ext.name;
        data['extContractNumber:' + index] = ext.extContractNumber;
        data['extForeignId:' + index] = ext.foreignId;
        data['extStatusId:' + index] = ext.statusId;
      }
    });
    return data;
  };

  formRef: FormInstance;
  render() {
    const isBusy = this.state.isBusy;
    const services = this.state.services;
    const contractTemplates = contractTemplateStore.supplierContracts;
    const extTemplates = contractTemplateStore.supplierExts;
    return (
      <AdaptiveDrawer
        title={'Договор'}
        onClose={() => this.handelClose()}
        width={'80%'}
        visible={this.props.visible}
        afterVisibleChange={(e) => {
          if (e) {
            this.openDrawer();
          }
        }}
        footer={[
          <Button onClick={() => this.onView(99)} style={{ marginRight: 8 }} size={'middle'} key={'view'}>
            Посмотрeть
          </Button>,
          <Button onClick={this.handelClose} style={{ marginRight: 8 }} size={'middle'} key={'close'}>
            Отмена
          </Button>,
          <Button type="primary" loading={isBusy} onClick={() => this.formRef.submit()} size={'middle'} key={'save'}>
            {this.isEditMode ? 'Сохранить' : 'Добавить'}
          </Button>,
        ]}>
        <Spin spinning={this.state.loadingDoc}>
          <Form
            layout="vertical"
            ref={(ref) => (this.formRef = ref)}
            onFinish={(e) => this.handleConfirm(e)}
            initialValues={this.state.formData}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="supplierId" rules={[{ required: true, message: 'Выберите поставщика' }]}>
                  <FloatSelect onChange={this.onSupplierChange} placeholder="Поставщик">
                    {adminBillingStore.suppliers.map((s) => (
                      <Select.Option key={s.supplierId} value={s.supplierId}>
                        {s.name}
                      </Select.Option>
                    ))}
                  </FloatSelect>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="contractName" rules={[{ required: true, message: 'Укажите наименование договора' }]}>
                  <FloatInput placeholder="Наименование договора" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="offerNumber" rules={[{ required: true, message: 'Укажите номер договора' }]}>
                  <FloatInput placeholder="Номер договора" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="offerDate" rules={[{ required: true, message: 'Укажите дату' }]}>
                  <FloatDatepicker placeholder="Дата" format={GLOBAL_DATE_FORMAT} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="contractEndDate" rules={[{ required: true, message: 'Укажите дату окончания' }]}>
                  <FloatDatepicker format={GLOBAL_DATE_FORMAT} placeholder="Действует до" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="contractSupplierDocTypeId" rules={[{ required: true }]}>
                  <FloatSelect
                    placeholder="Шаблон договора"
                    notFoundContent={
                      this.state.selectedSupplier ? (
                        <Empty>Нeт шаблонов. Создайте новый на странице "Биллинг"</Empty>
                      ) : (
                        <Empty>Сначала выберите поставщика</Empty>
                      )
                    }>
                    {contractTemplates.map((c) => (
                      <Select.Option value={c.docTypeId} key={c.docTypeId}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </FloatSelect>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="contractAddress" rules={[{ required: true, message: '' }]}>
                  <FloatInput placeholder="Город" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="contractForeignId">
                  <FloatInput placeholder="Номер из внешней системы" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="contractStatusId" rules={[{ required: true, message: '' }]}>
                  <FloatSelect placeholder="Статус">
                    <Select.Option value={'onagr'}>На согласовании</Select.Option>
                    <Select.Option value={'agr'}>Согласован</Select.Option>
                  </FloatSelect>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24}>
                <Button className={'blue-gray-button'} block icon={<PlusOutlined />} onClick={this.addService}>
                  Добавить услугу
                </Button>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {!services.length ? (
                <Col xs={24}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>Добавьте новую услугу</Empty>
                </Col>
              ) : (
                services.map((s, index) => {
                  const selectedServiceId = this.formRef ? this.formRef.getFieldValue('serviceId:' + index) : s.serviceId;
                  const selectedService = selectedServiceId ? adminBillingStore.services.find((as) => as.id == selectedServiceId) : null;
                  return (
                    <Col xs={24} sm={24} md={24} xl={12} key={index}>
                      <AdaptiveCard
                        title={'Услуга ' + (index + 1)}
                        extra={[
                          <Button
                            title={'Просмотр доп. соглашения'}
                            size={'small'}
                            type={'text'}
                            icon={<EyeOutlined />}
                            key={'view'}
                            onClick={() => this.onView(index)}
                          />,
                          <Popconfirm title="Удалить？" okText="Да" cancelText="Нет" onConfirm={() => this.remove(index)} key={'delete'}>
                            <Button size={'small'} type={'text'} icon={<DeleteOutlined />} title={'Удалить услугу'} />
                          </Popconfirm>,
                        ]}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={24}>
                            <Form.Item name={'serviceId:' + index} rules={[{ required: true, message: 'Выберите услугу' }]}>
                              <FloatSelect
                                placeholder={'Направление услуги'}
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                  if (this.formRef) {
                                    this.formRef.resetFields(['price:' + index]);
                                    this.forceUpdate();
                                  }
                                }}>
                                {adminBillingStore.services.map((s) => (
                                  <Select.Option
                                    key={s.id}
                                    value={s.id}
                                    style={{
                                      maxWidth: '100%',
                                      boxSizing: 'border-box',
                                      whiteSpace: 'normal',
                                      wordWrap: 'break-word',
                                    }}>
                                    {s.name}
                                  </Select.Option>
                                ))}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item name={'extName:' + index} rules={[{ required: true, message: '' }]}>
                              <FloatInput placeholder="Наименование доп. соглашения" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item name={'extContractNumber:' + index} rules={[{ required: true, message: '' }]}>
                              <FloatInput placeholder="Номер" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name={'extBeginDate:' + index} rules={[{ required: true, message: 'Укажите дату' }]}>
                              <FloatDatepicker format={GLOBAL_DATE_FORMAT} placeholder="Дата" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name={'extEndDate:' + index} rules={[{ required: true, message: 'Укажите дату окончания' }]}>
                              <FloatDatepicker format={GLOBAL_DATE_FORMAT} placeholder="Действует до" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item name={'extSupplierDocTypeId:' + index} rules={[{ required: true, message: 'Выберите шаблон' }]}>
                              <FloatSelect
                                placeholder={'Шаблон доп. соглашения'}
                                notFoundContent={
                                  this.state.selectedSupplier ? (
                                    <Empty>Нeт шаблонов. Создайте новый на странице "Биллинг"</Empty>
                                  ) : (
                                    <Empty>Сначала выберите поставщика</Empty>
                                  )
                                }>
                                {extTemplates.map((s) => (
                                  <Select.Option key={s.docTypeId} value={s.docTypeId}>
                                    {s.name}
                                  </Select.Option>
                                ))}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item name={'extAddress:' + index} rules={[{ required: true, message: '' }]}>
                              <FloatInput placeholder="Город" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item name={'extForeignId:' + index}>
                              <FloatInput placeholder="Номер из внешней системы" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item name={'extStatusId:' + index} rules={[{ required: true, message: '' }]}>
                              <FloatSelect placeholder="Статус">
                                <Select.Option value={'onagr'}>На согласовании</Select.Option>
                                <Select.Option value={'agr'}>Согласован</Select.Option>
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              style={{
                                display: !selectedService || !selectedService.strategyCode ? null : 'none',
                              }}
                              name={'price:' + index}>
                              <FloatInputNumber min={0} step={0.1} placeholder={'Цена'} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </AdaptiveCard>
                    </Col>
                  );
                })
              )}
            </Row>
          </Form>
        </Spin>
        <PdfViewModal data={this.state.doc} visible={!!this.state.doc} onClose={() => this.setState({ doc: null })} />
      </AdaptiveDrawer>
    );
  }

  addService = () => {
    let s = this.state.services;
    s.push({ serviceId: null, serviceName: null, price: null, extContract: null, strategyCode: null });
    this.setState({ services: s });
  };

  remove = (index: number) => {
    let s = this.state.services;
    s.splice(index, 1);
    if (this.formRef) {
      this.formRef.resetFields(['serviceId:' + index, 'price:' + index]);
    }
    this.setState({ services: s });
  };

  get isEditMode(): boolean {
    return this.props.data && this.props.data.id !== null;
  }

  onSupplierChange = (supplierId: string) => {
    const formData = this.state.formData;
    formData.contractSupplierDocTypeId = null;
    this.state.services.forEach((s, index) => {
      formData['extSupplierDocTypeId:' + index] = null;
    });
    this.setState({ isBusy: true, selectedSupplier: supplierId }, () => {
      if (this.formRef) {
        this.formRef.resetFields(['contractSupplierDocTypeId']);
        this.state.services.forEach((s, index) => {
          this.formRef.resetFields(['extSupplierDocTypeId:' + index]);
        });
      }
    });
    if (supplierId) {
      Promise.all([contractTemplateStore.loadSupplierContracts(supplierId), contractTemplateStore.loadSupplierExt(supplierId)])
        .then(() => {})
        .finally(() => this.setState({ isBusy: false }));
    } else {
      contractTemplateStore.clearSupplierTemplates();
      this.setState({ isBusy: false });
    }
  };

  onView = (index: number) => {
    this.setState({ viewSubmitIndex: index });
    this.formRef.submit();
  };

  openDrawer = () => {
    this.setState({ services: this.props.data.services, formData: this.formData(this.props.data.services) }, () => {
      if (this.formRef) this.formRef.resetFields();
    });
    this.onSupplierChange(this.state.selectedSupplier);
  };

  handelClose = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ services: [] });
    this.props.onClose();
  };

  async handleConfirm(e) {
    const data: CompanyServiceSetModel = {
      id: this.props.data.id,
      companyId: this.props.data.companyId,
      supplierId: e.supplierId,
      services: this.state.services.map((s, index) => {
        const serviceId = e['serviceId:' + index];
        const service = adminBillingStore.services.find((s) => s.id == serviceId);
        return {
          serviceId: serviceId,
          serviceSetId: this.props.data.id,
          strategyCode: service ? service.strategyCode : 'info',
          serviceName: service ? service.name : 'Услуга',
          price: e['price:' + index] ? e['price:' + index] : 0,
          extContract: {
            serviceSetId: this.props.data.id,
            beginDate: e['extBeginDate:' + index] ? moment(e['extBeginDate:' + index]).toISOString(true) : null,
            endDate: e['extEndDate:' + index] ? moment(e['extEndDate:' + index]).toISOString(true) : null,
            statusId: e['extStatusId:' + index],
            supplierDocTypeId: e['extSupplierDocTypeId:' + index],
            name: e['extName:' + index],
            address: e['extAddress:' + index],
            contractNumber: undefined,
            contractId: this.props.data.serviceSetContract ? this.props.data.serviceSetContract.templateMetadataId : null,
            extContractNumber: e['extContractNumber:' + index],
            foreignId: e['extForeignId:' + index],
          },
        };
      }),
      offerDate: e.offerDate ? moment(e.offerDate).toISOString(true) : null,
      offerNumber: e.offerNumber,
      serviceSetContract: {
        serviceSetId: this.props.data.id,
        beginDate: e.offerDate ? moment(e.offerDate).toISOString(true) : null,
        endDate: e.contractEndDate ? moment(e.contractEndDate).toISOString(true) : null,
        statusId: e.contractStatusId,
        supplierDocTypeId: e.contractSupplierDocTypeId,
        name: e.contractName,
        address: e.contractAddress,
        contractNumber: e.offerNumber,
        foreignId: e.contractForeignId,
        templateMetadataId: this.props.data.serviceSetContract ? this.props.data.serviceSetContract.templateMetadataId : null,
      },
    };
    const index = this.state.viewSubmitIndex;
    if (index != null) {
      this.setState({ loadingDoc: true, viewSubmitIndex: null });
      let type = index == 99 ? 'contract' : 'ext';
      let model;
      if (index == 99) {
        model = data.serviceSetContract;
      } else {
        data.services = [data.services[index]];
        model = data;
      }
      try {
        const doc = await adminBillingStoreService.contractGetDemoFile(model, type);
        this.setState({ doc });
      } catch (e) {
      } finally {
        this.setState({ loadingDoc: false });
      }
    } else {
      this.setState({ isBusy: true });
      try {
        await adminBillingStore.editServiceSet(data);
        this.handelClose();
      } catch (e) {
      } finally {
        this.setState({ isBusy: false });
      }
    }
  }
}

export default ReferenceEditor;
