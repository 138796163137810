import { StationModel } from '../dictStore/dictStoreData';
import { TariffCalcResponse } from '../optionsStore/optionsStoreData';
import { OrganizationModel } from '../organizationsStore';
import { TemplateOrgContractModel } from '../organizationsStore/organizationsStoreData';
import { TariffCountryDetailModel } from '../sendsStore/sendData';

export interface ClaimTariffDetailModel extends TariffPrice {
  tariffDetailId: string;
  claimTariffId: string;
  /// Станция отправления
  loadStationCode: string;
  loadStationName: string;

  /// Станция назначения
  destStationCode: string;
  destStationName: string;

  wagonTypeCode: number;

  wagonTypeName: string;

  /// Расстояние
  distance: number;

  /// Код груза
  freightEtsngCode: number;

  /// Наименование груза
  freightEtsngName: string;

  /// коэффициент
  coefficient: number;

  /// Ставка с тарифом
  rateWithTariff: number;

  /// Ставка без тарифа
  rateWithoutTariff: number;

  /// Комиссия экспедирования c НДС
  expeditorCommissionRu: number;

  /// Итого без НДС
  totalAmountRu: number;

  /// НДС
  totalAmountRuVat: number;

  /// Итого с НДС
  totalAmountRuWithVat: number;

  /// Тип
  transportationTypeId: number;

  countryRates: ClaimTariffCountryRateModel[];

  unitCount: number;

  //вспомогательные
  totalWithVat: number;
  total: number;
  totalVat: number;
}

export interface TariffPrice {
  //****Рубли*****
  /// Провозная плата в руб. Общий парк.
  commonParkFreightChargeRuWithVat: number;
  /// Провозная плата в руб. Общий парк. НДС
  commonParkFreightChargeRuVat: number;
  /// Провозная плата в рублях Арендованный
  rentParkFreightChargeRuWithVat: number;
  /// Провозная плата в рублях Арендованный НДС
  rentParkFreightChargeRuVat: number;

  //****Доллары*****
  /// Провозная плата в руб. Общий парк.
  commonParkFreightChargeUsdWithVat: number;
  /// Провозная плата в руб. Общий парк. НДС
  commonParkFreightChargeUsdVat: number;
  /// Провозная плата в рублях Арендованный
  rentParkFreightChargeUsdWithVat: number;
  /// Провозная плата в рублях Арендованный НДС
  rentParkFreightChargeUsdVat: number;

  //****Франки*****
  /// Провозная плата в руб. Общий парк.
  commonParkFreightChargeChfWithVat: number;
  /// Провозная плата в руб. Общий парк. НДС
  commonParkFreightChargeChfVat: number;
  /// Провозная плата в рублях Арендованный
  rentParkFreightChargeChfWithVat: number;
  /// Провозная плата в рублях Арендованный НДС
  rentParkFreightChargeChfVat: number;

  //****Евро*****
  /// Провозная плата в руб. Общий парк.
  commonParkFreightChargeEurWithVat: number;
  /// Провозная плата в руб. Общий парк. НДС
  commonParkFreightChargeEurVat: number;
  /// Провозная плата в рублях Арендованный
  rentParkFreightChargeEurWithVat: number;
  /// Провозная плата в рублях Арендованный НДС
  rentParkFreightChargeEurVat: number;
}

export interface ClaimTariffCountryRateModel extends TariffPrice {
  countryRateId: string;
  tariffDetailId: string;
  extraAmount: number;
  extraPercentage: number;
  commonRate: number;
  rentRate: number;
  includeCalculation: boolean;
  countryCode: number;
  countryName: string;
}

export interface ClaimTariffModel {
  claimTariffId: string;
  claimId: string;
  calculationDate: Date;
  claimTariffDetails: ClaimTariffDetailModel[];
  serviceVatPercentage: number;
}

export interface ClaimTariffWagonTypeGroup {
  wagonTypeCode: number;
  wagonTypeName: string;
  freightGroup: ClaimTariffFreightGroup[];
}

export interface ClaimTariffFreightGroup {
  freightEtsngCode: string;
  freightEtsngName: string;
  tariffList: TariffCalcResponse[];
}

export interface ClaimRentRateModel {
  claimId: string;
  tariffRequestId: string;
  /// Ставка с НДС
  rateWithVat: number;
  /// Размер НДС
  rateVat: number;
  /// Ставка без НДС
  rateWithoutVat: number;
  /// Коммиссия
  commission: number;
  unitCount: number;
}

export type ClaimModel = {
  claimId?: string;
  groupId: string;
  groupName: string;
  producerId: string;
  claimName: string;
  claimNumber?: string;
  createDate: Date;
  executionDate?: Date;
  partnerId: string;
  contract?: string;
  validityFrom: any;
  validityTo: any;
  executorId: string;
  executorOrganizationId: string;
  loadTariffIcId: string;
  emptyTariffIcId?: number;
  specialConditions?: string;
  priorityId: number;
  statusId?: string;
  statusName?: string;
  metadata?: string;
  sends?: any[];
  claimWagonInfoList: ClaimWagonInfo[];
  loadStations: any[];
  destStations: any[];
  freightSenders: any[];
  freightReceivers: any[];
  freightCodes: ClaimFreightServiceModel[];
  tariffDate: any;
  commercialOfferStatusId: string;
  /// Значение статуса коммерческого предложения
  commercialOfferStatusName: string;

  contractExtIds?: string[]; //"00000000-0000-0000-0000-000000000000"
  contractExternalName?: string;
  contractId?: string; //"00000000-0000-0000-0000-000000000000"
  contractName?: string;
  claimType: 'wagon_provision' | 'forwarding';
  countryPaidTerritories?: ClaimCountryInteractionsModel[];
};

export const emptyClaim: ClaimModel = {
  groupId: null,
  groupName: null,
  producerId: null,
  claimName: null,
  createDate: null,
  partnerId: null,
  validityFrom: null,
  validityTo: null,
  executorId: null,
  executorOrganizationId: null,
  loadTariffIcId: null,
  priorityId: null,
  claimWagonInfoList: [],
  loadStations: [],
  destStations: [],
  freightSenders: [],
  freightReceivers: [],
  freightCodes: [],
  tariffDate: null,
  commercialOfferStatusId: null,
  commercialOfferStatusName: null,
  claimType: 'wagon_provision',
};

export interface ClaimWagonInfo {
  wagonGroupCode: number;
  wagonTypeCode: number;
  freightWeight: number;
  wagonCount: number;
  volume: number;
  affiliationCode: number;
  isHalfWagonInnovative: boolean;
}

export interface ClaimWagonInfoLimitation extends ClaimWagonInfo {
  freightWeightMapped: number;
  wagonCountMapped: number;
  wagonTypeName: string;
}

export interface ClaimFreightServiceModel {
  etsng: FreightEtsngModel;
  gng: FreightGngModel;
}

export interface ClaimCountryInteractionsTree {
  wagonTypeCode: number;
  wagonTypeName: string;
  byFreight: {
    freightEtsngCode: string;
    freightEtsngName: string;
    routes: ClaimCountryInteractionsModel[];
  }[];
}

export const claimCountryInteractionsModelToTree = (
  model: ClaimCountryInteractionsModel[],
  tariff?: ClaimTariffWagonTypeGroup[],
): ClaimCountryInteractionsTree[] => {
  const result: ClaimCountryInteractionsTree[] = [];
  model.forEach((m) => {
    if (tariff) {
      tariff.forEach((t) => {
        if (t.wagonTypeCode == m.wagonTypeCode && t.freightGroup) {
          t.freightGroup.forEach((f) => {
            if (f.freightEtsngCode == m.freightEtsngCode && f.tariffList) {
              f.tariffList.forEach((tl) => {
                if (
                  tl.pathDetails &&
                  tl.pathDetails.length &&
                  tl.pathDetails[0].stationCode == m.loadStationCode &&
                  tl.pathDetails[tl.pathDetails.length - 1].stationCode == m.destStationCode &&
                  tl.countryDetails
                ) {
                  m.countryList.forEach((cl) => {
                    cl.tariff = tl.countryDetails.find((cl2) => cl2.countryCode == cl.countryCode);
                  });
                }
              });
            }
          });
        }
      });
    }
    let byWagon = result.find((w) => w.wagonTypeCode === m.wagonTypeCode);
    if (!byWagon) {
      byWagon = {
        wagonTypeCode: m.wagonTypeCode,
        wagonTypeName: m.wagonTypeName,
        byFreight: [{ freightEtsngCode: m.freightEtsngCode, freightEtsngName: m.freightEtsngName, routes: [m] }],
      };
      result.push(byWagon);
    } else {
      let byFreight = byWagon.byFreight.find((f) => f.freightEtsngCode === m.freightEtsngCode);
      if (!byFreight) {
        byFreight = { freightEtsngCode: m.freightEtsngCode, freightEtsngName: m.freightEtsngName, routes: [m] };
        byWagon.byFreight.push(byFreight);
      } else {
        byFreight.routes.push(m);
      }
    }
  });
  return result;
};

export const claimCountryInteractionsTreeToModel = (
  tree: ClaimCountryInteractionsTree[],
  selected?: { [key: string]: any },
): ClaimCountryInteractionsModel[] => {
  const result: ClaimCountryInteractionsModel[] = [];
  tree.forEach((byWagon) => {
    byWagon.byFreight.forEach((byFreight) => {
      byFreight.routes.forEach((route) => {
        route.countryList.forEach((c) => {
          if (selected) {
            const key = generateClaimInteractionCountryKey(route, c);
            c.isActive = selected[key + ':isActive'];
            c.commission = selected[key + ':commission'];
          }
        });
        result.push(route);
      });
    });
  });
  return result;
};

export const claimCountryInteractionsModelToFormData = (model: ClaimCountryInteractionsModel[]) => {
  const result = {};
  model.forEach((m) => {
    m.countryList.forEach((c) => {
      const key = generateClaimInteractionCountryKey(m, c);
      result[key + ':isActive'] = c.isActive;
      result[key + ':commission'] = c.commission;
    });
  });
  return result;
};

export const generateClaimInteractionCountryKey = (data: ClaimCountryInteractionsModel, country: TariffCountryRangeInfo): string => {
  return `${data.wagonTypeCode}-${data.freightEtsngCode}-${data.loadStationCode}-${data.destStationCode}-${country.countryCode}`;
};

export interface ClaimCountryInteractionsModel {
  loadStationCode: string;
  loadStationName: string;
  destStationCode: string;
  destStationName: string;
  freightEtsngCode: string;
  freightEtsngName: string;
  wagonTypeCode: number;
  wagonTypeName: string;
  countryList: TariffCountryRangeInfo[];
}

export interface TariffCountryRangeInfo {
  /// код страны
  countryCode: number;
  countryName: string;
  /// расстояние в километрах
  range: number;
  /// Признак выбора
  isActive: boolean;
  commission: number;
  commissionNds?: number;
  commissionWithNds?: number;

  //вспомогательные поля
  tariff?: TariffCountryDetailModel;
  freightEtsngCode?: string;
  loadStationCode?: number;
  destStationCode?: number;
}

export type ClaimTableModel = {
  claimid: string;
  claimnumber: string;
  groupname: string;
  claimname: string;
  statusname: string;
  createdate: Date;
  executiondate: Date;
  producername: string;
  executorname: string;
  tags: string;
  companyname: string;
  loadstationname: string;
  loadroadname: string;
  deststationname: string;
  destroadname: string;
  wagoncount: number;
  validityfrom: Date;
  validityto: Date;
  plancost: number;
  markedwagoncount: number;
  sentwagoncountfact: number;
  remainingquantity: number;
  provisionpersentage: number;
  realizationpersentage: number;
  statusid: string;
  costfact: number;
  rowspan: number;
};

export class Page<T> {
  data: T[];
  groupCount: number;
  totalCount: number;
}

export type ClaimGroupModel = {
  groupId: string;
  groupName: string;
};

export interface ClaimLimitationsModel {
  freightSenders: OrganizationModel[];
  freightReceivers: OrganizationModel[];
  loadStations: StationModel[];
  destStations: StationModel[];
  wagonTypes: ClaimWagonInfoLimitation[];
  freightCodes: ClaimFreightServiceModel[];
  wagonModelExceptions: WagonModelInfo[];
}

export interface FreightEtsngModel {
  freightEtsngCode: string;
  freightEtsngShortCode: string;
  fullName: string;
  shortName: string;
}

export interface FreightGngModel {
  fullName: string;
  gngCode: string;
}

export interface EtranLastFreightModel {
  etsngFreightName: string;
  etsngFreightCode: string;
  gngFreightName: string;
  gngFreightCode: string;
}

export interface WagonModelInfo {
  modelInfoId: number;
  model: string;
  modelNumber: number | null;
  modelName: string;
  wagonType: string;
  additionalCharacteristic: string;
  modelFeature: string;
  modelSpecialization: string;
  technicalConditions: string;
  factory: string;
  frameMaterial: string;
  truck: string;
  volume: string;
  wagonBase: number | null;
  maxWidth: number | null;
  gostSize: string;
  releaseYear: number | null;
  graduationYear: number | null;
}

export interface ClaimCommercialInfo {
  claimId: string;
  calculateDate: Date | string;
  contractId: string | null;
  claimTariffModel: ClaimTariffModel;
  claimExtraServices: ClaimExtraServiceModel[];
  contracts: TemplateOrgContractModel[];
  tariffGroup: ClaimTariffWagonTypeGroup[];
  rentRateList: ClaimRentRateModel[];
  countryPaidTerritories: ClaimCountryInteractionsModel[];
}

export interface ClaimExtraServiceModel {
  claimId?: string;
  serviceId: number;
  serviceName?: string;
  price?: number;
  wagonTypeName?: string;
  wagonTypeCode: number;
}

export interface ClaimPerformanceInfo {
  claimPerformance: ClaimPerformanceData;
}

export interface ClaimPerformanceData {
  /**Кол-во вагонов по заявке */
  wagonCount: number;
  /**Кол-во вагонов, размеченных по заявке */
  markedWagonCount: number;
  /**Выполнено по заявке кол-во вагонов */
  doneWagonCount: number;
}

export const fillDefaults = (t: ClaimTariffDetailModel, ifClientPay: boolean, nds: number, totalOnly?: boolean) => {
  const companyNds = nds ? nds / 100 : 0;
  if (!t.coefficient) {
    t.coefficient = 1;
  }
  if (!t.expeditorCommissionRu) {
    t.expeditorCommissionRu = 0;
  }
  if (!totalOnly) {
    //для всех случаев применяем первый алгоритм 2022-09-07
    //if (ifClientPay) {
    //Если груж. тариф платит клиент:
    t.totalAmountRuWithVat = Math.round(t.commonParkFreightChargeRuWithVat * t.coefficient - t.rentParkFreightChargeRuWithVat);
    t.totalAmountRuVat = Math.round(t.commonParkFreightChargeRuVat * t.coefficient - t.rentParkFreightChargeRuVat);
    t.totalAmountRu = t.totalAmountRuWithVat - t.totalAmountRuVat;
    //} else {
    //Если груж. тариф платит ТК:
    //  t.totalAmountRuWithVat = Math.round(t.commonParkFreightChargeRuWithVat * t.coefficient);
    // t.totalAmountRuVat = Math.round(t.commonParkFreightChargeRuVat * t.coefficient);
    // t.totalAmountRu = t.totalAmountRuWithVat - t.totalAmountRuVat;
    //}
  }

  const expeditorCommissionRuWithVat = t.expeditorCommissionRu;
  const expeditorCommissionRuVat = (expeditorCommissionRuWithVat * nds) / (100 + nds);
  const expeditorCommissionRu = expeditorCommissionRuWithVat - expeditorCommissionRuVat;

  t.totalWithVat = t.totalAmountRuWithVat + expeditorCommissionRuWithVat;
  t.total = t.totalAmountRu + expeditorCommissionRu;
  t.totalVat = t.totalAmountRuVat + expeditorCommissionRuVat;
};

export interface ClaimContractInfoServiceModel {
  contractId?: string;
  contractExtIds: string[];
  partnerId?: string;
  commercialOfferStatusId: string;
  commercialOfferId: string;
  commercialOfferSendDate: string;
  commercialOfferSrcEntityId: string;
  commercialOfferStatusDate: string;
  commercialOfferStatusName: string;
  commercialOfferVariant: string;
}

export type CommercialOfferVariant = 'commonPark' | 'rent' | 'all' | 'onlyExpiditing'; // параметры модель алгоритма расчета:
export const CLAIM_STATUS_LIST = {
  onagr: 'На согласовании',
  onper: 'На исполнении',
  agr: 'Согласована',
  done: 'Закрыта',
};

export interface ClaimEmailMessageParams {
  subject: string;
  body: string;
  recipients: string[];
  sender: string;
}
