import { UserAddOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore';
import userStore from '@stores/userStore/userStore';

import OptionsProducerExt from './OptionsProducerExt';
import OptionsProducerRegular from './OptionsProducerRegular';

interface State {
  visible: boolean;
}

interface Props {}
@observer
class OptionsProducerBtn extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render() {
    const request = optionsStore.request;
    const etsngCode = request.freightEtsngCode;
    const loadStationCode = request.loadStationCode;

    const isExt = userStore.canAccess('tariff/ext-consumers');
    const width = isExt ? '800px' : '700px';
    const isMobile = designStore.isMobile;

    return (
      <>
        <Modal
          centered={true}
          open={this.state.visible}
          title={'Список распорядителей'}
          okButtonProps={{ style: { display: 'none' } }}
          width={isMobile ? '100%' : width}
          onCancel={() => this.setState({ visible: false })}>
          {isExt ? (
            this.state.visible ? (
              <OptionsProducerExt key={'ext'} etsngCode={etsngCode} loadStationCode={loadStationCode} />
            ) : null
          ) : (
            <OptionsProducerRegular key={'regular'} loadStationCode={loadStationCode} etsngCode={etsngCode} />
          )}
        </Modal>
        <Button type={'link'} icon={<UserAddOutlined />} disabled={true || !loadStationCode || !etsngCode} onClick={() => this.showMe()}>
          Подобрать исполнителя
        </Button>
      </>
    );
  }

  gtagProcess = (e) => {
    ReactGA.event({
      category: 'Tariff',
      action: e,
    });
  };

  showMe = () => {
    this.gtagProcess('producer');
    this.setState({ visible: true });
  };
}

export default OptionsProducerBtn;
