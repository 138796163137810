import { Spin } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

const MainPage = React.lazy(() => import('./MainPage'));

class MainPageWrapper extends Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  render() {
    return (
      <React.Suspense fallback={<Spin spinning={true} />}>
        <MainPage {...this.props} />
      </React.Suspense>
    );
  }
}

export default MainPageWrapper;
