import React, { Component } from 'react';

interface RequiredPlaceholderProps {
  value: string;
  mark?: string;
}
class RequiredPlaceholder extends Component<Readonly<RequiredPlaceholderProps>> {
  constructor(props: Readonly<RequiredPlaceholderProps>) {
    super(props);
  }

  render() {
    const mark = this.props.mark ?? '*';

    return (
      <span className="required-placeholder">
        {this.props.value} <span className={'required-mark'}>{mark}</span>
      </span>
    );
  }
}
export default RequiredPlaceholder;
