import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';

import { CustomReportDetailsModel } from '@stores/dispatcherStore/DispatcherData';
import dispatcherStore from '@stores/dispatcherStore/dispatcherStore';
import dispatcherService from '@stores/dispatcherStore/dispatcherStore.service';
import { DistributionModel } from '@stores/distributionStore/dictributionData';
import distributionStore from '@stores/distributionStore/distributionStore';
import distributionService from '@stores/distributionStore/distributionStore.service';
import userStore from '@stores/userStore/userStore';
import { UserModel } from '@stores/usersStore';

interface ComponentState {
  isBusy: boolean;
  visible: boolean;
  data: DistributionModel;
  users: UserModel[];
  reports: CustomReportDetailsModel[];
  search: string;
}

interface ComponentProps {
  data: DistributionModel;
}

class DistributionEditor extends Component<ComponentProps, ComponentState> {
  formRef: FormInstance;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      visible: false,
      data: this.props.data,
      users: [],
      reports: dispatcherStore.reportList,
      search: null,
    };
  }

  get isEdit() {
    return !!this.props.data.distributionId;
  }

  get isAdmin() {
    return ['admin', 'manager'].includes(userStore.userData.roleCode);
  }
  onOpenDrawer = () => {
    if (this.isAdmin) {
      const data = this.props.data;
      if (data.userId == userStore.userData.userId) {
        data.userId = null;
      }
      this.setState({ visible: true, data: this.props.data, search: null });
      Promise.all([this.fillUsers(), this.fillReports()]);
    } else {
      this.setState({ visible: true, data: this.props.data, reports: dispatcherStore.reportList });
    }
    setTimeout(() => {
      if (this.formRef) this.formRef.resetFields();
      //let input = document.getElementById("emails")
      //input.setAttribute('type', "email")
    }, 100);
  };

  async fillUsers() {
    this.setState({ users: await distributionService.getUsers() });
  }

  async fillReports() {
    if (this.state.data.userId) this.setState({ reports: await dispatcherService.getReportListByUserId(this.state.data.userId) });
    else this.setState({ reports: dispatcherStore.reportList });
  }

  handelClose = () => {
    this.setState({ visible: false });
  };

  onReportChange = (e) => {
    const data = this.state.data;
    data.customReportId = e;
    data.filterId = null;
    this.setState({ data });
    if (this.formRef) this.formRef.resetFields(['filterId']);
  };

  get days() {
    return [
      { value: 1, text: 'понедельник' },
      { value: 2, text: 'вторник' },
      { value: 3, text: 'среда' },
      { value: 4, text: 'четверг' },
      { value: 5, text: 'пятница' },
      { value: 6, text: 'суббота' },
      { value: 0, text: 'воскресенье' },
    ];
  }
  get times() {
    let result = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m = m + 30) {
        result.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m);
      }
    }
    return result;
  }

  onUserChange = (e) => {
    const data = this.state.data;
    data.userId = e;
    data.customReportId = null;
    data.filterId = null;
    this.setState({ data, search: null });
    this.fillReports();
    if (this.formRef) {
      this.formRef.resetFields(['customReportId', 'filterId']);
    }
  };

  render() {
    const data = this.state.data;
    const isBusy = this.state.isBusy;
    const users = this.state.search
      ? this.state.users.filter((u) => (u.companyName + u.name).toLowerCase().includes(this.state.search))
      : this.state.users;
    let filters = [];
    if (data.customReportId) {
      const find = this.state.reports.find((r) => r.id == data.customReportId);
      if (find) {
        filters = find.filters;
      }
    }

    return (
      <>
        {!this.isEdit ? (
          <Button icon={<PlusOutlined />} onClick={this.onOpenDrawer} size={'small'} type={'link'}>
            Создать
          </Button>
        ) : (
          <Button icon={<EditOutlined />} title={'изменить отчет'} size={'small'} type="text" onClick={this.onOpenDrawer} />
        )}
        <AdaptiveDrawer
          title={this.isEdit ? 'Редактирование' : 'Создание'}
          width={'60%'}
          onClose={this.handelClose}
          visible={this.state.visible}
          footer={[
            <Button key={'reject_btn'} onClick={this.handelClose}>
              Отмена
            </Button>,
            <Button loading={isBusy} key={'submit_btn'} type="primary" onClick={() => this.formRef.submit()}>
              Сохранить
            </Button>,
          ]}>
          {!this.isAdmin && !this.state.reports.length ? (
            <Empty description={'Сначала создайте отчет в разделе Диспетчер->Отчеты'} />
          ) : (
            <Form layout="horizontal" size={'large'} ref={(ref) => (this.formRef = ref)} initialValues={data} onFinish={this.handleConfirm}>
              {this.isAdmin && (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item name="userId" label="Пользователь" labelCol={{ span: 6 }}>
                      <Select
                        disabled={!!this.props.data.distributionId}
                        onChange={this.onUserChange}
                        showSearch
                        onSearch={(search) => this.setState({ search: search.toLocaleLowerCase() })}>
                        <Select.Option value={null}>Я</Select.Option>
                        {users.map((r) => (
                          <Select.Option value={r.userId} key={'user' + r.userId}>
                            {r.name} {r.companyName ? '(' + r.companyName + ')' : ''}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="emails"
                    label="Список адресов"
                    rules={[
                      { required: true, message: '' },
                      {
                        validator: (rule, value, callback) => {
                          if (!Array.isArray(value)) {
                            return Promise.reject('Укажите корректные адреса');
                          }
                          value.forEach((v) => {
                            if (!v || typeof v != 'string') {
                              return Promise.reject('Укажите корректные адреса');
                            }
                            if (v.indexOf('@') < 2 || v.indexOf('.') < 3) {
                              return Promise.reject('Укажите корректные адреса');
                            }
                          });
                          return Promise.resolve();
                        },
                      },
                    ]}
                    labelCol={{ span: 6 }}>
                    <Select
                      mode={'tags'}
                      placeholder="Выберите из ранее указанных или введите новый"
                      getPopupContainer={(trigger) => trigger.parentElement}>
                      {distributionStore.emails.map((e) => (
                        <Select.Option value={e} key={e}>
                          {e}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="customReportId" label="Отчет" rules={[{ required: true, message: '' }]} labelCol={{ span: 6 }}>
                    <Select onChange={this.onReportChange} getPopupContainer={(trigger) => trigger.parentElement}>
                      {this.state.reports.map((r) => (
                        <Select.Option value={r.id} key={'report' + r.id}>
                          {r.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="filterId"
                    label="Фильтр"
                    labelCol={{ span: 6 }}
                    extra={<h6>*Рассылки по фильтрам с группировкой могут формироваться некорректно</h6>}>
                    <Select getPopupContainer={(trigger) => trigger.parentElement}>
                      <Select.Option value={null}>Без фильтра</Select.Option>
                      {filters.map((r) => (
                        <Select.Option key={'filter' + r.filterId} value={r.filterId}>
                          {r.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="times"
                    label="Время"
                    rules={[
                      { required: true, message: '' },
                      {
                        validator: (rule, value, callback) => {
                          if (!Array.isArray(value)) {
                            return Promise.reject('Укажите время в формате ЧЧ:ММ');
                          }
                          value.forEach((v) => {
                            if (!v || typeof v != 'string') {
                              return Promise.reject('Укажите время в формате ЧЧ:ММ');
                            }
                            let split = v.split(':');
                            if (split.length != 2) {
                              return Promise.reject('Укажите время в формате ЧЧ:ММ');
                            }
                            if (split[0].length != 2 || isNaN(Number(split[0])) || Number(split[0]) > 23) {
                              return Promise.reject('Укажите время в формате ЧЧ:ММ');
                            }
                            if (split[1].length != 2 || isNaN(Number(split[1])) || Number(split[1]) > 59) {
                              return Promise.reject('Укажите время в формате ЧЧ:ММ');
                            }
                          });
                          return Promise.resolve();
                        },
                      },
                    ]}
                    labelCol={{ span: 6 }}>
                    <Select
                      mode={'tags'}
                      placeholder="Выберите из списка или укажите с точностью до 1 минуты"
                      getPopupContainer={(trigger) => trigger.parentElement}>
                      {this.times.map((t) => (
                        <Select.Option value={t} key={t}>
                          {t}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="days" label="Дни недели" rules={[{ required: true, message: '' }]} labelCol={{ span: 6 }}>
                    <Select mode={'multiple'} optionFilterProp="children" getPopupContainer={(trigger) => trigger.parentElement}>
                      {this.days.map((d) => (
                        <Select.Option key={'day' + d.value} value={d.value}>
                          {d.text}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </AdaptiveDrawer>
      </>
    );
  }
  handleConfirm = (e) => {
    this.setState({ isBusy: true });
    e.distributionId = this.props.data.distributionId;
    distributionStore
      .update(e)
      .then(() => {
        this.setState({ visible: false });
      })
      .finally(() => this.setState({ isBusy: false }));
  };
}

export default observer(DistributionEditor);
