import * as Sentry from '@sentry/browser';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/es/locale-provider/ru_RU';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import 'moment/locale/ru';
import packageInfo from 'package.json';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import 'react-leaflet-markercluster/dist/styles.min.css';

import App from './components/app/App';
import config from './config';
import './default2.less';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const { sentryDns } = config;

//ReactGA.initialize('G-4LPXH686KY', {debug:true});
ReactGA.initialize('UA-182669883-1', { debug: true });

ReactGA.pageview(window.location.pathname + window.location.search);

Sentry.init({ dsn: sentryDns, release: packageInfo.version, ignoreErrors: ['ResizeObserver loop limit exceeded'] });

moment.locale('ru');

ReactDOM.render(
  <ConfigProvider locale={ru_RU}>
    <App />
  </ConfigProvider>,
  document.getElementById('root'),
);
serviceWorkerRegistration.register();
