import { observer } from 'mobx-react';
import React, { Component } from 'react';

import TrackingActiveTable from '@components/fw/TrackingActiveTable';

import trackingWagonStore from '@stores/trackingStore/wagon/trackingWagonStore';

interface TrackingWagonsActiveProps {}

interface TrackingWagonsActiveState {}

@observer
class TrackingWagonsActive extends Component<TrackingWagonsActiveProps, TrackingWagonsActiveState> {
  constructor(props: TrackingWagonsActiveProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    trackingWagonStore.loadActive();
  }
  handleDataChanged() {
    trackingWagonStore.loadActive();
  }
  handleSelectionChanged = (data: any) => {
    trackingWagonStore.selected = data;
  };

  render() {
    const isBusy = trackingWagonStore.isBusyActive;
    const data = trackingWagonStore.active;
    return (
      <>
        <TrackingActiveTable value={data} isBusy={isBusy} type={'wagon'} onSelectionChanged={this.handleSelectionChanged} />
      </>
    );
  }
}

export default TrackingWagonsActive;
