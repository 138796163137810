import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Spin } from 'antd';
import Search from 'antd/es/input/Search';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';
import styles from '@components/fw/TagEditor/TagEditor.module.scss';

import { FilterContentModel } from '@stores/FilterStore/filterStore';

import { CREATE_NEW_TITLE, EMPTY_FILTER, ON_REMOVE_ALERT } from './words';

interface FilterListProps {
  data: FilterContentModel[];
  selected: FilterContentModel;
  onClick(data: FilterContentModel): void;
  onAddNew(): void;
  onEdit(data: FilterContentModel | null): void;
  onDelete(data: FilterContentModel): void;
  isBusy: boolean;
}

interface FilterListState {
  search: string | null;
}

class FilterList extends Component<FilterListProps, FilterListState> {
  constructor(props: FilterListProps) {
    super(props);
    this.state = {
      search: null,
    };
  }

  render() {
    const { isBusy, onClick, selected, onEdit, onDelete, onAddNew } = this.props;
    const { search } = this.state;
    const data = this.props.data.filter((i) => !search || i.name.toLowerCase().includes(search.toLowerCase()));

    return (
      <>
        <Search
          placeholder="поиск"
          onChange={(e) =>
            this.setState({
              search: e.target.value,
            })
          }
        />
        <ScrolledContainer className={'scrolledContainer'} style={{ maxHeight: '400px' }}>
          <Spin spinning={isBusy}>
            <div>
              <span style={{ cursor: 'pointer' }} onClick={() => onClick(null)}>
                {!selected ? <CheckOutlined style={{ marginRight: 5 }} /> : null}
                {EMPTY_FILTER}
              </span>
            </div>
            {data.map((tag, index) => {
              const isContains = selected && selected.filterId == tag.filterId;
              return (
                <div key={`tag_all_${index}`}>
                  <span style={{ cursor: 'pointer' }} onClick={() => onClick(tag)}>
                    {isContains ? <CheckOutlined style={{ marginRight: 5 }} /> : null}
                    {tag.name}
                  </span>
                  <span className={styles.tag_list_icons_block}>
                    <EditOutlined className={styles.tag_list_icon} onClick={() => onEdit(tag)} />
                    <Popconfirm title={ON_REMOVE_ALERT} onConfirm={() => onDelete(tag)}>
                      <DeleteOutlined className={styles.tag_list_icon} />
                    </Popconfirm>
                  </span>
                </div>
              );
            })}
          </Spin>

          <Divider />
          <div>
            <Button type="dashed" block onClick={onAddNew}>
              {CREATE_NEW_TITLE}
            </Button>
          </div>
        </ScrolledContainer>
      </>
    );
  }
}

export default observer(FilterList);
