import { Button } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import SendInfo from '@components/routed/SendInfo';

interface Props extends RouteComponentProps {
  claimId: string;
  sendId: string;
  onChange?(): void;
  onClose(): void;
}

interface State {}

class SendEditorDrawer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AdaptiveDrawer
        closeIcon={null}
        width={'90%'}
        visible={true}
        className={'send-editor-drawer'}
        onClose={() => this.props.onClose()}
        footer={[
          <Button key={'close'} onClick={() => this.props.onClose()}>
            Закрыть
          </Button>,
        ]}>
        <SendInfo {...this.props} />
      </AdaptiveDrawer>
    );
  }
}

export default SendEditorDrawer;
