import React from 'react';

const CapitalizeDxCell: React.FC<any> = (props) => {
  const value = props.value;

  if (!value || typeof value !== 'string') return <span></span>;

  const result = value.length > 0 ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : value.charAt(0).toUpperCase();

  return <span>{result}</span>;
};

export default CapitalizeDxCell;
