import { PageHeader, PageHeaderProps } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

interface State {}

class AdaptivePageHeader extends Component<PageHeaderProps, State> {
  render() {
    const isMobile = designStore.isMobile;
    const props = { ...this.props };
    return <PageHeader {...props}>{this.props.children}</PageHeader>;
  }
}

export default observer(AdaptivePageHeader);
