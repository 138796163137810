import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Badge, Button, Popover, Upload, message } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import messengerStore from '@stores/messengerStore/messengerStore';

import { getBase64 } from '@utils/files';

const PopoverContent = (props: { attachments; handleDelete }) => {
  if (props.attachments.length > 0) {
    return (
      <>
        <span>{props.attachments[0].name}</span>
        <Button type={'link'} icon={<DeleteOutlined />} onClick={props.handleDelete} />
      </>
    );
  } else {
    return null;
  }
};

@observer
class FileAttacher extends Component {
  // @ts-ignore
  uploader: Upload;
  render() {
    const attachments = messengerStore.attachments;
    const props = {
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
          message.error('Извините, но размер больше 5Мб');
        }

        // getBytes(file, (result) => {
        //   messengerStore.attachments = [Object.assign(file, { bytes: result })];
        // });

        getBase64(file, (url) => {
          messengerStore.attachments = [Object.assign(file, { fileUrl: url })];
        });

        return false;
      },
      onRemove: (file) => {
        const index = messengerStore.attachments.indexOf(file);
        const newFileList = messengerStore.attachments.slice();
        newFileList.splice(index, 1);
        messengerStore.attachments = newFileList;
      },
      fileList: attachments,
      showUploadList: null,
    };
    return (
      <>
        {attachments.length > 0 ? (
          <Popover
            trigger="hover"
            title={'Вложение'}
            content={<PopoverContent attachments={attachments} handleDelete={this.handleDelete} />}>
            <div>
              <Upload {...props} onChange={this.handleAttach}>
                <Button
                  type="link"
                  size={'large'}
                  icon={
                    <Badge dot>
                      <PaperClipOutlined />
                    </Badge>
                  }
                  onClick={() => this.uploader}
                  title={'Вложения'}
                />
              </Upload>
            </div>
          </Popover>
        ) : (
          <Upload {...props} onChange={this.handleAttach}>
            <Button type="link" size={'large'} icon={<PaperClipOutlined />} onClick={() => this.uploader} title={'Вложения'} />
          </Upload>
        )}
      </>
    );
  }

  handleDelete = (e) => {
    messengerStore.attachments = [];
  };

  handleAttach = (e) => {};
}

export default FileAttacher;
