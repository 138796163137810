import { BidRegistryCommercialStore } from '@stores/bidRegistryCommercialStore/bidRegistryCommercialStore';

import { ClaimCountryInteractionsTree, CountryPaidTerritories, TariffGroup, WagonProvision } from './bidRegistryCommercialStore.types';

export const wagonProvisionGroupName = 'wagonProvision';
export const expeditionGroupName = 'expedition';
export const tariffDetailsGroupName = 'tariffDetails';

export const sortByFreightEtsng = (wagonProvisions?: WagonProvision[]): { [p: string]: WagonProvision[] } => {
  if (!wagonProvisions) {
    return {};
  }

  return wagonProvisions.reduce((initialValues, wagonProvision) => {
    const { freightEtsngName } = wagonProvision;
    if (initialValues[freightEtsngName]) {
      initialValues[freightEtsngName].push(wagonProvision);
      return initialValues;
    }

    initialValues[freightEtsngName] = [wagonProvision];
    return initialValues;
  }, {});
};

export const flatRollingStock = (sortWagonProvisions: { [p: string]: WagonProvision[] }) => {
  return Object.values<WagonProvision[]>(sortWagonProvisions).reduce((prev, wagonProvisions: WagonProvision[]) => {
    return {
      ...prev,
      ...wagonProvisions.reduce((prev, wagonProvision) => {
        return {
          ...prev,
          ...Object.fromEntries(
            Object.entries(wagonProvision).map(([key, value]) => {
              const { freightEtsngCode, loadStationCode, destStationCode } = wagonProvision;
              const flatKey = `${wagonProvisionGroupName}-${key}-${freightEtsngCode}-${loadStationCode}-${destStationCode}`;
              return [flatKey, value];
            }),
          ),
        };
      }, {}),
    };
  }, {});
};

export const packingRollingStock = (flatRollingStock) => {
  const values = { ...BidRegistryCommercialStore.flatRollingStocks, ...flatRollingStock };
  return Object.entries(values).reduce<WagonProvision[]>((prev, [key, value]) => {
    const [groupName, fieldName, freightEtsngCode, loadStationCode, destStationCode] = key.split('-');
    if (groupName !== wagonProvisionGroupName) {
      return prev;
    }
    const pack = {
      [fieldName]: value,
      loadStationCode: Number(loadStationCode),
      destStationCode: Number(destStationCode),
      freightEtsngCode,
    } as unknown as WagonProvision;

    if (
      !prev.find(
        (wagonProvision) =>
          wagonProvision.loadStationCode === Number(loadStationCode) &&
          wagonProvision.destStationCode === Number(destStationCode) &&
          wagonProvision.freightEtsngCode === freightEtsngCode,
      )
    ) {
      prev.push(pack);
      return prev;
    }

    const index = prev.findIndex(
      (wagonProvision) =>
        wagonProvision.loadStationCode === Number(loadStationCode) &&
        wagonProvision.destStationCode === Number(destStationCode) &&
        wagonProvision.freightEtsngCode === freightEtsngCode,
    );

    prev[index] = { ...prev[index], [fieldName]: value };

    return prev;
  }, []);
};

export const totalingRollingStock = (flatRollingStock) => {
  return packingRollingStock({ ...BidRegistryCommercialStore.flatRollingStocks, ...flatRollingStock }).filter((stock) => stock.isActive);
};

export const totalingSums = (flatCountryPaid) => {
  const wagons = packingRollingStock(flatCountryPaid).filter((route) => route.isActive);
  const countries = packingCountryPaidTerritories({ ...flatCountryPaid })
    .map((route) => {
      const isActivatedRoutes = route.countryList.filter((country) => country.isActive);
      return { ...route, countryList: [...isActivatedRoutes] };
    })
    .filter((route) => {
      return route.countryList.length;
    });

  return { wagons, countries };
};

export const sortCountryPaidTerritoriesModelToTree = (
  countryPaidTerritories?: CountryPaidTerritories[],
  tariffGroup?: TariffGroup[],
): { [p: string]: any } => {
  if (!countryPaidTerritories) {
    return [];
  }

  const result: ClaimCountryInteractionsTree[] = [];

  countryPaidTerritories.forEach((country) => {
    if (tariffGroup) {
      tariffGroup.forEach((group) => {
        if (group.freightEtsngCode == country.freightEtsngCode && group.tariffList) {
          group.tariffList.forEach((tariff) => {
            if (
              tariff.pathDetails &&
              tariff.pathDetails.length &&
              tariff.pathDetails[0].stationCode == country.loadStationCode &&
              tariff.pathDetails[tariff.pathDetails.length - 1].stationCode == country.destStationCode &&
              tariff.countryDetails
            ) {
              country.countryList.forEach((cl) => {
                cl.tariff = tariff.countryDetails.find((cl2) => cl2.countryCode == cl.countryCode);
              });
            }
          });
        }
      });
    }

    const existFreightIndex = result.findIndex(({ freightEtsngCode }) => freightEtsngCode === country.freightEtsngCode);

    if (existFreightIndex !== -1) {
      result[existFreightIndex].routes.push(country);
    } else {
      result.push({ freightEtsngCode: country.freightEtsngCode, freightEtsngName: country.freightEtsngName, routes: [country] });
    }
  });

  return result;
};

export const totalSums = (countryModelTree: ClaimCountryInteractionsTree[], extraServices, wagons: WagonProvision[]) => {
  const total = [];

  const extraServicesPrice = extraServices?.filter((service) => Boolean(service)).reduce((prev, service) => prev + service.price, 0) || 0;
  countryModelTree.forEach(({ routes, freightEtsngName, freightEtsngCode }) => {
    const freight = { freightEtsngCode, freightEtsngName, routes: [] };
    routes.forEach((route) => {
      const filteredCountries = route.countryList
        .filter(({ isActive }) => isActive)
        .reduce(
          (prev, { commission, tariff: { freightChargeRuWithVat, freightChargeRuVat, freightChargeRu } }) => {
            const wagonSum = wagons.find(({ loadStationCode, destStationCode, ...wagon }) => {
              return (
                wagon.freightEtsngCode === freightEtsngCode &&
                String(loadStationCode) == route.loadStationCode &&
                String(destStationCode) == route.destStationCode
              );
            });
            const commissionWithNds = commission + (commission * Number(BidRegistryCommercialStore.commercial.serviceVatPercentage)) / 100;
            const commissionNds = (commission * Number(BidRegistryCommercialStore.commercial.serviceVatPercentage)) / 100;

            let servicePriceWidthNds = 0;
            let servicePriceNds = 0;
            if (extraServicesPrice) {
              servicePriceWidthNds =
                extraServicesPrice + (extraServicesPrice * Number(BidRegistryCommercialStore.commercial.serviceVatPercentage)) / 100;
              servicePriceNds = (extraServicesPrice * Number(BidRegistryCommercialStore.commercial.serviceVatPercentage)) / 100;
            }

            let wagonPrice = 0;
            let wagonPriceNds = 0;
            let wagonPriceWithNds = 0;
            if (wagonSum) {
              wagonPrice += wagonSum.priceWithoutNds;
              //@ts-ignore
              wagonPriceNds += 0;
              //@ts-ignore
              wagonPriceWithNds += wagonSum.priceWithoutNds;
            }
            prev = {
              commission: prev.commission + commission + freightChargeRu + extraServicesPrice + wagonPrice,
              commissionNds: prev.commissionNds + Number(commissionNds) + freightChargeRuVat + servicePriceNds + wagonPriceNds,
              commissionWithNds:
                prev.commissionWithNds + Number(commissionWithNds) + freightChargeRuWithVat + servicePriceWidthNds + wagonPriceWithNds,
            };
            return prev;
          },
          { commission: 0, commissionNds: 0, commissionWithNds: 0 },
        );
      const filteredRoute = { ...route, countryList: [filteredCountries] };
      freight.routes.push(filteredRoute);
    });
    total.push(freight);
  });

  wagons
    .filter(({ isActive }) => isActive)
    .forEach(
      ({ destStationCode, loadStationCode, freightEtsngCode, freightEtsngName, destStationName, loadStationName, priceWithoutNds }) => {
        const totalByFreight = total.find(({ freightEtsngCode: totalFreightEtsngCode }) => totalFreightEtsngCode === freightEtsngCode);
        const totalRoute = totalByFreight?.routes.find(
          ({ destStationCode: totalDestStationCode, loadStationCode: totalLoadStationCode }) => {
            return totalDestStationCode === destStationCode && totalLoadStationCode === loadStationCode;
          },
        );

        if (totalRoute) {
          return;
        }

        let servicePriceWidthNds = 0;
        let servicePriceNds = 0;
        if (extraServicesPrice) {
          servicePriceWidthNds =
            extraServicesPrice + (extraServicesPrice * Number(BidRegistryCommercialStore.commercial.serviceVatPercentage)) / 100;
          servicePriceNds = (extraServicesPrice * Number(BidRegistryCommercialStore.commercial.serviceVatPercentage)) / 100;
        }

        const freight = { freightEtsngCode, freightEtsngName, routes: [] };
        freight.routes = [
          {
            destStationCode,
            destStationName,
            loadStationName,
            loadStationCode,
            freightEtsngCode,
            freightEtsngName,
            countryList: [
              {
                commission: priceWithoutNds + extraServicesPrice || 0,
                commissionNds: servicePriceNds || 0,
                commissionWithNds: priceWithoutNds + servicePriceWidthNds || 0,
              },
            ],
          },
        ];
        total.push(freight);
      },
    );

  return total;
};

export const flatCountryPaidTerritories = (countryPaidTerritories, tariffGroup) => {
  return countryPaidTerritories.reduce((initialValue, country) => {
    const { freightEtsngCode, loadStationCode, destStationCode, freightEtsngName } = country;
    const key = `${freightEtsngCode}-${loadStationCode}-${destStationCode}`;

    const flattedCountry = Object.fromEntries(
      Object.entries(country).map(([modelKey, value]) => {
        if (modelKey === 'countryList') {
          const countryListValues = country[modelKey].reduce((prev, country) => {
            const countryObject = Object.entries(country).map(([filedName, value]) => {
              const { countryCode } = country;
              const currentKey = `${expeditionGroupName}-${filedName}-${key}-${countryCode}`;
              return [currentKey, value];
            });
            prev.push(...countryObject);
            return prev;
          }, []);

          // @ts-ignore
          return [modelKey, Object.fromEntries(countryListValues)];
        }
        const currentKey = `${key}-${modelKey}`;
        return [currentKey, value];
      }),
    );

    const group = tariffGroup.find((group) => group.freightEtsngCode === freightEtsngCode && group.tariffList);

    const tariff = group.tariffList.find(
      (tariff) =>
        tariff.pathDetails &&
        tariff.pathDetails.length &&
        tariff.pathDetails[0].stationCode == country.loadStationCode &&
        tariff.pathDetails[tariff.pathDetails.length - 1].stationCode == country.destStationCode &&
        tariff.countryDetails,
    );

    const tariffDetails = tariff.countryDetails.reduce((init, currentDetail) => {
      const flattedDetails = Object.fromEntries(
        Object.entries(currentDetail).map(([fieldName, value]) => {
          return [`${tariffDetailsGroupName}-${fieldName}-${key}`, value];
        }),
      );
      return { ...init, ...flattedDetails };
    }, {});

    const flattedCountryList = flattedCountry.countryList;
    delete flattedCountry.countryList;
    return { ...initialValue, ...flattedCountry, ...flattedCountryList, ...tariffDetails };
  }, {});
};

export const packingCountryPaidTerritories = (flatCountryPaidTree) => {
  const values = { ...BidRegistryCommercialStore.flatCountryPaidTree, ...flatCountryPaidTree };
  return Object.entries(values).reduce((prev, [key, value]) => {
    const [groupName, fieldName, freightEtsngCode, loadStationCode, destStationCode, countryCode] = key.split('-');
    if (groupName !== expeditionGroupName) {
      return prev;
    }
    const pack = {
      loadStationCode: Number(loadStationCode),
      destStationCode: Number(destStationCode),
      freightEtsngName: values[`${freightEtsngCode}-${loadStationCode}-${destStationCode}-freightEtsngName`],
      destStationName: values[`${freightEtsngCode}-${loadStationCode}-${destStationCode}-destStationName`],
      loadStationName: values[`${freightEtsngCode}-${loadStationCode}-${destStationCode}-loadStationName`],
      freightEtsngCode,
    };

    const index = prev.findIndex(
      (paidTerritories) =>
        paidTerritories.loadStationCode === Number(loadStationCode) &&
        paidTerritories.destStationCode === Number(destStationCode) &&
        paidTerritories.freightEtsngCode === freightEtsngCode,
    );

    const countryListPack = {
      countryCode: Number(countryCode),
      [fieldName]: value,
    };

    if (index === -1) {
      pack['countryList'] = [countryListPack];
    }

    if (index !== -1) {
      if (!prev[index].countryList) {
        prev[index].countryList = [countryListPack];
        return prev;
      }

      if (prev[index].countryList) {
        const countryIndex = prev[index].countryList.findIndex((country) => country.countryCode == countryCode);

        if (countryIndex === -1) {
          prev[index].countryList.push(countryListPack);
          return prev;
        } else {
          prev[index].countryList[countryIndex] = { ...prev[index].countryList[countryIndex], ...countryListPack };
          return prev;
        }
      }
    }

    if (index === -1) {
      prev.push(pack);
      return prev;
    }
    prev[index] = { ...prev[index], [fieldName]: value };

    return prev;
  }, []);
};
