import { MailOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React from 'react';

const showErrorNotify = (title: string, description: string) => {
  const args = {
    message: title,
    description: description,
    duration: 5,
    style: {
      //width: 500,
      //wordBreak: 'breakWord'
      marginLeft: 350 - 500,
    },
  };

  notification.error(args);
};

const showSuccessNotify = (title: string, description?: any) => {
  const args = {
    message: title,
    description: description,
    duration: 5,
    style: {
      //width: 500,
      //wordBreak: 'breakWord'
      marginLeft: 350 - 500,
    },
  };

  notification.success(args);
};

const newMessagesNotify = (message: string) => {
  notification.open({
    message: `Новое сообщение`,
    description: message,
    icon: <MailOutlined style={{ color: '#108ee9' }} />,
  });
};

export { showErrorNotify, showSuccessNotify, newMessagesNotify };
