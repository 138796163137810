import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, FormInstance, InputNumber, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import optionsService from '@stores/optionsStore/optionsStore.service';
import {
  GLOBAL_DATE_FORMAT,
  TariffProducerRequest,
  TariffProducerResponse,
  TariffProducerWagonInfo,
  defaultTariffProducerRequest,
} from '@stores/optionsStore/optionsStoreData';

const { RangePicker } = DatePicker;

interface State {
  isBusy: boolean;
  data: TariffProducerResponse[];
  selectedOrg: TariffProducerResponse;
  selectedInfo: TariffProducerWagonInfo;
  range: any[];
}

interface Props {
  loadStationCode: string;
  etsngCode: string;
}

class OptionsProducerExt extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: false,
      data: null,
      selectedOrg: null,
      selectedInfo: null,
      range: null,
    };
  }
  form: FormInstance;
  onSubmit = (e: TariffProducerRequest) => {
    e.loadStationCode = this.props.loadStationCode;
    e.producerCount = 100;
    e.etsngCode = this.props.etsngCode;
    e.arrivalDateBegin = moment(e.range[0]).startOf('day').toISOString(true);
    e.arrivalDateEnd = moment(e.range[1]).startOf('day').toISOString(true);
    this.setState({ isBusy: true });
    optionsService
      .tariffProducerExt(e)
      .then((data) => {
        data.forEach((byDate) => {
          byDate.wagonInfo.forEach((info) => {
            info.stations.forEach((station) => {
              station.empty = station.models.reduce((sum, current) => sum + current.empty, 0);
              station.loaded = station.models.reduce((sum, current) => sum + current.loaded, 0);
            });
          });
        });
        this.setState({ data, range: e.range });
      })
      .finally(() => this.setState({ isBusy: false }));
  };

  render() {
    if (!this.state.data) return this.renderForm();
    else if (this.state.selectedInfo) return this.renderDetail();
    else return this.renderData();
  }

  disabledDates = (current) => {
    const range = this.state.range;
    let result = current < moment().subtract(1, 'day');
    if (range) {
      if (range[0]) return current > moment(range[0]).add(7, 'day') || result;
      if (range[1]) return current < moment(range[1]).subtract(7, 'day') || result;
    }
    return result;
  };

  renderForm = () => {
    return (
      <Form
        ref={(ref) => (this.form = ref)}
        size={'large'}
        layout={'horizontal'}
        labelCol={{ span: 24 }}
        initialValues={defaultTariffProducerRequest}
        onFinish={this.onSubmit}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Form.Item name={'stationRadius'} label={'Радиус поиска'} rules={[{ required: true }]}>
              <InputNumber max={200} min={50} step={1} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={'range'} label={'Интервал времени прибытия'} rules={[{ required: true }]}>
              <RangePicker
                onCalendarChange={(e) => this.setState({ range: e })}
                disabledDate={this.disabledDates}
                format={GLOBAL_DATE_FORMAT}
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button htmlType={'submit'} type={'primary'} loading={this.state.isBusy}>
              Запросить
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  renderData = () => {
    let dates = [];
    const range = this.state.range;
    let current = moment(range[0]).startOf('day');
    const end = moment(range[1]).endOf('day');
    while (current < end) {
      dates.push(current.clone());
      current = current.add(1, 'day');
    }
    return (
      <div>
        <Button onClick={() => this.setState({ data: null, range: null })} type={'primary'}>
          Сброс
        </Button>
        <ScrolledContainer>
          <table className={'tariffTable'}>
            <thead>
              <tr>
                <th> </th>
                {dates.map((d) => (
                  <th key={d.toString()}>{moment(d).locale('ru').format('MMM DD')}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((p) => (
                <tr key={p.okpo}>
                  <td>{p.shortNameReq}</td>
                  {dates.map((d) => {
                    const find = p.wagonInfo.find(
                      (i) => moment(i.date).format(GLOBAL_DATE_FORMAT) === moment(d).format(GLOBAL_DATE_FORMAT),
                    );
                    if (find) {
                      const loaded = find.stations.reduce((sum, current) => sum + current.loaded, 0);
                      const empty = find.stations.reduce((sum, current) => sum + current.empty, 0);
                      return (
                        <td key={p.okpo + d.toString()}>
                          <Button type={'link'} onClick={() => this.setState({ selectedOrg: p, selectedInfo: find })}>
                            {loaded + '/' + empty}
                          </Button>{' '}
                        </td>
                      );
                    } else return <td key={p.okpo + d.toString()}> </td>;
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </ScrolledContainer>
      </div>
    );
  };
  renderDetail = () => {
    const org = this.state.selectedOrg;
    const wagons = this.state.selectedInfo;
    return (
      <AdaptiveCard
        title={
          <span>
            <Button icon={<ArrowLeftOutlined />} onClick={() => this.setState({ selectedInfo: null, selectedOrg: null })} />
            {org.shortNameReq}, на {moment(wagons.date).format(GLOBAL_DATE_FORMAT)}
          </span>
        }
        actions={[
          <div key={'1'}>
            ОКПО: {org.okpo}. {org.address} <b>{org.telephone}</b>
          </div>,
        ]}
        extra={<Button icon={<CloseOutlined />} onClick={() => this.setState({ selectedInfo: null, selectedOrg: null })} />}>
        <table className={'tariffTable'}>
          <thead>
            <tr>
              <th>Станция</th>
              <th>Модель вагона</th>
              <th>Груженых</th>
              <th>Порожних</th>
            </tr>
          </thead>
          <tbody>
            {wagons.stations.map((w, index) => {
              const length = w.models.length;
              if (length > 1) {
                return (
                  <>
                    <tr key={w.name + index}>
                      <td rowSpan={w.models.length + 1}>{w.name}</td>
                      <td>Все модели</td>
                      <td>{w.loaded}</td>
                      <td>{w.empty}</td>
                    </tr>
                    {w.models.map((m, index2) => (
                      <tr key={w.name + index + m.name + index2}>
                        <td>{m.name}</td>
                        <td>{m.loaded}</td>
                        <td>{m.empty}</td>
                      </tr>
                    ))}
                  </>
                );
              } else {
                return (
                  <tr key={w.name + index}>
                    <td>{w.name}</td>
                    <td>{w.models[0].name}</td>
                    <td>{w.loaded}</td>
                    <td>{w.empty}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </AdaptiveCard>
    );
  };
}

export default OptionsProducerExt;
