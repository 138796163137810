import Icon from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ReactComponent as helpline } from '@assets/icons/helpline.svg';

import MessengerPageEmbedded from '@components/routed/MessengerPage/MessengerPageEmbedded';

import designStore from '@stores/designStore/designStore';
import messengerStore from '@stores/messengerStore/messengerStore';
import userStore from '@stores/userStore/userStore';

interface State {
  visible: boolean;
}

interface Props extends RouteComponentProps {
  forTariff?: boolean;
}

class SupportModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  handleVisibleChange = (e) => {
    if (!designStore.isMobile) {
      this.setState({ visible: e });
    }
    if (e) {
      //@ts-ignore
      let find = messengerStore.dialogs.find((d) => d.dialogType == 'Tech');
      messengerStore.selectDialog(find);
      if (designStore.isMobile) {
        this.props.history.push('/messenger');
        this.setState({ visible: false });
      }
    }
  };

  render() {
    if (['admin', 'manager', 'tech'].includes(userStore.userData.roleCode)) return null;
    //@ts-ignore
    let find = messengerStore.dialogs.find((d) => d.dialogType == 'Tech');
    const count = find ? find.unreadMessageCount : 0;
    const visible = this.state.visible;
    const forTariff = this.props.forTariff;
    return (
      <>
        <Popover
          title={'Сообщение в техподдержку'}
          trigger={'click'}
          visible={visible}
          onVisibleChange={this.handleVisibleChange}
          content={<MessengerPageEmbedded />}>
          <Button
            onClick={this.handleOpen}
            icon={count ? null : <Icon component={helpline} />}
            title={'Сообщение в техподдержку'}
            size={forTariff ? null : 'large'}
            danger={!!count}
            shape={forTariff ? null : 'circle'}
            type={forTariff ? null : 'primary'}>
            {count ? count : null}
            {forTariff ? ' Техподдержка' : null}
          </Button>
        </Popover>
      </>
    );
  }
  handleOpen = () => {
    this.setState({ visible: true });
  };

  handelClose = () => {
    this.setState({ visible: false });
  };
}

export default observer(SupportModal);
