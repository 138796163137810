import { action, makeObservable, observable } from 'mobx';

import dictService from '@stores/dictStore/dictStore.service';

import { BillingProfile } from '../companiesStore/companyData';
import userStore from '../userStore/userStore';
import { CountryModel, WagonOrContainer } from './CompanyData';
import service from './companyStore.service';

class CompanyStore {
  @observable isBusy: boolean = false;
  @observable countries: CountryModel[] = [];
  @observable billingProfiles: BillingProfile[] = [];

  constructor() {
    makeObservable(this);
  }

  @action async getCountries(type: WagonOrContainer, billingProfileId?: string) {
    if (!userStore.userData) return;
    if (!userStore.userData.companyId) {
      this.isBusy = true;
      dictService
        .countryDict(null)
        .finally(() => (this.isBusy = false))
        .then((result) => {
          this.countries = result;
        });
    }
    this.isBusy = true;
    await service
      .getCountries(type, billingProfileId)
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.countries = result;
      });
  }
  @action async loadBillingProfiles(companyId: string) {
    return await service.getBillingProfiles(companyId).then((ans) => {
      this.billingProfiles = ans;
      return ans;
    });
  }
}

export default new CompanyStore();
