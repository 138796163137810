import { Form, Select } from 'antd';
import React from 'react';

import styles from '@components/fw/Fields/field.module.scss';

import { FieldSelectProps as Props } from './select.types';

export const FieldSelect = ({ name, children, rules, ...selectProps }: Props) => {
  return (
    <Form.Item className={styles.fieldFlexAuto} rules={rules} name={name}>
      <Select {...selectProps}>{children}</Select>
    </Form.Item>
  );
};
