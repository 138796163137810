import HttpClient from '@utils/httpClient';

import { ClaimReport, CreateRequest, CreateSend, IlsClaimHistoryReport, IlsClaimWagon, Send, StateType } from './requestIlsStoreData';

const apiClaim = '/api/ilsClaim';
const apiSend = '/api/ilsSend';
const apiWagon = '/api/ilsClaimWagon';

const requestIlsStoreService = {
  async getData(data: any): Promise<ClaimReport[]> {
    return (await HttpClient.post(`${apiClaim}/report`, data)).data;
  },
  async editData(data: ClaimReport): Promise<void> {
    return (await HttpClient.put(`${apiClaim}`, data)).data;
  },
  async createData(data: CreateRequest): Promise<string> {
    return (await HttpClient.post(apiClaim, data)).data;
  },
  async deleteData(id: string): Promise<void> {
    return (await HttpClient.delete(`${apiClaim}/${id}`)).data;
  },
  async getOneData(id: string): Promise<ClaimReport> {
    return (await HttpClient.get(`${apiClaim}/${id}`)).data;
  },
  async copyData(id: string): Promise<string> {
    return (await HttpClient.get(`${apiClaim}/${id}/copy`)).data;
  },
  async historyData(id: string): Promise<IlsClaimHistoryReport[]> {
    return (await HttpClient.get(`${apiClaim}/${id}/history`)).data;
  },
  async exportToPdf(id: string): Promise<Blob> {
    return (
      await HttpClient.get(`${apiClaim}/${id}/pdf`, {
        responseType: 'blob',
      })
    ).data;
  },
  async setStatus(ilsClaimId: string, status: StateType): Promise<void> {
    return (await HttpClient.put(`${apiClaim}/${ilsClaimId}/setState?newState=${status}`)).data;
  },
  async createSendIls(data: CreateSend): Promise<ClaimReport> {
    return (await HttpClient.post(apiSend, data)).data;
  },
  async updateSendIls(data: Send): Promise<ClaimReport> {
    return (await HttpClient.put(apiSend, data)).data;
  },
  async deleteSend(ilsSendId: string): Promise<ClaimReport> {
    return (await HttpClient.delete(`${apiSend}/${ilsSendId}`)).data;
  },

  async addWagons(
    ilsClaimId: string,
    data: { wagons: number[]; supplierOrganizationId?: string; supplierContractId?: string },
  ): Promise<void> {
    return await HttpClient.post(`${apiWagon}/add/${ilsClaimId}`, data);
  },

  async removeWagons(ilsClaimId: string, wagons: number[]): Promise<void> {
    return await HttpClient.post(`${apiWagon}/remove/${ilsClaimId}`, wagons);
  },

  async getWagons(ilsClaimId: string): Promise<IlsClaimWagon[]> {
    return (await HttpClient.get(`${apiWagon}/${ilsClaimId}`)).data;
  },
};

export default requestIlsStoreService;
