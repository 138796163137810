import { action, makeObservable, observable } from 'mobx';

import { headerFilterSort } from '@utils/sort';

import service from './notification.service';

export interface NotificationTemplateModel {
  code: string;
  name: string;
  groupName: string;
  meta: NotificationTemplateMeta;
}

export interface NotificationTemplateMeta {
  params: NotificationParam[];
}

export interface NotificationParam {
  name: string;
  datatype: string;
  description: string;
}

export interface NotificationModel {
  templateCode: string;
  content: NotificationContent;
  updateDate: Date | string;
  template?: NotificationTemplateModel;
}

export interface NotificationContent {
  params: NotificationParamValue[];
  actions: NotificationAction[];
}

export interface NotificationParamValue {
  name: string;
  value: any;
}

export interface NotificationAction {
  type: 'mail' | 'chat';
  receivers: string[];
}

export interface NotificationEntry {
  tmplateCode: string;
  templateName: string;
  message: string;
  createDate: string;
}

class NotificationStore {
  @observable isBusy: boolean = false;
  @observable notificationTemplates: NotificationTemplateModel[] = [];
  @observable notification: NotificationModel[] = [];

  constructor() {
    makeObservable(this);
  }

  // Получить шаблоны уведомлений
  @action async getNotificationTemplates(search?: string, group?: string) {
    if (search) {
      return this.notificationTemplates
        .filter((n) => n.name.toLowerCase().includes(search.toLowerCase()) && (!group || n.groupName == group))
        .map((n) => {
          return { value: n.code, text: n.name };
        });
    } else {
      this.isBusy = true;
      try {
        this.notificationTemplates = await service.getNotificationTemplates();
        const data = this.notificationTemplates
          .filter((n) => !group || n.groupName == group)
          .map((n) => ({ value: n.code, text: n.name }))
          .sort(headerFilterSort);
        data.unshift({ value: null, text: '(Пустое)' });

        return data;
      } finally {
        this.isBusy = false;
      }
    }
  }

  // Получить уведомления на компанию
  @action async getNotificationByCompanyId(companyId: string) {
    this.isBusy = true;
    await service
      .getNotificationByCompanyId(companyId)
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.notification = result;
      });
  }

  // Добавить/обновить уведомление на компанию
  @action async addNotificationByCompanyId(companyId: string, notification: NotificationModel) {
    this.isBusy = true;
    await service
      .addNotificationByCompanyId(companyId, notification)
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.getNotificationByCompanyId(companyId);
        return result;
      });
  }

  // Удалить уведомление компании
  @action async deleteNotification(companyId: string, templateCode: string) {
    this.isBusy = true;
    await service
      .deleteNotification(companyId, templateCode)
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.getNotificationByCompanyId(companyId);
        return result;
      });
  }
  @action async getDescription(
    type: string,
    templateCode: string,
    numberOrClaimId: string,
    companyId?: string,
  ): Promise<NotificationEntry> {
    switch (type) {
      case 'notifications':
        return await service.getClaimNotifyDescription(templateCode, numberOrClaimId);
      case 'wagonnotification':
        return await service.getWagonNotifyDescription(companyId, numberOrClaimId, templateCode);
      case 'containernotification':
        return await service.getContainerNotifyDescription(companyId, numberOrClaimId, templateCode);
    }
  }
}

export default new NotificationStore();
