import { Button, Col, Form, Input, List, Modal, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import RemoteSelect from '@components/fw/RemoteSelect';

import designStore from '@stores/designStore/designStore';
import { EtranOrganizationPassportInfo, EtranOrganizationPayerModel } from '@stores/etranStore/EtranData';
import etranService from '@stores/etranStore/etranStore.service';

import { showErrorNotify } from '@utils/notify';

interface Props {
  onChange?: (value: EtranOrganizationPayerModel) => void;
  value?: EtranOrganizationPayerModel;
}

interface State {
  org: EtranOrganizationPassportInfo;
  okpo: string;
  code: string;
  showModal: boolean;
  payerCandidates: EtranOrganizationPayerModel[];
  loading: boolean;
}

@observer
class EtranPayer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const value = this.props.value;
    if (value) {
      this.state = {
        org: { orgId: value.orgId, orgOkpo: value.payerOkpo, orgName: value.payerName },
        okpo: props.value ? props.value.payerOkpo : null,
        code: props.value ? props.value.payerCode : null,
        showModal: false,
        payerCandidates: [],
        loading: false,
      };
    } else {
      this.state = {
        org: null,
        okpo: null,
        code: null,
        showModal: false,
        payerCandidates: [],
        loading: false,
      };
    }
  }

  remoteSelect: RemoteSelect;
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const props = this.props;
    if (props.value && (!prevProps.value || props.value.payerCode != prevProps.value.payerCode)) {
      const value = props.value;
      this.setState({
        org: { orgId: value.orgId, orgOkpo: value.payerOkpo, orgName: value.payerName },
        okpo: props.value ? props.value.payerOkpo : null,
        code: props.value ? props.value.payerCode : null,
      });
    }
  }

  onOrgChange = (org: EtranOrganizationPassportInfo) => {
    this.setState({ org, loading: true });
    etranService
      .getEtranOrganizationPayers(org.orgId)
      .then((ans) => {
        this.setPayerCandidates(ans);
      })
      .finally(() => this.setState({ loading: false }));
  };

  setPayerCandidates(candidates: EtranOrganizationPayerModel[]) {
    if (candidates.length == 0) {
      this.setState({ okpo: null, code: null });
      showErrorNotify('Плательщики не найдены', '');
      return;
    }
    if (candidates.length == 1) {
      this.onPayerChoose(candidates[0]);
      return;
    }
    this.setState({ payerCandidates: candidates, showModal: true });
  }

  onOkpoSearch = () => {
    this.setState({ loading: true });
    etranService
      .getEtranOrganizationPayers(undefined, this.state.okpo)
      .then((ans) => {
        this.setPayerCandidates(ans);
      })
      .finally(() => this.setState({ loading: false }));
  };

  onCodeSearch = () => {
    this.setState({ loading: true });
    etranService
      .getEtranOrganizationPayers(undefined, undefined, this.state.code)
      .then((ans) => {
        this.setPayerCandidates(ans);
      })
      .finally(() => this.setState({ loading: false }));
  };

  onPayerChoose = (payer: EtranOrganizationPayerModel) => {
    this.setState(
      { org: { orgName: payer.payerName, orgId: payer.orgId, orgOkpo: payer.payerOkpo }, okpo: payer.payerOkpo, code: payer.payerCode },
      () => {
        this.remoteSelect.updateValue();
      },
    );
    if (this.props.onChange) {
      this.props.onChange(payer);
    }
    this.setState({ payerCandidates: [], showModal: false });
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Modal
          width={designStore.isMobile ? '100%' : '60%'}
          open={this.state.showModal}
          onCancel={() => this.setState({ showModal: false })}
          title={'Выберите плательщика'}
          okButtonProps={{ style: { display: 'none' } }}>
          <List
            dataSource={this.state.payerCandidates}
            renderItem={(item) => (
              <List.Item actions={[<Button onClick={() => this.onPayerChoose(item)}>Выбрать</Button>]}>
                <List.Item.Meta description={item.payerCode + ', тип: ' + item.payerTypeName} title={item.payerName} />
              </List.Item>
            )}
          />
        </Modal>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={4}>
            <label className={'etranFormLabel'}>Плательщик:</label>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} className={'comboEditSearchOther'}>
            <RemoteSelect
              ref={(ref) => (this.remoteSelect = ref)}
              value={this.state.org}
              placeholder={''}
              dataSource={etranService.getEtranOrganizationDictionary}
              valueExpr={'orgId'}
              displayExpr={'orgName'}
              onChange={this.onOrgChange}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} style={{ display: 'flex' }}>
            <label className={'etranFormLabel'} style={{ whiteSpace: 'nowrap', padding: '0 0.5rem 0 0.5rem', alignSelf: 'center' }}>
              Код плательщика:
            </label>
            <Input.Search
              className={'etranInputSearch'}
              value={this.state.code}
              onChange={(e) => this.setState({ code: e.target.value })}
              onSearch={this.onCodeSearch}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={4}>
            <label className={'etranFormLabel'}>ОКПО:</label>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} style={{ padding: '0 0 0.5rem 0' }}>
            <Input.Search
              className={'etranInputSearch'}
              value={this.state.okpo}
              onChange={(e) => this.setState({ okpo: e.target.value })}
              onSearch={this.onOkpoSearch}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} style={{ display: 'flex' }}>
            <label className={'etranFormLabel'} style={{ whiteSpace: 'nowrap', padding: '0 0.5rem 0 0' }}>
              Тип:
            </label>
            <Form.Item name={' '} style={{ width: '100%' }}>
              <div className={'textSelect'}>{this.props.value ? this.props.value.payerTypeName : ''}</div>
            </Form.Item>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default EtranPayer;
