import { ColumnsType } from 'antd/es/table';

import { WagonProvision } from '@stores/bidRegistryCommercialStore/bidRegistryCommercialStore.types';

import {
  renderComment,
  renderDestNormDays,
  renderIsActive,
  renderLoadNormDays,
  renderNDS,
  renderPriceWithNDS,
  renderPriceWithoutNDS,
  renderWagonNumbers,
} from './columns.ui';

export const COLUMNS: ColumnsType<WagonProvision> = [
  {
    dataIndex: 'isActive',
    key: 'isActive',
    render: renderIsActive,
    align: 'center',
    width: 20,
  },
  {
    title: 'Маршрут',
    dataIndex: 'bidRegistryId',
    key: 'bidRegistryId',
    render: (_, { loadStationName, destStationName }) => {
      return `${loadStationName}-${destStationName}`;
    },
  },
  {
    title: 'Ставка без НДС',
    dataIndex: 'priceWithoutNds',
    key: 'priceWithoutNds',
    render: renderPriceWithoutNDS,
    width: 130,
  },
  {
    title: 'НДС',
    key: 'ndsType',
    render: renderNDS,
    width: 120,
  },
  {
    title: 'Ставка с НДС',
    key: 'record.nds',
    render: renderPriceWithNDS,
    width: 130,
  },
  {
    title: 'Норматив простоя под погрузкой',
    dataIndex: 'loadNormDays',
    key: 'loadNormDays',
    width: 50,
    render: renderLoadNormDays,
  },
  {
    title: 'Норматив простоя под выгрузкой',
    dataIndex: 'destNormDays',
    key: 'destNormDays',
    width: 50,
    render: renderDestNormDays,
  },
  {
    title: 'Номера вагонов',
    dataIndex: 'wagonNumbers',
    key: 'wagonNumbers',
    width: 250,
    render: renderWagonNumbers,
  },
  {
    title: 'Примечание',
    dataIndex: 'comment',
    key: 'comment',
    render: renderComment,
  },
];
