import qs from 'qs';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import ItineraryEconomicalsDataGrid from './ItineraryEconomicalsDataGrid';

interface State {}

class ItineraryEconomicalsWagons extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  render() {
    let filter = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).w;
    if (filter) {
      if (Array.isArray(filter)) {
        document.title = '"Экономика рейсов ' + filter.join(' ');
      } else {
        document.title = 'Экономика рейсов ' + filter;
      }
    } else {
      document.title = 'Экономика рейсов ⬎ Контейнеры';
    }
    return <ItineraryEconomicalsDataGrid type={'wagons'} location={this.props.location} />;
  }
}

export default ItineraryEconomicalsWagons;
