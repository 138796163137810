import React from 'react';

import ContractDocViewer from '@components/fw/Drawers/OrganizationEditor/ContractList/ContractDocViewer';

const ItineraryContractDxCell: React.FC<any> = (props) => {
  let value = props.row.data;
  return value ? (
    <span>
      {value.contractid && (
        <ContractDocViewer type={'contract'} id={value.contractid}>
          Договор
        </ContractDocViewer>
      )}
    </span>
  ) : null;
};

export default ItineraryContractDxCell;
