import { Button, Checkbox, Form, Input, InputNumber, Modal, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import RemoteSelect from '@components/fw/RemoteSelect';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import EtranCountry from '@components/routed/EtranPage/CommonInput/EtranCountry';
import EtranOrganization from '@components/routed/EtranPage/CommonInput/EtranOrganization';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';

import {
  EtranInvoiceCarForm,
  EtranInvoiceCarInfo,
  emptyEtranInvoiceCarForm,
  etranInvoiceCarFormToInfo,
  etranInvoiceCarInfoToForm,
} from '@stores/etranStore/EtranInvoiceCarData';
import etranStore from '@stores/etranStore/etranStore';
import etranStoreService from '@stores/etranStore/etranStore.service';
import etranService from '@stores/etranStore/etranStore.service';
import userStore from '@stores/userStore/userStore';

import EtranGuidesTable from './Guides/EtranGuidesTable';
import EtranCarSpsMarksTable from './SpcMarks/EtranCarSpsMarksTable';

interface Props {
  data?: EtranInvoiceCarInfo;
  onChange(): void;
}

interface State {
  visible: boolean;
  wagonInfoLoading: boolean;
  data: EtranInvoiceCarForm;
  wagonTypeSearch: string;
  countrySearch: string;
}

export const etranEtsngGngTemplate = (item) => {
  return (
    <div style={{ whiteSpace: 'normal', height: 'auto' }}>
      <span style={{ color: 'lightGray' }}>{item.code} </span>
      {item.name}
    </div>
  );
};

@observer
class EtranInvoceCarInfoModal extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      data: emptyEtranInvoiceCarForm,
      wagonInfoLoading: false,
      wagonTypeSearch: null,
      countrySearch: null,
    };
  }

  handleFormReset = () => {
    const data = this.props.data ? etranInvoiceCarInfoToForm(this.props.data) : emptyEtranInvoiceCarForm;
    this.setState({ data: data }, () => {
      if (this.formRef) this.formRef.resetFields();
      setTimeout(() => this.forceUpdate(), 200);
    });
  };

  handleConfirm = (e: EtranInvoiceCarForm) => {
    if (e.carOwnerTypeId) {
      const find = etranStore.dictionaryInfo.plannedOwningTypeDictionary.find((t) => t.id == e.carOwnerTypeId);
      if (find) {
        e.carOwnerTypeName = find.name;
      }
    }
    if (e.carTypeId) {
      const find = etranStore.dictionaryInfo.wagonTypeDictionary.find((t) => t.wagTypeId == e.carTypeId);
      if (find) {
        e.carTypeName = find.name;
      }
    }
    if (e.carOwnerCountryCode) {
      const find = etranStore.countries.find((c) => c.code == e.carOwnerCountryCode.toString());
      if (find) {
        e.carOwnerCountryName = find.shortName;
      }
    }
    etranStore.addCar(etranInvoiceCarFormToInfo(e), this.props.data);
    this.props.onChange();
    this.onClose();
  };

  onOpen = () => {
    this.setState({ visible: true }, () => {
      this.handleFormReset();
    });
  };

  get isEdit() {
    return !!this.props.data && this.props.data.carNumber;
  }

  onClose = () => {
    if (this.formRef) {
      this.formRef.resetFields();
    }
    this.setState({ visible: false });
  };

  onInputCarNumber = async (n) => {
    if (n && !isNaN(n) && n > 10000000) {
      this.setState({ wagonInfoLoading: true });
      try {
        const wagonInfo = await etranService.getCarDictionary(n, userStore.userData.companyId);
        if (wagonInfo) {
          this.formRef.setFieldsValue(etranInvoiceCarInfoToForm(wagonInfo));
        }
        const freightInfo = await etranService.getWagonLstFreight(n, userStore.userData.companyId);
        if (freightInfo) {
          const carPriorFreightObj = { code: freightInfo.etsngFreightCode, name: freightInfo.etsngFreightName, shortName: null };
          const carPriorFreightGNGObj = { code: freightInfo.gngFreightCode, name: freightInfo.gngFreightName, oldCode: null };
          this.formRef.setFieldsValue({
            carPriorFreightObj: carPriorFreightObj,
            carPriorFreightGNGObj: carPriorFreightGNGObj,
          });
        }
      } finally {
        this.setState({ wagonInfoLoading: false });
      }
    }
  };

  onChangeMass = () => {
    const carWeightDepReal = this.formRef.getFieldValue('carWeightDepReal');
    const carWeightNet = this.formRef.getFieldValue('carWeightNet');
    if (!isNaN(carWeightDepReal) && !isNaN(carWeightNet)) {
      this.formRef.setFieldsValue({ carWeightGross: carWeightNet + carWeightDepReal });
    }
  };

  render() {
    const dict = etranStore.dictionaryInfo;
    const wSearch = this.state.wagonTypeSearch ? this.state.wagonTypeSearch.toLowerCase() : null;
    const wagonTypeList = wSearch
      ? dict.wagonTypeDictionary.filter((w) => (w.name + w.mnemoCode).toLowerCase().includes(wSearch))
      : dict.wagonTypeDictionary;
    const plannedOwningTypeDictionary = dict.plannedOwningTypeDictionary;
    const data = this.state.data;
    if (data.carNtuMtuDate) data.carNtuMtuDate = moment(data.carNtuMtuDate);
    const carOwnerTypeId = this.formRef ? this.formRef.getFieldValue('carOwnerTypeId') : 1;
    const wagonInfoLoading = this.state.wagonInfoLoading;

    return (
      <>
        <Button onClick={this.onOpen}>{this.isEdit ? 'Изменить' : 'Добавить'}</Button>
        <Modal
          title="Вагоны"
          open={this.state.visible}
          onCancel={this.onClose}
          className={'etran-modal'}
          footer={
            <Button
              onClick={() => {
                this.formRef.submit();
              }}>
              {this.isEdit ? 'Изменить' : 'Добавить'}
            </Button>
          }>
          <ScrolledContainer style={{ maxHeight: 'calc(100vh - 265px)', overflowX: 'hidden' }}>
            <Form
              layout="horizontal"
              ref={(ref) => (this.formRef = ref)}
              size={'small'}
              name={'etran-editor'}
              labelCol={{ span: 4 }}
              initialValues={data}
              onFinish={this.handleConfirm}>
              <Form.Item name={'carNumber'} label={'Номер вагона'}>
                <InputNumber max={99999999} min={10000000} step={1} onChange={(e) => this.onInputCarNumber(e)} />
              </Form.Item>
              <Form.Item name={'carTypeId'} label={'Род вагона'}>
                <Select showSearch onSearch={(v) => this.setState({ wagonTypeSearch: v })}>
                  {wagonTypeList.map((i) => (
                    <Select.Option value={i.wagTypeId} key={i.wagTypeId}>
                      {i.wagTypeCode} {i.mnemoCode} {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={'carTonnage'} label={'Грузоподъемность в т. (ГП)'}>
                <InputNumber max={999} min={0} step={0.1} />
              </Form.Item>
              <Form.Item name={'carWeightDep'} label={'Масса тары с бруса (ц)'}>
                <InputNumber max={9999} min={0} step={0.1} />
              </Form.Item>
              <Form.Item name={'carWeightDepReal'} label={'Масса тары проверенная (ц)'}>
                <InputNumber max={999999} min={0} step={1} onChange={this.onChangeMass} />
              </Form.Item>
              <Form.Item name={'carAxles'} label={'Оси'}>
                <InputNumber max={20} min={1} step={1} />
              </Form.Item>
              <Form.Item name={'carLength'} label={'Длина по осям'}>
                <InputNumber max={99} min={0} step={0.001} />
              </Form.Item>
              <Form.Item name={'carOwnerCountryCode'} label={'Администрация собственник'}>
                <EtranCountry />
              </Form.Item>
              <Form.Item name={'carOwnerTypeId'} label={'Тип собственности'}>
                <Select onChange={() => this.forceUpdate()}>
                  {plannedOwningTypeDictionary.map((i) => (
                    <Select.Option value={i.id} key={i.id}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={'carOwnerObj'} label={carOwnerTypeId == 1 ? 'Собственник' : 'Арендатор'}>
                {!wagonInfoLoading && (
                  <EtranOrganization width={24} name={'carOwnerObj'} onChange={() => this.forceUpdate()} formRef={this.formRef} />
                )}
              </Form.Item>
              <Form.Item name={'carWeightNet'} label={'Масса в нетто (кг)'}>
                <InputNumber max={999999} min={0} step={1} onChange={this.onChangeMass} />
              </Form.Item>
              <Form.Item name={'carWeightAddDev'} label={'Масса дополнительного оборудования'}>
                <InputNumber max={999999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'carWeightGross'} label={'Масса в брутто (кг)'}>
                <InputNumber max={999999} min={0} step={1} />
              </Form.Item>
              <EtranCaption title={'Проводники'} />
              <EtranGuidesTable
                data={this.state.data.guides}
                onChange={(e) => {
                  this.state.data.guides = e;
                  this.state.data.carGuideCount = e.length;
                  this.forceUpdate();
                }}
              />

              <Form.Item name={'carPriorFreightGNGObj'} label={'Ранее перевозимый груз ГНГ'}>
                {!wagonInfoLoading && (
                  <RemoteSelect
                    placeholder={'Наименование или код ГНГ'}
                    valueExpr={'code'}
                    displayExpr={'name'}
                    onChange={() => this.forceUpdate()}
                    dataSource={etranStoreService.getEtranGng}
                    itemTemplate={etranEtsngGngTemplate}
                  />
                )}
              </Form.Item>

              <Form.Item name={'carPriorFreightObj'} label={'Ранее перевозимый груз ЕТСНГ'}>
                {!wagonInfoLoading && (
                  <RemoteSelect
                    placeholder={'Наименование или код ЕТСНГ'}
                    valueExpr={'code'}
                    displayExpr={'name'}
                    onChange={() => this.forceUpdate()}
                    dataSource={etranStoreService.getEtranEtsng}
                    itemTemplate={etranEtsngGngTemplate}
                  />
                )}
              </Form.Item>

              <Form.Item name={''} label={'Количество проводников'}>
                <span>{data.carGuideCount ? data.carGuideCount : 0}</span>
              </Form.Item>
              <Form.Item name={'carOutsizeCode'} label={'Код негабаритности'}>
                <Input />
              </Form.Item>
              <EtranCaption title={'Вагонные отметки и тарифные отметки на вагон'} />
              <EtranCarSpsMarksTable
                data={this.state.data.specialMarks}
                onChange={(e) => {
                  this.state.data.specialMarks = e;
                  this.forceUpdate();
                }}
              />

              <Form.Item name={'carConnectCodeBool'} label={'Вагон в сцепе'} valuePropName={'checked'}>
                <Checkbox>Вагон в сцепе</Checkbox>
              </Form.Item>

              <Form.Item name={'carIsCoverBool'} label={'Признак прикрытия'} valuePropName={'checked'}>
                <Checkbox>Признак прикрытия</Checkbox>
              </Form.Item>

              {/*<Form.Item name={'carOrder'} label={'Порядковый № вагона в отправке'}>
                <InputNumber max={9999} min={1} step={1} />
              </Form.Item>

              <Form.Item name={'carTenantObj'} label={'Арендатор'}>
                <EtranOrganization />
              </Form.Item>
              <Form.Item name={'carPocketCount'} label={'Количество пакетов'}>
                <InputNumber max={99999} min={1} step={1} />
              </Form.Item>
              <Form.Item name={'carPlacesCount'} label={'Количество мест'}>
                <InputNumber max={99999} min={1} step={1} />
              </Form.Item>
              <Form.Item name={'carVolume'} label={'Объем кузова'}>
                <InputNumber max={9999} min={0} step={0.1} />
              </Form.Item>
              <Form.Item name={'carAddDevWithGoods'} label={'Признак выдачи дополнительного оборудования вместе с грузом'}>
                <InputNumber max={999999} min={0} step={1} />
              </Form.Item>

              <Form.Item name={'carOutsizeCode'} label={'Код негабаритности'}>
                <Input />
              </Form.Item>
              <Form.Item name={'carFrameWeight'} label={'Масса контрольной рамы (кг)'}>
                <InputNumber max={999999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'carFrameWagNum'} label={'Номер вагона с контрольной рамой'}>
                <Input />
              </Form.Item>
              <Form.Item name={'carTopHeight'} label={'Высота над уровнем борта'}>
                <InputNumber max={999999} min={1} step={1} />
              </Form.Item>
              <Form.Item name={'carMainShtabQuantity'} label={'Кол-во основных штабелей'}>
                <InputNumber max={999999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'carMainShtabHeight'} label={'Высота основных штабелей'}>
                <InputNumber max={999999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'carMainShtabHeight'} label={'Высота основных штабелей'}>
                <InputNumber max={999999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'carHeadShtabQuantity'} label={'Кол-во штабелей в шапке'}>
                <InputNumber max={999999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'carForestVolume'} label={'Объём лесного груза (кубических метров)'}>
                <InputNumber max={9999} min={0} step={0.01} />
              </Form.Item>
              <Form.Item name={'carLiquidTemperature'} label={'Температура жидкости - для наливного вагона'}>
                <InputNumber max={9999} min={0} step={0.01} />
              </Form.Item>
              <Form.Item name={'carLiquidHeight'} label={'Высота налива - для наливного вагона (см)'}>
                <InputNumber max={9999} min={0} step={0.01} />
              </Form.Item>
              <Form.Item name={'carLiquidDensity'} label={'Плотность - для наливного вагона (г/см3)'}>
                <InputNumber max={9999} min={0} step={0.01} />
              </Form.Item>
              <Form.Item name={'carLiquidVolume'} label={'Объем - для наливного вагона (м3)'}>
                <InputNumber max={9999} min={0.01} step={0.01} />
              </Form.Item>
              <Form.Item name={'carTankType'} label={'Тип цистерны'}>
                <Input />
              </Form.Item>
              <Form.Item name={'carRefNum'} label={'Номер рефсекции'}>
                <Input />
              </Form.Item>
              <Form.Item name={'carRefCount'} label={'Кол-во вагонов в рефсекции'}>
                <InputNumber max={9999} min={1} step={1} />
              </Form.Item>
              <Form.Item name={'carRolls'} label={'Ролики'}>
                <InputNumber max={9999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'carConnectCode'} label={'Код вагона в сцепе'}>
                <InputNumber max={9999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'carIsCover'} label={'Признак вагона прикрытия'}>
                <InputNumber max={9999} min={0} step={1} />
              </Form.Item>
              <Form.Item name={'carSign'} label={'Признаки вагона – набор 0 и 1, тип признака определяется позицией в строке'}>
                <Input />
              </Form.Item>

              <Form.Item name={'carDepNormDocId'} label={'Нормативный документ по размещению'}>
                <RemoteSelectWithInit dataSourceLib={etranService.getFasteningList} valueExpr={'id'} displayExpr={'data'} />
              </Form.Item>
              <Form.Item name={'carDepPart'} label={'Доп. реквизиты размещения: глава, параграф, раздел, ТУ'}>
                <Input maxLength={1500} />
              </Form.Item>
              <Form.Item name={'carDepArc'} label={'Доп. реквизиты размещения: параграф'}>
                <Input maxLength={255} />
              </Form.Item>
              <Form.Item name={'carDepSec'} label={'Доп. реквизиты размещения: раздел'}>
                <Input maxLength={255} />
              </Form.Item>
              <Form.Item name={'carDepCond'} label={'Доп. реквизиты размещения: ТУ'}>
                <Input maxLength={255} />
              </Form.Item>
              <Form.Item name={'carNtuMtuNumber'} label={'Номер НТУ/МТУ'}>
                <Input maxLength={255} />
              </Form.Item>
              <Form.Item name={'carNtuMtuDate'} label={'Дата утверждения НТУ/МТУ'}>
                <DatePicker format={GLOBAL_DATE_FORMAT} allowClear />
              </Form.Item>
              <Form.Item name={'carNtuClearance'} label={'Наименование габарита погрузки (только для НТУ)'}>
                <Input maxLength={255} />
              </Form.Item>
              <Form.Item name={'carMtuScheme'} label={'Номер схемы МТУ (только для НТУ)'}>
                <Input maxLength={255} />
              </Form.Item>

              <Form.Item name={'carMtuScheme'} label={'Номер схемы МТУ (только для НТУ)'}>
                <Input maxLength={255} />
              </Form.Item>*/}
            </Form>
          </ScrolledContainer>
        </Modal>
      </>
    );
  }
}

export default EtranInvoceCarInfoModal;
