import { Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AdaptiveHeaderTabs from '@components/fw/AdaptiveElements/AdaptiveHeaderTabs';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import AccessRoute from '@components/fw/RoutingContext/AccessRoute';
import HistoryList from '@components/routed/Administration/HistoryList';
import OrganizationsList from '@components/routed/Administration/OrganizationsList';

import designStore from '@stores/designStore/designStore';
import routingStore from '@stores/fw/routingStore/routingStore';
import userStore from '@stores/userStore/userStore';

import MyCompanyEditor from './MyCompanyEditor';
import UsersListForUser from './UsersListForUser';

const menus = [
  <Tabs.TabPane tab={'Пользователи'} key="/companySettings/users"></Tabs.TabPane>,
  <Tabs.TabPane tab={'Настройки компании'} key="/companySettings/companies"></Tabs.TabPane>,
  <Tabs.TabPane tab={'Организации'} key="/companySettings/organizations"></Tabs.TabPane>,
  <Tabs.TabPane tab={'История'} key="/companySettings/history"></Tabs.TabPane>,
];

class MyCompanySettings extends Component<RouteComponentProps> {
  render() {
    const path = routingStore.location.pathname;
    const tabsAvailableItems = userStore.tabsAvailableItems;
    const width =
      designStore.sidebarCollapsed && designStore.isMobile
        ? 'calc(100vw - 4rem)'
        : !designStore.sidebarCollapsed && !designStore.isMobile
          ? 'calc(100vw - 19rem)'
          : !designStore.sidebarCollapsed && designStore.isMobile
            ? 'calc(100vw - 21rem)'
            : designStore.sidebarCollapsed && !designStore.isMobile
              ? 'calc(100vw - 22rem)'
              : 'calc(100vw - 50rem)';

    let defaultRedirect = '/';
    if (tabsAvailableItems.includes('administration/users')) defaultRedirect = 'companySettings/users';
    else if (tabsAvailableItems.includes('administration/companies')) defaultRedirect = 'companySettings/companies';
    else if (tabsAvailableItems.includes('administration/organizations')) defaultRedirect = 'companySettings/organizations';
    else if (tabsAvailableItems.includes('administration/history')) defaultRedirect = 'companySettings/history';
    document.title = 'Моя компания';
    return (
      <>
        <AdaptivePageHeader
          subTitle={
            <AdaptiveHeaderTabs
              centered
              className={'administrationTabs'}
              activeKey={path}
              tabPosition={'top'}
              onTabClick={(e) => this.props.history.push(e)}
              style={{ width: width }}>
              {this.renderMenu(tabsAvailableItems)}
            </AdaptiveHeaderTabs>
          }
        />
        <Switch>
          <Route exact path="/companySettings">
            <Redirect to={defaultRedirect} />
          </Route>
          <AccessRoute
            path="/companySettings/users"
            component={UsersListForUser}
            canAccess={tabsAvailableItems.includes('administration/users')}
          />
          <AccessRoute
            path="/companySettings/companies"
            component={MyCompanyEditor}
            canAccess={tabsAvailableItems.includes('administration/companies')}
          />
          <AccessRoute
            path="/companySettings/organizations"
            component={OrganizationsList}
            canAccess={tabsAvailableItems.includes('administration/organizations')}
          />
          <AccessRoute
            path="/companySettings/history"
            component={HistoryList}
            canAccess={tabsAvailableItems.includes('administration/history')}
          />
        </Switch>
      </>
    );
  }

  private renderMenu(availableMenus: string[]) {
    return menus.map((item, index) => {
      if (availableMenus.map((x) => `/${x}`).includes(`${item.key.toString().replace('companySettings', 'administration')}`)) {
        return item;
      } else {
        return null;
      }
    });
  }
}

export default observer(MyCompanySettings);
