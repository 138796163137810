import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';

import RemoteSelect from '@components/fw/RemoteSelect';

import { InputPosition } from '@stores/designStore/designData';
import dictService from '@stores/dictStore/dictStore.service';

import OptionsParagraphBtn from './OptionsParagraphBtn';

interface State {}

interface Props {
  name: string;
  type?: string;
  formRef: FormInstance;
  valueExpr?: string;
  required?: boolean;
  onChange?(e): void;
  disabled?: boolean;
  position?: InputPosition;
  calculateDate?: Moment;
  'data-test'?: string;
}

export const stationWithParagraphsTemplate = (item) => {
  if (!item) return null;
  return (
    <>
      <span style={{ color: 'lightGray' }}>
        {!!item.stationCodeCheckSum ? item.stationCodeCheckSum.toString().padStart(6, '0') : item.stationCode.toString().padStart(5, '0')}{' '}
      </span>
      {item.fullName ? item.fullName : item.stationName}
      <span style={{ color: 'sandybrown' }}> {item['roadShortName']}</span>
      <span style={{ color: 'sandybrown' }}> {item['countryName']}</span>
      {item.paragraphs && <span>, {item.paragraphs}</span>}
    </>
  );
};

class OptionsStationSelectorBtn extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const stationCode = this.props.formRef ? this.props.formRef.getFieldValue(this.props.name) : null;
    const valueExpr = this.props.valueExpr ? this.props.valueExpr : 'stationCode';
    const calculateDate = moment(this.props.calculateDate).toISOString(true);
    return (
      <Form.Item name={this.props.name} rules={[{ required: !!this.props.required, message: 'Необходимо заполнить поле ' }]}>
        <RemoteSelect
          position={this.props.position}
          float={true}
          disabled={this.props.disabled}
          onChange={this.onChange}
          itemTemplate={stationWithParagraphsTemplate}
          placeholder={
            <span>
              Станция {this.props.type}, параграфы <OptionsParagraphBtn stationCode={stationCode} dateTime={calculateDate} />
            </span>
          }
          valueExpr={valueExpr}
          primitiveResult={true}
          aValueExpr={calculateDate}
          displayExpr={'fullName'}
          dataSource={dictService.stationWithParagraphDict}
          autoInitDict={true}
          data-test={this.props['data-test']}
        />
      </Form.Item>
    );
  }

  onChange = (e) => {
    this.forceUpdate();
    if (this.props.onChange) this.props.onChange(e);
  };
}

export default OptionsStationSelectorBtn;
