import DataGrid, {
  Column,
  Export,
  FilterPanel,
  FilterRow,
  GroupItem,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import { ignoreCaseFilter } from '@stores/dispatcherStore/DispatcherData';
import itineraryStore from '@stores/itineraryStore/itineraryStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import resultViewStore from '@stores/resultViewStore/resultViewStore';

interface Props {
  type: 'wagons' | 'containers';
  filterValues?: string[];
}

interface State {
  dataSource: DataSource;
}

class HoldDataGrid extends Component<Props, State> {
  dataGrid: DataGrid;
  ExcelJs: any;
  constructor(props: Props) {
    super(props);
    import('exceljs').then((e) => {
      this.ExcelJs = e;
    });

    this.state = {
      dataSource: this.dataSource,
    };
  }

  get dataSource() {
    const type = this.props.type;
    return new DataSource({
      store: new CustomStore({
        key: 'operationid',
        load: function (loadOptions) {
          return itineraryStore
            .hold(loadOptions, type)
            .then((data) => {
              return data;
            })
            .catch(() => {
              throw 'Data Loading Error';
            });
        },
      }),
    });
  }

  render() {
    const data = this.state.dataSource;
    const type = this.props.type;
    const numberDataField = type == 'wagons' ? 'wagonnumber' : 'containernumber';
    const groupSummary = resultViewStore.groupSummaryList(this.dataGrid);
    const totalSummary = resultViewStore.totalSummaryList();

    return (
      <AdaptiveCard>
        <DataGrid
          allowColumnReordering={true}
          dataSource={data}
          ref={(ref) => (this.dataGrid = ref)}
          style={{ height: 'calc(100vh - var(--header-height) - var(--card-padding)' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          repaintChangesOnly={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          onContextMenuPreparing={(e) => resultViewStore.handleItemContextMenu(e, this.dataGrid)}
          onExporting={this.onExporting}
          selection={{ mode: 'single' }}>
          <Paging enabled={true} defaultPageSize={25} />
          <Pager
            allowedPageSizes={[25, 50, 100]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            infoText={'Всего: {2}'}
          />
          <GroupPanel visible={true} />
          <Grouping contextMenuEnabled={true} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Export enabled={true} excelWrapTextEnabled={true} />
          <FilterRow visible={true} />
          <FilterPanel visible={true} />
          <Column
            allowFixing={true}
            dataField={numberDataField}
            dataType="string"
            caption={'Номер'}
            defaultFilterValues={this.props.filterValues}
          />
          <Column
            allowFixing={true}
            dataField="stationname"
            dataType="string"
            caption={'Станция'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column
            allowFixing={true}
            dataField="roadname"
            dataType="string"
            caption={'Дорога'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column
            allowFixing={true}
            dataField="operationprev"
            dataType="string"
            caption={'Операция начала простоя'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column
            allowFixing={true}
            dataField="operation"
            dataType="string"
            caption={'Операция окончания простоя'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column
            allowFixing={true}
            dataType={'datetime'}
            dataField="dateprev"
            allowGrouping={false}
            allowHeaderFiltering={false}
            caption={'Дата начала'}
          />
          <Column
            allowFixing={true}
            dataField="date"
            dataType={'datetime'}
            allowGrouping={false}
            allowHeaderFiltering={false}
            caption={'Дата окончания'}
          />
          <Column allowFixing={true} dataField="hold" dataType="number" caption={'Простой (сут.)'} />

          <Summary>
            {groupSummary.map((x, index) => (
              <GroupItem key={`GroupItem_${index}`} {...x} />
            ))}
            {totalSummary.map((x, index) => (
              <TotalItem key={`TotalItem_${index}`} {...x} />
            ))}
          </Summary>
        </DataGrid>
      </AdaptiveCard>
    );
  }
  onExporting = (e) => {
    const workbook = new this.ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Лист1');
    const name = 'Рейсы';

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: { row: 1, column: 1 },
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;
      },
    }).then(function () {
      const date = moment().format(GLOBAL_DATE_FORMAT).toString();
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), date + `-Отчёт-${name}.xlsx`);
      });
    });
    e.cancel = true;
  };
}

export default observer(HoldDataGrid);
