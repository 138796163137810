import DataGrid, { Column, FilterRow, GroupPanel, HeaderFilter, Paging, Summary } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';

import { EtranInvoiceDistance } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';

interface Props {}

interface State {
  selected: EtranInvoiceDistance;
}

@observer
class EtranDistancesTable extends Component<Props, State> {
  dataGrid: DataGrid;
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  onRemove = () => {
    etranStore.removeDistance(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onCopy = () => {
    etranStore.copyDistance(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onUp = () => {
    etranStore.upDistance(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onDown = () => {
    etranStore.downDistance(this.state.selected);
    this.dataGrid.instance.refresh();
  };
  render() {
    const data = etranStore.invoceFormData.distances;
    const selected = this.state.selected;
    return (
      <div className={'etranTable'}>
        <EtranCaption title={'Маршруты cледования'} />
        {/*<div className={'etranButtonsGroup'}>
          <EtranInvoceDistancesInfoModal />
          {selected ? <EtranInvoceDistancesInfoModal data={selected} /> : <Button disabled={true}>Изменить</Button>}
          <Button>Изменить</Button>
          <Button disabled={!selected} onClick={this.onRemove}>
            Удалить
          </Button>
          <Button disabled={!selected} onClick={this.onCopy}>
            Дублировать
          </Button>
          <Button>Удалить все</Button>
          <Button>Копировать</Button>
          <Button>Вставить</Button>
          <Button disabled={!selected} onClick={this.onUp} icon={<ArrowUpOutlined />} />
          <Button disabled={!selected} onClick={this.onDown} icon={<ArrowDownOutlined />} />
        </div>*/}
        <DataGrid
          onSelectionChanged={(e) => {
            const key = e.selectedRowsData[0];
            this.setState({ selected: key });
          }}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={false}
          dataSource={data}
          style={{ minHeight: '110px' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          selection={{ mode: 'single' }}>
          <Paging enabled={false} />
          <GroupPanel visible={false} />
          <HeaderFilter visible={false} allowSearch={false} />
          <FilterRow visible={false} />
          {INDEX_COLUMN}
          <Column allowFixing={true} dataField="distCountryName" caption={'Страна инфраструктуры'} />
          <Column allowFixing={true} dataField="distStationName" caption={'Тарифная станция'} />
          <Column allowFixing={true} dataField="distPayPlaceRWID" caption={'Дорога'} />
          <Column allowFixing={true} dataField="distStationCode" caption={'Код станции'} />
          <Column allowFixing={true} dataField="distPortName" caption={'Порт'} />
          <Column allowFixing={true} dataField="distLoadWay" caption={'Подъездной путь'} />
          <Column allowFixing={true} dataField="distMinWay" caption={'Кратчайшее расстояние до следующей узловой станции'} />
          <Column allowFixing={true} dataField="distPayerName" caption={'Плательщик'} />
          <Column allowFixing={true} dataField="distPayerCode" caption={'Код плательщика'} />
          <Column allowFixing={true} dataField="distFRWSubCode" caption={'Подкод экспедитора'} />
          <Column allowFixing={true} dataField="distTranspTypeName" caption={'Вид транспорта'} />
          <Column allowFixing={true} dataField="distTrackTypeName" caption={'Код колеи'} />
          <Column allowFixing={true} dataField="" caption={'Тип Станции'} />
          <Column allowFixing={true} dataField="" caption={'Фактическая станция'} />
          <Column allowFixing={true} dataField="" caption={'Код фактической станции'} />
          <Column
            allowFixing={true}
            dataField="distCarrier"
            caption={'Перевозчик'}
            customizeText={(e) => (e.value ? e.value.shortName : null)}
          />

          <Summary></Summary>
        </DataGrid>
      </div>
    );
  }
}

export default EtranDistancesTable;
