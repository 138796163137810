import { CheckOutlined, DeleteOutlined, EditOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Popconfirm, Popover, Radio, Spin, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { Flex } from '@components/fw/Flex';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import optionsStore from '@stores/optionsStore';
import optionsService from '@stores/optionsStore/optionsStore.service';
import { TariffTemplate } from '@stores/optionsStore/optionsStoreData';

import { getConvertedTemplate } from './tariff-template-select.lib';
import classes from './tariff-template-select.styles.module.scss';
import { TariffTemplateSelectProps as Props } from './tariff-template-select.types';

export const TariffTemplateSelect = observer(({ shouldSelectDefault, selectedTemplate, optionsForm, onChange }: Props) => {
  const [form] = Form.useForm();
  const [search, setSearch] = useState('');
  const [isBusy, setIsBusy] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [editTemplate, setEditTemplate] = useState<TariffTemplate | null>(null);

  const { templates } = optionsStore;

  function onOpenChange() {
    setSearch('');
    setIsEdit(false);
    setEditTemplate(null);
  }

  function onBack() {
    setIsEdit(false);
    form.resetFields();
  }

  function onSelect(template: TariffTemplate | null) {
    if (template?.templateId === selectedTemplate?.templateId) return;

    const converted = !!template ? getConvertedTemplate(template, 'object') : null;
    onChange(converted);
  }

  function onEdit(template: TariffTemplate | null) {
    setIsEdit(true);
    setEditTemplate(template);

    form.setFieldValue('name', template?.name);
    form.setFieldValue('isDefault', template?.isDefault);
  }

  async function onDelete(templateId: string) {
    try {
      setIsBusy(true);
      await optionsService.deleteTemplate(templateId);
      await optionsStore.loadTemplates();
    } finally {
      setIsBusy(false);
    }
  }

  async function onSave() {
    if (!selectedTemplate) return;

    const data = optionsForm.getFieldsValue();
    const template = getConvertedTemplate({ ...selectedTemplate, data }, 'string');

    try {
      setIsBusy(true);
      await optionsService.updateTemplate(template);
      await optionsStore.loadTemplates();
    } finally {
      setIsBusy(false);
    }
  }

  async function onFinish({ isDefault, name }: { isDefault: boolean; name: string }) {
    const action = !editTemplate ? optionsService.createTemplate : optionsService.updateTemplate;
    const data = optionsForm.getFieldsValue();
    const template = getConvertedTemplate({ templateId: editTemplate?.templateId, isDefault, name, data }, 'string');

    try {
      setIsBusy(true);
      await action(template);
      await optionsStore.loadTemplates();
    } finally {
      setIsBusy(false);
      setIsEdit(false);
      setEditTemplate(null);
    }
  }

  useEffect(() => {
    optionsStore
      .loadTemplates()
      .then((templates) => {
        if (shouldSelectDefault) {
          const defaultTemplate = templates.find(({ isDefault }) => isDefault);
          if (!!defaultTemplate) onSelect(defaultTemplate);
        }
      })
      .finally(() => {
        setIsBusy(false);
      });
  }, []);

  const renderTemplate = (template: TariffTemplate) => {
    const className = template.templateId === selectedTemplate?.templateId ? classes.templateSelected : classes.template;

    return (
      <div className={className} key={template.templateId}>
        <Flex align={'center'} justify={'space-between'} wrap={'nowrap'}>
          <div className={classes.templateName} onClick={() => onSelect(template)}>
            <span>{template.name}</span>
          </div>
          <Flex gap={6} align={'center'} wrap={'nowrap'}>
            {template.isDefault && (
              <Tooltip title={'Шаблон по умолчанию'}>
                <CheckOutlined style={CHECK_STYLE} />
              </Tooltip>
            )}
            <Button type={'text'} size={'small'} icon={<EditOutlined />} onClick={() => onEdit(template)} />
            <Popconfirm title={'Удалить шаблон?'} onConfirm={() => onDelete(template.templateId)}>
              <Button type={'text'} size={'small'} icon={<DeleteOutlined />} />
            </Popconfirm>
          </Flex>
        </Flex>
      </div>
    );
  };

  return (
    <div className={classes.templates}>
      <Popover
        trigger={'click'}
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ padding: 0 }}
        style={{ padding: 0 }}
        onOpenChange={onOpenChange}
        title={
          <div className={classes.title}>
            {isEdit && <LeftOutlined className={classes.backButton} onClick={onBack} />}
            <span>{'Шаблоны'}</span>
          </div>
        }
        content={
          <Spin spinning={isBusy}>
            <div className={classes.content}>
              {isEdit ? (
                <Form form={form} layout={'vertical'} onFinish={onFinish}>
                  <Flex gap={6} vertical={true}>
                    <Form.Item name={'name'} label={'Название'} rules={[{ required: true }]}>
                      <Input placeholder={'Введите название'} />
                    </Form.Item>
                    <Form.Item name={'isDefault'} valuePropName={'checked'}>
                      <Checkbox checked={editTemplate?.isDefault}>Шаблон по умолчанию</Checkbox>
                    </Form.Item>
                    <Button type={'primary'} htmlType={'submit'} style={SAVE_BTN_STYLE}>
                      Сохранить
                    </Button>
                  </Flex>
                </Form>
              ) : (
                <div>
                  <Search placeholder={'Поиск'} value={search} onChange={({ target }) => setSearch(target.value)} />
                  <ScrolledContainer className={classes.list}>
                    <div className={classes.template} onClick={() => onSelect(null)}>
                      <span className={classes.templateName}>Без шаблона</span>
                    </div>
                    {templates.filter(({ name }) => name.includes(search)).map(renderTemplate)}
                  </ScrolledContainer>
                  <Button type={'dashed'} block={true} onClick={() => onEdit(null)}>
                    Создать новый
                  </Button>
                </div>
              )}
            </div>
          </Spin>
        }>
        <Button className={classes.btn} type={'text'} size={'small'}>
          {selectedTemplate ? selectedTemplate.name : 'Шаблоны'}
        </Button>
      </Popover>
      {!!selectedTemplate && (
        <Button className={classes.btn} size={'small'} type={'text'} icon={<SaveOutlined />} onClick={onSave} loading={isBusy} />
      )}
    </div>
  );
});

const SAVE_BTN_STYLE = { width: '100%', marginTop: 10 };
const CHECK_STYLE = { color: '#4977e9', marginRight: 4 };
