import { action, computed, makeObservable, observable } from 'mobx';

import { buildTree } from '@utils/roleAccess';

import { ChatDialog } from '../messengerStore/messengerStoreData';
import userStore from '../userStore/userStore';
import service from './usersStore.service';

export type UserModel = {
  userId: string;
  login: string;
  password: string;
  name: string;
  email: string;
  roleCode: string;
  roleName: string;

  userAccessList: string[];
  roleAccessList: string[];
  companyAccessList: string[];

  companyId: string;
  companyName: string;

  organizationId: string;
  activeType?: UserActiveType;
  activeDate?: string;

  birthdayDate?: string;

  availableReportTypes?: string[];
  createReportTypes?: string[];
  availableSources?: string[];
  tariffSession?: TariffCrossDisplayInfo;
  dialogType?: ChatDialog;
  startPage?: string;
  externalKey?: string;

  mobilePhoneNumber?: string;
};
export interface TariffCrossDisplayInfo {
  code: string;
  dateTimeTo: any;
}

export type HierarchyModel = {
  userId: string;
  userLogin: string;
  userName: string;
  userRoleName: string;
  managerLogin?: string;
  managerName?: string;
  managerRoleName?: string;
  companyName?: string;
  wagonCount?: number;
  containerCount?: number;
  isEtranLoginExists?: boolean;
  isMoscowApiEnabled?: boolean;
  isTransreysApiEnabled?: boolean;
  isEtranSoapEnabled?: boolean;
  isTaisLoginExists?: boolean;
  isSigisLoginExists?: boolean;
  isRailwayLoginExists?: boolean;
  isLagitEnabled?: boolean;
  isRemoveRequest?: boolean;
  sngFtpEnabled?: boolean;
  managerUserId?: string;
  userCompanyId?: string;
  userActiveDate?: string;
  managerActiveDate?: string;
  userActiveType?: string;
  managerActiveType?: string;
  hasDocuments: boolean;
};

export type UserActiveType = 'unknown' | 'active' | 'walkedAway' | 'notAvailable';

export type RoleModel = {
  code: string;
  name: string;
  priority: number;
};

class UsersStore {
  @observable loadingUsers: boolean = false;
  @observable tableViewCurrentOption: object;
  @observable loadingConfirm: boolean = false;
  @observable loadingRoleAccess: boolean = false;
  @observable users: UserModel[] = [];
  @observable hierarchy: HierarchyModel[] = [];
  @observable private _roles: RoleModel[];
  @observable private _roleAccess: string[];
  @observable hierarchyOjectUsers: any = [];
  @observable wagonCount: number = 0;
  @observable containerCount: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action getUsers(filter: any = {}) {
    this.loadingUsers = true;
    return service
      .getUsers(filter)
      .finally(() => (this.loadingUsers = false))
      .then((result: UserModel[]) => {
        this.users = result;
        return result;
      });
  }

  @action getUsersWithChief(filter: any = {}) {
    this.loadingUsers = true;
    service
      .getUsersWithChief(filter)
      .finally(() => (this.loadingUsers = false))
      .then((result: UserModel[]) => {
        this.users = result;
      });
  }

  @action getHierarchy() {
    service.getHierarchy().then((result: any) => {
      this.hierarchy = result;
      const dataSourceOptions = { store: [] };
      this.containerCount = 0;
      this.wagonCount = 0;
      if (this.hierarchy.find((item) => !item.managerUserId && item.userRoleName != 'Менеджер')) {
        dataSourceOptions.store.push({
          userName: 'Без менеджера',
          userLogin: 'Менеджер не назначен',
          userRole: 'Менеджер',
          userId: 'without_manager',
          userActiveDate: 'undefined',
          userActiveType: null,
          key: 'without_manager',
        });
      }
      this.hierarchy.forEach((el) => {
        if (el.userRoleName == 'Менеджер' || el.userRoleName == 'Технолог') {
          dataSourceOptions.store.push({
            userName: el.userName,
            userLogin: el.userLogin,
            userRole: el.userRoleName,
            isRemoveRequest: el.isRemoveRequest,
            userId: el.userId,
            key: el.userId,
            userActiveDate: el.userActiveDate,
            userActiveType: el.userActiveType,
          });
        } else {
          const userManagerId = el.managerUserId ? el.managerUserId : 'without_manager';
          let checkCompany = !!dataSourceOptions.store.find((item) => item.key == userManagerId + el.userCompanyId);
          let checkCompanyStat = !!dataSourceOptions.store.find((item) => item.key.includes(el.userCompanyId));
          if (!checkCompany) {
            dataSourceOptions.store.push({
              userLogin: el.companyName,
              userName: '',
              userId: el.userCompanyId,
              parentKey: userManagerId,
              key: userManagerId + el.userCompanyId,
              managerId: userManagerId,
              managerLogin: el.managerLogin,
              wagonCount: el.wagonCount,
              containerCount: el.containerCount,
              isEtranLoginExists: el.isEtranLoginExists,
              isMoscowApiEnabled: el.isMoscowApiEnabled,
              isTransreysApiEnabled: el.isTransreysApiEnabled,
              isEtranSoapEnabled: el.isEtranSoapEnabled,
              isTaisLoginExists: el.isTaisLoginExists,
              isLagitEnabled: el.isLagitEnabled,
              isSigisLoginExists: el.isSigisLoginExists,
              isRailwayLoginExists: el.isRailwayLoginExists,
              sngFtpEnabled: el.sngFtpEnabled,
              hasDocuments: el.hasDocuments,
              userRole: '',
              isRemoveRequest: el.isRemoveRequest,
            });
          }
          if (!checkCompanyStat) {
            this.wagonCount += el.wagonCount;
            this.containerCount += el.containerCount;
          }
          dataSourceOptions.store.push({
            userLogin: el.userLogin,
            userName: el.userName,
            userId: el.userId,
            parentKey: userManagerId + el.userCompanyId,
            key: userManagerId + el.userCompanyId + el.userId,
            managerId: el.userCompanyId,
            managerLogin: el.companyName + ' ' + el.managerLogin,
            companyManagerId: el.managerUserId,
            userRole: el.userRoleName,
            isRemoveRequest: el.isRemoveRequest,
            userActiveDate: el.userActiveDate,
            userActiveType: el.userActiveType,
          });
        }
      });
      this.hierarchyOjectUsers = dataSourceOptions;
    });
  }

  @action getRoles() {
    //if (this._roles) return;
    service.getRoles().then((result: RoleModel[]) => {
      this._roles = result;
    });
  }

  @action getRoleAccess(roleCode: string) {
    if (!roleCode) {
      this._roleAccess = [];
      return;
    }
    this.loadingRoleAccess = true;
    service
      .getRoleAccess(roleCode)
      .finally(() => (this.loadingRoleAccess = false))
      .then((result: string[]) => {
        //Гнусный хардкод лдя финансов инфорлога
        if (userStore.userData.roleCode == 'admin') {
          result.push('finance-infolog');
        }
        this._roleAccess = result;
      });
  }

  /* создание пользователя */
  @action async insertUser(data: any) {
    return await service.insert(data);
  }

  /* обновить профиль */
  @action async updateProfile(data: any) {
    return await service.updateProfile(data);
  }

  /* удаление профиля */
  @action async removeRequest() {
    await service.removeRequest();
  }

  /* изменение пользователя */
  @action async updateUser(data: UserModel) {
    return await service.updateUser(data);
  }

  /* удаление пользователя */
  @action async removeUser(userId: string) {
    this.loadingUsers = true;
    await service
      .remove(userId)
      .finally(() => (this.loadingUsers = false))
      .then(() => {
        this.getHierarchy();
      });
  }

  @action
  async getUser(userId) {
    return await service.getUser(userId);
  }

  @computed get roles() {
    if (!this._roles) {
      return [];
    }
    return [...this._roles];
  }

  @computed get roleAccessTree(): any[] {
    if (!this._roleAccess) {
      return [];
    }
    const tree = buildTree(this._roleAccess);

    return [...tree];
  }
}

export default new UsersStore();
