import { Button, Col, Form, FormInstance, Modal, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';

import FloatInput from '@components/fw/FloatLabel/FloatInput';

import { CompanyTariffRequestKpModel, CompanyTariffResponseModel } from '@stores/companyTariffStore/CompanyTariffData';
import companyTariffStoreService from '@stores/companyTariffStore/companyTariffStore.service';
import { TariffRequestModel } from '@stores/historyTariffInfo/historyTariffInfoStore';
import userStore from '@stores/userStore/userStore';
import { UserModel } from '@stores/usersStore';
import usersStore from '@stores/usersStore/usersStore';

import { showSuccessNotify } from '@utils/notify';

interface Props {
  companyId: string;
  request: TariffRequestModel;
  response: CompanyTariffResponseModel;
}

interface State {
  visible: boolean;
  isBusy: boolean;
  userData: UserModel;
}

class RequestKpModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      isBusy: false,
      userData: null,
    };
  }

  form: FormInstance;

  onClose = () => {
    this.setState({ visible: false });
  };

  onFinish = (e: CompanyTariffRequestKpModel) => {
    e.companyId = this.props.companyId;
    e.request = this.props.request;
    e.response = this.props.response;
    this.setState({ isBusy: true });
    companyTariffStoreService
      .requestKp(e)
      .then((ans) => {
        showSuccessNotify(ans, '');
        this.onClose();
      })
      .finally(() => {
        this.setState({ isBusy: false });
      });
  };

  onOpen = () => {
    this.setState({ visible: true, isBusy: true });
    const user = userStore.userData;
    usersStore
      .getUser(user.userId)
      .finally(() => this.setState({ isBusy: false }))
      .then((result) => {
        this.setState({ userData: result });
        if (this.form) this.form.resetFields();
      });
  };

  render() {
    const user = this.state.userData;
    return (
      <div>
        <Button block={true} shape={'round'} type={'primary'} onClick={this.onOpen}>
          Запросить коммерческое предложение
        </Button>
        <Modal
          title={'Запросить коммерческое предложение'}
          okText={'Запросить'}
          okButtonProps={{ htmlType: 'submit', form: 'templateForm' }}
          open={this.state.visible}
          onCancel={this.onClose}>
          <Spin spinning={this.state.isBusy}>
            <Form
              name={'templateForm'}
              onFinish={this.onFinish}
              ref={(ref) => (this.form = ref)}
              initialValues={{ email: user ? user.email : null, phone: user ? user.mobilePhoneNumber : null }}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: 'Необходимо заполнить email' },
                      {
                        type: 'email',
                        message: 'Невалидный Email',
                      },
                    ]}>
                    <FloatInput placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="phone" rules={[{ required: true, message: 'Укажите телефон' }]}>
                    <InputMask mask="+9999999999999">{(inputProps) => <FloatInput placeholder="Мобильный" type={'tel'} />}</InputMask>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default observer(RequestKpModal);
