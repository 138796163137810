import HttpClient from '@utils/httpClient';

import { ICreateTariffInfologModel } from './tariffInfologData';

const service = {
  createTariff(data: ICreateTariffInfologModel) {
    return HttpClient.post(`/api/tariffInfolog/create`, data).then((resp) => {
      return resp.data;
    });
  },
  getTariffs() {
    return HttpClient.get(`/api/tariffInfolog/list`).then((resp) => {
      return resp.data;
    });
  },
  getTariffById(id: string) {
    return HttpClient.get(`/api/tariffInfolog/list/${id}`).then((resp) => {
      return resp.data;
    });
  },
  deleteTariff(id: string) {
    return HttpClient.delete(`/api/tariffInfolog/list/${id}`).then((resp) => {
      return resp.data;
    });
  },
  updateTariff(id: string, data: ICreateTariffInfologModel) {
    return HttpClient.patch(`/api/tariffInfolog/list/${id}`, data).then((resp) => {
      return resp.data;
    });
  },
  getAvailableTariff() {
    return HttpClient.get(`/api/tariffInfolog/available_tariff`).then((resp) => {
      return resp.data;
    });
  },
  getUserTariff(id: string) {
    return HttpClient.get(`/api/tariffInfolog/user_tariff/${id}`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
