import moment from 'moment';

import HttpClient from '@utils/httpClient';

import { ExchangeRateModel } from './exchangeRateData';

const exchangeRateService = {
  async getExchangeRateList(date: any): Promise<ExchangeRateModel[]> {
    const dateISO = moment(date).toISOString(true);
    return (await HttpClient.get(`/api/dictionary/exchangeRateList`, { params: { date: dateISO } })).data;
  },
};

export default exchangeRateService;
