import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ClaimReport } from '@stores/requestIlsStore/requestIlsStoreData';

interface State {}

interface Props {
  ilsClaim: ClaimReport;
}

@observer
class IlsClaimWagonCommonInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const claim = this.props.ilsClaim;
    const countWagons = claim?.sends?.reduce((acc, el) => acc + el.wagonCount, 0) || 0;
    return (
      <Row gutter={16} style={{ fontSize: 16 }}>
        <Col xs={6}>РПС</Col>
        <Col xs={6}>{claim.rps}</Col>
        <Col xs={6}>Всего вагонов:</Col>
        <Col xs={6}>
          <div className={'wagonsWrapper'}>
            <p>
              <span>{countWagons}</span>
            </p>
          </div>
        </Col>
        <Col xs={6}>Станция погрузки</Col>
        <Col xs={6}>{claim.loadStationName}</Col>
        <Col xs={6}>Привязано:</Col>
        <Col xs={6}>
          <div className={'wagonsWrapper'}>
            <p>
              <span>{claim.wagons?.length ?? 0}</span>
            </p>
          </div>
        </Col>
        <Col xs={6}>Группа груза</Col>
        <Col xs={6}>{claim.sends?.map((s) => s.parentEtsngName).join(',')}</Col>
        <Col xs={6}>Заадресовано:</Col>
        <Col xs={6}>
          <div className={'wagonsWrapper'}>
            <p>
              <span>{claim.wagons?.filter((w) => w.state == 'toload')?.length ?? 0}</span>
            </p>
          </div>
        </Col>
        <Col xs={6}></Col>
        <Col xs={6}></Col>
        <Col xs={6}>Отгружено:</Col>
        <Col xs={6}>
          <div className={'wagonsWrapper'}>
            <p>
              <span>{claim.wagons?.filter((w) => ['loaded', 'onway', 'unloading', 'complete'].includes(w.state))?.length ?? 0}</span>
            </p>
          </div>
        </Col>
        <Col xs={6}></Col>
        <Col xs={6}></Col>
        <Col xs={6}>Выгружено:</Col>
        <Col xs={6}>
          <div className={'wagonsWrapper'}>
            <p>
              <span>{claim.wagons?.filter((w) => ['complete'].includes(w.state))?.length ?? 0}</span>
            </p>
          </div>
        </Col>
      </Row>
    );
  }
}

export default IlsClaimWagonCommonInfo;
