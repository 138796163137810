import { Button } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, HeaderFilter, Paging, Summary } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';

import etranStore from '@stores/etranStore/etranStore';

interface Props {}

interface State {}

@observer
class EtranSPFsTable extends Component<Props, State> {
  dataGrid: DataGrid;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = etranStore.invoceFormData.devs;

    return (
      <div className={'etranTable'}>
        <EtranCaption title={'СПФ'} />
        <div className={'etranButtonsGroup'}>
          <Button disabled={false}>Привязать</Button>
          <Button disabled={true}>Отвязать</Button>
          <Button disabled={true}>Закрыть</Button>
          <Button disabled={true}>Открыть</Button>
        </div>
        <DataGrid
          onSelectionChanged={(e) => {
            const key = e.selectedRowsData[0];
            this.setState({ selected: key });
          }}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={false}
          dataSource={data}
          style={{ minHeight: '110px' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          selection={{ mode: 'single' }}>
          <Paging enabled={false} />
          <GroupPanel visible={false} />
          <HeaderFilter visible={false} allowSearch={false} />
          <FilterRow visible={false} />
          {INDEX_COLUMN}
          <Column allowFixing={true} dataField="?" caption={'ИД'} />
          <Column allowFixing={true} dataField="?" caption={'Кол-во вагонов'} />
          <Column allowFixing={true} dataField="?" caption={'Кол-во локомотивов'} />
          <Column allowFixing={true} dataField="?" caption={'Номер'} />
          <Column allowFixing={true} dataField="?" caption={'Состояние'} />

          <Summary></Summary>
        </DataGrid>
      </div>
    );
  }
}

export default EtranSPFsTable;
