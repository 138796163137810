import React from 'react';

const ClientLrDxCellReport: React.FC<any> = (props) => {
  const isGroup = props.rowType === 'group';
  //const isData = props.rowType === 'data';
  let value;
  try {
    value = JSON.parse(props.value);
  } catch (e) {}

  return (
    <div style={{ background: value ? (value.type == 'current' ? 'lightgreen' : 'lightyellow') : null }}>
      {isGroup && <span>{props.column.caption}: </span>}
      {value?.name}
    </div>
  );
};

export default ClientLrDxCellReport;
