import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { Component } from 'react';

interface Props {
  onChange(): void;
  messageId: number;
}

interface State {
  isBusyDelete: boolean;
}

class NotificationViewerActions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusyDelete: false,
    };
  }

  onDelete = () => {
    this.setState({ isBusyDelete: true });
    this.props.onChange();
    this.setState({ isBusyDelete: false });
  };

  render() {
    return (
      <span>
        <Button
          size={'small'}
          title={'Скачать'}
          loading={this.state.isBusyDelete}
          icon={<DeleteOutlined />}
          onClick={() => this.onDelete()}
        />
      </span>
    );
  }
}

export default NotificationViewerActions;
