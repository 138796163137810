import { DeploymentUnitOutlined, UserOutlined } from '@ant-design/icons';
import { DatePicker, List, Spin } from 'antd';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import logStore from '@stores/logStore/logStore';
import { GLOBAL_DATETIME_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface HistoryListState {
  dateRange: [Moment, Moment];
}

const IconText = ({ icon, text }) => (
  <span style={{ marginRight: 20 }}>
    {React.createElement(icon, { style: { marginRight: 4 } })}
    {text}
  </span>
);
const { RangePicker } = DatePicker;

@observer
class HistoryList extends Component<RouteComponentProps, HistoryListState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      dateRange: [moment().subtract(1, 'months'), moment()],
    };
  }

  componentDidMount(): void {
    logStore.getLog(this.state.dateRange[0], this.state.dateRange[1]);
    document.title = 'История';
  }

  render() {
    const log = logStore.log;
    const isBusy = logStore.isBusy;
    const dateRange = this.state.dateRange;

    return (
      <Spin spinning={isBusy}>
        <AdaptiveCard
          extra={<RangePicker value={dateRange} onChange={this.handleDateRangeChange} format={'DD.MM.YYYY'} allowClear={false} />}
          bodyStyle={{ height: 'calc(100vh - 3rem - var(--header-height) - var(--card-padding))' }}>
          <ScrolledContainer
            className={'scrolledContainer'}
            style={{ height: 'calc(100vh - 4.8rem - var(--header-height) - var(--card-padding))' }}>
            <List
              itemLayout="horizontal"
              size={'small'}
              dataSource={log}
              renderItem={(item) => (
                <List.Item>
                  <div style={{ display: 'flex', minWidth: '60%', maxWidth: '100%', alignItems: 'left' }}>
                    <List.Item.Meta
                      title={`${item.actionName} (${moment(item.createDate).format(GLOBAL_DATETIME_FORMAT)})`}
                      description={item.note}
                    />
                  </div>
                  <div style={{ display: 'flex', minWidth: '90px', alignContent: 'left' }}>
                    <IconText icon={UserOutlined} text={item.userLogin} key="login-icon" />
                  </div>
                  <div style={{ display: 'flex', minWidth: '150px', alignContent: 'left' }}>
                    <IconText icon={DeploymentUnitOutlined} text={item.userRole} key="role-icon" />
                  </div>
                </List.Item>
              )}
            />
          </ScrolledContainer>
        </AdaptiveCard>
      </Spin>
    );
  }

  handleDateRangeChange = (e) => {
    this.setState({ dateRange: e });
    logStore.getLog(e[0], e[1]);
  };
}

export default HistoryList;
