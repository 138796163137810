import HttpClient from '@utils/httpClient';

import { EquipmentDataViewModel } from './equipmentData';

const equipmentStoreService = {
  async getData(data: EquipmentDataViewModel): Promise<any> {
    return (await HttpClient.post(`/api/equipment/data`, data)).data;
  },

  async getDynamicData(data: any): Promise<any> {
    return (await HttpClient.post(`/api/equipment/dynamicData`, data)).data;
  },

  async getFile(data: EquipmentDataViewModel): Promise<any> {
    return (await HttpClient.post(`/api/equipment/data`, data, { responseType: 'blob' })).data;
  },

  async equipmentToXlsx(filterModel: any, progressFunc: ((e: ProgressEvent) => void) | undefined = undefined): Promise<Blob> {
    return (
      await HttpClient.post(`/api/equipment/export`, filterModel, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
      })
    ).data;
  },
};

export default equipmentStoreService;
