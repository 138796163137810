import { Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import etranStore from '@stores/etranStore/etranStore';

import { filterOptionFn } from '@utils/sort';

interface Props {
  onChange?: (value: any) => void;
  value?: any;
}

interface State {}

const { Option } = Select;

@observer
class EtranCountry extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  onChange = (e) => {
    if (this.props.onChange) this.props.onChange(e);
  };

  render() {
    const allCounties = etranStore.countries;
    return (
      <Select
        onChange={this.onChange}
        value={this.props.value}
        className={'comboEditSearchOther'}
        showSearch
        optionFilterProp={'children'}
        filterOption={filterOptionFn}>
        {allCounties.map((c) => (
          <Option key={c.code} value={c.code}>
            {c.shortName}
          </Option>
        ))}
      </Select>
    );
  }
}

export default EtranCountry;
