import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import { NotificationModel } from '@stores/notification';
import notificationStore, { NotificationTemplateModel } from '@stores/notification/notificationStore';
import usersStore from '@stores/usersStore/usersStore';

import { showErrorNotify } from '@utils/notify';

const { OptGroup } = Select;
interface Props {
  data?: NotificationModel;
  companyId: string;
}

interface State {
  isBusy: boolean;
  visible: boolean;
  data: any;
  selectedTemplate: NotificationTemplateModel;
  selectedTypes: { [key: string]: string };
}

@observer
class NotificationsEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: false,
      visible: false,
      data: null,
      selectedTemplate: null,
      selectedTypes: {},
    };
  }

  formRef: FormInstance;

  render() {
    const isBusy = this.state.isBusy;
    const data = this.state.data;
    const visible = this.state.visible;
    const types = this.state.selectedTypes;
    const templateGroups: { [key: string]: NotificationTemplateModel[] } = {};
    const currentTemplateCode = this.props.data ? this.props.data.templateCode : null;
    notificationStore.notificationTemplates.forEach((t) => {
      if (!notificationStore.notification.find((n) => n.templateCode == t.code && n.templateCode != currentTemplateCode)) {
        if (templateGroups[t.groupName]) {
          templateGroups[t.groupName].push(t);
        } else {
          templateGroups[t.groupName] = [t];
        }
      }
    });
    const template = this.state.selectedTemplate;
    const users = usersStore.users;
    return (
      <>
        <AdaptiveDrawer
          onClose={this.handelClose}
          width={700}
          title={'Уведомление'}
          visible={visible}
          footer={[
            <Button onClick={this.handelClose} size={'middle'}>
              Отмена
            </Button>,
            <Button htmlType={'submit'} type={'primary'} onClick={() => this.formRef.submit()} size={'middle'}>
              Сохранить
            </Button>,
          ]}>
          <Form
            layout="vertical"
            size={'large'}
            ref={(ref) => (this.formRef = ref)}
            onFinish={(e) => this.handleConfirm(e)}
            initialValues={data}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24}>
                <Form.Item name="templateCode" rules={[{ required: true, message: 'Укажите' }]}>
                  <FloatSelect placeholder="Шаблон оповещения" onSelect={(e) => this.handleTemplateChange(e)} disabled={this.isEditMode}>
                    {Object.keys(templateGroups).map((g) => (
                      <OptGroup key={g} label={g}>
                        {templateGroups[g].map((t) => (
                          <Select.Option key={t.code} value={t.code}>
                            {t.name}
                          </Select.Option>
                        ))}
                      </OptGroup>
                    ))}
                  </FloatSelect>
                </Form.Item>
              </Col>
            </Row>
            {template
              ? template.meta.params.map((p) => {
                  return (
                    <Row key={p.name} gutter={[16, 16]} style={{ marginTop: '10px' }}>
                      <Col xs={24}>
                        <Form.Item name={p.name} rules={[{ required: true, message: 'Укажите' }]}>
                          <FloatInputNumber placeholder={p.description} />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })
              : null}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: '10px' }}>
              <Col xs={24}>
                <Form.List name="actions">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <AdaptiveCard
                          size={'small'}
                          key={field.name}
                          extra={
                            <Button
                              icon={<DeleteOutlined />}
                              size={'small'}
                              type={'text'}
                              onClick={() => {
                                remove(field.name);
                                types[field.name] = null;
                                this.setState({ selectedTypes: types });
                              }}
                            />
                          }>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col xs={24}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'type']}
                                fieldKey={[field.key, 'type']}
                                rules={[{ required: true, message: 'Укажите получатетей' }]}>
                                <FloatSelect
                                  placeholder={'Куда отправлять'}
                                  onSelect={(e) => {
                                    types[field.name] = e.toString();
                                    if (this.formRef) {
                                      this.formRef.resetFields([['actions', field.name, 'receivers']]);
                                    }
                                    this.setState({ selectedTypes: types });
                                  }}>
                                  <Select.Option value={'chat'}>В чат</Select.Option>
                                  <Select.Option value={'mail'}>По почте</Select.Option>
                                </FloatSelect>
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                              {types[field.name] == 'mail' && (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'receivers']}
                                  fieldKey={[field.name, 'receivers']}
                                  rules={[
                                    { required: true, message: '' },
                                    {
                                      validator: (rule, value, callback) => {
                                        if (!Array.isArray(value)) {
                                          return Promise.reject('Укажите корректные адреса');
                                        }
                                        value.forEach((v) => {
                                          if (!v || typeof v != 'string') {
                                            return Promise.reject('Укажите корректные адреса');
                                          }
                                          if (v.indexOf('@') < 2 || v.indexOf('.') < 3) {
                                            return Promise.reject('Укажите корректные адреса');
                                          }
                                        });
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}>
                                  <FloatSelect
                                    placeholder={'Получатели'}
                                    mode={'tags'}
                                    getPopupContainer={(trigger) => trigger.parentElement}>
                                    {users.map((u) => (
                                      <Select.Option value={u.email} key={u.userId}>
                                        {u.email}
                                      </Select.Option>
                                    ))}
                                  </FloatSelect>
                                </Form.Item>
                              )}
                              {types[field.name] == 'chat' && (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'receivers']}
                                  fieldKey={[field.name, 'receivers']}
                                  rules={[{ required: true, message: 'Выберите' }]}>
                                  <FloatSelect
                                    placeholder={'Получатели'}
                                    mode={'multiple'}
                                    getPopupContainer={(trigger) => trigger.parentElement}>
                                    {users.map((u) => (
                                      <Select.Option value={u.userId} key={u.userId}>
                                        {u.name}
                                      </Select.Option>
                                    ))}
                                  </FloatSelect>
                                </Form.Item>
                              )}
                            </Col>
                          </Row>
                        </AdaptiveCard>
                      ))}
                      <Form.Item style={{ marginTop: '10px' }}>
                        <Button className="blue-gray-button" onClick={() => add()} block icon={<PlusOutlined />}>
                          Добавить получателей
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Form>
        </AdaptiveDrawer>
        {this.isEditMode ? (
          <Button onClick={this.openDrawer} icon={<EditOutlined />} title={'Изменить'} size={'small'} type={'text'} />
        ) : (
          <Button onClick={this.openDrawer} icon={<PlusOutlined />} title={'Создать'} type={'text'} size={'small'}>
            создать
          </Button>
        )}
      </>
    );
  }

  get isEditMode() {
    return !!this.props.data;
  }

  handleTemplateChange = (e) => {
    let selectedTemplate = notificationStore.notificationTemplates.find((t) => t.code == e);
    if (selectedTemplate) {
      this.setState({ selectedTemplate });
    }
  };

  openDrawer = () => {
    let selectedTemplate;
    let data;
    let selectedTypes = {};
    if (this.props.data) {
      selectedTemplate = notificationStore.notificationTemplates.find((t) => t.code == this.props.data.templateCode);
      data = {
        templateCode: this.props.data.templateCode,
        actions: this.props.data.content.actions,
      };
      this.props.data.content.actions.forEach((a, index) => {
        selectedTypes[index] = a.type;
      });
      this.props.data.content.params.forEach((p) => {
        data[p.name] = p.value;
      });
    }
    this.setState({
      visible: true,
      data: data,
      selectedTemplate: selectedTemplate,
      selectedTypes: selectedTypes,
    });
    setTimeout(() => {
      if (this.formRef) this.formRef.resetFields();
    }, 10);
  };

  handelClose = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: false });
  };

  async handleConfirm(e) {
    if (!e.actions || !e.actions.length) {
      showErrorNotify('Не хватает данных', 'Укажите получателей');
      return;
    }
    let request: NotificationModel = {
      templateCode: e.templateCode,
      updateDate: moment().toISOString(true),
      content: {
        actions: e.actions,
        params: this.state.selectedTemplate.meta.params.map((m) => {
          return { name: m.name, value: e[m.name] };
        }),
      },
    };
    this.setState({ isBusy: true });
    notificationStore.addNotificationByCompanyId(this.props.companyId, request).then(() => {
      this.setState({ isBusy: false });
      this.handelClose();
    });
  }
}

export default NotificationsEditor;
