import { DeleteOutlined, DeploymentUnitOutlined, EditOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Drawer, Input, List, Popconfirm, Row, Select, Spin, Tooltip } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import { Template } from 'devextreme-react/core/template';
import { Summary } from 'devextreme-react/data-grid';
import TreeList, { Column, ColumnChooser, HeaderFilter, Lookup, SearchPanel } from 'devextreme-react/tree-list';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import References from '@components/fw/Drawers/CompanyEditor/References';
import UserEditor from '@components/fw/Drawers/UserEditor';
import UserActiveBadge from '@components/fw/UserActiveBadge';

import designStore from '@stores/designStore/designStore';
import userStore from '@stores/userStore/userStore';
import { UserModel } from '@stores/usersStore';
import usersStore from '@stores/usersStore/usersStore';

import CheckboxCell from './CheckboxCell';
import styles from './UsersList.module.scss';

interface UsersListState {
  editedUser: UserModel | any;
  searchStr: string;
  statusFilter: string;
  editedReference: string;
}
const { Search } = Input;
const { Option } = Select;

export const IconText = ({ icon, text }) => (
  <span style={{ marginRight: 20 }}>
    {React.createElement(icon, { style: { marginRight: 4 } })}
    {text}
  </span>
);

@observer
class UsersList extends Component<RouteComponentProps, UsersListState> {
  summary: Summary;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      editedUser: null,
      searchStr: '',
      statusFilter: null,
      editedReference: null,
    };
  }

  componentDidMount(): void {
    if (this.isAdmin) usersStore.getHierarchy();
    else usersStore.getUsers();
    usersStore.getRoles();
    document.title = 'Пользователи';
  }

  get isAdmin() {
    return ['admin', 'tech', 'manager'].includes(userStore.userData.roleCode);
  }

  render() {
    if (this.isAdmin) return this.adminView();
    else return this.userView();
  }

  adminView() {
    const editedUser = this.state.editedUser;
    const editedReference = this.state.editedReference;
    const isMobile = designStore.isMobile;
    const padding = isMobile ? '9.2rem' : '9.7rem';

    return (
      <>
        <AdaptiveCard>
          <Button icon={<PlusOutlined />} size={'small'} onClick={this.handleCreate}>
            Создать
          </Button>
          <TreeList
            dataSource={usersStore.hierarchyOjectUsers}
            onToolbarPreparing={this.onToolbarPreparing.bind(this)}
            showBorders={true}
            columnAutoWidth={true}
            wordWrapEnabled={true}
            keyExpr="key"
            height={`calc(100vh - ${padding})`}
            parentIdExpr="parentKey"
            id="key"
            className={styles['v-align-m']}
            allowColumnResizing={true}
            autoExpandAll={true}>
            <SearchPanel visible={true} width={250} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} />
            <Column allowFixing={true} caption="Логин" dataField="userLogin" />
            <Column allowFixing={true} visible={false} caption="Top" dataField="managerLogin" />
            <Column allowFixing={true} dataField="userName" caption="Имя пользователя" cellTemplate="employeeTemplate">
              <Lookup dataSource={usersStore.hierarchyOjectUsers} displayExpr="userName" valueExpr="ID" />
            </Column>
            <Column allowFixing={true} dataField="userRole" caption="Роль" />
            <Column allowFixing={true} caption="Подключенные источники">
              {/*              <Column allowFixing={true}
                dataField="isMoscowApiEnabled"
                caption="msk"
                cellTemplate="checkboxTemplate"
                cssClass={styles['text-a-c']}
                width="65px"
              />
              <Column allowFixing={true} dataField="sngFtpEnabled" caption="sng" cellTemplate="checkboxTemplate" cssClass={styles['text-a-c']} width="65px" />*/}
              {designStore.logoType == 'ITK' && (
                <Column
                  allowFixing={true}
                  dataField="isTransreysApiEnabled"
                  caption="api"
                  cellTemplate="checkboxTemplate"
                  cssClass={styles['text-a-c']}
                  width="65px"
                />
              )}
              <Column
                allowFixing={true}
                dataField="isEtranSoapEnabled"
                caption="etran"
                cellTemplate="checkboxTemplate"
                cssClass={styles['text-a-c']}
                width="65px"
              />
              <Column
                allowFixing={true}
                dataField="isLagitEnabled"
                caption="lagit"
                cellTemplate="checkboxTemplate"
                cssClass={styles['text-a-c']}
                width="65px"
              />
            </Column>

            <Column allowFixing={true} caption="Интеграции">
              <Column
                allowFixing={true}
                dataField="isEtranLoginExists"
                caption="Этран"
                cellTemplate="checkboxTemplate"
                cssClass={styles['text-a-c']}
                width="65px"
              />
              {/* <Column allowFixing={true}
                dataField="isTaisLoginExists"
                caption="Таис"
                cellTemplate="checkboxTemplate"
                cssClass={styles['text-a-c']}
                width="65px"
              />
              <Column allowFixing={true}
                dataField="isSigisLoginExists"
                caption="Сигис"
                cellTemplate="checkboxTemplate"
                cssClass={styles['text-a-c']}
                width="65px"
              />*/}
              {designStore.logoType === 'ITK' && (
                <Column
                  allowFixing={true}
                  dataField="isRailwayLoginExists"
                  caption="Raiway"
                  cellTemplate="checkboxTemplate"
                  cssClass={styles['text-a-c']}
                  width="65px"
                />
              )}
            </Column>
            <Column allowFixing={true} caption="Парк ПС на момент">
              <Column
                allowFixing={true}
                dataField="containerCount"
                caption="Контейнеры"
                cssClass={styles['text-a-c']}
                customizeText={this.customizeObjectCount}
                width="84px"
              />
              <Column
                allowFixing={true}
                dataField="wagonCount"
                key={'wagonCount'}
                caption="Вагоны"
                cssClass={styles['text-a-c']}
                customizeText={this.customizeObjectCount}
                width="84px"
              />
            </Column>
            <Column
              allowFixing={true}
              dataField="hasDocuments"
              caption="Договоры"
              cellTemplate="docsTemplate"
              cssClass={styles['text-a-c']}
            />
            <Template name="employeeTemplate" render={this.EmployeeCell.bind(this)} />
            <Template name="docsTemplate" render={this.docsTemplate} />
            <Template name="checkboxTemplate" render={CheckboxCell} />
          </TreeList>
        </AdaptiveCard>
        {editedUser ? (
          <UserEditor user={editedUser} onCancel={this.handleUserEditorCancel} onConfirm={this.handleUserEditorConfirm} />
        ) : null}
        <Drawer
          title={'Договоры'}
          width={designStore.isMobile ? '100%' : '40%'}
          onClose={() => this.setState({ editedReference: null })}
          visible={!!editedReference}>
          {!!editedReference && <References companyId={editedReference} />}
        </Drawer>
      </>
    );
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      text: `Всего на слежении вагонов: ${usersStore.wagonCount}, контейнеров: ${usersStore.containerCount}`,
    });
  }

  docsTemplate = (e) => {
    const data = e.value;
    if (data == undefined) return null;
    return (
      <>
        <CheckBox readOnly={true} defaultValue={e.value}></CheckBox>
        {userStore.canAccess('administration/companies/services') && (
          <Button size={'small'} type={'link'} onClick={() => this.setState({ editedReference: e.key })}>
            {e.value ? 'Изменить' : 'Создать'}
          </Button>
        )}
      </>
    );
  };

  calculateSelectedRow(options) {
    if (options.summaryProcess === 'start') {
      options.totalValue = 0;
    } else if (options.summaryProcess === 'calculate') {
      if (options.value.isExcept !== true) {
        options.totalValue = options.totalValue + options.value[options.name];
      }
    }
  }

  customizeObjectCount(cellInfo) {
    if (cellInfo.value === undefined) return '';
    return cellInfo.value ? `${cellInfo.value}` : '0';
  }

  EmployeeCell(options) {
    const data = options.data;
    if (!data.userName) {
      return;
    }
    if (data.userId == 'without_manager') return data.userName;

    return (
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col style={{ display: 'flex' }}>
          <UserActiveBadge type={data.userActiveType} activeDate={data.userActiveDate}>
            <Avatar style={{ verticalAlign: 'middle' }}>{data.userLogin}</Avatar>
          </UserActiveBadge>
          <p style={{ margin: 'auto 10px' }}>{data.userName}</p>
        </Col>
        <Col style={{ display: 'flex', margin: 'auto 0 auto auto', justifyContent: 'space-around', minWidth: '3rem' }}>
          <Tooltip title={'Редактировать'}>
            <EditOutlined onClick={() => this.handleEdit(data)} />
          </Tooltip>
          <span style={{ color: '#f0f0f0' }}>|</span>
          <Tooltip title={'Удалить'}>
            <Popconfirm title="Удалить пользователя？" okText="Да" cancelText="Нет" onConfirm={() => this.handleRemoveConfirm(data)}>
              <DeleteOutlined style={data.isRemoveRequest ? { color: '#52c41a' } : null} />
            </Popconfirm>
          </Tooltip>
        </Col>
      </Row>
    );
  }

  statuses = ['Not Started', 'Need Assistance', 'In Progress', 'Deferred', 'Completed'];

  filter(users: UserModel[], searchStr: string, activeType: string | null) {
    const usersByType = activeType ? users.filter((x) => x.activeType === activeType) : users;

    return searchStr
      ? usersByType.filter(
          (x) =>
            (x.name && x.name.toLowerCase().includes(searchStr.toLowerCase())) ||
            (x.companyName && x.companyName.toLowerCase().includes(searchStr.toLowerCase())) ||
            (x.login && x.login.toLowerCase().includes(searchStr.toLowerCase())) ||
            (x.roleName && x.roleName.toLowerCase().includes(searchStr.toLowerCase())),
        )
      : usersByType;
  }

  userName = (user: UserModel) => {
    if (user.companyName) {
      return `${user.name} (${user.companyName})`;
    } else {
      return user.name;
    }
  };

  handleSearch = (value) => {
    this.setState({
      searchStr: value,
    });
  };

  handleCreate = () => {
    this.setState({
      editedUser: {
        userId: null,
        login: null,
        password: null,
        name: null,
        email: null,
        roleCode: null,
        roleName: null,

        userAccessList: [],
        roleAccessList: [],
        companyAccessList: [],

        companyId: null,
        companyName: null,

        organizationId: null,
      },
    });
  };

  handleEdit = (user: any) => {
    usersStore.loadingUsers = true;
    usersStore
      .getUser(user.userId)
      .finally(() => (usersStore.loadingUsers = false))
      .then((result) => {
        this.setState({
          editedUser: Object.assign({ ...result }, { companyAccessList: result.companyAccessList || [] }),
        });
      });
  };

  handleRemoveConfirm = (user: any) => {
    usersStore.removeUser(user.userId).then(() => {
      if (this.isAdmin) usersStore.getHierarchy();
      else usersStore.getUsers();
      setTimeout(() => this.forceUpdate(), 1000);
    });
  };

  handleUserEditorCancel = () => {
    this.setState({ editedUser: null });
  };

  handleUserEditorConfirm = (user: UserModel) => {
    if (this.isAdmin) usersStore.getHierarchy();
    else usersStore.getUsers();
    this.setState({ editedUser: null });
  };

  userView() {
    const editedUser = this.state.editedUser;
    const searchStr = this.state.searchStr;
    const statusFilter = this.state.statusFilter;
    const users = this.filter(usersStore.users, searchStr, statusFilter);
    const loadingUsers = usersStore.loadingUsers;
    const height = 'calc(100vh - 125px)';

    return (
      <div style={{ height: 'calc(100vh - var(--header-height))' }}>
        <AdaptiveCard
          title={
            <Button icon={<PlusOutlined />} size={'small'} onClick={this.handleCreate} key={1}>
              Создать
            </Button>
          }
          extra={
            <CollapsedToolbar
              content={[
                <Select
                  onClick={(e) => e.stopPropagation()}
                  key={2}
                  bordered={false}
                  size={'small'}
                  placeholder={'Статус'}
                  allowClear={true}
                  style={{ width: 120 }}
                  onChange={(value: string) => this.setState({ statusFilter: value })}>
                  <Option value={null}>все</Option>
                  <Option value="active">активный</Option>
                  <Option value="walkedAway">отошёл</Option>
                  <Option value="notAvailable">недоступен</Option>
                  <Option value="unknown">неизвестно</Option>
                </Select>,
                <Search
                  onClick={(e) => e.stopPropagation()}
                  key={3}
                  size={'small'}
                  placeholder="Поиск"
                  onSearch={(value) => this.setState({ searchStr: value })}
                  allowClear={true}
                  style={{ width: 200 }}
                />,
              ]}
            />
          }>
          <Spin spinning={loadingUsers}>
            <List
              itemLayout="horizontal"
              dataSource={users}
              renderItem={(item) => (
                <List.Item
                  style={{ paddingRight: 50 }}
                  actions={[
                    <Tooltip title={'Редактировать'}>
                      <EditOutlined onClick={() => this.handleEdit(item)} />
                    </Tooltip>,
                    <Tooltip title={'Удалить'}>
                      <Popconfirm
                        title="Удалить пользователя？"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => this.handleRemoveConfirm(item)}>
                        <DeleteOutlined />
                      </Popconfirm>
                    </Tooltip>,
                  ]}>
                  <List.Item.Meta
                    avatar={
                      <UserActiveBadge type={item.activeType} activeDate={item.activeDate}>
                        <Avatar style={{ verticalAlign: 'middle' }}>{item.login}</Avatar>
                      </UserActiveBadge>
                    }
                    title={item.name}
                    description={
                      <>
                        <IconText icon={DeploymentUnitOutlined} text={item.roleName} key="role-icon" />
                        <IconText icon={UserOutlined} text={item.login} key="login-icon" />
                      </>
                    }
                  />
                  {/*<IconText icon={EditOutlined} text="156" key="login" />*/}
                  {/*<IconText icon={DeleteOutlined} text="156" key="role" />,*/}
                </List.Item>
              )}
            />
          </Spin>
          {editedUser ? (
            <UserEditor user={editedUser} onCancel={this.handleUserEditorCancel} onConfirm={this.handleUserEditorConfirm} />
          ) : null}
        </AdaptiveCard>
      </div>
    );
  }
}

export default UsersList;
