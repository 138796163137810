import { Modal, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

const Ecp = React.lazy(() => import('./Ecp'));

interface State {
  visible: boolean;
}

interface Props {
  documentId: string;
  documentName: string;

  onOk(): void;

  onCancel(): void;
}

class EcpModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render() {
    return (
      <Modal
        open={true}
        className={'ecpModal'}
        width={'fit-content'}
        title={'Подписать ' + this.props.documentName}
        okText={'Подписать'}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={(e) => {
          e.stopPropagation();
          this.props.onCancel();
        }}>
        <React.Suspense fallback={<Spin spinning={true} />}>
          <Ecp documentId={this.props.documentId} documentName={this.props.documentName} onOk={this.props.onOk} />
        </React.Suspense>
      </Modal>
    );
  }
}

export default observer(EcpModal);
