import { Radio } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import AccessRoute from '@components/fw/RoutingContext/AccessRoute';
import WagonAdd from '@components/fw/Tracking/Wagon/WagonAdd';
import WagonGroupRemove from '@components/fw/Tracking/Wagon/WagonGroupRemove';
import WagonRemove from '@components/fw/Tracking/Wagon/WagonRemove';

import routingStore from '@stores/fw/routingStore/routingStore';
import trackingWagonStore from '@stores/trackingStore/wagon/trackingWagonStore';
import userStore from '@stores/userStore/userStore';

import TrackingWagonsActive from './TrackingWagonsActive';
import TrackingWagonsHistory from './TrackingWagonsHistory';

interface TrackingState {}

class TrackingWagons extends Component<RouteComponentProps, TrackingState> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  render() {
    const path = routingStore.location.pathname;
    const viewType = path == '/billing/orders/wagons/active' ? 'active' : 'history';
    const canEdit = userStore.canAccess('billing/orders/wagons/edit');
    document.title = 'Заказы';

    return (
      <>
        <AdaptivePageHeader
          title={'Вагоны'}
          extra={
            <CollapsedToolbar
              content={[
                canEdit ? <WagonAdd onDataReady={this.handleDataChanged} key={'add'} /> : null,
                canEdit ? <WagonRemove onDataReady={this.handleDataChanged} key={'remove'} /> : null,
                canEdit ? (
                  <WagonGroupRemove data={trackingWagonStore.selected} onDataReady={this.handleDataChanged} key={'change'} />
                ) : null,
                <Radio.Group
                  key={'selector'}
                  defaultValue={viewType}
                  buttonStyle="solid"
                  size={'small'}
                  onChange={(value) => this.handleViewTypeChanged(value)}
                  value={viewType}
                  style={{ marginLeft: 5, marginTop: 4 }}>
                  <Radio.Button key={'active'} value={'active'}>
                    Активные
                  </Radio.Button>
                  <Radio.Button key={'history'} value={'history'}>
                    История
                  </Radio.Button>
                </Radio.Group>,
              ]}
            />
          }
          className={'page_header'}
        />
        <AdaptiveCard>
          <Switch>
            <AccessRoute
              path="/billing/orders/wagons/active"
              component={TrackingWagonsActive}
              canAccess={userStore.canAccess('billing/orders/wagons')}
            />
            <AccessRoute
              path="/billing/orders/wagons/history"
              component={TrackingWagonsHistory}
              canAccess={userStore.canAccess('billing/orders/wagons')}
            />
          </Switch>
        </AdaptiveCard>
      </>
    );
  }

  handleViewTypeChanged = (value) => {
    this.props.history.push(`/billing/orders/wagons/${value.target.value}`);
  };

  handleDataChanged() {
    trackingWagonStore.loadHistory();
    trackingWagonStore.loadActive();
  }
}

export default observer(TrackingWagons);
