import { FileExcelOutlined, FileUnknownOutlined, FileWordOutlined } from '@ant-design/icons';
import { Button, Progress } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import { StatisticDocInfo } from '@stores/statisticStore/statisticData';
import statisticService from '@stores/statisticStore/statisticStore.service';

interface Props {
  data: StatisticDocInfo;
}

interface State {
  isBusy: boolean;
  progress: {
    lengthComputable: boolean;
    loaded: number;
    total: number;
  };
}

class DocumentButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: false,
      progress: this.initProgress,
    };
  }

  get initProgress() {
    return {
      lengthComputable: true,
      loaded: 0,
      total: 1,
    };
  }

  render() {
    const isBusy = this.state.isBusy;
    const data = this.props.data;
    const progress = this.state.progress;
    if (isBusy && progress.lengthComputable) {
      return (
        <div style={{ height: '25px', width: '100%', textAlign: 'center', verticalAlign: 'middle' }}>
          <Progress percent={(progress.loaded / progress.total) * 100} size="small" />
        </div>
      );
    } else {
      return (
        <Button
          type={'text'}
          loading={isBusy}
          onClick={this.handleDownload}
          style={{ height: '25px' }}
          icon={
            data.extension == 'xlsx' ? <FileExcelOutlined /> : data.extension == 'docx' ? <FileWordOutlined /> : <FileUnknownOutlined />
          }
        />
      );
    }
  }
  handleDownload = () => {
    this.setState({ isBusy: true, progress: this.initProgress });
    statisticService
      .getDocBody(this.props.data.documentId, (e: ProgressEvent) => {
        this.setState({ progress: e });
      })
      .then((ans) => {
        let mockEvent = document.createEvent('MouseEvents');
        let mockElement = document.createElement('a');
        mockElement.download =
          this.props.data.name +
          '_' +
          (this.props.data.docNumber && this.props.data.docNumber != ''
            ? this.props.data.docNumber
            : moment(this.props.data.date).format('MMMM_YYYY')) +
          '.' +
          this.props.data.extension;
        mockElement.href = window.URL.createObjectURL(new Blob([ans]));
        mockElement.dataset.downloadurl = ['blob', mockElement.download, mockElement.href].join(':');
        mockEvent.initEvent('click');
        mockElement.dispatchEvent(mockEvent);
      })
      .finally(() => {
        setTimeout(() => this.setState({ isBusy: false }), this.state.progress.lengthComputable ? 1000 : 0);
      });
  };
}

export default DocumentButton;
