import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Column,
  Export,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  StateStoring,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import qs from 'qs';
import React, { Component } from 'react';

import ClaimIdDxCell from '@components/fw/DxCellTemplates/ClaimIdDxCell';
import NotificationDxCellReport from '@components/fw/DxCellTemplates/NotificationDxCellReport';
import TagDxCell from '@components/fw/DxCellTemplates/TagDxCell';
import FilterEditor from '@components/fw/FiltersEditor/FilterEditor';
import SendsBoardDetail from '@components/routed/SendsPage/TableView/SendsBoardDetail';

import filterStore, { FilterContentModel } from '@stores/FilterStore/filterStore';
import { ClaimModel } from '@stores/claimsStore/ClaimData';
import claimsStore from '@stores/claimsStore/claimsStore';
import { ignoreCaseFilter } from '@stores/dispatcherStore/DispatcherData';
import notificationStore from '@stores/notification/notificationStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { SendModel } from '@stores/sendsStore/sendData';
import tagStore from '@stores/tagStore/tagStore';
import userStore from '@stores/userStore/userStore';
import usersStore from '@stores/usersStore/usersStore';

import DocumentsDxCell from './DocumentsDxCell';

interface TableViewProps {
  onSelectionChanged(row: any): void;
  onRowDblClick(row: ClaimModel): void;
  onMasterRowDblClick(row: SendModel): void;
  onDelete(row: any): void;
  location: any;
}

interface TableViewState {
  dataSource: DataSource;
  reloading: boolean;
  currentOption: object;
}

export const claimDocumentStates = [
  { value: '"commercialOfferStatus": "agr"', text: 'КП согласовано' },
  { value: '"commercialOfferStatus": "onagr"', text: 'КП на согласовании' },
  { value: '"contractStatus": "agr"', text: 'Договор согласован' },
  { value: '"contractStatus": "onagr"', text: 'Договор на согласовании' },
  { value: '"contractStatus": null', text: 'Договор отсутствует' },
  { value: '"contractExtStatus": "agr"', text: 'Все доп. соглашения согласованы' },
  { value: '"contractExtStatus": "onagr"', text: 'Все доп. соглашения на согласовании' },
  { value: '"contractExtStatus": "diff"', text: 'Доп. соглашения имеют различный статус согласования' },
  { value: '"contractExtStatus": null', text: 'Нет доп. соглашений' },
  { value: null, text: '(Пустое)' },
];

@observer
class TableView extends Component<TableViewProps, TableViewState> {
  dataGrid: DataGrid;
  ExcelJs: any;

  constructor(props: TableViewProps) {
    super(props);

    import('exceljs').then((e) => {
      this.ExcelJs = e;
    });
    this.state = {
      dataSource: this.dataSource,
      reloading: false,
      currentOption: !usersStore.tableViewCurrentOption ? usersStore.tableViewCurrentOption : null,
    };
  }

  get dataSource() {
    return new DataSource({
      store: new CustomStore({
        key: 'claimid',
        load: function (loadOptions: any) {
          return claimsStore
            .loadPlan(loadOptions)
            .then((data) => {
              if (data.data) {
                data.data.forEach((d) => {
                  d.rowspan = 0;
                  const filtered = data.data.filter((s) => d.claimid === s.claimid);
                  if (filtered.length) {
                    filtered[0].rowspan = filtered.length;
                  }
                  if (d.items && d.items.length) {
                    d.count = d.items.length;
                  }
                  //С бэка прилетает строка, а юзеры требуют число
                  if (d.claimnumber) {
                    d.claimnumber = Number(d.claimnumber);
                  }
                });
              }

              return data;
            })
            .catch(() => {
              throw 'Data Loading Error';
            });
        },
      }),
    });
  }

  getFiler = (name: string) => {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const data = query[name];
    if (!data) return null;
    let target = Array.isArray(data) ? data : [data];
    if (name == 'documentdata') {
      return target.map((t) => claimDocumentStates[Number(t)].value);
    }
    if (name == 'createdate') {
      return target.map((t) => moment(t).toDate());
    }
    return target;
  };

  get filterCode() {
    return 'load_plan';
  }
  componentDidMount() {
    filterStore.setSelected(this.filterCode, null);
  }

  render() {
    const data = this.state.dataSource;
    const height = 'calc(100vh - var(--padding))';
    const dateFilter = this.getFiler('createdate');
    return (
      !this.state.reloading && (
        <DataGrid
          allowColumnReordering={true}
          dataSource={data}
          height={height}
          ref={(ref) => (this.dataGrid = ref)}
          onSelectionChanged={this.handleSelectionChanged}
          onRowDblClick={this.handleRowDoubleClick}
          onContextMenuPreparing={this.handleContextMenuPreparing}
          onToolbarPreparing={this.onToolbarPreparing.bind(this)}
          onExporting={this.onExporting}
          selection={{ mode: 'single' }}
          allowColumnResizing={true}
          remoteOperations={{ paging: true, filtering: true, sorting: true, grouping: true, summary: true }}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}>
          <Export enabled={true} excelWrapTextEnabled={true} />
          <GroupPanel visible={true} emptyPanelText={'Поле группировки'} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Paging enabled={true} defaultPageSize={25} />
          <Pager
            allowedPageSizes={[25, 50, 100]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            infoText={'Всего: {2}'}
          />
          <FilterRow visible={true} />
          <StateStoring enabled={true} type="custom" customSave={(e) => this.handleStorageSave(e)} customLoad={this.handleStorageLoad} />
          <Column allowFixing={true} dataField="claimnumber" caption={'Номер'} dataType={'number'} />
          <Column
            allowFixing={true}
            groupCellRender={NotificationDxCellReport}
            dataField="notifications"
            caption={'Уведомления'}
            headerFilter={{
              dataSource: {
                load: (loadOptions) => {
                  return notificationStore.getNotificationTemplates(loadOptions.searchValue, 'Этапы жизни заявки');
                },
              },
            }}
            calculateFilterExpression={(value, selectedFilterOperations, target) => {
              if (target === 'headerFilter') {
                return ['notifications', value ? 'contains' : '=', value, 'ignorecase'];
              }
            }}
            cellRender={NotificationDxCellReport}
          />
          <Column
            allowFixing={true}
            calculateGroupValue={'claimid'}
            dataField="claimname"
            caption={'Название заявки'}
            groupCellRender={ClaimIdDxCell}
            cellRender={ClaimIdDxCell}
          />
          <Column allowFixing={true} dataField="groupname" caption={'Группа'} calculateFilterExpression={ignoreCaseFilter} />
          <Column
            allowFixing={true}
            dataField="statusname"
            caption={'Статус'}
            filterValues={this.getFiler('statusname')}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="producername" caption={'Постановщик'} calculateFilterExpression={ignoreCaseFilter} />
          <Column allowFixing={true} dataField="executorname" caption={'Исполнитель'} calculateFilterExpression={ignoreCaseFilter} />
          <Column
            allowFixing={true}
            dataField="createdate"
            caption={'Дата создания'}
            dataType={'date'}
            selectedFilterOperation={dateFilter && dateFilter.length ? 'between' : null}
            filterValue={dateFilter}
            allowHeaderFiltering={false}
          />
          <Column allowFixing={true} dataField="executiondate" caption={'Дата исполнения'} dataType={'date'} allowHeaderFiltering={false} />
          <Column
            allowFixing={true}
            filterValues={this.getFiler('documentdata')}
            dataField="documentdata"
            caption={'Документы'}
            allowFiltering={false}
            allowHeaderFiltering={true}
            groupCellRender={DocumentsDxCell}
            cellRender={DocumentsDxCell}
            headerFilter={{
              dataSource: claimDocumentStates,
            }}
            calculateFilterExpression={(value, selectedFilterOperations, target) => {
              if (target === 'headerFilter') {
                if (value) return ['documentdata', 'contains', value, 'ignorecase'];
                else return ['documentdata', '=', null];
              }
            }}
          />

          <Column
            allowFixing={true}
            groupCellRender={TagDxCell}
            dataField="tags"
            caption={'Теги'}
            headerFilter={{
              dataSource: tagStore.tagsForFilter,
            }}
            calculateFilterExpression={(value, selectedFilterOperations, target) => {
              if (target === 'headerFilter') {
                if (value) return ['tags', 'contains', value, 'ignorecase'];
                else return ['tags', '=', '[]'];
              }
            }}
            cellRender={TagDxCell}
          />
          <Column allowFixing={true} dataField="companyname" caption={'Клиент'} calculateFilterExpression={ignoreCaseFilter} />
          <Column
            allowFixing={true}
            dataField="loadstationnames"
            caption={'Станция погрузки'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="loadroadnames" caption={'Дорога погрузки'} calculateFilterExpression={ignoreCaseFilter} />
          <Column
            allowFixing={true}
            dataField="deststationnames"
            caption={'Станция выгрузки'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="destroadnames" caption={'Дорога выгрузки'} calculateFilterExpression={ignoreCaseFilter} />
          <Column allowFixing={true} dataField="wagoncount" caption={'Кол-во пс в заявке'} />
          <Column allowFixing={true} dataField="validityfrom" caption={'Дата начала'} dataType={'date'} allowHeaderFiltering={false} />
          <Column allowFixing={true} dataField="validityto" caption={'Дата окончания'} dataType={'date'} allowHeaderFiltering={false} />
          <Column allowFixing={true} dataField="plancost" caption={'Стоимость заявки'} />
          <Column allowFixing={true} dataField="markedwagoncount" caption={'Размечено'} />
          <Column allowFixing={true} dataField="sentwagoncountfact" caption={'Отгружено факт'} />
          <Column allowFixing={true} dataField="remainingquantity" caption={'Остаток по количеству'} />
          <Column allowFixing={true} dataField="provisionpersentage" caption={'Обеспечение в процентах'} />
          <Column allowFixing={true} dataField="realizationpersentage" caption={'Выполнение в процентах'} />
          <Template name={'filter'} render={() => this.filterSelectorTemplate()} />
          {/*<MasterDetail
            enabled={userStore.canAccess('sends')}
            component={(e) => <RowDetail onRowDblClick={this.handleMasterRowDoubleClick} {...e} />}
          />*/}
          <MasterDetail
            enabled={userStore.canAccess('sends') || userStore.canAccess('administration')}
            component={(e) => <SendsBoardDetail onRowDblClick={this.handleMasterRowDoubleClick} {...e} />}
          />
          {/*          <Summary>
            <TotalItem column="wagoncount" summaryType="sum" skipEmptyValues={true} name={'Итого:'} />
            <TotalItem column="plancost" summaryType="sum" />
            <TotalItem column="markedwagoncount" summaryType="sum" />
            <TotalItem column="sentwagoncountfact" summaryType="sum" />
          </Summary>*/}
        </DataGrid>
      )
    );
  }

  handleSelectionChanged = ({ selectedRowsData }: any) => {
    const data = this.rowToClaim(selectedRowsData[0]);
    this.props.onSelectionChanged(data);
  };

  handleRowDoubleClick = (e: any) => {
    // if (!$(e.event.target).parents('.dx-master-detail-row').length) {
    try {
      if (e.rowType === 'data') {
        const item = this.rowToClaim(e.data) as ClaimModel;
        this.props.onRowDblClick(item);
      }
    } catch (e) {}
    //}
  };

  rowToClaim(data) {
    return {
      claimId: data.claimid,
      claimName: data.claimname,
    };
  }

  handleMasterRowDoubleClick = (item: SendModel) => {
    this.props.onMasterRowDblClick(item);
  };

  handleContextMenuPreparing = (e) => {
    if (!userStore.canAccess('claims/edit')) return;
    if (e.target === 'content') {
      if (e.row && e.row.rowType === 'data') {
        const row = e.row.data as ClaimModel;
        if (!e.items) e.items = [];

        e.items.push(
          {
            text: 'Изменить',
            onItemClick: () => {
              this.props.onRowDblClick(this.rowToClaim(row) as ClaimModel);
            },
          },
          {
            text: 'Удалить',
            onItemClick: () => {
              this.props.onDelete(this.rowToClaim(row) as ClaimModel);
            },
          },
        );
      }
    }
  };
  onExporting = (e) => {
    const workbook = new this.ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Лист1');
    const name = 'План погрузки';

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: { row: 1, column: 1 },
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;

        if (gridCell.value && gridCell.column.dataField.toLowerCase().includes('tags')) {
          const v = JSON.parse(gridCell.value);
          excelCell.value = v.map((f) => f.name).join(String.fromCharCode(10)); //Символ переноса Для excell таблиц
          excelCell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true }; //Настройки ячейки Excell для применения переносов строки
        }
        if (gridCell.value && gridCell.column.dataField.toLowerCase().includes('claimname')) {
          if (gridCell.rowType != 'group') {
            if (gridCell.data && gridCell.data.rowspan > 1) {
              worksheet.mergeCells(excelCell._address + ':' + 'B' + (excelCell._row._number + gridCell.data.rowspan - 1));
            }
            if (gridCell.data) excelCell.value = gridCell.data.claimname;
          } else {
            let find = claimsStore.tmpClaimData.find((f) => f.claimid === gridCell.value);
            if (find) {
              excelCell.value = find.claimname;
            }
          }
        }
        if (gridCell.value && gridCell.column.dataField.toLowerCase().includes('claimnumber')) {
          if (gridCell.data && gridCell.data.rowspan > 1) {
            worksheet.mergeCells(excelCell._address + ':' + 'A' + (excelCell._row._number + gridCell.data.rowspan - 1));
          }
        }
      },
    }).then(function () {
      const date = moment().format(GLOBAL_DATE_FORMAT).toString();
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), date + `-Отчёт-${name}.xlsx`);
      });
    });
    e.cancel = true;
  };

  filterSelectorTemplate = () => {
    return <FilterEditor reportType={'load_plan'} reportId={null} onChange={(item) => this.hardReload(item)} />;
  };

  hardReload(item: FilterContentModel | null) {
    if (this.dataGrid) {
      if (!item) {
        this.dataGrid.instance.clearFilter();
        this.dataGrid.instance.clearGrouping();
        this.dataGrid.instance.clearSorting();
      }
      this.setState({ reloading: true });
      setTimeout(() => this.setState({ reloading: false }), 1);
    }
  }

  handleStorageSave = (e) => {
    filterStore.setContent(e, this.filterCode);
  };

  handleStorageLoad = (e) => {
    return filterStore.getContent(this.filterCode);
  };

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'filter',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.state.dataSource.reload();
          },
        },
      },
    );
  }
}

export default TableView;
