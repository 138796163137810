import Icon from '@ant-design/icons';
import { DatePicker } from 'antd';
import { RangePickerProps as BaseRangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { Moment } from 'moment';
import React, { Component } from 'react';

import { ReactComponent as iconCalendar } from '@assets/icons/icon-calendar.svg';

import { InputPosition } from '@stores/designStore/designData';

import BorderWrapper from './BorderWrapper';
import styles from './FloatLabel.module.scss';

interface State {
  focus: boolean;
}

class FloatRangepicker extends Component<BaseRangePickerProps<Moment> & { position?: InputPosition }, State> {
  constructor(props: BaseRangePickerProps<Moment> & { position?: InputPosition }) {
    super(props);

    this.state = {
      focus: false,
    };
  }

  render() {
    const { value, placeholder, position } = this.props;
    const labelClass = this.state.focus || value ? styles.labelfloat : styles.label;
    const isStringLabel = typeof this.props.placeholder === 'string';

    return (
      <BorderWrapper
        disabled={!!this.props.disabled}
        className={styles.floatlabel}
        position={position}
        onFocusChange={(e) => this.setState({ focus: e })}>
        <DatePicker.RangePicker
          {...this.props}
          bordered={false}
          placeholder={null}
          className={styles.picker}
          getPopupContainer={(trigger) => trigger.parentElement}
          suffixIcon={<Icon component={iconCalendar} />}
        />
        {placeholder ? (
          <span style={{ pointerEvents: isStringLabel ? 'none' : null }} className={labelClass}>
            {placeholder[0]}
          </span>
        ) : null}
      </BorderWrapper>
    );
  }
}
export default FloatRangepicker;
