import { Button, Checkbox, Col, Form, Modal, Row, Spin, Typography } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatDatePicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import MarkSelect from '@components/fw/MarkSelect';
import RemoteSelect from '@components/fw/RemoteSelect';

import dictService from '@stores/dictStore/dictStore.service';
import {
  CompanyLoadRegistryListUpdateModel,
  RateType,
  Vat,
  rateTypeOptions,
  vatOptions,
  vatToNumber,
} from '@stores/loadRegistryStore/loadRegistryData';
import loadRegistryStore from '@stores/loadRegistryStore/loadRegistryStore';
import loadRegistryStoreService from '@stores/loadRegistryStore/loadRegistryStore.service';
import markStore from '@stores/markStore';
import organizationsStore from '@stores/organizationsStore';
import userStore from '@stores/userStore';

import { filterOptionFn } from '@utils/sort';

interface LoadingRegisterSupplierFormProps {
  onFinish(): void;
  onCancel(): void;
}

interface LoadingRegisterSupplierFormState {
  data: CompanyLoadRegistryListUpdateModel;
  loading: boolean;
}

/**
 * Модалка для редактирования одного поля - id поставщика
 */

@observer
class LoadingRegisterSupplierForm extends Component<LoadingRegisterSupplierFormProps, LoadingRegisterSupplierFormState> {
  formRef: FormInstance;

  constructor(props: LoadingRegisterSupplierFormProps) {
    super(props);
    this.state = {
      data: new CompanyLoadRegistryListUpdateModel(loadRegistryStore.edit),
      loading: false,
    };
  }

  get isListEdit() {
    return loadRegistryStore.edit.length > 1;
  }

  onFinish = (e: CompanyLoadRegistryListUpdateModel) => {
    this.setState({ loading: true });
    e.loadRegistryIds = this.state.data.loadRegistryIds;
    //Изменить нужно только одно поле, поэтому изменение всех полей отключаем
    Object.keys(e).forEach((key) => {
      if (key.startsWith('change')) {
        e[key] = false;
      }
    });
    //А включаем только редактирование поставщика. Не путать с меткой 3. Метку 3 заказчик тоже называет "Поставщик"
    e.changeSupplierId = true;
    loadRegistryStoreService
      .updateListCompanyLoadRegistry(e)
      .then(() => {
        this.props.onFinish();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { onCancel } = this.props;
    const data = this.state.data;
    const options = organizationsStore.organizations.map((organization) => {
      return { label: organization.name, value: organization.organizationId };
    });

    return (
      <Modal
        centered={true}
        open={true}
        onCancel={onCancel}
        footer={null}
        title={`Изменение поставщика ${this.isListEdit ? 'группы' : ''}`}
        width={1150}
        style={{ maxWidth: '100%' }}>
        <Spin spinning={this.state.loading}>
          <Form
            // form={this.formRef}
            ref={(ref) => (this.formRef = ref)}
            style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
            layout="vertical"
            initialValues={{
              supplierId: data.supplierId,
            }}
            onFinish={this.onFinish}>
            <Row className={'loading-register--form-row'} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className={'loading-register--form-row--column'} xs={24} sm={24} md={12} lg={12}>
                <Typography.Title level={5}>Поставщик</Typography.Title>

                <Form.Item name={'supplierId'}>
                  <FloatSelect placeholder={'Выберите поставщика'} options={options} showSearch={true} filterOption={filterOptionFn} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ marginLeft: 'auto' }}>
              <Button htmlType="submit" style={{ marginRight: 10 }}>
                Сохранить
              </Button>
              <Button htmlType="button" onClick={onCancel} danger>
                Отменить
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default LoadingRegisterSupplierForm;
