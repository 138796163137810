import { WagonAffiliationTypeModel, WagonTypeGroupModel, WagonTypeModel } from '@stores/optionsStore/optionsStoreData';

export const getWagonGroupCodeOptions = (wagonTypeGroupDictionary: WagonTypeGroupModel[]) =>
  wagonTypeGroupDictionary.map(({ groupName: label, groupCode: value }) => ({
    label,
    value,
  }));

export const getWagonTypeCodeOptions = (wagonTypesDictionary: WagonTypeModel[]) =>
  wagonTypesDictionary.map(({ wagonTypeName: label, wagonTypeCode: value }) => ({
    label,
    value,
  }));

export const getAffiliationCodeOptions = (wagonAffiliationDictionary: WagonAffiliationTypeModel[]) =>
  wagonAffiliationDictionary.map(({ affiliationName: label, affiliationCode: value }) => ({
    label,
    value,
  }));
