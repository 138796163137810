import { Button, Col, Form, Row, Select } from 'antd';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import WagonAdd from '@components/fw/Tracking/Wagon/WagonAdd';

import { SendLimitationsModel, SendSupplyScheduleModel } from '@stores/sendsStore/sendData';
import sendsStore from '@stores/sendsStore/sendsStore';

interface SendScheduleEditorProps {
  sendSchedule: SendSupplyScheduleModel;
  onCancel(): void;
  onConfirm(sendSchedule: any): void;
  sendId: string;
}
interface State {
  isBusy: boolean;
  wagons: number[];
  limitations: SendLimitationsModel;
}

class SendScheduleEditor extends Component<SendScheduleEditorProps, State> {
  constructor(props: SendScheduleEditorProps) {
    super(props);

    this.state = {
      isBusy: true,
      wagons: [],
      limitations: null,
    };
  }

  componentDidMount() {
    Promise.all([this.loadWagons(), this.getLimitation()]).finally(() => this.setState({ isBusy: false }));
  }

  async loadWagons() {
    await sendsStore.wagonsForScheduler().then((ans) => {
      this.setState({ wagons: ans });
    });
  }

  get scheduleId() {
    return this.props.sendSchedule ? this.props.sendSchedule.scheduleId : null;
  }

  async getLimitation() {
    await sendsStore.getLimitation(this.props.sendId, this.scheduleId).then((limitations) => {
      this.setState({ limitations: limitations });
    });
  }

  render() {
    const sendSchedule = this.props.sendSchedule;
    const limitations = this.state.limitations;
    const wagons = this.state.wagons;
    return (
      <AdaptiveDrawer
        width={600}
        title={this.isEditMode ? 'Редактирование графика подач' : 'Создание графика подач'}
        onClose={this.handelClose}
        visible={true}
        footer={[
          <Button onClick={this.handelClose} style={{ marginRight: 8 }}>
            Отмена
          </Button>,
          <Button type="primary" loading={this.state.isBusy} htmlType={'submit'} form={'sendSchedule-editor'}>
            {this.isEditMode ? 'Сохранить' : 'Добавить'}
          </Button>,
        ]}>
        <Form
          layout="vertical"
          name={'sendSchedule-editor'}
          initialValues={
            sendSchedule
              ? Object.assign({ ...sendSchedule }, { supplyDate: sendSchedule.supplyDate ? moment(sendSchedule.supplyDate) : null })
              : null
          }
          onFinish={this.handleConfirm}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24}>
              <Form.Item name="supplyDate" rules={[{ required: true, message: 'Необходимо заполнить время подачи' }]}>
                <FloatDatepicker
                  placeholder="Время подачи"
                  format={'DD.MM.YYYY'}
                  disabledDate={(current: Moment) => {
                    const from = limitations ? moment(limitations.validityFrom) : moment();
                    const to = limitations ? moment(limitations.validityTo) : moment();
                    return current && (current < from || current > to);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="freightWeight"
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (!limitations.freightWeight) {
                        return Promise.reject('Вес груза не указан в отправке');
                      }
                      if (value > limitations.freightWeight) {
                        return Promise.reject(`Не более ${limitations.freightWeight}`);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <FloatInputNumber step={0.001} min={0.001} decimalSeparator={'.'} placeholder={'Вес груза (т)'} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                extra={<WagonAdd size={'small'} onDataReady={() => this.componentDidMount()} />}
                name={'wagonNumbers'}
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (limitations.wagonCount === null) {
                        return Promise.reject('Количество вагонов недоступно. проверьте отправку');
                      }
                      if (!value || value.length == 0) {
                        return Promise.reject(`Выберите вагоны`);
                      }
                      if (value.length > limitations.wagonCount) {
                        return Promise.reject(`Не более ${limitations.wagonCount}`);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <FloatSelect
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Номера вагонов"
                  getPopupContainer={(trigger) => trigger.parentElement}>
                  {wagons.map((w) => {
                    return (
                      <Select.Option key={w} value={w}>
                        {w}
                      </Select.Option>
                    );
                  })}
                </FloatSelect>
              </Form.Item>
            </Col>
            {/*<Col xs={24}>
              <Form.Item
                name="commonTariff"
                label="Тариф"
                rules={[{ required: true, message: 'Необходимо заполнить поле ' }]}>
                <InputNumber step={0.1} min={0.1} parser={(value) => value.replace(',', '.').replace(/[^0-9.]/g, '')} />
              </Form.Item>
            </Col>*/}
          </Row>
        </Form>
      </AdaptiveDrawer>
    );
  }

  get isEditMode(): boolean {
    return !!this.scheduleId;
  }

  handelClose = () => {
    this.props.onCancel();
  };

  handleConfirm = (e) => {
    this.setState({
      isBusy: true,
    });
    const sendSchedule = {
      scheduleId: this.scheduleId,
      sendId: this.props.sendId,
      supplyDate: e.supplyDate,
      freightWeight: e.freightWeight,
      wagonCount: e.wagonNumbers.length,
      wagonNumbers: e.wagonNumbers,
      commonTariff: e.commonTariff,
    };

    const promise = this.isEditMode ? sendsStore.updateSchedule : sendsStore.createSchedule;

    promise(sendSchedule)
      .then((data) => {
        if (this.props.onConfirm) {
          this.props.onConfirm(data);
        }
      })
      .finally(() => this.setState({ isBusy: false }));
  };
}

export default SendScheduleEditor;
