import { DefaultOptionType } from 'antd/lib/select';
import React, { Component } from 'react';

import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import dictService from '@stores/dictStore/dictStore.service';

interface State {
  options: DefaultOptionType[];
  loading: boolean;
}

interface RpsModelSelectProps {
  rps: string;
  feature: string | null;
  disabled?: boolean;
  defaultValue?: string[];
  value?: string[];
  onChange?: (value: string[]) => void;
}

class RpsModelSelect extends Component<RpsModelSelectProps, State> {
  constructor(props: RpsModelSelectProps) {
    super(props);

    this.state = {
      options: undefined,
      loading: true,
    };
  }

  onChange = (value: string[]) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  loadData = () => {
    if (!this.props.feature || this.props.feature.length === 0) {
      this.setState({ loading: false });
      return;
    }

    dictService
      .getRpsWagonModels(this.props.rps, this.props.feature)
      .then((resp) => {
        this.setState({ options: resp.map((item: string) => ({ value: item, label: item })) });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<RpsModelSelectProps>): void {
    if (prevProps.feature !== this.props.feature || prevProps.rps !== this.props.rps) {
      this.loadData();
    }
  }

  render() {
    return (
      <FloatSelect
        disabled={this.props.disabled}
        mode="multiple"
        placeholder={'Модель вагона'}
        value={this.props.value}
        defaultValue={this.props.defaultValue}
        options={this.state.options}
        onChange={this.onChange}
        loading={this.state.loading}
      />
    );
  }
}
export default RpsModelSelect;
