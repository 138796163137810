import { InputNumber, InputNumberProps } from 'antd';
import React, { Component } from 'react';

import { InputPosition } from '@stores/designStore/designData';

import BorderWrapper from './BorderWrapper';
import styles from './FloatLabel.module.scss';

interface State {
  focus: boolean;
}

interface FloatInputNumberProps extends InputNumberProps {
  position?: InputPosition;
  placeholder: any;
  'data-test'?: string;
}

class FloatInputNumber extends Component<FloatInputNumberProps, State> {
  constructor(props: FloatInputNumberProps) {
    super(props);

    this.state = {
      focus: false,
    };
  }

  render() {
    const { value, placeholder, position } = this.props;
    const labelClass = this.state.focus || value || value === 0 ? styles.labelfloat : styles.label;
    const isStringLabel = typeof this.props.placeholder === 'string';

    return (
      <BorderWrapper
        disabled={this.props.disabled}
        className={styles.floatlabel}
        position={position}
        onFocusChange={(e) => this.setState({ focus: e })}>
        <InputNumber
          bordered={false}
          {...this.props}
          placeholder={null}
          className={styles.inputnumber}
          size={'large'}
          controls={false}
          data-test={this.props['data-test']}
        />
        <span style={{ pointerEvents: isStringLabel ? 'none' : null }} className={labelClass}>
          {placeholder}
        </span>
      </BorderWrapper>
    );
  }
}
export default FloatInputNumber;
