import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { GLOBAL_DATE_FORMAT } from '../optionsStore/optionsStoreData';
import service from './companiesStore.service';
import { CompanyModel } from './companyData';

class CompaniesStore {
  @observable companies: CompanyModel[] = [];

  @observable companiesShort: CompanyModel[] = [];

  @observable loadingCompanies: boolean = false;
  @observable loadingConfirm: boolean = false;

  @observable hasEtran: boolean = false;
  @observable hasTais: boolean = false;
  @observable hasRailwayInfo = false;
  @observable hasSigis = false;

  constructor() {
    makeObservable(this);
  }

  @action getCompanies() {
    this.loadingCompanies = true;
    service
      .getCompanies()
      .finally(() => (this.loadingCompanies = false))
      .then((result) => {
        result.forEach((e: any) => {
          e.serviceStr = e.services.map((x) => `№${x.offerNumber} ${moment(x.offerDate).format(GLOBAL_DATE_FORMAT)}`).join(`\n`);
        });
        this.companies = result;
      });
  }

  @action getCompaniesSort() {
    this.loadingCompanies = true;
    service
      .getCompaniesShort()
      .finally(() => (this.loadingCompanies = false))
      .then((result) => {
        this.companiesShort = result;
      });
  }

  @action create(company: CompanyModel) {
    this.loadingConfirm = true;
    return service
      .create(company)
      .finally(() => (this.loadingConfirm = false))
      .then(() => {
        this.getCompanies();
      });
  }

  @action update(company: CompanyModel) {
    this.loadingConfirm = true;
    return service
      .update(company)
      .finally(() => (this.loadingConfirm = false))
      .then(() => {
        this.getCompanies();
      });
  }

  @action remove(companyId: string) {
    this.loadingCompanies = true;
    return service
      .remove(companyId)
      .finally(() => (this.loadingCompanies = false))
      .then(() => {
        this.getCompanies();
      });
  }

  @action resetHasIntegrations() {
    this.hasEtran = false;
    this.hasTais = false;
    this.hasRailwayInfo = false;
    this.hasSigis = false;
  }

  @action async getById(companyId: string): Promise<CompanyModel> {
    return service.getCompany(companyId).then((ans) => {
      this.hasEtran = ans.etranLogin ? ans.etranLogin.isActive : false;
      this.hasTais = ans.taisLogin ? ans.taisLogin.isActive : false;
      this.hasRailwayInfo = ans.railwayLogin ? ans.railwayLogin.isActive : false;
      this.hasSigis = ans.sigisLogin ? ans.sigisLogin.isActive : false;
      return ans;
    });
  }
}

export default new CompaniesStore();
