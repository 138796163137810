import { FormInstance } from 'antd';

import { UserModel } from '@stores/usersStore/usersStore';

export type BasicTabBasicsFieldsProps = {
  users: UserModel[];
  form: FormInstance;
  isEdit: boolean;
};

export enum BidRegistryServiceType {
  wagons = 'wagons',
  expedition = 'expedition',
  wagons_expedition = 'wagons_expedition',
}

export enum BidRegistryKind {
  itinerary = 'itinerary',
  time = 'time',
}

export enum BidRegistryTeuType {
  ps = 'ps',
  ton = 'ton',
}
