import { LoadOptions } from 'devextreme/data/load_options';
import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment/moment';

import service from './transportRegisterStore.service';

class TransportRegisterStore {
  @observable private _isLoading: boolean = false;
  @observable private _reportRange: [Moment, Moment] = [moment().startOf('month'), moment().endOf('month')];
  @observable private _selectedItineraryIds: Array<string> = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async getReport(loadOptions: LoadOptions) {
    this._isLoading = true;
    try {
      return await service.getReport(this.isoReportRange[0], this.isoReportRange[1], loadOptions);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async excludeItineraries() {
    this._isLoading = true;
    try {
      await service.excludeItineraries(this.selectedItineraryIds);
      this.setSelectedItineraryIds([]);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get reportRange(): [Moment, Moment] {
    return this._reportRange;
  }

  @action
  setReportRange(range: [Moment, Moment]) {
    this._reportRange = range;
  }

  @computed
  get selectedItineraryIds(): Array<string> {
    return this._selectedItineraryIds;
  }

  @action
  setSelectedItineraryIds(ids: Array<string>) {
    this._selectedItineraryIds = ids;
  }

  get isoReportRange() {
    return this._reportRange.map((date) => date.toISOString());
  }
}
export const transportRegisterStore = new TransportRegisterStore();
