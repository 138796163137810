import { Form } from 'antd';
import React from 'react';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';

import { registerOfBetsStore } from '@stores/registerOfBetsStore';
import { BidRegistry } from '@stores/registerOfBetsStore/registerOfBetsStore.namespace';

export const PriceWithNds = ({ name }: { name: number }) => {
  return (
    <Form.Item noStyle dependencies={[['extraServices', name, 'price']]}>
      {({ getFieldValue }) => <FloatInputNumber value={getPrice(name, getFieldValue)} disabled={true} placeholder="Стоимость с НДС" />}
    </Form.Item>
  );
};

const getPrice = (name: number, getFieldValue: any) => {
  const price = getFieldValue(['extraServices', name, 'price']);
  const ndsRate = registerOfBetsStore.bidRegistry.ndsRate;

  return BidRegistry.calcPriceWithNds(ndsRate, price).toLocaleString('ru', {
    maximumFractionDigits: 2,
  });
};
