import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';

import { VersionContentInfo } from '@stores/systemInfoStore/systemInfoData';
import systemInfoStore from '@stores/systemInfoStore/systemInfoStore';
import userStore from '@stores/userStore/userStore';

interface VersionEditorProps {
  version?: VersionContentInfo;
}

interface VersionEditorState {
  isBusy: boolean;
  visible: boolean;
}

class VersionEditor extends Component<VersionEditorProps, VersionEditorState> {
  constructor(props: VersionEditorProps) {
    super(props);

    this.state = {
      isBusy: false,
      visible: false,
    };
  }

  formRef: FormInstance;
  render() {
    const isBusy = this.state.isBusy;
    const version = this.props.version;
    const visible = this.state.visible;
    const canEdit = userStore.userData.roleCode === 'admin';
    if (!canEdit) return null;
    return (
      <>
        {this.isEditMode ? (
          <Button onClick={this.openDrawer} icon={<EditOutlined />} title={'Изменить версию'} size={'small'} type={'text'}>
            Изменить
          </Button>
        ) : (
          <Button onClick={this.openDrawer} icon={<PlusOutlined />} title={'Добавить версию'} type={'text'} size={'small'}>
            Новая версия
          </Button>
        )}
        <AdaptiveDrawer
          width={600}
          title={this.isEditMode ? 'Редактирование версии' : 'Создание версии'}
          onClose={this.handelClose}
          visible={visible}
          footer={[
            <Button onClick={this.handelClose} style={{ marginRight: 8 }} key={'1'}>
              Отмена
            </Button>,
            <Button type="primary" loading={isBusy} htmlType={'submit'} form={'version-editor'} key={'2'}>
              {this.isEditMode ? 'Сохранить' : 'Добавить'}
            </Button>,
          ]}>
          <Form
            layout="vertical"
            name={'version-editor'}
            ref={(ref) => (this.formRef = ref)}
            onFinish={(e) => this.handleConfirm(e)}
            initialValues={{
              version: version ? version.version : null,
              updateDate: version && version.updateDate ? moment(version.updateDate) : moment(),
              content: version ? version.content : null,
            }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24}>
                <Form.Item
                  name={'version'}
                  rules={[
                    {
                      required: true,
                      message: 'Введите номер версии',
                    },
                  ]}>
                  <FloatInput maxLength={10} disabled={this.isEditMode} placeholder={'Номер версии'} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="updateDate"
                  rules={[
                    {
                      required: true,
                      message: 'Необходимо заполнить дату создания',
                    },
                  ]}>
                  <FloatDatepicker format={'DD.MM.YYYY'} placeholder="Дата создания" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="content"
                  label="Описание"
                  rules={[
                    {
                      required: true,
                      message: 'Необходимо описание',
                    },
                  ]}>
                  <Input.TextArea rows={20} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </AdaptiveDrawer>
      </>
    );
  }

  get isEditMode(): boolean {
    return this.props.version && this.props.version.version !== null;
  }

  openDrawer = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: true });
  };

  handelClose = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: false });
  };

  async handleConfirm(e) {
    this.setState({ isBusy: true });
    try {
      if (this.isEditMode) await systemInfoStore.update(e);
      else await systemInfoStore.add(e);
      this.handelClose();
      systemInfoStore.getVersionList();
      systemInfoStore.getCurrentVersion();
    } catch (e) {
    } finally {
      this.setState({ isBusy: false });
    }
  }
}

export default VersionEditor;
