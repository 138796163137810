import { makeObservable, observable } from 'mobx';

import Wagon from '../trackingStore/wagon/WagonTypes';
import service from './equipmentStore.service';

class EquipmentStore {
  loadOptions: any = null;

  @observable filteredWagons: Wagon[] = [];
  @observable numbersFilterModalVisible: boolean = false;

  constructor() {
    makeObservable(this);
  }

  async load(loadOptions: any) {
    if (this.filteredWagons.length) {
      let wagonFilter = ['eq.wagon_id', 'in', '(' + this.filteredWagons.map((w) => w.number).join(',') + ')'];
      if (loadOptions.filter) {
        loadOptions.filter = [wagonFilter, 'and', loadOptions.filter];
      } else {
        loadOptions.filter = wagonFilter;
      }
    }
    this.loadOptions = loadOptions;
    return await service.getDynamicData(loadOptions);
  }
}

export default new EquipmentStore();
