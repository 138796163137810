import { ColumnsType } from 'antd/es/table';

import { renderCurrency } from '@components/routed/RegisterOfBetsCreatePage/ui/tariff-table/lib/columns/columns.ui';

export const COLUMNS: ColumnsType<any> = [
  {
    dataIndex: 'tariffGuidName',
    key: 'tariffGuidName',
    title: 'Политика',
  },
  {
    dataIndex: 'countryName',
    key: 'countryName',
    title: 'Страна',
  },
  {
    dataIndex: 'transportationTypeName',
    key: 'transportationTypeName',
    title: 'Тип',
  },
  {
    dataIndex: 'tariffDistance',
    key: 'tariffDistance',
    title: 'КМ',
  },
  {
    dataIndex: 'tariffCostTk',
    key: 'tariffCostTk',
    title: 'Тариф',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    dataIndex: 'tariffUnitName',
    key: 'tariffUnitName',
    title: 'Ед.',
  },
  {
    dataIndex: 'tariffChargeTk',
    key: 'tariffChargeTk',
    title: 'Плата-тариф',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    dataIndex: 'chargePerTonTk',
    key: 'chargePerTonTk',
    title: 'За тонну',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    dataIndex: 'wagonTypeChargeTk',
    key: 'wagonTypeChargeTk',
    title: 'За п/с',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    dataIndex: 'feeChargeTk',
    key: 'feeChargeTk',
    title: 'Плата-сборы',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    dataIndex: 'expeditorAmountTk',
    key: 'expeditorAmountTk',
    title: 'Эксп',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    dataIndex: 'expeditorNdsAmountTk',
    key: 'expeditorNdsAmountTk',
    title: 'Эксп. НДС',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    dataIndex: 'freightChargeTk',
    key: 'freightChargeTk',
    title: 'Провозная плата',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    dataIndex: 'freightChargeTkVat',
    key: 'freightChargeTkVat',
    title: 'НДС',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    dataIndex: 'freightChargeTkWithVat',
    key: 'freightChargeTkWithVat',
    title: 'с НДС',
    render: (item) =>
      item?.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    title: 'Валюта',
    key: 'currency',
    align: 'center',
    width: 30,
    render: renderCurrency,
  },
];
