import { Checkbox, Spin } from 'antd';
import React, { Component } from 'react';

import statisticStore from '@stores/statisticStore/statisticStore';

interface Props {
  dxCell: any;
  onChange?(): void;
}

interface State {
  value: boolean;
  isBusy: boolean;
}

class StatisticExceptDxCell extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: this.props.dxCell.value,
      isBusy: false,
    };
  }

  render() {
    if (this.state.isBusy) return <Spin size={'small'} />;
    else return <Checkbox checked={this.state.value} onChange={() => this.handleChange()} />;
  }

  async handleChange() {
    this.setState({ isBusy: true });
    try {
      await statisticStore.setExcept(this.props.dxCell.data.number, !this.state.value);
      this.setState({ value: !this.state.value });
      this.props.onChange();
    } catch (e) {
    } finally {
      this.setState({ isBusy: false });
    }
  }
}

export default StatisticExceptDxCell;
