import { Button, Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import { FieldFloatInput } from '@components/fw/Fields/float-input';
import { FieldFloatInputNumber } from '@components/fw/Fields/float-input-number';
import { FieldFloatSelect } from '@components/fw/Fields/float-select';
import { Flex } from '@components/fw/Flex';

import companyStore from '@stores/companyStore/companyStore';
import dictStore from '@stores/dictStore/dictStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';
import { BidRegistry } from '@stores/registerOfBetsStore/registerOfBetsStore.namespace';

import { PriceNds } from './ui/price-nds';
import { PriceWithNds } from './ui/price-with-nds';

export const ExtraServices = observer(() => {
  const { extraServicesDictionary } = dictStore;

  return (
    <AdaptiveCard key="extra-services" hoverable>
      <Flex vertical gap={12}>
        <Form.List name="extraServices">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name }) => {
                  return (
                    <Flex gap={20} align="baseline" key={key}>
                      <FieldFloatSelect
                        randomId={false}
                        placeholder="Тип услуги"
                        name={[name, 'serviceId']}
                        options={getOptions(extraServicesDictionary)}
                        rules={[{ required: true, message: 'Пожалуйста, выберите тип' }]}
                      />

                      {/*@ts-ignore*/}
                      <FieldFloatInputNumber min={0} max={999999999} step={0.01} placeholder="Стоимость без НДС" name={[name, 'price']} />
                      <FieldFloatInput
                        disabled={true}
                        value={BidRegistry.getNdsLabel(registerOfBetsStore.bidRegistry.ndsRate)}
                        placeholder={'Тип НДС'}
                      />
                      <PriceNds name={name} />
                      <PriceWithNds name={name} />
                      <Button onClick={() => remove(name)}>Удалить</Button>
                    </Flex>
                  );
                })}
                <Button onClick={() => add()}>Добавить услугу</Button>
              </>
            );
          }}
        </Form.List>
      </Flex>
    </AdaptiveCard>
  );
});

const getOptions = (dict) =>
  dict.map((service) => ({
    value: service.serviceId,
    key: service.serviceId,
    label: service.name,
  }));
