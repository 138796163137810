import Icon from '@ant-design/icons';
import { DatePicker } from 'antd';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { Moment } from 'moment';
import React, { Component } from 'react';

import { ReactComponent as iconCalendar } from '@assets/icons/icon-calendar.svg';

import designStore from '@stores/designStore';
import { InputPosition } from '@stores/designStore/designData';

import BorderWrapper from './BorderWrapper';
import styles from './FloatLabel.module.scss';

interface State {
  focus: boolean;
}

interface FloatDatepicketProps {
  position?: InputPosition;
  placeholder?: any;
}

class FloatDatepicker extends Component<PickerProps<Moment> & FloatDatepicketProps, State> {
  constructor(props: PickerProps<Moment> & { position?: InputPosition }) {
    super(props);

    this.state = {
      focus: false,
    };
  }

  render() {
    const { value, placeholder, position } = this.props;
    const labelClass = this.state.focus || value ? styles.labelfloat : styles.label;

    return (
      <BorderWrapper
        disabled={this.props.disabled}
        className={styles.floatlabel}
        position={position}
        onFocusChange={(e) => this.setState({ focus: e })}>
        <DatePicker
          {...this.props}
          inputReadOnly={designStore.isMobile}
          bordered={false}
          placeholder={null}
          className={styles.picker}
          getPopupContainer={(trigger) => trigger.parentElement}
          suffixIcon={<Icon component={iconCalendar} />}
        />
        <span className={labelClass}>{placeholder}</span>
      </BorderWrapper>
    );
  }
}
export default FloatDatepicker;
