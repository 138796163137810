import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { CURRENCY_FIELDS, Currency } from '../sendsStore/sendData';
import service from './tariffFgkBaseStore.service';
import tariffFgkBaseStoreService from './tariffFgkBaseStore.service';

export interface TariffFgkHistoryModel {
  request: TariffFgkRequestModel;
  response: TariffFgkBaseResponseModel;
}

export interface TariffFgkRequestModel {
  /*Согласие с условиями (для успешного результата – "true") */
  rateAddWarning: boolean;
  /*Груз (код ЕТСНГ 6 знаков) */
  rateCargoCargo: number | string;
  /*
   * Ожидаемый объем вагоноотправок, ваг.
   * Целое число, по умолчанию – "1" (вагон).
   */
  rateCargoCntWag: number;
  /*
   * Вид отправки "0" – 1
   * "1" – 2
   * "2" – 3-5
   * "3" – 6-20
   * "4" – 21 и более
   * "20" – отправительский маршрут "21" – любая
   */
  rateCargoKindOtp: number;
  /*Тип груза "1" – груз */
  rateCargoType: number;
  /*Код валюты в соответствии с общероссийским классификатором валют */
  rateHeadCurrencyCode: number;
  /*Дата окончания. Формат "yyyy-mm- dd". Текущая дата. */
  rateHeadFinDate: Date | string | any;
  /*Род подвижного состава. "8" – полувагоны */
  rateHeadProp: number;
  /*Дата начала. Формат yyyy-mm-dd. Текущая дата. */
  rateHeadStartDate: Date | string | any;
  /*
   * Тип расчета
   * "-2" – абсолютное значение
   */
  rateHeadType: number;
  /*Единица измерения "1" – вагон */
  rateHeadUnit: number;
  /*
   * Использование паромной переправы в груженом рейсе
   * "0" – да
   * "1" – нет
   */
  rateNoFerry: number;
  /*Оплата порожнего рейса "-1" – за счёт АО «ФГК» */
  rateRoutePayerEmpty: number;
  /*Станция отправления (код ЕСР 6 знаков) */
  rateRouteStFromName: number;

  /*Станция назначения (код ЕСР 6 знаков) */
  rateRouteStToName: number;
  /*
   * (Только для rate__head__prop : "8") Инновационный вагон
   * true – да
   * false – нет
   */
  rateTechInnovation: boolean;

  rateHeadCurrencyObj?: Currency;
}

export interface TariffFgkBaseResponseModel {
  id: string;
  rateCargoZagruzka: string;
  rateComment: string;
  rateCommentEng: string;
  rateHeadId: string;
  rateResultDiscountMargin: string;
  rateResultDist: number;
  rateResultRate: number;
  rateResultRequestDiscountMargin: string;
  rateResultSoob: number;
  rateResultZStavka: number;
  rateStminv: number;
  rateStmsobst: number;
  type: string;
  message: string;
}

export let defaultTariffFgkBaseRequest: TariffFgkRequestModel;
defaultTariffFgkBaseRequest = {
  rateAddWarning: true,
  rateCargoCargo: null,
  rateCargoCntWag: 1,
  rateCargoKindOtp: 21,
  rateCargoType: 1,
  rateHeadCurrencyCode: 643,
  rateHeadFinDate: null,
  rateHeadProp: 8,
  rateHeadStartDate: null,
  rateHeadType: -2,
  rateHeadUnit: 1,
  rateNoFerry: 1,
  rateRoutePayerEmpty: -1,
  rateRouteStFromName: null,
  rateRouteStToName: null,
  rateTechInnovation: true,
  rateHeadCurrencyObj: CURRENCY_FIELDS[4],
};

/*"rateAddWarning": true,
  "rateCargoCargo": 242024,
  "rateCargoCntWag": 1,
  "rateCargoKindOtp": 21,
  "rateCargoType": 1,
  "rateHeadContractnum": 2310168,
  "rateHeadCurrencyCode": 392,
  "rateHeadFinDate": "2020-06-10",
  "rateHeadProp": 8,
  "rateHeadStartDate": "2020-06-10",
  "rateHeadType": -2,
  "rateHeadUnit": 1,
  "rateRoutePayerEmpty": -1,
  "rateRouteStFromName": 596506,
  "rateRouteStToName": 982102,
  "rateTechInnovation": true*/

class TariffFgkBaseStore {
  @observable isBusy: boolean = false;
  @observable data: TariffFgkBaseResponseModel;
  @observable request: TariffFgkRequestModel = defaultTariffFgkBaseRequest;
  @observable selectedData: any[] = [];
  @observable selectedCurrency: Currency = CURRENCY_FIELDS[4];

  @observable userRequestHistory: DataSource = new DataSource({
    store: new CustomStore({
      key: 'requestid',
      load: function (loadOptions: any) {
        return tariffFgkBaseStoreService
          .historyData(loadOptions)
          .then((data) => {
            data.data.forEach((d) => {
              if (d.items && d.items.length) {
                d.count = d.items.length;
              }
            });
            return data;
          })
          .catch(() => {
            throw 'Data Loading Error';
          });
      },
      remove: (key) => {
        return Promise.any(null);
      },
      update: (key, values) => {
        return Promise.all([]);
      },
    }),
  });

  constructor() {
    makeObservable(this);
  }

  @action
  setSelected(selected: any[]) {
    this.selectedData = selected;
  }

  @action async onHistory(requestId: string) {
    return service.requestData(requestId).then((ans) => {
      const request = ans.request;
      if (request) {
        if (request.rateHeadStartDate) request.rateHeadStartDate = moment(request.rateHeadStartDate);
        if (request.rateHeadFinDate) request.rateHeadFinDate = moment(request.rateHeadFinDate);
        if (request.rateCargoCargo) request.rateCargoCargo = request.rateCargoCargo.toString();
        if (request.rateHeadCurrencyCode) {
          request.rateHeadCurrencyObj = CURRENCY_FIELDS.find((c) => c.filedPostfixCode === request.rateHeadCurrencyCode.toString());
        }
        if (!request.rateHeadCurrencyObj) {
          request.rateHeadCurrencyObj = CURRENCY_FIELDS[4];
        }
        this.request = request;
      }
      return ans;
    });
  }

  @action
  reset() {
    this.request = defaultTariffFgkBaseRequest;
    this.selectedCurrency = CURRENCY_FIELDS[4];
  }

  @action async calculate(request: TariffFgkRequestModel) {
    this.request = request;
    return await service.calculate(request);
  }
}

export default new TariffFgkBaseStore();
