import { Spin } from 'antd';
import React, { Component } from 'react';

import claimsStore from '@stores/claimsStore/claimsStore';

import SendBoardDetail from './SendBoardDetail';

interface SendsBoardDetailProps {
  data: any;
  index?: any;
  onRowDblClick?(item: any): void;
}

class SendsBoardDetail extends Component<SendsBoardDetailProps> {
  _isMounted = false;
  state = {
    rows: [],
    isBusy: true,
  };

  componentDidMount(): void {
    this._isMounted = true;
    const claimid = !!this.props.data.data ? this.props.data.data.claimid : this.props.data;

    claimsStore
      .getClaimSends(claimid)
      .finally(() => this.setState({ isBusy: false }))
      .then((result) => {
        if (this._isMounted) {
          this.setState({ rows: result });
        }
      });
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  render() {
    const isBusy = this.state.isBusy;
    const sends = this.state.rows;

    return (
      <Spin spinning={isBusy}>
        {sends.map((send) => (
          <SendBoardDetail send={send} onChange={() => this.componentDidMount()} />
        ))}
      </Spin>
    );
  }

  handleRowDoubleClick = ({ data }: any) => {
    this.props.onRowDblClick(data);
  };
}

export default SendsBoardDetail;
