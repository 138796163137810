import { OrganizationRequisites } from '../organizationsStore/organizationsStoreData';

export interface ServiceStrategyModel {
  code: string;
  name: string;
}

export interface CompanyServiceModel {
  id: string;
  name: string;
  strategyCode: string;
  strategyName: string;
}

export interface CompanyServiceSetModel {
  id: string;
  companyId: string;
  supplierId: string;
  supplierName?: string;
  services: CompanyServiceSetReferenceModel[];
  offerDate: Date | string | any;
  offerNumber: string;
  serviceSetContract: TemplateServiceSetContractModel;
}

export interface TemplateServiceSetContractModel {
  templateMetadataId?: string;
  serviceSetId?: string;
  beginDate: any;
  endDate: any;
  statusId?: string;
  createDate?: any;
  supplierDocTypeId: string;
  supplierDocTypeName?: string;
  name: string;
  address: string;
  contractNumber: string;
  foreignId: string;
}

export interface CompanyServiceSetReferenceModel {
  serviceId: string;
  serviceName?: string;
  price: number;
  strategyCode: string;
  extContract: TemplateServiceSetExtModel;
  id?: string;
}

export interface TemplateServiceSetExtModel extends TemplateServiceSetContractModel {
  serviceSetReferenceId?: string;
  contractId?: string;
  contractName?: string;
  extContractNumber: string;
}

export interface SupplierModel {
  supplierId: string;
  name: string;
  docPrefix: string;
}
export interface SupplierRequisitesModel extends OrganizationRequisites, SupplierModel {}

export const newSupplierRequisitesModel: SupplierRequisitesModel = {
  supplierId: null,
  name: null,
  inn: null,
  kpp: null,
  okpo: null,
  ogrn: null,
  shortNameReq: null,
  fullNameReq: null,
  address: null,
  telephone: null,
  email: null,
  owner: null,
  okfs: null,
  okogu: null,
  okopf: null,
  oktmo: null,
  okato: null,
  country: null,
  addressMail: null,
  addressFact: null,
  view: 'ul',
  group: null,
  bankBik: null,
  bankName: null,
  bankAddress: null,
  bankCorBill: null,
  orgBill: null,
  signer: null,
  post: null,
  reason: null,
  attorney: null,
  glbuh: null,
  docPrefix: null,
};
