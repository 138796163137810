import { Collapse } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { DirectionsTable } from '@components/routed/RegisterOfBetsCreatePage/ui/directions-table';

import styles from './wagon-services.module.scss';

export const WagonsService = observer(() => {
  return (
    <Collapse defaultActiveKey={['owner']}>
      <Collapse.Panel className={styles.infologCollapse} key="owner" header="Ставка предоставления ПС">
        <DirectionsTable />
      </Collapse.Panel>
    </Collapse>
  );
});
