import { renderPriceWithNDS } from '@components/routed/RegisterOfBetsCreatePage/ui/directions-table/lib/columns/columns.ui';

import companyStore from '@stores/companyStore/companyStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';
import { BidRegistry } from '@stores/registerOfBetsStore/registerOfBetsStore.namespace';

export const COLUMNS = [
  {
    title: 'Маршрут',
    dataIndex: 'bidRegistryId',
    key: 'bidRegistryId',
    render: (_, { loadStationName, destStationName }) => {
      return `${loadStationName}-${destStationName}`;
    },
  },
  {
    title: 'Ставка без НДС',
    dataIndex: 'priceWithoutNds',
    key: 'priceWithoutNds',
    width: 130,
  },
  {
    title: 'НДС',
    key: 'nds',
    render: () => BidRegistry.getNdsLabel(registerOfBetsStore.bidRegistry.ndsRate),
    width: 120,
  },
  {
    title: 'Ставка с НДС',
    key: 'priceWithNds',
    render: renderPriceWithNDS,
    width: 130,
  },
];
