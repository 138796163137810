import { Button } from 'antd';
import React, { Component } from 'react';

import PdfViewModal from '@components/fw/DocumentViewer/PdfViewModal';

import organizationsStoreService from '@stores/organizationsStore/organizationsStore.service';

interface ComponentProps {
  id: string;
  type: string;
}

interface ComponentState {
  loading: boolean;
  data: Blob;
}

class ContractDocViewer extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      loading: false,
      data: null,
    };
  }

  onDownload = (e) => {
    e.stopPropagation();
    this.setState({ loading: true });
    organizationsStoreService
      .contractGetFile(this.props.id, this.props.type)
      .then((doc) => {
        if (doc && doc.size) {
          this.setState({ data: doc });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <>
        <PdfViewModal data={this.state.data} onClose={() => this.setState({ data: null })} visible={!!this.state.data} />
        <Button size={'small'} type={'link'} title={'Открыть'} loading={this.state.loading} onClick={this.onDownload}>
          <span>{this.props.children}</span>
        </Button>
      </>
    );
  }
}

export default ContractDocViewer;
