import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { RentDocumentServiceModel } from '@stores/leasingStore/LeasingData';
import leasingStoreService from '@stores/leasingStore/leasingStore.service';
import { TemplateOrgContractModel } from '@stores/organizationsStore/organizationsStoreData';
import userStore from '@stores/userStore/userStore';

import { showSuccessNotify } from '@utils/notify';

import LasingDetailActions from './LasingDetailActions';
import LasingTransferActModal from './LasingTransferActModal';
import LeasingCompleteWorksDrawer from './LeasingCompleteWorksDrawer';

interface State {
  isBusy: boolean;
  showTransferActAdd: boolean;
  showCompleteWorksAdd: boolean;
  dataSource: RentDocumentServiceModel[];
  contract: TemplateOrgContractModel;
}

@observer
class LeasingDetailPage extends Component<RouteComponentProps, State> {
  dataGrid: DataGrid;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      contract: null,
      showCompleteWorksAdd: false,
      showTransferActAdd: false,
      dataSource: [],
    };
  }

  get id(): string {
    const { params } = this.props.match;
    return params['id'];
  }

  componentDidMount() {
    document.title = 'Договор аренды';
    this.loadData();
  }

  loadData = () => {
    if (this.id) {
      this.setState({ isBusy: true });
      leasingStoreService
        .getContractRentDocs(this.id)
        .then((ans) => {
          this.setState({ dataSource: ans });
        })
        .finally(() => this.setState({ isBusy: false }));
      /*organizationsStoreService
        .getOneContract(this.id, 'contract')
        .then((ans) => {
          this.setState({ contract: ans });
        })
        .finally(() => this.setState({ isBusy: false }));*/
    }
  };

  createComplektation = () => {
    showSuccessNotify('Документ сформирован');
  };

  render() {
    const dataSource = this.state.dataSource;

    return (
      <>
        <AdaptivePageHeader
          title={'Договор аренды'}
          style={{ background: 'white' }}
          extra={[
            <Dropdown
              key={'dropdown'}
              overlay={
                <Menu>
                  {/* <Menu.Item
                    key="1"
                    onClick={() => {
                      this.createComplektation();
                    }}>
                    Лист комплектации
                  </Menu.Item>
                  <Menu.Item
                    key="3"
                    onClick={() => {
                      this.setState({ showTransferActAdd: true });
                    }}>
                    Aкт приема-передачи
                  </Menu.Item>*/}
                  <Menu.Item
                    key={'4'}
                    onClick={() => {
                      this.setState({ showCompleteWorksAdd: true });
                    }}>
                    Акт выполненных работ
                  </Menu.Item>
                </Menu>
              }>
              <Button type={'text'}>
                Сформировать <DownOutlined />
              </Button>
            </Dropdown>,
          ]}
        />
        {this.state.showTransferActAdd && (
          <LasingTransferActModal
            id={this.id}
            onChange={() => {
              this.setState({ showTransferActAdd: false });
              this.loadData();
            }}
            onClose={() => this.setState({ showTransferActAdd: false })}
          />
        )}
        {this.state.showCompleteWorksAdd && (
          <LeasingCompleteWorksDrawer
            contractId={this.id}
            onClose={() => this.setState({ showCompleteWorksAdd: false })}
            onChange={() => {
              this.setState({ showCompleteWorksAdd: false });
              this.loadData();
            }}
          />
        )}
        <Layout.Content style={{ height: 'calc(100vh - var(--header-height))' }}>
          <AdaptiveCard>
            <DataGrid
              allowColumnReordering={true}
              ref={(ref) => (this.dataGrid = ref)}
              dataSource={dataSource}
              remoteOperations={false}
              //loadPanel={{ enabled: false }}
              allowColumnResizing={true}
              showBorders={true}
              height={'calc(100vh - var(--padding))'}
              columnResizingMode={'widget'}
              columnAutoWidth={true}
              showColumnLines={true}
              columnMinWidth={30}
              showRowLines={true}
              rowAlternationEnabled={true}
              selection={{ mode: 'single' }}>
              <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'leasingDetailPage'} />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <GroupPanel visible={true} />
              <Grouping autoExpandAll={false} />
              <Paging enabled={true} defaultPageSize={25} />
              <Pager
                allowedPageSizes={[25, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
                infoText={'Всего: {2}'}
              />
              {/*<Editing allowDeleting={true} mode="row" texts={{ deleteRow: 'Удалить' }} />*/}
              {INDEX_COLUMN}
              <Column
                allowFixing={true}
                alignment={'center'}
                dataField="id"
                caption={'Действия'}
                allowGrouping={false}
                allowHeaderFiltering={false}
                allowSearch={false}
                allowSorting={false}
                allowFiltering={false}
                allowExporting={false}
                cellRender={(e) => {
                  return <LasingDetailActions data={e.data} onChange={this.loadData} contractId={this.id} />;
                }}
              />
              <Column allowFixing={true} dataField={'docType'} caption={'Тип документа'} dataType={'string'} />
              <Column allowFixing={true} dataField={'name'} caption={'Наименование документа'} dataType={'string'} />
              <Column allowFixing={true} dataField={'date'} caption={'Дата'} dataType={'date'} />
              <Column allowFixing={true} dataField={'number'} caption={'Номер документа'} />
              <Column allowFixing={true} dataField={'statusName'} caption={'Статус'} />
              <Column allowFixing={true} dataField={'changeStationName'} caption={'Станция'} />
              <Column allowFixing={true} dataField={'wagonsCount'} caption={'Вагонов'} dataType={'number'} />
            </DataGrid>
          </AdaptiveCard>
        </Layout.Content>
      </>
    );
  }
}

export default LeasingDetailPage;
