import HttpClient from '@utils/httpClient';

import { StatisticCompanyTrackingInfo, StatisticDocInfo, StatisticExceptionInfo } from './statisticData';

const service = {
  async getCompanyData(month: number, year: number, type: 0 | 1 | 2): Promise<StatisticCompanyTrackingInfo[]> {
    return (await HttpClient.get(`/api/statistic/company`, { params: { type, month, year } })).data;
  },

  async getCustomData(begin: string, end: string, type: 0 | 1 | 2, companyId: string): Promise<StatisticCompanyTrackingInfo[]> {
    return (await HttpClient.get(`/api/statistic/custom`, { params: { type, begin, end, companyId } })).data;
  },

  async getAdminData(month: number, year: number, type: 0 | 1 | 2, companyId: string): Promise<StatisticCompanyTrackingInfo[]> {
    return (await HttpClient.get(`/api/statistic/admin`, { params: { type, month, year, companyId } })).data;
  },

  async setException(type: 0 | 1 | 2 | 3, data: StatisticExceptionInfo) {
    let typeStr = '';
    switch (Number(type)) {
      case 0:
        typeStr = 'wagon';
        break;
      case 1:
        typeStr = 'container';
        break;
      case 2:
        typeStr = 'request';
        break;
    }
    return (await HttpClient.put(`/api/statistic/admin/${typeStr}/exception`, data)).data;
  },

  async createDocs(data: { companyId: string; date: any; begin: any; end: any }) {
    return (await HttpClient.post(`/api/document/billing/create`, data)).data;
  },

  async getDocsData(data: string[]): Promise<StatisticDocInfo[]> {
    return (await HttpClient.post(`/api/document/data`, data)).data;
  },

  async getDocBody(id: string, progressFunc: ((e: ProgressEvent) => void) | undefined = undefined): Promise<Blob> {
    return (
      await HttpClient.get(`/api/document/download/${id}`, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
      })
    ).data;
  },
};

export default service;
