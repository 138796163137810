import { Drawer, List, Modal, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import DocumentTemplateRowView from '@components/fw/DocumentTemplateEditor/DocumentTemplateRowView';

import { DocTypeContext, DocTypeTemplateModel } from '@stores/contractTemplateStore/ContractTemplatesData';
import contractTemplateStore from '@stores/contractTemplateStore/contractTemplateStore';

import classes from './styles.module.scss';

@observer
export class ContractTemplatesDrawer extends Component<ContractTemplatesDrawerProps, ContractTemplatesDrawerState> {
  constructor(props: ContractTemplatesDrawerProps) {
    super(props);

    this.state = {
      isOpenTemplateModal: false,
      selectedTemplate: null,
    };
  }

  openTemplateModal = (template: DocTypeTemplateModel) => {
    this.setState({ isOpenTemplateModal: true, selectedTemplate: template });
  };

  closeTemplateModal = () => {
    this.setState({ isOpenTemplateModal: false });
  };

  selectTemplate = () => {
    this.setState({ isOpenTemplateModal: false }, () => {
      this.props.onSelect(this.state.selectedTemplate);
      this.props.onClose();
    });
  };

  componentDidUpdate(prevProps: Readonly<ContractTemplatesDrawerProps>, prevState: Readonly<ContractTemplatesDrawerState>, snapshot?: any) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      contractTemplateStore.loadTemplates(this.props.context);
    }
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { isOpenTemplateModal, selectedTemplate } = this.state;
    const preview = selectedTemplate
      ? {
          nestedElements: selectedTemplate.data,
          relativePath: '',
          value: selectedTemplate.name,
          elementType: 'title',
        }
      : null;

    const renderedTemplate = (template: DocTypeTemplateModel) => {
      const onClick = () => this.openTemplateModal(template);

      return (
        <List.Item className={classes.listItem} key={template.docTemplateId} onClick={onClick}>
          {template.name}
        </List.Item>
      );
    };

    return (
      <Drawer
        className={classes.content}
        bodyStyle={{ padding: 0 }}
        title={'Шаблоны договоров'}
        destroyOnClose={true}
        open={isOpen}
        onClose={onClose}>
        <Spin spinning={contractTemplateStore.isBusyTemplates}>
          <List bordered={false} dataSource={contractTemplateStore.templates} renderItem={renderedTemplate} />
        </Spin>
        <Modal
          width={1000}
          centered={true}
          destroyOnClose={true}
          open={isOpenTemplateModal}
          bodyStyle={{ maxHeight: '80vh', overflow: 'auto' }}
          okText={'Выбрать'}
          onOk={this.selectTemplate}
          onCancel={this.closeTemplateModal}>
          <DocumentTemplateRowView selected={''} data={preview} />
        </Modal>
      </Drawer>
    );
  }
}

type ContractTemplatesDrawerProps = {
  context: DocTypeContext;
  isOpen: boolean;
  onSelect(template: DocTypeTemplateModel): void;
  onClose(): void;
};

type ContractTemplatesDrawerState = {
  isOpenTemplateModal: boolean;
  selectedTemplate: DocTypeTemplateModel | null;
};
