import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import CalendarCustom from '@components/fw/Calendar/CalendarCustom';

const CREATE_LINK = '/list-of-rates/create';

export const registerOfBetsHeaderExtra = [
  <Link to={CREATE_LINK} key={'create'}>
    <Button icon={<PlusOutlined />} size={'small'} type={'link'}>
      Создать
    </Button>
  </Link>,
];
