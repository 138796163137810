import { Tabs } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptiveHeaderTabs from '@components/fw/AdaptiveElements/AdaptiveHeaderTabs';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import Notifications from '@components/fw/Drawers/CompanyEditor/CompanyNotifications/Notifications';

import userStore from '@stores/userStore/userStore';

import NotificationsViewer from './NotificationsViewer';

interface NotificationsPageState {
  activeTab: string | null;
}

class NotificationsPage extends Component<RouteComponentProps, NotificationsPageState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      activeTab: 'assist',
    };
  }

  render() {
    const activeTab = this.state.activeTab;
    return (
      <>
        <AdaptivePageHeader
          subTitle={
            <AdaptiveHeaderTabs onChange={(e) => this.setState({ activeTab: e })}>
              <Tabs.TabPane tab={'Уведомления'} key={'assist'} />
              <Tabs.TabPane tab={'Настройка'} key={'settings'} />
            </AdaptiveHeaderTabs>
          }
        />
        {activeTab == 'assist' ? (
          <AdaptiveCard>
            <NotificationsViewer />
          </AdaptiveCard>
        ) : null}
        {activeTab == 'settings' ? (
          <AdaptiveCard>
            <Notifications companyId={userStore.userData.companyId} />
          </AdaptiveCard>
        ) : null}
      </>
    );
  }
}

export default NotificationsPage;
