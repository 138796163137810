import DataSource from 'devextreme/data/data_source';

import HttpClient from '@utils/httpClient';

import { EtranLastFreightModel } from '../claimsStore/ClaimData';
import { CompanyEtranLoginModel } from '../companiesStore/companyData';
import {
  EtranClaimInfo,
  EtranInvoiceInfo,
  EtranInvoiceStatusHistory,
  EtranOperationSuccessInfo,
  EtranOrganizationPassportInfo,
  EtranOrganizationPayerModel,
  EtranTelegramInfo,
} from './EtranData';
import {
  EtranCarDictionaryModel,
  EtranCountryModel,
  EtranDictionaryInfo,
  EtranFasteningModel,
  EtranStationDictionaryInfo,
  EtsngFreightServiceModel,
  GngFreightServiceModel,
} from './EtranDictData';

const etranService = {
  async getEtranDictionary(): Promise<EtranDictionaryInfo> {
    return (await HttpClient.get(`/api/etrandictionary/dictionary`)).data;
  },

  /// Гармонизированная номенклатура грузов (8-значные грузы ГНГ)
  async getEtranEtsng(filter: string, cancelToken?): Promise<EtsngFreightServiceModel[]> {
    return (await HttpClient.get(`/api/etrandictionary/freight/etsng`, { params: { filter }, cancelToken: cancelToken })).data;
  },

  /// Гармонизированная номенклатура грузов (8-значные грузы ГНГ)
  async getEtranGng(filter: string, cancelToken?): Promise<GngFreightServiceModel[]> {
    return (await HttpClient.get(`/api/etrandictionary/freight/gng`, { params: { filter }, cancelToken: cancelToken })).data;
  },

  async getEtranfilterdDictionary(filter: string): Promise<any[]> {
    return (await HttpClient.get(`/api/etrandictionary/dictionary`, { params: { filter } })).data;
  },

  async pingEtran(loginModel: CompanyEtranLoginModel): Promise<EtranOperationSuccessInfo> {
    return (await HttpClient.post(`/api/etran/pingEtran`, loginModel)).data;
  },

  async pingTais(loginModel: CompanyEtranLoginModel): Promise<EtranOperationSuccessInfo> {
    return (await HttpClient.post(`/api/tais/ping`, loginModel)).data;
  },

  async pingRailway(loginModel: CompanyEtranLoginModel): Promise<EtranOperationSuccessInfo> {
    return (await HttpClient.post(`/api/railwaySource/ping`, loginModel)).data;
  },

  async pingSigis(loginModel: CompanyEtranLoginModel): Promise<EtranOperationSuccessInfo> {
    return (await HttpClient.post(`/api/sigisSource/ping`, loginModel)).data;
  },

  async getEtranInvoiceList(LoadOptions: any): Promise<DataSource> {
    return (await HttpClient.post(`/api/etraninvoice/list`, LoadOptions)).data;
  },

  async getEtranInvoice(invoiceId: string): Promise<EtranInvoiceInfo> {
    return (await HttpClient.get(`/api/etranInvoice/invoice?invoiceId=${invoiceId}`)).data;
  },

  async getStatusHistory(type: 'etranInvoice' | 'etranTelegram' | 'etranClaim', invoiceId: string): Promise<EtranInvoiceStatusHistory[]> {
    return (await HttpClient.get(`/api/${type}/statushistory?${type.replace('etran', '').toLocaleLowerCase()}Id=${invoiceId}`)).data;
  },

  async getEtranOrganizationDictionary(filter: string, cancelToken?): Promise<EtranOrganizationPassportInfo[]> {
    return (await HttpClient.get(`/api/etran/orgPassports/search`, { params: { filter }, cancelToken: cancelToken })).data;
  },
  async getEtranOrganizationByOrgId(orgId: number): Promise<EtranOrganizationPassportInfo> {
    return (await HttpClient.get(`/api/etran/orgPassports/${orgId}`)).data;
  },

  async getEtranOrganizationPayers(orgId?: number, okpo?: string, payerCode?: string): Promise<EtranOrganizationPayerModel[]> {
    if (!orgId && !okpo && !payerCode) return Promise.resolve([]);
    return (
      await HttpClient.get(`/api/etran/orgPayers`, {
        params: {
          orgId,
          okpo,
          payerCode,
        },
      })
    ).data;
  },

  async getEtranTelegramList(LoadOptions: any): Promise<DataSource> {
    return (await HttpClient.post(`/api/etranTelegram/list`, LoadOptions)).data;
  },

  async getEtranTelegram(telegramId: string): Promise<EtranTelegramInfo> {
    return (await HttpClient.get(`/api/etranTelegram/telegram?telegramId=${telegramId}`)).data;
  },

  async getEtranClaimList(LoadOptions: any): Promise<DataSource> {
    return (await HttpClient.post(`/api/etranClaim/list`, LoadOptions)).data;
  },

  async getEtranClaim(claimId: string): Promise<EtranClaimInfo> {
    return (await HttpClient.get(`/api/etranClaim/claim?claimId=${claimId}`)).data;
  },

  /// Классификатор нормативных документов по размещению и креплению грузов
  async getFasteningList(filter: string, cancelToken?): Promise<EtranFasteningModel> {
    return (await HttpClient.get(`/api/etrandictionary/fastening?filter=${filter}`, { cancelToken: cancelToken })).data;
  },

  /// Получить информацию по вагону
  async getCarDictionary(wagonNumber: number, companyId: string): Promise<EtranCarDictionaryModel> {
    return (await HttpClient.get(`/api/etrandictionary/car`, { params: { wagonNumber, companyId } })).data;
  },

  /// Получить ранее перевозимый груз вагона
  async getWagonLstFreight(wagonNumber: number, companyId: string): Promise<EtranLastFreightModel> {
    return (await HttpClient.get(`/api/freight/car-last-freight`, { params: { wagonNumber, companyId } })).data;
  },

  /// Получить список стран
  async getCountryList(): Promise<EtranCountryModel[]> {
    return (await HttpClient.get(`/api/etrandictionary/country`)).data;
  },

  /// Получить список станций
  async getStationList(filter: string, cancelToken?): Promise<EtranStationDictionaryInfo[]> {
    return (await HttpClient.get(`/api/etrandictionary/station`, { params: { filter }, cancelToken: cancelToken })).data;
  },

  /// Создание накладной
  async createInvoice(data: EtranInvoiceInfo): Promise<void> {
    return (await HttpClient.post(`/api/etranInvoice/create`, data)).data;
  },

  /// Редактирование накладной
  async updateInvoice(data: EtranInvoiceInfo): Promise<void> {
    return (await HttpClient.post(`/api/etranInvoice/update`, data)).data;
  },
};

export default etranService;
