import React from 'react';

const RequestNameDxCell: React.FC<any> = (props) => {
  return (
    <>
      {props.data.requesttypecode} - {props.data.requesttypename}
    </>
  );
};

export default RequestNameDxCell;
