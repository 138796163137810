import HttpClient from '@utils/httpClient';

const service = {
  async docRegistryData(data): Promise<any> {
    return (await HttpClient.post(`/api/docRegistry/data`, data)).data;
  },

  async download(templateMetadataId: string): Promise<Blob> {
    return (
      await HttpClient.get(`/api/docRegistry/content/${templateMetadataId}`, {
        responseType: 'blob',
      })
    ).data;
  },
};

export default service;
