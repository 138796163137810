import Icon from '@ant-design/icons';
import { Calendar } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import React, { Component } from 'react';

import { ReactComponent as container } from '@assets/icons/container.svg';

import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { ClaimReport } from '@stores/requestIlsStore/requestIlsStoreData';

import './IlsClaimWagonCalendar.less';

interface State {
  plan: { [key: string]: number };
}

interface Props {
  ilsClaim: ClaimReport;
  type: 'send' | 'loaded';
}

@observer
class IlsClaimWagonCalendar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let plan = {};
    if (this.props.type === 'send') {
      props.ilsClaim.sends.forEach((s) => {
        s.loadPlans.forEach((p) => {
          const date = moment(p.date).startOf('day').format(GLOBAL_DATE_FORMAT);
          if (plan[date]) {
            plan[date] += p.wagonCount;
          } else {
            plan[date] = p.wagonCount;
          }
        });
      });
    }
    if (this.props.type === 'loaded') {
      props.ilsClaim.wagons.forEach((w) => {
        if (['loaded', 'onway', 'unloading', 'complete'].includes(w.state)) {
          const date = moment(w.sendDate).startOf('day').format(GLOBAL_DATE_FORMAT);
          if (plan[date]) {
            plan[date] += 1;
          } else {
            plan[date] = 1;
          }
        }
      });
    }

    this.state = {
      plan: plan,
    };
  }

  isValidDate(currentDate, startDate, endDate) {
    return (
      moment(currentDate).startOf('day') < moment(startDate).startOf('day') || currentDate.startOf('day') > moment(endDate).startOf('day')
    );
  }

  render() {
    const claim = this.props.ilsClaim;
    const color = this.props.type === 'loaded' ? 'green' : '#4977e9';
    return (
      <Calendar
        defaultValue={moment(claim.beginDate)}
        headerRender={() => <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{moment(claim.beginDate).format('MMMM')}</div>}
        disabledDate={(currentDate) => {
          return this.isValidDate(currentDate, claim.beginDate, claim.endDate);
        }}
        dateFullCellRender={(date) => {
          const dateString = date.startOf('day').format(GLOBAL_DATE_FORMAT);
          const count = this.state.plan[dateString];
          if (count) {
            return (
              <div className={'calendar-cell'}>
                <div>{date.format('DD')}</div>
                <div style={{ justifyContent: 'left' }}>
                  <span style={{ color: 'white', paddingRight: 2, paddingLeft: 2, marginRight: 4, background: color }}>{count}</span>
                  <Icon style={{ color: color, fontSize: 16 }} component={container} />
                </div>
              </div>
            );
          } else {
            return (
              <div className={'calendar-cell'}>
                <div>{date.format('DD')}</div>
              </div>
            );
          }
        }}
      />
    );
  }
}

export default IlsClaimWagonCalendar;
