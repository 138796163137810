import HttpClient from '@utils/httpClient';

import { BillingProfile } from '../companiesStore/companyData';
import { CompanySmtpSettingsServiceModel, WagonOrContainer } from './CompanyData';

const service = {
  getCountries(type: WagonOrContainer, billingProfileId?: string) {
    return HttpClient.get(`/api/company/${type}/countries`, { params: { billingProfileId: billingProfileId } }).then((resp) => {
      return resp.data;
    });
  },

  async getSmtpSettings(companyId: string): Promise<CompanySmtpSettingsServiceModel> {
    return (await HttpClient.get(`/api/company/${companyId}/smtpSettings`)).data;
  },

  async updateSmtpSettings(companyId: string, data: CompanySmtpSettingsServiceModel): Promise<void> {
    return (await HttpClient.put(`/api/company/${companyId}/smtpSettings`, data)).data;
  },

  async testSmtpSettings(emailTo: string, data: CompanySmtpSettingsServiceModel): Promise<boolean> {
    return (await HttpClient.post(`/api/company/smtpSettings/test`, data, { params: { emailTo } })).data;
  },

  async getBillingProfiles(companyId: string): Promise<BillingProfile[]> {
    return (await HttpClient.get(`/api/company/billingProfiles/${companyId}`)).data;
  },
};

export default service;
