import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore';

interface OptionsPageState {}

interface OptionsPageProps {}

class OptionsPrintBtn extends Component<OptionsPageProps, OptionsPageState> {
  gtagProcess = (e) => {
    ReactGA.event({
      category: 'Tariff',
      action: e,
    });
  };

  render() {
    if (designStore.isMobile) return null;
    return (
      <Button
        shape={this.props.children ? null : 'circle'}
        type={'link'}
        disabled={!optionsStore.result || !optionsStore.result.countryDetails || !optionsStore.result.countryDetails.length}
        onClick={() => this.printMe()}
        icon={<PrinterOutlined />}>
        {this.props.children}
      </Button>
    );
  }

  async printMe() {
    this.gtagProcess('print');
    window.print();
  }
}

export default OptionsPrintBtn;
