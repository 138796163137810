import HttpClient from '@utils/httpClient';

import { CompanyCatalogModel, UpdateRentResponse } from './companyCatalogStore';

const service = {
  getCompanyCatalogs(companyId: string): Promise<CompanyCatalogModel[]> {
    return HttpClient.get(`/api/companyCatalog/list/${companyId}`).then((resp) => {
      return resp.data;
    });
  },

  addCompanyCatalogs(data: any) {
    return HttpClient.post(`/api/companyCatalog/add`, data).then((resp) => {
      return resp.data;
    });
  },

  saveCompanyCatalogs(data: any) {
    return HttpClient.post(`/api/companyCatalog/save`, data).then((resp) => {
      return resp.data;
    });
  },

  deleteCompanyCatalogs(companyCatalogId: string, companyId: string) {
    return HttpClient.delete(`/api/companyCatalog/delete/${companyCatalogId}`, {
      params: { companyCatalogId, companyId },
    }).then((resp) => {
      return resp.data;
    });
  },
  rentUpdate(): Promise<UpdateRentResponse> {
    return HttpClient.get(`/api/companyCatalog/rent/update`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
