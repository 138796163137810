import { Checkbox, Modal } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { TemplateOrgExpeditionExtParam } from '@stores/organizationsStore/organizationsStoreData';

interface State {
  checked: TemplateOrgExpeditionExtParam[];
}

interface Props {}

class OptionsExpeditionModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let checked = [];
    if (optionsStore.request.enabledExpeditorSettingIds && optionsStore.request.enabledExpeditorSettingIds.length) {
      optionsStore.request.expeditorSettings.forEach((s) => {
        if (optionsStore.request.enabledExpeditorSettingIds.includes(s.expeditorSettingId)) {
          checked.push(s);
        }
      });
    }
    this.state = {
      checked: checked,
    };
  }

  handleSubmit = () => {
    optionsStore.request.enabledExpeditorSettingIds = this.state.checked.map((c) => c.expeditorSettingId);
    optionsStore.showExpedition = false;
    optionsStore.calcTariff(false).then();
  };

  handleChange = (e, item: TemplateOrgExpeditionExtParam) => {
    const checked = this.state.checked;
    const index = checked.indexOf(item);
    if (index != -1) {
      checked.splice(index, 1);
    } else {
      checked.push(item);
    }
    this.setState({ checked });
  };

  render() {
    const data = optionsStore.request.expeditorSettings;
    const checked = this.state.checked;
    const isMobile = designStore.isMobile;
    return (
      <>
        <Modal
          title={'Выбор экспедитора'}
          open={optionsStore.showExpedition}
          onCancel={() => {
            optionsStore.showExpedition = false;
          }}
          okText={'Расчет'}
          centered={isMobile}
          onOk={() => this.handleSubmit()}
          width={isMobile ? '100%' : '60%'}>
          <ScrolledContainer className={'scrolledContainer'} style={{ width: '100%' }}>
            <table>
              <thead className={'ant-table-thead'}>
                <tr>
                  <th className="ant-table-cell">
                    <Checkbox checked={false} />
                  </th>
                  <th className="ant-table-cell">Страна</th>
                  <th className="ant-table-cell">Экспедитор</th>
                  <th className="ant-table-cell">%</th>
                  <th className="ant-table-cell">Доп. надбавка</th>
                  <th className="ant-table-cell">% НДС</th>
                  <th className="ant-table-cell">В т.ч. доп сборы</th>
                  <th className="ant-table-cell">Валюта</th>
                  <th className="ant-table-cell">Действует</th>
                </tr>
              </thead>
              <tbody className={'ant-table-tbody'}>
                {data.map((c, index) => (
                  <tr className="ant-table-row ant-table-row-level-0" key={'c' + index}>
                    <td className="ant-table-cell">
                      <Checkbox
                        disabled={!!checked.find((c2) => c2.expeditorSettingId != c.expeditorSettingId && c2.countryCode == c.countryCode)}
                        checked={checked.includes(c)}
                        onChange={(e) => this.handleChange(e, c)}
                      />
                    </td>
                    <td className="ant-table-cell">{c.countryName}</td>
                    <td className="ant-table-cell">{c.organizationName}</td>
                    <td className="ant-table-cell">{c.expeditorPercentage}</td>
                    <td className="ant-table-cell">{c.extraAmount}</td>
                    <td className="ant-table-cell">{c.ndsPercentage}</td>
                    <td className="ant-table-cell">{c.freightChargeAvailable ? 'ДА' : ''}</td>
                    <td className="ant-table-cell">{c.countryName}</td>
                    <td className="ant-table-cell">
                      {moment(c.periodFrom).format(GLOBAL_DATE_FORMAT)} - {moment(c.periodTo).format(GLOBAL_DATE_FORMAT)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ScrolledContainer>
        </Modal>
      </>
    );
  }
}

export default observer(OptionsExpeditionModal);
