import HttpClient from '@utils/httpClient';

const service = {
  getCommercial(bidRegistryId: string) {
    return HttpClient.get(`/api/bidRegistryCommercial?bidRegistryId=${bidRegistryId}`).then((resp) => {
      return resp.data;
    });
  },
  updateCommercial(body) {
    return HttpClient.post(`/api/bidRegistryCommercial`, body).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
