import { Button, DatePicker, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import optionsStoreService from '@stores/optionsStore/optionsStore.service';

import CompanyUsingStatistic from './CompanyUsingStatistic';

interface ReportsListState {
  date: any;
  isBusy: boolean;
}

@observer
class ReportsList extends Component<RouteComponentProps, ReportsListState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      date: moment(),
      isBusy: false,
    };
  }

  handleSubmit = () => {
    this.setState({ isBusy: true });
    const date = moment(this.state.date);
    optionsStoreService
      .getReport(date.get('month') + 1, date.get('year'))
      .finally(() => this.setState({ isBusy: false }))
      .then((result) => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Количество запросов тарифа по клиентам ${date.format('YYYY MM')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  componentDidMount(): void {
    document.title = 'Отчеты администратора';
  }

  render() {
    const isBusy = this.state.isBusy;
    return (
      <div>
        <AdaptiveCard>
          <Spin spinning={isBusy}>
            <Tabs style={{ height: 'calc(100vh - 128px)' }}>
              <Tabs.TabPane tab={' Количество запросов тарифа по клиентам'} key={'rate-requests'}>
                <div className={'flex-wrapper'}>
                  <DatePicker
                    getPopupContainer={(trigger) => trigger.parentElement}
                    defaultValue={this.state.date}
                    picker={'month'}
                    allowClear={false}
                    format={'MMMM YYYY'}
                    onChange={(e) => this.setState({ date: e })}
                  />
                  <Button onClick={this.handleSubmit} loading={isBusy}>
                    Скачать
                  </Button>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab={'Использование системы'} key={'company-using-statistic'}>
                <CompanyUsingStatistic />
              </Tabs.TabPane>
            </Tabs>
          </Spin>
        </AdaptiveCard>
      </div>
    );
  }
}

export default ReportsList;
