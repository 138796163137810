import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import RefreshModal from '@components/fw/RefreshModal/RefreshModal';
import AccessRoute from '@components/fw/RoutingContext/AccessRoute';
import Administration from '@components/routed/Administration';
import Analytics from '@components/routed/Analytics';
import BrosPage from '@components/routed/Analytics/BrosPage/BrosPage';
import ItineraryEconomicalsContainers from '@components/routed/Analytics/ItineraryEconomicals/ItineraryEconomicalsContainers';
import ItineraryEconomicalsWagons from '@components/routed/Analytics/ItineraryEconomicals/ItineraryEconomicalsWagons';
import BankPage from '@components/routed/BankPage/BankPage';
import ClaimInfoPageWrapper from '@components/routed/ClaimInfo/ClaimInfoPageWrapper';
import ClaimsPage from '@components/routed/ClaimsPage';
import CompanyTariffPage from '@components/routed/CompnayTariffPage/CompanyTariffPage';
import Dashboard from '@components/routed/Dashboard/Dashboard';
import DispatcherInfoPage from '@components/routed/DispatcherInfoPage/DispatcherInfoPage';
import DispatcherPage from '@components/routed/DispatcherPage';
import DispatcherReportWrapper from '@components/routed/DispatcherReport/DispatcherReportWrapper';
import DistributionPage from '@components/routed/DistributionPage/DistributionPage';
import DocRegistryPage from '@components/routed/DocRegistryPage/DocRegistryPage';
import DocumentsPage from '@components/routed/DocumentsPage';
import EquipmentPage from '@components/routed/EquipmentPage/EquipmentPage';
import EtranClaimPage from '@components/routed/EtranClaimPage/EtranClaimPage';
import EtranClaimViewer from '@components/routed/EtranClaimViewer/EtranClaimViewer';
import EtranInvoicePage from '@components/routed/EtranPage/EtranInvoicePage';
import EtranTelegramPage from '@components/routed/EtranTelegramPage/EtranTelegramPage';
import EtranTelegramViewer from '@components/routed/EtranTelegramViewer/EtranTelegramViewer';
import EtranViewer from '@components/routed/EtranViewer';
import FinancePage from '@components/routed/FinancePage/FinancePage';
import HoldContainers from '@components/routed/HoldPage/HoldContainers';
import HoldWagons from '@components/routed/HoldPage/HoldWagons';
import HoldsPage from '@components/routed/Holds/HoldsPage';
import { IlsClaimPage } from '@components/routed/IlsClaimPage';
import IlsClaimItemPage from '@components/routed/IlsClaimPage/IlsClaimItemPage';
import IlsClaimWagonPage from '@components/routed/IlsClaimPage/IlsClaimWagons/IlsClaimWagonPage';
import ItineraryContainers from '@components/routed/ItineraryPage/ItineraryContainers';
import ItineraryWagons from '@components/routed/ItineraryPage/ItineraryWagons';
import LeasingDetailPage from '@components/routed/LeasingPage/LeasingDeatail/LeasingDetailPage';
import LeasingPage from '@components/routed/LeasingPage/LeasingPage';
import LoadingRegister from '@components/routed/LoadingRegister';
import MainPageWrapper from '@components/routed/MainPage/MainPageWrapper';
import MapPageWrapper from '@components/routed/MapPage/MapPageWrapper';
import MessengerPage from '@components/routed/MessengerPage';
import MyCompanySettings from '@components/routed/MyCompanySettings/MyCompanySettings';
import NotificationsPage from '@components/routed/NotificationsPage/NotificationsPage';
import OptionsPageWrapper from '@components/routed/OptionsPage/OptionsPageWrapper';
import RegisterOfBetsCreatePage from '@components/routed/RegisterOfBetsCreatePage';
import RegisterOfBetsPage from '@components/routed/RegisterOfBetsPage';
import RequestsPage from '@components/routed/RequestsPage/RequestsPage';
import SendInfoPageWrapper from '@components/routed/SendInfo/SendInfoPageWrapper';
import SendsPage from '@components/routed/SendsPage';
import StatisticPage from '@components/routed/StatisticPage/StatisticPage';
import SupplierBidRegistryCreatePage from '@components/routed/SupplierBidRegistryCreatePage';
import SupplierBidRegistryPage from '@components/routed/SupplierBidRegistryPage';
import TariffFgkPage from '@components/routed/TariffFgkPage';
import TrackingContainers from '@components/routed/TrackingContainers';
import TrackingWagons from '@components/routed/TrackingWagons';
import TransportRegisterPage from '@components/routed/TransportRegisterPage';
import UserProfile from '@components/routed/UserProfile';

import designStore from '@stores/designStore/designStore';
import dictStore from '@stores/dictStore/dictStore';
import messengerStore from '@stores/messengerStore/messengerStore';
import userStore from '@stores/userStore/userStore';

import styles from './AppContent.module.scss';
import Greeting from './Greeting';
import Sidebar from './Sidebar';

const { Content } = Layout;

class AppContent extends Component<RouteComponentProps> {
  componentDidMount(): void {
    designStore.updateWindowDimensions();
    window.addEventListener('resize', designStore.updateWindowDimensions.bind(this));
    userStore.getUserInfo().then(() => {
      if (userStore.tmpUser) this.props.history.push('/options');
      else if (this.props.location.pathname == '/') {
        if (userStore.userData.startPage) {
          this.props.history.push(userStore.userData.startPage);
        } else {
          this.props.history.push('/main');
        }
      }
    });
    dictStore.getDictionaries();
    messengerStore.connect().then();
    messengerStore.getDialogs();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', designStore.updateWindowDimensions.bind(this));
  }

  render() {
    return (
      <Layout className={styles.content}>
        {userStore.userData ? (
          <>
            <RefreshModal />
            {!userStore.tmpUser && <Sidebar {...this.props} />}
            <Layout>
              <Content>
                <Switch>
                  <Route exact path="/"></Route>
                  <AccessRoute path="/dashboard" component={Dashboard} canAccess={true} />

                  <AccessRoute path="/map" component={MapPageWrapper} canAccess={true} />
                  <AccessRoute path="/ils-page" component={IlsClaimPage} canAccess={userStore.canAccess('ils_claim')} />

                  <AccessRoute path="/view-ils-send/:id" component={IlsClaimItemPage} canAccess={userStore.canAccess('ils_claim')} />
                  <AccessRoute path="/ils-claim-wagon/:id" component={IlsClaimWagonPage} canAccess={userStore.canAccess('ils_claim')} />

                  <AccessRoute
                    path="/billing/orders/wagons"
                    component={TrackingWagons}
                    canAccess={userStore.canAccess('billing/orders/wagons')}
                  />
                  <AccessRoute
                    path="/billing/orders/containers"
                    component={TrackingContainers}
                    canAccess={userStore.canAccess('billing/orders/containers')}
                  />
                  <AccessRoute path="/administration" component={Administration} canAccess={userStore.canAccess('administration')} />
                  <AccessRoute path="/companySettings" component={MyCompanySettings} canAccess={userStore.canAccess('administration')} />
                  <AccessRoute exact path="/claims" component={ClaimsPage} canAccess={userStore.canAccess('claims')} />
                  <AccessRoute exact path="/options" component={OptionsPageWrapper} canAccess={userStore.canAccess('tariff')} />

                  {/*<AccessRoute path="/my-reports" component={MyReportsPage} canAccess={userStore.canAccess('dispatcher')} />*/}
                  <AccessRoute
                    path="/my-reports/reports"
                    component={DispatcherPage}
                    canAccess={userStore.canAccess('dispatcher/reports')}
                  />
                  <AccessRoute
                    path="/my-reports/itineraries"
                    component={Analytics}
                    canAccess={userStore.canAccess('dispatcher/itineraries')}
                  />
                  <AccessRoute
                    path="/my-reports/loading-register"
                    component={LoadingRegister}
                    canAccess={userStore.canAccess('dispatcher/loadregistry')}
                  />
                  <AccessRoute
                    path="/my-reports/equipment"
                    component={EquipmentPage}
                    canAccess={userStore.canAccess('dispatcher/equipment')}
                  />
                  <AccessRoute path="/my-reports/holds" component={HoldsPage} canAccess={userStore.canAccess('dispatcher/loadregistry')} />

                  <AccessRoute
                    exact
                    path="/dispatcher/itineraries"
                    component={Analytics}
                    canAccess={userStore.canAccess('dispatcher/itineraries')}
                  />
                  <AccessRoute
                    exact
                    path="/dispatcher/itineraries/wagons"
                    component={ItineraryWagons}
                    canAccess={userStore.canAccess('dispatcher/itineraries/wagons')}
                  />
                  <AccessRoute
                    exact
                    path="/dispatcher/itineraries-econ/containers"
                    component={ItineraryEconomicalsContainers}
                    canAccess={userStore.canAccess('dispatcher/itineraries/containers')}
                  />
                  <AccessRoute
                    exact
                    path="/dispatcher/itineraries-econ/wagons"
                    component={ItineraryEconomicalsWagons}
                    canAccess={userStore.canAccess('dispatcher/itineraries/wagons')}
                  />
                  <AccessRoute
                    exact
                    path="/dispatcher/itineraries/containers"
                    component={ItineraryContainers}
                    canAccess={userStore.canAccess('dispatcher/itineraries/containers')}
                  />
                  <AccessRoute
                    exact
                    path="/dispatcher/hold/wagons"
                    component={HoldWagons}
                    canAccess={userStore.canAccess('dispatcher/itineraries/wagons')}
                  />
                  <AccessRoute
                    exact
                    path="/dispatcher/bros/wagons"
                    component={BrosPage}
                    canAccess={userStore.canAccess('dispatcher/bros/wagons')}
                  />
                  <AccessRoute
                    exact
                    path="/dispatcher/hold/containers"
                    component={HoldContainers}
                    canAccess={userStore.canAccess('dispatcher/itineraries/containers')}
                  />

                  <AccessRoute
                    exact
                    path="/dispatcher/equipment"
                    component={EquipmentPage}
                    canAccess={userStore.canAccess('dispatcher/itineraries/containers')}
                  />

                  <AccessRoute
                    exact
                    path="/billing/statistic"
                    component={StatisticPage}
                    canAccess={userStore.canAccess('billing/statistic')}
                  />
                  <AccessRoute path="/etran/create-invoice" component={EtranViewer} canAccess={userStore.canAccess('etran')} />
                  <AccessRoute path="/etranInvoice" component={EtranInvoicePage} canAccess={userStore.canAccess('etran')} />

                  <AccessRoute path="/etrantelegram" component={EtranTelegramPage} canAccess={userStore.canAccess('etran')} />

                  <AccessRoute path="/etranclaim" component={EtranClaimPage} canAccess={userStore.canAccess('etran')} />

                  <AccessRoute path="/etranViewer" component={EtranViewer} canAccess={userStore.canAccess('etran')} />
                  <AccessRoute
                    path="/dispatcher/reports/:id/:groupId/:filterId"
                    component={DispatcherReportWrapper}
                    canAccess={userStore.canAccess('dispatcher/reports')}
                  />
                  <Route path="/claims/create-claim" component={ClaimInfoPageWrapper} />
                  <Route path="/claims/edit-claim/:claimId" component={ClaimInfoPageWrapper} />
                  <AccessRoute exact path="/sends" component={SendsPage} canAccess={userStore.canAccess('sends')} />
                  <Route path="/sends/create-send" component={SendInfoPageWrapper} />
                  <Route path="/sends/edit-send/:sendId" component={SendInfoPageWrapper} />
                  <AccessRoute path="/etran/edit-invoice/:invoiceId" component={EtranViewer} canAccess={userStore.canAccess('etran')} />
                  <AccessRoute
                    path="/etran/view-telegram/:telegramId"
                    component={EtranTelegramViewer}
                    canAccess={userStore.canAccess('etran')}
                  />
                  <AccessRoute path="/etran/view-claim/:claimId" component={EtranClaimViewer} canAccess={userStore.canAccess('etran')} />

                  <AccessRoute
                    path="/documents/billing"
                    component={() => <DocumentsPage type={'billing'} />}
                    canAccess={userStore.canAccess('documents/billing')}
                  />
                  <AccessRoute
                    path="/documents/registry"
                    component={DocRegistryPage}
                    canAccess={userStore.canAccess('documents/billing')}
                  />
                  <Route path="/messenger" component={MessengerPage} />
                  <AccessRoute path="/distribution" component={DistributionPage} canAccess={userStore.canAccess('distribution')} />
                  <Route path="/profile" component={UserProfile} />
                  <AccessRoute path="/requests" component={RequestsPage} canAccess={userStore.canAccess('requests')} />
                  <AccessRoute exact path="/fgk" component={TariffFgkPage} canAccess={userStore.canAccess('tariff/fgk')} />
                  <AccessRoute path="/companyTariff/:companyId" component={CompanyTariffPage} canAccess={true} />

                  <AccessRoute exact path="/info" component={DispatcherInfoPage} canAccess={true} />
                  <AccessRoute path="/finance-infolog" component={FinancePage} canAccess={userStore.canAccess('finance-infolog')} />
                  <AccessRoute path="/main" component={MainPageWrapper} canAccess={true} />
                  <AccessRoute
                    path="/notifications"
                    component={NotificationsPage}
                    canAccess={userStore.userData && userStore.userData.companyId && userStore.canAccess('notifications')}
                  />
                  <AccessRoute path="/leasing" component={LeasingPage} canAccess={userStore.canAccess('rent')} />
                  <AccessRoute path="/leasingDetails/:id" component={LeasingDetailPage} canAccess={userStore.canAccess('rent')} />
                  <AccessRoute path="/bank" component={BankPage} canAccess={true} />
                  {/* TODO: Указать access */}
                  <AccessRoute
                    exact
                    path="/list-of-rates"
                    component={RegisterOfBetsPage}
                    canAccess={userStore.canAccess('list-of-rates')}
                  />
                  <AccessRoute
                    exact
                    path="/list-of-rates/create"
                    component={RegisterOfBetsCreatePage}
                    canAccess={userStore.canAccess('list-of-rates')}
                  />
                  <AccessRoute
                    exact
                    path="/list-of-rates/edit/:bidregistryid"
                    component={RegisterOfBetsCreatePage}
                    canAccess={userStore.canAccess('list-of-rates')}
                  />

                  <AccessRoute
                    exact
                    path="/supplier-list-of-rates"
                    component={SupplierBidRegistryPage}
                    canAccess={userStore.canAccess('supplier-list-of-rates')}
                  />
                  <AccessRoute
                    exact
                    path="/supplier-list-of-rates/create"
                    component={SupplierBidRegistryCreatePage}
                    canAccess={userStore.canAccess('supplier-list-of-rates')}
                  />
                  <AccessRoute
                    exact
                    path="/supplier-list-of-rates/edit/:supplierbidregistryid"
                    component={SupplierBidRegistryCreatePage}
                    canAccess={userStore.canAccess('supplier-list-of-rates')}
                  />

                  <AccessRoute
                    exact
                    path="/transport-register"
                    component={TransportRegisterPage}
                    canAccess={userStore.canAccess('transport-register')}
                  />
                </Switch>
              </Content>
            </Layout>
          </>
        ) : (
          <Greeting />
        )}
      </Layout>
    );
  }
}

export default observer(AppContent);
