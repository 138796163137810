import { Col, Row } from 'antd';
import React, { Component } from 'react';

import RemoteSelect from '@components/fw/RemoteSelect';
import styles from '@components/routed/EtranViewer/EtranViewer.module.scss';

import { EtranStationDictionaryInfo } from '@stores/etranStore/EtranDictData';
import etranStoreService from '@stores/etranStore/etranStore.service';

interface Props {
  value?: EtranStationDictionaryInfo;
  label: string;
  onChange?(e: EtranStationDictionaryInfo): void;
}

interface State {}

class EtranStation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const selected = this.props.value;
    return (
      <>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={4}>
            <label className={'etranFormLabel'}>{this.props.label}</label>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <RemoteSelect
              value={this.props.value}
              className={'comboEditSearchOther'}
              placeholder={'поиск'}
              dataSource={etranStoreService.getStationList}
              valueExpr={'stCode'}
              displayExpr={'stName'}
              onChange={(e) => {
                if (this.props.onChange) {
                  this.props.onChange(e);
                }
              }}
              itemTemplate={(e) => (
                <span>
                  <b>{e.stCode}</b> {e.stName}
                </span>
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} style={{ display: 'inline-flex' }}>
            <span className={styles.label}>Код</span>
            <span className={styles.textEditOther} style={{ minWidth: '4rem' }}>
              {' '}
              {selected ? selected.stCode : ''}
            </span>
            <span className={styles.label}>ЖД</span>
            <span className={styles.textEditOther} style={{ minWidth: '4rem' }}>
              {selected ? selected.rwCode : ''}
            </span>
            <span className={styles.label}>Параграфы</span>
            <span className={styles.textEditThree}>{selected ? selected.fromStationParagraphs : null}</span>
          </Col>
          <Col className={'node'} xs={24} sm={24} md={24} lg={6} style={{ display: 'inline-flex' }}>
            <span className={styles.label}>Узел</span>
            <span className={styles.textEditThree}>{selected ? selected.uzel : null}</span>
          </Col>
        </Row>
      </>
    );
  }
}

export default EtranStation;
