import AbstractTrackingStoreService from '../abstractTrackingStore.service';
import { ActiveWagon, HistoryWagon, WagonAddRequest, WagonRemoveRequest } from './WagonTypes';

export class TrackingWagonStoreService extends AbstractTrackingStoreService<
  ActiveWagon,
  HistoryWagon,
  WagonAddRequest,
  WagonRemoveRequest
> {
  path = 'wagon';
}
