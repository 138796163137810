import { Tabs } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import MobileNotSupport from '@components/fw/AdaptiveElements/MobileNotSupport';
import { ContractTemplatesEditor } from '@components/fw/ContractTemplatesEditor/ContractTemplatesEditor';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import { NSI_OKPO_FIELDS, NSI_STATIONS_FIELDS } from '@stores/nsiStore/nsiData';
import nsiOkpoStore from '@stores/nsiStore/okpo/nsiOkpoStore';
import nsiStationStore from '@stores/nsiStore/station/nsiStationStore';

import NsiEditor from './NsiEditor';

interface State {}

class NsiPage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  render() {
    return (
      <ScrolledContainer>
        <AdaptiveCard>
          <Tabs tabPosition={'top'}>
            <Tabs.TabPane tab="Станции" key="1">
              <NsiEditor data={NSI_STATIONS_FIELDS} store={nsiStationStore} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="ОКПО" key="2">
              <NsiEditor data={NSI_OKPO_FIELDS} store={nsiOkpoStore} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Базовые шаблоны" key="3" style={{ height: 'calc(100vh - 8.7rem)' }}>
              <MobileNotSupport>
                <ContractTemplatesEditor />
              </MobileNotSupport>
            </Tabs.TabPane>
          </Tabs>
        </AdaptiveCard>
      </ScrolledContainer>
    );
  }
}

export default NsiPage;
