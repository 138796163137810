import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import SourceLogs from './SourceLogs';

interface State {}

class SourceLogsInput extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  render() {
    document.title = 'Входящие';
    return <SourceLogs type={'input'} />;
  }
}

export default SourceLogsInput;
