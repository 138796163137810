import AbstractTrackingStoreService from '../abstractTrackingStore.service';
import { ActiveContainer, ContainerAddRequest, ContainerRemoveRequest, HistoryContainer } from './ContainerTypes';

export class TrackingContainerStoreService extends AbstractTrackingStoreService<
  ActiveContainer,
  HistoryContainer,
  ContainerAddRequest,
  ContainerRemoveRequest
> {
  path = 'container';
}
