import { Button, Col, Form, Row, Select, Spin, Tabs } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import RequisitsForm from '@components/fw/Drawers/CompanyEditor/RequisitsForm';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import companiesStore from '@stores/companiesStore/companiesStore';
import companyCatalogStore from '@stores/companyCatalogStore';
import { OrganizationModel } from '@stores/organizationsStore';
import organizationsStore, { emptyOrganization } from '@stores/organizationsStore/organizationsStore';
import userStore from '@stores/userStore/userStore';

import ContractList from './ContractList/ContractList';

interface OrganizationEditorProps {
  onCancel(): void;
  onConfirm(organization: OrganizationModel): void;
  organizationId: string;
}

interface OrganizationEditorState {
  organization: OrganizationModel | null;
  isBusy: boolean;
  companyId: string;
}

@observer
class OrganizationEditor extends Component<OrganizationEditorProps, OrganizationEditorState> {
  formRef: FormInstance;

  constructor(props: OrganizationEditorProps) {
    super(props);

    this.state = {
      organization: null,
      isBusy: true,
      companyId: null,
    };
  }

  componentDidMount(): void {
    if (userStore.isAdmin) {
      companiesStore.getCompaniesSort();
    }
    organizationsStore.getOrganizationTypes().then(() => {
      setTimeout(() => {
        this.forceUpdate();
      }, 1000);
    });
    if (this.props.organizationId) {
      organizationsStore
        .getById(this.props.organizationId)
        .then((ans) => {
          if (!ans.contactEmails) ans.contactEmails = [];

          companyCatalogStore.getCompanyCatalogs(ans.companyId);
          this.setState({ organization: ans, companyId: ans.companyId });
        })
        .finally(() => this.setState({ isBusy: false }));
    } else {
      this.setState({ organization: emptyOrganization, isBusy: false });
    }
  }

  onCompanyChange = (value: string) => {
    companyCatalogStore.getCompanyCatalogs(value).then(() => {
      this.formRef.setFieldValue('catalogId', this.state.organization.companyId === value ? this.state.organization.catalogId : undefined);
    });
  };

  render() {
    const companyCatalogs = companyCatalogStore.companyCatalogs;
    const organization = this.state.organization;
    const loadingConfirm = organizationsStore.loadingConfirm;
    const companies = companiesStore.companiesShort;
    const types = organizationsStore.organizationTypes;
    const user = userStore.userData;
    const isBusy = this.state.isBusy;
    return (
      <AdaptiveDrawer
        title={this.isEditMode ? 'Редактирование организации' : 'Создание организации'}
        width={'84%'}
        onClose={this.handelClose}
        open={organization !== null}
        footer={[
          <Button onClick={this.handelClose} style={{ marginRight: 8 }} key={'close'}>
            Отмена
          </Button>,
          <Button type="primary" key={'submit'} loading={loadingConfirm} htmlType={'submit'} form={'org-editor'}>
            {this.isEditMode ? 'Сохранить' : 'Добавить'}
          </Button>,
        ]}>
        <Spin spinning={isBusy}>
          <Tabs>
            <Tabs.TabPane tab={'Общее'} key={'common'}>
              {organization ? (
                <Form
                  ref={(ref) => (this.formRef = ref)}
                  layout="vertical"
                  size={'large'}
                  // hideRequiredMark
                  name={'org-editor'}
                  initialValues={organization}
                  onFinish={this.handleConfirm}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={24} lg={8}>
                      <Form.Item name="name" rules={[{ required: true, message: 'Необходимо заполнить название' }]}>
                        <FloatInput placeholder="Название" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item name="organizationTypeCode" rules={[{ required: true, message: 'Необходимо заполнить тип' }]}>
                        <FloatSelect
                          placeholder="Тип"
                          //value={organization.organizationTypeCode}
                          allowClear
                          getPopupContainer={(trigger) => trigger.parentElement}>
                          {types.map((item, index) => {
                            return (
                              <Select.Option value={item.code} key={`type_${index}`}>
                                {item.name}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    {user.roleCode !== 'user' ? (
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <Form.Item name="companyId" rules={[{ required: true, message: 'Необходимо заполнить компанию' }]}>
                          <FloatSelect placeholder="Компания" disabled={this.isEditMode} allowClear onChange={this.onCompanyChange}>
                            {companies.map((item, index) => {
                              return (
                                <Select.Option value={item.companyId} key={`company_${index}`}>
                                  {item.name}
                                </Select.Option>
                              );
                            })}
                          </FloatSelect>
                        </Form.Item>
                      </Col>
                    ) : null}
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        name="contactEmails"
                        rules={[
                          {
                            validator: (rule, value, callback) => {
                              if (!Array.isArray(value)) {
                                return Promise.reject('Укажите корректные адреса');
                              }
                              value.forEach((v) => {
                                if (!v || typeof v != 'string') {
                                  return Promise.reject('Укажите корректные адреса');
                                }
                                if (v.indexOf('@') < 2 || v.indexOf('.') < 3) {
                                  return Promise.reject('Укажите корректные адреса');
                                }
                              });
                              return Promise.resolve();
                            },
                          },
                        ]}>
                        <FloatSelect placeholder="EMAIL адреса для отправки документов" mode={'tags'} allowClear>
                          {organization.email ? (
                            <Select.Option value={organization.email} key={organization.email}>
                              {organization.email}
                            </Select.Option>
                          ) : null}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item name="catalogId">
                        <FloatSelect placeholder="Подразделение" allowClear>
                          {companyCatalogs.map((item, index) => {
                            return (
                              <Select.Option key={item.id} value={item.id}>
                                {item.title}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                  </Row>
                  <RequisitsForm onDataReady={this.handleReqChange} reason={organization.reason} address={organization.address} />
                </Form>
              ) : null}
            </Tabs.TabPane>
            {this.state.companyId ? (
              <Tabs.TabPane tab={'Договоры'} key={'offers'}>
                <ContractList companyId={this.state.companyId} organizationId={this.state.organization.organizationId} />
              </Tabs.TabPane>
            ) : null}
          </Tabs>
        </Spin>
      </AdaptiveDrawer>
    );
  }

  get isEditMode(): boolean {
    return !!this.props.organizationId;
  }

  handelClose = () => {
    this.props.onCancel();
  };

  handleConfirm = (event: any) => {
    const organization: OrganizationModel = {
      organizationId: this.props.organizationId,
      name: event.name,
      companyId: event.companyId ? event.companyId : userStore.userData.companyId,
      organizationTypeCode: event.organizationTypeCode,
      inn: event.inn,
      kpp: event.kpp,
      okpo: event.okpo,
      ogrn: event.ogrn,
      shortNameReq: event.shortNameReq,
      fullNameReq: event.fullNameReq,
      address: event.address,
      telephone: event.telephone,
      email: event.email,
      owner: event.owner,
      okfs: event.okfs,
      okogu: event.okogu,
      okopf: event.okopf,
      oktmo: event.oktmo,
      okato: event.okato,
      country: event.country,
      orgBill: event.orgBill,
      addressMail: event.addressMail,
      addressFact: event.addressFact,
      view: event.view,
      group: event.group,
      bankBik: event.bankBik,
      bankName: event.bankName,
      bankAddress: event.bankAddress,
      bankCorBill: event.bankCorBill,
      signer: event.signer,
      post: event.post,
      reason: event.reason,
      attorney: event.attorney,
      glbuh: event.glbuh,
      contactEmails: event.contactEmails,
      catalogId: event.catalogId,
    };
    this.props.onConfirm(organization);
  };

  handleReqChange = (data: any) => {
    let organization = this.state.organization;
    Object.keys(data).forEach((k) => {
      if (data[k] && data[k].length) {
        organization[k] = data[k];
      }
    });

    this.setState({ organization });
    this.formRef.resetFields(Object.keys(data));
  };
}

export default OrganizationEditor;
