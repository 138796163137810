import { Empty, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import tariffFgkBaseStore, { TariffFgkBaseResponseModel } from '@stores/TariffFgkBase/tariffFgkBaseStore';
import designStore from '@stores/designStore/designStore';

interface TariffFgkViewerProps {
  response: TariffFgkBaseResponseModel;
}

export const wayKind: { [key: number]: string } = {
  1: 'Прямое',
  2: 'Экспорт',
  3: 'Импорт',
  4: 'Прямое смешанное',
  5: 'Транзит',
  99: 'Вне РФ',
};

class TariffFgkViewer extends Component<TariffFgkViewerProps> {
  constructor(props: TariffFgkViewerProps) {
    super(props);
  }

  render() {
    const isMobile = designStore.isMobile;
    const request = tariffFgkBaseStore.request;
    const response = this.props.response;
    if (response.type == 'error') return <Empty>{response.message}</Empty>;
    else
      return (
        <ScrolledContainer>
          <div className={'verticalTable'}>
            <div
              className={'tariffCalculationTable fgk'}
              style={{
                gridTemplateColumns: `${isMobile ? '14rem repeat(' + 1 + ', minmax(auto, 1fr))' : 'repeat(2, 14rem)'}`,
              }}>
              <div>
                {/*<th>К прейскуранту No10-01 в запросе</th>*/}
                <div>Единица расчета</div>
                <div>Справочно, % к Прейскуранту No10-01 в ответе</div>
                <div>Кратчайшее расстояние, км.</div>
                <div>Вид сообщения</div>
                <div style={{ display: 'block' }}>
                  Ставка АО "ФГК" руб. без НДС <b>*</b>
                </div>
                {/*<th>
                Провозная плата за инв. ваг.
              </th>*/}
                {/*<th>Провозная плата за собств. ваг.</th>*/}
              </div>
              <div>
                {/*<td>{response.rateResultRequestDiscountMargin}%</td>*/}
                <div>один вагон</div>
                <div>{response.rateResultDiscountMargin}%</div>
                <div>{response.rateResultDist}</div>
                <div>{wayKind[response.rateResultSoob]}</div>
                <div className={'totalNumberFgk'}>
                  {response.rateResultRate.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                  <Tooltip title={request.rateHeadCurrencyObj.name}>
                    <span>{request.rateHeadCurrencyObj.nameFiled}</span>
                  </Tooltip>
                </div>
                {/*<td className={'money-value'}>
                {response.rateStminv.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
              </td>*/}
                {/* <td className={'money-value'}>
                {response.rateStmsobst.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
              </td>*/}
              </div>
            </div>
            <div>
              <div>{response.rateComment}</div>
            </div>
            <div>
              <span style={{ marginLeft: '15px' }}>
                <b>*</b> -информация носит справочный характер
              </span>
            </div>
          </div>
        </ScrolledContainer>
      );
  }
}

export default observer(TariffFgkViewer);
