import HttpClient from '@utils/httpClient';

import { FinanceDocModel, FinanceOperationModel, InfologFinanceModel } from './FinanceData';

const service = {
  /// Список финансов
  async getInfologFinanceList(): Promise<InfologFinanceModel[]> {
    return (await HttpClient.get(`/api/infologFinance/data`)).data;
  },

  /// Добавить финансы
  async addInfologFinance(data: InfologFinanceModel): Promise<void> {
    return (await HttpClient.post(`/api/infologFinance`, data)).data;
  },

  /// Обновить финансы
  async updateInfologFinance(data: InfologFinanceModel): Promise<void> {
    return (await HttpClient.put(`/api/infologFinance`, data)).data;
  },

  /// Обновить финансы
  async deleteInfologFinance(financeId: String, accountNumber: string): Promise<void> {
    return (await HttpClient.delete(`/api/infologFinance/${financeId}`, { params: { accountNumber } })).data;
  },

  /// Список операций
  async getFinanceOperationList(): Promise<FinanceOperationModel[]> {
    return (await HttpClient.get(`/api/financeOperation/data`)).data;
  },

  /// Список документов (без контента)
  async getFinanceDocList(financeId: string): Promise<FinanceDocModel[]> {
    return (await HttpClient.get(`/api/financeDoc/data/${financeId}`)).data;
  },

  async addFinanceDoc(data: any, financeId: string, accountNumber: string): Promise<void> {
    const formData = new FormData();
    formData.append('file', data);
    return (
      await HttpClient.post(`/api/financeDoc/attach`, data, {
        params: { financeId, accountNumber },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    ).data;
  },

  async getFinanceDoc(documentId: string): Promise<Blob> {
    return (
      await HttpClient.get(`/api/financeDoc/content/${documentId}`, {
        responseType: 'blob',
      })
    ).data;
  },

  async deleteFinanceDoc(data: FinanceDocModel): Promise<void> {
    return (await HttpClient.post(`/api/financeDoc/delete`, data)).data;
  },
};
export default service;
