import HttpClient from '@utils/httpClient';

import { VersionContentInfo, VersionInfo } from './systemInfoData';

const service = {
  async getVersion(): Promise<VersionInfo> {
    return (await HttpClient.get(`/api/system/version/last`)).data;
  },

  async getVersionList(): Promise<VersionContentInfo[]> {
    return (await HttpClient.get(`/api/system/version/data`)).data;
  },
  async updateVersion(data: VersionContentInfo): Promise<void> {
    return (await HttpClient.put(`/api/system/version`, data)).data;
  },
  async addVersion(data: VersionContentInfo): Promise<void> {
    return (await HttpClient.post(`/api/system/version`, data)).data;
  },
  async deleteVersion(code: string): Promise<void> {
    return (await HttpClient.delete(`/api/system/version/${code}`)).data;
  },
};

export default service;
