import { DeleteOutlined, EditOutlined, PlusCircleOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Popconfirm, Radio, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import Search from 'antd/es/input/Search';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import DraggableList from '@components/fw/DragableList/DragableList';

import companyCatalogStore from '@stores/companyCatalogStore/companyCatalogStore';
import designStore from '@stores/designStore';
import { CustomReportDetailsModel, InfoBlockItem, InfoBlockItemFields } from '@stores/dispatcherStore/DispatcherData';
import dispatcherStore from '@stores/dispatcherStore/dispatcherStore';
import dispatcherService from '@stores/dispatcherStore/dispatcherStore.service';
import userStore from '@stores/userStore/userStore';

import { showSuccessNotify } from '@utils/notify';

interface ComponentState {
  isBusy: boolean;
  loadingEdit: boolean;
  selectedReport: CustomReportDetailsModel | null;
  isBusyConfirm: boolean;
  isEditReport: boolean;
  isCreateReport: boolean;
  selectedInfogroupId: number | null;
  selected: InfoBlockItemFields[];
  search: string | null;
  selectedDatasource: InfoBlockItem;
}

interface ComponentProps {
  data?: CustomReportDetailsModel | null;
  onSubmit(): void;
  mode: 'edit' | 'create' | 'embedded';
}

@observer
class DispatcherDrawer extends Component<ComponentProps, ComponentState> {
  formRef: FormInstance;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      loadingEdit: false,
      selectedReport: null,
      isEditReport: false,
      isCreateReport: false,
      isBusyConfirm: false,
      selectedInfogroupId: null,
      selected: [],
      search: null,
      selectedDatasource: null,
    };
  }

  componentDidMount() {
    if (!companyCatalogStore.companyCatalogs || (!companyCatalogStore.companyCatalogs.length && userStore.userData.companyId)) {
      companyCatalogStore.getCompanyCatalogs(userStore.userData.companyId).then();
    }
  }

  addHandle = (field) => {
    let s = this.state.selected;
    s.push(field);
    this.setState({ selected: s });
  };

  removeHandle = (field) => {
    let s = this.state.selected;
    s.splice(s.indexOf(field), 1);
    this.setState({ selected: s });
  };

  resetHandle = () => {
    this.setState({ selected: [] });
  };

  onChangeDatasource = (e) => {
    const find = dispatcherStore.infoblocksList.find((i) => i.id === e);
    this.setState({ search: null, selectedDatasource: find });
  };

  handleInfogroupChange = (event) => {
    this.setState({ selectedInfogroupId: event.target.value, isBusy: true });
    dispatcherStore.loadInfoBlocksList(event.target.value).then(() => {
      this.setState({ selected: dispatcherStore.defaultSelected, isBusy: false, selectedDatasource: dispatcherStore.infoblocksList[0] });
    });
  };

  render() {
    const selectedInfoGroupId = this.state.selectedInfogroupId;
    const reportList =
      this.props.data && this.isEditMode
        ? { name: this.props.data.name, groupId: this.props.data.groupId, catalogs: this.props.data.catalogs }
        : null;
    const isBusy = this.state.isBusy || this.state.loadingEdit;
    const isEditReport = this.state.isEditReport;
    const isCreateReport = this.state.isCreateReport;
    const selectedReport = this.props.data;
    const loadingEdit = this.state.loadingEdit;
    const mode = this.props.mode;
    const infoGroupList = dispatcherStore.infogroupList;
    const dataSource = dispatcherStore.infoblocksList;
    const selected = this.state.selected;
    const search = this.state.search;
    const catalogs = companyCatalogStore.companyCatalogs;
    const isMobile = designStore.isMobile;

    return (
      <>
        {mode == 'create' && (
          <Button icon={<PlusOutlined />} onClick={this.handleCreateReport.bind(this)} size={'small'} type={'link'}>
            Создать
          </Button>
        )}
        {mode == 'edit' && (
          <Button
            icon={<EditOutlined className={'dispatcher-icon-gray'} />}
            title={'изменить отчет'}
            size={'small'}
            type="text"
            className={'dx-button-mode-contained dx-button'}
            disabled={!selectedReport}
            loading={loadingEdit}
            onClick={this.handleEditReport.bind(this)}
          />
        )}
        <Drawer
          className={'dispacher-drawer'}
          title={this.isEditMode ? 'Редактирование' : 'Создание'}
          width={designStore.isMobile ? '100%' : '85%'}
          onClose={this.handelClose}
          visible={isEditReport || isCreateReport}
          footer={
            <table style={{ width: '100%' }}>
              {isEditReport && (
                <td>
                  <Popconfirm
                    title={'Все наименования полей отчета будут установлены в значение “по умолчанию”. Это действие необратимо'}
                    onConfirm={this.handleResetFields}>
                    <Button icon={<RedoOutlined />} title={'сбросить названия полей'} />
                  </Popconfirm>
                </td>
              )}
              <td style={{ textAlign: 'right' }}>
                <Button
                  onClick={() => this.formRef.submit()}
                  loading={isBusy}
                  key={'submit_btn'}
                  type="primary"
                  //htmlType="submit"
                  form={'edit-report'}>
                  Сохранить
                </Button>
              </td>
            </table>
          }>
          <AdaptiveCard>
            <Spin spinning={isBusy}>
              <Form
                className="dispatcher-form"
                layout="vertical"
                ref={(ref) => (this.formRef = ref)}
                hideRequiredMark
                size={'large'}
                name={'edit-report'}
                //labelCol={{ span: 0 }}
                initialValues={{ ...reportList }}
                onFinish={this.handleConfirm}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ margin: '0' }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      name="groupId"
                      label="Вид отчета"
                      rules={[{ required: true, message: '' }]}
                      style={{ paddingBottom: '1rem', paddingTop: isMobile ? undefined : 36 }}>
                      <Radio.Group
                        className={'dispatcher-radio-group'}
                        options={infoGroupList.map((i) => {
                          return { label: i.description, value: i.id };
                        })}
                        onChange={this.handleInfogroupChange}
                        optionType="button"
                        buttonStyle="solid"
                        style={{ marginTop: 22, gap: isMobile ? 20 : 43 }}
                      />
                    </Form.Item>
                    <div style={{ paddingBottom: '1rem' }}>
                      <span className="chapter">Добавить поля</span>
                    </div>
                    <div style={{ paddingBottom: '1rem' }}>
                      <Select
                        value={this.state.selectedDatasource ? this.state.selectedDatasource.id : null}
                        onChange={this.onChangeDatasource}
                        getPopupContainer={(trigger) => trigger.parentElement}>
                        {dataSource.map((value, index) => {
                          const disabled =
                            ([6, 4, 7, 5].includes(value.id) &&
                              !!selected.find((s) => [6, 4, 7, 5].includes(s.infoBlockId) && s.infoBlockId != value.id)) ||
                            ([10, 11].includes(value.id) &&
                              !!selected.find((s) => [10, 11].includes(s.infoBlockId) && s.infoBlockId != value.id));
                          return (
                            <Select.Option value={value.id} key={index} disabled={disabled}>
                              {value.description}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </div>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ margin: '0' }}>
                      <Col className={'dispatcher-field'} span={24} style={{ padding: '0' }}>
                        <div className={'dispatcher-search-wrapper'}>
                          <Search
                            className="dispatcher-search"
                            placeholder="поиск"
                            onChange={(value) => this.setState({ search: value.target.value })}
                            size={'middle'}
                            //enterButton
                          />
                        </div>
                        <div className="dispatcher-fields">
                          {this.state.selectedDatasource &&
                            this.state.selectedDatasource.fields
                              .filter((f) => {
                                return (
                                  !selected.find((s) => s.id == f.id) &&
                                  (!search || (f.description + f.abbreviation).toLowerCase().includes(search.toLowerCase()))
                                );
                              })
                              .map((field, index2) => {
                                return (
                                  <Row key={index2}>
                                    <Col span={24}>
                                      <Button
                                        onClick={() => this.addHandle(field)}
                                        type="text"
                                        shape="circle"
                                        icon={<PlusCircleOutlined className={'dispatcher-icon dispatcher-icon-blue'} />}
                                      />
                                      <span>
                                        {field.description}({field.abbreviation})
                                      </span>
                                    </Col>
                                  </Row>
                                );
                              })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={24} style={{ padding: '2rem', backgroundColor: '#F1F2F6', borderRadius: '12px' }}>
                        <div style={{ paddingBottom: '1rem' }}>
                          <span className={'chapter'} style={{ fontSize: 20 }}>
                            Новый отчет
                          </span>
                        </div>
                        <div style={{ paddingBottom: 4 }}>
                          <span className={'dispatcher-chapter'}>Название</span>
                        </div>
                        <Form.Item name="name" label="" rules={[{ required: true, message: '' }]} style={{ paddingBottom: '1rem' }}>
                          <Input className={'dispatcher-search-input'} placeholder="Название" />
                        </Form.Item>
                        <div style={{ paddingBottom: 4 }}>
                          <span className={'dispatcher-chapter'}>Подразделения</span>
                        </div>
                        <Form.Item name="catalogs" label="" style={{ paddingBottom: '1rem' }}>
                          <Select mode={'multiple'}>
                            {catalogs.map((c) => (
                              <Select.Option value={c.id} key={c.id}>
                                {c.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Row style={{ margin: 0 }} align={'middle'}>
                          <Col span={16}>
                            <div style={{ paddingBottom: 4 }}>
                              <span className={'dispatcher-chapter'}>Выбранные поля</span>
                            </div>
                          </Col>
                          <Col span={8}>
                            <Button
                              style={{ display: 'block', padding: '0 0 0 6.4px', margin: '0 0 0 auto', height: 22, lineHeight: 0 }}
                              type="link"
                              onClick={this.resetHandle}>
                              Очистить <DeleteOutlined />
                            </Button>
                          </Col>
                        </Row>
                        <DraggableList
                          onChange={() => {}}
                          onRemove={(field) => this.removeHandle(field)}
                          editedReport={selectedReport}
                          dataSource={selected}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </AdaptiveCard>
        </Drawer>
      </>
    );
  }

  get isEditMode(): boolean {
    return this.state.isEditReport;
  }

  handleResetFields = () => {
    this.setState({ loadingEdit: true });
    dispatcherService.resetField(this.props.data.id).then(() => {
      this.handleEditReport();
    });
  };

  async handleEditReport() {
    this.resetData();
    this.setState({
      loadingEdit: true,
    });
    await dispatcherStore.loadInfoGroupList();
    if (this.props.data) {
      let ans = await dispatcherService.getReportListByID(this.props.data.id.toString());
      this.setState({ selected: ans.fields as InfoBlockItemFields[], selectedInfogroupId: ans.groupId });
      await dispatcherStore.loadInfoBlocksList(ans.groupId).then(() => {
        this.setState({ selectedDatasource: dispatcherStore.infoblocksList[0] });
      });
    } else {
      this.setState({ selected: dispatcherStore.defaultSelected });
    }
    this.setState(
      {
        isEditReport: true,
        loadingEdit: false,
      },
      () => {
        if (this.formRef) this.formRef.resetFields();
      },
    );
  }

  resetData() {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ selectedInfogroupId: null });
    dispatcherStore.clearInfoblock();
  }
  handleCreateReport = () => {
    this.setState({
      isCreateReport: true,
    });
    this.resetData();
  };

  handelClose = () => {
    this.setState({
      isEditReport: false,
      isCreateReport: false,
    });
  };

  handleConfirm = (event) => {
    const data = Object.assign({
      ...event,
    });

    data.fields = this.state.selected.map((f) => f.id);

    this.setState({
      isBusy: true,
    });

    if (this.isEditMode) {
      data.id = this.props.data.id;
      dispatcherStore
        .saveReportList(data)
        .finally(() => this.setState({ isBusy: false }))
        .then(() => {
          this.props.onSubmit();
          this.handelClose();
          showSuccessNotify('Отчет сохранен');
        });
    } else {
      dispatcherStore
        .createReportList(data)
        .finally(() => this.setState({ isBusy: false }))
        .then((result) => {
          this.props.onSubmit();
          this.handelClose();
          showSuccessNotify('Отчет создан');
        });
    }
  };
}

export default DispatcherDrawer;
