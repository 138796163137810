import { Button, Spin } from 'antd';
import DataGrid, { Column, GroupPanel, HeaderFilter, Scrolling, Selection } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import requestIlsStoreService from '@stores/requestIlsStore/requestIlsStore.service';
import { ClaimReport } from '@stores/requestIlsStore/requestIlsStoreData';
import userStore from '@stores/userStore';

import { showSuccessNotify } from '@utils/notify';

interface State {
  loading: boolean;
  selected: number[];
}

interface Props {
  ilsClaim: ClaimReport;
  onChange(): void;
  disabled: boolean;
}

@observer
class IlsClaimWagonLinked extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      selected: [],
    };
  }

  onSelectionChanged = (e) => {
    this.setState({ selected: e.selectedRowsData.filter((key) => key.state == 'added').map((key) => Number(key.wagonNumber)) });
  };

  onSetWagons = () => {
    this.setState({ loading: true });
    requestIlsStoreService
      .removeWagons(this.props.ilsClaim.ilsClaimId, this.state.selected)
      .then(() => {
        showSuccessNotify('Вагоны отвязаны');
        this.props.onChange();
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const isOrg = userStore.isOrg;
    return (
      <>
        {!isOrg && (
          <Button style={{ marginTop: 20 }} disabled={!this.state.selected.length || this.props.disabled} onClick={this.onSetWagons}>
            Отвязать вагоны от заявки
          </Button>
        )}

        <Spin spinning={this.state.loading}>
          <DataGrid
            keyExpr={'wagonNumber'}
            allowColumnReordering={true}
            dataSource={this.props.ilsClaim.wagons}
            allowColumnResizing={true}
            showBorders={true}
            columnResizingMode={'widget'}
            columnAutoWidth={true}
            showColumnLines={true}
            columnMinWidth={10}
            showRowLines={true}
            selectedRowKeys={this.state.selected}
            remoteOperations={false}
            onSelectionChanged={(e) => this.onSelectionChanged(e)}>
            <Selection mode="multiple" allowSelectAll={false} selectAllMode="allPages" />
            <GroupPanel visible={true} />

            <HeaderFilter visible={true} allowSearch={true} />
            <Scrolling mode="virtual" />
            <Column
              allowFixing={true}
              dataField="state"
              caption={'Статус вагона'}
              cellRender={(e) => {
                switch (e.value) {
                  //'Added' | 'Toload' | 'Loaded' | 'Onway' | 'Unloading' | 'Complete'
                  case 'toload':
                    return 'Заадресован';
                  case 'loaded':
                    return 'Погружен';
                  case 'onway':
                    return 'В пути';
                  case 'unloading':
                    return 'Разгрузка';
                  case 'complete':
                    return 'Рейс завершен';
                  default:
                    return '';
                }
              }}
            />
            <Column allowFixing={true} dataField="wagonNumber" caption={'№ вагона'} />
            <Column allowFixing={true} dataField="additionalProp" caption={'Дополнительная характеристика'} />
            <Column allowFixing={true} dataField="wagonModel" caption={'Модель'} />
            <Column allowFixing={true} dataField="volume" caption={'Объем кузова (м3)'} />
            <Column allowFixing={true} dataField="lifting" caption={'Грузоподъемность (т)'} />
            <Column allowFixing={true} dataField="daysBeforeRepair" caption={'Дней до планового ремонта'} />
            <Column allowFixing={true} dataField="dislStationName" caption={'Станция дислокации'} />
            <Column allowFixing={true} dataField="destStationName" caption={'Станция назначения'} />
            <Column allowFixing={true} dataField="isLoaded" caption={'Признак гружености'} />
            <Column allowFixing={true} dataField="distance" caption={'Расстояние до станции назначения'} />
          </DataGrid>
        </Spin>
      </>
    );
  }
}

export default IlsClaimWagonLinked;
