import HttpClient from '@utils/httpClient';

import { TemplateOrgContractModel } from '../organizationsStore/organizationsStoreData';
import { ClaimContractInfoServiceModel, ClaimEmailMessageParams, ClaimModel, CommercialOfferVariant } from './ClaimData';

const service = {
  async getClaimDocuments(claim: ClaimModel): Promise<TemplateOrgContractModel[]> {
    return (await HttpClient.post(`/api/claimDocument`, claim)).data;
  },

  async getClaimDocumentsById(claimId: string): Promise<TemplateOrgContractModel[]> {
    return (await HttpClient.post(`/api/claimDocument`, null, { params: { claimId } })).data;
  },

  /// Создать коммерческое предложение
  async createOfferDocument(claimId: string, offerTemplateId: string, variant: CommercialOfferVariant): Promise<void> {
    return (await HttpClient.post(`/api/claimDocument/offer`, null, { params: { claimId, offerTemplateId, variant } })).data;
  },

  /// Данные для создания допника по КП
  async createDs(claimId: string): Promise<TemplateOrgContractModel> {
    const data: TemplateOrgContractModel = (await HttpClient.get(`/api/claimDocument/ds`, { params: { claimId } })).data;
    data.docTypeId = null;
    return data;
  },

  /// Скачать коммерческое предложение
  async getOfferDocument(claimId: string, progressFunc: ((e: ProgressEvent) => void) | undefined = undefined): Promise<Blob> {
    return (
      await HttpClient.get(`/api/claimDocument/offer`, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
        params: { claimId },
      })
    ).data;
  },

  async getOfferByTemplateMetadataId(
    commercialOfferId: string,
    progressFunc: ((e: ProgressEvent) => void) | undefined = undefined,
  ): Promise<Blob> {
    return (
      await HttpClient.get(`/api/claimDocument/offerByTemplateMetadataId?templateMetadataId=${commercialOfferId}`, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
        params: { commercialOfferId },
      })
    ).data;
  },

  /// Получение параметров отправки коммерческого предложения
  async getEmailParams(claimId: string): Promise<ClaimEmailMessageParams> {
    return (await HttpClient.get(`/api/claimDocument/offer/emailParams`, { params: { claimId } })).data;
  },

  /// Отправка коммерческого предложения
  async sendOfferDocument(claimId: string, emailParams: ClaimEmailMessageParams): Promise<string> {
    return (await HttpClient.post(`/api/claimDocument/offer/send`, emailParams, { params: { claimId } })).data;
  },

  /// Удалить коммерческое предложение
  async deleteOfferDocument(claimId: string): Promise<void> {
    return (await HttpClient.delete(`/api/claimDocument/offer`, { params: { claimId } })).data;
  },

  async changeOfferStatus(claimId: string, statusCode: string): Promise<void> {
    return (await HttpClient.post(`/api/claimDocument/offerstatus/${statusCode}`, null, { params: { claimId } })).data;
  },

  async getClaimContracts(claimId: string): Promise<ClaimContractInfoServiceModel> {
    return (await HttpClient.get(`/api/claimDocument/contracts`, { params: { claimId } })).data;
  },

  async updateClaimContracts(claimId: string, data: ClaimContractInfoServiceModel): Promise<void> {
    return (await HttpClient.put(`/api/claimDocument/contracts`, data, { params: { claimId } })).data;
  },
};

export default service;
