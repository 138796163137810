import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Tooltip } from 'antd';
import { Template } from 'devextreme-react/core/template';
import DataGrid, { Column, Export, FilterPanel, FilterRow, GroupPanel, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import OrganizationEditor from '@components/fw/Drawers/OrganizationEditor';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { OrganizationModel } from '@stores/organizationsStore';
import organizationsStore, { emptyOrganization } from '@stores/organizationsStore/organizationsStore';
import userStore from '@stores/userStore/userStore';

interface OrganizationsListState {
  editedOrganization: OrganizationModel;
}

@observer
class OrganizationsList extends Component<RouteComponentProps, OrganizationsListState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      editedOrganization: null,
    };
  }

  componentDidMount(): void {
    organizationsStore.getOrganizations();
    document.title = 'Организации';
  }

  render() {
    const organizations = organizationsStore.organizations;
    const editedOrganization = this.state.editedOrganization;
    const isAdmin = userStore.isAdmin;

    return (
      <ScrolledContainer style={{ height: 'calc(100vh - var(--header-height))' }}>
        <AdaptiveCard>
          <Spin spinning={organizationsStore.loadingOrganizations}>
            <DataGrid
              allowColumnReordering={true}
              dataSource={organizations}
              height={'calc(100vh - 128px)'}
              rowAlternationEnabled={true}
              remoteOperations={false}
              showRowLines={true}
              showBorders={true}
              allowColumnResizing={true}
              columnResizingMode={'widget'}
              columnAutoWidth={true}
              columnMinWidth={20}
              onToolbarPreparing={this.onToolbarPreparing}
              wordWrapEnabled={true}>
              <Paging enabled={true} defaultPageSize={50} />
              <Pager
                allowedPageSizes={[25, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
                infoText={'Всего: {2}'}
              />
              <GroupPanel visible={true} emptyPanelText={'Поле группировки'} />
              <HeaderFilter visible={true} allowSearch={true} />
              <Export enabled={true} excelWrapTextEnabled={true} />
              <FilterRow visible={true} />
              <FilterPanel visible={true} />
              {INDEX_COLUMN}
              <Column
                allowFixing={true}
                dataField="name"
                caption={'Название компании'}
                cellRender={(e) => {
                  if (e.rowType == 'data') {
                    return (
                      <span>
                        <span style={{ float: 'left' }}>{e.data.name}</span>
                        <span style={{ float: 'right' }}>
                          <Tooltip title={'Редактировать'}>
                            <EditOutlined onClick={() => this.handleEdit(e.data)} />
                          </Tooltip>
                          <Tooltip title={'Удалить'}>
                            <Popconfirm title="Удалить？" okText="Да" cancelText="Нет" onConfirm={() => this.handleRemoveConfirm(e.data)}>
                              <DeleteOutlined disabled={true} style={{ marginLeft: 5 }} />
                            </Popconfirm>
                          </Tooltip>
                        </span>
                      </span>
                    );
                  }
                }}
              />
              <Column allowFixing={true} dataField="organizationTypeName" caption={'Тип'} dataType="string" />
              <Column allowFixing={true} dataField="signer" caption={'ФИО подписанта'} dataType="string" />
              <Column allowFixing={true} dataField="signerPost" caption={'Должность'} dataType="string" />
              <Column allowFixing={true} dataField="companyPhone" caption={'Телефон'} dataType="string" />
              <Column allowFixing={true} dataField="companyEmail" caption={'email'} dataType="string" />
              {isAdmin && (
                <Column allowFixing={true} dataField="companyName" caption={'Компания'} dataType="string" defaultGroupIndex={0} />
              )}
              <Template name={'create'} render={() => this.createTemplate()} />
            </DataGrid>
          </Spin>
        </AdaptiveCard>
        {editedOrganization ? (
          <OrganizationEditor
            organizationId={editedOrganization.organizationId}
            onCancel={this.handleOrganizationEditorCancel}
            onConfirm={this.handleOrganizationEditorConfirm}
          />
        ) : null}
      </ScrolledContainer>
    );
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'create',
    });
  };

  createTemplate = () => {
    return (
      <Button icon={<PlusOutlined />} size={'small'} onClick={this.handleCreate}>
        Создать
      </Button>
    );
  };

  handleCreate = () => {
    this.setState({
      editedOrganization: emptyOrganization,
    });
  };

  handleEdit = (organization: OrganizationModel) => {
    this.setState({ editedOrganization: organization });
  };

  handleRemoveConfirm = (organization: OrganizationModel) => {
    organizationsStore.remove(organization.organizationId);
  };

  handleOrganizationEditorCancel = () => {
    this.setState({ editedOrganization: null });
  };

  handleOrganizationEditorConfirm = (organization: OrganizationModel) => {
    if (organization.organizationId === null) {
      organizationsStore.create(organization);
    } else {
      organizationsStore.update(organization);
    }
    this.setState({ editedOrganization: null });
  };
}

export default OrganizationsList;
