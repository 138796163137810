import { Button } from 'antd';
import React from 'react';

import { Flex } from '@components/fw/Flex';

import userStore from '@stores/userStore/userStore';

import { ExtraActionsTabsProps as Props } from './extra-actions-tabs.types';

export const ExtraActionsTabs = ({ onCancel, onSuccess, isLoading }: Props) => {
  if (!userStore.userData) return null;

  return (
    <Flex gap="10px">
      <Button onClick={onCancel} disabled={isLoading}>
        Сброс
      </Button>
      <Button onClick={onSuccess} type="primary" loading={isLoading}>
        Сохранить
      </Button>
    </Flex>
  );
};
