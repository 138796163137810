import { DeleteOutlined, DollarOutlined } from '@ant-design/icons';
import { Button, List, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';
import tariffInfologStore from '@stores/tariffInfologStore/tariffInfologStore';

import { showSuccessNotify } from '@utils/notify';

import AdminBillingTariffEditor from './AdminBillingTariffEditor';

interface AdminBillingTariffsProps {}
interface AdminBillingTariffsState {}

@observer
class AdminBillingTariffs extends Component<AdminBillingTariffsProps, AdminBillingTariffsState> {
  constructor(props: AdminBillingTariffsProps) {
    super(props);
  }

  componentDidMount() {
    tariffInfologStore.getTariffs();
  }

  componentWillUnmount() {
    tariffInfologStore.clearTariffs();
  }

  render() {
    const isBusy = tariffInfologStore.isBusy;
    const data = tariffInfologStore.tariffs;
    const isMobile = designStore.isMobile;
    const padding = isMobile ? '8.7rem' : '12.2rem';
    return (
      <div style={{ height: `calc(100vh - ${padding})` }}>
        <AdminBillingTariffEditor
          data={{
            name: null,
            description: null,
            infoBlockIds: null,
            sourceCountries: null,
            id: null,
            operativeRequestRate: null,
            userAccessList: null,
            amount: null,
          }}
          id={null}
        />
        <List
          loading={isBusy}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              actions={[
                <AdminBillingTariffEditor data={item.billingSettings} key={'edit-' + item.id} id={item.id} />,
                <Popconfirm title="Удалить？" okText="Да" cancelText="Нет" onConfirm={() => this.handleDelete(item)}>
                  <Button size={'small'} type={'text'} icon={<DeleteOutlined />} />
                </Popconfirm>,
              ]}>
              <List.Item.Meta avatar={<DollarOutlined />} title={item.name} description={item.description} />
            </List.Item>
          )}
        />
      </div>
    );
  }

  handleDelete = (item) => {
    tariffInfologStore
      .deleteTariff(item.id)
      .then(() => showSuccessNotify('', 'Тариф удален'))
      .then((res) => tariffInfologStore.getTariffs());
  };
}

export default AdminBillingTariffs;
