import { observer } from 'mobx-react';
import React from 'react';

import { FieldFloatSelect } from '@components/fw/Fields/float-select';
import { Flex } from '@components/fw/Flex';

import dictStore from '@stores/dictStore';

import { getTariffOptions } from './basic-tab-tariff-fields.lib';
import { BasicTabTariffFieldsProps } from './basic-tab-tariff-fields.types';

export const BasicTabTariffFields = observer(({ isShow }: BasicTabTariffFieldsProps) => {
  if (!isShow) {
    return null;
  }

  return (
    <>
      <h4>Оплата тарифа</h4>
      <Flex gap="32px">
        <FieldFloatSelect
          options={getTariffOptions(dictStore.tariffIcDictionary)}
          name="loadTariffIcId"
          placeholder="Груженый тариф"
          allowClear
        />
        <FieldFloatSelect
          options={getTariffOptions(dictStore.tariffIcDictionary)}
          name="emptyTariffIcId"
          placeholder="Порожний подсыл"
          allowClear
        />
      </Flex>
    </>
  );
});
