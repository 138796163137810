import Icon, { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Popconfirm, Popover, Row, Select, Spin, Tag } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import CompanyCatalogEditor from '@components/fw/CompanyCatalogEditor';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import MarkEditor from '@components/fw/MarkEditor';

import companyCatalogStore from '@stores/companyCatalogStore/companyCatalogStore';
import companyStore from '@stores/companyStore/companyStore';
import designStore from '@stores/designStore/designStore';
import markStore from '@stores/markStore';
import Wagon, { WagonAddRequest } from '@stores/trackingStore/wagon/WagonTypes';
import trackingWagonStore from '@stores/trackingStore/wagon/trackingWagonStore';
import userStore from '@stores/userStore/userStore';

import { showSuccessNotify } from '@utils/notify';

interface WagonAddProps {
  onDataReady?: () => void;
  size?: 'regular' | 'small' | 'hidden';
}

interface WagonAddState {
  visible: boolean;
  wagons: Wagon[];
  countryId: number;
  selectedBillingProfileId: string;
}

@observer
class WagonAdd extends Component<WagonAddProps, WagonAddState> {
  formRef: FormInstance;

  constructor(props: WagonAddProps) {
    super(props);

    this.state = {
      visible: false,
      wagons: [],
      countryId: null,
      selectedBillingProfileId: null,
    };
  }

  get hasWrong() {
    return this.state.wagons.find((w) => !w.isvalid);
  }

  componentDidMount(): void {}

  showModal = () => {
    this.setState({ visible: true, wagons: [] });
    if (userStore.userData.roleCode != 'admin') {
      companyCatalogStore.getCompanyCatalogs(userStore.userData.companyId);
      markStore.getNotes(userStore.userData.companyId);
      companyStore.getCountries('wagon');
    }
  };

  handleOk = (e) => {
    this.formRef.submit();
  };

  handleCancel = (e) => {
    this.setState({
      wagons: [],
      visible: false,
    });
  };

  onChangeCatalogs = (e: string[]) => {
    const find = companyCatalogStore.companyCatalogs.find((c) => e.includes(c.id) && !!c.billingProfileId);
    if (find && find.billingProfileId != this.state.selectedBillingProfileId) {
      this.setState({ selectedBillingProfileId: find.billingProfileId });
      companyStore.getCountries('wagon', find.billingProfileId).then(() => {
        this.formRef.setFieldsValue({ countryId: null });
      });
    }
    if (!find && this.state.selectedBillingProfileId != null) {
      this.setState({ selectedBillingProfileId: null });
      companyStore.getCountries('wagon').then(() => {
        this.formRef.setFieldsValue({ countryId: null });
      });
    }
  };

  handleTexAreaChange = (event) => {
    event.persist();

    const wagons: Wagon[] = [];
    const existsWagons = this.state.wagons;

    const regex = /[0-9]{8}/g;
    const lines = event.target.value.match(regex);
    lines.forEach((l) => {
      if (l != '') {
        let wagon = existsWagons.find((w) => w.number === l);
        if (!wagon) {
          wagon = new Wagon(l);
          wagon.loading = true;
          trackingWagonStore
            .checkOnTrack(l)
            .then((ans) => {
              wagon.isontrack = ans;
            })
            .finally(() => {
              wagon.loading = false;
              this.forceUpdate();
            });
        }
        if (!wagons.find((w) => w.number === l)) {
          wagons.push(wagon);
        }
      }
    });
    this.setState({ wagons: wagons });
  };

  handleSubmit = (event: WagonAddRequest) => {
    if (trackingWagonStore.isBusyAction) return;
    event.numbers = this.state.wagons.map((w) => Number(w.number));
    trackingWagonStore.add(event).then(() => {
      this.setState({ visible: false });
      this.props.onDataReady();
      showSuccessNotify('Добавление вагонов', 'Выбранные вагоны добавлены');
    });
  };

  notesRender = (group: number) => {
    const notes = markStore.notes;
    const tagRender = (props) => {
      const { label, value, closable, onClose } = props;
      const note = notes.find((n) => n.id === value);
      if (!note) return null;
      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };
      return (
        <Tag
          color={note.description}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3, marginTop: 10 }}>
          {note.title}
        </Tag>
      );
    };
    return (
      <Row gutter={16}>
        <Col xs={group === 1 ? 20 : 24}>
          <Form.Item name={'notes' + group} style={{ height: 52 }}>
            <FloatSelect
              mode={'multiple'}
              placeholder={'Метки' + group}
              allowClear
              optionFilterProp="children"
              tagRender={tagRender}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toString().toLowerCase())
              }
              filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}>
              {notes.map((item) => {
                return (
                  <Select.Option value={item.id} label={item.title} key={item.id}>
                    <Tag color={item.description} style={{ cursor: 'pointer' }}>
                      {item.title}
                    </Tag>
                  </Select.Option>
                );
              })}
            </FloatSelect>
          </Form.Item>
        </Col>
        {group === 1 && (
          <Col xs={4}>
            <Popover
              trigger={'click'}
              overlayStyle={{ minWidth: '30rem' }}
              content={<MarkEditor companyId={userStore.userData.companyId} />}>
              <Button size={'large'} icon={<EditOutlined />} />
            </Popover>
          </Col>
        )}
      </Row>
    );
  };

  render() {
    const isBusy = trackingWagonStore.isBusyAction;
    const wagons = this.state.wagons;
    const catalogs = companyCatalogStore.companyCatalogs;
    const countries = companyStore.countries;
    const hasWrong = this.hasWrong;
    const size = this.props.size ? this.props.size : 'regular';
    const isSmall = size == 'small';
    const isHidden = size == 'hidden';
    if (!userStore.canAccess('billing/orders/wagons/edit')) return null;
    return (
      <>
        {!isHidden && (
          <Button
            onClick={this.showModal}
            icon={<PlusOutlined />}
            size={'small'}
            type={isSmall ? 'text' : 'link'}
            className={isSmall ? 'dx-button-mode-contained dx-button' : undefined}
            title={'добавить на слежение'}>
            {!isSmall && <>добавить</>}
          </Button>
        )}
        <Modal
          width={designStore.isMobile ? '100%' : '50%'}
          title="Постановка вагонов на слежение"
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Отмена
            </Button>,
            <>
              {hasWrong && (
                <Popconfirm
                  title={
                    <>
                      <div>Имеются невалидные номера.Продолжить?</div>
                      {wagons
                        .filter((i) => !i.isvalid)
                        .map((i) => (
                          <div style={{ color: 'red' }}>{i.number}</div>
                        ))}
                    </>
                  }
                  onConfirm={this.handleOk}>
                  <Button key="submit" type="primary">
                    Добавить
                  </Button>
                </Popconfirm>
              )}
              {!hasWrong && (
                <Button key="submit" type="primary" onClick={this.handleOk}>
                  Добавить
                </Button>
              )}{' '}
            </>,
          ]}>
          <Spin spinning={isBusy}>
            <Form layout={'vertical'} ref={(ref) => (this.formRef = ref)} onFinish={this.handleSubmit} hideRequiredMark={true}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} md={12}>
                  <Form.Item label="Введите список номеров:" name="list" rules={[{ required: true, message: 'Укажите вагоны' }]}>
                    <Input.TextArea rows={4} onChange={this.handleTexAreaChange} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  {wagons.map((value, index) => {
                    return (
                      <div key={index} style={!value.isvalid ? { color: 'red' } : value.isontrack ? { color: 'green' } : {}}>
                        {value.number}
                        {value.isontrack && (
                          <Popover
                            style={{ width: '200px' }}
                            trigger="hover"
                            content={
                              <div style={{ width: '200px' }}>
                                <b>{value.number}</b> уже добавлен. Изменений по нему не будет
                              </div>
                            }>
                            <Icon component={iconQuestion} style={{ cursor: 'pointer' }} />
                          </Popover>
                        )}
                      </div>
                    );
                  })}
                </Col>
                <Col xs={24} md={12}>
                  <Row gutter={16}>
                    <Col xs={20}>
                      <Form.Item name="catalogs">
                        <FloatSelect
                          mode={'multiple'}
                          placeholder="Подразделения"
                          allowClear
                          optionFilterProp="children"
                          onChange={this.onChangeCatalogs}>
                          {catalogs.map((item) => {
                            return (
                              <Select.Option value={item.id} key={item.id}>
                                {item.title}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={4}>
                      <Popover
                        trigger={'click'}
                        overlayStyle={{ minWidth: '30rem' }}
                        content={<CompanyCatalogEditor companyId={userStore.userData.companyId} />}>
                        <Button size={'large'} icon={<EditOutlined />} />
                      </Popover>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="countryId" rules={[{ required: true, message: 'Укажите страну дислокации' }]}>
                        <FloatSelect placeholder="Страна">
                          {countries.map((item) => {
                            return (
                              <Select.Option value={item.code} key={item.code}>
                                {item.fullName}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="trackingDayCount">
                        <FloatInputNumber placeholder="Убрать со слежения через (дней)" max={720} min={1} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={12}>
                  <Row gutter={16}>
                    <Col xs={24}>{this.notesRender(1)}</Col>
                    <Col xs={24}>{this.notesRender(2)}</Col>
                    <Col xs={24}>{this.notesRender(3)}</Col>
                  </Row>
                </Col>
                <Col xs={24} md={24}>
                  <Row gutter={16}>
                    <Col xs={24}>
                      <Form.Item name="remark1">
                        <FloatInput placeholder="Прим.1" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="remark2">
                        <FloatInput placeholder="Прим.2" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="remark3">
                        <FloatInput placeholder="Прим.3" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="remark4">
                        <FloatInput placeholder="Прим.4" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="remark5">
                        <FloatInput placeholder="Прим.5" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </>
    );
  }
}

export default WagonAdd;
