import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AccessTreeEditor from '@components/fw/AccessTreeEditor';
import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import CompanySourceEditorExt from '@components/fw/Drawers/CompanyEditor/CompanySourceEditorExt';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import designStore from '@stores/designStore';
import dictStore, { SourceModel } from '@stores/dictStore/dictStore';
import dictService from '@stores/dictStore/dictStore.service';
import { ICreateTariffInfologModel, ITariffInfologBillingSettingsModel } from '@stores/tariffInfologStore/tariffInfologData';
import tariffInfologStore from '@stores/tariffInfologStore/tariffInfologStore';
import userStore, { START_PAGES } from '@stores/userStore/userStore';

import { showSuccessNotify } from '@utils/notify';

interface AdminBillingTariffProps {
  data: ITariffInfologBillingSettingsModel;
  id: string;
}

interface AdminBillingTariffState {
  isBusy: boolean;
  visible: boolean;
  validateOnly: boolean;
  checkedRoleAccess: string[];
  countries: {
    src: SourceModel[];
    countryCode: number;
    countryName: string;
  }[];
}

@observer
class AdminBillingTariffEditor extends Component<AdminBillingTariffProps, AdminBillingTariffState> {
  constructor(props: AdminBillingTariffProps) {
    super(props);

    this.state = {
      isBusy: false,
      visible: false,
      validateOnly: false,
      checkedRoleAccess: this.props.data.userAccessList || [],
      countries: [],
    };
  }

  formRef: FormInstance;

  componentDidMount() {
    this.loadData();
    dictStore.loadInfoblocksDict();
  }

  async loadData() {
    this.setState({ isBusy: true });
    const countries: {
      [key: string]: {
        src: SourceModel[];
        countryCode: number;
        countryName: string;
      };
    } = {};
    try {
      const sources = await dictService.companySourcesDict();
      for (let s of sources) {
        const countryForSource = await dictService.countriesBySources(s.id);
        countryForSource.forEach((c) => {
          if (countries[c.countryName]) {
            countries[c.countryName].src.push(s);
          } else {
            countries[c.countryName] = {
              countryCode: c.countryCode,
              src: [s],
              countryName: c.countryName,
            };
          }
        });
      }

      const toState = Object.values(countries);
      toState.sort((a, b) => a.countryCode - b.countryCode);

      this.setState({ countries: toState });
    } finally {
      this.setState({ isBusy: false });
    }
  }

  render() {
    const isBusy = this.state.isBusy;
    const data = this.props.data;
    const visible = this.state.visible;
    const roleAccess = userStore.userAccessTree;
    const checkedRoleAccess = this.state.checkedRoleAccess;

    return (
      <>
        {this.isEditMode ? (
          <Button onClick={this.openDrawer} icon={<EditOutlined />} title={'Изменить'} size={'small'} type={'text'} />
        ) : (
          <Button onClick={this.openDrawer} icon={<PlusOutlined />} title={'Создать'} type={'text'} size={'small'}>
            создать
          </Button>
        )}
        <AdaptiveDrawer
          width={designStore.isMobile ? '100%' : '85%'}
          title={this.isEditMode ? 'Редактирование' : 'Создание '}
          onClose={this.handelClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={[
            <Button onClick={this.handelClose} key={'cancel'}>
              Отмена
            </Button>,
            <Button type="primary" key={'submit'} loading={isBusy} htmlType={'submit'} form={'tariffForm'}>
              {this.isEditMode ? 'Сохранить' : 'Добавить'}
            </Button>,
          ]}>
          <Form name={'tariffForm'} ref={(ref) => (this.formRef = ref)} onFinish={this.handleConfirm} onFinishFailed={() => {}}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: '1rem' }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item initialValue={data.name} name="name" label="" rules={[{ required: true, message: 'Укажите название тарифа' }]}>
                  <FloatInput className={'dispatcher-search-input'} placeholder="Название тарифа" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="description" label="" initialValue={data.description}>
                  <FloatInput placeholder="Описание" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="amount"
                  label=""
                  initialValue={data.amount}
                  rules={[{ required: true, message: 'Укажите сумму договора' }]}>
                  <FloatInputNumber max={9999999} min={100} step={0.01} placeholder="Сумма договора" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={24}>
                    <Form.Item
                      initialValue={data.operativeRequestRate}
                      name="operativeRequestRate"
                      rules={[{ required: true, message: 'Укажите цифру или введите 0' }]}>
                      <FloatInputNumber decimalSeparator={'.'} min={0} step={0.1} placeholder="Стоимость справки" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={24}>
                    <Form.Item initialValue={data.sourceCountries} name="sourceCountries">
                      <CompanySourceEditorExt countries={this.state.countries} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={24}>
                    <Form.Item initialValue={data.infoBlockIds ? data.infoBlockIds : []} name="infoBlockIds">
                      <FloatSelect mode={'multiple'} placeholder="Инфоблоки" allowClear optionFilterProp="children">
                        {dictStore.companyInfoblocksDictionary &&
                          dictStore.companyInfoblocksDictionary.map((item, index) => {
                            return (
                              <Select.Option value={item.id} key={`infoblock${index}`}>
                                {item.description}
                              </Select.Option>
                            );
                          })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <h4>Права</h4>
                    {!!roleAccess && <AccessTreeEditor tree={roleAccess} checked={checkedRoleAccess} onChange={this.handleTreeNodeCheck} />}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </AdaptiveDrawer>
      </>
    );
  }

  get isEditMode(): boolean {
    return this.props.data && this.props.id !== null;
  }

  openDrawer = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: true });
  };

  handelClose = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: false });
  };

  handleTreeNodeCheck = (checkedKeys: string[]) => {
    const result = [...checkedKeys];
    if (this.formRef) {
      const sp = this.formRef.getFieldValue('startPage');
      if (sp) {
        const find = START_PAGES.find((p) => p.path == sp);
        if (find && !result.find((r) => r.includes(find.rule))) {
          setTimeout(() => this.formRef.resetFields(['startPage']), 100);
        }
      }
    }
    this.setState({ checkedRoleAccess: result });
  };

  handleConfirm = (e) => {
    const data: ICreateTariffInfologModel = { ...e, userAccessList: this.state.checkedRoleAccess };
    this.isEditMode
      ? tariffInfologStore
          .updateTariff(this.props.id, data)
          .then(() => showSuccessNotify('', 'Тариф обновлен'))
          .then(() => this.setState({ visible: false }))
          .then(() => tariffInfologStore.getTariffs())
      : tariffInfologStore
          .createTariff(data)
          .then(() => showSuccessNotify('', 'Тариф создан'))
          .then(() => this.setState({ visible: false }))
          .then(() => tariffInfologStore.getTariffs());
  };
}

export default AdminBillingTariffEditor;
