import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Modal, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { Component } from 'react';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';

import { TariffRequestWagonGroupDetails } from '@stores/historyTariffInfo/historyTariffInfoStore';

interface State {
  visible: boolean;
  enableThisFeature: boolean;
}

interface Props {
  disabled?: boolean;
  value?: TariffRequestWagonGroupDetails[];
  onChange?(e: TariffRequestWagonGroupDetails[]): void;
}

class OptionsGroupEditor extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      enableThisFeature: false,
    };
  }

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    this.setState({ enableThisFeature: !!nextProps.value && !!nextProps.value.length });
  }

  componentDidMount() {
    this.setState({ enableThisFeature: !!this.props.value && !!this.props.value.length });
  }

  onOpen() {
    this.setState({ visible: true, enableThisFeature: !!this.props.value && !!this.props.value.length });
  }

  onEnableChange(e: boolean) {
    if (e) {
      this.setState({ enableThisFeature: true }, () => {
        if (this.formRef) {
          this.formRef.resetFields();
        }
      });
    } else {
      this.setState({ enableThisFeature: false });
    }
  }

  render() {
    const visible = this.state.visible;
    const enable = this.state.enableThisFeature;
    return (
      <>
        <Button
          disabled={this.props.disabled}
          icon={<EditOutlined />}
          size={'small'}
          type={enable ? 'primary' : 'text'}
          onClick={() => {
            this.setState({ visible: true });
          }}>
          Настройки для группы ПС
        </Button>
        <Modal open={visible} onOk={this.onConfirm} onCancel={this.onClose} title={'Настройки для группы ПС'} destroyOnClose={true}>
          <Checkbox style={{ marginTop: 14 }} checked={enable} onChange={(e) => this.onEnableChange(e.target.checked)}>
            Указать вес и грузоподъемность для каждого вагона
          </Checkbox>
          {enable && (
            <Form
              layout="horizontal"
              size={'large'}
              ref={(ref) => {
                this.formRef = ref;
              }}
              name={'group-details-form'}
              onFinish={(e) => this.onSubmit(e)}
              initialValues={{ data: this.props.value }}>
              <Form.List
                name="data"
                rules={[
                  {
                    validator: async (rule, value) => {
                      if (Array.isArray(value) && value.length && value.length > 1) {
                      } else {
                        return Promise.reject(new Error('Добавьте вагон'));
                      }
                    },
                  },
                ]}>
                {(fields, { add, remove }, err) => (
                  <>
                    <Form.ErrorList errors={err.errors} />
                    {fields.map((field, index) => (
                      <Row>
                        <Col xs={10}>
                          <Form.Item name={[field.name, 'freightWeight']} rules={[{ required: true, message: 'Укажите вес' }]}>
                            <FloatInputNumber min={1} max={120} step={0.01} placeholder={'Вес (т)'} />
                          </Form.Item>
                        </Col>
                        <Col xs={10}>
                          <Form.Item
                            name={[field.name, 'freightCapacity']}
                            rules={[{ required: true, message: 'Укажите грузоподъемность' }]}>
                            <FloatInputNumber min={1} max={120} step={0.01} placeholder={'Грузоподъемность (т)'} />
                          </Form.Item>
                        </Col>
                        <Col xs={4}>
                          <Button
                            icon={<DeleteOutlined />}
                            size={'small'}
                            type={'text'}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Form.Item style={{ marginTop: '10px' }}>
                      <Button className="blue-gray-button" onClick={() => add()} block icon={<PlusOutlined />}>
                        Добавить
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          )}
        </Modal>
      </>
    );
  }

  onConfirm = () => {
    if (this.state.enableThisFeature) {
      this.formRef.submit();
    } else {
      if (this.props.onChange) {
        this.props.onChange(null);
      }
      this.onClose();
    }
  };

  onClose = () => {
    this.setState({ visible: false, enableThisFeature: !!this.props.value && !!this.props.value.length });
  };

  onSubmit = (e: { data: TariffRequestWagonGroupDetails[] }) => {
    if (this.props.onChange) {
      this.props.onChange(e.data);
    }
    this.setState({ visible: false });
  };
}

export default OptionsGroupEditor;
