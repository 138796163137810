import { Checkbox, Modal } from 'antd';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

interface Props {}

interface State {
  visible: boolean;
  reject: boolean;
  updateExists: boolean;
}

const DISABLE_UPDATES_KEY = 'updates_disable';

class RefreshModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      reject: false,
      updateExists: false,
    };
  }
  registration: ServiceWorkerRegistration;
  interval: any;

  showModal() {
    const isUpdDisabled = localStorage.getItem(DISABLE_UPDATES_KEY);
    if (!this.state.reject && !isUpdDisabled) {
      this.setState({ updateExists: true });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      try {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register(`/app/service-worker.js`).then((registration) => {
            this.registration = registration;
            if (this.registration) {
              this.registration.addEventListener('updatefound', () => {
                this.showModal();
              });
              this.checkUpdates();
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    }, 60000); //время на загрузку начального контента
  }

  checkUpdates = () => {
    if (this.registration.installing) {
      return;
    }
    if (this.registration.waiting) {
      this.showModal();
    } else {
      this.interval = setInterval(() => {
        if (this.registration) this.registration.update();
      }, 60000); //как часто проверять обновления
    }
  };
  doRefresh = () => {
    this.registration.unregister().then(() => {
      window.location.reload();
    });
  };

  rejectRefresh = () => {
    this.setState({ reject: true, updateExists: false });
    clearInterval(this.interval);
  };

  render() {
    return (
      <Modal
        open={this.state.updateExists}
        width={designStore.isMobile ? '100%' : '400px'}
        centered={designStore.isMobile}
        okText={'Перезагрузить'}
        onCancel={this.rejectRefresh}
        onOk={this.doRefresh}>
        <br />
        На сайт добавлены важные обновления, влияющие на функционал. Если у Вас открыты другие вкладки на этом сайте, пожалуйтса, закройте
        их и перезагрузите страницу.
        <br />
        <Checkbox
          checked={!!localStorage.getItem(DISABLE_UPDATES_KEY)}
          onChange={(w) => {
            if (w.target.checked) {
              localStorage.setItem(DISABLE_UPDATES_KEY, 'true');
            } else {
              localStorage.removeItem(DISABLE_UPDATES_KEY);
            }
            this.forceUpdate();
          }}>
          Больше не показывать это окно
        </Checkbox>
      </Modal>
    );
  }
}

export default RefreshModal;
