import { Badge, Spin } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import { ClaimModel } from '@stores/claimsStore/ClaimData';
import claimsStore from '@stores/claimsStore/claimsStore';

import styles from './BoardView.module.scss';
import ClaimsList from './ClaimsList';

interface BoardViewProps {
  onEdit(row: ClaimModel): void;
  onDelete(row: ClaimModel): void;
}

interface State {
  selectedClaim: ClaimModel;
}

const CardTitle: React.FC<{ name: string; count: number; totalPlan: number; totalFact: number }> = (props) => {
  return (
    <>
      <Badge count={props.count}>
        <div style={{ paddingRight: 8 }}>{props.name}</div>
      </Badge>
      <div style={{ fontSize: 12 }}>Итого план: {props.totalPlan} ₽</div>
      <div style={{ fontSize: 12 }}>Итого факт: {props.totalFact} ₽</div>
    </>
  );
};

@observer
class BoardView extends Component<RouteComponentProps & BoardViewProps, State> {
  constructor(props: RouteComponentProps<{ history }> & BoardViewProps) {
    super(props);
    this.state = {
      selectedClaim: null,
    };
  }

  componentDidMount() {
    claimsStore.loadPlanForBoard();
  }

  render() {
    const { creat, onagr, agr, onper, done } = claimsStore.claimsForBoard;
    const isBusy = claimsStore.isBusy;
    const selectedClaim = this.state.selectedClaim;
    return (
      <ScrolledContainer style={{ maxHeight: 'calc(100vh - var(--padding))', overflowX: 'hidden' }}>
        <Spin spinning={isBusy}>
          <div className={styles.lists}>
            <AdaptiveCard
              size={'small'}
              title={
                <CardTitle
                  name={'Создана'}
                  count={creat.length}
                  totalPlan={this.countTotal(creat, 'plancost')}
                  totalFact={this.countTotal(creat, 'costfact')}
                />
              }
              bordered={false}
              className={styles.list}>
              <ClaimsList
                {...this.props}
                handleSelectionChanged={(item) => this.handleSelectionChanged(item)}
                selected={selectedClaim}
                list={creat}
              />
            </AdaptiveCard>
            <AdaptiveCard
              size={'small'}
              title={
                <CardTitle
                  name={'На согласовании'}
                  count={onagr.length}
                  totalPlan={this.countTotal(onagr, 'plancost')}
                  totalFact={this.countTotal(onagr, 'costfact')}
                />
              }
              bordered={false}
              className={styles.list}>
              <ClaimsList
                {...this.props}
                handleSelectionChanged={(item) => this.handleSelectionChanged(item)}
                selected={selectedClaim}
                list={onagr}
              />
            </AdaptiveCard>
            <AdaptiveCard
              size={'small'}
              title={
                <CardTitle
                  name={'Согласовано'}
                  count={agr.length}
                  totalPlan={this.countTotal(onagr, 'plancost')}
                  totalFact={this.countTotal(onagr, 'costfact')}
                />
              }
              bordered={false}
              className={styles.list}>
              <ClaimsList
                {...this.props}
                handleSelectionChanged={(item) => this.handleSelectionChanged(item)}
                selected={selectedClaim}
                list={agr}
              />
            </AdaptiveCard>
            <AdaptiveCard
              size={'small'}
              title={
                <CardTitle
                  name={'На исполнении'}
                  count={onper.length}
                  totalPlan={this.countTotal(onagr, 'plancost')}
                  totalFact={this.countTotal(onagr, 'costfact')}
                />
              }
              bordered={false}
              className={styles.list}>
              <ClaimsList
                {...this.props}
                handleSelectionChanged={(item) => this.handleSelectionChanged(item)}
                selected={selectedClaim}
                list={onper}
              />
            </AdaptiveCard>
            <AdaptiveCard
              size={'small'}
              title={
                <CardTitle
                  name={'Выполнено'}
                  count={done.length}
                  totalPlan={this.countTotal(onagr, 'plancost')}
                  totalFact={this.countTotal(onagr, 'costfact')}
                />
              }
              bordered={false}
              className={styles.list}>
              <ClaimsList
                {...this.props}
                handleSelectionChanged={(item) => this.handleSelectionChanged(item)}
                selected={selectedClaim}
                list={done}
              />
            </AdaptiveCard>
          </div>
        </Spin>
      </ScrolledContainer>
    );
  }

  countTotal(values: any[], propName: string) {
    const vals = values.filter((x) => x[propName]).map((x) => x[propName]);
    return _.sum(vals);
  }

  handleSelectionChanged = (item: ClaimModel) => {
    this.setState({
      selectedClaim: item,
    });
    return this.state.selectedClaim;
  };

  handleEdit = (item: ClaimModel) => {
    this.props.onEdit(item);
  };

  handleDelete = (item: ClaimModel) => {
    this.props.onDelete(item);
  };
}

export default BoardView;
