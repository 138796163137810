import { action, observable } from 'mobx';

import { CompanyCatalogModel } from '../companyCatalogStore';
import AbstractTrackingStoreService from './abstractTrackingStore.service';

export interface TrackingHistoryBase {
  /// Идентификатор
  id: number;
  /// Пользователь
  userName: string;
  /// Действие
  actionName: string;
  /// Компания
  companyName: string;
  /// Время
  dateTime: Date | string;
  number: string | number;
}

export interface TrackingActiveBase {
  /// Страна
  country: string;
  /// Дорога
  road: string;
  /// Добавил
  creator: string;
  /// Подразделения
  catalogs: CompanyCatalogModel[];
  /// Компания
  company: string;
  /// Время
  date: Date;
  number: string | number;
}

abstract class TrackingStore<
  A extends TrackingActiveBase,
  H extends TrackingHistoryBase,
  R1,
  R2,
  S extends AbstractTrackingStoreService<A, H, R1, R2>,
> {
  @observable isBusyActive: boolean = false;
  @observable isBusyHistory: boolean = false;
  @observable isBusyAction: boolean = false;
  abstract active: A[];
  abstract history: H[];
  abstract service: S;
  @observable selected: any[] = [];

  @action loadActive() {
    this.isBusyActive = true;
    this.service
      .getActiveList()
      .finally(() => (this.isBusyActive = false))
      .then((result: A[]) => {
        this.active = result;
      });
  }
  @action loadHistory() {
    this.isBusyHistory = true;
    this.service
      .getHistoryList()
      .finally(() => (this.isBusyHistory = false))
      .then((result: H[]) => {
        this.history = result;
      });
  }
  @action async add(data: R1) {
    this.isBusyAction = true;
    await this.service
      .add(data)
      .finally(() => (this.isBusyAction = false))
      .then(() => {});
  }

  @action async remove(data: R2) {
    this.isBusyAction = true;
    await this.service
      .remove(data)
      .finally(() => (this.isBusyAction = false))
      .then(() => {});
  }

  @action async checkOnTrack(number: string | number): Promise<boolean> {
    return await this.service.checkOnTrack(number);
  }

  @action clearSelected() {
    this.selected = [];
  }
}

export default TrackingStore;
