import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import OrganizationEditor from '@components/fw/Drawers/OrganizationEditor';
import ContractList from '@components/fw/Drawers/OrganizationEditor/ContractList/ContractList';

import { OrganizationModel } from '@stores/organizationsStore';
import organizationsStore, { emptyOrganization } from '@stores/organizationsStore/organizationsStore';

interface Props {
  companyId?: string;
  orgId?: string;
  contractId?: string;
}

interface State {
  isBusy: boolean;
  editedOrganization: OrganizationModel;
  visible: boolean;
  selectedOrgId: string;
}

class LeasingContractDrawer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: false,
      editedOrganization: null,
      visible: false,
      selectedOrgId: null,
    };
  }

  contractList: ContractList;

  loadContracts = (orgId: string) => {
    this.setState({ selectedOrgId: orgId });
    if (this.contractList) {
      this.contractList.getData(orgId).then();
    }
  };

  handleOrganizationEditorCancel = () => {
    this.setState({ editedOrganization: null });
  };

  handleOrganizationEditorConfirm = (organization: OrganizationModel) => {
    let promise;
    if (organization.organizationId === null) {
      promise = organizationsStore.create(organization);
    } else {
      promise = organizationsStore.update(organization);
    }
    promise.then(() => {
      organizationsStore.getUserOrganizations().then(() => {
        this.setState({ editedOrganization: null });
      });
    });
  };

  handleCreateOrg = () => {
    this.setState({ editedOrganization: emptyOrganization });
  };

  handleChangeOrg = () => {
    const org = organizationsStore.getContragentArray().find((c) => c.organizationId === this.state.selectedOrgId);
    this.setState({ editedOrganization: org });
  };

  onOpenDrawer = () => {
    this.setState({ visible: true, selectedOrgId: this.props.orgId });
    if (this.props.orgId) {
      this.loadContracts(this.props.orgId);
    }
  };

  render() {
    if (!this.props.companyId) return null;
    const contractorOrganizations = organizationsStore.getContragentArray();
    const editedOrganization = this.state.editedOrganization;
    const selectedOrgId = this.state.selectedOrgId;
    const isEdit = !!this.props.contractId;
    return (
      <>
        <AdaptiveDrawer title={'Договоры'} visible={this.state.visible} onClose={() => this.setState({ visible: false })} width={'50%'}>
          {editedOrganization ? (
            <OrganizationEditor
              organizationId={editedOrganization.organizationId}
              onCancel={this.handleOrganizationEditorCancel}
              onConfirm={this.handleOrganizationEditorConfirm}
            />
          ) : null}
          <Spin spinning={this.state.isBusy}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24}>
                <Divider>Организация контрагента</Divider>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24}>
                    <Select
                      size={'large'}
                      style={{ width: '100%' }}
                      disabled={isEdit}
                      placeholder="Выберите контрагента"
                      allowClear
                      onChange={this.loadContracts}
                      value={this.state.selectedOrgId}>
                      {contractorOrganizations.map((item) => (
                        <Select.Option value={item.organizationId} key={item.organizationId}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={24}>
                    {!isEdit && (
                      <Button size={'small'} type={'link'} onClick={this.handleCreateOrg} key={'1'}>
                        Добавить организацию
                      </Button>
                    )}
                    <Button size={'small'} type={'link'} onClick={this.handleChangeOrg} disabled={!selectedOrgId} key={'2'}>
                      Редактировать выбранную
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Divider>Договоры/доп.соглашения с контрагентом</Divider>
                {selectedOrgId && (
                  <ContractList
                    selectedContractId={this.props.contractId}
                    onChange={() => this.loadContracts(selectedOrgId)}
                    ref={(ref) => (this.contractList = ref)}
                    organizationId={selectedOrgId}
                    companyId={this.props.companyId}
                  />
                )}
              </Col>
            </Row>
          </Spin>
        </AdaptiveDrawer>
        {this.props.contractId ? (
          <Button size={'small'} type={'text'} icon={<EditOutlined />} onClick={this.onOpenDrawer} />
        ) : (
          <Button onClick={this.onOpenDrawer}>Мои договоры</Button>
        )}
      </>
    );
  }
}

export default observer(LeasingContractDrawer);
