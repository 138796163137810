import HttpClient from '@utils/httpClient';

import { HoldModel, HoldUpdateModel } from './data';

const service = {
  /**
   * Получить список простоев за выбранный период времени
   * @param started Начальная дата поиска для интервала
   * @param ended Конечная дата поиска для интервала
   */
  async holdsList(started: string, ended: string): Promise<Array<HoldModel>> {
    return (await HttpClient.get(`/api/companyHolds/holdsList?started=${started}&ended=${ended}`)).data;
  },

  /**
   * Обновление доступных для редактирования полей списка простоев
   * @param data Обновленные значения свойств для записей
   */
  async updateHoldsList(data: HoldUpdateModel): Promise<undefined> {
    return await HttpClient.post(`/api/companyHolds/updateHoldsList`, data);
  },
};

export default service;
