import { Button, Checkbox, Form, Spin } from 'antd';
import React from 'react';

import FloatInput from '@components/fw/FloatLabel/FloatInput';
import ColorSelector from '@components/fw/TagEditor/ColorSelector';

import { MarkModel } from '@stores/markStore';

interface MarkCreatorProps {
  onFinish(values);
  mark?: MarkModel;
  isBusy: boolean;
}

const MarkCreator: React.FC<MarkCreatorProps> = (props) => {
  const init = props.mark
    ? {
        name: props.mark.title,
        color: props.mark.description,
        isBelongsCreator: props.mark.isBelongsCreator,
        reportRowColor: props.mark.reportRowColor,
      }
    : null;
  return (
    <Spin spinning={props.isBusy}>
      <Form onFinish={props.onFinish} layout={'vertical'} initialValues={init} size={'small'}>
        <Form.Item name="name" rules={[{ required: true, message: 'Введите название' }]}>
          <FloatInput placeholder="Название" />
        </Form.Item>
        <Form.Item label="Цвет" name="color" rules={[{ required: true, message: 'Выберите цвет' }]}>
          <ColorSelector />
        </Form.Item>
        <Form.Item
          label=""
          name="isBelongsCreator"
          valuePropName={'checked'}
          tooltip={'Установите признак "Моя метка" и други сотрудники компании не смогут редактировать эту метку'}>
          <Checkbox>Моя метка</Checkbox>
        </Form.Item>
        <Form.Item label="" name="reportRowColor" valuePropName={'checked'} tooltip={'Цвет строки в отчете'}>
          <Checkbox>Цвет строки в отчете</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={props.isBusy}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default MarkCreator;
