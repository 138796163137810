import HttpClient from '@utils/httpClient';

import { RestoreResponse } from './userStore';

const service = {
  logon(data: any) {
    return HttpClient.post(`/api/auth/logon`, data).then((resp) => {
      return resp.data;
    });
  },

  adminLogon(userId: string) {
    return HttpClient.get(`/api/auth/admin-logon`, { params: { userId } }).then((resp) => {
      return resp.data;
    });
  },

  restoreRequest(data: { login: string; url: string }): Promise<RestoreResponse> {
    return HttpClient.post(`/api/auth/restore-request`, data).then((resp) => {
      return resp.data;
    });
  },

  restore(data: { newPassword: string; token: string }): Promise<void> {
    return HttpClient.post(`/api/auth/restore`, data).then((resp) => {
      return resp.data;
    });
  },

  refresh() {
    return HttpClient.get(`/api/auth/refresh`).then((resp) => {
      return resp.data;
    });
  },

  userInfo() {
    return HttpClient.get(`/api/auth/userinfo`).then((resp) => {
      return resp.data;
    });
  },
  /// Получить токен по коду
  getTokenByCode(code: string): Promise<{ access_token: string; refresh_token: string }> {
    return HttpClient.get(`/api/tariffCross/token`, { params: { code } }).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
