import { DatePicker, Spin } from 'antd';
import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Column,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { CompanyUsingStatisticModel } from '@stores/companyUsingStatisticStore/companyUsingStatisticData';
import companyUsingStatisticStoreService from '@stores/companyUsingStatisticStore/companyUsingStatisticStore.service';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface UsingTheSystemProps {}

interface UsingTheSystemState {
  isBusy: boolean;
  data: CompanyUsingStatisticModel[];
  year: Moment;
}

class CompanyUsingStatistic extends Component<UsingTheSystemProps, UsingTheSystemState> {
  dataGrid: DataGrid;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      isBusy: false,
      data: [],
      year: moment(),
    };
  }

  loadData() {
    this.setState({ isBusy: true });
    companyUsingStatisticStoreService
      .getStatistic(this.state.year.year())
      .finally(() => this.setState({ isBusy: false }))
      .then((result) => {
        result.forEach((e: any) => {
          e.serviceStr = e.services.map((x) => `№${x.offerNumber} ${moment(x.offerDate).format(GLOBAL_DATE_FORMAT)}`).join(`\n`);
          e.statusStr = this.calculateAccessEndDate(e);
        });
        this.setState({ data: result });
      });
  }

  componentDidMount() {
    this.loadData();
  }

  update = (key, values) => {
    const find = this.state.data.find((d) => d.companyId == key);
    if (find) {
      Object.keys(values).forEach((vkey) => {
        find[vkey] = values[vkey];
        find.year = this.state.year.year();
      });
      const pays: number =
        Number(find.payJan ?? 0) +
        Number(find.payFeb ?? 0) +
        Number(find.payMar ?? 0) +
        Number(find.payApr ?? 0) +
        Number(find.payMay ?? 0) +
        Number(find.payJun ?? 0) +
        Number(find.payJul ?? 0) +
        Number(find.payAug ?? 0) +
        Number(find.paySep ?? 0) +
        Number(find.payOct ?? 0) +
        Number(find.payNov ?? 0) +
        Number(find.payDec ?? 0);
      const checks: number =
        Number(find.checkJan ?? 0) +
        Number(find.checkFeb ?? 0) +
        Number(find.checkMar ?? 0) +
        Number(find.checkApr ?? 0) +
        Number(find.checkMay ?? 0) +
        Number(find.checkJun ?? 0) +
        Number(find.checkJul ?? 0) +
        Number(find.checkAug ?? 0) +
        Number(find.checkSep ?? 0) +
        Number(find.checkOct ?? 0) +
        Number(find.checkNov ?? 0) +
        Number(find.checkDec ?? 0);
      find.saldo = pays - checks;
      companyUsingStatisticStoreService.updateStatistic(find);
      return null;
    }
  };

  getDs = () => {
    const data = this.state.data;
    const keyField = 'companyId';
    const updateRow = this.update;
    return new DataSource({
      store: new CustomStore({
        key: keyField,
        load: function (loadOptions) {
          return data;
        },
        insert: function (values) {
          throw 'Unimplemented';
        },
        update: function (key, values) {
          return updateRow(key, values);
        },
        remove: function (key) {
          throw 'Unimplemented';
        },
      }),
    });
  };

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'selector',
    });
  }

  render() {
    const { isBusy, data } = this.state;
    const height = 'calc(100vh - 200px)';
    const months = [
      { imn: 'январь', shortName: 'Jan' },
      { imn: 'февраль', shortName: 'Feb' },
      { imn: 'март', shortName: 'Mar' },
      { imn: 'арпель', shortName: 'Apr' },
      { imn: 'май', shortName: 'May' },
      { imn: 'июнь', shortName: 'Jun' },
      { imn: 'июль', shortName: 'Jul' },
      { imn: 'август', shortName: 'Aug' },
      { imn: 'сентябрь', shortName: 'Sep' },
      { imn: 'октябрь', shortName: 'Oct' },
      { imn: 'ноябрь', shortName: 'Nov' },
      { imn: 'декабрь', shortName: 'Dec' },
    ];

    return (
      <Spin spinning={isBusy}>
        <DataGrid
          height={height}
          allowColumnReordering={true}
          dataSource={this.getDs()}
          ref={(ref) => (this.dataGrid = ref)}
          loadPanel={{ enabled: false }}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={100}
          showRowLines={true}
          onToolbarPreparing={this.onToolbarPreparing.bind(this)}
          wordWrapEnabled={true}
          onRowPrepared={this.onRowPrepared}
          selection={{ mode: 'single' }}
          repaintChangesOnly={true}
          columnHidingEnabled={true}>
          <Paging enabled={true} defaultPageSize={25} />
          <Pager
            allowedPageSizes={[25, 50, 100]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            infoText={'Всего: {2}'}
          />
          <Editing mode="cell" refreshMode={'repaint'} allowUpdating={true} allowAdding={false} allowDeleting={false} useIcons={true} />
          <Export enabled={true} allowExportSelectedData={true} excelWrapTextEnabled={true} />
          <FilterPanel visible={true} />
          <FilterRow visible={true} />
          <GroupPanel visible={true} />
          <HeaderFilter visible={true} allowSearch={true} />
          {INDEX_COLUMN}
          <Column allowFixing={true} dataField="name" caption={'Клиент'} allowEditing={false} />
          <Column allowFixing={true} dataField="serviceStr" caption={'Номер договора'} allowEditing={false} />
          <Column allowFixing={true} dataField="contractAmount" caption={'Сумма договора'} allowEditing={false} />
          <Column allowFixing={true} dataField="managerList" caption={'Тип договора'} allowEditing={false} />
          <Column allowFixing={true} dataField="users" caption={'Учетная запись'} width={200} allowEditing={false} />
          <Column allowFixing={true} dataField="statusStr" caption={'Статус'} allowEditing={false} />
          <Column
            allowFixing={true}
            dataField="isTest"
            caption={'Тест/продуктив'}
            allowEditing={false}
            calculateCellValue={(e) => this.calculateIsTest(e)}
          />
          <Column allowFixing={true} dataField="tariff" caption={'Тарифный план'} allowEditing={false} />
          <Column
            allowFixing={true}
            dataField="saldoType"
            caption={'Статус'}
            allowEditing={false}
            calculateCellValue={(e) => this.calculateSaldoType(e)}
          />
          <Column
            allowFixing={true}
            dataField="saldo"
            caption={'Сальдо на момент'}
            allowEditing={false}
            calculateCellValue={(e) => e.saldo.toLocaleString('ru', { maximumFractionDigits: 2 })}
          />
          {months.map((m, i) => (
            <Column allowFixing={true} dataField="" caption={m.imn} key={'month_' + m.shortName}>
              <Column allowFixing={true} dataField={'check' + m.shortName} caption={'Счет'} key={'check' + m.shortName} showEditorAlways />
              <Column allowFixing={true} dataField={'pay' + m.shortName} caption={'Оплата'} key={'pay' + m.shortName} showEditorAlways />
            </Column>
          ))}
          <Template name={'selector'} render={() => this.selectorTemplate()} />
        </DataGrid>
      </Spin>
    );
  }

  selectorTemplate = () => {
    return (
      <DatePicker
        picker="year"
        value={this.state.year}
        onChange={(e) => {
          this.setState({ year: e }, () => this.loadData());
        }}
      />
    );
  };

  onRowPrepared = (e) => {
    if (e.data && e.data.current === false) {
      e.rowElement.style.color = '#9b9ba1';
    }
  };

  calculateSaldoType = (e) => {
    if (e.saldo > 0) {
      e.saldoType = 'Аванс';
    } else if (e.saldo < 0) {
      e.saldoType = 'Долг';
    } else {
      e.saldoType = 0;
    }
    return e.saldoType;
  };

  calculateAccessEndDate = (e) => {
    let result = null;
    if (e.accessEndDate === null || moment(e.accessEndDate) > moment()) {
      result = 'Активный';
    } else if (moment(e.accessEndDate) < moment()) {
      result = 'Заблокирован';
    }
    return result;
  };

  calculateIsTest = (e) => {
    let result = null;
    if (e.isTest == true) {
      if (e.accessEndDate) {
        result = 'ТЕСТ до ' + moment(e.accessEndDate).format(GLOBAL_DATE_FORMAT);
      } else {
        result = 'ТЕСТ';
      }
    } else {
      result = null;
    }
    return result;
  };
}
export default CompanyUsingStatistic;
