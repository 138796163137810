import { Radio } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React from 'react';

import { ViewType } from '@stores/dictStore';

interface ViewTypeRadioProps {
  type: ViewType;
  size: SizeType;
  onChange(value: ViewType): void;
}
const ViewTypeRadio: React.FC<ViewTypeRadioProps> = (props) => {
  return (
    <div>
      <Radio.Group
        defaultValue="a"
        buttonStyle="solid"
        size={props.size}
        value={props.type}
        onChange={(e) => props.onChange(e.target.value)}
        style={{ marginLeft: 5 }}>
        <Radio.Button key={'table'} value={'table'}>
          Таблица
        </Radio.Button>
        <Radio.Button key={'board'} value={'board'}>
          Доска
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default ViewTypeRadio;
