import { UserOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';

const NotesDxCellReport: React.FC<any> = (props) => {
  const isGroup = props.rowType === 'group';
  let value;
  try {
    if (typeof props.value == 'string') {
      value = JSON.parse(props.value);
    } else {
      value = props.value;
    }
  } catch (e) {}
  return value && Array.isArray(value) ? (
    <span>
      {isGroup && <span>{props.column.caption}: </span>}
      {value
        .sort((a, b) => (a.title > b.title ? 1 : -1))
        .map((tag, index) => {
          return (
            <span key={`tag_${index}`} style={{ marginLeft: '2px' }}>
              <Tag icon={tag.isBelongsCreator ? <UserOutlined /> : null} color={tag.description}>
                {tag.title}
              </Tag>
            </span>
          );
        })}
    </span>
  ) : null;
};

export default NotesDxCellReport;
