import HttpClient from '@utils/httpClient';

import { RequestModel, RequestResourceInfo, RequestTypeInfo } from './RequestsData';

const requestsService = {
  async getTypes(): Promise<RequestTypeInfo[]> {
    return (await HttpClient.get(`/api/request/types`)).data;
  },

  async getResources(code: string): Promise<RequestResourceInfo[]> {
    return (await HttpClient.get(`/api/request/resources/${code}`)).data;
  },

  async createRequest(code: string, data: any): Promise<RequestModel> {
    return (await HttpClient.post(`/api/request/create/${code}`, data)).data;
  },

  async getData(data: any): Promise<any> {
    return (await HttpClient.post(`/api/request/data`, data)).data;
  },

  async getInfo(requestId: string): Promise<RequestModel> {
    return (await HttpClient.post(`/api/request/info/${requestId}`)).data;
  },

  async reCreate(requestId: string): Promise<any> {
    return (await HttpClient.post(`/api/request/recreate/${requestId}`)).data;
  },

  async download(requestId: string, progressFunc: ((e: ProgressEvent) => void) | undefined = undefined): Promise<Blob> {
    return (
      await HttpClient.post(`/api/request/download/${requestId}`, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
      })
    ).data;
  },
};

export default requestsService;
