import HttpClient from '@utils/httpClient';

import {
  AcceptanceCertificateServiceModel,
  AcceptanceOfServiceServiceModel,
  OrganizationContractServiceModel,
  RentDocumentServiceModel,
  WagonRentServiceModel,
} from './LeasingData';

const service = {
  async updateAcceptanceCertificate(data: AcceptanceCertificateServiceModel): Promise<string> {
    return (await HttpClient.put(`/api/rent/acceptanceCertificate/edit`, data)).data;
  },

  async getLeasingDoc(
    id: string,
    type: 'acceptanceCertificate' | 'equipmentList' | 'acceptanceOfService',
    format: string = 'pdf',
    progressFunc: ((e: ProgressEvent) => void) | undefined = undefined,
  ): Promise<Blob> {
    return (
      await HttpClient.get(`/api/rent/${type}/view/${id}`, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
        params: { format },
      })
    ).data;
  },

  async addAcceptanceCertificate(
    data: AcceptanceCertificateServiceModel,
    type: 'wagons' | 'containers',
    direction: 'toRent' | 'fromRent',
  ): Promise<string> {
    return (await HttpClient.post(`/api/rent/acceptanceCertificate/${type}/${direction}`, data)).data;
  },

  async returnWagonOnLeaseList(data: number[]): Promise<WagonRentServiceModel[]> {
    return (await HttpClient.post(`/api/rent/wagons/onlease`, data)).data;
  },

  //проверяет есть ли в спике вагонов которые в аренде эти номера вагонов, если да то хорошо и можно вернуть, если нет то вернется модель где только номер вагона, которй нельзя вернуть из аренды так как его там нет
  async returnWagonNotOnLeaseList(data: number[]): Promise<WagonRentServiceModel[]> {
    return (await HttpClient.post(`/api/rent/wagons/notonlease`, data)).data;
  },

  async getContractForRent(): Promise<OrganizationContractServiceModel[]> {
    return (await HttpClient.get(`/api/organizationContract/rent`)).data;
  },

  async getContractRentDocs(contractId: string): Promise<RentDocumentServiceModel[]> {
    return (await HttpClient.get(`/api/organizationContract/${contractId}/rentdocs`)).data;
  },

  async getAcceptanceOfService(id: string): Promise<AcceptanceOfServiceServiceModel> {
    return (await HttpClient.get(`/api/rent/acceptanceOfService/wagons/${id}`)).data;
  },

  async addAcceptanceOfService(data: AcceptanceOfServiceServiceModel): Promise<string> {
    return (await HttpClient.post(`/api/rent/acceptanceOfService/wagons`, data)).data;
  },
};

export default service;
