import { action, computed, makeObservable, observable } from 'mobx';

import {
  ClaimCountryInteractionsModel,
  ClaimCountryInteractionsTree,
  ClaimModel,
  claimCountryInteractionsModelToFormData,
  claimCountryInteractionsModelToTree,
} from './ClaimData';
import service from './claimsStore.service';

class ClaimCountryInteractionsStore {
  @observable isBusy: boolean = false;
  @observable countryPaidTerritories: ClaimCountryInteractionsModel[] = [];
  @observable countryPaidTerritoriesTree: ClaimCountryInteractionsTree[] = [];
  @observable initFormData = {};

  constructor() {
    makeObservable(this);
  }

  @action async loadClaimCountryInteractionsModel(claim: ClaimModel) {
    this.isBusy = true;
    return await service
      .getInteractions(claim)
      .then((ans) => {
        this.countryPaidTerritories = ans;
        this.countryPaidTerritoriesTree = claimCountryInteractionsModelToTree(ans);
        this.initFormData = claimCountryInteractionsModelToFormData(this.countryPaidTerritories);
      })
      .finally(() => {
        this.isBusy = false;
      });
  }

  @action clearData() {
    this.countryPaidTerritoriesTree = [];
    this.countryPaidTerritories = [];
    this.initFormData = {};
  }

  @computed get hasData() {
    return this.countryPaidTerritories.length || this.isBusy;
  }
}

export default new ClaimCountryInteractionsStore();
