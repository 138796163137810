import { action, computed, makeObservable, observable } from 'mobx';

import service from './tagStore.service';

export type TagModel = {
  tagId: string;
  name: string;
  metadata: { color: any };
  createDate: string;
};

class TagStore {
  @observable isBusy: boolean = false;
  @observable tags: TagModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed get tagsForFilter() {
    let result = this.tags.map((x) => {
      return { value: x.tagId, text: x.name };
    });
    result.push({ value: null, text: '(пустое)' });
    return result;
  }

  @action getTags() {
    this.isBusy = true;
    service
      .getTags()
      .finally(() => (this.isBusy = false))
      .then((result: TagModel[]) => {
        this.tags = result;
      });
  }

  @action async create(data: any) {
    this.isBusy = true;
    await service
      .create(data)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getTags();
      });
  }

  @action async update(data: any) {
    this.isBusy = true;
    await service
      .update(data)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getTags();
      });
  }

  @action remove(tagId: string) {
    this.isBusy = true;
    service
      .remove(tagId)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getTags();
      });
  }
}

export default new TagStore();
