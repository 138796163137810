import { TemplateOrgExpeditionExtParam } from '../organizationsStore/organizationsStoreData';

export interface CompanyExpeditorSettingInfo {
  allSettings?: TemplateOrgExpeditionExtParam[];
  selectedSettings: string[];
}

export const CURRENCY_LIST = [
  { code: '756', name: 'Швейцарский франк', nameField: '₣' },
  { code: '840', name: 'Доллар США', nameField: '$' },
  { code: '978', name: 'Евро', nameField: '€' },
  { code: '810', name: 'Российский рубль', nameField: '₽' },
];
