import { Button, Empty, Form, Modal, ModalProps, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';
import RequiredPlaceholder from '@components/fw/RequiredPlaceholder';
import { RpsSelect } from '@components/fw/RpsSelect';

import dictService from '@stores/dictStore/dictStore.service';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import organizationsStore from '@stores/organizationsStore';
import organizationsStoreService from '@stores/organizationsStore/organizationsStore.service';
import { TemplateOrgContractModel } from '@stores/organizationsStore/organizationsStoreData';
import requestIlsStore from '@stores/requestIlsStore/requestIlsStore';
import { ClaimReport, wayKinds } from '@stores/requestIlsStore/requestIlsStoreData';
import userStore from '@stores/userStore';

import './IlsClaimForm.less';

interface ComponentProps extends ModalProps {
  closeModal: () => void;
  value?: ClaimReport;
  history?: any;
}

interface ComponentState {
  optionContract: TemplateOrgContractModel[];
  reqValues: null | ClaimReport;
}

@observer
class IlsClaimForm extends Component<ComponentProps, ComponentState> {
  formRef: FormInstance;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      optionContract: [],
      reqValues: this.props.value,
    };
  }

  componentDidMount() {
    organizationsStore.getOrganizations();
    organizationsStore.getUserOrganizations();
    this.contractList(this.props.value?.clientId);
  }

  requestHandler = (): void => {
    const { closeModal } = this.props;
    const { reqValues } = this.state;
    this.formRef.validateFields().then((e) => {
      e.beginDate = moment(e.beginDate).local(false).toISOString(true);
      e.endDate = moment(e.endDate).local(false).toISOString(true);

      if (reqValues) {
        requestIlsStore.edit({ ...reqValues, ...e }).then((res) => {
          this.setState({ reqValues: { ...reqValues, ...e } });
          closeModal();
        });
      } else {
        requestIlsStore.create({ ...e }).then((res) => {
          this.props.history.replace(`/view-ils-send/${res}`);
          closeModal();
        });
      }
    });
  };

  contractList = async (clientId: string | null): Promise<void> => {
    if (!clientId) return;
    const res = await organizationsStoreService.getContractList(clientId, 'contract');
    this.setState({ optionContract: res });
  };

  render() {
    const { reqValues, optionContract } = this.state;
    const { value } = this.props;
    const optionsClient = organizationsStore.organizations.map((organization) => {
      return { label: organization.name, value: organization.organizationId };
    });

    return (
      <Modal
        width={'100%'}
        className={'RequestIlsForm'}
        title={!!reqValues ? 'Редактировать заявку' : 'Создать заявку'}
        centered={true}
        open={this.props.open}
        onCancel={this.props.closeModal}
        footer={
          <div style={{ marginTop: 20, display: 'flex', gap: 10 }}>
            <Button type={'primary'} htmlType={'button'} onClick={this.requestHandler}>
              Сохранить
            </Button>

            <Button htmlType={'button'} onClick={this.props.closeModal}>
              Отменить
            </Button>
          </div>
        }>
        <p className={`status request`} style={{ margin: '10px 0 0 0' }}>
          <span className={'request'}>Заявка {reqValues ? `№${reqValues.ilsClaimNumber}` : null}</span>
        </p>

        <Form
          className={'ilsFormRequest'}
          ref={(ref) => (this.formRef = ref)}
          initialValues={
            reqValues && {
              ...reqValues,
              beginDate: moment(reqValues.beginDate),
              endDate: moment(reqValues.endDate),
              clientId: userStore.isOrg ? userStore.userData.organizationId : reqValues.clientId,
            }
          }>
          <div className={'ilsInputs'}>
            <div className={'form-element'}>
              <Form.Item name={'clientId'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                <FloatSelect placeholder={<RequiredPlaceholder value="Клиент" />} options={optionsClient} disabled={userStore.isOrg} />
              </Form.Item>

              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10, width: '100%' }}>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) => {
                    const isUpdate = prevValues.clientId !== currentValues.clientId;
                    if (isUpdate) {
                      this.contractList(currentValues.clientId);
                      this.formRef.setFieldValue('contractId', null);
                    }
                    return isUpdate;
                  }}>
                  {() => (
                    <Form.Item
                      name={'contractId'}
                      rules={[
                        {
                          required: true,
                          message: 'Пожалуйста, заполните это поле',
                        },
                      ]}>
                      <FloatSelect
                        disabled={false}
                        placeholder={<RequiredPlaceholder value="Номер договора" />}
                        allowClear
                        notFoundContent={
                          optionContract.length ? <Empty>Создайте новый договор</Empty> : <Empty>Сначала выберите контрагента</Empty>
                        }>
                        {optionContract.map((item) => (
                          <Select.Option value={item.templateMetadataId} key={item.templateMetadataId}>
                            {item.name} <span style={{ color: 'darkgrey' }}># {item.contractNumber}</span>
                          </Select.Option>
                        ))}
                      </FloatSelect>
                    </Form.Item>
                  )}
                </Form.Item>

                <Form.Item name={'destinationType'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                  <FloatSelect
                    placeholder={<RequiredPlaceholder value="Тип назначения" />}
                    options={[
                      { label: 'Дорога', value: 'road' },
                      { label: 'Станция', value: 'station' },
                    ]}
                  />
                </Form.Item>
              </div>
              <Form.Item name={'loadStationCode'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                <RemoteSelect
                  float={true}
                  autoInitDict={true}
                  primitiveResult={true}
                  placeholder={<RequiredPlaceholder value="Станция отправления" />}
                  valueExpr={'stationCode'}
                  displayExpr={'fullName'}
                  dataSource={dictService.stationDict}
                  itemTemplate={(s) => (
                    <span>
                      {s.stationCode} {s.fullName}
                    </span>
                  )}
                />
              </Form.Item>

              <Form.Item name={'senderId'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                <RemoteSelect
                  dataSource={dictService.okpoDict}
                  valueExpr={'okpoId'}
                  displayExpr={'shortName'}
                  float={true}
                  autoInitDict={true}
                  primitiveResult={true}
                  placeholder={<RequiredPlaceholder value="Грузоотправитель" />}
                />
              </Form.Item>
            </div>

            <div className={'form-element'} style={{ marginTop: 'auto' }}>
              <Form.Item name={'rps'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                <RpsSelect />
              </Form.Item>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gap: 10 }}>
                <Form.Item name={'beginDate'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                  <FloatDatepicker
                    placeholder={<RequiredPlaceholder value="Дата начала" />}
                    format={GLOBAL_DATE_FORMAT}
                    disabledDate={(currentDate) => {
                      const endDate = this.formRef?.getFieldValue('endDate');
                      const date = value ? moment(value.createDate) : moment();

                      return (
                        (currentDate && moment(currentDate).startOf('day') < moment(date).startOf('day')) ||
                        (endDate && moment(currentDate).startOf('day') > endDate.startOf('day')) ||
                        (endDate && moment(currentDate).endOf('months') < endDate)
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item name={'endDate'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                  <FloatDatepicker
                    format={GLOBAL_DATE_FORMAT}
                    placeholder={<RequiredPlaceholder value="Дата окончания" />}
                    disabledDate={(currentDate) => {
                      const beginDate = this.formRef?.getFieldValue('beginDate');
                      const date = value ? moment(value.createDate) : moment();
                      const startDate = beginDate ? beginDate : date;

                      return (
                        (currentDate && moment(currentDate).startOf('day') < startDate.startOf('day')) ||
                        (beginDate && currentDate > moment(beginDate).endOf('months'))
                      );
                    }}
                  />
                </Form.Item>
              </div>

              <Form.Item name={'wayKind'} rules={[{ required: true, message: 'Пожалуйста, заполните это поле' }]}>
                <FloatSelect placeholder={<RequiredPlaceholder value="Вид сообщения" />} options={wayKinds} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default IlsClaimForm;
