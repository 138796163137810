import { Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import { BidRegistryCommercialStore } from '@stores/bidRegistryCommercialStore';

import { COLUMNS } from './lib/columns';

export const CommissionTable = observer(() => {
  const sortCountryPaid = BidRegistryCommercialStore.total.comission;

  return (
    <>
      {sortCountryPaid.map(({ freightEtsngName, freightEtsngCode, routes }) => {
        return (
          <AdaptiveCard hoverable key={freightEtsngCode} title={freightEtsngName}>
            {routes.map((route) => {
              const countryList = route.countryList.map((country) => ({
                ...country,
                freightEtsngCode,
                freightEtsngName,
                loadStationCode: route.loadStationCode,
                destStationCode: route.destStationCode,
                loadStationName: route.loadStationName,
                destStationName: route.destStationName,
              }));

              return (
                <Table
                  columns={COLUMNS}
                  style={{ marginBottom: '1rem' }}
                  rowKey={Math.random}
                  pagination={false}
                  dataSource={countryList}
                  bordered
                />
              );
            })}
          </AdaptiveCard>
        );
      })}
    </>
  );
});
