import { action, makeObservable, observable } from 'mobx';

import { headerFilterSort } from '@utils/sort';

import service from './organizationsStore.service';
import { BankRequisites, BankSearchRequisites, OrganizationRequisites, OrganizationSearchRequisites } from './organizationsStoreData';

export interface OrganizationModel extends OrganizationRequisites {
  organizationId: string;
  name: string;
  companyId: string;
  organizationTypeCode: string;
  organizationTypeName?: string;
  companyName?: string;
  contactEmails: string[];
  catalogId?: string;
  catalogTitle?: string;
}

export const emptyOrganization: OrganizationModel = {
  organizationId: null,
  name: null,
  companyId: null,
  companyName: null,
  organizationTypeCode: null,
  organizationTypeName: null,
  inn: null,
  kpp: null,
  okpo: null,
  ogrn: null,
  shortNameReq: null,
  fullNameReq: null,
  address: null,
  telephone: null,
  email: null,
  owner: null,
  okfs: null,
  okogu: null,
  okopf: null,
  oktmo: null,
  okato: null,
  country: null,
  addressMail: null,
  addressFact: null,
  view: 'ul',
  group: null,
  bankBik: null,
  bankName: null,
  bankAddress: null,
  bankCorBill: null,
  orgBill: null,
  signer: null,
  post: null,
  reason: null,
  attorney: null,
  glbuh: null,
  contactEmails: [],
};

export type OrganizationTypeModel = {
  code: string;
  name: string;
};

export interface OrganizationFilter {
  companyIds: string[];
  organizationIds: string[];
  organizationTypeCode: string;
  hasOkpo: boolean | null;
}

class OrganizationsStore {
  @observable organizations: OrganizationModel[] = [];
  @observable contragent: OrganizationModel[] = [];
  @observable organizationTypes: OrganizationTypeModel[] = [];
  @observable loadingOrganizations: boolean = false;
  @observable loadingConfirm: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action getOrganizations(filter: any = {}) {
    this.loadingOrganizations = true;
    return service
      .getOrganizations(filter)
      .finally(() => (this.loadingOrganizations = false))
      .then((result) => {
        this.organizations = result;
      });
  }

  @action async getOrganizationsHeaderFilter(search: string) {
    this.loadingOrganizations = true;
    if (!this.organizations || !this.organizations.length) {
      await this.getOrganizations({});
    }
    const data = this.organizations
      .map((o) => ({ value: o.name, text: o.name }))
      .filter((o) => !search || o.text.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
      .sort(headerFilterSort);
    data.unshift({ value: null, text: '(Пустое)' });

    return data;
  }

  @action getOrganizationTypes() {
    return service.getOrganizationTypes().then((result) => {
      this.organizationTypes = result;
      return this.organizationTypes;
    });
  }

  @action create(organization: OrganizationModel): Promise<void> {
    this.loadingConfirm = true;
    return service
      .create(organization)
      .finally(() => (this.loadingConfirm = false))
      .then(() => {
        this.getOrganizations();
      });
  }

  @action async update(organization: OrganizationModel): Promise<void> {
    this.loadingConfirm = true;
    return service
      .update(organization)
      .finally(() => (this.loadingConfirm = false))
      .then(() => {
        this.getOrganizations();
      });
  }

  @action remove(organizationId: string): Promise<void> {
    this.loadingOrganizations = true;
    return service
      .remove(organizationId)
      .finally(() => (this.loadingOrganizations = false))
      .then(() => {
        this.getOrganizations();
      });
  }
  @action async getRequisitesByInn(inn: string): Promise<OrganizationRequisites> {
    return await service.getRequisitesByInn(inn);
  }

  @action async findRequisites(search: string): Promise<OrganizationSearchRequisites[]> {
    return await service.searchRequisites(search);
  }

  @action async getBankRequisitesByBIK(search: string): Promise<BankRequisites> {
    return await service.getBankRequisitesByBIK(search);
  }

  @action async findBankSearchRequisites(search: string): Promise<BankSearchRequisites[]> {
    return await service.bankSearchRequisites(search);
  }

  @action async getById(id: string): Promise<OrganizationModel> {
    return await service.getOrganization(id);
  }

  @action async getUserOrganizations(): Promise<OrganizationModel[]> {
    const body: OrganizationFilter = {
      companyIds: [],
      organizationIds: [],
      organizationTypeCode: null,
      hasOkpo: null,
    };
    const data = await service.getUserOrganizations(body);
    this.contragent = data;
    return data;
  }

  @action getContragentArrayAsync = async (search?: string) => {
    return Promise.resolve(this.getContragentArray(search));
  };

  @action getContragentArray = (search?: string) => {
    if (!search) return this.contragent.filter((c) => c.organizationTypeCode == 'contractor');
    return this.contragent.filter(
      (c) => c.organizationTypeCode == 'contractor' && c.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  };

  @action getBranchArray = (search?: string) => {
    if (!search) return this.contragent.filter((c) => c.organizationTypeCode == 'branch');
    return this.contragent.filter(
      (c) => c.organizationTypeCode == 'branch' && c.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  };

  @action getSupplierArray = (search?: string) => {
    if (!search) return this.contragent.filter((c) => c.organizationTypeCode == 'supplier');
    return this.contragent.filter(
      (c) => c.organizationTypeCode == 'supplier' && c.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  };
}

export default new OrganizationsStore();
