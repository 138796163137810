import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react';
import { DefaultOptionType } from 'rc-select/lib/Select';
import React, { useEffect, useState } from 'react';

import styles from '@components/fw/Fields/field.module.scss';
import { FieldFloatSelect } from '@components/fw/Fields/float-select';
import { FieldRangePicker } from '@components/fw/Fields/range-picker';
import { Flex } from '@components/fw/Flex';
import OrganizationFormItem from '@components/routed/ClaimInfo/documents/OrganizationFormItem';

import organizationsStoreService from '@stores/organizationsStore/organizationsStore.service';
import { TemplateOrgContractModel } from '@stores/organizationsStore/organizationsStoreData';
import userStore from '@stores/userStore';

import { getContractOptions, getExecutorOptions } from './basic-tab-basics-fields.lib';
import { BasicTabBasicsFieldsProps, BidRegistryKind, BidRegistryServiceType, BidRegistryTeuType } from './basic-tab-basics-fields.types';

export const BasicTabBasicsFields = observer(({ users, form, isEdit }: BasicTabBasicsFieldsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabledTeuTypes, setIsDisabledTeuTypes] = useState<boolean>(true);
  const [contractList, setContractList] = useState<TemplateOrgContractModel[]>([]);

  const serviceType = useWatch('serviceType', form);
  const organizationContractor = useWatch('organizationId', form);

  useEffect(() => {
    if (serviceType === BidRegistryServiceType.wagons || serviceType === BidRegistryServiceType.expedition) {
      return setIsDisabledTeuTypes(false);
    }
    setIsDisabledTeuTypes(true);
  }, [serviceType]);

  useEffect(() => {
    if (organizationContractor) {
      setIsLoading(true);
      organizationsStoreService
        .getContractList(organizationContractor, 'contract')
        .then((res) => setContractList(res))
        .finally(() => setIsLoading(false));
    }
  }, [organizationContractor]);

  return (
    <>
      <Flex gap="var(--gap) 32px" wrap="wrap">
        <OrganizationFormItem
          onChange={() => {
            form.setFieldValue('contractId', '');
          }}
          placeholder="Организация контрагента"
          float={true}
          primitiveResult={true}
          form={form}
          type="contractor"
          formItemName="organizationId"
          formItemClassName={styles.fieldFlexAuto}
          required={false}
        />
        <FieldFloatSelect options={SERVICE_TYPES_OPTIONS} name="serviceType" placeholder="Тип услуги" allowClear disabled={isEdit} />
        <OrganizationFormItem
          placeholder="Организация исполнителя"
          float={true}
          primitiveResult={true}
          form={form}
          type="branch"
          formItemName="executorOrganizationId"
          formItemClassName={styles.fieldFlexAuto}
          required={false}
        />
        <FieldFloatSelect
          disabled={!!userStore.userData.companyId}
          widthSearch={true}
          options={getExecutorOptions(users)}
          name="executorId"
          placeholder="Исполнитель"
          allowClear
        />
      </Flex>
      <Flex gap="var(--gap) 32px" wrap="wrap" justify="space-between">
        <FieldFloatSelect
          disabled={!organizationContractor}
          loading={isLoading}
          options={getContractOptions(contractList)}
          name="contractId"
          placeholder="№ договора, дата договора"
          allowClear
        />
        <FieldFloatSelect options={REGISTRY_KIND_OPTIONS} name="bidKind" placeholder="Вид ставки" disabled={true} />
        <FieldFloatSelect
          disabled={isDisabledTeuTypes || true}
          options={TEU_TYPES_OPTIONS}
          name="bidTeuType"
          placeholder="Тип ставки"
          allowClear
        />
        <FieldRangePicker
          rules={[{ required: true, message: 'Обязательное поле' }]}
          name="validity"
          placeholder={['Срок действия', '']}
          format="DD.MM.YYYY"
        />
      </Flex>
    </>
  );
});

const SERVICE_TYPES_OPTIONS: DefaultOptionType[] = [
  { label: 'Предоставление вагона', value: BidRegistryServiceType.wagons },
  { label: 'Экспедирование (Оплата жд тарифа)', value: BidRegistryServiceType.expedition },
  { label: 'ТЭУ(Предоставление вагона с оплатой тарифа)', value: BidRegistryServiceType.wagons_expedition },
];

const REGISTRY_KIND_OPTIONS: DefaultOptionType[] = [
  {
    label: 'На технический рейс - ставка за перевозку на фиксированном плече от станции А до станции Б',
    value: BidRegistryKind.itinerary,
  },
  { label: 'Сутки - ставка указывается  за сутки использования вагона', value: BidRegistryKind.time },
];

const TEU_TYPES_OPTIONS: DefaultOptionType[] = [
  { label: 'За единицу транспорта', value: BidRegistryTeuType.ps },
  { label: 'за тонну перевозимого груза', value: BidRegistryTeuType.ton },
];
