import HttpClient from '@utils/httpClient';

import { TariffFgkBaseResponseModel, TariffFgkHistoryModel, TariffFgkRequestModel } from './tariffFgkBaseStore';

const service = {
  calculate(request: TariffFgkRequestModel): Promise<TariffFgkBaseResponseModel> {
    return HttpClient.post(`/api/tarifffgk/calculate`, request).then((resp) => {
      return resp.data;
    });
  },

  historyData(request): Promise<any> {
    return HttpClient.post(`/api/tarifffgk/request/data`, request).then((resp) => {
      return resp.data;
    });
  },

  requestData(requestId: string): Promise<TariffFgkHistoryModel> {
    return HttpClient.get(`/api/tarifffgk/request/${requestId}`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
