import moment from 'moment';

import HttpClient from '@utils/httpClient';

import {
  ClaimCommercialInfo,
  ClaimCountryInteractionsModel,
  ClaimModel,
  ClaimPerformanceInfo,
  ClaimTableModel,
  ClaimTariffDetailModel,
  ClaimTariffModel,
  ClaimTariffWagonTypeGroup,
  Page,
} from './ClaimData';

const service = {
  getClaims(filter: any) {
    return HttpClient.post(`/api/claim`, filter).then((resp) => {
      return resp.data;
    });
  },

  async loadPlan(filter: any): Promise<Page<ClaimTableModel>> {
    return (await HttpClient.post(`/api/claim/loadplan`, filter)).data;
  },

  getClaim(claimId: string): Promise<ClaimModel> {
    return HttpClient.get(`/api/claim/getClaim`, { params: { claimId } }).then((resp) => {
      return resp.data;
    });
  },

  getClaimGroups() {
    return HttpClient.get(`/api/claimGroup`).then((resp) => {
      return resp.data;
    });
  },

  create(data: any): Promise<string> {
    return HttpClient.post(`/api/claim/create`, data).then((resp) => {
      return resp.data;
    });
  },

  validate(data: any) {
    return HttpClient.post(`/api/claim/validate`, data).then((resp) => {
      return resp.data;
    });
  },

  update(data: any) {
    return HttpClient.post(`/api/claim/update`, data).then((resp) => {
      return resp.data;
    });
  },

  setStatus(claimId: string, statusCode: string) {
    return HttpClient.post(`/api/claim/status/${claimId}/${statusCode}`).then((resp) => {
      return resp.data;
    });
  },

  delete(claimId: string) {
    return HttpClient.delete(`/api/claim/deleteClaim/${claimId}`).then((resp) => {
      return resp.data;
    });
  },

  sends(claimId: string) {
    return HttpClient.get(`/api/claim/sends`, { params: { claimId } }).then((resp) => {
      return resp.data;
    });
  },

  priorities() {
    return HttpClient.get(`/api/claim/priorities`).then((resp) => {
      return resp.data;
    });
  },

  limitations(claimId: string, sendId: string) {
    return HttpClient.get(`/api/claim/limitations`, { params: { claimId, sendId } }).then((resp) => {
      return resp.data;
    });
  },

  async calculateTariff(claim: ClaimModel): Promise<ClaimTariffModel> {
    const calculateDate = moment(claim.tariffDate).toISOString(true);
    return (await HttpClient.post(`/api/claimTariff/calculateTariff`, claim, { params: { calculateDate } })).data;
  },

  async getTariffInfo(claimId: string): Promise<ClaimTariffModel> {
    return (await HttpClient.get(`/api/claimTariff/getTariffInfo?claimId=${claimId}`)).data;
  },

  async saveTariffInfo(claimId: string, data: ClaimTariffDetailModel[], calculateDate: string, contractId?: string): Promise<string[]> {
    return (await HttpClient.post(`/api/claimTariff/saveTariffInfo`, data, { params: { claimId, contractId, calculateDate } })).data;
  },

  //Поиск допников отключен
  /*  async getClaimTariffRelatedExtContracts(contractId: string, claimTariffId: string): Promise<TemplateOrgExtContractModel[]> {
    return (await HttpClient.get(`/api/claimTariff/tariff-extcontract`, { params: { contractId, claimTariffId } })).data;
  },

  async addExtContractsToClaim(claimId: string, extContracts: string[]): Promise<void> {
    return (
      await HttpClient.get(`/api/claimTariff/attach-extcontracts`, {
        params: { claimId, extContracts },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      })
    ).data;
  },*/

  async getClaimCommercialInfo(claimId: string): Promise<ClaimCommercialInfo> {
    return (await HttpClient.get(`/api/claimCommercial`, { params: { claimId } })).data;
  },

  async saveClaimCommercialInfo(data: ClaimCommercialInfo): Promise<string[]> {
    return (await HttpClient.post(`/api/claimCommercial`, data)).data;
  },

  async getClaimPerformance(claimId: string): Promise<ClaimPerformanceInfo> {
    return HttpClient.get(`/api/claimPerformance`, { params: { claimId } }).then((resp) => {
      return resp.data;
    });
  },

  async calculateTariffNew(claim: ClaimModel): Promise<ClaimTariffWagonTypeGroup[]> {
    const calculateDate = moment(claim.tariffDate).toISOString(true);
    return (await HttpClient.post(`/api/claimTariffNew/calculate`, claim, { params: { calculateDate } })).data;
  },

  async getInteractions(claim: ClaimModel): Promise<ClaimCountryInteractionsModel[]> {
    const calculateDate = moment(claim.tariffDate).toISOString(true);
    return (await HttpClient.post(`/api/claim/tariff-interactions`, claim, { params: { calculateDate } })).data;
  },
};

export default service;
