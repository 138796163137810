import { makeObservable, observable } from 'mobx';

import TrackingStore from '../trackingStoreBase';
import { ActiveContainer, ContainerAddRequest, ContainerRemoveRequest, HistoryContainer } from './ContainerTypes';
import { TrackingContainerStoreService } from './trackingContainerStore.service';

class TrackingContainerStore extends TrackingStore<
  ActiveContainer,
  HistoryContainer,
  ContainerAddRequest,
  ContainerRemoveRequest,
  TrackingContainerStoreService
> {
  @observable active: ActiveContainer[];
  @observable history: HistoryContainer[];
  service = new TrackingContainerStoreService();

  constructor() {
    super();

    makeObservable(this);
  }
}

export default new TrackingContainerStore();
