import DataGrid, {
  Column,
  Export,
  FilterPanel,
  FilterRow,
  GroupItem,
  GroupPanel,
  Grouping,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import qs from 'qs';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import ItineraryClaimDxCell from '@components/fw/Itinerary/ItineraryClaimDxCell';
import ItineraryContractDxCell from '@components/fw/Itinerary/ItineraryContractDxCell';
import ItineraryExtDxCell from '@components/fw/Itinerary/ItineraryExtDxCell';
import OperationsDataGrid from '@components/fw/Itinerary/OperationsDataGrid';

import { ignoreCaseFilter } from '@stores/dispatcherStore/DispatcherData';
import itineraryStore from '@stores/itineraryStore/itineraryStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import resultViewStore from '@stores/resultViewStore/resultViewStore';

interface Props {
  type: 'wagons' | 'containers';
  location: any;
}

interface State {
  dataSource: DataSource;
}

@observer
class ItineraryEconomicalsDataGrid extends Component<Props, State> {
  dataGrid: DataGrid;
  ExcelJs: any;
  constructor(props: Props) {
    super(props);
    import('exceljs').then((e) => {
      this.ExcelJs = e;
    });

    this.state = {
      dataSource: this.dataSource,
    };
  }

  get dataSource() {
    const type = this.props.type;
    return new DataSource({
      store: new CustomStore({
        key: 'itineraryid',
        load: function (loadOptions) {
          return itineraryStore
            .itinerary(loadOptions, type)
            .then((data) => {
              return data;
            })
            .catch(() => {
              throw 'Data Loading Error';
            });
        },
      }),
    });
  }

  getFiler = (name: string) => {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const data = query[name];
    if (!data) return null;
    let target = typeof data == 'object' ? (Object.values(data) as string[]) : [data];
    if (name == 'started') {
      return target.map((t) => moment(t.replaceAll(' ', '+')).toDate());
    }
    return target;
  };

  dateFilter = () => {
    return this.getFiler('started');
  };

  render() {
    const data = this.state.dataSource;
    const type = this.props.type;
    const numberDataField = type == 'wagons' ? 'wagonnumber' : 'containernumber';
    const groupSummary = resultViewStore.groupSummaryList(this.dataGrid);
    const totalSummary = resultViewStore.totalSummaryList();
    const dateFilter = this.dateFilter();
    return (
      <AdaptiveCard>
        <DataGrid
          allowColumnReordering={true}
          dataSource={data}
          ref={(ref) => (this.dataGrid = ref)}
          style={{ height: 'calc(100vh - var(--card-padding))' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          repaintChangesOnly={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          onContextMenuPreparing={(e) => resultViewStore.handleItemContextMenu(e, this.dataGrid)}
          onExporting={this.onExporting}
          selection={{ mode: 'single' }}>
          <FilterPanel visible={true} />
          <Paging enabled={true} defaultPageSize={25} />
          <Pager
            allowedPageSizes={[25, 50, 100]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            infoText={'Всего: {2}'}
          />
          <GroupPanel visible={true} />
          <Grouping contextMenuEnabled={true} />
          <HeaderFilter visible={true} allowSearch={true} />

          <Export enabled={true} excelWrapTextEnabled={true} />
          <FilterRow visible={true} />
          <Column
            allowFixing={true}
            dataField={'itineraryid'}
            dataType="string"
            caption={'id'}
            visible={false}
            defaultFilterValues={this.getFiler('id')}
          />
          <Column
            allowFixing={true}
            dataField={numberDataField}
            dataType="string"
            caption={'Номер'}
            defaultFilterValues={this.getFiler('w')}
          />
          <Column allowFixing={true} dataField="loadstationcode" dataType="string" caption={'Код станции погрузки'} />
          <Column
            allowFixing={true}
            dataField="loadstationname"
            dataType="string"
            caption={'Станция погрузки'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="deststationcode" dataType="string" caption={'Код станции назначения'} />
          <Column
            allowFixing={true}
            dataField="deststationname"
            dataType="string"
            caption={'Станции назначения'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="sendnumber" dataType="string" caption={'Накладная'} />
          <Column allowFixing={true} dataField="etsngcode" dataType="string" caption={'Код груза'} />
          <Column
            allowFixing={true}
            dataField="freightname"
            dataType="string"
            caption={'Груз'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="freightnetto" dataType="number" caption={'Вес груза'}>
            <HeaderFilter groupInterval={10000} />
          </Column>
          <Column
            allowFixing={true}
            dataField="started"
            dataType="date"
            caption={'Начало'}
            selectedFilterOperation={dateFilter && dateFilter.length ? 'between' : null}
            filterValue={dateFilter}
          />
          <Column allowFixing={true} dataField="ended" dataType="date" caption={'Окончание'} />
          <Column allowFixing={true} dataField="iskpdate" dataType="date" caption={'ИСКП'} />
          <Column allowFixing={true} dataField="pvgudate" dataType="date" caption={'ПВГУ'} />
          <Column allowFixing={true} dataField="uvgudate" dataType="date" caption={'УВГУ'} />
          <Column allowFixing={true} dataField="vigdate" dataType="date" caption={'ВЫГ'} />
          <Column allowFixing={true} dataField="senddate" dataType="date" caption={'Отправка'} />
          <Column allowFixing={true} dataField="receivedate" dataType="date" caption={'Прибытие'} />
          <Column allowFixing={true} dataField="trainindex" dataType="string" caption={'Индекспоезда'} />
          <Column allowFixing={true} dataField="senderokpo" dataType="string" caption={'ОКПО отправителя'} />
          <Column
            allowFixing={true}
            dataField="sendername"
            dataType="string"
            caption={'Отправитель'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column allowFixing={true} dataField="receiverokpo" dataType="string" caption={'ОКПО получателя'} />
          <Column
            allowFixing={true}
            dataField="receivername"
            dataType="string"
            caption={'Получатель'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column
            allowFixing={true}
            dataField="claimname"
            dataType="string"
            caption={'Заявка'}
            cellRender={ItineraryClaimDxCell}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column
            allowFixing={true}
            calculateFilterExpression={ignoreCaseFilter}
            dataField="contractid"
            dataType="string"
            caption={'Договор'}
            cellRender={ItineraryContractDxCell}
            allowGrouping={false}
            allowFiltering={false}
          />
          <Column
            allowFixing={true}
            calculateFilterExpression={ignoreCaseFilter}
            dataField="extid"
            dataType="string"
            caption={'Доп. соглашение'}
            cellRender={ItineraryExtDxCell}
            allowGrouping={false}
            allowFiltering={false}
          />
          <Column allowFixing={true} dataField="pricewithnds" dataType="string" caption={'Стоимость (с НДС)'} />

          <MasterDetail
            enabled={true}
            component={(e) => {
              return <OperationsDataGrid id={e.data.data.itineraryid} type={type} />;
            }}
          />
          <Summary>
            {groupSummary.map((x, index) => (
              <GroupItem key={`GroupItem_${index}`} {...x} />
            ))}
            {totalSummary.map((x, index) => (
              <TotalItem key={`TotalItem_${index}`} {...x} />
            ))}
          </Summary>
        </DataGrid>
      </AdaptiveCard>
    );
  }
  onExporting = (e) => {
    const workbook = new this.ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Лист1');
    const name = 'Рейсы';

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: { row: 1, column: 1 },
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;
      },
    }).then(function () {
      const date = moment().format(GLOBAL_DATE_FORMAT).toString();
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), date + `-Отчёт-${name}.xlsx`);
      });
    });
    e.cancel = true;
  };
}

export default ItineraryEconomicalsDataGrid;
