import DataGrid, { Column, GroupPanel, HeaderFilter, Scrolling } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ClaimReport } from '@stores/requestIlsStore/requestIlsStoreData';

interface State {}

interface Props {
  ilsClaim: ClaimReport;
}

@observer
class IlsClaimWagonLoaded extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      selected: [],
    };
  }

  render() {
    return (
      <DataGrid
        allowColumnReordering={true}
        dataSource={this.props.ilsClaim.wagons.filter((w) => ['loaded', 'onway', 'unloading', 'complete'].includes(w.state))}
        allowColumnResizing={true}
        showBorders={true}
        columnResizingMode={'widget'}
        columnAutoWidth={true}
        showColumnLines={true}
        columnMinWidth={10}
        showRowLines={true}
        remoteOperations={false}>
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Scrolling mode="virtual" />
        <Column allowFixing={true} dataField="wagonNumber" caption={'№ вагона'} />
        <Column
          allowFixing={true}
          dataField="state"
          caption={'Статус вагона'}
          cellRender={(e) => {
            switch (e.value) {
              case 'toload':
                return 'Заадресован';
              case 'loaded':
                return 'Погружен';
              case 'onway':
                return 'В пути';
              case 'unloading':
                return 'Разгрузка';
              case 'complete':
                return 'Рейс завершен';
              default:
                return '';
            }
          }}
        />

        <Column allowFixing={true} dataField="sendDate" caption={'Дата погрузки'} dataType={'date'} />
        <Column allowFixing={true} dataField="dislStationName" caption={'Станция дислокации'} />
        <Column allowFixing={true} dataField="destStationName" caption={'Станция назначения'} />

        <Column allowFixing={true} dataField="operationName" caption={'Последняя операция'} />
        <Column allowFixing={true} dataField="operationDate" caption={'Дата последней операции'} dataType={'dateTime'} />
        <Column allowFixing={true} dataField="etsngName" caption={'Груз'} />

        <Column allowFixing={true} dataField="etsngCode" caption={'Код груза ЕТСНГ'} />
        <Column allowFixing={true} dataField="sendNetto" dataType={'number'} caption={'Вес груза'} />
        <Column allowFixing={true} dataField="distance" dataType={'number'} caption={'Расстояние до станции назначения'} />
      </DataGrid>
    );
  }
}

export default IlsClaimWagonLoaded;
