import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { animateScroll } from 'react-scroll';

import DialogContent from './DialogContent';
import MessageInput from './DialogContent/MessageInput';

const { Content } = Layout;

interface State {
  editedDialog: any;
}

interface Props {}

class MessengerPageEmbedded extends Component<Props, State> {
  componentDidMount() {
    this.scrollToBottomMassageList();
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
    this.scrollToBottomMassageList();
  }

  scrollToBottomMassageList() {
    animateScroll.scrollToBottom({
      containerId: 'messageList',
      smooth: 'easeInOutQuint',
      duration: 100,
    });
  }

  render() {
    const contentHeight = 400;
    return (
      <>
        <Layout className="site-layout-background" style={{ height: contentHeight }}>
          <ScrollBar component="div" style={{ minHeight: 280 }} id={'messageList'}>
            <Content style={{ minHeight: 280 }}>
              <DialogContent />
            </Content>
          </ScrollBar>
        </Layout>
        <div style={{ width: '100%', backgroundColor: 'white', padding: 20 }}>
          <MessageInput />
        </div>
      </>
    );
  }
}

export default observer(MessengerPageEmbedded);
