import { DeleteOutlined, EditOutlined, FormOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Column,
  Export,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import { dxElement } from 'devextreme/core/element';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { dxToolbarOptions } from 'devextreme/ui/toolbar';
import saveAs from 'file-saver';
import moment from 'moment';
import React, { Component } from 'react';

import NotesDxCellReport from '@components/fw/DxCellTemplates/NotesDxCellReport';
import StationDxCell from '@components/fw/DxCellTemplates/StationDxCell';
import VatDxCell from '@components/fw/DxCellTemplates/VatDxCell';
import FilterEditor from '@components/fw/FiltersEditor/FilterEditor';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import filterStore, { FilterContentModel, ReportFilterType } from '@stores/FilterStore/filterStore';
import designStore from '@stores/designStore/designStore';
import { Vat } from '@stores/loadRegistryStore/loadRegistryData';
import loadRegistryStore from '@stores/loadRegistryStore/loadRegistryStore';
import markStore from '@stores/markStore/markStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import userStore from '@stores/userStore';

interface LoadingRegisterTableProps {
  dataSource: DataSource;
}

interface LoadingRegisterTableState {
  autoExpandAll: boolean;
  reloading: boolean;
}

class LoadingRegisterTable extends Component<LoadingRegisterTableProps, LoadingRegisterTableState> {
  dataGrid: DataGrid;

  constructor(props: LoadingRegisterTableProps) {
    super(props);
    this.state = {
      autoExpandAll: true,
      reloading: false,
    };
  }

  componentDidMount() {
    filterStore.setSelected(this.filterType, null);
  }

  gotoItineraries(id: string) {
    const url = `/app#/dispatcher/itineraries/wagons?id=${id}`;
    designStore.adaptiveGoto(url);
  }

  calculatedCellVatAmount = (rowData: any) => {
    return rowData.vatRate === Vat.Without ? 'Без НДС' : rowData.vatAmount?.toLocaleString('ru');
  };

  calculatedCellSupplierVatAmount = (rowData: any) => {
    return rowData.supplierVatRate === Vat.Without ? 'Без НДС' : rowData.supplierVatAmount?.toLocaleString('ru');
  };

  render() {
    const { dataSource } = this.props;
    if (this.state.reloading) return null;
    return (
      <DataGrid
        ref={(r) => (this.dataGrid = r)}
        height={'calc(100vh - var(--card-padding) - 117px'}
        onSelectionChanged={(e) => {
          loadRegistryStore.setSelected(e.selectedRowsData);
        }}
        dataSource={dataSource}
        showBorders={true}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        remoteOperations={true}
        showRowLines={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnMinWidth={60}
        columnResizingMode={'widget'}
        onExporting={this.onExporting}
        onToolbarPreparing={this.onToolbarPreparing.bind(this)}>
        <Paging enabled={true} defaultPageSize={50} />
        <Pager
          allowedPageSizes={[25, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
          infoText={'Всего: {2}'}
        />
        <StateStoring enabled={true} type="custom" customSave={(e) => this.handleStorageSave(e)} customLoad={this.handleStorageLoad} />
        <HeaderFilter visible={true} allowSearch={true} />
        <FilterRow visible={true} />
        <Export enabled={true} allowExportSelectedData={false} excelWrapTextEnabled={true} />
        <Selection mode="multiple" showCheckBoxesMode={'none'} />
        <Sorting mode="multiple" />
        <Grouping autoExpandAll={this.state.autoExpandAll} />
        <GroupPanel visible={true} />
        <Template name={'filter'} render={() => this.filterSelectorTemplate()} />
        <Template
          name={'edit'}
          render={() => {
            return (
              <Tooltip placement={'bottom'} title={'Редактировать'}>
                <Button
                  onClick={() => {
                    loadRegistryStore.updateListCompanyHandler('all');
                  }}
                  disabled={false}
                  type={'link'}>
                  <EditOutlined />
                </Button>
              </Tooltip>
            );
          }}
        />
        <Template
          name={'editSupplier'}
          render={() => {
            return (
              <Tooltip placement={'bottom'} title={'Изменить поставщика'}>
                <Button
                  onClick={() => {
                    loadRegistryStore.updateListCompanyHandler('supplierOnly');
                  }}
                  disabled={false}
                  type={'link'}>
                  <FormOutlined />
                </Button>
              </Tooltip>
            );
          }}
        />
        <Template
          name={'delete'}
          render={() => {
            return (
              <Tooltip placement={'bottom'} title={'Удалить'}>
                <Popconfirm
                  title="Удалить？"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={() => {
                    loadRegistryStore.deleteListCompanyHandler().then(() => {
                      this.props.dataSource.reload();
                    });
                  }}
                  disabled={false}>
                  <Button disabled={false} type={'link'}>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Tooltip>
            );
          }}
        />

        {INDEX_COLUMN}

        <Column
          fixed={true}
          allowReordering={true}
          caption="Номер вагона"
          dataField="wagonnumber"
          cellRender={({ data }) => {
            return (
              <a
                href={'#'}
                onClick={(e) => {
                  e.preventDefault();
                  this.gotoItineraries(data.itineraryid);
                }}>
                {data.wagonnumber}
              </a>
            );
          }}
        />
        <Column caption="Номер отправки" dataField="sendnumber" />

        <Column
          allowReordering={true}
          caption="Дата погрузки"
          dataField="senddate"
          defaultSortIndex={3}
          defaultSortOrder="desc"
          dataType={'date'}
          cellRender={({ data }) => moment(data.senddate).format(GLOBAL_DATE_FORMAT)}
        />

        <Column
          allowReordering={true}
          caption="Станция погрузки"
          dataField="loadstationname"
          cellRender={designStore.isFormattedNameCells ? StationDxCell : undefined}
          groupCellRender={designStore.isFormattedNameCells ? StationDxCell : undefined}
          dataType={'string'}
          defaultSortOrder="asc"
          defaultSortIndex={1}
        />

        <Column
          allowReordering={true}
          caption="Станция выгрузки"
          dataField="deststationname"
          cellRender={designStore.isFormattedNameCells ? StationDxCell : undefined}
          groupCellRender={designStore.isFormattedNameCells ? StationDxCell : undefined}
          dataType={'string'}
          defaultSortOrder="asc"
          defaultSortIndex={2}
        />

        <Column allowReordering={true} caption="Груз" dataField="etsngname" />

        <Column caption="Вес груза" dataField="netto" cellRender={(e) => (+e.value / 1000).toFixed(3)} />

        <Column allowReordering={true} caption="Наименование поставщика" dataField="suppliername" />
        {userStore.isAdmin && <Column caption="Название компании" dataField="companyname" />}

        <Column
          allowReordering={true}
          caption="Поставщик"
          dataField="notes3json"
          cellRender={NotesDxCellReport}
          groupCellRender={NotesDxCellReport}
          calculateFilterExpression={(dxValue, selectedFilterOperations, target) => {
            return ['notes3json', selectedFilterOperations.replace('=', 'contains'), dxValue];
          }}
          headerFilter={{
            dataSource: {
              load: (loadOptions: any) => {
                return markStore.getNotes(userStore.userData.companyId, loadOptions.searchValue, loadOptions.take, loadOptions.skip);
              },
              paginate: false,
            },
          }}
        />

        <Column
          allowReordering={true}
          caption="Имя клиента"
          dataField="clientname"
          groupIndex={0}
          dataType={'string'}
          defaultSortOrder="asc"
          defaultSortIndex={0}
        />

        <Column allowReordering={true} caption={'Клиент'}>
          <Column allowReordering={true} caption="Ставка" dataField="rate" customizeText={(e) => e.value?.toLocaleString('ru')} />

          <Column allowReordering={true} caption="Ставка НДС" dataField="vatrate" cellRender={VatDxCell} />

          <Column
            allowReordering={true}
            caption="Стоимость услуг"
            dataField="servicesamount"
            customizeText={(e) => e.value?.toLocaleString('ru')}
          />

          <Column allowReordering={true} caption="Сумма НДС" dataField="vatamount" calculateCellValue={this.calculatedCellVatAmount} />

          <Column
            allowReordering={true}
            caption="Стоимость услуг с НДС"
            dataField="servicesamountwithvat"
            customizeText={(e) => e.value?.toLocaleString('ru')}
          />
        </Column>

        <Column allowReordering={true} caption={'Поставщик'}>
          <Column allowReordering={true} caption="Ставка" dataField="supplierrate" customizeText={(e) => e.value?.toLocaleString('ru')} />

          <Column allowReordering={true} caption="Ставка НДС" dataField="suppliervatrate" cellRender={VatDxCell} />

          <Column
            allowReordering={true}
            caption="Стоимость услуг"
            dataField="supplierservicesamount"
            customizeText={(e) => e.value?.toLocaleString('ru')}
          />

          <Column
            allowReordering={true}
            caption="Сумма НДС"
            dataField="suppliervatamount"
            calculateCellValue={this.calculatedCellSupplierVatAmount}
          />

          <Column
            allowReordering={true}
            caption="Стоимость услуг с НДС"
            dataField="supplierservicesamountwithvat"
            customizeText={(e) => e.value?.toLocaleString('ru')}
          />
        </Column>
        <Column
          allowReordering={true}
          caption="Метки1"
          dataField="notes1json"
          cellRender={NotesDxCellReport}
          groupCellRender={NotesDxCellReport}
          calculateFilterExpression={(dxValue, selectedFilterOperations, target) => {
            return ['notes1json', selectedFilterOperations.replace('=', 'contains'), dxValue];
          }}
          headerFilter={{
            dataSource: {
              load: (loadOptions: any) => {
                return markStore.getNotes(userStore.userData.companyId, loadOptions.searchValue, loadOptions.take, loadOptions.skip);
              },
              paginate: false,
            },
          }}
        />
        <Column
          allowReordering={true}
          caption="Метки2"
          dataField="notes2json"
          cellRender={NotesDxCellReport}
          groupCellRender={NotesDxCellReport}
          calculateFilterExpression={(dxValue, selectedFilterOperations, target) => {
            return ['notes2json', selectedFilterOperations.replace('=', 'contains'), dxValue];
          }}
          headerFilter={{
            dataSource: {
              load: (loadOptions: any) => {
                return markStore.getNotes(userStore.userData.companyId, loadOptions.searchValue, loadOptions.take, loadOptions.skip);
              },
              paginate: false,
            },
          }}
        />
        <Column allowFixing={true} dataField="allsendnumbers" dataType="string" caption={'Досылки'} />
      </DataGrid>
    );
  }

  filterSelectorTemplate = () => {
    return <FilterEditor reportType={this.filterType} reportId={null} onChange={(item) => this.hardReload(item)} />;
  };

  hardReload(item: FilterContentModel | null) {
    if (this.dataGrid) {
      if (!item) {
        this.dataGrid.instance.clearFilter();
        this.dataGrid.instance.clearGrouping();
        this.dataGrid.instance.clearSorting();
      }
      this.setState({ reloading: true });
      setTimeout(() => this.setState({ reloading: false }), 1);
    }
  }

  handleStorageSave = (e) => {
    filterStore.setContent(e, this.filterType);
  };

  handleStorageLoad = (e) => {
    return filterStore.getContent(this.filterType);
  };

  get filterType(): ReportFilterType {
    return 'loadRegistry';
  }

  async onExporting(e) {
    const ExcelJs = await import('exceljs');
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Реестр');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function (options) {
        if (options.gridCell.column.dataField === 'notesjson' && options.gridCell.value) {
          const parse: any[] = JSON.parse(options.gridCell.value);
          const notes = parse.map(({ title }) => title);

          options.excelCell.value = notes.join(', ');
        }

        if (options.gridCell.column.dataField === 'netto' && options.gridCell.value) {
          const num = options.gridCell.value / 1000;
          const whole = Math.trunc(num);
          const frac = Number(String(num.toFixed(3)).split('.')[1] || 0);

          options.excelCell.value = `${whole},${frac}`;
        }
      },
    }).then(function () {
      let fileName = 'Реестр_погрузки_';
      if (loadRegistryStore.picker == 'month') {
        fileName += moment(loadRegistryStore.dates[0]).format('YYYYMM');
      } else {
        fileName += moment(loadRegistryStore.dates[0]).format('YYYYMMDD') + '_' + moment(loadRegistryStore.dates[1]).format('YYYYMMDD');
      }
      fileName += '_' + moment().format('YYYYMMDDHHmm');
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
      });
    });
  }

  onToolbarPreparing(e: { component?: any; element?: dxElement; model?: any; toolbarOptions?: dxToolbarOptions }) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'edit',
      },
      {
        location: 'after',
        template: 'editSupplier',
      },
      {
        location: 'after',
        template: 'delete',
      },
      {
        location: 'after',
        template: 'filter',
      },
      {
        location: 'after',
        widget: 'dxButton',
        showText: 'inMenu',
        locateInMenu: 'auto',
        options: {
          icon: 'hierarchy',
          text: 'Свернуть/развернуть',
          hint: 'Свернуть/развернуть',
          onClick: () => {
            this.setState({ autoExpandAll: !this.state.autoExpandAll });
          },
        },
      },

      {
        location: 'after',
        widget: 'dxButton',
        showText: 'inMenu',
        locateInMenu: 'auto',
        options: {
          icon: 'redo',
          text: 'Сбросить',
          hint: 'Сбросить',
          onClick: () => {
            this.dataGrid.instance.clearFilter();
            this.dataGrid.instance.clearSorting();
            this.dataGrid.instance.clearSelection();
            this.dataGrid.instance.clearGrouping();
            this.dataGrid.instance.columnOption('clientname', { sortOrder: 'asc', sortIndex: 0, groupIndex: 0 });
            this.dataGrid.instance.columnOption('loadstationname', { sortOrder: 'asc', sortIndex: 1 });
            this.dataGrid.instance.columnOption('deststationname', { sortOrder: 'asc', sortIndex: 2 });
            this.dataGrid.instance.columnOption('senddate', { sortOrder: 'asc', sortIndex: 3 });
          },
        },
      },

      {
        location: 'after',
        widget: 'dxButton',
        showText: 'inMenu',
        locateInMenu: 'auto',
        options: {
          icon: 'refresh',
          text: 'Oбновить',
          hint: 'Oбновить',
          onClick: () => {
            loadRegistryStore.refreshData();
          },
        },
      },
    );
  }
}

export default LoadingRegisterTable;
