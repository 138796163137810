import React, { Component } from 'react';

import optionsStore from '@stores/optionsStore';

interface Props {
  code: number;
}

interface State {
  wagonTypeName: string;
  isBusy: boolean;
}

class WagonType extends Component<Props, State> {
  state = {
    wagonTypeName: '',
    isBusy: false,
  };

  async componentDidMount() {
    if (!optionsStore.wagonTypesDictionary.length) {
      await optionsStore.getTariffDictionary();
    }
    const find = optionsStore.wagonTypesDictionary.find((w) => w.wagonTypeCode == this.props.code);
    if (find) {
      this.setState({ wagonTypeName: find.wagonTypeName });
    }
  }

  render() {
    return (
      <p style={{ display: 'grid' }}>
        <p className={'ellipsis'}>{this.state.wagonTypeName}</p>
      </p>
    );
  }
}

export default WagonType;
