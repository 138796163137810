import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Column,
  Export,
  GroupPanel,
  Grouping,
  HeaderFilter,
  IDataGridOptions,
  Selection,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import moment from 'moment/moment';
import React, { Component } from 'react';

import StationDxCell from '@components/fw/DxCellTemplates/StationDxCell';

import designStore from '@stores/designStore/designStore';
import holdsStore from '@stores/holdsStore/holdsStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import userStore from '@stores/userStore';

import { exportTable, prepareTableToolbar } from './util';

export default class HoldsTable extends Component<HoldsTableProps, HoldsTableState> {
  dataGrid: DataGrid;

  constructor(props: HoldsTableProps) {
    super(props);
    this.state = {
      autoExpandAll: true,
    };
  }

  /*#region Actions*/
  gotoItineraries(id: string) {
    const url = `/app#/dispatcher/itineraries/wagons?id=${id}`;
    designStore.adaptiveGoto(url);
  }

  /*#endregion*/

  /*#region Handlers*/
  onSelectionChanged(event: any) {
    holdsStore.setSelectedRowKeys(event.selectedRowKeys);
  }

  /*#endregion*/

  render() {
    const { dataSource, onEdit, isGroupSelected } = this.props;

    const createGridRef = (ref: DataGrid) => {
      return (this.dataGrid = ref);
    };

    /*#region Renderers */
    const wagonNumberColumnRender = (params: any) => {
      const onClick = (event: any) => {
        event.preventDefault();
        this.gotoItineraries(params.data.itineraryId);
      };

      return <a onClick={onClick}>{params.data.wagonNumber}</a>;
    };

    const indexColumnRender = (cellElement: any) => {
      const offset = cellElement.component.getVisibleRows().find((x) => x.rowType === 'group')
        ? 0
        : cellElement.component.pageSize() * cellElement.component.pageIndex();

      return <span>{cellElement.row.dataIndex + 1 + offset}</span>;
    };

    const editTooltipRender = () => {
      return (
        <Tooltip placement={'bottom'} title={'Редактировать'}>
          <Button onClick={onEdit} disabled={!isGroupSelected} type={'link'}>
            <EditOutlined />
          </Button>
        </Tooltip>
      );
    };

    const headerCellRender = (params: any) => {
      return (
        <Tooltip title={params.column.caption}>
          <span className={'dx-datagrid-headers'}>{params.column.caption}</span>
        </Tooltip>
      );
    };

    /*#endregion*/

    return (
      <DataGrid
        ref={createGridRef}
        className={'holdsDataGrid'}
        onSelectionChanged={this.onSelectionChanged}
        dataSource={dataSource}
        showBorders={true}
        showRowLines={true}
        columnAutoWidth={false}
        columnMinWidth={60}
        columnWidth={120}
        columnResizingMode={'widget'}
        remoteOperations={false}
        paging={{ enabled: false }}
        onExporting={exportTable}
        allowColumnResizing={true}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        onToolbarPreparing={prepareTableToolbar.bind(this)}>
        <Grouping autoExpandAll={this.state.autoExpandAll} />
        <GroupPanel visible={true} />
        <Sorting mode="multiple" />
        <HeaderFilter visible={true} allowSearch={true} />
        <Selection mode="multiple" selectAllMode={'allPages'} showCheckBoxesMode={'none'} />
        <Export enabled={true} allowExportSelectedData={false} excelWrapTextEnabled={true} />
        <StateStoring enabled={true} type={'localStorage'} storageKey={'holdsGrid' + userStore.userData.userId} />
        <Template name={'edit'} render={editTooltipRender} />

        <Column
          allowFixing={true}
          caption={'№'}
          minWidth={30}
          width={30}
          fixed={true}
          allowEditing={false}
          allowReordering={false}
          allowFiltering={false}
          allowSearch={false}
          allowGrouping={false}
          allowSorting={false}
          allowHeaderFiltering={false}
          allowExporting={false}
          defaultSortIndex={0}
          cellRender={indexColumnRender}
        />

        <Column
          fixed={true}
          allowReordering={true}
          headerCellRender={headerCellRender}
          caption="№ вагона"
          width={80}
          minWidth={80}
          dataField="wagonNumber"
          cellRender={wagonNumberColumnRender}
        />

        <Column allowReordering={true} headerCellRender={headerCellRender} caption="Груженый рейс" defaultSortIndex={1}>
          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="№ накладной"
            width={80}
            minWidth={80}
            dataField="sendNumber"
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Дата погрузки"
            dataField="sendDate"
            defaultSortIndex={3}
            defaultSortOrder="desc"
            dataType={'date'}
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Дата прибытия"
            dataField="receiveDate"
            defaultSortIndex={3}
            defaultSortOrder="desc"
            dataType={'date'}
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Станция погрузки"
            dataField="loadStationName"
            cellRender={designStore.isFormattedNameCells ? StationDxCell : undefined}
            groupCellRender={designStore.isFormattedNameCells ? StationDxCell : undefined}
            dataType={'string'}
            defaultSortOrder="asc"
            defaultSortIndex={1}
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Станция выгрузки"
            dataField="destStationName"
            cellRender={designStore.isFormattedNameCells ? StationDxCell : undefined}
            groupCellRender={designStore.isFormattedNameCells ? StationDxCell : undefined}
            dataType={'string'}
            defaultSortOrder="asc"
            defaultSortIndex={2}
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Вес груза (Тн)"
            dataField="netto"
            cellRender={(e) => (+e.value / 1000).toFixed(3)}
          />

          <Column allowReordering={true} headerCellRender={headerCellRender} caption="Груз" dataField="etsngName" />
        </Column>

        <Column allowReordering={true} headerCellRender={headerCellRender} caption="Порожний рейс под погрузку" defaultSortIndex={2}>
          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Дата прибытия на ст. погрузки"
            dataField="prevItineraryReceiveDate"
            defaultSortOrder="desc"
            dataType={'date'}
            cellRender={({ data }) => !!data.prevItineraryReceiveDate && moment(data.prevItineraryReceiveDate).format(GLOBAL_DATE_FORMAT)}
          />

          <Column allowReordering={true} headerCellRender={headerCellRender} caption="№ жд накладной" dataField="prevItinerarySendNumber" />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Ст. погрузки"
            dataField="prevItineraryLoadStationName"
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Простой на ст. погрузки, сут."
            dataField="holdTimeOnLoadStation"
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Норматив простоя на ст.погрузки, сут."
            dataField="loadHoldTimeNorm"
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Норматив штрафа за простой руб./сут."
            dataField="loadHoldSurchargeNorm"
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Сумма штрафа за простой под погрузкой, руб."
            dataField="loadHoldSurcharge"
          />
        </Column>

        <Column allowReordering={true} headerCellRender={headerCellRender} caption="Порожний рейс после выгрузки" defaultSortIndex={3}>
          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Дата отправления со ст. выгрузки"
            dataField="nextItinerarySendDate"
            defaultSortOrder="desc"
            dataType={'date'}
          />

          <Column allowReordering={true} headerCellRender={headerCellRender} caption="№ жд накладной" dataField="nextItinerarySendNumber" />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Ст. назначения"
            dataField="nextItineraryLoadStationName"
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Простой на ст. выгрузки, сут."
            dataField="holdTimeOnDestStation"
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Норматив простоя на ст.выгрузки, сут."
            dataField="destHoldTimeNorm"
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Норматив штрафа за простой руб./сут."
            dataField="destHoldSurchargeNorm"
          />

          <Column
            allowReordering={true}
            headerCellRender={headerCellRender}
            caption="Сумма штрафа за простой под выгрузкой, руб."
            dataField="destHoldSurcharge"
          />
        </Column>

        <Column
          allowReordering={true}
          headerCellRender={headerCellRender}
          caption="Общий простой по ст. погрузки и выгрузки , сут."
          dataField="totalHoldTime"
        />

        <Column
          allowReordering={true}
          headerCellRender={headerCellRender}
          caption="Общая сумма шрафа за простой, руб."
          dataField="totalHoldSurcharge"
        />

        <Column allowReordering={true} headerCellRender={headerCellRender} caption="Комментарии" dataField="comment" />
        <Column allowFixing={true} dataField="allSendNumbers" dataType="string" caption={'Досылки'} />
      </DataGrid>
    );
  }
}

/*#region Typing*/
interface HoldsTableProps extends IDataGridOptions {
  onRefresh: () => void;
  onEdit: () => void;
  isGroupSelected: boolean;
}

interface HoldsTableState {
  autoExpandAll: boolean;
}

/*#endregion*/
