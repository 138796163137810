import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { Component } from 'react';

import { CompanyServiceModel } from '@stores/adminBilling/adminBillingData';
import adminBillingStore from '@stores/adminBilling/adminBillingStore';
import designStore from '@stores/designStore';

interface VersionEditorProps {
  data: CompanyServiceModel;
}

interface VersionEditorState {
  isBusy: boolean;
  visible: boolean;
}

class AdminBillingSourceEditor extends Component<VersionEditorProps, VersionEditorState> {
  constructor(props: VersionEditorProps) {
    super(props);

    this.state = {
      isBusy: false,
      visible: false,
    };
  }

  formRef: FormInstance;
  render() {
    const isBusy = this.state.isBusy;
    const data = this.props.data;
    const visible = this.state.visible;
    return (
      <>
        {this.isEditMode ? (
          <Button onClick={this.openDrawer} icon={<EditOutlined />} title={'Изменить'} size={'small'} type={'text'} />
        ) : (
          <Button onClick={this.openDrawer} icon={<PlusOutlined />} title={'Создать'} type={'text'} size={'small'}>
            создать
          </Button>
        )}
        <Drawer
          width={designStore.isMobile ? '100%' : 600}
          title={this.isEditMode ? 'Редактирование' : 'Создание '}
          onClose={this.handelClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}>
              <Button onClick={this.handelClose} style={{ marginRight: 8 }}>
                Отмена
              </Button>
              <Button type="primary" loading={isBusy} htmlType={'submit'} form={'version-editor'}>
                {this.isEditMode ? 'Сохранить' : 'Добавить'}
              </Button>
            </div>
          }>
          <Form
            layout="horizontal"
            labelCol={{ span: 8 }}
            name={'version-editor'}
            ref={(ref) => (this.formRef = ref)}
            onFinish={(e) => this.handleConfirm(e)}
            initialValues={data}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24}>
                <Form.Item
                  name={'name'}
                  label={'Название'}
                  rules={[
                    {
                      required: true,
                      message: 'Введите название',
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="strategyCode" label="Стратегия">
                  <Select getPopupContainer={(trigger) => trigger.parentElement}>
                    {adminBillingStore.strategies.map((s) => {
                      return (
                        <Select.Option key={s.code} value={s.code}>
                          {s.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }

  get isEditMode(): boolean {
    return this.props.data && this.props.data.id !== null;
  }

  openDrawer = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: true });
  };

  handelClose = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: false });
  };

  async handleConfirm(e) {
    let service = this.props.data;
    service.strategyName = null;
    service.name = e.name;
    service.strategyCode = e.strategyCode;
    this.setState({ isBusy: true });
    try {
      await adminBillingStore.editService(service);
      this.handelClose();
    } catch (e) {
    } finally {
      this.setState({ isBusy: false });
    }
  }
}

export default AdminBillingSourceEditor;
