import { Tag } from 'antd';
import React from 'react';

const getColor = (c) => {
  switch (c) {
    case 'onagr':
      return 'orange';
    case 'agr':
      return 'green';
    case 'diff':
      return 'blue';
    default:
      return 'lightgrey';
  }
};
const getTitle = (c) => {
  switch (c) {
    case 'onagr':
      return 'Документ на согласовании';
    case 'agr':
      return 'Документ согласован';
    case 'diff':
      return 'Документы имеют различные статусы согласования';
    default:
      return 'Документ отсутствует';
  }
};

const DocumentsDxCell: React.FC<any> = (props) => {
  const value = typeof props.value == 'string' ? JSON.parse(props.value) : null;
  return value ? (
    <span>
      <Tag color={getColor(value.commercialOfferStatus)} title={getTitle(value.commercialOfferStatus)}>
        КП
      </Tag>
      <Tag color={getColor(value.contractStatus)} title={getTitle(value.contractStatus)}>
        Договор
      </Tag>
      <Tag color={getColor(value.contractExtStatus)} title={getTitle(value.contractExtStatus)}>
        ДОП
      </Tag>
    </span>
  ) : null;
};

export default DocumentsDxCell;
