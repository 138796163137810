export interface HoldModel {
  loadRegistryId?: string;
  itineraryId: string;
  comment: string;
  totalHoldTime: number;
  totalHoldSurcharge: number;

  /*#region Груженый рейс*/

  /** Номер вагона (Груженый рейс)*/
  wagonNumber: number;

  /** Номер жд накладной (Груженый рейс)*/
  sendNumber: string;

  /** Дата погрузки (Груженый рейс)*/
  sendDate: Date | null;

  /** Дата отправки (Груженый рейс)*/
  receiveDate: Date | null;

  /** Код станции погрузки (Груженый рейс)*/
  loadStationCode?: number;

  /** Наименование станции погрузки (Груженый рейс)*/
  loadStationName: number;

  /** Код станции выгрузки (Груженый рейс)*/
  destStationCode?: number;

  /** Наименование станции выгрузки (Груженый рейс)*/
  destStationName: number;

  /** Код груза (Груженый рейс)*/
  etsngCode?: string;

  /** Наименование груза (Груженый рейс)*/
  etsngName: string;

  /** Вес груза (Груженый рейс)*/
  netto: number;

  /*#endregion*/

  /*#region Порожний рейс под погрузку*/

  /** Дата прибытия вагона на станцию погрузки (рейс под погрузку)*/
  prevItineraryReceiveDate: Date | null;

  /** Номер жд накладной (рейс под погрузку)*/
  prevItinerarySendNumber: string;

  /** Код станции назначения (рейс под погрузку)*/
  prevItineraryDestStationCode?: number;

  /** Наименование станции назначения (рейс под погрузку)*/
  prevItineraryDestStationName: string;

  /** Код станции погрузки (рейс под погрузку)*/
  prevItineraryLoadStationCode?: number;

  /** Наименование станции погрузки (рейс под погрузку)*/
  prevItineraryLoadStationName: string;

  /** Время простоя на станции погрузки (рейс под погрузку)*/
  holdTimeOnLoadStation: number;

  /** Норматив простоя на ст.погрузки, руб/сут. (рейс под погрузку)*/
  loadHoldSurchargeNorm: number;

  /** Норматив простоя на ст.погрузки, сут. (рейс под погрузку)*/
  loadHoldTimeNorm: number;

  /** Штраф за простой (рейс под погрузку)*/
  loadHoldSurcharge: number;

  /** Дата начала простоя на ст. погрузки (рейс под погрузку)*/
  loadStationHoldStartDate: Date | null;

  /** Дата погрузки на ст. погрузки (рейс под погрузку)*/
  loadSendDate: Date | null;

  /*#endregion*/

  /*#region Порожний рейс после выгрузки*/

  /** Дата отправления порожнего вагона со ст.выгрузки (рейс после выгрузки)*/
  nextItinerarySendDate: Date | null;

  /** Номер жд накладной (рейс после выгрузки)*/
  nextItinerarySendNumber: string;

  /** Код станции назначения (рейс после выгрузки)*/
  nextItineraryLoadStationCode?: number;

  /** Наименовании станции назначения (рейс после выгрузки)*/
  nextItineraryLoadStationName: string;

  /** Время простоя на станции выгрузки (рейс после выгрузки)*/
  holdTimeOnDestStation: number;

  /** Норматив простоя на ст.выгрузки, руб/сут. (рейс после выгрузки)*/
  destHoldSurchargeNorm: number;

  /** Норматив простоя на ст.выгрузки, сут. (рейс после выгрузки)*/
  destHoldTimeNorm: number;

  /** Штраф за простой (рейс после выгрузки)*/
  destHoldSurcharge: number;

  /** Дата начала простоя на ст. выгрузки / погранперехода (рейс после выгрузки)*/
  destStationHoldStartDate: Date | null;

  /** Дата окончания простоя на ст. выгрузки (рейс после выгрузки)*/
  destStationHoldEndDate: Date | null;

  /*#endregion*/
}

export class HoldUpdateModel {
  loadRegistryIds: Array<string>;

  changeComment: boolean;
  comment: string;

  changeLoadStationHoldStartDate: boolean;
  loadStationHoldStartDate: Date | null;
  changeLoadSendDate: boolean;
  loadSendDate: Date | null;
  changeLoadHoldSurchargeNorm: boolean;
  loadHoldSurchargeNorm: number;
  changeLoadHoldTimeNorm: boolean;
  loadHoldTimeNorm: number;

  changeDestStationHoldStartDate: boolean;
  destStationHoldStartDate: Date | null;
  changeDestStationHoldEndDate: boolean;
  destStationHoldEndDate: Date | null;
  changeDestHoldSurchargeNorm: boolean;
  destHoldSurchargeNorm: number;
  changeDestHoldTimeNorm: boolean;
  destHoldTimeNorm: number;

  constructor(data: HoldModel[]) {
    if (!data || !Array.isArray(data) || !data.length) throw new Error('Array is empty');

    const first = data[0];

    if (data.length > 1) {
      Object.keys(this).forEach((key) => {
        if (!data.find((d) => d[key] !== first[key])) {
          this[key] = first[key];
          this['change' + this.capitalise(key)] = true;
        }
      });
    } else {
      Object.keys(this).forEach((key) => {
        this[key] = first[key];
        this['change' + this.capitalise(key)] = true;
      });
    }
    this.loadRegistryIds = data.map((i) => i.loadRegistryId);
  }

  private capitalise(str: string) {
    return str[0].toUpperCase() + str.slice(1);
  }
}
