import HttpClient from '@utils/httpClient';

import { UserModel } from '../usersStore';
import { DistributionModel } from './dictributionData';

const distributionService = {
  async getData(): Promise<DistributionModel[]> {
    return (await HttpClient.get(`/api/distribution/data`)).data;
  },

  async update(data: DistributionModel): Promise<number> {
    return (await HttpClient.put(`/api/distribution`, data)).data;
  },

  async delete(id: number): Promise<void> {
    return (await HttpClient.delete(`/api/distribution/${id}`)).data;
  },

  async getUsers(): Promise<UserModel[]> {
    let data = {
      companyIds: null,
      notRoles: null,
      withoutCurrentUser: true,
    };
    return (await HttpClient.post(`/api/user/list`, data)).data;
  },
};

export default distributionService;
