import { Modal, Spin } from 'antd';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

const DocView = React.lazy(() => import('./DocView'));

interface PdfViewModalProps {
  data: Blob;
  visible: boolean;
  onClose(e): void;
  type?: string;
  format?: 'album' | 'book';
}

interface PdfViewModalState {}

class PdfViewModal extends Component<PdfViewModalProps, PdfViewModalState> {
  constructor(props: PdfViewModalProps) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.data) return null;
    const format = this.props.format;
    return (
      <Modal
        footer={<div style={{ height: 20 }}></div>}
        style={{ zIndex: 1002 }}
        open={this.props.visible}
        destroyOnClose={true}
        width={designStore.isMobile ? '100%' : format === 'album' ? '90%' : '900px'}
        centered
        onCancel={this.props.onClose}
        okButtonProps={{ style: { display: 'none' } }}>
        <React.Suspense fallback={<Spin spinning={true} />}>
          <div style={{ height: format === 'album' ? null : '89vh' }}>
            <DocView data={this.props.data} type={this.props.type} />
          </div>
        </React.Suspense>
      </Modal>
    );
  }
}
export default PdfViewModal;
