import { Button } from 'antd';
import React from 'react';

import { BidRegistryDocumentStore } from '@stores/bidRegistryDocumentStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import { CreateOfferProps } from './create-offer.types';

export const CreateOffer = ({ isLoading }: CreateOfferProps) => {
  return (
    <Button
      block
      shape="round"
      type="primary"
      disabled={isLoading}
      onClick={() => BidRegistryDocumentStore.createOffer(registerOfBetsStore.bidRegistryId)}>
      Сформировать коммерческое предложение
    </Button>
  );
};
