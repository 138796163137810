import { Button, Tabs } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveHeaderTabs from '@components/fw/AdaptiveElements/AdaptiveHeaderTabs';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import SupportModal from '@components/fw/SupportModal/SupportModal';

import companyTariffStore from '@stores/companyTariffStore/companyTariffStore';
import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import userStore from '@stores/userStore/userStore';

import { logout } from '@utils/authority';

import CompanyTariffForm from './CompanyTariffForm';

interface State {
  selectedTab: 'form' | 'history';
}

class CompanyTariffPage extends Component<RouteComponentProps, State> {
  formRef: FormInstance;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      selectedTab: 'form',
    };
  }

  get companyName() {
    const company = companyTariffStore.tariffCompanies.find((c) => c.companyId == this.companyId);
    return company ? company.companyName : '';
  }

  componentDidMount(): void {
    document.title = 'Расчет тарифа ' + this.companyName;
    optionsStore.getTariffDictionary();
  }

  get companyId(): string {
    const { params } = this.props.match;
    return params['companyId'];
  }

  form: CompanyTariffForm;

  render() {
    const tab = this.state.selectedTab;
    const width = designStore.toolbarWidth;
    return (
      <>
        <AdaptivePageHeader
          className={'page_header'}
          title={[
            userStore.tmpUser ? (
              <Button shape="round" key={'logoutBtn'} onClick={() => logout()}>
                Вход
              </Button>
            ) : null,
            userStore.tmpUser ? <SupportModal {...this.props} key={'Support'} forTariff={true} /> : null,
          ]}
          subTitle={
            <AdaptiveHeaderTabs activeKey={this.state.selectedTab} onTabClick={(e) => this.setState({ selectedTab: e as any })}>
              <Tabs.TabPane tab={'Расчет'} key="form" />
              {/*<Tabs.TabPane tab={'История'} key="history" />*/}
            </AdaptiveHeaderTabs>
          }
        />
        {tab == 'history' && <div></div>}
        <div style={{ display: tab == 'form' ? null : 'none' }}>
          <CompanyTariffForm companyId={this.companyId} ref={(ref) => (this.form = ref)} />
        </div>
      </>
    );
  }
}

export default observer(CompanyTariffPage);
