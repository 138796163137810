import qs from 'qs';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';

import HoldDataGrid from './HoldDataGrid';

interface State {}

class HoldWagons extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  render() {
    let filter = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).w;
    let result = [];
    if (filter) {
      if (Array.isArray(filter)) {
        document.title = 'Простои ' + filter.join(' ');
        result = filter as string[];
      } else {
        document.title = 'Простои ' + filter;
        result = [filter] as string[];
      }
    } else {
      document.title = 'Простои ⬎ Вагоны';
    }
    return (
      <>
        <AdaptivePageHeader title={document.title} />
        <HoldDataGrid type={'wagons'} filterValues={result} />
      </>
    );
  }
}

export default HoldWagons;
