import { Checkbox } from 'antd';
import React, { Component } from 'react';

interface AccessTreeEditorProps {
  tree: any[];
  checked: any[];
  onChange(items: string[]): void;
}

const TreeItem: React.FC<{ item; level; checked: boolean; onChecked(item: any) }> = (props) => {
  return (
    <div style={{ marginLeft: 20 * props.level, padding: 3 }}>
      <Checkbox onChange={() => props.onChecked(props.item)} checked={props.checked}>
        {props.item.title}
      </Checkbox>
      <>{props.children}</>
    </div>
  );
};

class AccessTreeEditor extends Component<AccessTreeEditorProps> {
  render() {
    const tree = this.props.tree;
    const checked = this.props.checked || [];
    return (
      <div className={'usersRoleAccess'}>
        {tree.map((sidebarItem, index) => {
          const children = sidebarItem.children;
          return (
            <TreeItem
              key={`tree_item_${sidebarItem.key}`}
              item={sidebarItem}
              checked={checked.includes(sidebarItem.key)}
              level={1}
              onChecked={this.handleChange}>
              {children.map((tab, index) => {
                return (
                  <TreeItem
                    key={`tree_item_${tab.key}`}
                    item={tab}
                    checked={checked.includes(tab.key)}
                    level={2}
                    onChecked={this.handleChange}
                  />
                );
              })}
            </TreeItem>
          );
        })}
      </div>
    );
  }

  handleChange = (item: any) => {
    const checked = this.props.checked || [];
    if (checked.includes(item.key)) {
      const key = item.key;
      const childrenKeys = item.children.map((x) => x.key);
      const newChecked = checked.filter((x) => x !== key && !childrenKeys.includes(x));
      this.props.onChange(newChecked);
    } else {
      const key = item.key;
      const childrenKeys = item.children.map((x) => x.key);
      const newChecked = [...checked, key, ...childrenKeys];
      this.props.onChange(newChecked);
    }
  };
}

export default AccessTreeEditor;
