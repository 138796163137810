import { CompanyBankModel } from '../bankStore/BankData';
import { CompanySourceCountryModel } from '../dictStore/dictStore';
import { OrganizationRequisites } from '../organizationsStore/organizationsStoreData';
import { ITariffInfologResponseItem } from '../tariffInfologStore/tariffInfologData';

export interface CompanyModel extends OrganizationRequisites {
  companyId: string | null;
  name: string;
  infoBlockIds: number[] | null;
  sourceIds: string[] | null;
  availableDate: string;
  sourceCountries: CompanySourceCountryModel[];

  /**#region Автоподбор вагона*/

  /**Срок до очередного планового ремонта (сут) */
  autoSelectPlanDays: number | null;

  /**Максимальное расстояние до ближайших станций */
  autoSelectRadius: number | null;

  /**#endregion*/
  etranLogin: CompanyEtranLoginModel;
  taisLogin: CompanyEtranLoginModel;
  railwayLogin: CompanyEtranLoginModel;
  sigisLogin?: CompanyEtranLoginModel;

  operativeRequestRate: number;
  operativeRequestCurrencyCode: string;

  //Размер НДС за услуги ТК, в %.
  serviceVatPercentage: number;

  isTest: boolean;
  wagonLimit?: number;
  containerLimit?: number;
  requestLimit?: number;
  tariffLimit?: number;
  companyBanks?: CompanyBankModel[];
  distributionEmail?: string;
  deepInDays?: number;

  tariffsInfolog?: ITariffInfologResponseItem[];
  tariffInfolog?: string;
  billingProfiles: BillingProfile[];
  currencyCode: string;
}

export interface BillingProfile {
  billingProfileId?: string;
  billingProfileName: string;
  sourceCountries: CompanySourceCountryModel[];
}

export const emptyCompany: CompanyModel = {
  availableDate: null,
  companyId: null,
  name: null,
  infoBlockIds: [8, 9],
  sourceIds: [],
  autoSelectPlanDays: null,
  autoSelectRadius: null,
  sourceCountries: [],
  inn: null,
  kpp: null,
  okpo: null,
  ogrn: null,
  shortNameReq: null,
  fullNameReq: null,
  address: null,
  telephone: null,
  email: null,
  owner: null,
  okfs: null,
  okogu: null,
  okopf: null,
  oktmo: null,
  okato: null,
  country: null,
  addressMail: null,
  addressFact: null,
  view: 'ul',
  group: null,
  bankBik: null,
  bankName: null,
  bankAddress: null,
  bankCorBill: null,
  orgBill: null,
  etranLogin: { login: null, password: null },
  taisLogin: { login: null, password: null },
  railwayLogin: { login: null, password: null },
  signer: null,
  post: null,
  reason: null,
  attorney: null,
  glbuh: null,
  operativeRequestRate: 0,
  serviceVatPercentage: 0,
  isTest: true,
  wagonLimit: 10,
  containerLimit: 10,
  requestLimit: 10,
  tariffLimit: 10,
  billingProfiles: [],
  operativeRequestCurrencyCode: '810',
  currencyCode: '810',
};

export interface CompanyEtranLoginModel {
  login: string;
  password: string;
  isActive?: boolean;
}
