import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';

import messengerStore from '@stores/messengerStore/messengerStore';
import { ChatMessage } from '@stores/messengerStore/messengerStoreData';

import styles from './Message.module.scss';

interface MessageProps {
  message: ChatMessage;
  isMy: boolean;
  author: string;
  time: string;
}
const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const MessageContent = (props: { text: string; mine: boolean }) => {
  if (props.text) {
    return (
      <>
        {props.text.split('\n').map((t, index1) => (
          <div style={{ whiteSpace: 'normal', height: 'auto' }} key={index1 + 'prt1'}>
            {t.split(' ').map((part, index) =>
              URL_REGEX.test(part) ? (
                <a href={part} style={props.mine ? { color: '#ffffff' } : {}} target="_blank" key={`message_part_${index}`}>
                  {part}{' '}
                </a>
              ) : (
                part + ' '
              ),
            )}
          </div>
        ))}
      </>
    );
  } else {
    return <></>;
  }
};

const TextMessage = (props: { text: string; mine: boolean }) => {
  return <MessageContent {...props} />;
};

const AttachmentMessage = (props: { text: string; mine: boolean; fileName: string; onDownload: () => any }) => {
  return (
    <div>
      <div style={{ padding: 5 }}>
        <PaperClipOutlined style={{ fontSize: 34 }} />
        <span>{props.fileName}</span>
        <Tooltip title={'Скачать файл'}>
          <Button type="link" icon={<DownloadOutlined />} onClick={props.onDownload} style={{ color: 'inherit' }} />
        </Tooltip>
      </div>
      <div>
        <MessageContent {...props} />
      </div>
    </div>
  );
};

const Message: React.FC<MessageProps> = (props) => {
  const message = props.message;
  const jc = props.isMy ? 'flex-end' : 'flex-start';
  const color = props.isMy ? '#ffffff' : '#000000';
  const bg = props.isMy ? '#4977E9' : '#ffffff';
  const time = props.time;
  const isAttachment = !!props.message.attachmentName;
  return (
    <div className={styles.message}>
      <div className={styles.bubble_container} style={{ justifyContent: jc }}>
        <div className={styles.bubble} style={{ color: color, backgroundColor: bg }}>
          {props.time ? (
            <div className={styles.title} style={{ color: props.isMy ? '#ffffff' : 'darkgray' }}>
              {!props.isMy ? <span className={styles.author}>{props.author}</span> : null}
              <Tooltip title={moment(time).format('DD MMM HH:mm')}>
                <span className={styles.time}>{moment(props.time).fromNow()}</span>
              </Tooltip>
            </div>
          ) : null}

          {isAttachment ? (
            <AttachmentMessage
              text={props.message.content}
              mine={props.isMy}
              fileName={props.message.attachmentName}
              onDownload={() => messengerStore.downloadAttachment(message.messageId, message.attachmentName)}
            />
          ) : (
            <TextMessage text={props.message.content} mine={props.isMy} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
