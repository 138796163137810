export type Payer = 'operator' | 'client';
export type WagonDistributionType = 'manual' | 'auto';
export type DestinationType = 'road' | 'station';
export type PlanType = 'load' | 'delivery';
export type StateType = 'draft' | 'onAgr' | 'agr' | 'reject' | 'agrWithChanges' | 'complete' | 'change' | 'blank' | 'inProgress';
export type WayKind = 'inside' | 'import' | 'export' | 'transit' | 'outsideRf' | 'insideWithFerry';

export interface CreateRequest {
  clientId: string;
  contractId: string;
  destinationType: DestinationType;
  loadStationCode: number;
  senderId: string;
  rps: string;
  beginDate: string;
  endDate: string;
  wayKind: WayKind;
}

export interface CreateSend {
  destStationCode: number;
  etsngCode: string;
  receiverId: string;
  loadPayer: Payer;
  emptyPayer: Payer;
  wagonCount: number;
  singleWagonLoad: number;
  weightTotal: number;
  wagonDistributionType: WagonDistributionType;
  planType: PlanType;
  ilsClaimId: string;
  loadPlans: LoadPlan[];
}

export interface LoadPlan {
  ilsLoadPlanId?: string;
  ilsSendId?: string;
  date: string;
  wagonCount: number;
  weight: number;
}

export interface Send extends CreateSend {
  ilsSendId: string;
  ilsClaimId: string;
  destRoadCode: number;
  destRoadName: string;
  destStationName?: string;
  etsngName: string;
  parentEtsngCode?: string;
  parentEtsngName?: string;
  receiverName: string;
  wagonProps?: string;
  wagonModels?: string[];
  wagonLoadMin?: number;
  wagonLoadMax?: number;
  wagonVolumeMax?: number;
  wagonVolumeMin?: number;
  comment?: string;
  guNumber?: string;
}

export interface ClaimReport extends CreateRequest {
  ilsClaimId: string;
  ilsClaimNumber: string;
  companyId: string;
  creatorId: string;
  createDate: string;
  clientName: string;
  contractName: string;
  contractNumber: string;
  loadCountryName: string;
  loadStationName: string;
  senderName: string;
  state: StateType;
  sends: Send[];
  wagons: IlsClaimWagon[];
  managerChange?: boolean;
  canDelete: boolean;
  statusName: string;
}

export interface IlsClaimHistoryReport {
  ilsClaimHistoryId: string;
  ilsClaimId: string;
  date: string;
  userId: string;
  userName: string; //Имя пользователя. совершившего действие
  changeType: string; // тип изменения  (какие типы бывают)
  changeTypeName: string; //расшифровка типа изменения
  claimJson: JSON;
}

export const wayKinds: Array<{ value: WayKind; label: string }> = [
  { value: 'inside', label: 'Внутреннее' },
  { value: 'import', label: 'Импорт' },
  { value: 'export', label: 'Экспорт' },
  { value: 'transit', label: 'Транзит' },
  { value: 'outsideRf', label: 'Вне территории РФ' },
  { value: 'insideWithFerry', label: 'Внутреннее с использованием парома' },
];

export type IlsClaimWagonState = 'added' | 'toload' | 'loaded' | 'onway' | 'unloading' | 'complete';

export interface IlsClaimWagon {
  /**
   * № вагона  Дислокация вагона, показывать номер как активную ссылку на текущий рейс.
   */
  wagonNumber: number;
  currentItineraryId?: string;
  /**
   * Статус вагона  Статус "Привязан"- не показывать
   */
  state: IlsClaimWagonState;
  /**
   * Дополнительная характеристика
   */
  additionalProp: string;
  /**
   * Модель вагона  Паспорт вагона
   */
  wagonModel: string;
  /**
   * Объем кузова  Паспорт вагона
   */
  volume?: number;
  /**
   * Грузоподъемность  Паспорт вагона
   */
  lifting?: number;
  /**
   * Дней до планового ремонта  Паспорт вагона
   */
  daysBeforeRepair?: number;
  /**
   * Станция дислокации  Дислокация вагона
   */
  dislStationName: string;
  /**
   * Станция назначения  Дислокация вагона
   */
  destStationName: string;
  /**
   * Признак гружености  Дислокация вагона
   */
  isLoaded?: boolean;

  //Последняя операция сокращенно
  operationName: string;

  //Дата последней операции
  operationDate: any;

  //Груз
  etsngName: string;

  //	Код груза ЕТСНГ
  etsngCode: string;
  //Вес груза
  sendNetto: number;

  //Расстояние до станции назначения
  distance: number;

  sendDate: any;
  toLoadDate: any;
}
