import { CloseOutlined, CopyOutlined, DownloadOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import requestIlsStore from '@stores/requestIlsStore/requestIlsStore';
import { ClaimReport, wayKinds } from '@stores/requestIlsStore/requestIlsStoreData';
import userStore from '@stores/userStore';

import RequestIlsClaimForm from './IlsClaimForm/IlsClaimForm';
import RequestIlsSendForm from './IlsClaimForm/IlsSendForm';
import IlsClaimHistory from './IlsClaimHistory';
import './IlsClaimItemPage.less';

const accessEdit = 'ils_claim/edit';
const access = {
  edit: 'ils_claim/edit',
  toAgr: 'ils_claim/from_draft_to_on_agr',
  fromAgr: 'ils_claim/from_on_agr_to_draft',
  fromToAgr: 'ils_claim/from_on_agr_to_agr',
  onAgrChanges: 'ils_claim/on_agr_changes',
  agrChanges: 'ils_claim/agr_changes',
  reject: 'ils_claim/reject',
};

// enum Status {
//   'draft' = 'черновик',
//   'onAgr' = 'На согласовании',
//   'arg' = 'Согласовано',
//   'agrWithChanges' = 'Согласовано с изменениями',
//   'complete' = 'Завершено',
//   'change' = 'Cогласованная заявка была изменена',
//   'reject ' = 'заявка отклонена',
// }

interface ComponentState {
  data: ClaimReport | null;
  isLoading: boolean;
  isShowModal: boolean;
  status: 'request' | 'sending';
  editSendId: string | null;
  isCopy: boolean;
  // copyData: CreateRequest | null;
}

enum DestinationType {
  'station' = 'Станция',
  'road' = 'Дорога',
}

interface DataType {
  key: string;
  wagonCount: number;
  // stationCode: string;
  destStationName: string;
  destRoadName: string;
  wagonProps: string;
  // rps: item.rps,
  weightTotal: number;
  etsngName: string;
}

class IlsClaimItemPage extends Component<RouteComponentProps, ComponentState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      isLoading: false,
      data: null,
      isShowModal: false,
      status: 'request',
      editSendId: null,
      isCopy: false,
    };
  }

  componentDidMount() {
    this.getDataIls();
  }

  componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: Readonly<ComponentState>, snapshot?: any) {
    if (prevState.isShowModal !== this.state.isShowModal) {
      !this.state.isShowModal && this.getDataIls();
    }
  }

  get id() {
    const { params } = this.props.match;
    return params['id'];
  }

  getDataIls = async (data?: ClaimReport): Promise<void> => {
    this.setState({ isLoading: true });
    const id = this.id;

    try {
      const res = data ?? (await requestIlsStore.getIls(id));
      this.setState({ data: res });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  generateColumnWagons = (disableEdit: boolean): ColumnsType<DataType> => {
    const columnsWagons: ColumnsType<DataType> = [];
    const state = this.state.data?.state;
    columnsWagons.push({
      title: '',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button
              type={'link'}
              onClick={() => {
                this.setState({ isShowModal: true, status: 'sending', editSendId: record.key });
              }}
              disabled={
                (state === 'onAgr' ? !userStore.canAccess(access.onAgrChanges) : false) ||
                (['agr', 'arWithChanges'].includes(state) ? !userStore.canAccess(access.agrChanges) : false) ||
                disableEdit
              }>
              <EditOutlined />
            </Button>

            <Button
              type={'link'}
              onClick={() => {
                this.setState({
                  isShowModal: true,
                  status: 'sending',
                  editSendId: record.key,
                  isCopy: true,
                });
              }}
              disabled={
                (state === 'onAgr' ? !userStore.canAccess(access.onAgrChanges) : false) ||
                (['agr', 'arWithChanges'].includes(state) ? !userStore.canAccess(access.agrChanges) : false) ||
                disableEdit
              }>
              <CopyOutlined />
            </Button>

            <Popconfirm
              title={'Удалить отправку?'}
              okText={'Да'}
              disabled={disableEdit}
              cancelText={'нет'}
              onConfirm={() => {
                requestIlsStore.deleteSendIls(record.key).then((ans) => {
                  this.props.history.replace(`/view-ils-send/${ans.ilsClaimId}`);
                  this.getDataIls(ans);
                });
              }}>
              <Button
                type={'link'}
                disabled={
                  (['agr', 'arWithChanges'].includes(state) ? !userStore.canAccess(access.agrChanges) : false) ||
                  !userStore.canAccess(access.edit) ||
                  disableEdit
                }>
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    });

    if (this.state.data?.destinationType === 'station') {
      columnsWagons.push({
        title: 'Станция назначения',
        dataIndex: 'destStationName',
        key: 'destStationName',
      });
    }
    if (this.state.data?.destinationType === 'road') {
      columnsWagons.push({
        title: 'Дорога назначения',
        dataIndex: 'destRoadName',
        key: 'destRoadName',
      });
    }
    columnsWagons.push(
      {
        title: 'Характеристика вагона',
        dataIndex: 'wagonProps',
        key: 'wagonProps',
      },
      {
        title: 'Вагонов',
        dataIndex: 'wagonCount',
        key: 'wagonCount',
      },
      {
        title: 'Вес, тн',
        dataIndex: 'weightTotal',
        key: 'weightTotal',
      },
      {
        title: 'Груз',
        dataIndex: 'etsngName',
        key: 'etsngName',
      },
    );

    return columnsWagons;
  };

  deleteRequestIlsHandler = (id: string): void => {
    requestIlsStore.delete(id).then(() => {
      this.props.history.replace(`/ils-page`);
    });
  };

  onDownload = () => {
    requestIlsStore.exportToPdf(this.state.data.ilsClaimId).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.state.data.ilsClaimNumber}.pdf`);
      const b = document.body.appendChild(link);
      link.click();
      document.body.removeChild(b);
    });
  };

  onBackToRegistryClicked = () => {
    this.props.history.replace('/ils-page');
  };

  render() {
    const { data, isLoading, isShowModal } = this.state;
    const countWagons = data?.sends?.reduce((acc, el) => (acc += el.wagonCount), 0) || 0;
    const weightTotal = data?.sends?.reduce((acc, el) => (acc += el.weightTotal), 0) || 0;
    const state = data?.state;
    const disableEdit =
      !userStore.isAdmin && (['change', 'inProgress', 'reject', 'complete'].includes(state) || !userStore.canAccess(access.edit));
    const canDelete = (userStore.canAccess(access.edit) && data?.canDelete) || userStore.isAdmin;

    const dataSends = data?.sends.map((item) => ({
      wagonCount: item.wagonCount,
      key: item.ilsSendId,
      destStationName: item.destStationName,
      destRoadName: item.destRoadName,
      wagonProps: item.wagonProps,
      receiverName: item.receiverName,
      weightTotal: item.weightTotal,
      etsngName: item.etsngName,
    }));

    return (
      <>
        <AdaptivePageHeader
          title={
            <h3 className={'statusIlsTitle'}>
              <span className={'claim'}>Заявка №{data?.ilsClaimNumber}</span>
              <span className={'client'}>Клиент {data?.clientName}</span>
              {data?.statusName && <span className={'status'}>{data?.statusName}</span>}
            </h3>
          }
          extra={null}
        />
        <Spin spinning={isLoading}>
          {data && (
            <AdaptiveCard>
              <ScrolledContainer className={'requestIlsItemPage'}>
                <p className={'requestPeriod'}>
                  Заявка действует с {moment(data.beginDate).format(GLOBAL_DATE_FORMAT)} по{' '}
                  {moment(data.endDate).format(GLOBAL_DATE_FORMAT)}
                </p>
                <div className={'wrapperBtn'}>
                  <Popconfirm
                    okText={'да'}
                    cancelText={'нет'}
                    title={'Скопировать заявку?'}
                    onConfirm={() => {
                      const { data } = this.state;
                      requestIlsStore.copy(data.ilsClaimId).then((res) => {
                        this.props.history.replace(`/view-ils-send/${res}`);
                        this.getDataIls();
                      });
                    }}>
                    <Button icon={<CopyOutlined />} type={'link'}>
                      Копировать
                    </Button>
                  </Popconfirm>
                  <Button icon={<DownloadOutlined />} type={'link'} onClick={this.onDownload}>
                    Скачать
                  </Button>
                  <Button
                    icon={<EditOutlined />}
                    type={'link'}
                    onClick={() => this.setState({ isShowModal: true, status: 'request' })}
                    disabled={
                      (state === 'onAgr' ? !userStore.canAccess(access.onAgrChanges) : false) ||
                      ['agr', 'agrWithChanges'].includes(state) ||
                      disableEdit
                    }>
                    Редактировать
                  </Button>

                  <Popconfirm
                    okText={'да'}
                    cancelText={'нет'}
                    title={'Удалить заявку?'}
                    onConfirm={() => this.deleteRequestIlsHandler(data.ilsClaimId)}
                    disabled={!canDelete}>
                    <Button icon={<CloseOutlined />} type={'link'} disabled={!canDelete}>
                      Удалить
                    </Button>
                  </Popconfirm>

                  {userStore.canAccess(access.toAgr) && state === 'blank' && (
                    <Button
                      className={'approveBtn'}
                      type={'ghost'}
                      onClick={() => {
                        requestIlsStore.setStatus(data.ilsClaimId, 'onAgr').then(() => {
                          this.getDataIls();
                        });
                      }}>
                      Отправить на согласование
                    </Button>
                  )}

                  {userStore.canAccess(access.fromToAgr) && state === 'onAgr' && (
                    <Button
                      className={'approveBtn'}
                      type={'ghost'}
                      onClick={() => {
                        requestIlsStore.setStatus(data.ilsClaimId, 'agr').then(() => {
                          this.getDataIls();
                        });
                      }}>
                      Согласовать
                    </Button>
                  )}
                  {state === 'onAgr' && (
                    <Button
                      className={'rejectBtn'}
                      type={'ghost'}
                      onClick={() => {
                        requestIlsStore.setStatus(data.ilsClaimId, 'reject').then(() => {
                          this.getDataIls();
                        });
                      }}
                      disabled={!userStore.canAccess(access.reject)}>
                      Отклонить
                    </Button>
                  )}
                  {state === 'reject' && (
                    <Button
                      className={'rejectBtn'}
                      type={'ghost'}
                      onClick={() => {
                        requestIlsStore.setStatus(data.ilsClaimId, 'onAgr').then(() => {
                          this.getDataIls();
                        });
                      }}
                      disabled={!userStore.canAccess(access.reject)}>
                      Вернуть на согласование
                    </Button>
                  )}

                  {state === 'onAgr' && (
                    <Button
                      className={'returnBtn'}
                      type={'ghost'}
                      onClick={() => {
                        requestIlsStore.setStatus(data.ilsClaimId, 'blank').then(() => {
                          this.getDataIls();
                        });
                      }}
                      disabled={!userStore.canAccess(access.fromAgr)}>
                      Вернуть в заготовку
                    </Button>
                  )}

                  <IlsClaimHistory ilsClaimId={data.ilsClaimId} />

                  <Button type="ghost" onClick={this.onBackToRegistryClicked}>
                    Вернуться в реестр
                  </Button>
                </div>

                <ul className={'listParams'}>
                  <li className={'itemParams'}>
                    <p>Клиент</p>
                    <p>{data?.clientName}</p>
                  </li>
                  <li className={'itemParams'}>
                    <p>Номер Договора</p>
                    <p>{data?.contractName}</p>
                  </li>

                  <li className={'itemParams'}>
                    <p>Тип назначения</p>
                    <p>{DestinationType[data.destinationType]}</p>
                  </li>
                  <li className={'itemParams'}>
                    <p>Род подвижного состава</p>
                    <p>{data.rps}</p>
                  </li>

                  <li className={'itemParams'}>
                    <p>Станция отправления</p>
                    <p>{data.loadStationName}</p>
                  </li>

                  <li className={'itemParams'}>
                    <p>Страна отправления</p>
                    <p>{data?.loadCountryName}</p>
                  </li>

                  <li className={'itemParams'}>
                    <p>Грузоотправитель</p>
                    <p>{data.senderName}</p>
                  </li>
                  <li className={'itemParams'}>
                    <p>Вид сообщения</p>
                    <p>{wayKinds.find((i) => i.value === data.wayKind)?.label}</p>
                  </li>
                </ul>

                <div className={'wagonsWrapper'}>
                  <p>
                    Всего вагонов: <span>{countWagons}</span>
                  </p>
                  <p>
                    Общий вес, тн: <span>{weightTotal}</span>
                  </p>

                  <Button
                    icon={<PlusOutlined />}
                    className={'addSend'}
                    onClick={() => {
                      this.setState({ isShowModal: true, editSendId: null, status: 'sending' });
                    }}
                    disabled={
                      (['agr', 'arWithChanges'].includes(state) ? !userStore.canAccess(access.agrChanges) : false) ||
                      !userStore.canAccess(access.edit) ||
                      disableEdit
                    }>
                    Добавить Отправку
                  </Button>
                </div>
                <Table style={{ marginTop: 10 }} columns={this.generateColumnWagons(disableEdit)} dataSource={dataSends} />
                <Link to={`/ils-claim-wagon/${data.ilsClaimId}`}>Посмотреть список вагонов</Link>
              </ScrolledContainer>
            </AdaptiveCard>
          )}
        </Spin>

        {isShowModal && this.state.status == 'sending' && (
          <RequestIlsSendForm
            closeModal={() => {
              this.setState({ isShowModal: false, isCopy: false });
            }}
            history={this.props.history}
            open={isShowModal}
            value={data}
            editSendId={this.state.editSendId}
            isCopy={this.state.isCopy}
          />
        )}
        {isShowModal && this.state.status == 'request' && (
          <RequestIlsClaimForm
            closeModal={() => {
              this.setState({ isShowModal: false, isCopy: false });
            }}
            history={this.props.history}
            open={isShowModal}
            value={data}
          />
        )}
      </>
    );
  }
}

export default observer(IlsClaimItemPage);
