import { Radio } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import AccessRoute from '@components/fw/RoutingContext/AccessRoute';
import ContainerAdd from '@components/fw/Tracking/Container/ContainerAdd';
import ContainerGroupRemove from '@components/fw/Tracking/Container/ContainerGroupRemove';
import ContainerRemove from '@components/fw/Tracking/Container/ContainerRemove';

import routingStore from '@stores/fw/routingStore/routingStore';
import trackingContainerStore from '@stores/trackingStore/container/trackingContainerStore';
import userStore from '@stores/userStore/userStore';

import TrackingContainersActive from './TrackingContainersActive';
import TrackingContainersHistory from './TrackingContainersHistory';

interface TrackingState {}

class TrackingContainers extends Component<RouteComponentProps, TrackingState> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  render() {
    const path = routingStore.location.pathname;
    const viewType = path == '/billing/orders/containers/active' ? 'active' : 'history';
    const canEdit = userStore.canAccess('billing/orders/containers/edit');
    document.title = 'Заказы';

    return (
      <>
        <AdaptivePageHeader
          title={'Контейнеры'}
          extra={
            <CollapsedToolbar
              content={[
                canEdit ? <ContainerAdd onDataReady={this.handleDataChanged} key={'add'} /> : null,
                canEdit ? <ContainerRemove onDataReady={this.handleDataChanged} key={'remove'} /> : null,
                canEdit ? (
                  <ContainerGroupRemove
                    data={trackingContainerStore.selected}
                    key={'remove_selected'}
                    onDataReady={this.handleDataChanged}
                  />
                ) : null,
                <Radio.Group
                  key={'selector'}
                  defaultValue={viewType}
                  buttonStyle="solid"
                  size={'small'}
                  onChange={(value) => this.handleViewTypeChanged(value)}
                  value={viewType}
                  style={{ marginLeft: 5 }}>
                  <Radio.Button key={'active'} value={'active'}>
                    Активные
                  </Radio.Button>
                  <Radio.Button key={'history'} value={'history'}>
                    История
                  </Radio.Button>
                </Radio.Group>,
              ]}
            />
          }
          className={'page_header'}
        />
        <AdaptiveCard>
          <Switch>
            <AccessRoute
              path="/billing/orders/containers/active"
              component={TrackingContainersActive}
              canAccess={userStore.canAccess('billing/orders/containers')}
            />
            <AccessRoute
              path="/billing/orders/containers/history"
              component={TrackingContainersHistory}
              canAccess={userStore.canAccess('billing/orders/containers')}
            />
          </Switch>
        </AdaptiveCard>
      </>
    );
  }

  handleViewTypeChanged = (value) => {
    this.props.history.push(`/billing/orders/containers/${value.target.value}`);
  };

  handleDataChanged() {
    trackingContainerStore.loadHistory();
    trackingContainerStore.loadActive();
  }
}

export default observer(TrackingContainers);
