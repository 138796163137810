import { Form } from 'antd';
import React from 'react';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';

import { registerOfBetsStore } from '@stores/registerOfBetsStore';
import { BidRegistry } from '@stores/registerOfBetsStore/registerOfBetsStore.namespace';

export const PriceNds = ({ name }: { name: number }) => {
  return (
    <Form.Item noStyle dependencies={[['extraServices', name, 'price']]}>
      {({ getFieldValue }) => <FloatInputNumber value={getNds(name, getFieldValue)} disabled={true} placeholder="НДС" />}
    </Form.Item>
  );
};

const getNds = (name: number, getFieldValue: any) => {
  const price = getFieldValue(['extraServices', name, 'price']);
  const ndsRate = registerOfBetsStore.bidRegistry.ndsRate;

  return BidRegistry.calcNds(ndsRate, price).toLocaleString('ru', {
    maximumFractionDigits: 2,
  });
};
