import { SelectedRemark } from '@stores/dispatcherStore/dispatcherStore.types';

import HttpClient from '@utils/httpClient';

import {
  AttributeDefinition,
  ClientFieldRequest,
  CompanyFieldWidthRequest,
  CompanyRepairParams,
  CopyReportRequest,
  CustomReportDetailsModel,
  InfoBlockItem,
  SetCatalogRequest,
  SetNoteRequest,
  WagonFullInfo,
  WagonItineraryModel,
  WagonOperationModel,
  containerInfoResponse,
  wagonInfoResponse,
} from './DispatcherData';

const dispatcherService = {
  getReportList() {
    return HttpClient.get(`/api/report/list`).then((resp) => {
      return resp.data;
    });
  },

  getReportListByID(id: string): Promise<CustomReportDetailsModel> {
    return HttpClient.get(`/api/report/list/${id}`).then((resp) => {
      return resp.data;
    });
  },

  getReportListByUserId(userId: string): Promise<CustomReportDetailsModel[]> {
    return HttpClient.get(`/api/report/list/byUser`, { params: { userId } }).then((resp) => {
      return resp.data;
    });
  },

  saveReportList(data: CustomReportDetailsModel) {
    return HttpClient.post(`/api/report/save`, data).then((resp) => {
      return resp.data;
    });
  },

  createReportList(data: CustomReportDetailsModel) {
    return HttpClient.post(`/api/report/create`, data).then((resp) => {
      return resp.data;
    });
  },

  deleteReportList(id: string) {
    return HttpClient.delete(`/api/report/delete/${id}`).then((resp) => {
      return resp.data;
    });
  },

  /// Коипрование отчёта пользователям
  copyReport(data: CopyReportRequest): Promise<void> {
    return HttpClient.post(`/api/report/copy`, data).then((resp) => {
      return resp.data;
    });
  },

  dataReportList(id: string, data: any) {
    return HttpClient.post(`/api/report/data/${id}`, data).then((resp) => {
      return resp.data;
    });
  },

  infoGroupList() {
    return HttpClient.get(`/api/infogroup/list`).then((resp) => {
      return resp.data;
    });
  },

  infoBlockList(id: number): Promise<InfoBlockItem[]> {
    return HttpClient.get(`/api/infoblock/${id}`).then((resp) => {
      return resp.data;
    });
  },

  saveField(data: ClientFieldRequest): Promise<void> {
    return HttpClient.post(`/api/infofield/save`, data).then((resp) => {
      return resp.data;
    });
  },

  saveFieldWidth(data: CompanyFieldWidthRequest): Promise<void> {
    return HttpClient.post(`/api/infofield/width/save`, data).then((resp) => {
      return resp.data;
    });
  },

  updateReportFieldIndex(reportId: number, fieldName: string, index: number): Promise<void> {
    return HttpClient.post(`/api/report/reindex`, {}, { params: { reportId, fieldName, index } }).then((resp) => {
      return resp.data;
    });
  },

  resetField(id: number): Promise<void> {
    return HttpClient.get(`/api/infofield/resetsettings?customReportId=${id}`).then((resp) => {
      return resp.data;
    });
  },

  setNote(data: SetNoteRequest, type: 'wagons' | 'containers'): Promise<void> {
    return HttpClient.post(`/api/companynote/${type}/setnote`, data).then((resp) => {
      return resp.data;
    });
  },

  wagonInfo(id: number[]): Promise<wagonInfoResponse[]> {
    return HttpClient.post(`/api/wagon/info`, id).then((resp) => {
      return resp.data;
    });
  },

  containerInfo(id: string[]): Promise<containerInfoResponse[]> {
    return HttpClient.post(`/api/container/info`, id).then((resp) => {
      return resp.data;
    });
  },

  setCatalog(data: SetCatalogRequest, type: 'wagons' | 'containers'): Promise<void> {
    return HttpClient.post(`/api/CompanyCatalog/${type}/movetocatalog`, data).then((resp) => {
      return resp.data;
    });
  },

  wagonItineraryList(loadOptions): Promise<any> {
    return HttpClient.post(`/api/wagonItinerary/list`, loadOptions).then((resp) => {
      return resp.data;
    });
  },

  wagonItineraryListByWagonId(wagonId: number): Promise<WagonItineraryModel[]> {
    return HttpClient.get(`/api/wagonOperation/list/${wagonId}`).then((resp) => {
      return resp.data;
    });
  },

  wagonOperationByItineraryId(itineraryId: number): Promise<WagonOperationModel[]> {
    return HttpClient.get(`/api/wagonOperation/list/${itineraryId}`).then((resp) => {
      return resp.data;
    });
  },

  toCountry(countryCode: number, data: number[]): Promise<void> {
    return HttpClient.put(`/api/track/wagon/tocountry/${countryCode}`, data).then((resp) => {
      return resp.data;
    });
  },

  /// Получить параметры компании на ремонт
  async getCompanyRepairParams(repairId: string): Promise<CompanyRepairParams> {
    return (await HttpClient.get(`/api/wagonRepair/params/${repairId}`)).data;
  },

  /// Обновить параметры компании на ремонт
  async updateCompanyRepairParams(data: CompanyRepairParams): Promise<void> {
    return (await HttpClient.post(`/api/wagonRepair/params`, data)).data;
  },

  async fullInfo(type: 'wagon' | 'container', number: string): Promise<WagonFullInfo> {
    return (await HttpClient.get(`/api/${type}/info/full/${number}`)).data;
  },

  async reportToXlsx(
    reportId: string,
    filterModel: any,
    numbers: any,
    progressFunc: ((e: ProgressEvent) => void) | undefined = undefined,
  ): Promise<Blob> {
    return (
      await HttpClient.post(
        `/api/report/export/${reportId}`,
        { filter: filterModel, numbers: numbers },
        {
          onDownloadProgress: progressFunc,
          responseType: 'blob',
        },
      )
    ).data;
  },

  operationByNumber(loadOptions: any, number: string, type: 'wagon' | 'container'): Promise<any> {
    return HttpClient.post(`/api/${type}Operation/history`, loadOptions, { params: { number } }).then((resp) => {
      return resp.data;
    });
  },
  operationsFields(type: 'wagon' | 'container'): Promise<AttributeDefinition[]> {
    return HttpClient.get(`/api/${type}Operation/history/fields`).then((resp) => {
      return resp.data;
    });
  },

  async cleanLoadRegistryClient(wagons: number[]): Promise<void> {
    await HttpClient.put(`/api/companyLoadRegistry/setDoNotShowInReport`, wagons);
  },

  async updateRemark(type: 'wagon' | 'container', numbers: string | number[], values: { remark1?: string; remark2?: string }) {
    await HttpClient.post(`/api/track/${type}/updateRemark`, { numbers, ...values });
  },

  async getRemarks(type: 'wagon' | 'container', numbers: string | number[]): Promise<SelectedRemark[]> {
    return (await HttpClient.post(`/api/track/${type}/remarks`, numbers)).data;
  },
};

export default dispatcherService;
