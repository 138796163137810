import { dxElement } from 'devextreme/core/element';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { dxToolbarOptions } from 'devextreme/ui/toolbar';
import saveAs from 'file-saver';

import userStore from '@stores/userStore';

import HoldsTable from './HoldsTable';

interface PrepareTableToolbarEvent {
  model?: any;
  component?: any;
  element?: dxElement;
  toolbarOptions?: dxToolbarOptions;
}

export const exportTable = async (event: any) => {
  const ExcelJs = await import('exceljs');
  const workbook = new ExcelJs.Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');
  exportDataGrid({
    component: event.component,
    worksheet: worksheet,
    customizeCell: (options) => {
      const gridCell = options.gridCell;

      if (gridCell.value && gridCell.column.dataField === 'netto') {
        const num = gridCell.value / 1000;
        const whole = Math.trunc(num);
        const frac = Number(String(num.toFixed(3)).split('.')[1] || 0);

        options.excelCell.value = `${whole},${frac}`;
      }
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
  });
};

export const prepareTableToolbar = function (this: HoldsTable, event: PrepareTableToolbarEvent) {
  event.toolbarOptions.items.unshift(
    {
      location: 'after',
      template: 'edit',
    },
    {
      location: 'after',
      widget: 'dxButton',
      showText: 'inMenu',
      locateInMenu: 'auto',
      options: {
        icon: 'hierarchy',
        text: 'Свернуть/развернуть',
        hint: 'Свернуть/развернуть',
        onClick: () => {
          this.setState({ autoExpandAll: !this.state.autoExpandAll });
        },
      },
    },
    {
      location: 'after',
      widget: 'dxButton',
      showText: 'inMenu',
      locateInMenu: 'auto',
      options: {
        icon: 'redo',
        text: 'Сбросить',
        hint: 'Сбросить',
        onClick: () => {
          localStorage.removeItem('holdsGrid' + userStore.userData.userId);
          this.dataGrid.instance.clearFilter();
          this.dataGrid.instance.clearSorting();
          this.dataGrid.instance.clearSelection();
          this.dataGrid.instance.clearGrouping();
        },
      },
    },
    {
      location: 'after',
      widget: 'dxButton',
      showText: 'inMenu',
      locateInMenu: 'auto',
      options: {
        icon: 'refresh',
        text: 'Обновить',
        hint: 'Обновить',
        onClick: this.props.onRefresh,
      },
    },
  );
};
