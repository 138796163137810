import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
  DeliveredProcedureOutlined,
  ScheduleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Avatar, Col, Divider, Empty, Row, Skeleton, Spin, Tooltip } from 'antd';
import Meta from 'antd/es/card/Meta';
import moment from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import { TaskAdminDisplayModel } from '@stores/monitorStore/monitorData';
import monitorStore from '@stores/monitorStore/monitorStore';
import userStore from '@stores/userStore/userStore';

interface State {
  data: TaskAdminDisplayModel[];
  isBusy: boolean;
  timer: any;
}

export const category_list = [
  { name: 'Customer', title: 'Заказчик', icon: <DeliveredProcedureOutlined /> },
  { name: 'Loader', title: 'Загрузчик', icon: <CloudUploadOutlined /> },
  { name: 'Scheduler', title: 'Планировщик', icon: <ScheduleOutlined /> },
];

class MonitorPage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      data: [],
      isBusy: true,
      timer: 0,
    };
  }

  componentDidMount() {
    this.getData();
    document.title = 'Мониторинг';
  }

  getData() {
    monitorStore
      .getData()
      .then((ans) => {
        this.setState({
          data: ans,
          timer: setTimeout(() => {
            this.getData();
          }, 5000),
        });
      })
      .finally(() => {
        this.setState({ isBusy: false });
      });
  }
  componentWillUnmount() {
    clearTimeout(this.state.timer);
  }
  get isAdmin() {
    return userStore.userData.roleCode == 'admin';
  }

  render() {
    const data = this.state.data;
    const isBusy = this.state.isBusy;
    const categoryList = this.isAdmin ? category_list : [{ name: null, title: 'Задания', icon: null }];

    return (
      <ScrolledContainer className={'scrolledContainer'} style={{ height: 'calc(100vh - var(--header-height)' }}>
        <Spin spinning={isBusy}>
          <AdaptiveCard>
            {data.length == 0 ? (
              <Empty />
            ) : (
              categoryList.map((cat) => {
                return (
                  <div key={cat.title}>
                    <Divider style={{ margin: 0 }}>{cat.title}</Divider>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ margin: '8px' }}>
                      {data
                        .filter((f) => f.category == cat.name || (!f.category && !cat.name))
                        .map((d, index) => {
                          return (
                            <Col xs={24} sm={12} md={12} xl={8} key={'task' + index}>
                              <AdaptiveCard hoverable>
                                <Skeleton loading={isBusy} avatar active>
                                  <Meta
                                    avatar={
                                      <Avatar style={{ color: this.getStatusIconColor(d.status) }} icon={this.getStatusIcon(d.status)} />
                                    }
                                    title={
                                      <Tooltip title={d.name}>
                                        <span>{d.name}</span>
                                      </Tooltip>
                                    }
                                    description={
                                      <>
                                        {d.sourceName ? <p style={{ margin: 0 }}>Источник: {d.sourceName}</p> : null}
                                        <p style={{ margin: 0 }}>Запуск: {d.startDate ? moment(d.startDate).fromNow() : '---'}</p>
                                        <p style={{ margin: 0 }}>Cледующий: {d.nextDate ? moment(d.nextDate).fromNow() : '---'} </p>
                                        {d.startDate && d.endDate && ['Done', 'Error'].includes(d.status) ? (
                                          <p style={{ margin: 0 }}>
                                            Выполнен за: {(moment(d.endDate).valueOf() - moment(d.startDate).valueOf()) / 1000} сек.
                                          </p>
                                        ) : null}
                                      </>
                                    }
                                  />
                                </Skeleton>
                              </AdaptiveCard>
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                );
              })
            )}
          </AdaptiveCard>
        </Spin>
      </ScrolledContainer>
    );
  }

  getStatusIconColor = (type: string) => {
    switch (type) {
      case 'Done':
        return 'green';
      case 'Process':
        return 'blue';
      case 'Error':
        return 'red';
      case 'Wait':
        return 'blue';
      default:
        return 'black';
    }
  };

  getStatusIcon = (type: string) => {
    switch (type) {
      case 'Done':
        return <CheckCircleOutlined />;
      case 'Process':
        return <SyncOutlined spin />;
      case 'Error':
        return <CloseCircleOutlined />;
      case 'Wait':
        return <ClockCircleOutlined />;
      default:
        return <ScheduleOutlined />;
    }
  };
}

export default MonitorPage;
