import { Button } from 'antd';
import { Template } from 'devextreme-react/core/template';
import DataGrid, { Column, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { dxToolbarOptions } from 'devextreme/ui/toolbar';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { hatchingRow } from '@stores/dispatcherStore/DispatcherData';
import { transportRegisterStore } from '@stores/transportRegisterStore';

import { toolbarPreparing } from './lib/toolbar-preparing';
import { TransportRegisterTableProps } from './types/table-props';

export const TransportRegisterTable = observer(({ dataSource }: TransportRegisterTableProps) => {
  const dataGridRef = useRef<DataGrid>(null);

  const onExcludeItinerariesClick = () => transportRegisterStore.excludeItineraries().then(dataSource.reload);

  const selectedItineraryIds = computed(() => transportRegisterStore.selectedItineraryIds).get();

  const onSelectionChange = (e: any) => {
    transportRegisterStore.setSelectedItineraryIds(e.selectedRowsData.map((row: any) => row.itineraryid));
  };

  const onRestore = () => {
    dataGridRef.current.instance.clearFilter();
    dataGridRef.current.instance.clearSorting();
    dataGridRef.current.instance.clearSelection();
    dataGridRef.current.instance.clearGrouping();
  };

  const onRefresh = () => dataSource.reload();

  const statusDxCell: React.FC<any> = (props) => {
    const isGroup = props.rowType === 'group';
    let color;
    let colorName;
    if (props.value === 'red') {
      color = 'rgba(239,43,89,0.35)';
      colorName = 'Красный';
    } else if (props.value === 'yellow') {
      color = 'rgba(239,200,43,0.35)';
      colorName = 'Желтый';
    } else if (props.value === 'green') {
      color = 'rgba(43,239,69,0.35)';
      colorName = 'Зеленый';
    }
    return <div style={{ background: color }}>{colorName}</div>;
  };
  const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => toolbarPreparing(onRestore, onRefresh, e);

  return (
    <AdaptiveCard>
      <DataGrid
        ref={dataGridRef}
        onRowPrepared={hatchingRow}
        allowColumnReordering={true}
        dataSource={dataSource}
        height={TABLE_HEIGHT}
        onSelectionChanged={onSelectionChange}
        onToolbarPreparing={onToolbarPreparing}
        selection={{ mode: 'multiple', showCheckBoxesMode: 'always' }}
        allowColumnResizing={true}
        showBorders={true}
        columnResizingMode={'widget'}
        columnAutoWidth={true}
        showColumnLines={true}
        columnMinWidth={30}
        showRowLines={true}
        rowAlternationEnabled={true}
        remoteOperations={true}>
        <Template
          name={'exclude'}
          render={() => (
            <Button disabled={!selectedItineraryIds.length} type={'default'} onClick={onExcludeItinerariesClick}>
              Исключить
            </Button>
          )}
        />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <Paging enabled={true} defaultPageSize={25} />
        <Pager
          allowedPageSizes={[25, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
          infoText={'Всего: {2}'}
        />
        {INDEX_COLUMN}

        <Column
          allowFixing={true}
          dataField={'status'}
          groupCellRender={statusDxCell}
          cellRender={statusDxCell}
          caption={'Статус'}
          dataType={'string'}
        />
        <Column allowFixing={true} dataField={'number'} caption={'Номер вагона'} dataType={'string'} />
        <Column allowFixing={true} dataField={'sendnumber'} caption={'Номер накладной'} dataType={'string'} />
        <Column allowFixing={true} dataField={'rps'} caption={'РПС'} dataType={'string'} />
        <Column allowFixing={true} dataField={'rpsfeatures'} caption={'Дополнительная характеристика РПС'} dataType={'string'} />
        <Column allowFixing={true} dataField={'loadstationname'} caption={'Станция погрузки'} dataType={'string'} />
        <Column allowFixing={true} dataField={'sendername'} caption={'Грузоотправитель'} dataType={'string'} />
        <Column allowFixing={true} dataField={'senddate'} caption={'Дата погрузки по накладной'} dataType={'date'} defaultSortOrder={0} />
        <Column allowFixing={true} dataField={'deststationname'} caption={'Станция выгрузки'} dataType={'string'} />
        <Column allowFixing={true} dataField={'receivername'} caption={'Грузополучатель'} dataType={'string'} />
        <Column allowFixing={true} dataField={'loadstationreceivfactdate'} caption={'Дата выгрузки'} dataType={'date'} />
        <Column allowFixing={true} dataField={'freightname'} caption={'Груз'} dataType={'string'} />
        <Column allowFixing={true} dataField={'etsngcode'} caption={'Код груза ЕТСНГ'} dataType={'string'} />
        <Column allowFixing={true} dataField={'ilsclaimnumber'} caption={'Номер заявки'} dataType={'string'} />
        <Column allowFixing={true} dataField={'bidorgname'} caption={'Наименование контрагента'} dataType={'string'} />
        <Column allowFixing={true} dataField={'bidcontractname'} caption={'Договор контрагента'} dataType={'string'} />
        <Column allowFixing={true} dataField={'bidwitvat'} caption={'Ставка контрагента'} dataType={'string'} />
        <Column allowFixing={true} dataField={'supplierbidorgname'} caption={'Наименование поставщика'} dataType={'string'} />
        <Column allowFixing={true} dataField={'supplierbidcontractname'} caption={'Договор поставщика'} dataType={'string'} />
        <Column allowFixing={true} dataField={'supplierbidwitvat'} caption={'Ставка поставщика'} dataType={'string'} />
        <Column
          allowFixing={true}
          dataField={'excluded'}
          caption={'Исключен'}
          dataType={'boolean'}
          cellRender={(data) => (!!data.value ? 'Да' : 'Нет')}
        />
      </DataGrid>
    </AdaptiveCard>
  );
});

const TABLE_HEIGHT = 'calc(100vh - var(--padding))';
