import { DownloadOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { Component } from 'react';

import PdfViewModal from '@components/fw/DocumentViewer/PdfViewModal';

import leasingStoreService from '@stores/leasingStore/leasingStore.service';

import { showErrorNotify } from '@utils/notify';

import LeasingCompleteWorksDrawer from './LeasingCompleteWorksDrawer';

interface Props {
  contractId: string;
  data: any;
  onChange(): void;
}

interface State {
  isBusyDownload: boolean;
  isBusyPreview: boolean;
  isEditAct: boolean;
  blob: Blob;
  signVisible: boolean;
}

class LasingDetailActions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusyDownload: false,
      isBusyPreview: false,
      blob: null,
      signVisible: false,
      isEditAct: false,
    };
  }

  render() {
    const data = this.props.data;
    const canView = !['Ошибка', 'Формируется'].includes(this.props.data.statusName);
    return (
      <span>
        <Button
          disabled={!canView}
          size={'small'}
          title={'Скачать'}
          loading={this.state.isBusyDownload}
          icon={<DownloadOutlined />}
          onClick={() => this.onDownload()}
        />
        <Button
          disabled={!canView}
          size={'small'}
          title={'Посмотреть'}
          loading={this.state.isBusyPreview}
          icon={<EyeOutlined />}
          onClick={() => this.onPreview()}
        />
        {this.type == 'acceptanceOfService' && (
          <Button
            size={'small'}
            title={'редактировать'}
            loading={this.state.isBusyPreview}
            icon={<EditOutlined />}
            onClick={() => this.onActEdit()}
          />
        )}

        {!!this.state.blob && (
          <PdfViewModal
            data={this.state.blob}
            visible={!!this.state.blob}
            onClose={() => this.setState({ blob: null })}
            format={this.type === 'equipmentList' ? 'album' : 'book'}
          />
        )}
        {this.state.isEditAct && (
          <LeasingCompleteWorksDrawer
            contractId={this.props.contractId}
            onChange={() => {
              this.setState({ isEditAct: false });
              this.props.onChange();
            }}
            onClose={() => this.setState({ isEditAct: false })}
            id={this.props.data.id}
          />
        )}
      </span>
    );
  }
  onActEdit = () => {
    this.setState({ isEditAct: true });
  };

  onDownload = () => {
    this.setState({ isBusyDownload: true });
    leasingStoreService
      .getLeasingDoc(this.props.data.id, this.type)
      .then((ans) => {
        if (!ans || !ans.size) {
          showErrorNotify('Документ не найден', '');
          return;
        }
        let mockEvent = document.createEvent('MouseEvents');
        let mockElement = document.createElement('a');
        mockElement.download = this.props.data.docType + '_' + this.props.data.number + '.pdf';
        mockElement.target = '_blank';
        mockElement.href = window.URL.createObjectURL(new Blob([ans]));
        mockElement.dataset.downloadurl = ['blob', mockElement.download, mockElement.href].join(':');
        mockEvent.initEvent('click');
        mockElement.dispatchEvent(mockEvent);
      })
      .finally(() => {
        this.setState({ isBusyDownload: false });
      });
  };

  get type(): 'acceptanceCertificate' | 'equipmentList' | 'acceptanceOfService' {
    let type: 'acceptanceCertificate' | 'equipmentList' | 'acceptanceOfService' = 'acceptanceCertificate';
    if (this.props.data.docType == 'Лист комплектации') {
      type = 'equipmentList';
    }
    if (this.props.data.docType == 'Акт оказанных услуг') {
      type = 'acceptanceOfService';
    }

    return type;
  }

  onPreview = () => {
    this.setState({ isBusyPreview: true });
    leasingStoreService
      .getLeasingDoc(this.props.data.id, this.type)
      .then((ans) => {
        if (!ans || !ans.size) {
          showErrorNotify('Документ не найден', '');
          return;
        }
        this.setState({ blob: ans });
      })
      .finally(() => {
        this.setState({ isBusyPreview: false });
      });
  };
}

export default LasingDetailActions;
