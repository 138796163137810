import { Button, Col, Drawer, Form, Row, Select } from 'antd';
import React, { Component } from 'react';

import DocumentTemplateEditor from '@components/fw/DocumentTemplateEditor/DocumentTemplateEditor';

import { ContractElement, DocTypeContext } from '@stores/contractTemplateStore/ContractTemplatesData';
import designStore from '@stores/designStore';

import { ContractTemplatesEditorDrawerProps, ContractTemplatesEditorDrawerState } from './ContractTemplatesEditor.types';
import { convertTemplateToContract, copyTemplate, docContextOptions } from './ContractTemplatesEditor.utils';

export class ContractTemplatesEditorDrawer extends Component<ContractTemplatesEditorDrawerProps, ContractTemplatesEditorDrawerState> {
  constructor(props: ContractTemplatesEditorDrawerProps) {
    super(props);

    this.state = {
      template: copyTemplate(props.template),
    };
  }

  onSave = () => {
    this.props.onSave(this.state.template);
    this.props.onClose();
  };

  onChange = (contract: ContractElement) => {
    this.setState((state) => {
      state.template.name = contract.value;
      state.template.data = contract.nestedElements;
      return { ...state };
    });
  };

  onChangeSelectedContext = (value: DocTypeContext) => {
    this.setState((state) => {
      state.template.dataContext = value;
      return state;
    });
  };

  componentDidUpdate(prevProps: Readonly<ContractTemplatesEditorDrawerProps>) {
    if (prevProps.template !== this.props.template) {
      this.setState({ template: copyTemplate(this.props.template) });
    }
  }

  render() {
    const { template } = this.state;
    const { isOpen, onClose } = this.props;
    const contract = convertTemplateToContract(template);

    return (
      <Drawer
        open={isOpen}
        width={designStore.isMobile ? '100%' : '90%'}
        onClose={onClose}
        footer={
          <Button onClick={this.onSave} key={'saveAndClose'} type={'primary'}>
            Сохранить и закрыть
          </Button>
        }>
        {contract && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={'Тип шаблона'}>
                  <Select
                    placeholder={'Выбрать тип шаблона'}
                    options={docContextOptions}
                    value={template.dataContext}
                    onChange={this.onChangeSelectedContext}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ height: 'calc(100% - 36px)', padding: 20, marginTop: 20, overflow: 'auto' }}>
              <DocumentTemplateEditor data={contract} onChange={this.onChange} />
            </div>
          </>
        )}
      </Drawer>
    );
  }
}
