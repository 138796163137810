import HttpClient from '@utils/httpClient';

const service = {
  getExistsDocuments(bidRegistryId: string) {
    return HttpClient.get(`/api/bidRegistryDocument/existsDocument/${bidRegistryId}`).then((resp) => {
      return resp.data;
    });
  },
  createProtocol(bidRegistryId: string) {
    return HttpClient.get(`/api/bidRegistryDocument/createProtocol/${bidRegistryId}`).then((resp) => {
      return resp.data;
    });
  },
  createOffer(bidRegistryId: string) {
    return HttpClient.get(`/api/bidRegistryDocument/createOffer/${bidRegistryId}`).then((resp) => {
      return resp.data;
    });
  },
  getProtocol(bidRegistryId: string) {
    return HttpClient.get(`/api/bidRegistryDocument/protocol/${bidRegistryId}`, { responseType: 'blob' }).then((resp) => {
      return resp.data;
    });
  },
  getOffer(bidRegistryId: string) {
    return HttpClient.get(`/api/bidRegistryDocument/offer/${bidRegistryId}`, { responseType: 'blob' }).then((resp) => {
      return resp.data;
    });
  },
  removeProtocol(bidRegistryId: string) {
    return HttpClient.get(`/api/bidRegistryDocument/removeProtocol/${bidRegistryId}`).then((resp) => {
      return resp.data;
    });
  },
  removeOffer(bidRegistryId: string) {
    return HttpClient.get(`/api/bidRegistryDocument/removeOffer/${bidRegistryId}`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
