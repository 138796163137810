import { Col, Row, Spin } from 'antd';
import DataGrid, { Column, GroupPanel } from 'devextreme-react/data-grid';
import moment from 'moment';
import React, { Component } from 'react';

import CommercialOfferView from '@components/fw/DocumentViewer/CommercialOfferView';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { DocTypeModel } from '@stores/contractTemplateStore/ContractTemplatesData';
import designStore from '@stores/designStore/designStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

import ContractActionMenu from './ContractActionMenu';

interface ExtsProps {
  data: any;
  index: any;
  companyId: string;
  onChange?(): void;
  extTemplateList: DocTypeModel[];
}

class Exts extends Component<ExtsProps> {
  dataGrid: DataGrid;
  state = {
    rows: [],
    isBusy: false,
  };

  componentDidMount(): void {}

  render() {
    const { isBusy } = this.state;
    const contract = this.props.data.data;
    const { exts, organizationId } = contract;
    const isMobile = designStore.isMobile;

    return (
      <Spin spinning={isBusy}>
        {exts.length > 0 ? (
          <DataGrid
            allowColumnReordering={true}
            ref={(ref) => (this.dataGrid = ref)}
            dataSource={exts}
            loadPanel={{ enabled: false }}
            rowAlternationEnabled={true}
            remoteOperations={false}
            showRowLines={true}
            showBorders={true}
            allowColumnResizing={true}
            columnResizingMode={'widget'}
            columnAutoWidth={true}
            columnMinWidth={20}
            wordWrapEnabled={true}>
            <GroupPanel visible={false} />
            {INDEX_COLUMN}
            <Column allowFixing={true} dataField="extContractNumber" caption={'Номер доп. соглашения'} />
            <Column allowFixing={true} dataField="name" caption={'Наименование'} />
            <Column
              allowFixing={true}
              dataField="beginDate"
              caption={'Дата'}
              cellRender={(e) => moment(e.data.beginDate).format(GLOBAL_DATE_FORMAT)}
            />
            <Column
              allowFixing={true}
              dataField="endDate"
              caption={'Действует до'}
              cellRender={(e) => moment(e.data.endDate).format(GLOBAL_DATE_FORMAT)}
            />
            <Column
              allowFixing={true}
              dataField="commercialOfferId"
              caption={'КП'}
              cellRender={(e) => {
                return !!e.data.commercialOfferId ? <CommercialOfferView commercialOfferId={e.data.commercialOfferId} /> : null;
              }}
            />
            <Column
              allowFixing={true}
              dataField="signers"
              caption={'Подписали'}
              cellRender={(e) => {
                const { signers, templateMetadataId } = e.data;
                return (
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {!isMobile && <Col span={16}>{signers ? signers.map((s) => <div key={'extSigner' + s}>{s}</div>) : ''}</Col>}
                    <Col span={8} style={{ textAlign: 'right' }}>
                      <ContractActionMenu
                        type={'ext'}
                        contract={contract}
                        contractTemplateList={this.props.extTemplateList}
                        extTemplateList={this.props.extTemplateList}
                        organizationId={organizationId}
                        id={templateMetadataId}
                        getData={this.props.onChange}
                        hasSigners={!!(signers && signers.length)}
                      />
                    </Col>
                  </Row>
                );
              }}
            />
          </DataGrid>
        ) : (
          <div className="dx-datagrid-rowsview dx-datagrid-nowrap dx-empty" role="presentation">
            <span className="dx-datagrid-nodata">Нет данных</span>
          </div>
        )}
      </Spin>
    );
  }
}

export default Exts;
