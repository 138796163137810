import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { transportRegisterStore } from '@stores/transportRegisterStore';

export const dataSource = new DataSource({
  store: new CustomStore({
    key: ['itineraryid', 'bidregistryid', 'supplierbidregistryid'],
    load: (loadOptions) =>
      transportRegisterStore
        .getReport(loadOptions)
        .then((data) => {
          return data;
        })
        .catch(() => {
          throw 'Data Loading Error';
        }),
  }),
});
