import type { RadioChangeEvent } from 'antd';
import { Button, DatePicker, Radio, Space, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';

import './Calendar.styles.less';

const options = [
  { label: 'Месяц', value: 'month' },
  { label: 'Период', value: 'period' },
];

type Props = {
  onChange: (event: [Moment, Moment] | null, picker?: 'month' | 'period') => void;
  values: [Moment, Moment];
};

type State = {
  picker: 'month' | 'period';
  isRender: boolean;
};

class CalendarCustom extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      picker: 'month',
      isRender: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevState.picker !== this.state.picker) {
      if (this.state.picker === 'period') {
        this.changeCalendarHandler(this.props.values);
      } else {
        this.changeCalendarHandler(this.props.values[0]);
      }
    }

    if (this.state.isRender === true) {
      this.setState({ isRender: false });
    }
  }

  changePickerHandler = (e: RadioChangeEvent): void => {
    this.setState({ picker: e.target.value });
  };

  changeCalendarHandler = (date: Moment | [Moment, Moment]): void => {
    let values: Moment[] = [];
    const isArray = Array.isArray(date);

    if (this.state.picker === 'period' && isArray) {
      values = [date[0], date[1]];
    } else {
      values = [!isArray && moment(date.toString()).startOf('month'), !isArray && moment(date.toString()).endOf('month')];
    }

    this.props.onChange(values as [Moment, Moment], this.state.picker);
  };

  choiceDateHandler = (value: 'nextMonth' | 'prevMonth') => {
    const [start, end] = this.props.values;
    this.setState({ isRender: true });
    switch (value) {
      case 'prevMonth':
        start.subtract(1, 'month');
        end.subtract(1, 'month');
        break;
      case 'nextMonth':
        start.add(1, 'month');
        end.add(1, 'month');
        break;

      default:
        break;
    }
    this.props.onChange([start, end]);
  };

  render() {
    const { values } = this.props;
    return (
      <div className={'my-custom-calendar'} style={{ width: 'auto' }}>
        <Radio.Group className={'my-custom-calendar__radio-list'} defaultValue={this.state.picker} onChange={this.changePickerHandler}>
          <Space direction={'horizontal'}>
            {options.map((o) => (
              <Radio value={o.value} key={o.value}>
                {o.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        {!this.state.isRender &&
          (this.state.picker === 'period' ? (
            <DatePicker.RangePicker
              style={{ width: 300 }}
              className={'my-custom-calendar__calendar-range'}
              value={values}
              format={'DD.MM.YYYY'}
              allowClear={false}
              onChange={this.changeCalendarHandler}
              // onCalendarChange={(dates,dateStr,info)=>{
              //
              // }}
            />
          ) : (
            <div className={'my-custom-calendar__calendar'} style={{ width: 300 }}>
              <Tooltip placement={'bottom'} title={'Предыдущий месяц'}>
                <Button onClick={() => this.choiceDateHandler('prevMonth')}>{'<'}</Button>
              </Tooltip>
              <DatePicker
                format={'MMMM YYYY'}
                value={values[0]}
                defaultPickerValue={values[0]}
                picker={'month'}
                onChange={this.changeCalendarHandler}
                allowClear={false}
              />
              <Tooltip placement={'bottom'} title={'Следующий месяц'}>
                <Button onClick={() => this.choiceDateHandler('nextMonth')}>{'>'}</Button>
              </Tooltip>
            </div>
          ))}
      </div>
    );
  }
}

export default CalendarCustom;
