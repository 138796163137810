import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import StationSelector from '@components/routed/TariffFgkPage/StationSelector';

import tariffFgkBaseStore, { TariffFgkBaseResponseModel } from '@stores/TariffFgkBase/tariffFgkBaseStore';
import { CompanyTariffResponseModel } from '@stores/companyTariffStore/CompanyTariffData';
import companyTariffStore from '@stores/companyTariffStore/companyTariffStore';
import companyTariffStoreService from '@stores/companyTariffStore/companyTariffStore.service';
import designStore from '@stores/designStore/designStore';
import { TariffRequestModel, defaultTariffRequest } from '@stores/historyTariffInfo/historyTariffInfoStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

import { filterOptionFn } from '@utils/sort';

import CompanyTariffViewer from './CompanyTariffViewer';
import RequestKpModal from './RequestKpModal';

interface State {
  isBusy: boolean;
  response: CompanyTariffResponseModel;
  historyTariffInfoOpen: boolean;
  showResult: boolean;
  request: TariffRequestModel;
}
interface Props {
  companyId: string;
}

@observer
class CompanyTariffForm extends Component<Props, State> {
  formRef: FormInstance;
  data: TariffFgkBaseResponseModel;

  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: false,
      response: null,
      historyTariffInfoOpen: false,
      showResult: false,
      request: defaultTariffRequest(),
    };
  }

  handleFormReset = () => {
    this.setState({
      showResult: false,
      response: null,
    });
    tariffFgkBaseStore.reset();
    this.resetFields();
  };

  get companyName() {
    const company = companyTariffStore.tariffCompanies.find((c) => c.companyId == this.props.companyId);
    return company ? company.companyName : '';
  }

  render() {
    const { response, showResult } = this.state;
    const isBusy = this.state.isBusy || optionsStore.isBusy;
    const request = this.state.request;
    const isMobile = designStore.isMobile;
    const calculateDate = this.formRef ? this.formRef.getFieldValue('calculateDate') : null;
    const wagonTypesDictionary = optionsStore.wagonTypesDictionary;
    const wagonTypeGroupDictionary = optionsStore.wagonTypeGroupDictionary;
    const selectedWagonTypeGroupCode = this.formRef ? this.formRef.getFieldValue('wagonTypeGroupCode') : null;

    return (
      <>
        <ScrolledContainer
          style={{
            height: showResult ? 'calc(100vh - var(--header-height))' : 'calc(100vh - var(--header-height) - var(--header-height))',
          }}>
          <Spin spinning={isBusy}>
            {response && (
              <div style={{ display: showResult ? null : 'none' }}>
                <AdaptiveCard
                  extra={<RequestKpModal companyId={this.props.companyId} request={request} response={response} />}
                  bodyStyle={{ padding: isMobile ? '0px' : '16px' }}
                  title={<h1>Результат расчета тарифа</h1>}
                  actions={[
                    <Button size={'large'} type={'link'} key={'close'} onClick={() => this.setState({ showResult: false })}>
                      Закрыть
                    </Button>,
                  ]}>
                  <CompanyTariffViewer request={request} response={response} />
                </AdaptiveCard>
              </div>
            )}
            <Form
              layout="vertical"
              ref={(ref) => (this.formRef = ref)}
              name={'tariff'}
              labelCol={{ span: 0 }}
              initialValues={{
                ...request,
              }}
              onFinish={this.handleConfirm}>
              <AdaptiveCard
                extra={
                  isMobile ? (
                    <CollapsedToolbar
                      content={[
                        <Button key={'result'} type={'link'} onClick={() => this.setState({ showResult: true })} disabled={!response}>
                          Показать результат
                        </Button>,
                        <Button key={'submit_btn'} type={'link'} htmlType="submit" form={'tariff'} disabled={isBusy} loading={isBusy}>
                          Рассчитать
                        </Button>,
                      ]}
                    />
                  ) : null
                }
                bodyStyle={{ padding: isMobile ? null : '16px' }}
                title={<h1>Расчет тарифа {this.companyName}</h1>}
                style={{ display: showResult ? 'none' : null }}>
                <Row gutter={{ xs: 0 }}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="calculateDate" rules={[{ required: true, message: 'Необходимо заполнить поле ' }]}>
                      <FloatDatepicker
                        onChange={() => this.forceUpdate()}
                        position={isMobile ? 'top' : 'left'}
                        placeholder={'Дата расчёта'}
                        format={GLOBAL_DATE_FORMAT}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item label="" name={'calculateEmptyReturn'} valuePropName={'checked'}>
                      <Checkbox style={{ marginLeft: 20 }}>Расчет порожнего возврата</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item label="" name="emptyBool" valuePropName={'checked'}>
                      <Checkbox>Порожний</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                <StationSelector
                  disabled={false}
                  form={this.formRef}
                  loadName={'loadStationCode'}
                  destName={'destStationCode'}
                  calculateDate={calculateDate}
                />
                <h4>Группа подвижного состава</h4>
                <Row gutter={{ xs: 0 }}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="wagonTypeGroupCode">
                      <FloatSelect position={isMobile ? 'top' : 'top-left'} onChange={() => this.forceUpdate()} placeholder="Тип">
                        {wagonTypeGroupDictionary.map((item, index) => {
                          return (
                            <Select.Option value={item.groupCode} key={`wagonTypesCategory_${index}`}>
                              {item.groupName}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="wagonTypeCode">
                      <FloatSelect placeholder="Подвижной состав" dropdownMatchSelectWidth={false} showSearch filterOption={filterOptionFn}>
                        {wagonTypesDictionary
                          .filter((r) => !selectedWagonTypeGroupCode || r.groupCode == selectedWagonTypeGroupCode)
                          .map((item, index) => {
                            return (
                              <Select.Option value={item.wagonTypeCode} key={`speedCategory_${index}`}>
                                {item.wagonTypeName}
                              </Select.Option>
                            );
                          })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                </Row>
              </AdaptiveCard>
            </Form>
          </Spin>
        </ScrolledContainer>
        <AdaptivePageHeader
          style={{ display: showResult ? 'none' : null }}
          extra={[
            <Button
              key={'reset_btn'}
              form={'claim-editor'}
              onClick={this.handleFormReset}
              disabled={isBusy}
              type={'link'}
              size={isMobile ? 'small' : 'middle'}
              style={{ float: 'left' }}>
              Очистить все <DeleteOutlined />
            </Button>,
            <Button
              style={{ marginLeft: 10, marginRight: 10 }}
              key={'result'}
              size={isMobile ? 'small' : 'large'}
              type={'primary'}
              onClick={() => this.setState({ showResult: true })}
              disabled={!response}>
              Показать результат
            </Button>,
            <Button
              key={'submit_btn'}
              type="primary"
              htmlType="submit"
              form={'tariff'}
              disabled={isBusy}
              size={isMobile ? 'small' : 'large'}
              loading={isBusy}>
              Рассчитать
            </Button>,
          ]}
        />
      </>
    );
  }

  resetFields(fields?: string[]) {
    optionsStore.toggleStation = false;
    setTimeout(() => {
      if (this.formRef) {
        this.formRef.resetFields(fields);
      }
      this.forceUpdate();
    }, 100);
  }

  handleConfirm = (event: TariffRequestModel) => {
    if (optionsStore.toggleStation) {
      const load = event.loadStationCode;
      const dest = event.destStationCode;
      event.loadStationCode = dest;
      event.destStationCode = load;
    }

    this.setState({ isBusy: true });
    companyTariffStoreService
      .calcTariff(this.props.companyId, event)
      .then((r) => this.setState({ response: r, request: event, showResult: true }))
      .finally(() => {
        this.setState({ isBusy: false });
      });
  };
}

export default CompanyTariffForm;
