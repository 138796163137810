import { LoadOptions } from 'devextreme/data/load_options';

import HttpClient from '@utils/httpClient';

import { SupplierBidRegistryDirection, SupplierBidRegistryDto } from './supplierBidRegistryStore.types';

const service = {
  async getReport(begin: string, end: string, loadOptions: LoadOptions) {
    return (await HttpClient.post(`/api/supplierBidRegistry/report?begin=${begin}&end=${end}`, loadOptions)).data;
  },
  async createSupplierBidRegistry(body: SupplierBidRegistryDto) {
    return (await HttpClient.post(`/api/supplierBidRegistry`, body)).data;
  },
  async updateSupplierBidRegistry(body: SupplierBidRegistryDto) {
    return (await HttpClient.put(`/api/supplierBidRegistry`, body)).data;
  },
  async deleteSupplierBidRegistry(id: string) {
    return (await HttpClient.delete(`/api/supplierBidRegistry/${id}`)).data;
  },
  async getSupplierBidRegistryById(id: string) {
    return (await HttpClient.get(`/api/supplierBidRegistry/${id}`)).data;
  },
  async getDirections(id: string) {
    return (await HttpClient.get(`/api/supplierBidRegistry/${id}/direction`)).data;
  },
  async updateDirections(id: string, directions: SupplierBidRegistryDirection[]) {
    return (await HttpClient.put(`/api/supplierBidRegistry/${id}/direction`, directions)).data;
  },
};

export default service;
