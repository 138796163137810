import DataGrid, { Column, Export, FilterPanel, FilterRow, GroupPanel, HeaderFilter, StateStoring } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';

import docRegistryStoreService from '@stores/docRegistryStore/docRegistryStore.service';
import userStore from '@stores/userStore/userStore';

import DocRegistryActions from './DocRegistryActions';

interface State {
  datasource: DataSource;
}

class DocRegistryPage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      datasource: this.dataSource,
    };
  }
  componentDidMount() {
    document.title = 'Реестр документов';
  }

  get dataSource() {
    return new DataSource({
      store: new CustomStore({
        key: 'id',
        load: function (loadOptions) {
          return docRegistryStoreService
            .docRegistryData(loadOptions)
            .then((data) => {
              return data;
            })
            .catch(() => {
              throw 'Data Loading Error';
            });
        },
      }),
    });
  }

  render() {
    return (
      <>
        <AdaptivePageHeader title={'Реестр документов'} style={{ background: 'white' }} />
        <AdaptiveCard>
          <DataGrid
            allowColumnReordering={true}
            dataSource={this.state.datasource}
            //ref={(ref) => (this.dataGrid = ref)}
            loadPanel={{ enabled: true }}
            showRowLines={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            remoteOperations={true}
            showBorders={true}
            //onRowDblClick={this.handleRowDoubleClick}
            height={'calc(100vh - var(--padding)'}>
            <GroupPanel visible={true} />
            <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'docRegistryPage'} />
            <HeaderFilter visible={true} allowSearch={true} />
            <Export enabled={true} excelWrapTextEnabled={true} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />

            <Column allowFixing={true} dataField={'type'} caption={'Тип'} cssClass={'v-align-middle'} />
            <Column allowFixing={true} dataField="name" caption={'Название'} cssClass={'v-align-middle'} />
            <Column allowFixing={true} dataField="numberparent" caption={'Родительский номер'} cssClass={'v-align-middle'} />
            <Column
              allowFixing={true}
              cssClass={'v-align-middle'}
              dataField="begindate"
              caption={'Действует от'}
              allowGrouping={false}
              allowHeaderFiltering={false}
              dataType={'date'}
            />
            <Column
              allowFixing={true}
              cssClass={'v-align-middle'}
              dataField="enddate"
              caption={'Действует до'}
              allowGrouping={false}
              allowHeaderFiltering={false}
              dataType={'date'}
            />
            <Column allowFixing={true} dataField="executor" caption={'Исполнитель'} cssClass={'v-align-middle'} />
            <Column allowFixing={true} dataField="customer" caption={'Заказчик'} cssClass={'v-align-middle'} />
            <Column allowFixing={true} dataField="createuser" caption={'Создал'} cssClass={'v-align-middle'} />
            <Column
              allowFixing={true}
              cssClass={'v-align-middle'}
              dataField="createdate"
              caption={'Дата создания'}
              allowGrouping={false}
              allowHeaderFiltering={false}
              dataType={'datetime'}
            />
            <Column
              allowFixing={true}
              dataField="signers"
              caption={'Подписал'}
              cssClass={'v-align-middle'}
              customizeText={(e) => {
                const s = e.value;
                if (typeof s == 'string') {
                  const value = JSON.parse(s.replaceAll('""', '\\"'));
                  return value && value.join ? value.join(', ') : '';
                } else {
                  return '';
                }
              }}
            />

            <Column
              allowFixing={true}
              alignment={'center'}
              dataField="id"
              caption={'Действия'}
              allowGrouping={false}
              allowHeaderFiltering={false}
              allowSearch={false}
              allowSorting={false}
              allowFiltering={false}
              allowExporting={false}
              cellRender={(e) => {
                return <DocRegistryActions ecp={true} name={e.data.name} id={e.data.id} onChange={() => this.state.datasource.reload()} />;
              }}
            />
          </DataGrid>
        </AdaptiveCard>
      </>
    );
  }
}

export default observer(DocRegistryPage);
