import { ColumnsType } from 'antd/es/table';

import { TariffCountryRangeInfo } from '@stores/claimsStore/ClaimData';

import { renderCommission, renderCommissionWithNds, renderIsActive, renderNds, renderNdsType } from './columns.ui';

export const COLUMNS: ColumnsType<TariffCountryRangeInfo> = [
  {
    title: 'КП',
    dataIndex: 'isActive',
    key: 'isActive',
    width: 20,
    render: renderIsActive,
  },
  {
    title: 'Политика',
    dataIndex: 'tariff',
    key: 'tariffGuidName',
    render: (tariff) => tariff?.tariffGuidName,
  },
  { title: 'Государство', dataIndex: 'countryName', key: 'countryName' },
  {
    title: 'Тип',
    dataIndex: 'tariff',
    key: 'transportationTypeName',
    render: (tariff) => tariff?.transportationTypeName,
  },
  { title: 'КМ', dataIndex: 'range', key: 'range' },
  {
    title: 'Ед',
    dataIndex: 'tariff',
    key: 'tariffUnitName',
    render: (tariff) => tariff?.tariffUnitName,
  },
  {
    title: 'Тариф ИТОГО с НДС',
    dataIndex: 'tariff',
    key: 'freightChargeTkWithVat',
    render: (tariff) => tariff?.freightChargeTkWithVat?.toLocaleString('ru'),
  },
  {
    title: 'Комиссия без НДС',
    dataIndex: 'commission',
    key: 'commission',
    render: renderCommission,
  },
  {
    title: 'НДС',
    dataIndex: 'commission',
    key: 'nds',
    render: renderNdsType,
  },
  {
    title: 'Комиссия с НДС',
    dataIndex: 'commission',
    key: 'commission_vat',
    render: renderCommissionWithNds,
  },
];
