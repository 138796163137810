import { Form } from 'antd';
import React from 'react';

import { FieldRemoteSelectProps } from '@components/fw/Fields/remote-select/field-remote-select.types';
import RemoteSelect from '@components/fw/RemoteSelect';

import styles from '../field.module.scss';

export const FieldRemoteSelect = ({ name, rules, ...props }: FieldRemoteSelectProps) => {
  return (
    <Form.Item className={styles.fieldFlexAuto} rules={rules} name={name}>
      <RemoteSelect {...props} />
    </Form.Item>
  );
};
