import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { Component } from 'react';

import BorderWrapper from '@components/fw/FloatLabel/BorderWrapper';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FreightSelect from '@components/fw/FreightSelect';

import designStore from '@stores/designStore/designStore';

interface FreightListProps {
  form: FormInstance;
  arrayName: string;
  gngName: string;
  etsngName: string;
  primitiveResult?: boolean;
  weightName?: string;
  disabled?: boolean;
}

interface FreightListState {}

class FreightList extends Component<FreightListProps, FreightListState> {
  constructor(props: FreightListProps) {
    super(props);
    this.state = {};
  }

  hasDuplicates(arr) {
    return new Set(arr).size !== arr.length;
  }

  render() {
    const isMobile = designStore.isMobile;
    const { weightName, gngName, etsngName, arrayName, form, primitiveResult, disabled } = this.props;
    let freightEtsngCodes = [];

    return (
      <Form.List
        name={arrayName}
        rules={[
          {
            validator: async (rule, value) => {
              if (Array.isArray(value)) {
                if (value.length > 1) {
                  value.map((i) => {
                    if (!!i) {
                      freightEtsngCodes.push(i.etsngCode || i.etsng);
                    }
                  });
                }
                if (freightEtsngCodes.length > 1) {
                  if (this.hasDuplicates(freightEtsngCodes)) {
                    freightEtsngCodes = [];
                    return Promise.reject(new Error('Груз должен быть уникальным'));
                  }
                }
                freightEtsngCodes = [];
              } else {
                return Promise.reject(new Error('Добавьте груз'));
              }
            },
          },
        ]}>
        {(fields, { add, remove }, err) => {
          return (
            <>
              <Form.ErrorList errors={err.errors} />
              {fields.map((field, index, ...restField) => {
                return (
                  <Row gutter={0} key={field.key}>
                    <FreightSelect
                      span={weightName ? 10 : 11}
                      primitiveResult={primitiveResult}
                      field={field}
                      form={form}
                      disabled={disabled}
                      arrayName={arrayName}
                      etsngName={etsngName}
                      gngName={gngName}
                    />
                    {weightName ? (
                      <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                        <Form.Item
                          {...restField}
                          name={[field.name, weightName]}
                          fieldKey={[field.key, weightName]}
                          rules={[{ required: true, message: 'Укажите вес' }]}>
                          <FloatInputNumber placeholder="Вес (тонн)" position={'middle'} />
                        </Form.Item>
                      </Col>
                    ) : null}
                    <Col
                      xs={24}
                      sm={24}
                      md={weightName ? 1 : 2}
                      lg={weightName ? 1 : 2}
                      xl={weightName ? 1 : 2}
                      style={{ textAlign: 'center' }}>
                      <BorderWrapper position={isMobile ? 'bottom' : 'right'}>
                        <DeleteOutlined key={'delete'} onClick={() => remove(field.name)} style={{ marginTop: 16 }} />
                      </BorderWrapper>
                    </Col>
                  </Row>
                );
              })}
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ paddingTop: '16px' }}>
                <Col span={24}>
                  <Form.Item>
                    <Button className={'blue-gray-button'} disabled={disabled} onClick={() => add()} block icon={<PlusOutlined />}>
                      Добавить груз
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          );
        }}
      </Form.List>
    );
  }
}

export default FreightList;
