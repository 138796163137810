import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import React, { Component } from 'react';

import { InputPosition } from '@stores/designStore/designData';

import BorderWrapper from './BorderWrapper';
import styles from './FloatLabel.module.scss';

interface State {
  focus: boolean;
}

class FloatInput extends Component<InputProps & { position?: InputPosition; placeholder?: any }, State> {
  constructor(props: InputProps & { position?: InputPosition }) {
    super(props);

    this.state = {
      focus: false,
    };
  }

  render() {
    const { value, placeholder, position } = this.props;
    const labelClass = this.state.focus || value ? styles.labelfloat : styles.label;
    const inputClass = this.props.addonBefore || this.props.addonAfter ? styles.inputwithaddon : styles.input;
    const isStringLabel = typeof this.props.placeholder === 'string';

    return (
      <BorderWrapper
        disabled={this.props.disabled}
        className={styles.floatlabel}
        position={position}
        onFocusChange={(e) => this.setState({ focus: e })}>
        <Input {...this.props} bordered={false} placeholder={null} className={inputClass} size={'large'} />
        <span style={{ pointerEvents: isStringLabel ? 'none' : null }} className={labelClass}>
          {placeholder}
        </span>
      </BorderWrapper>
    );
  }
}
export default FloatInput;
