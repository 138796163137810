import React from 'react';

const StationDxCell: React.FC<any> = (props) => {
  const regex = /([^a-zA-Z0-9а-яА-Я])/;
  const value = props.value;

  if (!value || typeof value !== 'string') return <span></span>;

  const parts = value.split(regex);
  let result = '';

  for (let i = 0; i < parts.length; i++) {
    // Если слово полностью состоит из англ. букв, например, римская цифра III, то не применяем форматирование
    if (/[a-zA-Z]/.test(parts[i])) {
      result += parts[i];
    } else {
      // Применяем форматирование только в том случае, если длина слова больше 1 символа
      if (parts[i].length > 1) {
        result += `${parts[i].charAt(0).toUpperCase()}${parts[i].slice(1).toLowerCase()}`;
      } else {
        result += parts[i];
      }
    }
  }

  return <span>{result}</span>;
};

export default StationDxCell;
