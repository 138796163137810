export interface DistributionModel {
  /// Идентификатор рассылки
  distributionId: number;

  /// Идентификатор отчета
  customReportId: number;

  /// Название отчета
  /// *Необязательное поле
  customReportName: string;

  /// Идентификатор фильтра
  filterId: number;

  /// Название фильтра
  /// *Необязательное поле
  filterName: number;

  /// Формат отчета
  format: 'xlsx';

  /// Идентификатор пользователя
  /// *Необязательное поле
  userId: string;

  /// Название пользователя
  userName: string;

  /// Название компании
  /// *Необязательное поле
  companyName: string;

  /// Дни недели для рассылки
  days: number[];

  /// Время рассылки
  times: string[];

  /// Почты для рассылки
  emails: string[];

  /// Ввремя создания рассылки
  createDate: any;
}

export const emptyDistribution: DistributionModel = {
  distributionId: null,
  customReportId: null,
  customReportName: null,
  filterId: null,
  filterName: null,
  format: 'xlsx',
  userId: null,
  userName: null,
  companyName: null,
  days: [],
  times: [],
  emails: [],
  createDate: null,
};
