import DataGrid, {
  Column,
  Export,
  FilterRow,
  GroupItem,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  Summary,
} from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import tariffFgkBaseStore from '@stores/TariffFgkBase/tariffFgkBaseStore';
import designStore from '@stores/designStore';
import { ignoreCaseFilter } from '@stores/dispatcherStore/DispatcherData';
import userStore from '@stores/userStore/userStore';

import TariffFgkHistoryToolBar from './TariffFgkHistoryToolBar';

interface TariffFgkHistoryProps {
  onCancel(): void;
  onConfirm?(userTariffRequestId): void;
}

interface TariffFgkHistoryState {
  autoExpandAll: boolean;
}

class TariffFgkHistory extends React.Component<TariffFgkHistoryProps, TariffFgkHistoryState> {
  dataGrid: DataGrid;
  constructor(props) {
    super(props);
    this.state = {
      autoExpandAll: false,
    };
  }

  selectionChanged = (data) => {
    tariffFgkBaseStore.setSelected(data.selectedRowKeys);
  };

  render() {
    const data = tariffFgkBaseStore.userRequestHistory;
    const isAdmin = ['tech', 'admin', 'manager'].includes(userStore.userData.roleCode);
    const isMobile = designStore.isMobile;
    const padding = isMobile ? '6.2rem' : '11rem';
    const height = `calc(100vh - ${padding})`;

    return (
      <AdaptiveCard>
        <TariffFgkHistoryToolBar
          onChose={this.props.onConfirm}
          onRemove={() => {
            if (this.dataGrid) {
              this.dataGrid.instance.clearSelection();
            }
          }}
          onAutoExpand={() => this.setState({ autoExpandAll: !this.state.autoExpandAll })}
        />
        <DataGrid
          dataSource={data}
          style={{ height: height }}
          ref={(ref) => (this.dataGrid = ref)}
          onRowDblClick={(e) => this.props.onConfirm(e.data.requestid)}
          onSelectionChanged={this.selectionChanged}
          allowColumnReordering={true}
          selection={{ mode: 'single' }}
          remoteOperations={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showBorders={true}>
          <Paging enabled={true} defaultPageSize={25} />
          <Pager
            allowedPageSizes={[25, 50, 100]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            infoText={'Всего: {2}'}
          />
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <HeaderFilter visible={true} allowSearch={true} />
          <FilterRow visible={isAdmin} />
          <Export enabled={true} excelWrapTextEnabled={true} />
          <Column
            allowFixing={true}
            allowResizing={true}
            allowHeaderFiltering={false}
            dataField="begindate"
            caption={'На дату'}
            dataType={'date'}
          />
          {isAdmin && (
            <Column
              allowFixing={true}
              allowResizing={true}
              allowHeaderFiltering={false}
              dataField="createdate"
              caption={'Дата создания'}
              dataType={'date'}
            />
          )}
          <Column
            allowFixing={true}
            allowResizing={true}
            dataField="loadstationname"
            caption={'Отправление'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column
            allowFixing={true}
            allowResizing={true}
            dataField="deststationname"
            caption={'Назначение'}
            calculateFilterExpression={ignoreCaseFilter}
          />
          <Column
            allowFixing={true}
            allowResizing={true}
            dataField="freightetsngname"
            caption={'Груз'}
            calculateFilterExpression={ignoreCaseFilter}
          />

          <Column allowFixing={true} allowResizing={true} dataField="rate" caption={'Ставка без НДС'} />
          <Column allowFixing={true} allowResizing={true} dataField="stminv" caption={'За инв.вагон'} />
          <Column allowFixing={true} allowResizing={true} dataField="stmsobst" caption={'За собст.вагон'} />

          {isAdmin && (
            <Column
              allowFixing={true}
              allowResizing={true}
              dataField="companyname"
              caption={'Компания'}
              calculateFilterExpression={ignoreCaseFilter}
            />
          )}
          {isAdmin && (
            <Column
              allowFixing={true}
              allowResizing={true}
              dataField="mannames"
              caption={'Менеджер'}
              calculateFilterExpression={ignoreCaseFilter}
            />
          )}
          {isAdmin && (
            <Column
              allowFixing={true}
              allowResizing={true}
              dataField="userlogin"
              caption={'Пользователь'}
              calculateFilterExpression={ignoreCaseFilter}
            />
          )}
          <Summary>
            <GroupItem column="requestid" summaryType="count" displayFormat="кол-во {0}" />
          </Summary>
        </DataGrid>
      </AdaptiveCard>
    );
  }

  handelClose = () => {
    this.props.onCancel();
  };
}

export default observer(TariffFgkHistory);
