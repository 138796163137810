import HttpClient from '@utils/httpClient';

import { CompanyUsingStatisticModel } from './companyUsingStatisticData';

const service = {
  ///  Изменить статистику использования одной компании в одном годе
  async updateStatistic(data: CompanyUsingStatisticModel): Promise<void> {
    return (await HttpClient.post(`/api/company/usingstat`, data)).data;
  },

  ///     Получить статистику использования
  async getStatistic(year: number): Promise<CompanyUsingStatisticModel[]> {
    return (await HttpClient.get(`/api/company/usingstat`, { params: { year } })).data;
  },
};

export default service;
