import { DeleteOutlined, DownOutlined, DownloadOutlined, EditOutlined, EyeOutlined, FileOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import PdfViewModal from '@components/fw/DocumentViewer/PdfViewModal';

import { CompanyServiceSetModel } from '@stores/adminBilling/adminBillingData';
import adminBillingStore from '@stores/adminBilling/adminBillingStore';
import adminBillingStoreService from '@stores/adminBilling/adminBillingStore.service';

import { showSuccessNotify } from '@utils/notify';

import ReferenceEditor from './ReferenceEditor';

interface ComponentProps {
  data: CompanyServiceSetModel;
}

interface ComponentState {
  showEditor: boolean;
  loadingDoc: boolean;
  loadingDelete: boolean;
  attachments: { [key: string]: Blob };
  loadingAttachment: boolean;
  viewBlob: Blob;
}

@observer
class ReferenceActionMenu extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      showEditor: false,
      loadingDoc: false,
      loadingDelete: false,
      attachments: {},
      loadingAttachment: false,
      viewBlob: null,
    };
  }

  onDownload = (id: string, type: 'contract' | 'ext', name: string) => {
    this.setState({ loadingDoc: true });
    adminBillingStoreService
      .contractGetFile(id, type)
      .then((doc) => {
        if (doc && doc.size) {
          let mockEvent = document.createEvent('MouseEvents');
          let mockElement = document.createElement('a');
          let typeName = type == 'contract' ? 'Договор ' : 'Доп.соглашение ';
          mockElement.download = typeName + '_' + name + '.pdf';
          mockElement.href = window.URL.createObjectURL(new Blob([doc]));
          mockElement.dataset.downloadurl = ['blob', mockElement.download, mockElement.href].join(':');
          mockEvent.initEvent('click');
          mockElement.dispatchEvent(mockEvent);
        }
      })
      .finally(() => this.setState({ loadingDoc: false }));
  };

  onView = (id: string, type: 'contract' | 'ext') => {
    this.setState({ loadingDoc: true });
    adminBillingStoreService
      .contractGetFile(id, type)
      .then((doc) => {
        if (doc && doc.size) {
          this.setState({ viewBlob: doc });
        }
      })
      .finally(() => this.setState({ loadingDoc: false }));
  };

  onDelete = () => {
    this.setState({ loadingDelete: true });
    adminBillingStore
      .deleteServiceSet(this.props.data)
      .then(() => {})
      .finally(() => this.setState({ loadingDelete: false }));
  };

  loadAllAttachments = () => {
    this.setState({ loadingAttachment: true });
    this.loadAttachment(this.props.data.id, 'contract');
    this.props.data.services.forEach((s) => {
      this.loadAttachment(s.id, 'ext');
    });
  };

  loadAttachment = (id: string, type: 'contract' | 'ext') => {
    this.setState({ loadingAttachment: true });
    adminBillingStoreService
      .getAttachment(id, type)
      .then((doc) => {
        if (doc && doc.size) {
          const attachments = this.state.attachments;
          attachments[id] = doc;
          this.setState({ attachments });
        }
      })
      .finally(() => this.setState({ loadingAttachment: false }));
  };

  onUploadAttachment = (id: string, type: 'contract' | 'ext') => {
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, application/pdf';

    input.onchange = (e) => {
      //@ts-ignore
      var file = e.path[0].files[0];
      let fm = new FormData();
      fm.append('file', file);

      this.setState({ loadingAttachment: true });
      adminBillingStoreService
        .updateAttachment(fm, id, type)
        .then((ans) => {
          showSuccessNotify('', 'Файл загружен');
          this.loadAttachment(id, type);
        })
        .finally(() => this.setState({ loadingAttachment: false }));
    };
    input.click();
  };

  onDeleteAttachment = (id: string, type: 'contract' | 'ext') => {
    this.setState({ loadingAttachment: true });
    adminBillingStoreService
      .deleteAttachment(id, type)
      .finally(() => this.setState({ loadingAttachment: false }))
      .then((result: any) => {
        showSuccessNotify('', 'Файл удален');
        const attachments = this.state.attachments;
        attachments[id] = null;
        this.setState({ attachments });
      });
  };

  onDownloadAttachment = (id: string) => {
    const blob = this.state.attachments[id];
    if (!blob) return;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const type = blob.type == 'image/jpeg' ? 'jpeg' : 'pdf';
    link.setAttribute('download', `${id}.${type}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  onEditor = () => {
    this.setState({ showEditor: true });
  };

  onAttachmentView = (id: string) => {
    this.setState({ viewBlob: this.state.attachments[id] });
  };

  onViewClose = () => {
    this.setState({ viewBlob: null });
  };

  render() {
    const viewBlob = this.state.viewBlob;
    const attType = viewBlob && viewBlob.type.includes('image') ? 'jpeg' : 'pdf';
    const data = this.props.data;
    return (
      <>
        <ReferenceEditor visible={this.state.showEditor} data={this.props.data} onClose={() => this.setState({ showEditor: false })} />
        {viewBlob && <PdfViewModal data={viewBlob} type={attType} visible={!!viewBlob} onClose={this.onViewClose} />}
        <Dropdown
          onVisibleChange={(e) => {
            if (e) this.loadAllAttachments();
          }}
          overlay={
            <Menu>
              <Menu.Item key="1" icon={<EditOutlined />} onClick={() => this.onEditor()}>
                Изменить
              </Menu.Item>
              {data.serviceSetContract && (
                <Menu.Item
                  key="3"
                  icon={<DownloadOutlined />}
                  onClick={() =>
                    this.onDownload(
                      data.serviceSetContract.templateMetadataId,
                      'contract',
                      data.serviceSetContract ? data.serviceSetContract.name : data.id,
                    )
                  }>
                  Скачать
                </Menu.Item>
              )}
              {data.serviceSetContract && (
                <Menu.Item
                  key="4"
                  icon={<EyeOutlined />}
                  onClick={() => this.onView(data.serviceSetContract.templateMetadataId, 'contract')}>
                  Посмотреть
                </Menu.Item>
              )}

              <Menu.Item key="5" icon={<DeleteOutlined />} onClick={() => this.onDelete()}>
                Удалить
              </Menu.Item>
              {this.attachmentMenu(this.props.data.id, 'contract')}
              <Menu.ItemGroup key="extGroup" title="Доп. соглашения">
                {data.services.map((s, index) => (
                  <Menu.SubMenu key={'ext' + s.id} title={`${s.extContract ? s.extContract.extContractNumber : index + 1}`}>
                    {s.extContract && (
                      <Menu.Item
                        key={'download' + s.id}
                        icon={<EyeOutlined />}
                        onClick={() => this.onView(s.extContract.templateMetadataId, 'ext')}>
                        Посмотреть
                      </Menu.Item>
                    )}
                    {s.extContract && (
                      <Menu.Item
                        key={'view' + s.id}
                        icon={<DownloadOutlined />}
                        onClick={() =>
                          this.onDownload(s.extContract.templateMetadataId, 'ext', s.extContract ? s.extContract.extContractNumber : s.id)
                        }>
                        Скачать
                      </Menu.Item>
                    )}
                    {this.attachmentMenu(s.id, 'ext')}
                  </Menu.SubMenu>
                ))}
              </Menu.ItemGroup>
            </Menu>
          }>
          <Button type={'text'} loading={this.state.loadingDoc}>
            Действия <DownOutlined />
          </Button>
        </Dropdown>
      </>
    );
  }

  attachmentMenu = (id: string, type: 'contract' | 'ext') => {
    const attachment = this.state.attachments[id];
    return (
      <Menu.SubMenu title={'Вложение'} icon={<FileOutlined />}>
        <Menu.Item key={id + '1'} icon={<DownloadOutlined />} onClick={() => this.onDownloadAttachment(id)} disabled={!attachment}>
          Скачать
        </Menu.Item>
        <Menu.Item key={id + '2'} icon={<EyeOutlined />} onClick={() => this.onAttachmentView(id)} disabled={!attachment}>
          Посмотреть
        </Menu.Item>
        <Menu.Item key={id + '3'} disabled={!!attachment} icon={<UploadOutlined />} onClick={() => this.onUploadAttachment(id, type)}>
          Загрузить
        </Menu.Item>

        <Menu.Item key={id + '4'} icon={<DeleteOutlined />} onClick={() => this.onDeleteAttachment(id, type)} disabled={!attachment}>
          Удалить
        </Menu.Item>
      </Menu.SubMenu>
    );
  };
}

export default ReferenceActionMenu;
