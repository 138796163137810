import HttpClient from '@utils/httpClient';

import { OrganizationModel } from './index';
import { OrganizationFilter } from './organizationsStore';
import {
  AppendixDowntimeData,
  BankRequisites,
  BankSearchRequisites,
  OrganizationRequisites,
  OrganizationSearchRequisites,
  TemplateOrgContractModel,
  TemplateOrgExtContractModel,
} from './organizationsStoreData';

const getController = (type: string) => {
  if (type == 'contract') return 'organizationContract';
  if (type == 'ext') return 'organizationExtContract';
};

const service = {
  getOrganizations(data: any) {
    return HttpClient.post(`/api/organization/list`, data).then((resp) => {
      return resp.data;
    });
  },

  getOrganizationTypes() {
    return HttpClient.get(`/api/organization/types`).then((resp) => {
      return resp.data;
    });
  },

  getOrganization(organizationId: string) {
    return HttpClient.get(`/api/organization/list/${organizationId}`).then((resp) => {
      return resp.data;
    });
  },

  create(data: any) {
    return HttpClient.post(`/api/organization/create`, data).then((resp) => {
      return resp.data;
    });
  },

  update(data: any) {
    return HttpClient.post(`/api/organization/update`, data).then((resp) => {
      return resp.data;
    });
  },

  remove(organizationId: string) {
    return HttpClient.delete(`/api/organization/remove/${organizationId}`).then((resp) => {
      return resp.data;
    });
  },

  async getRequisitesByInn(inn: string): Promise<OrganizationRequisites> {
    return (await HttpClient.get(`/api/organization/requisites`, { params: { inn } })).data;
  },

  async searchRequisites(value: string): Promise<OrganizationSearchRequisites[]> {
    return (await HttpClient.get(`/api/organization/requisites/search`, { params: { value } })).data;
  },

  async getBankRequisitesByBIK(bik: string): Promise<BankRequisites> {
    return (await HttpClient.get(`/api/organization/requisites/bank`, { params: { bik } })).data;
  },

  async bankSearchRequisites(value: string): Promise<BankSearchRequisites[]> {
    return (await HttpClient.get(`/api/organization/requisites/bank/search`, { params: { value } })).data;
  },

  async getUserOrganizations(body: OrganizationFilter): Promise<OrganizationModel[]> {
    return (await HttpClient.post(`/api/organization/list`, body)).data;
  },

  async saveContract(data: TemplateOrgContractModel, type: string): Promise<string> {
    return (await HttpClient.post(`/api/${getController(type)}`, data)).data;
  },

  async getAttachment(templateMetadataId: string, type: string): Promise<Blob> {
    return (
      await HttpClient.get(`/api/${getController(type)}/attachment/${templateMetadataId}`, {
        responseType: 'blob',
      })
    ).data;
  },

  async updateAttachment(data: any, templateMetadataId: string, type: string): Promise<void> {
    const formData = new FormData();
    formData.append('file', data);
    return (
      await HttpClient.post(`/api/${getController(type)}/attachment/${templateMetadataId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    ).data;
  },
  async deleteAttachment(templateMetadataId: string, type: string): Promise<void> {
    return (await HttpClient.delete(`/api/${getController(type)}/attachment/${templateMetadataId}`)).data;
  },

  async getOneContract(templateId: string, type: string): Promise<TemplateOrgExtContractModel> {
    return (await HttpClient.get(`/api/${getController(type)}`, { params: { templateId } })).data;
  },

  async getContractList(organizationId: string, type: string): Promise<TemplateOrgContractModel[]> {
    return (await HttpClient.get(`/api/${getController(type)}/byOrganization`, { params: { organizationId } })).data;
  },

  async deleteContract(templateId: string, type: string): Promise<void> {
    return (await HttpClient.delete(`/api/${getController(type)}`, { params: { templateId } })).data;
  },

  async contractSaveAndGetFile(
    data: TemplateOrgContractModel,
    type: string,
    progressFunc: ((e: ProgressEvent) => void) | undefined = undefined,
  ): Promise<Blob> {
    return (
      await HttpClient.post(`/api/${getController(type)}/saveAndGet`, data, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
      })
    ).data;
  },

  async contractGetDemoFile(
    data: TemplateOrgContractModel,
    type: string,
    progressFunc: ((e: ProgressEvent) => void) | undefined = undefined,
  ): Promise<Blob> {
    return (
      await HttpClient.post(`/api/${getController(type)}/demoFile`, data, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
      })
    ).data;
  },
  async contractGetFile(
    templateMetadataId: string,
    type: string,
    progressFunc: ((e: ProgressEvent) => void) | undefined = undefined,
  ): Promise<Blob> {
    return (
      await HttpClient.get(`/api/${getController(type)}/contract`, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
        params: { templateMetadataId },
      })
    ).data;
  },

  async getDowntime(contractId: string, rps: string) {
    return (await HttpClient.get<AppendixDowntimeData | null>(`/api/organizationDowntime`, { params: { contractId, rps } })).data;
  },
};

export default service;
