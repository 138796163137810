import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import DocumentTemplateEditor from '@components/fw/DocumentTemplateEditor/DocumentTemplateEditor';
import PdfViewModal from '@components/fw/DocumentViewer/PdfViewModal';
import { ContractTemplatesDrawer } from '@components/fw/Drawers/CompanyEditor/ContractTemplates/ContractTemplatesDrawer';
import { getBase64 } from '@components/fw/Drawers/CompanyEditor/SinglePictureUpload';

import { ContractElement, DocTypeContext, DocTypeTemplateModel } from '@stores/contractTemplateStore/ContractTemplatesData';
import companyTemplatesService from '@stores/contractTemplateStore/companyTemplates.service';
import supplierTemplateService from '@stores/contractTemplateStore/supplierTemplates.service';
import designStore from '@stores/designStore';

interface ComponentProps {
  id: string;
  parentId: string;
  onFinish(): void;
  dataContext: DocTypeContext;
  type: 'company' | 'supplier';
}

interface ComponentState {
  data: ContractElement;
  loading: boolean;
  loadingDoc: boolean;
  visible: boolean;
  doc: string;
  blob: Blob;
  isOpenContractTemplatesDrawer: boolean;
}

@observer
class ContractTemplateEditor extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      data: null,
      loading: false,
      visible: false,
      loadingDoc: false,
      doc: null,
      blob: null,
      isOpenContractTemplatesDrawer: false,
    };
  }

  componentDidMount() {}

  get service() {
    if (this.props.type == 'company') return companyTemplatesService;
    if (this.props.type == 'supplier') return supplierTemplateService;
  }

  onOpen = () => {
    this.setState({ visible: true });
    if (this.props.id) {
      this.setState({ loading: true });
      this.service
        .getContract(this.props.id)
        .then((ans) => {
          this.setState({
            data: {
              nestedElements: ans.data,
              relativePath: null,
              value: ans.name,
              elementType: 'title',
            },
          });
        })
        .finally(() => this.setState({ loading: false }));
    } else {
      this.setState({ data: { nestedElements: [], elementType: 'title', value: '', relativePath: null } });
    }
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  onChange = () => {
    const data = JSON.parse(JSON.stringify(this.state.data));
    this.setState({ data });
  };

  save = () => {
    this.setState({ loading: true });
    this.service
      .createOrUpdateContract({
        docTypeId: this.props.id,
        data: this.state.data.nestedElements,
        name: this.state.data.value,
        companyId: this.props.parentId,
        supplierId: this.props.parentId,
        createDate: null,
        createdBy: null,
        dataContext: this.props.dataContext,
      })
      .then(() => {
        this.setState({ visible: false });
        this.props.onFinish();
      })
      .finally(() => this.setState({ loading: false }));
  };

  getView = () => {
    this.setState({ loadingDoc: true });
    this.service
      .demoContract({
        docTypeId: this.props.id,
        data: this.state.data.nestedElements,
        name: this.state.data.value,
        companyId: this.props.parentId,
        supplierId: this.props.parentId,
        createDate: null,
        createdBy: null,
        dataContext: this.props.dataContext,
      })
      .then((doc) => {
        if (doc && doc.size) {
          getBase64(doc, (docUrl) => this.setState({ doc: docUrl, blob: doc }));
        }
      })
      .finally(() => this.setState({ loadingDoc: false }));
  };

  createBaseSupplierContract = () => {
    this.setState({ loading: true });
    supplierTemplateService
      .createBaseContract({
        docTypeId: null,
        companyId: this.props.parentId,
        supplierId: this.props.parentId,
        name: null,
        dataContext: this.props.dataContext,
        data: [],
        createdBy: null,
        createDate: null,
      })
      .then((ans) => {
        this.setState({
          data: {
            nestedElements: ans.data,
            relativePath: null,
            value: ans.name,
            elementType: 'title',
          },
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  onTemplateSelected = (template: DocTypeTemplateModel) => {
    this.setState({
      data: {
        nestedElements: template.data,
        relativePath: null,
        value: template.name,
        elementType: 'title',
      },
    });
  };

  openContractTemplatesDrawer = () => {
    this.setState({ isOpenContractTemplatesDrawer: true });
  };

  closeContractTemplatesDrawer = () => {
    this.setState({ isOpenContractTemplatesDrawer: false });
  };

  render() {
    const data = this.state.data;
    return (
      <>
        <Button
          icon={this.props.id ? <EditOutlined /> : <PlusOutlined />}
          onClick={this.onOpen}
          size={'small'}
          type={this.props.id ? 'text' : null}>
          {this.props.id ? null : 'Создать'}
        </Button>
        <Drawer
          width={designStore.isMobile ? '100%' : '90%'}
          open={this.state.visible}
          onClose={this.onClose}
          footer={[
            <Button onClick={this.save} key={'saveAndClose'} type={'primary'}>
              Сохранить и закрыть
            </Button>,
            <Button loading={this.state.loadingDoc} onClick={this.getView} key={'saveAndView'}>
              Посмотреть документ
            </Button>,
            <>
              {this.props.type === 'company' ? (
                <Button onClick={this.openContractTemplatesDrawer} key={'openContractTemplatesDrawer'}>
                  Выбрать базовый
                </Button>
              ) : (
                <Button onClick={this.createBaseSupplierContract} key={'createBaseSupplierContract'}>
                  Заполнить базовый
                </Button>
              )}
            </>,
          ]}>
          <Spin spinning={this.state.loading}>{data ? <DocumentTemplateEditor data={data} onChange={this.onChange} /> : null}</Spin>
        </Drawer>
        <ContractTemplatesDrawer
          context={this.props.dataContext}
          isOpen={this.state.isOpenContractTemplatesDrawer}
          onSelect={this.onTemplateSelected}
          onClose={this.closeContractTemplatesDrawer}
        />
        <PdfViewModal data={this.state.blob} visible={!!this.state.doc} onClose={() => this.setState({ doc: null })} />
      </>
    );
  }
}

export default ContractTemplateEditor;
