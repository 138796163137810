const splitter = '/';

type TreeNode = {
  key: string;
  title: string;
  children: TreeNode[];
};

export function buildTree(items: string[]) {
  if (!items) return [];
  const firstLevel = items.map((x) => {
    const first = x.split(splitter)[0];
    const obj: TreeNode = {
      key: x.split(splitter)[0],
      title: getTitle(x.split(splitter)[0]),
      children: items
        .filter((y) => y !== x.split(splitter)[0] && y.split(splitter)[0] === x.split(splitter)[0])
        .map((y) => {
          return {
            key: y,
            title: getTitle(y),
            children: [],
          };
        }),
    };

    return obj;
  });

  return firstLevel.filter(unique);
}

const unique = (value, index, self) => {
  return self.map((x) => x.key).indexOf(value.key) === index;
};

export const getTitle = (key: string): string => {
  switch (key) {
    case 'administration':
      return 'Администрирование';
    case 'administration/users':
      return 'Вкладка "Пользователи"';

    case 'administration/companies':
      return 'Вкладка "Транспортные компании"';
    case 'administration/companies/billing':
      return 'Редактор транспортной компании - Биллинг';
    case 'administration/companies/services':
      return 'Редактор транспортной компании - Биллинг - Услуги';
    case 'administration/companies/requisites':
      return 'Редактор транспортной компании - Реквизиты';
    case 'administration/companies/integration':
      return 'Редактор транспортной компании - Интеграции';
    case 'administration/companies/forwarding':
      return 'Редактор транспортной компании - Экспедирование';
    case 'administration/companies/banks':
      return 'Редактор транспортной компании - Банк';
    case 'administration/companies/smtp':
      return 'Редактор транспортной компании - Настройки отправителя';
    case 'administration/companies/tariff':
      return 'Редактор транспортной компании - Ставка компании';

    case 'administration/organizations':
      return 'Вкладка "Организации"';
    case 'administration/history':
      return 'Вкладка "История"';

    case 'claims':
      return 'Заявки';
    case 'claims/status':
      return 'показывать кнопки "На согласовании", "Согласовать", "На согласовании, "Выполнить"';
    case 'claims/priority':
      return 'показывать выбор приоритета';
    case 'claims/edit':
      return 'создание/редактирование заявки';
    case 'claims/delete':
      return 'удаление заявки';

    case 'options':
      return 'Расчет тарифа';

    case 'rent':
      return 'Аренда';

    case 'etran':
      return 'Этран';

    case 'sends':
      return 'Отправки';
    case 'sends/status':
      return 'показывать кнопки "На исполнении", "Выполнить"';
    case 'sends/edit':
      return 'создание/редактирование отправки';
    case 'sends/delete':
      return 'удаление отправки';

    case 'billing':
      return 'Биллинг';
    case 'billing/statistic':
      return 'Статистика';
    case 'billing/orders/wagons':
      return 'Заказы "Вагоны"';
    case 'billing/orders/wagons/edit':
      return 'редактирование заказов на вагоны';
    case 'billing/orders/containers':
      return 'Заказы "Контейнеры"';
    case 'billing/orders/containers/edit':
      return 'редактирование заказов на контейнеры';

    case 'dispatcher':
      return 'Диспетчер';
    case 'dispatcher/reports':
      return 'Диспетчер - Отчеты';
    case 'dispatcher/itineraries':
      return 'Диспетчер - Аналитика';
    case 'dispatcher/itineraries/wagons':
      return 'Диспетчер - Рейсы - Вагоны';
    case 'dispatcher/itineraries/containers':
      return 'Диспетчер - Рейсы - Контейнеры';
    case 'dispatcher/updaterent':
      return 'Обновление арендованных';
    case 'dispatcher/equipment':
      return 'Диспетчер - Комплектация';
    case 'dispatcher/loadregistry':
      return 'Диспетчер - Реестр погрузки';
    case 'dispatcher/bros/wagons':
      return 'Диспетчер - Брошенные - Вагоны';

    case 'list-of-rates':
      return 'Реестр ставок';
    case 'supplier-list-of-rates':
      return 'Реестр ставок поставщика';
    case 'transport-register':
      return 'Реестр перевозок';

    case 'documents':
      return 'Документы';
    case 'documents/billing':
      return 'Документы - Биллинг';
    case 'distribution':
      return 'Рассылки';
    case 'tariff':
      return 'Тариф';
    case 'tariff/ext-consumers':
      return 'Расширенный список распорядителей';
    case 'tariff/fgk':
      return 'Тариф - ФГК';
    case 'messenger':
      return 'Сообщения';
    case 'requests':
      return 'Справки';
    case 'finance-infolog':
      return 'Финансы Инфолог';
    case 'notifications':
      return 'Уведомления';
    case 'main':
      return 'Главная';
    case 'main/company':
      return 'Главная - Компания';
    case 'main/company/finance':
      return 'Главная - Компания - Финансы';
    case 'main/company/personnel':
      return 'Главная - Компания - Персонал';
    case 'main/company/clients':
      return 'Главная - Компания - Клиенты';
    case 'main/company/internal_processes':
      return 'Главная - Компания - внутренние процессы';
    case 'main/widgets':
      return 'Главная - Виджеты';
    case 'main/shippingSendsArrival':
      return 'Главная - Отправка/Прибытие';
    case 'main/immerse':
      return 'Главная - Погрузка';

    case 'main/dashboard':
      return 'Главная - Дашборд';
    case 'ils_claim':
      return 'Заявки на перевозки';
    case 'ils_claim/edit':
      return 'Создание, изменение, удаление заявок ИЛС';
    case 'ils_claim/from_draft_to_on_agr':
      return 'Отправка на согласование';
    case 'ils_claim/from_on_agr_to_draft':
      return 'Отзыв с согласования';
    case 'ils_claim/from_on_agr_to_agr':
      return 'Согласование';
    case 'ils_claim/on_agr_changes':
      return 'Изменение заявок в статусе "на согласовании"';
    case 'ils_claim/agr_changes':
      return 'Изменение согласованных заявок';
    case 'ils_claim/reject':
      return 'Отклонение заявок';
    default:
      return key;
  }
};
