import { Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import { BidRegistryCommercialStore } from '@stores/bidRegistryCommercialStore';

import { COLUMNS } from './lib/columns';
import { renderSummary } from './ui/summary';
import { renderTitle } from './ui/title';

export const TariffTable = observer(() => {
  const { tariffGroup } = BidRegistryCommercialStore;

  if (!tariffGroup) {
    return null;
  }

  return (
    <>
      {tariffGroup.map(({ freightEtsngName, freightEtsngCode, tariffList }) => {
        return (
          <AdaptiveCard hoverable key={freightEtsngCode} title={freightEtsngName}>
            {tariffList.map((tariff) => {
              const { countryDetails, loadStationCode, destStationCode } = tariff;
              return (
                <Table
                  key={`${freightEtsngCode}-${loadStationCode}-${destStationCode}`}
                  style={{ marginBottom: '1rem' }}
                  title={renderTitle(tariff)}
                  bordered
                  rowKey={Math.random}
                  pagination={false}
                  columns={COLUMNS}
                  dataSource={countryDetails}
                  summary={renderSummary}
                  scroll={{ x: true }}
                />
              );
            })}
          </AdaptiveCard>
        );
      })}
    </>
  );
});
