import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ContractElement } from '@stores/contractTemplateStore/ContractTemplatesData';

import DocumentTemplateRow from './DocumentTemplateRow';
import DocumentTemplateRowView from './DocumentTemplateRowView';

interface ComponentProps {
  data: ContractElement;
  onChange(data: ContractElement): void;
}

interface ComponentState {
  focus: string;
}

class DocumentTemplateEditor extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      focus: null,
    };
  }

  componentDidMount() {}

  onChange = () => {
    this.props.onChange(this.props.data);
    this.forceUpdate();
  };

  render() {
    const data = this.props.data;

    return (
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <DocumentTemplateRow
            level={0}
            data={data}
            onDelete={() => {}}
            onChange={this.onChange}
            onFocus={(e) => {
              this.setState({ focus: e });
            }}
          />
        </Col>
        <Col span={12} style={{ width: 630, border: '1px solid', padding: 5 }}>
          <DocumentTemplateRowView selected={this.state.focus} data={data} />
        </Col>
      </Row>
    );
  }
}

export default observer(DocumentTemplateEditor);
