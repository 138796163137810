import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import tariffFgkBaseStore from '@stores/TariffFgkBase/tariffFgkBaseStore';
import userStore from '@stores/userStore/userStore';

interface TariffFgkHistoryToolBarProps {
  onChose(id: string): void;
  onRemove(): void;
  onAutoExpand(): void;
}

interface TariffFgkHistoryToolBarState {}

class TariffFgkHistoryToolBar extends React.Component<TariffFgkHistoryToolBarProps, TariffFgkHistoryToolBarState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const isUser = userStore.userData.roleCode == 'user';
    return (
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify={'end'}>
        <Col>
          <Button
            size={'small'}
            disabled={tariffFgkBaseStore.selectedData.length != 1}
            onClick={() => this.props.onChose(tariffFgkBaseStore.selectedData[0])}>
            Выбрать
          </Button>
        </Col>
        <Col>
          <Button size={'small'} onClick={() => this.props.onAutoExpand()}>
            Свернуть/развернуть группы
          </Button>
        </Col>
        {/*<Button
          size={'small'}
          title={'Запись добавленная в архив будет удалена через 60 дней'}
          disabled={!historyTariffInfoStore.selected.length}
          onClick={this.toArchive.bind(this)}
          //style={{ position: 'absolute', right: '100' }}
        >
          Поместить в ахив
        </Button>
        {!isUser ? (
          <Button
            size={'small'}
            disabled={!historyTariffInfoStore.selected.length}
            onClick={this.deleteRecords.bind(this)}
            //style={{ position: 'absolute', right: '0' }}
          >
            Удалить выбранное
          </Button>
        ) : null}*/}
      </Row>
    );
  }

  async deleteRecords() {
    for (let key of tariffFgkBaseStore.selectedData) {
      //await historyTariffInfoStore.deleteHistoryTariffInfo(key);
    }
    tariffFgkBaseStore.setSelected([]);
    tariffFgkBaseStore.userRequestHistory.reload().then(() => {
      this.props.onRemove();
    });
  }

  async toArchive() {
    //await historyTariffInfoStore.addToArchive(historyTariffInfoStore.selected);
    tariffFgkBaseStore.setSelected([]);
    tariffFgkBaseStore.userRequestHistory.reload().then(() => {
      this.props.onRemove();
    });
  }
}

export default observer(TariffFgkHistoryToolBar);
