import Icon from '@ant-design/icons';
import { Button, DatePicker, Form, FormInstance, Input, Spin, Tabs } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, Grouping, HeaderFilter, Paging, Summary, TotalItem } from 'devextreme-react/data-grid';
import moment from 'moment';
import React, { Component } from 'react';

import { ReactComponent as iconCalendar } from '@assets/icons/icon-calendar.svg';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { AcceptanceOfServiceServiceModel } from '@stores/leasingStore/LeasingData';
import leasingStoreService from '@stores/leasingStore/leasingStore.service';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

import { showSuccessNotify } from '@utils/notify';

interface Props {
  id?: string;
  contractId: string;
  onChange(): void;
  onClose(): void;
}

interface State {
  isBusy: boolean;
  data?: AcceptanceOfServiceServiceModel;
}

class LeasingCompleteWorksDrawer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: false,
    };
  }

  form: FormInstance;

  componentDidMount() {
    if (this.props.id) {
      this.setState({ isBusy: true });
      leasingStoreService
        .getAcceptanceOfService(this.props.id)
        .then((ans) => {
          if (ans) {
            ans.acceptanceOfServiceDate = moment(ans.acceptanceOfServiceDate);
            ans.startDate = moment(ans.startDate);
            ans.endDate = moment(ans.endDate);
          }
          this.setState({ data: ans }, () => {
            if (this.form) {
              this.form.resetFields();
            }
          });
        })
        .finally(() => this.setState({ isBusy: false }));
    }
  }

  onFinish = (e: AcceptanceOfServiceServiceModel) => {
    this.setState({ isBusy: true });
    e.startDate = moment(e.startDate).toISOString(true);
    e.endDate = moment(e.endDate).toISOString(true);
    e.acceptanceOfServiceDate = moment(e.acceptanceOfServiceDate).toISOString(true);
    e.contractId = this.props.contractId;
    e.id = this.props.id;
    leasingStoreService
      .addAcceptanceOfService(e)
      .then(() => {
        showSuccessNotify('Документ сохранен');
        this.props.onChange();
      })
      .finally(() => this.setState({ isBusy: false }));
  };

  render() {
    const data = this.state.data;
    const wagons = data ? data.wagonList : [];
    return (
      <AdaptiveDrawer
        onClose={() => this.props.onClose()}
        visible={true}
        width={'90%'}
        title={'Акт выполненных работ'}
        footer={[
          <Button key={'save'} onClick={() => this.form.submit()} type={'primary'}>
            Сохранить
          </Button>,
          <Button key={'close'} onClick={() => this.props.onClose()}>
            Отмена
          </Button>,
        ]}>
        <Spin spinning={this.state.isBusy}>
          <Tabs>
            <Tabs.TabPane tab={'Основное'} key={'main'}>
              <Form size={'large'} layout={'vertical'} ref={(ref) => (this.form = ref)} initialValues={data} onFinish={this.onFinish}>
                <Form.Item name={'acceptanceOfServiceDate'} label={'Дата акта'} rules={[{ required: true }]}>
                  <DatePicker format={GLOBAL_DATE_FORMAT} allowClear={false} suffixIcon={<Icon component={iconCalendar} />} />
                </Form.Item>
                <Form.Item name={'startDate'} label={'Начало'} rules={[{ required: true }]}>
                  <DatePicker format={GLOBAL_DATE_FORMAT} allowClear={false} suffixIcon={<Icon component={iconCalendar} />} />
                </Form.Item>
                <Form.Item name={'endDate'} label={'Конец'} rules={[{ required: true }]}>
                  <DatePicker format={GLOBAL_DATE_FORMAT} allowClear={false} suffixIcon={<Icon component={iconCalendar} />} />
                </Form.Item>
                <Form.Item name={'number'} label={'Номер акта'} rules={[{ required: true }]}>
                  <Input maxLength={255} />
                </Form.Item>
              </Form>
            </Tabs.TabPane>
            {wagons.length ? (
              <Tabs.TabPane tab={'По вагонам'} key={'wagons'}>
                <DataGrid
                  allowColumnReordering={true}
                  dataSource={wagons}
                  remoteOperations={false}
                  //loadPanel={{ enabled: false }}
                  allowColumnResizing={true}
                  showBorders={true}
                  //height={'calc(100vh - var(--padding))'}
                  columnResizingMode={'widget'}
                  columnAutoWidth={true}
                  showColumnLines={true}
                  columnMinWidth={20}
                  showRowLines={true}
                  /*onSaved={(e) => {
              e.changes.forEach((c) => {
                const find = data.find((d) => d.wagonNumber == c.data.wagonNumber);
                if (find) {
                  Object.keys(c.data).forEach((d) => {
                    find[d] = c.data[d];
                  });
                } else {
                  data.push(c.data);
                }
                this.setState({ wagonData: data });
              });
            }}*/
                  rowAlternationEnabled={true}>
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  <GroupPanel visible={true} />
                  <Grouping autoExpandAll={false} />
                  <Paging enabled={false} />
                  {/*<Editing mode="cell" allowUpdating={true} allowDeleting={false} allowAdding={false} />*/}
                  {INDEX_COLUMN}
                  <Column allowFixing={true} allowEditing={false} dataField={'wagonNumber'} caption={'Номер вагона'} dataType={'string'} />
                  <Column allowFixing={true} caption={'Кол-во суток ремонта'} dataField={'inRepairDaysCount'} />

                  <Column allowFixing={true} caption={'Расчетный период'}>
                    <Column allowFixing={true} dataField={'startDate'} caption={'Дата начала'} dataType={'date'} />
                    <Column allowFixing={true} dataField={'endDate'} caption={'Дата окончания'} dataType={'date'} />
                  </Column>
                  <Column allowFixing={true} dataField={'toPayDaysCount'} caption={'Кол-во суток к оплате'} dataType={'number'} />
                  <Column
                    allowFixing={true}
                    dataField={'unitCost'}
                    caption={'Стоимость аренды с НДС (за 1 ваг./сут.)'}
                    dataType={'number'}
                  />
                  <Column allowFixing={true} dataField={'totalCost'} caption={'Стоимость аренды с НДС'} dataType={'number'} />
                  <Summary>
                    <TotalItem name="wagonNumber" summaryType="count" showInColumn="wagonNumber" />
                    <TotalItem name="inRepairDaysCount" summaryType="sum" column="inRepairDaysCount" showInColumn="inRepairDaysCount" />
                    <TotalItem name="toPayDaysCount" summaryType="sum" showInColumn="toPayDaysCount" column="toPayDaysCount" />
                    <TotalItem name="totalCost" summaryType="sum" showInColumn="totalCost" column="totalCost" />
                  </Summary>
                </DataGrid>
              </Tabs.TabPane>
            ) : null}
          </Tabs>
        </Spin>
      </AdaptiveDrawer>
    );
  }
}

export default LeasingCompleteWorksDrawer;
