import { UserOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React, { Component } from 'react';

import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import { MarkModel } from '@stores/markStore';

import { filterOptionFn } from '@utils/sort';

interface MarkSelectProps {
  disabled?: boolean;
  onChange?: (value?: string[] | null) => void; // Возвращает JSON строку содержащую массив ID меток
  value?: string[]; // Принимает JSON строку содержащую массив ID меток
  placeholder?: React.ReactNode;
  options: MarkModel[];
}

class MarkSelect extends Component<MarkSelectProps> {
  constructor(props) {
    super(props);
  }

  customTagRender = (props) => {
    const tag = this.props.options.find((i) => i.id === props.value);

    return (
      <TagItem
        closable={props.closable}
        label={props.label}
        value={props.value}
        onClose={props.onClose}
        color={tag?.description}
        isBelongsCreator={tag?.isBelongsCreator}
      />
    );
  };

  onChange = (values?: string[]) => {
    if (this.props.onChange) {
      if (!values || values.length === 0) {
        this.props.onChange(null);
        return;
      }

      this.props.onChange(values);
    }
  };

  render() {
    const options = this.props.options.map((i) => ({ label: i.title, value: i.id }));
    options.sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? -1 : 1));
    return (
      <FloatSelect
        disabled={this.props.disabled}
        mode="multiple"
        options={options}
        filterOption={filterOptionFn}
        value={this.props.value ?? undefined}
        maxTagCount="responsive"
        placeholder={this.props.placeholder}
        tagRender={this.customTagRender}
        onChange={this.onChange}
      />
    );
  }
}
export default MarkSelect;

interface TagItemProps {
  label: string;
  value: string;
  color: string;
  isBelongsCreator: boolean;
  closable: boolean;
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
class TagItem extends Component<TagItemProps> {
  constructor(props) {
    super(props);
  }

  onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  render() {
    const { label, color, isBelongsCreator, closable, onClose } = this.props;

    return (
      <div style={{ paddingTop: 10 }}>
        <Tag
          color={color}
          onMouseDown={this.onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          icon={isBelongsCreator ? <UserOutlined /> : null}>
          {label}
        </Tag>
      </div>
    );
  }
}
