import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Col, DatePicker, Divider, List, Row, Select, Spin, Tag } from 'antd';
import Search from 'antd/es/input/Search';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import designStore from '@stores/designStore/designStore';
import dictStore from '@stores/dictStore/dictStore';
import { GLOBAL_DATETIME_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { FileStorageFilter } from '@stores/sourceLogsStore/sourceLogsData';
import sourceLogsStore from '@stores/sourceLogsStore/sourceLogsStore';

import styles from './SourceLogs.module.scss';
import SourceLogsContentViewer from './SourceLogsContentViewer';

interface ComponentState {
  fileId: string | null;
  filter: FileStorageFilter;
  timer: any;
}

interface ComponentProps {
  type: 'input' | 'output';
}
const { RangePicker } = DatePicker;

@observer
class SourceLogs extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      fileId: null,
      filter: { searchString: null, dateFrom: null, dateTo: null, sourceId: null },
      timer: null,
    };
  }

  componentDidMount(): void {
    dictStore.loadSourcesDict();
    this.getData();
  }
  onSearch = (s) => {
    const filter = this.state.filter;
    filter.searchString = s;
    this.setState({ filter });
    this.getData();
  };

  getData() {
    sourceLogsStore.getData(this.props.type, this.state.filter).then(() => {
      this.setState({
        timer: setTimeout(() => {
          this.getData();
        }, 5000),
      });
    });
  }
  componentWillUnmount() {
    clearTimeout(this.state.timer);
  }

  onRangeChange = (dates) => {
    const filter = this.state.filter;
    filter.dateFrom = dates ? moment(dates[0]).startOf('day').add(3, 'hour') : null;
    filter.dateTo = dates ? moment(dates[1]).endOf('day').add(3, 'hour') : null;
    this.setState({ filter });
    this.getData();
  };

  onSourceChange = (id) => {
    const filter = this.state.filter;
    filter.sourceId = id;
    this.setState({ filter });
    this.getData();
  };

  render() {
    const isBusy = sourceLogsStore.isBusy;
    const data = sourceLogsStore.tableData;
    const sourceDict = dictStore.companySourcesDictionary ? dictStore.companySourcesDictionary : [];
    const selectedSrc = this.state.filter.sourceId;
    const isMobile = designStore.width <= 992;

    return (
      <div>
        <Spin spinning={isBusy}>
          <AdaptiveCard
            title={
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <RangePicker format={'DD.MM.YYYY'} onChange={this.onRangeChange} allowClear={true} style={{ width: '100%' }} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Search size={'middle'} placeholder="поиск" onSearch={(value) => this.onSearch(value)} style={{ width: '100%' }} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <span style={{ paddingRight: '0.5rem' }}>Источники:</span>
                  <Select
                    onSelect={(e) => this.onSourceChange(e)}
                    placeholder="Источники"
                    defaultValue={null}
                    style={{ width: 'calc(100% - 6.075rem)' }}>
                    <Select.Option
                      className={'sources'}
                      value={null}
                      key={`sourceDict_all_src`}
                      checked={!selectedSrc}
                      selected={!selectedSrc}>
                      Все
                    </Select.Option>
                    {sourceDict.map((s, index) => {
                      return (
                        <Select.Option
                          className={'sources'}
                          value={s.id}
                          key={`sourceDict_${index}`}
                          checked={selectedSrc && selectedSrc == s.id}
                          selected={selectedSrc && selectedSrc == s.id}>
                          {s.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
            }>
            <ScrolledContainer className={'scrolledContainer'} style={{ height: `calc(100vh - ${isMobile ? '12rem' : '12.2rem'})` }}>
              {data.sourceList.map((sourceName) => {
                return (
                  <div key={sourceName}>
                    <Divider orientation={'left'}> {sourceName}</Divider>
                    <table>
                      {data.groupListBySource(sourceName).map((group) => {
                        const fields = isBusy ? [] : data.data[sourceName][group];
                        return (
                          <tr>
                            <th key={'header_' + group} style={{ minWidth: '200px', textAlign: 'right', paddingRight: '10px' }}>
                              {group}
                            </th>
                            <td key={'data_' + group} valign={'top'}>
                              <List
                                className={styles['d-flex']}
                                dataSource={fields}
                                renderItem={(report) => (
                                  <List.Item
                                    key={report.fileId}
                                    style={{
                                      cursor: 'pointer',
                                      height: '30px',
                                      padding: 0,
                                      minWidth: '11rem',
                                      marginRight: '1rem',
                                    }}
                                    onClick={() => this.setState({ fileId: report.fileId })}>
                                    <List.Item.Meta
                                      avatar={
                                        <div style={{ textAlign: 'center' }}>
                                          {report.status === 'done' && (
                                            <Tag style={{ width: '28px' }} icon={<CheckCircleOutlined />} color="success" />
                                          )}
                                          {report.status === 'processed' && (
                                            <Tag style={{ width: '28px' }} icon={<SyncOutlined spin />} color="processing" />
                                          )}
                                          {report.status === 'error' && (
                                            <Tag style={{ width: '28px' }} icon={<CloseCircleOutlined />} color="error" />
                                          )}
                                        </div>
                                      }
                                      description={moment(report.dateTime).format(GLOBAL_DATETIME_FORMAT)}
                                    />
                                  </List.Item>
                                )}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                );
              })}
            </ScrolledContainer>
          </AdaptiveCard>
        </Spin>
        {this.state.fileId && <SourceLogsContentViewer fileId={this.state.fileId} onClose={this.handleClose} />}
      </div>
    );
  }
  handleClose = () => {
    this.setState({ fileId: null });
  };
}

export default SourceLogs;
