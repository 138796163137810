import { Button } from 'antd';
import React from 'react';

import { BidRegistryDocumentStore } from '@stores/bidRegistryDocumentStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import { CreateProtocolProps } from './create-protocol.types';

export const CreateProtocol = ({ isLoading }: CreateProtocolProps) => {
  return (
    <Button
      block
      shape="round"
      type="primary"
      disabled={isLoading}
      onClick={() => BidRegistryDocumentStore.createProtocol(registerOfBetsStore.bidRegistryId)}>
      Сформировать протокол
    </Button>
  );
};
