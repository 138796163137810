import { CalendarOutlined, ClockCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { GLOBAL_DATE_FORMAT, SHORT_TIME_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface State {}
interface Props {
  date: any;
  link: string;
  name: string;
  body?: any;
  actions?: any;
  history?: any;
  id?: string;
}

class DispatcherItemCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { date, body, link, name, actions, id } = this.props;
    return (
      <Card
        data-test={`dispatcher-item-${id}`}
        onClick={(e) => {
          if (this.props.history) {
            this.props.history.push(link);
          }
        }}
        className={'dispatcher-card'}
        hoverable={true}
        title={
          <Link to={link}>
            <span className={'chapter'}>{name}</span>
          </Link>
        }
        extra={
          <Link to={link}>
            <RightOutlined style={{ color: 'black' }} />
          </Link>
        }
        headStyle={{ borderBottom: 'none', padding: '0 1rem' }}
        bodyStyle={{ padding: '0 1rem 1rem 1rem' }}>
        <div className={'dispatcher-filtertag-wrapper'}>{body}</div>
        <Divider />
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className={'dispathcher-date'} span={16} style={{ fontSize: '12px' }}>
            <CalendarOutlined className={'dispatcher-icon-gray'} style={{ paddingRight: '0.2rem' }} />
            <span style={{ paddingRight: '1rem' }}>{moment(date).format(GLOBAL_DATE_FORMAT)}</span>
            <ClockCircleOutlined className={'dispatcher-icon-gray'} style={{ paddingRight: '0.2rem' }} />
            <span style={{ paddingRight: '0.2rem' }}>{moment(date).format(SHORT_TIME_FORMAT)}</span>
          </Col>
          <Col span={8} style={{ textAlign: 'end' }}>
            {actions}
          </Col>
        </Row>
      </Card>
    );
  }
}

export default DispatcherItemCard;
