import { CompanyCatalogModel } from '../../companyCatalogStore';
import { TrackingActiveBase, TrackingHistoryBase } from '../trackingStoreBase';

export class Container {
  public isvalid: boolean;
  public isontrack: boolean;
  public loading: boolean;

  constructor(public number: string) {
    this.isvalid = this.checkNumber(this.number);
  }

  private checkNumber(cnumber: string): boolean {
    //Проверка Валидных по regexp маске
    let regexp = /[A-Z]{4}[0-9]{6}/g;
    let regexpNum = /[0-9]{9}/g;

    if (cnumber.match(regexpNum) && cnumber.length == 9) {
      return this.checkControlValue(8, cnumber);
    } else if (cnumber.match(regexp) && cnumber.length == 11) {
      return this.checkControlValue(10, cnumber);
    } else {
      return false;
    }
  }

  private checkControlValue(length: number, cnumber: string) {
    // Проверка контрольного числа
    let addition = 0;
    let num = 0;
    while (num < length) {
      let digit = this.getDigitalEquivalent(cnumber[num]) * Math.pow(2, num);
      addition += digit;
      num++;
    }

    let myControlValue = addition % 11 == 10 ? 0 : addition % 11;
    let controlValue = +cnumber[length];
    return myControlValue == controlValue;
  }

  private getDigitalEquivalent(char: string): number {
    //let test = char.charCodeAt(0) - 55; неверно, потому что в ржд символ не заложен на кодовую страницу
    switch (char.toLowerCase()) {
      case 'a':
        return 10;
      case 'b':
        return 12;
      case 'c':
        return 13;
      case 'd':
        return 14;
      case 'e':
        return 15;
      case 'f':
        return 16;
      case 'g':
        return 17;
      case 'h':
        return 18;
      case 'i':
        return 19;
      case 'j':
        return 20;
      case 'k':
        return 21;
      case 'l':
        return 23;
      case 'm':
        return 24;
      case 'n':
        return 25;
      case 'o':
        return 26;
      case 'p':
        return 27;
      case 'q':
        return 28;
      case 'r':
        return 29;
      case 's':
        return 30;
      case 't':
        return 31;
      case 'u':
        return 32;
      case 'v':
        return 34;
      case 'w':
        return 35;
      case 'x':
        return 36;
      case 'y':
        return 37;
      case 'z':
        return 38;
      case '1':
        return 1;
      case '2':
        return 2;
      case '3':
        return 3;
      case '4':
        return 4;
      case '5':
        return 5;
      case '6':
        return 6;
      case '7':
        return 7;
      case '8':
        return 8;
      case '9':
        return 9;
      case '0':
        return 0;
      default:
        return 0;
    }
  }
}

export interface ActiveContainer extends TrackingActiveBase {
  /// Вагон
  wagonNumber: number;
}

export interface HistoryContainer extends TrackingHistoryBase {
  /// Вагон
  wagonNumber: number;
}

export interface ContainerAddRequest {
  numbers: string[];
  catalogs: CompanyCatalogModel[];
  countryId: number;
  trackingDayCount?: number;
  remark1?: string;
  remark2?: string;
  remark3?: string;
  remark4?: string;
  remark5?: string;
}

export interface ContainerRemoveRequest {
  numbers: string[];
}
