import HttpClient from '@utils/httpClient';

abstract class AbstractTrackingStoreService<A, H, R1, R2> {
  abstract path: string;
  async getActiveList(): Promise<A[]> {
    return (await HttpClient.get(`/api/track/${this.path}/list`)).data;
  }
  async getHistoryList(): Promise<H[]> {
    return (await HttpClient.get(`/api/track/${this.path}/history/list`)).data;
  }
  async add(data: R1): Promise<void> {
    return (await HttpClient.post(`/api/track/${this.path}/add`, data)).data;
  }
  async remove(data: R2): Promise<void> {
    return (await HttpClient.post(`/api/track/${this.path}/remove`, data)).data;
  }

  async checkOnTrack(number: string | number): Promise<boolean> {
    return (await HttpClient.get(`/api/track/${this.path}/checkOnTrack/${number}`)).data;
  }
}
export default AbstractTrackingStoreService;
