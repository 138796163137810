import { LoadOptions } from 'devextreme/data/load_options';
import { action, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment/moment';

import { CompanyLoadRegistryModel, LoadRegistryStatisticDto } from './loadRegistryData';
import loadRegistryStoreService from './loadRegistryStore.service';

class LoadRegistryStore {
  @observable selectedRowData: any[] = [];
  @observable statistic: LoadRegistryStatisticDto;
  @observable edit: CompanyLoadRegistryModel[] | null = null;
  @observable editType: 'supplierOnly' | 'all' = 'all';
  @observable dates: [Moment, Moment] = [moment().startOf('month'), moment().endOf('month')];
  @observable isBusy: boolean = false;
  @observable picker: 'month' | 'period' = 'month';

  constructor() {
    makeObservable(this);
  }

  @action setSelected(data: CompanyLoadRegistryModel[]) {
    this.selectedRowData = data;
  }

  @action setDates(dates: [Moment, Moment], picker: 'month' | 'period') {
    this.dates = dates;
    this.picker = picker;
    this.refreshData();
  }

  @action async updateListCompanyHandler(editType: 'supplierOnly' | 'all') {
    const selectedRowData = this.selectedRowData;
    if (!selectedRowData.length) return;
    this.editType = editType;
    this.edit = await loadRegistryStoreService.getList(selectedRowData.map((l) => l.loadregistryid));
  }

  @action async deleteListCompanyHandler() {
    const selectedRowData = this.selectedRowData;
    if (!selectedRowData.length) return;
    await loadRegistryStoreService.deleteListCompanyLoadRegistry(selectedRowData.map((i) => i.loadregistryid));
    this.refreshData();
  }

  @action async getReport(loadOptions: LoadOptions) {
    return await loadRegistryStoreService.getReport(
      loadOptions,
      this.dates[0].startOf('day').toISOString(),
      this.dates[1].endOf('day').toISOString(),
    );
  }

  @action async getLoadRegister(start: Moment, end: Moment) {
    this.isBusy = true;
    return await loadRegistryStoreService
      .getStatistic(start.startOf('day').toISOString(), end.endOf('day').toISOString())
      .then((ans) => {
        this.statistic = ans;
        return ans;
      })
      .finally(() => (this.isBusy = false));
  }

  @action refreshData() {
    this.getLoadRegister(this.dates[0], this.dates[1]).then();
  }
}

export default new LoadRegistryStore();
