import { PlusOutlined } from '@ant-design/icons';
import { Button, List, Skeleton } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import adminBillingStore from '@stores/adminBilling/adminBillingStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

import ReferenceActionMenu from './ReferenceActionMenu';
import ReferenceEditor from './ReferenceEditor';

interface State {
  showEditor: boolean;
}
interface Props {
  companyId: string;
}

@observer
class References extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showEditor: false,
    };
  }

  componentDidMount() {
    adminBillingStore.loadServices();
    adminBillingStore.loadSuppliers();
    adminBillingStore.loadServiceSets(this.props.companyId);
  }

  render() {
    const isBusy = adminBillingStore.isBusySet;
    return (
      <div>
        <ReferenceEditor
          data={{
            id: null,
            companyId: this.props.companyId,
            services: [],
            supplierId: null,
            supplierName: null,
            offerNumber: null,
            offerDate: null,
            serviceSetContract: null,
          }}
          onClose={() => this.setState({ showEditor: false })}
          visible={this.state.showEditor}
        />
        <Button onClick={() => this.setState({ showEditor: true })} icon={<PlusOutlined />} title={'Создать'} type={'text'} size={'small'}>
          создать
        </Button>
        <List
          loading={isBusy}
          itemLayout="horizontal"
          dataSource={adminBillingStore.references}
          renderItem={(item, index) => (
            <List.Item actions={[<ReferenceActionMenu data={item} />]}>
              <Skeleton title={false} active loading={false}>
                <List.Item.Meta
                  title={index + 1 + '. ' + 'Договор №' + item.offerNumber + ' от ' + moment(item.offerDate).format(GLOBAL_DATE_FORMAT)}
                  description={'Поставщик ' + item.supplierName}
                />
                Услуг: {item.services.length}
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    );
  }

  handleDelete = (item) => {
    adminBillingStore.deleteServiceSet(item);
  };
}

export default References;
