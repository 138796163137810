import { Form, Spin, notification } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { SortedDirections, supplierBidRegistryStore } from '@stores/supplierBidRegistryStore';

import { DirectionTable } from '../calculation-tab-direction-table';
import { CalculationTabProps } from './calculation-tab.types';

export const SupplierBidRegistryCreateCalculationTab = observer(({ form }: CalculationTabProps) => {
  const { isLoading, sortedDirections } = supplierBidRegistryStore;

  const onFinish = async (values: SortedDirections) => {
    await supplierBidRegistryStore.updateSortedDirections(values);
    return notification.success({ message: 'Ставка успешно обновлена' });
  };

  return (
    <Spin spinning={isLoading}>
      <Form form={form} initialValues={sortedDirections} onFinish={onFinish}>
        <DirectionTable />
      </Form>
    </Spin>
  );
});
