import HttpClient from '@utils/httpClient';

import { TariffHistoryModel } from './historyTariffInfoStore';

const service = {
  getHistoryTariffInfo(userTariffRequestId: string): Promise<TariffHistoryModel> {
    return HttpClient.get(`/api/Tariff/historyinfo?requestId=${userTariffRequestId}`).then((resp) => {
      return resp.data;
    });
  },

  getUserRequestHistory(loadOptions) {
    return HttpClient.post(`/api/Tariff/requesthistory`, loadOptions).then((resp) => {
      return resp.data;
    });
  },

  deleteHistoryTariffInfo(userTariffRequestId: string) {
    return HttpClient.get(`/api/Tariff/deletehistory/?tariffRequestId=${userTariffRequestId}`).then((resp) => {
      return resp.data;
    });
  },

  addToArchive(requestIds: string[]) {
    return HttpClient.post(`/api/Tariff/add-to-archive`, requestIds).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
