import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Popconfirm, Row, Spin, Tag, Tooltip } from 'antd';
import Meta from 'antd/es/card/Meta';
import moment from 'moment';
import React, { Component } from 'react';

import SendScheduleEditor from '@components/fw/Drawers/SendScheduleEditor';
import Etsng from '@components/fw/GetFullInfoByCode/Etsng';
import Station from '@components/fw/GetFullInfoByCode/Station';
import WagonType from '@components/fw/GetFullInfoByCode/WagonType';
import SendEditorDrawer from '@components/routed/ClaimInfo/execution/SendEditorDrawer';
import WagonsAutoSelection from '@components/routed/SendInfo/WagonsAutoSelection';

import designStore from '@stores/designStore/designStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { SendModel, SendSupplyScheduleModel, emptySendSupplyScheduleModel } from '@stores/sendsStore/sendData';
import sendsStore from '@stores/sendsStore/sendsStore';
import sendsService from '@stores/sendsStore/sendsStore.service';

interface SendBoardDetailProps {
  send: SendModel;
  onChange?(): void;
}

interface SendBoardDetailState {
  schedules: SendSupplyScheduleModel[];
  isBusy: boolean;
  edited: SendSupplyScheduleModel;
  editThis: boolean;
}

class SendBoardDetail extends Component<SendBoardDetailProps, SendBoardDetailState> {
  state = {
    schedules: [],
    isBusy: true,
    edited: null,
    editThis: false,
  };

  componentDidMount(): void {
    this.loadSchedules();
  }

  loadSchedules = () => {
    this.setState({ isBusy: true });
    sendsService.getSendSchedules(this.props.send.sendId).then((result) => {
      this.setState({ schedules: result, isBusy: false });
    });
  };

  render() {
    const isBusy = this.state.isBusy;
    const send = this.props.send;
    const schedules = this.state.schedules;
    const isMobile = designStore.isMobile;
    const collapsed = designStore.sidebarCollapsed;

    return (
      <Spin spinning={isBusy}>
        {this.state.editThis && (
          <SendEditorDrawer
            onChange={this.props.onChange}
            claimId={null}
            sendId={this.props.send.sendId}
            onClose={() => this.setState({ editThis: false })}
            history={null}
            location={null}
            match={null}
          />
        )}
        <div
          className={collapsed ? 'send-params-collapsed' : 'send-params'}
          style={{ padding: 'var(--gap)', width: `${isMobile ? '95Vw' : null}` }}>
          <Card
            className={'send-params-card'}
            title={
              <>
                <div
                  className="dx-group-cell"
                  style={{ display: 'grid', gridTemplateColumns: `${isMobile ? 'repeat(1, max-content)' : 'repeat(4, max-content)'}` }}>
                  <div className="chapter">Параметры отправки</div>
                  <div>{send && send.sendName}</div>
                  <div>{send && send.statusName ? <Tag color="blue">{send.statusName}</Tag> : null}</div>
                  <div>{send && send.executionDate ? moment(send.executionDate).format(GLOBAL_DATE_FORMAT) : null}</div>
                </div>
              </>
            }
            extra={
              this.props.onChange ? (
                <div className={'flex-wrapper'}>
                  <Tooltip title={'Редактировать отправку'} key={'send_edit'}>
                    <Button type={'link'} size={'small'} onClick={() => this.handleEdit()} icon={<EditOutlined />} />
                  </Tooltip>
                  <Tooltip title={'Удалить отправку'} key={'send_delete'}>
                    <Popconfirm title="Удалить отправку？" okText="Да" cancelText="Нет" onConfirm={() => this.handleRemoveConfirm()}>
                      <Button type={'link'} size={'small'} icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
              ) : null
            }
            style={{ width: '100%' }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <div
                  className={'send-params-title'}
                  style={{ gridTemplateColumns: `${isMobile ? 'repeat(1, 100%)' : 'repeat(3, max-content)'}` }}>
                  <div style={{ gridTemplateColumns: `${isMobile ? 'repeat(1, 100%)' : 'repeat(2, max-content)'}` }}>
                    <div>Станция отправления</div>
                    <div>
                      <Station code={send.loadStationCode} />
                    </div>
                    <div>Станция назначения</div>
                    <div>
                      <Station code={send.destStationCode} />
                    </div>
                  </div>
                  <div style={{ gridTemplateColumns: `${isMobile ? 'repeat(1, 100%)' : 'repeat(2, max-content)'}` }}>
                    <div>Наименования груза</div>
                    <div>
                      <Etsng code={send.freightEtsngCode} />
                    </div>
                    <div>Тип вагона</div>
                    <div>{!!send.wagonTypeCode && <WagonType code={send.wagonTypeCode} />}</div>
                  </div>
                  <div style={{ gridTemplateColumns: `${isMobile ? 'repeat(1, 100%)' : 'repeat(2, max-content)'}` }}>
                    <div>Вес груза</div>
                    <div>{send.freightWeight} т</div>
                    <div>Кол-во вагонов</div>
                    <div>{send.wagonCount}</div>
                  </div>
                </div>
              </Col>
              <Col xs={24}>
                <Button type={'link'} size={'small'} onClick={() => this.setState({ edited: emptySendSupplyScheduleModel })}>
                  Добавить подачу
                </Button>
                <WagonsAutoSelection
                  onChange={() => this.loadSchedules()}
                  send={this.props.send}
                  loadStationCode={this.props.send.loadStationCode}
                />
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {schedules.map((schedule: SendSupplyScheduleModel, index) => {
                return (
                  <Col xs={24} sm={24} md={8} lg={8} key={'schedule_' + index}>
                    <div style={{ paddingTop: 'var(--gap)', paddingBottom: 'var(--gap)' }}>
                      <Card
                        className={'schedule-card dispatcher-card'}
                        hoverable={true}
                        title={'Дата подачи: ' + moment(schedule.supplyDate).format(GLOBAL_DATE_FORMAT)}
                        key={'send_' + index}
                        headStyle={{ borderBottom: 'none', padding: '0 1rem' }}
                        bodyStyle={{ padding: '0 1rem 1rem 1rem' }}
                        style={{ outline: 'var(--gap) solid transparent' }}>
                        <Meta
                          description={
                            <>
                              {schedule.wagonNumbers.map((w, index) => (
                                <p key={'schedule_' + index}>
                                  <a target={isMobile ? null : '_blank'} href={'/app#/info?w%5B0%5D=' + w + '&type=wagon'}>
                                    {w}
                                  </a>
                                </p>
                              ))}
                            </>
                          }
                        />
                        <Divider />
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                          <Col className={'dispathcher-date'} span={16} style={{ fontSize: '12px' }}>
                            <span style={{ paddingRight: '1rem' }}>
                              вес груза: {schedule.freightWeight ? schedule.freightWeight + ' т' : '-'}
                            </span>
                            <span style={{ paddingRight: '0.2rem' }}>кол-во вагонов: {schedule.wagonCount}</span>
                          </Col>
                          <Col span={8} style={{ textAlign: 'end' }}>
                            <Tooltip title={'Редактировать подачу'} key={'schedule_edit'}>
                              <Button
                                type={'link'}
                                size={'small'}
                                icon={<EditOutlined />}
                                onClick={() => this.handleEditSchedule(schedule)}
                              />
                            </Tooltip>
                            <Tooltip title={'Удалить подачу'} key={'schedule_delete'}>
                              <Popconfirm
                                title="Удалить？"
                                okText="Да"
                                cancelText="Нет"
                                onConfirm={() => this.handleRemoveConfirmSchedule(schedule.scheduleId)}>
                                <Button type={'link'} size={'small'} icon={<DeleteOutlined />} />
                              </Popconfirm>
                            </Tooltip>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Card>
        </div>
        {this.state.edited ? (
          <SendScheduleEditor
            sendId={this.props.send.sendId}
            sendSchedule={this.state.edited}
            onCancel={() => this.setState({ edited: null })}
            onConfirm={this.handleEditorConfirm}
          />
        ) : null}
      </Spin>
    );
  }

  handleEditorConfirm = () => {
    this.setState({ edited: null });
    this.loadSchedules();
  };

  handleEditSchedule = (schedule: SendSupplyScheduleModel) => {
    this.setState({ edited: schedule });
  };

  handleRemoveConfirmSchedule = (scheduleId: string) => {
    this.setState({ isBusy: true });
    sendsStore
      .deleteSchedule(scheduleId)
      .then(() => {
        this.loadSchedules();
      })
      .finally(() => this.setState({ isBusy: false }));
  };

  private handleEdit() {
    this.setState({ editThis: true });
  }

  private handleRemoveConfirm() {
    sendsStore
      .deleteSend(this.props.send.sendId)
      .finally(() => this.setState({ isBusy: false }))
      .then(() => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
  }
}

export default SendBoardDetail;
