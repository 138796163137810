import { PrinterOutlined } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';
import { RecursiveSvp, RequestModel } from '@stores/requestsStore/RequestsData';
import requestsStore from '@stores/requestsStore/requestsStore';
import requestsService from '@stores/requestsStore/requestsStore.service';

import RecursiveSvpViewer from './viewers/RecursiveSvpViewer';

interface Props {
  requestId: string;
  onClose(): void;
}

interface State {
  isBusy: boolean;
  data: RequestModel;
}

class RequestDetail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: true,
      data: null,
    };
  }

  componentDidMount(): void {
    requestsService
      .getInfo(this.props.requestId)
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => this.setState({ isBusy: false }));
  }

  onClose = () => {
    requestsStore.onWatch(this.props.requestId);
    this.props.onClose();
  };

  renderText = () => {
    const data = this.state.data;
    return (
      <pre
        className={'pre1'}
        style={{
          lineHeight: '100%',
          whiteSpace: 'pre',
          fontFamily: 'monospace',
          margin: '5px',
          padding: '5px',
          backgroundColor: 'white',
          border: 'none',
        }}>
        {data.responseContent}
      </pre>
    );
  };

  getContent = (): RecursiveSvp | string => {
    const data = this.state.data;
    try {
      return JSON.parse(data.responseContent);
    } catch {
      return data.requestContent ?? '';
    }
  };

  renderBody = () => {
    const content = this.getContent();
    if (typeof content === 'string') {
      return this.renderText();
    }
    return <RecursiveSvpViewer content={content} />;
  };

  render() {
    const isBusy = this.state.isBusy;
    const data = this.state.data;
    return (
      <Modal
        width={designStore.isMobile ? '100%' : '70%'}
        title={<>{data ? <Button onClick={this.printMe} icon={<PrinterOutlined />} /> : null}</>}
        open={true}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={this.onClose}>
        <Spin spinning={isBusy}>
          <div id="request-content">{data ? this.renderBody() : null}</div>
        </Spin>
      </Modal>
    );
  }

  printMe = () => {
    const data = document.getElementById('request-content').innerHTML;
    const popupWinindow = window.open(
      '',
      '_blank',
      'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no',
    );
    popupWinindow.document.open();
    popupWinindow.document.write(
      '<html><head> <style type="text/css">.pre1 {line-height: 100%; white-space: pre;font-family: monospace; margin: 5px; padding: 5px;background-color: white; border: none;}</style>    </head><body onload="window.print()">' +
        data +
        '</html>',
    );
    popupWinindow.document.close();
  };
}

export default observer(RequestDetail);
