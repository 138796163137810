export interface FileInputModel {
  fileId: string;
  name: string;
  sourceId: string;
  sourceName: string;

  keywords: string;
  status: 'processed' | 'done' | 'error';
  formatType: 'json' | 'xml';

  content: string | null;
  dateTime: Date;
}

export interface FileStorageFilter {
  searchString: string;
  dateFrom: Date | string | any | null;
  dateTo: Date | string | any | null;
  sourceId: string | null;
}

export class SourceLogsTableData {
  data: { [key: string]: { [key: string]: FileInputModel[] } } = {};

  constructor(items: FileInputModel[]) {
    items.forEach((item) => {
      let source = this.data[item.sourceName] ? this.data[item.sourceName] : {};
      let group = source[item.name] ? source[item.name] : [];
      group.push(item);
      source[item.name] = group;
      this.data[item.sourceName] = source;
    });
    this.data = Object.fromEntries(Object.entries(this.data).sort((a, b) => Object.keys(b[1]).length - Object.keys(a[1]).length));
  }

  get sourceList() {
    return Object.keys(this.data);
  }

  groupListBySource(srcName: string) {
    let source = this.data[srcName];
    if (!source) return [];
    return Object.keys(source);
  }
}
