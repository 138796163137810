import { Button, DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import { EtranInvoiceSpcModel } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';
import { ETRAN_DATETIME_FORMAT, GLOBAL_DATETIME_FORMAT, GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

import { filterOptionFn } from '@utils/sort';

interface Props {
  data?: EtranInvoiceSpcModel;
  onChange(data: EtranInvoiceSpcModel): void;
}

interface State {
  visible: boolean;
  data: EtranInvoiceSpcModel;
  search: string;
}

@observer
class EtranInvoiceCarSpcMarksModal extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      data: null,
      search: null,
    };
  }

  handleFormReset = () => {
    const data = this.props.data;
    if (data && [1293, 1120].includes(data.spcTranspClauseId)) {
      data.spcDate = moment(data.spcCustomText);
    } else if (data && data.spcDate) {
      data.spcDate = moment(data.spcDate, ETRAN_DATETIME_FORMAT);
    }

    this.setState({ data: data }, () => {
      if (this.formRef) this.formRef.resetFields();
      setTimeout(() => this.forceUpdate(), 200);
    });
  };

  handleConfirm = (e: EtranInvoiceSpcModel) => {
    const selected = this.selected;
    const date = e.spcDate ? moment(e.spcDate) : null;
    const result: EtranInvoiceSpcModel = {
      spcCustomText: date ? date.toISOString(true) : e.spcCustomText,
      spcId: e.spcId,
      spcNumber: e.spcNumber,
      spcDate: date ? date.toISOString(true) : null,
      spcWrDocId: e.spcWrDocId,
      spcWsId: e.spcWsId,
      spcTranspClauseDesc: selected.description,
      spcTranspClauseId: e.spcTranspClauseId,
      spcClaimNumber: e.spcClaimNumber,
      spcWNNumLoad: e.spcWNNumLoad,
      transpClauseTypeName: selected.transpClauseTypeName,
    };
    this.setState({ visible: false });
    this.props.onChange(result);
  };

  onOpen = () => {
    this.setState({ visible: true }, () => {
      this.handleFormReset();
    });
  };

  get isEdit() {
    return !!this.props.data && this.props.data;
  }

  onClose = () => {
    this.handleFormReset();
    this.setState({ visible: false });
  };

  get selected() {
    if (!this.formRef) return;
    const selectedId = this.formRef.getFieldValue('spcTranspClauseId');
    if (!selectedId) return;
    return etranStore.dictionaryInfo.transpClauseDictionary.find((s) => s.transpClauseId == selectedId);
  }

  render() {
    const data = this.state.data;
    const marks = etranStore.dictionaryInfo.transpClauseDictionary.filter((d) => [1, 4, 5].includes(d.transpClauseTypeId));
    return (
      <>
        <Button onClick={this.onOpen}>{this.isEdit ? 'Изменить' : 'Добавить'}</Button>
        <Modal
          title="Специальные отметки"
          destroyOnClose={true}
          open={this.state.visible}
          onCancel={this.onClose}
          className={'etran-modal'}
          footer={
            <Button
              onClick={() => {
                this.formRef.submit();
              }}>
              {this.isEdit ? 'Изменить' : 'Добавить'}
            </Button>
          }>
          <ScrolledContainer style={{ maxHeight: 'calc(100vh - 265px)', overflowX: 'hidden' }}>
            <Form
              layout="horizontal"
              ref={(ref) => (this.formRef = ref)}
              size={'small'}
              name={'etran-editor'}
              labelCol={{ span: 4 }}
              initialValues={{
                ...data,
              }}
              onFinish={this.handleConfirm}>
              <Form.Item name="spcTranspClauseId" label={'Отметка'} rules={[{ required: true }]}>
                <Select
                  showSearch
                  optionFilterProp={'children'}
                  filterOption={filterOptionFn}
                  onSelect={(e) => {
                    this.forceUpdate(() => {
                      this.formRef.resetFields(['spcCustomText']);
                    });
                  }}>
                  {marks.map((m) => (
                    <Select.Option value={m.transpClauseId} key={m.transpClauseId}>
                      {m.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {this.selected && [1143].includes(this.selected.transpClauseId) ? (
                <Form.Item name="spcClaimNumber" label={'Номер заявки на отстой'} rules={[{ required: true }]}>
                  <Input maxLength={255} />
                </Form.Item>
              ) : null}
              {this.selected && [1143].includes(this.selected.transpClauseId) ? (
                <Form.Item name="spcNumOtp" label={'Номер отправки на отстой'} rules={[{ required: true }]}>
                  <InputNumber max={9999999999} min={0} step={1} />
                </Form.Item>
              ) : null}
              {this.selected && [1303].includes(this.selected.transpClauseId) ? (
                <Form.Item name="spcNumber" label={'Номер договора на ремонт/промывку'} rules={[{ required: true }]}>
                  <Input maxLength={255} />
                </Form.Item>
              ) : null}
              {this.selected && [1303].includes(this.selected.transpClauseId) ? (
                <Form.Item name="spcDate" label={'Дата выдачи'} rules={[{ required: true }]}>
                  <DatePicker />
                </Form.Item>
              ) : null}
              {this.selected && [1304, 1325].includes(this.selected.transpClauseId) ? (
                <>
                  <Form.Item name="spcWrDocId" label={'Идентификатор Заявки на привлечение вагонов'} rules={[{ required: true }]}>
                    <InputNumber max={9999999999} min={0} step={1} />
                  </Form.Item>
                  <Form.Item name="spcWsId" label={'Идентификатор графика подачи'} rules={[{ required: true }]}>
                    <InputNumber max={9999999999} min={0} step={1} />
                  </Form.Item>
                </>
              ) : null}
              {this.selected && [1293].includes(this.selected.transpClauseId) ? (
                <Form.Item name="spcDate" label={'Дата и время'} rules={[{ required: !!this.selected.checkRequired }]}>
                  <DatePicker showTime={true} format={GLOBAL_DATETIME_FORMAT} />
                </Form.Item>
              ) : null}
              {this.selected && [1120].includes(this.selected.transpClauseId) ? (
                <Form.Item name="spcDate" label={'Дата'} rules={[{ required: !!this.selected.checkRequired }]}>
                  <DatePicker format={GLOBAL_DATE_FORMAT} />
                </Form.Item>
              ) : null}
              {this.selected && ![1293, 1120].includes(this.selected.transpClauseId) && this.selected.editMask ? (
                <Form.Item
                  name="spcCustomText"
                  label={this.selected.customTextDscr ? this.selected.customTextDscr : 'Замечание'}
                  rules={[{ required: !!this.selected.checkRequired }]}>
                  <Input.TextArea maxLength={255} />
                </Form.Item>
              ) : null}
            </Form>
          </ScrolledContainer>
        </Modal>
      </>
    );
  }
}

export default EtranInvoiceCarSpcMarksModal;
