import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Column,
  Export,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import qs from 'qs';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { CompanyBankService } from '@stores/bankStore/companyBank.service';
import { SupplierBankService } from '@stores/bankStore/supplierBank.service';
import userStore from '@stores/userStore/userStore';

import FilesLoadModal from './FilesLoadModal';

interface Props {
  controller: 'companyBanks' | 'supplierBanks';
  height: string;
}

interface State {
  dataSource: DataSource;
}

class OperationsList extends Component<Props, State> {
  dataGrid: DataGrid;

  constructor(props: Props) {
    super(props);

    this.state = {
      dataSource: this.dataSource,
    };
  }

  get dataSource() {
    const controller = this.props.controller;
    return new DataSource({
      store: new CustomStore({
        //key: 'id',
        load: function (loadOptions: any) {
          if (controller === 'companyBanks')
            return new CompanyBankService().getBankOperations(loadOptions).catch(() => {
              throw 'Data Loading Error';
            });
          if (controller === 'supplierBanks')
            return new SupplierBankService().getBankOperations(loadOptions).catch(() => {
              throw 'Data Loading Error';
            });
        },
        update: (key, values) => {
          return Promise.all([]);
        },
      }),
    });
  }

  getFilter = (name: string) => {
    const query = qs.parse(window.location.href.split('?')[1], { ignoreQueryPrefix: true });
    const data = query[name];
    if (!data) return null;
    let target = Array.isArray(data) ? data : [data];
    if (name == 'operationdate') {
      return target.map((t) => moment(t).toDate());
    }
    return target;
  };

  render() {
    const { dataSource } = this.state;
    const dateFilter = this.getFilter('operationdate');
    return (
      <DataGrid
        allowColumnReordering={true}
        ref={(r) => (this.dataGrid = r)}
        dataSource={dataSource}
        style={{ height: this.props.height }}
        selection={{
          deferred: false,
          showCheckBoxesMode: 'none',
          allowSelectAll: true,
          mode: 'multiple',
        }}
        allowColumnResizing={true}
        remoteOperations={true}
        showBorders={true}
        columnResizingMode={'widget'}
        columnAutoWidth={true}
        showColumnLines={true}
        columnMinWidth={30}
        showRowLines={true}
        rowAlternationEnabled={true}
        onToolbarPreparing={this.onToolbarPreparing}>
        <Paging enabled={true} defaultPageSize={50} />
        <Pager
          allowedPageSizes={[25, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
          infoText={'Всего: {2}'}
        />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} emptyPanelText={'Поле группировки'} />
        <Grouping autoExpandAll={false} />
        <Export enabled={true} allowExportSelectedData={true} excelWrapTextEnabled={true} />
        <Template name={'loadFiles'} render={() => this.loadFilesTemplate()} />
        {INDEX_COLUMN}
        {!userStore.isOrgOrUser && (
          <Column allowFixing={true} dataField={this.props.controller.replaceAll('Banks', 'name')} caption={'Компания'} />
        )}
        <Column
          allowFixing={true}
          dataField="operationdate"
          caption={'Дата'}
          dataType={'date'}
          selectedFilterOperation={dateFilter && dateFilter.length ? 'between' : null}
          filterValue={dateFilter}
        />
        <Column allowFixing={true} dataField="documentnumber" caption={'Номер'} />
        <Column allowFixing={true} dataField="counterpartyname" caption={'Контрагент'} />
        <Column allowFixing={true} dataField="counterpartyaccount" caption={'Счет'} />
        <Column allowFixing={true} dataField="counterpartyrcbic" caption={'БИК'} />
        <Column allowFixing={true} dataField="credit" caption={'Кредит'} />
        <Column allowFixing={true} dataField="debit" caption={'Дебет'} />
        <Column allowFixing={true} dataField="paymentdetails" caption={'Детали'} />
        <Column allowFixing={true} dataField="operationtype" caption={'Операция'} />
        <Summary key={'summary'}>
          <TotalItem column={'credit'} summaryType={'sum'} skipEmptyValues={true} displayFormat={'{0}'} />
          <TotalItem column={'debit'} summaryType={'sum'} skipEmptyValues={true} displayFormat={'{0}'} />
        </Summary>
        ,
      </DataGrid>
    );
  }

  loadFilesTemplate = () => {
    return <FilesLoadModal controller={this.props.controller} parentId={userStore.userData.companyId} />;
  };

  reload() {
    if (this.dataGrid) {
      this.dataGrid.instance.refresh();
    }
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'loadFiles',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.state.dataSource.reload();
          },
        },
      },
    );
  };
}

export default OperationsList;
