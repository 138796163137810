import { DeleteOutlined, DollarOutlined } from '@ant-design/icons';
import { Button, List, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import adminBillingStore from '@stores/adminBilling/adminBillingStore';
import designStore from '@stores/designStore/designStore';

import AdminBillingSourceEditor from './AdminBillingSourceEditor';

interface State {}
interface Props {}

@observer
class AdminBillingServices extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    adminBillingStore.loadServicesAndStrategies();
  }

  render() {
    const isBusy = adminBillingStore.isBusy;
    const isMobile = designStore.isMobile;
    const padding = isMobile ? '8.7rem' : '12.2rem';
    return (
      <div style={{ height: `calc(100vh - ${padding})` }}>
        <AdminBillingSourceEditor data={{ name: null, id: null, strategyCode: null, strategyName: null }} />
        <List
          loading={isBusy}
          itemLayout="horizontal"
          dataSource={adminBillingStore.services}
          renderItem={(item) => (
            <List.Item
              actions={[
                <AdminBillingSourceEditor data={item} key={'edit' + item.id} />,
                <Popconfirm title="Удалить？" okText="Да" cancelText="Нет" onConfirm={() => this.handleDelete(item)}>
                  <Button size={'small'} type={'text'} icon={<DeleteOutlined />} />
                </Popconfirm>,
              ]}>
              <List.Item.Meta avatar={<DollarOutlined />} title={item.name} description={item.strategyName} />
            </List.Item>
          )}
        />
      </div>
    );
  }

  handleDelete = (item) => {
    adminBillingStore.deleteService(item);
  };
}

export default AdminBillingServices;
