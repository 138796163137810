import { makeObservable, observable } from 'mobx';

import TrackingStore from '../trackingStoreBase';
import { ActiveWagon, HistoryWagon, WagonAddRequest, WagonRemoveRequest } from './WagonTypes';
import { TrackingWagonStoreService } from './trackingWagonStore.service';

class TrackingWagonStore extends TrackingStore<ActiveWagon, HistoryWagon, WagonAddRequest, WagonRemoveRequest, TrackingWagonStoreService> {
  @observable active: ActiveWagon[];
  @observable history: HistoryWagon[];
  service = new TrackingWagonStoreService();

  constructor() {
    super();

    makeObservable(this);
  }
}

export default new TrackingWagonStore();
