import { Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

class AdaptiveHeaderTabs extends Component<TabsProps> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Tabs {...this.props} style={{ width: designStore.toolbarWidth, height: 'var(--header-height)' }} centered tabPosition={'top'}>
        {this.props.children}
      </Tabs>
    );
  }
}

export default observer(AdaptiveHeaderTabs);
