import { LoadOptions } from 'devextreme/data/load_options';
import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';

import { sortDirections, unpackSortedDirections } from '@stores/supplierBidRegistryStore/supplierBidRegistryStore.lib';

import service from './supplierBidRegistryStore.service';
import { SortedDirections, SupplierBidRegistryDto } from './supplierBidRegistryStore.types';

class SupplierBidRegistryStore {
  @observable private _isLoading: boolean = false;
  @observable private _reportRange: [Moment, Moment] = [moment().startOf('month'), moment().endOf('month')];
  @observable private _item: SupplierBidRegistryDto = {} as SupplierBidRegistryDto;
  @observable private _sortedDirections: SortedDirections = {};

  constructor() {
    makeObservable(this);
  }

  @action
  async createSupplierBidRegistry(body: SupplierBidRegistryDto) {
    this._isLoading = true;
    try {
      return await service.createSupplierBidRegistry(body);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async updateSupplierBidRegistry(body: SupplierBidRegistryDto) {
    this._isLoading = true;
    try {
      await service.updateSupplierBidRegistry(body);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async getReport(loadOptions: LoadOptions) {
    this._isLoading = true;
    try {
      return await service.getReport(this.isoReportRange[0], this.isoReportRange[1], loadOptions);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async getSupplierBidRegistryById(id: string) {
    this._isLoading = true;
    try {
      this._item = await service.getSupplierBidRegistryById(id);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async getDirections(id: string) {
    try {
      this._sortedDirections = sortDirections(await service.getDirections(id));
    } catch (e) {
      throw e;
    }
  }

  @action
  async updateSortedDirections(values: SortedDirections) {
    this._isLoading = true;
    try {
      await service.updateDirections(this.supplierBidRegistryId, unpackSortedDirections(values));
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async deleteSupplierBidRegistry(id: string) {
    this._isLoading = true;

    try {
      await service.deleteSupplierBidRegistry(id);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action.bound
  clearSupplierBidRegistry() {
    this._item = {} as SupplierBidRegistryDto;
    this._sortedDirections = {};
  }

  @action.bound
  setReportRange(range: [Moment, Moment]) {
    this._reportRange = range;
  }

  @action
  changeSortedDirectionValue = (index: number, etsng: string, name: string, value: any) => {
    this._sortedDirections[etsng][index][name] = value;
    this._sortedDirections = { ...this.sortedDirections };
  };

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get reportRange(): [Moment, Moment] {
    return this._reportRange;
  }

  @computed
  get supplierBidRegistry() {
    return this._item;
  }

  @computed
  get supplierBidRegistryId() {
    return this.supplierBidRegistry.supplierBidRegistryId;
  }

  @computed
  get supplierBidRegistryServiceType() {
    return this.supplierBidRegistry.serviceType;
  }

  @computed
  get sortedDirections() {
    return this._sortedDirections;
  }

  get isoReportRange() {
    return this._reportRange.map((date) => date.toISOString());
  }
}

export const supplierBidRegistryStore = new SupplierBidRegistryStore();
