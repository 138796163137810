import { observer } from 'mobx-react';
import React, { Component } from 'react';

import LoadingRegisterSupplierForm from '@components/routed/LoadingRegister/LoadingRegisterForm/LoadingRegisterSupplierForm';

import loadRegistryStore from '@stores/loadRegistryStore/loadRegistryStore';
import markStore from '@stores/markStore';
import userStore from '@stores/userStore';

import LoadingRegisterForm from './LoadingRegisterForm';

interface Props {
  onFinish(): void;
}

interface State {}

@observer
class LoadingRegisterFormWrapper extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!loadRegistryStore.edit) return null;
    const FormComponent = loadRegistryStore.editType == 'supplierOnly' ? LoadingRegisterSupplierForm : LoadingRegisterForm;
    return (
      <FormComponent
        onFinish={() => {
          loadRegistryStore.edit = null;
          this.props.onFinish();
        }}
        onCancel={() => {
          loadRegistryStore.edit = null;
        }}
      />
    );
  }
}

export default LoadingRegisterFormWrapper;
