import service from './requestIlsStore.service';
import { ClaimReport, CreateRequest, CreateSend, IlsClaimHistoryReport, Send, StateType } from './requestIlsStoreData';

class RequestIlsStore {
  async load(loadOptions: any): Promise<ClaimReport[]> {
    return await service.getData(loadOptions);
  }

  async edit(data: ClaimReport): Promise<void> {
    return await service.editData(data);
  }

  async create(data: CreateRequest): Promise<string> {
    return await service.createData(data);
  }

  async delete(id: string): Promise<void> {
    return await service.deleteData(id);
  }

  async copy(id: string): Promise<string> {
    return await service.copyData(id);
  }

  async history(id: string): Promise<IlsClaimHistoryReport[]> {
    return await service.historyData(id);
  }
  async exportToPdf(id: string): Promise<Blob> {
    return await service.exportToPdf(id);
  }

  async getIls(id: string): Promise<ClaimReport> {
    return await service.getOneData(id);
  }

  async setStatus(ilsClaimId: string, status: StateType): Promise<void> {
    return await service.setStatus(ilsClaimId, status);
  }

  async createSendIls(data: CreateSend): Promise<ClaimReport> {
    return await service.createSendIls(data);
  }

  async updateSendIls(data: Send): Promise<ClaimReport> {
    return await service.updateSendIls(data);
  }

  async deleteSendIls(ilsSendId: string): Promise<ClaimReport> {
    return await service.deleteSend(ilsSendId);
  }
}

export default new RequestIlsStore();
