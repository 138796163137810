import { observer } from 'mobx-react';
import moment from 'moment/moment';
import React, { useEffect } from 'react';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CalendarCustom from '@components/fw/Calendar/CalendarCustom';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';

import { supplierBidRegistryStore } from '@stores/supplierBidRegistryStore';

import { supplierBidRegistryHeaderExtra as headerExtra } from '../header-extra';
import { SupplierBidRegistryHeaderProps as HeaderProps } from './types';

export const SupplierBidRegistryHeader = observer(({ dataSource }: HeaderProps) => {
  const onDatesChange = (event: [moment.Moment, moment.Moment]) => {
    supplierBidRegistryStore.setReportRange(event);
    dataSource.reload();
  };

  const title = 'Реестр ставок поставщика';
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <AdaptivePageHeader
      extra={
        <CollapsedToolbar
          content={[
            ...headerExtra,
            <CalendarCustom key={'calendar_2'} onChange={onDatesChange} values={supplierBidRegistryStore.reportRange} />,
          ]}
        />
      }
      title={title}
    />
  );
});
