import HttpClient from '@utils/httpClient';

import { FileInputModel, FileStorageFilter } from './sourceLogsData';

const service = {
  async getData(type: 'input' | 'output', filter: FileStorageFilter): Promise<FileInputModel[]> {
    return (await HttpClient.post(`/api/storage/${type}/data`, filter)).data;
  },

  async getContent(fileId: string): Promise<FileInputModel> {
    return (await HttpClient.get(`/api/storage/content`, { params: { fileId } })).data;
  },
};

export default service;
