import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const ItineraryClaimDxCell: React.FC<any> = (props) => {
  const isGroup = props.rowType === 'group';
  let value = props.row.data;
  return value ? (
    <span>
      {isGroup && <span>{props.column.caption}: </span>}
      <Link to={`/claims/edit-claim/${value.claimid}`} style={{ marginLeft: '2px' }}>
        <Button type={'link'} size={'small'}>
          {value.claimname}
        </Button>
      </Link>
    </span>
  ) : null;
};

export default ItineraryClaimDxCell;
