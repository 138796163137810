import moment from 'moment/moment';

import { TariffTemplate } from '@stores/optionsStore/optionsStoreData';

export function getConvertedTemplate(template: TariffTemplate, dataConvert: 'string' | 'object') {
  const copy = JSON.parse(JSON.stringify(template));

  if (typeof template.data === 'string') {
    copy.data = JSON.parse(copy.data);
  }

  for (const [key, value] of Object.entries(copy.data)) {
    if (key.toLowerCase().includes('date')) {
      switch (dataConvert) {
        case 'object':
          copy.data[key] = moment(value);
          break;
        case 'string':
          copy.data[key] = moment(value).toISOString(true);
          break;
      }
    }
  }

  if (dataConvert === 'string') {
    copy.data = JSON.stringify(copy.data);
  }

  return copy as TariffTemplate;
}
