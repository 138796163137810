import { Button, Tabs } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveHeaderTabs from '@components/fw/AdaptiveElements/AdaptiveHeaderTabs';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import SupportModal from '@components/fw/SupportModal/SupportModal';

import designStore from '@stores/designStore/designStore';
import userStore from '@stores/userStore/userStore';

import { logout } from '@utils/authority';

import TariffFgkForm from './TariffFgkForm';
import TariffFgkHistory from './TariffFgkHistory';

interface TariffFgkPageState {
  selectedTab: 'form' | 'history';
}

class TariffFgkPage extends Component<RouteComponentProps, TariffFgkPageState> {
  formRef: FormInstance;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      selectedTab: 'form',
    };
  }

  componentDidMount(): void {
    document.title = 'Расчет тарифа ФГК';
  }

  form: TariffFgkForm;

  render() {
    const tab = this.state.selectedTab;
    const width = designStore.toolbarWidth;
    return (
      <>
        <AdaptivePageHeader
          className={'page_header'}
          title={[
            userStore.tmpUser ? (
              <Button shape="round" key={'logoutBtn'} onClick={() => logout()}>
                Вход
              </Button>
            ) : null,
            userStore.tmpUser ? <SupportModal {...this.props} key={'Support'} forTariff={true} /> : null,
          ]}
          subTitle={
            <AdaptiveHeaderTabs activeKey={this.state.selectedTab} onTabClick={(e) => this.setState({ selectedTab: e as any })}>
              <Tabs.TabPane tab={'Расчет'} key="form" />
              <Tabs.TabPane tab={'История'} key="history" />
            </AdaptiveHeaderTabs>
          }
        />
        {tab == 'history' && (
          <TariffFgkHistory
            onConfirm={(e) => {
              this.setState({ selectedTab: 'form' });
              this.form.onHistorySelect(e);
            }}
            onCancel={() => this.setState({ selectedTab: 'form' })}
          />
        )}
        <div style={{ display: tab == 'form' ? null : 'none' }}>
          <TariffFgkForm ref={(ref) => (this.form = ref)} />
        </div>
      </>
    );
  }
}

export default observer(TariffFgkPage);
