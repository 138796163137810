import React from 'react';

import NotificationPopover from './NotificationPopover';

const NotificationDxCellReport: React.FC<any> = (props) => {
  const isGroup = props.rowType === 'group';
  const isData = props.rowType === 'data';
  let value;
  try {
    value = JSON.parse(props.value);
  } catch (e) {}
  const type = props.column ? props.column.dataField : null;
  const numberOrClaimId = props.key;
  const companyId = props.data ? props.data.companyid : null;
  return value && Array.isArray(value) ? (
    <span>
      {isGroup && <span>{props.column.caption}: </span>}
      {value
        .sort((a, b) => (a.TemplateName > b.TemplateName ? 1 : -1))
        .map((tag, index) => {
          return (
            <span key={`tag_${index}`} style={{ marginLeft: index ? '6px' : '0' }}>
              {isData ? (
                <NotificationPopover data={tag} type={type} companyId={companyId} numberOrClaimId={numberOrClaimId} />
              ) : (
                tag.TemplateName
              )}
            </span>
          );
        })}
    </span>
  ) : null;
};

export default NotificationDxCellReport;
