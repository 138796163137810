import { Select, SelectProps } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';

import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import { InputPosition } from '@stores/designStore/designData';

const { Option } = Select;

export interface RemoteSelectProps extends SelectProps {
  dataSource: any;
  aValueExpr?: string;
  primitiveResult?: boolean;
  valueExpr: string;
  displayExpr: string;
  value?: any;
  onChange?: (value: any) => void;
  initData?: any[];
  itemTemplate?(item: any): void;
  allowClear?: boolean;
  size?;
  onSelectFull?(item: any): void; // Игнорирует проп primitiveResult и возвращает выбранный элемент в исходном виде
  onSelect2?(item: any): void;
  loading?: boolean;
  className?: string;
  popupContainer?: any;
  debounceTime?: number;
  float?: boolean;
  position?: InputPosition;
  autoInitDict?: boolean;
  'data-test'?: string;
}

interface RemoteSelectState {
  data: any[];
  value: any[];
  fetching: boolean;
}

class RemoteSelect extends Component<RemoteSelectProps, RemoteSelectState> {
  lastFetchId = 0;
  constructor(props: RemoteSelectProps) {
    super(props);
    this.state = {
      data: this.props.initData ? this.props.initData : [],
      value: undefined,
      fetching: false,
    };
  }
  async componentDidMount() {
    if (this.props.autoInitDict && this.props.value) {
      await this.fetch(this.props.value.toString());
    }
    this.updateValue();
  }

  async componentDidUpdate(prevProps: Readonly<RemoteSelectProps>) {
    if (prevProps.value !== this.props.value) {
      if (this.props.autoInitDict && this.props.value) {
        await this.fetch(this.props.value.toString());
      }
      this.updateValue();
    }
  }

  updateValue = () => {
    let value;
    if (this.props.value) {
      const v = this.props.value;
      if (this.props.mode) {
        value = v.map((sv) => this.parseSingleValue(sv));
      } else {
        value = this.parseSingleValue(v);
      }
    }
    this.setState({ value });
  };

  search = '';
  debouncePending = false;
  promise;
  cancelTokenSource = axios.CancelToken.source();

  parseSingleValue(sv) {
    let find = this.state.data
      ? this.state.data.find((d) => d[this.props.valueExpr] === sv || d[this.props.valueExpr] === sv[this.props.valueExpr])
      : null;
    if (find) {
      return {
        value: find[this.props.valueExpr],
        label: this.props.itemTemplate ? this.props.itemTemplate(find) : find[this.props.displayExpr],
        key: find[this.props.valueExpr],
      };
    } else {
      if (this.props.primitiveResult) {
        return { value: sv, label: sv, key: sv };
      } else {
        return {
          value: sv[this.props.valueExpr],
          label: this.props.itemTemplate ? this.props.itemTemplate(sv) : sv[this.props.displayExpr],
          key: sv[this.props.valueExpr],
        };
      }
    }
  }

  render() {
    const { fetching, data, value } = this.state;
    const {
      placeholder,
      valueExpr,
      displayExpr,
      mode,
      disabled,
      itemTemplate,
      dropdownMatchSelectWidth,
      loading,
      bordered,
      style,
      className,
      popupContainer,
      position,
    } = this.props;
    const SelectComponent = this.props.float ? FloatSelect : Select;

    return (
      <SelectComponent
        bordered={bordered}
        position={position}
        className={className}
        getPopupContainer={(trigger) => (popupContainer ? popupContainer : trigger.parentElement)}
        loading={fetching}
        size={this.props.size}
        mode={mode}
        labelInValue
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        //notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={this.onSearch}
        showSearch
        autoClearSearchValue={this.props.autoClearSearchValue}
        allowClear={!!this.props.allowClear}
        onChange={this.handleChange}
        onSelect={this.onSelect}
        onDropdownVisibleChange={(e) => {
          if (e) {
            this.onFocus();
          }
        }}
        data-test={this.props['data-test']}
        style={style}>
        {data.map((d, index) => (
          <Option
            disabled={disabled}
            key={`item_${d[valueExpr] + index}`}
            value={d[valueExpr]}
            style={disabled ? { cursor: 'not-allowed' } : {}}>
            {itemTemplate ? itemTemplate(d) : d[displayExpr]}
          </Option>
        ))}
      </SelectComponent>
    );
  }

  onSearch = (value) => {
    this.search = value;
    const timeout = this.props.debounceTime ? this.props.debounceTime : 300;
    if (!this.debouncePending) {
      this.debouncePending = true;
      setTimeout(() => {
        this.debouncePending = false;
        this.fetch(this.search);
      }, timeout);
    }
  };

  fetch = async (value) => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ fetching: true });
    let promise;
    this.cancelTokenSource = axios.CancelToken.source();
    if (this.props.aValueExpr !== undefined) {
      promise = this.props.dataSource(value, this.props.aValueExpr, this.cancelTokenSource.token);
    } else {
      promise = this.props.dataSource(value, this.cancelTokenSource.token);
    }
    return await promise
      .then((result) => {
        this.cancelTokenSource = null;
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.setState({ data: result });
      })
      .finally(() => this.setState({ fetching: false }));
  };

  handleChange = (value) => {
    if (this.props.onChange) {
      if (!value) {
        this.setState({ value: undefined });
        this.props.onChange(null);
        return;
      }
      if (Array.isArray(value)) {
        const val = value.map((x) => {
          const find = this.state.data.find((d) => d[this.props.valueExpr] == x.value);
          if (find) return find;
          const obj = {};
          obj[this.props.valueExpr] = x.value;
          obj[this.props.displayExpr] = this.transformLabel(x.label);
          return obj;
        });
        if (this.props.primitiveResult) {
          this.props.onChange(val.map((v) => v[this.props.valueExpr]));
        } else {
          this.props.onChange(val);
        }
      } else {
        let obj = this.state.data.find((d) => d[this.props.valueExpr] == value.value);
        if (!obj) {
          obj = {};
          obj[this.props.valueExpr] = value.value;
          obj[this.props.displayExpr] = this.transformLabel(value.label);
        }
        if (this.props.primitiveResult) {
          this.props.onChange(obj[this.props.valueExpr]);
        } else {
          this.props.onChange(obj);
        }
      }
    }
    this.setState({
      value: value,
      fetching: false,
    });
    if (this.props.autoClearSearchValue) {
      setTimeout(() => this.setState({ value: [] }), 500);
    }
  };

  onSelect = (value) => {
    if (!this.props.onSelect2 && !this.props.onSelectFull) {
      return;
    }

    if (!value) {
      this.setState({ value: undefined });
      if (this.props.onSelect2) {
        this.props.onSelect2(null);
      }
      if (this.props.onSelectFull) {
        this.props.onSelectFull(null);
      }
      return;
    }
    let obj = this.state.data.find((d) => d[this.props.valueExpr] == value.value);
    if (!obj) {
      obj = {};
      obj[this.props.valueExpr] = value.value;
      obj[this.props.displayExpr] = this.transformLabel(value.label);
    }
    if (this.props.onSelect2) {
      if (this.props.primitiveResult) {
        this.props.onSelect2(obj[this.props.valueExpr]);
      } else {
        this.props.onSelect2(obj);
      }
    }
    if (this.props.onSelectFull) {
      this.props.onSelectFull(obj);
    }
  };

  transformLabel(label) {
    if (typeof label === 'string') {
      return label;
    } else {
      const filtered = label.props.children.filter((f) => typeof f === 'string');
      return filtered.length ? filtered[0] : this.props.valueExpr;
    }
  }

  onFocus = () => {
    if (!this.state.data || !this.state.data.length) {
      this.fetch('');
    }
  };
}

export default RemoteSelect;
