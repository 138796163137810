import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Popconfirm, Row, Spin, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ReportUsers from '@components/fw/ReportUsers';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import companyCatalogStore from '@stores/companyCatalogStore/companyCatalogStore';
import { CustomReportDetailsModel } from '@stores/dispatcherStore/DispatcherData';
import dispatcherStore from '@stores/dispatcherStore/dispatcherStore';
import userStore from '@stores/userStore/userStore';

import DispatcherDrawer from './DispatcherDrawer';
import DispatcherItemCard from './DispatcherItemCard';

const { CheckableTag } = Tag;

interface DispatcherPageState {
  isBusy: boolean;
  result: CustomReportDetailsModel | null;
  reportList: CustomReportDetailsModel | null;
  currentDispatcher: CustomReportDetailsModel;
}

const MAX_CATALOGS_NAME_LENGTH = 60;

class DispatcherPage extends Component<RouteComponentProps, DispatcherPageState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      result: null,
      reportList: null,
      currentDispatcher: null,
    };
  }

  componentDidMount(): void {
    dispatcherStore.getReportList();
    dispatcherStore.loadInfoGroupList();
    document.title = 'Отчеты';
    if (!companyCatalogStore.companyCatalogs || (!companyCatalogStore.companyCatalogs.length && userStore.userData.companyId)) {
      companyCatalogStore.getCompanyCatalogs(userStore.userData.companyId).then();
    }
  }

  onDataChange = () => {
    dispatcherStore.getReportList();
  };

  render() {
    const reportList = dispatcherStore.reportList;
    const defaultReportList = dispatcherStore.defaultReportList;
    const isBusy = dispatcherStore.isBusyReportList;
    const avlbGroups = dispatcherStore.avlbGroups;
    const catalogs = companyCatalogStore.companyCatalogs;

    return (
      <div data-test="operational">
        <AdaptivePageHeader
          title={'Мои отчеты'}
          extra={userStore.isOrg ? null : <DispatcherDrawer mode={'create'} onSubmit={this.onDataChange} />}
        />
        <ScrolledContainer style={{ height: 'calc(100vh - var(--header-height))' }}>
          <Spin spinning={isBusy}>
            <AdaptiveCard>
              <>
                {defaultReportList.length != 0 && (
                  <Card
                    title={<span className={'chapter'}>Базовые</span>}
                    hoverable={false}
                    key={'Base'}
                    headStyle={{ borderBottom: 'none' }}
                    style={{ border: 'none' }}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      {defaultReportList.map((report) => {
                        return (
                          <Col xs={24} sm={24} md={12} xl={8} key={report.id}>
                            <DispatcherItemCard
                              id={String(report.id)}
                              history={this.props.history}
                              date={report.createDate}
                              link={`/dispatcher/reports/${report.id}/${report.groupId}/null`}
                              name={report.name}
                              body={report.filters.map((filter) => {
                                return (
                                  <CheckableTag onClick={(e) => e.stopPropagation()} className={'filtertag'} key={filter.filterId} checked>
                                    <Link
                                      to={`/dispatcher/reports/${report.id}/${report.groupId}/${filter.filterId}`}
                                      key={filter.filterId}>
                                      {filter.name}
                                    </Link>
                                  </CheckableTag>
                                );
                              })}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Card>
                )}
                {avlbGroups.map((groupName) => {
                  return (
                    <Card
                      title={<span className={'chapter'}>{groupName}</span>}
                      hoverable={false}
                      key={groupName}
                      headStyle={{ border: 'none' }}
                      style={{ border: 'none' }}>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {reportList
                          .filter((r) => r.groupName == groupName)
                          .map((report) => {
                            let cat =
                              report.catalogs && report.catalogs.length
                                ? catalogs
                                    .filter((c) => report.catalogs.includes(c.id))
                                    .map((c) => c.title)
                                    .join(', ')
                                : null;
                            if (cat && cat.length > MAX_CATALOGS_NAME_LENGTH) {
                              cat = cat.substring(0, MAX_CATALOGS_NAME_LENGTH - 3) + '...';
                            }
                            return (
                              <Col xs={24} sm={24} md={12} xl={8} key={report.id}>
                                <DispatcherItemCard
                                  history={this.props.history}
                                  date={report.createDate}
                                  link={`/dispatcher/reports/${report.id}/${report.groupId}/null`}
                                  name={report.name}
                                  body={
                                    <>
                                      {report.filters.map((filter) => {
                                        return (
                                          <CheckableTag
                                            onClick={(e) => e.stopPropagation()}
                                            className={'filtertag'}
                                            key={filter.filterId}
                                            checked>
                                            <Link
                                              to={`/dispatcher/reports/${report.id}/${report.groupId}/${filter.filterId}`}
                                              key={filter.filterId}>
                                              {filter.name}
                                            </Link>
                                          </CheckableTag>
                                        );
                                      })}
                                      {cat ? (
                                        <p style={{ position: 'absolute', bottom: -3, color: 'darkgrey', fontSize: 9 }}>Только для {cat}</p>
                                      ) : null}
                                    </>
                                  }
                                  actions={
                                    <span onClick={(e) => e.stopPropagation()}>
                                      {!userStore.isOrg && <DispatcherDrawer onSubmit={this.onDataChange} mode={'edit'} data={report} />}
                                      <ReportUsers reportId={report.id} />
                                      <Popconfirm
                                        title="Удалить отчет？"
                                        okText="Да"
                                        cancelText="Нет"
                                        onConfirm={() => this.handleRemoveConfirm(report)}>
                                        <Button
                                          icon={<DeleteOutlined className={'dispatcher-icon-gray'} />}
                                          title={'Удалить отчет'}
                                          size={'small'}
                                          type="text"
                                          className={'dx-button-mode-contained dx-button'}
                                        />
                                      </Popconfirm>
                                    </span>
                                  }
                                />
                              </Col>
                            );
                          })}
                      </Row>
                    </Card>
                  );
                })}
              </>
            </AdaptiveCard>
          </Spin>
        </ScrolledContainer>
      </div>
    );
  }

  handleRemoveConfirm = (selectedReport) => {
    const id = selectedReport.id;
    this.setState({
      isBusy: true,
    });
    dispatcherStore
      .deleteReportList(id)
      .finally(() => this.setState({ isBusy: false }))
      .then(() => {
        dispatcherStore.getReportList();
      });
  };
}

export default observer(DispatcherPage);
