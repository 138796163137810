import { SearchOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React from 'react';

import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import { filterOptionFn } from '@utils/sort';

import styles from '../field.module.scss';
import { FieldSelectProps as Props } from './field-float-select.types';

export const FieldFloatSelect = ({ name, children, widthSearch, rules, ...selectProps }: Props) => {
  const suffixIcon = widthSearch ? <SearchOutlined /> : undefined;

  return (
    <Form.Item className={styles.fieldFlexAuto} rules={rules} name={name}>
      <FloatSelect suffixIcon={suffixIcon} showSearch={widthSearch} filterOption={filterOptionFn} {...selectProps}>
        {children}
      </FloatSelect>
    </Form.Item>
  );
};
