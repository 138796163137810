import { Form } from 'antd';
import React from 'react';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';

import styles from '../field.module.scss';
import { FieldFloatInputNumberProps as Props } from './field-float-input-number.types';

export const FieldFloatInputNumber = ({ name, ...inputNUmberProps }: Props) => {
  return (
    <Form.Item className={styles.fieldFlexAuto} name={name}>
      <FloatInputNumber {...inputNUmberProps} />
    </Form.Item>
  );
};
