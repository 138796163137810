import { action, computed, makeObservable, observable } from 'mobx';

import service from './logStore.service';

export type LogModel = {
  logId: number;
  actionName: string;
  userLogin: string;
  userRole: string;
  note: string;
  createDate: Date;
};

class LogStore {
  @observable isBusy: boolean = false;
  @observable private _log: LogModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action getLog(dateFrom: any, dateTo: any) {
    this.isBusy = true;
    const data = {
      notRoles: [],
      companyIds: [],
      from: dateFrom,
      to: dateTo,
    };
    service
      .getLogs(data)
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this._log = result;
      });
  }

  @computed get log() {
    if (!this._log) {
      return [];
    } else {
      return [...this._log];
    }
  }

  async in() {
    await service.in();
  }

  async out() {
    await service.out();
  }
}

export default new LogStore();
