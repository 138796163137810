import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

export const supplierBidRegistryHeaderExtra = [
  <Link to={'/supplier-list-of-rates/create'} key={'create'}>
    <Button icon={<PlusOutlined />} size={'small'} type={'link'}>
      Создать
    </Button>
  </Link>,
];
