import HttpClient from '@utils/httpClient';

import {
  CompanyWagonItineraryModel,
  SendLimitationsModel,
  SendModel,
  SendSupplyScheduleModel,
  TariffAggregationModel,
  TariffCountryDetailModel,
  WagonAutoSelectInfo,
} from './sendData';

const service = {
  getSends() {
    return HttpClient.get(`/api/send`).then((resp) => {
      return resp.data;
    });
  },
  getSend(sendId: string) {
    return HttpClient.get(`/api/send/getSend`, { params: { sendId } }).then((resp) => {
      return resp.data;
    });
  },

  async getSendSchedules(sendId: string): Promise<SendSupplyScheduleModel[]> {
    return (await HttpClient.get(`/api/send/schedules`, { params: { sendId } })).data;
  },

  create(data: any) {
    return HttpClient.post(`/api/send/create`, data).then((resp) => {
      return resp.data;
    });
  },

  update(data: any) {
    return HttpClient.post(`/api/send/update`, data).then((resp) => {
      return resp.data;
    });
  },

  setStatus(sendId: string, statusCode: string) {
    return HttpClient.post(`/api/send/status/${sendId}/${statusCode}`).then((resp) => {
      return resp.data;
    });
  },

  delete(sendId: string) {
    return HttpClient.delete(`/api/send/delete/${sendId}`).then((resp) => {
      return resp.data;
    });
  },

  limitations(sendId: string, scheduleId: string): Promise<SendLimitationsModel> {
    return HttpClient.get(`/api/send/limitations`, { params: { sendId, scheduleId } }).then((resp) => {
      return resp.data;
    });
  },

  createSchedule(data: any) {
    return HttpClient.post(`/api/send/createSchedule`, data).then((resp) => {
      return resp.data;
    });
  },

  updateSchedule(data: any) {
    return HttpClient.post(`/api/send/updateSchedule`, data).then((resp) => {
      return resp.data;
    });
  },

  deleteSchedule(scheduleId: string) {
    return HttpClient.delete(`/api/send/deleteSchedule/${scheduleId}`).then((resp) => {
      return resp.data;
    });
  },
  async getSendTariffDetail(data: SendModel): Promise<TariffCountryDetailModel[]> {
    return (await HttpClient.post(`/api/send/calculateTariff`, data)).data;
  },

  async getWagonsForScheduler(): Promise<number[]> {
    return (await HttpClient.get(`/api/track/wagon/number/list`)).data;
  },

  async wagonsAutoSelection(etsngCode: string, loadStationCode: string): Promise<WagonAutoSelectInfo[]> {
    return (
      //await HttpClient.get(`/api/sendAutoSelect/wagon/list`, { params: { etsngCode: 232431, loadStationCode: 53570 } })
      (await HttpClient.get(`/api/sendAutoSelect/wagon/list`, { params: { etsngCode, loadStationCode } })).data
    );
  },

  async wagonsAutoSelectionByRps(
    rps: string,
    loadStationCode: number,
    radius: number,
    daysBeforeRepair: number,
  ): Promise<WagonAutoSelectInfo[]> {
    return (await HttpClient.get(`/api/sendAutoSelect/wagon/listByRps`, { params: { rps, loadStationCode, radius, daysBeforeRepair } }))
      .data;
  },

  async tariffForAutoSelected(data): Promise<TariffAggregationModel> {
    return (await HttpClient.post(`/api/tariff/calculatetariff/agg/withoutsave`, data)).data;
  },

  async sendAutoSelected(data: WagonAutoSelectInfo[], sendId: string): Promise<void> {
    return (await HttpClient.post(`/api/sendAutoSelect/supply/set?sendId=${sendId}`, data)).data;
  },

  async geoInfo(loadStationCode: number) {
    return (await HttpClient.get(`/api/send/geoinfo?loadStationCode=${loadStationCode}`)).data;
  },

  /// Очистить плановые заявки отправки, клиента, станции и удалить вагон из графика подач
  async removePlanItineraryData(wagonNumber: number, companyId?: string) {
    return (await HttpClient.delete(`/api/send/itinerary/plan`, { params: { companyId, wagonNumber } })).data;
  },

  /// Очистить текущие заявки отправки, клиента, удалить вагон из графика подач
  async removeCurrentItineraryData(wagonNumber: number, companyId?: string) {
    return (await HttpClient.delete(`/api/send/itinerary/current`, { params: { companyId, wagonNumber } })).data;
  },

  /// Получить текущий рейс компании
  async getCompanyItineraryForWagon(wagonNumber: number, companyId?: string): Promise<CompanyWagonItineraryModel> {
    return (await HttpClient.get(`/api/send/itinerary`, { params: { companyId, wagonNumber } })).data;
  },

  /// Поместить вагон в график подачи, установив текущих клиента, заячвку, отправку на основе данных из графика подач
  async setCurrentItineraryData(wagonNumber: number, scheduleId: string, companyId?: string) {
    return (await HttpClient.put(`/api/send/itinerary/current`, {}, { params: { companyId, wagonNumber, scheduleId } })).data;
  },

  /// Поместить вагон в график подачи, установив плыновых клиента, заячвку, отправку,, станции на основе данных из графика подач
  async setPlanItineraryData(wagonNumber: number, scheduleId: string, companyId?: string) {
    return (await HttpClient.put(`/api/send/itinerary/plan`, {}, { params: { companyId, wagonNumber, scheduleId } })).data;
  },
};

export default service;
