import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select } from 'antd';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import dictStore from '@stores/dictStore/dictStore';
import { CURRENCY_LIST } from '@stores/expeditionsStore/expeditionsData';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface ComponentProps {}

interface ComponentState {}

class ContractExtExpedition extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Form.List name="expeditionParams">
        {(fields, { add, remove }) => (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item>
                <Button style={{ margin: 5 }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить
                </Button>
              </Form.Item>
            </Col>
            {fields.map((field, index) => (
              <Col xs={24} sm={24} md={24} lg={12} xxl={8} key={index}>
                <AdaptiveCard
                  title={'Параметры экспедирования'}
                  extra={[
                    <Button key={'delete'} size={'small'} type={'text'} onClick={() => remove(field.name)} icon={<DeleteOutlined />} />,
                  ]}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item rules={[{ required: true }]} name={[field.name, 'countryCode']} fieldKey={[field.key, 'countryCode']}>
                        <FloatSelect placeholder={'Страна'}>
                          {dictStore.countriesDictionary.map((t) => (
                            <Select.Option key={t.iso3166} value={t.iso3166}>
                              {t.fullName}
                            </Select.Option>
                          ))}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item rules={[{ required: true }]} name={[field.name, 'currencyCode']} fieldKey={[field.key, 'currencyCode']}>
                        <FloatSelect placeholder={'Валюта'}>
                          {CURRENCY_LIST.map((t) => (
                            <Select.Option key={t.code} value={t.code}>
                              {t.name}
                            </Select.Option>
                          ))}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'expeditorPercentage']} fieldKey={[field.key, 'expeditorPercentage']}>
                        <FloatInputNumber max={99999999} min={0} step={0.01} placeholder={'% комиссии'} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        initialValue={false}
                        name={[field.name, 'freightChargeAvailable']}
                        fieldKey={[field.key, 'freightChargeAvailable']}
                        valuePropName={'checked'}>
                        <FloatCheckbox>В т.ч. доп. сборы</FloatCheckbox>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'extraAmount']} fieldKey={[field.key, 'extraAmount']}>
                        <FloatInputNumber max={99999999} min={0} step={0.1} placeholder={'Фикс. надбавка с НДС'} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'ndsPercentage']} fieldKey={[field.key, 'ndsPercentage']}>
                        <FloatInputNumber max={99999999} min={0} step={0.01} placeholder={'% НДС комиссии'} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'periodFrom']} fieldKey={[field.key, 'periodFrom']} rules={[{ required: true }]}>
                        <FloatDatepicker placeholder={'Действует с'} format={GLOBAL_DATE_FORMAT} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[field.name, 'periodTo']} fieldKey={[field.key, 'periodTo']} rules={[{ required: true }]}>
                        <FloatDatepicker format={GLOBAL_DATE_FORMAT} placeholder={'Действует до'} />
                      </Form.Item>
                    </Col>
                  </Row>
                </AdaptiveCard>
              </Col>
            ))}
          </Row>
        )}
      </Form.List>
    );
  }
}

export default ContractExtExpedition;
