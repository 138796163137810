import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import '@components/routed/IlsClaimPage/IlsClaimItemPage.less';

import requestIlsStore from '@stores/requestIlsStore/requestIlsStore';
import { ClaimReport } from '@stores/requestIlsStore/requestIlsStoreData';
import userStore from '@stores/userStore';

import IlsClaimWagonAdd from './IlsClaimWagonAdd';
import IlsClaimWagonCalendar from './IlsClaimWagonCalendar';
import IlsClaimWagonCommonInfo from './IlsClaimWagonCommonInfo';
import IlsClaimWagonLinked from './IlsClaimWagonLinked';
import IlsClaimWagonLoaded from './IlsClaimWagonLoaded';

interface ComponentState {
  data: ClaimReport | null;
  loading: boolean;
}

@observer
class IlsClaimWagonPage extends Component<RouteComponentProps, ComponentState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: false,
      data: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  get id() {
    const { params } = this.props.match;
    return params['id'];
  }

  getData = async (data?: ClaimReport): Promise<void> => {
    this.setState({ loading: true });
    const id = this.id;

    try {
      const res = data ?? (await requestIlsStore.getIls(id));
      this.setState({ data: res });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { data, loading } = this.state;
    const isOrg = userStore.isOrg;

    return (
      <>
        <AdaptivePageHeader
          title={
            <h3 className={'statusIlsTitle'}>
              <span className={'claim'}>Заявка №{data?.ilsClaimNumber}</span>
              <span className={'client'}>Клиент {data?.clientName}</span>
              {data?.statusName && <span className={'status'}>{data?.statusName}</span>}
            </h3>
          }
          extra={null}
        />
        <Spin spinning={loading}>
          {data && (
            <AdaptiveCard>
              <ScrolledContainer className={'requestIlsItemPage'}>
                <h4>
                  <Button type={'text'} icon={<ArrowLeftOutlined />} onClick={() => this.props.history.push('/view-ils-send/' + this.id)} />{' '}
                  Просмотр списка вагонов
                </h4>
                <Tabs type={'card'} defaultActiveKey={isOrg ? '2' : '1'}>
                  <Tabs.TabPane tab={'Привязка вагонов'} key={'1'} tabKey={'1'} disabled={isOrg}>
                    <Row>
                      <Col xs={16}>
                        <IlsClaimWagonCommonInfo ilsClaim={data} />
                      </Col>
                      <Col xs={8}>
                        <IlsClaimWagonCalendar ilsClaim={data} type={'send'} />
                      </Col>
                    </Row>
                    <IlsClaimWagonAdd ilsClaim={data} onChange={this.getData} disabled={false} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab={'Привязанные вагоны'} key={'2'} tabKey={'2'}>
                    <Row>
                      <Col xs={16}>
                        <IlsClaimWagonCommonInfo ilsClaim={data} />
                      </Col>
                      <Col xs={8}>
                        <IlsClaimWagonCalendar ilsClaim={data} type={'send'} />
                      </Col>
                    </Row>
                    <IlsClaimWagonLinked ilsClaim={data} onChange={this.getData} disabled={false} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab={'Отгруженные вагоны'} key={'3'} tabKey={'3'}>
                    <Row>
                      <Col xs={16}>
                        <IlsClaimWagonCommonInfo ilsClaim={data} />
                      </Col>
                      <Col xs={8}>
                        <IlsClaimWagonCalendar ilsClaim={data} type={'loaded'} />
                      </Col>
                    </Row>
                    <IlsClaimWagonLoaded ilsClaim={data} />
                  </Tabs.TabPane>
                </Tabs>
              </ScrolledContainer>
            </AdaptiveCard>
          )}
        </Spin>
      </>
    );
  }
}

export default IlsClaimWagonPage;
