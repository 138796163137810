import React from 'react';

const CurrencyDxCell: React.FC<any> = (props) => {
  const value = props.value;
  return typeof value == 'number' ? (
    <span>
      {value.toLocaleString('ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}{' '}
      ₽
    </span>
  ) : null;
};

export default CurrencyDxCell;
