import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import TrackingHistoryTable from '@components/fw/TrackingHistoryTable';

import trackingWagonStore from '@stores/trackingStore/wagon/trackingWagonStore';

@observer
class TrackingWagonsHistory extends Component<RouteComponentProps> {
  componentDidMount(): void {
    trackingWagonStore.loadHistory();
  }

  render() {
    const isBusy = trackingWagonStore.isBusyHistory;
    const data = trackingWagonStore.history;
    return (
      <>
        <TrackingHistoryTable value={data} isBusy={isBusy} type={'wagon'} />
      </>
    );
  }
}

export default TrackingWagonsHistory;
