import { Spin } from 'antd';
import DataGrid, { Column, FilterPanel, GroupPanel, HeaderFilter, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';

import statisticStore from '@stores/statisticStore/statisticStore';
import userStore from '@stores/userStore/userStore';

import DocumentButton from './DocumentButton';

interface Props {
  type: 'billing' | 'message';
}

interface State {}

class DocumentsPage extends Component<Props, State> {
  componentDidMount() {
    statisticStore.getDocsData([this.props.type]);
  }

  render() {
    const isBusy = statisticStore.isBusyGetDocs;
    const data = statisticStore.docsData;
    document.title = 'Покупки';
    return (
      <>
        <AdaptivePageHeader title={'Покупки'} />
        <Spin spinning={isBusy}>
          <AdaptiveCard>
            <DataGrid
              allowColumnReordering={true}
              dataSource={data}
              loadPanel={{ enabled: false }}
              columnResizingMode={'widget'}
              columnAutoWidth={true}
              showColumnLines={true}
              columnMinWidth={30}
              showRowLines={true}
              height={'calc(100vh - 130px)'}>
              <GroupPanel visible={true} />
              <Paging enabled={true} defaultPageSize={50} />
              <Pager
                allowedPageSizes={[25, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
                infoText={'Всего: {2}'}
              />
              <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'documentsPage'} />
              <FilterPanel visible={true} />
              <HeaderFilter visible={true} allowSearch={true} />
              <Column allowFixing={true} dataField={'name'} caption={'Название'} cssClass={'v-align-middle'} />
              <Column allowFixing={true} dataField="companyName" caption={'Компания'} groupIndex={0} cssClass={'v-align-middle'} />
              <Column
                allowFixing={true}
                dataField="date"
                caption={'Месяц'}
                cssClass={'v-align-middle'}
                calculateCellValue={(e) => moment(e.date).format('MMMM YYYY')}
                calculateGroupValue={(e) => moment(e.date).format('MMMM YYYY')}
              />
              <Column allowFixing={true} dataField="dateBegin" caption={'Начало периода'} dataType={'date'} cssClass={'v-align-middle'} />
              <Column allowFixing={true} dataField="dateEnd" caption={'Конец периода'} dataType={'date'} cssClass={'v-align-middle'} />
              <Column
                allowFixing={true}
                dataField="docNumber"
                caption={'Номер документа'}
                dataType={'string'}
                cssClass={'v-align-middle'}
              />
              <Column
                allowFixing={true}
                dataField="createDate"
                caption={'Создан'}
                dataType={'datetime'}
                cssClass={'v-align-middle'}
                //calculateCellValue={(e) => moment(e.createDate).format(GLOBAL_DATETIME_FORMAT)}
              />
              <Column
                allowFixing={true}
                cssClass={'v-align-middle'}
                alignment={'center'}
                dataField="documentId"
                caption={'Скачать'}
                allowGrouping={false}
                allowHeaderFiltering={false}
                allowSearch={false}
                allowSorting={false}
                cellRender={(e) => {
                  return <DocumentButton data={e.data} />;
                }}
              />
            </DataGrid>
          </AdaptiveCard>
        </Spin>
      </>
    );
  }
}

export default observer(DocumentsPage);
