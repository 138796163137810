import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Modal, ModalProps, Row, Spin, Typography } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import React, { Component } from 'react';

import FloatDatePicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';

import { HoldModel, HoldUpdateModel } from '@stores/holdsStore/data';
import holdsStoreService from '@stores/holdsStore/holdsStore.service';

@observer
export default class HoldsForm extends Component<HoldsFormProps, HoldsFormState> {
  formRef: FormInstance;

  constructor(props: HoldsFormProps) {
    super(props);
    this.state = {
      data: new HoldUpdateModel(this.props.value),
      loading: false,
    };
  }

  get isListEdit() {
    return this.props.value.length > 1;
  }

  /*#region Handlers */

  onFinish = (event: HoldUpdateModel) => {
    this.setState({ loading: true });
    event.loadRegistryIds = this.state.data.loadRegistryIds;

    holdsStoreService
      .updateHoldsList(event)
      .then(() => {
        this.props.onFinish();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  /*#endregion */

  render() {
    const data = this.state.data;
    const isListEdit = this.isListEdit;
    const { isBusy, onCancel } = this.props;
    const title = `Редактирование ${this.isListEdit ? 'группы' : ''}`;

    const getInitialValues = () => {
      const values = { ...data };
      for (const [key, value] of Object.entries(data)) {
        if (key.toLowerCase().includes('date') && value && typeof value !== 'boolean') {
          values[key] = moment(new Date(value));
        }
      }
      return values;
    };

    const createFormRef = (ref: any) => {
      this.formRef = ref;
    };

    /*#region Renderers */
    const changeCheckbox = (name: string, label: string) => {
      const onChange = () => {
        this.forceUpdate();
      };

      return (
        <Form.Item style={{ display: isListEdit ? null : 'none' }} name={name} valuePropName={'checked'}>
          <Checkbox onChange={onChange}>{label}</Checkbox>
        </Form.Item>
      );
    };
    /*#endregion*/

    return (
      <Modal className={'holds-edit-form-modal'} centered={true} open={true} onCancel={onCancel} footer={null} title={title} width={1150}>
        <Spin spinning={isBusy || this.state.loading}>
          <Form
            className={'holds-edit-form'}
            ref={createFormRef}
            layout="vertical"
            initialValues={getInitialValues()}
            onFinish={this.onFinish}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Typography.Title level={5}>Рейс под погрузку</Typography.Title>

                {changeCheckbox('changeLoadStationHoldStartDate', 'Изменить дату начала простоя на ст. погрузки')}
                <Form.Item name={'loadStationHoldStartDate'}>
                  <FloatDatePicker
                    disabled={
                      this.formRef
                        ? !this.formRef.getFieldValue('changeLoadStationHoldStartDate')
                        : !this.state.data['changeLoadStationHoldStartDate']
                    }
                    disabledDate={(currentDate) =>
                      moment(currentDate).startOf('day') >
                      moment(this.formRef ? this.formRef.getFieldValue('loadSendDate') : this.state.data['loadSendDate'])?.startOf('day')
                    }
                    placeholder={'Дата начала простоя'}
                    clearIcon={<CloseOutlined />}
                  />
                </Form.Item>

                {changeCheckbox('changeLoadSendDate', 'Изменить дату погрузки на ст. погрузки')}
                <Form.Item name={'loadSendDate'}>
                  <FloatDatePicker
                    disabled={this.formRef ? !this.formRef.getFieldValue('changeLoadSendDate') : !this.state.data['changeLoadSendDate']}
                    disabledDate={(currentDate) =>
                      moment(currentDate).startOf('day') <
                      moment(
                        this.formRef ? this.formRef.getFieldValue('loadStationHoldStartDate') : this.state.data['loadStationHoldStartDate'],
                      )?.startOf('day')
                    }
                    placeholder={'Дата погрузки'}
                    clearIcon={<CloseOutlined />}
                  />
                </Form.Item>

                {changeCheckbox('changeLoadHoldSurchargeNorm', 'Изменить норматив за простой')}
                <Form.Item name={'loadHoldSurchargeNorm'}>
                  <FloatInputNumber
                    disabled={
                      this.formRef
                        ? !this.formRef.getFieldValue('changeLoadHoldSurchargeNorm')
                        : !this.state.data['changeLoadHoldSurchargeNorm']
                    }
                    placeholder={'Норматив за простой'}
                    min={0}
                  />
                </Form.Item>

                {changeCheckbox('changeLoadHoldTimeNorm', 'Изменить норматив времени простоя')}
                <Form.Item name={'loadHoldTimeNorm'}>
                  <FloatInputNumber
                    disabled={
                      this.formRef ? !this.formRef.getFieldValue('changeLoadHoldTimeNorm') : !this.state.data['changeLoadHoldTimeNorm']
                    }
                    placeholder={'Норматив времени'}
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Typography.Title level={5}>Рейс после выгрузки</Typography.Title>

                {changeCheckbox('changeDestStationHoldStartDate', 'Изменить дату начала простоя на ст. выгрузки')}
                <Form.Item name={'destStationHoldStartDate'}>
                  <FloatDatePicker
                    disabled={
                      this.formRef
                        ? !this.formRef.getFieldValue('changeDestStationHoldStartDate')
                        : !this.state.data['changeDestStationHoldStartDate']
                    }
                    disabledDate={(currentDate) =>
                      moment(currentDate).startOf('day') >
                      moment(
                        this.formRef ? this.formRef.getFieldValue('destStationHoldEndDate') : this.state.data['destStationHoldEndDate'],
                      ).startOf('day')
                    }
                    placeholder={'Дата начала простоя'}
                    clearIcon={<CloseOutlined />}
                  />
                </Form.Item>

                {changeCheckbox('changeDestStationHoldEndDate', 'Изменить дату окончания простоя на ст. выгрузки')}
                <Form.Item name={'destStationHoldEndDate'}>
                  <FloatDatePicker
                    disabled={
                      this.formRef
                        ? !this.formRef.getFieldValue('changeDestStationHoldEndDate')
                        : !this.state.data['changeDestStationHoldEndDate']
                    }
                    disabledDate={(currentDate) =>
                      moment(currentDate).startOf('day') <
                      moment(
                        this.formRef ? this.formRef.getFieldValue('destStationHoldStartDate') : this.state.data['destStationHoldStartDate'],
                      ).startOf('day')
                    }
                    placeholder={'Дата окончания простоя'}
                    clearIcon={<CloseOutlined />}
                  />
                </Form.Item>

                {changeCheckbox('changeDestHoldSurchargeNorm', 'Изменить норматив за простой')}
                <Form.Item name={'destHoldSurchargeNorm'}>
                  <FloatInputNumber
                    disabled={
                      this.formRef
                        ? !this.formRef.getFieldValue('changeDestHoldSurchargeNorm')
                        : !this.state.data['changeDestHoldSurchargeNorm']
                    }
                    placeholder={'Норматив за простой'}
                    min={0}
                  />
                </Form.Item>

                {changeCheckbox('changeDestHoldTimeNorm', 'Изменить норматив времени простоя')}
                <Form.Item name={'destHoldTimeNorm'}>
                  <FloatInputNumber
                    disabled={
                      this.formRef ? !this.formRef.getFieldValue('changeDestHoldTimeNorm') : !this.state.data['changeDestHoldTimeNorm']
                    }
                    placeholder={'Норматив времени'}
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Typography.Title level={5}>Общее</Typography.Title>

                {changeCheckbox('changeComment', 'Изменить комментарий')}
                <Form.Item name={'comment'}>
                  <FloatInput
                    disabled={this.formRef ? !this.formRef.getFieldValue('changeComment') : !this.state.data['changeComment']}
                    placeholder={'Комментарий'}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ marginLeft: 'auto' }}>
              <Button htmlType="submit" style={{ marginRight: 10 }}>
                Сохранить
              </Button>
              <Button htmlType="button" onClick={onCancel} danger>
                Отменить
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

/*#region Typing*/
interface HoldsFormProps extends ModalProps {
  isBusy: boolean;
  value: Array<HoldModel>;
  onCancel: () => void;
  onFinish: () => void;
}

interface HoldsFormState {
  data: HoldUpdateModel;
  loading: boolean;
}

/*#endregion*/
