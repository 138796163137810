import { Tag } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';

import { supplierBidRegistryStore } from '@stores/supplierBidRegistryStore';

import { SupplierBidRegistryCreateHeaderProps as Props } from './header.types';

export const SupplierBidRegistryCreateHeader = observer(({ isEdit }: Props) => {
  const { supplierBidRegistry } = supplierBidRegistryStore;

  return (
    <AdaptivePageHeader
      title={isEdit ? 'Редактирование ставки' : 'Создание ставки'}
      tags={
        supplierBidRegistry && supplierBidRegistry.state ? (
          <Tag color="blue" style={{ marginLeft: 4 }}>
            {SUPPLIER_BID_REGISTRY_STATUS_LIST[supplierBidRegistry.state]}
          </Tag>
        ) : (
          []
        )
      }
    />
  );
});

const SUPPLIER_BID_REGISTRY_STATUS_LIST = {
  onagr: 'На исполнении',
  draft: 'Черновик',
  agr: 'Согласована',
};
