import { Checkbox, CheckboxProps } from 'antd';
import React, { Component } from 'react';

import { InputPosition } from '@stores/designStore/designData';

import BorderWrapper from './BorderWrapper';
import styles from './FloatLabel.module.scss';

interface State {
  focus: boolean;
}

interface Props extends CheckboxProps {
  position?: InputPosition;
}

class FloatCheckbox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      focus: false,
    };
  }

  render() {
    const { position } = this.props;
    return (
      <BorderWrapper
        disabled={this.props.disabled}
        className={styles.floatlabel}
        position={position}
        onFocusChange={(e) => this.setState({ focus: e })}>
        <div style={{ marginLeft: 10, marginTop: 12 }}>
          <Checkbox {...this.props}>{this.props.children}</Checkbox>
        </div>
      </BorderWrapper>
    );
  }
}
export default FloatCheckbox;
