import { useMemo } from 'react';

import { TariffEscortJsonModel } from '@stores/historyTariffInfo/historyTariffInfoStore';

export const escortTypes = [
  { type: 'conductor', name: 'Проводник' },
  { type: 'heaterWagon', name: 'Теплушка' },
  { type: 'passengerWagon', name: 'Пассажирский (для проводников)' },
  { type: 'coverWagon', name: 'Вагон прикрытия' },
];

export const countries = [
  { name: 'Азербайджан', code: '07' },
  { name: 'Армения', code: '08' },
  { name: 'Беларусь', code: '02' },
  { name: 'Грузия', code: '06' },
  { name: 'Казахстан', code: '04' },
  { name: 'Киргизия', code: '11' },
  { name: 'Латвия', code: '13' },
  { name: 'Литва', code: '12' },
  { name: 'Молдавия', code: '05' },
  { name: 'Россия', code: '01' },
  { name: 'Таджикистан', code: '16' },
  { name: 'Туркменистан', code: '10' },
  { name: 'Узбекистан', code: '09' },
  { name: 'Украина', code: '03' },
  { name: 'Эстония', code: '14' },
];

export function getInitialEscortModel(type: string) {
  if (type === 'conductor') {
    return { type: type, affiliationCode: undefined, countryCode: undefined, axelCount: undefined };
  }

  return { type: type, affiliationCode: 0, countryCode: '01', axelCount: 4 };
}

export function useValueMap(value: TariffEscortJsonModel[]) {
  return useMemo(
    () =>
      value.reduce((acc, cur, index) => {
        acc[cur.type] = { ...cur, index };
        return acc;
      }, {}),
    [value],
  );
}
