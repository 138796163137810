import { LeftOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { CompanyCatalogModel } from '@stores/companyCatalogStore';
import companyCatalogStore from '@stores/companyCatalogStore/companyCatalogStore';
import companyStore from '@stores/companyStore/companyStore';

import CompanyCatalogCreator from './CompanyCatalogCreator';
import CompanyCatalogList from './CompanyCatalogList';

interface CompanyCatalogEditorProps {
  companyId: string;
}

interface CompanyCatalogEditorState {
  editMode: boolean;
  editedCompanyCatalog: CompanyCatalogModel;
}

class CompanyCatalogEditor extends Component<CompanyCatalogEditorProps, CompanyCatalogEditorState> {
  constructor(props: CompanyCatalogEditorProps) {
    super(props);

    this.state = {
      editMode: false,
      editedCompanyCatalog: null,
    };
  }

  componentDidMount(): void {
    companyCatalogStore.getCompanyCatalogs(this.props.companyId);
    companyStore.loadBillingProfiles(this.props.companyId);
  }

  render() {
    const isBusy = companyCatalogStore.isBusy;
    const companyCatalogs = companyCatalogStore.companyCatalogs;
    const editMode = this.state.editMode;
    const editedCompanyCatalog = this.state.editedCompanyCatalog;
    return (
      <div>
        {editMode ? (
          <>
            <h3>
              <LeftOutlined
                style={{ cursor: 'pointer', marginRight: 5 }}
                onClick={() => this.setState({ editMode: false, editedCompanyCatalog: null })}
              />{' '}
              Подразделения
            </h3>
          </>
        ) : (
          <h3>Подразделения</h3>
        )}
        {editMode ? (
          <CompanyCatalogCreator onFinish={this.onFormFinish} companyCatalog={editedCompanyCatalog} isBusy={isBusy} />
        ) : (
          <CompanyCatalogList
            companyCatalogs={companyCatalogs}
            isBusy={isBusy}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
            onAddNew={this.onAddNew}
          />
        )}
      </div>
    );
  }

  onFormFinish = (e) => {
    const newCompanyCatalog = {
      id: null,
      companyId: this.props.companyId,
      title: e.title,
      type: e.type,
      description: e.description,
      foreignId: e.foreignId,
      sourceName: e.sourceName,
      billingProfileId: e.billingProfileId,
    };
    if (this.state.editedCompanyCatalog) {
      newCompanyCatalog.id = this.state.editedCompanyCatalog.id;
      companyCatalogStore.saveCompanyCatalogs(newCompanyCatalog, newCompanyCatalog.companyId).then(() => {
        this.setState({
          editMode: false,
          editedCompanyCatalog: null,
        });
      });
    } else {
      companyCatalogStore.addCompanyCatalogs(newCompanyCatalog, this.props.companyId).then(() => {
        this.setState({
          editMode: false,
          editedCompanyCatalog: null,
        });
      });
    }
  };

  onDelete = (companyCatalog: CompanyCatalogModel) => {
    companyCatalogStore.deleteCompanyCatalogs(companyCatalog.id, this.props.companyId);
  };

  onEdit = (companyCatalog: CompanyCatalogModel) => {
    this.setState({
      editedCompanyCatalog: companyCatalog,
      editMode: true,
    });
  };

  onAddNew = () => {
    this.setState({
      editMode: true,
    });
  };
}

export default observer(CompanyCatalogEditor);
