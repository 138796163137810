import moment from 'moment';

export interface OrganizationRequisites {
  inn: string;
  kpp: string;
  okpo: string;
  ogrn: string;

  shortNameReq: string;
  fullNameReq: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;

  okfs: string;
  okogu: string;
  okopf: string;
  oktmo: string;
  okato: string;

  orgBill: string;
  country: string;
  addressMail: string;
  addressFact: string;
  view: string;
  group: string;

  bankBik: string;
  bankName: string;
  bankAddress: string;
  bankCorBill: string;
  signer: string;
  post: string;
  reason: string;
  attorney: string;
  glbuh: string;
}

export interface OrganizationSearchRequisites {
  name: string;
  inn: string;
}

export interface OrganizationBank {
  country: string;
  addressMail: string;
  addressFact: string;
  view: string;
  group: string;
  bankBik: string;
  bankName: string;
  bankAddress: string;
  bankCorBill: string;
}

export interface BankSearchRequisites {
  bik: string;
  name: string;
}

export interface BankRequisites {
  bankBik: string;
  bankName: string;
  bankAddress: string;
  bankCorBill: string;
  orgBill?: any[];
}

export interface OrganizationTypeModel {
  code: string;
  name: string;
}

export interface OkpoModel {
  okpoId: string;
  shortName: string;
  fullName: string;
  okpo: string;
  localCode: number | null;
  inn: string;
  kpp: string;
  type: string;
}

export interface OrganizationWithRequisitesModel {
  organizationId: string | null;
  name: string;

  /**Поле для фронтэнда не редактируется */
  userModifyId: string;
  organizationTypeCode: string;
  organizationTypeName: string;

  companyId: string | null;
  companyName: string;

  /** region Реквизиты */
  inn: string;
  kpp: string;
  okpo: string;
  ogrn: string;

  shortNameReq: string;
  fullNameReq: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;

  okfs: string;
  okogu: string;
  okopf: string;
  oktmo: string;
  okato: string;

  orgBill: string;
  country: string;
  addressMail: string;
  addressFact: string;
  view: string;
  group: string;
  bankBik: string;
  bankName: string;
  bankAddress: string;
  bankCorBill: string;

  signer: string;
  post: string;

  reason: string;
  attorney: string;
  glbuh: string;
  /** #endregion */
}

export interface SupplierModel {
  supplierId: string;
  name: string;
}

export interface SupplierRequisitesModel {
  supplierId: string | null;
  name: string;

  /** region Реквизиты **/
  inn: string;
  kpp: string;
  okpo: string;
  ogrn: string;

  shortNameReq: string;
  fullNameReq: string;
  address: string;
  telephone: string;
  email: string;
  owner: string;

  okfs: string;
  okogu: string;
  okopf: string;
  oktmo: string;
  okato: string;

  orgBill: string;
  country: string;
  addressMail: string;
  addressFact: string;
  view: string;
  group: string;
  bankBik: string;
  bankName: string;
  bankAddress: string;
  bankCorBill: string;

  signer: string;
  post: string;

  reason: string;
  attorney: string;
  glbuh: string;
  /** #endregion **/
}

export interface TemplateOrgContractModel {
  templateMetadataId: string;
  organizationId: string;
  beginDate: any;
  endDate: any;
  statusId: string;
  createDate: any;
  docTypeId: string;
  docTypeName: string;
  name: string;
  address: string;
  contractNumber: string;
  foreignId: string;
  exts: TemplateOrgExtContractModel[];
  signers?: string[];
  prolongationIsAuto: boolean;
  commercialOfferId?: string;
  conditionDowntime?: string;
  downtimeCalculation?: string;
  appendixes?: TemplateOrgContractAppendixModel[];
}

export interface TemplateOrgContractAppendixModel {
  /**
   * Идентификатор договора к кторому привязано приложение (можно не передавать с фронта)
   */
  templateMetadataId?: string;
  /**
   * Тип приложения
   */
  type: TemplateOrgContractAppendixType;
  /**
   * Дополнительные параметры в виде json. На текущий момент есть только у типа Downtime
   */
  data?: string;
}

export type TemplateOrgContractAppendixType = 'downtime' | 'priceAgreeing' | 'priceAgreeingShort' | 'claimForm';

export interface TemplateOrgExtContractModel extends TemplateOrgContractModel {
  contractId: string;
  extContractNumber: string;
  extTypeCode: ExtTypeCode;
  dealSide: 'orgcompany' | 'companyorg';
  wagonParams: TemplateOrgExtContractParamModel[];
  expeditionParams: TemplateOrgExpeditionExtParam[];
  rentParams: TemplateOrgRentExtParamModel;
  extraServicesParams: TemplateOrgExtContractExtraServicesModel[];
  prolongationIsAuto: boolean;
  downtimeParams: AppendixDowntimeData[];
}

export interface TemplateOrgRentExtParamModel {
  paramId?: string;
  catalogId: string;
  templateMetadataId?: string;
  type: 'wagon' | 'container';
  price: number;
  withExclusion: boolean;
  changeStationCode: string;
}

export interface TemplateOrgExpeditionExtParam {
  /// Уникальный идентификатор
  expeditorSettingId: string;
  /// Идентификатор допника
  templateMetadataId: string;
  //код страны
  countryCode: number;

  countryName: string;
  /// Идентификатор организации
  organizationId: string;

  organizationName?: string;

  /// Процент экспедитора
  expeditorPercentage: number;

  /// В т.ч. доп сборы (если установлен - брать в рассчет Провозная плата - иначе Плата тариф)
  freightChargeAvailable: boolean;
  extraAmount: number;
  currencyCode: string;
  currencyName: string;
  ndsPercentage: number;
  periodFrom: any;
  periodTo: any;
  convertedAmount: number;
  disabled?: boolean;
  defaultUse?: boolean;
  paramId?: string;
}

export interface TemplateOrgExtContractParamModel {
  templateMetadataId: number;
  wagonTypeCode: number;
  wagonTypeName: string;
  loadStationCode: string;
  loadStationName: string;
  destStationCode: string;
  destStationName: string;
  etsng: string;
  etsngName: string;
  gng: string;
  gngName: string;
  priceWithoutNds: number;
  nds: number;
  priceWithNds: number;
  paramId?: string;
}

export interface TemplateOrgExtContractExtraServicesModel {
  paramId?: string;
  templateMetadataId?: string;
  serviceId: number;
  serviceName: string;
  price: number;
  wagonTypeCode: number;
  wagonTypeName: string;
}

export interface AppendixDowntimeData {
  /**РПС (род подвижного состава)	Выбор из справочника РПС, который используется в Заявках ИЛСИ	not null
   *
   */
  rps: string;

  /**Норматив простоя под погрузкой, сут.	Целое число, не может быть меньше 1	nullable
   *
   */
  loadNormDays?: number;

  /**Норматив простоя под выгрузкой, сут.	Целое число, не может быть меньше 1	nullable
   *
   */
  destNormDays?: number;

  /**Норматив простоя под сдвоенной операцией, сут.	Целое число, не может быть меньше 1	nullable
   *
   */
  doubleOperationNormDays?: number;

  /**Стоимость сверхнормативного простоя, руб./сут.	Деньги, не может быть меньше 0
   *
   */
  price?: number;
}

export type ExtTypeCode = 'wagons' | 'expedition' | 'rent' | 'rentBack' | 'downtime';

export interface TemplateOrgExtContractModelForm extends TemplateOrgContractModel {
  contractId: string;
  extContractNumber: string;
  extTypeCode: ExtTypeCode;
  wagonParams: TemplateOrgExtContractParamModelForm[];
  expeditionParams: TemplateOrgExpeditionExtParam[];
  rentParams: TemplateOrgRentExtParamModel;
  extraServicesParams: TemplateOrgExtContractExtraServicesModel[];
  prolongationIsAuto: boolean;
  appendixTypes: TemplateOrgContractAppendixType[];
  downtimeDataList: AppendixDowntimeData[];
}

export const defaultTemplateOrgExtContractModelForm: TemplateOrgExtContractModelForm = {
  templateMetadataId: null,
  organizationId: null,
  beginDate: null,
  endDate: null,
  statusId: null,
  createDate: null,
  docTypeId: null,
  docTypeName: null,
  name: null,
  address: null,
  contractNumber: null,
  foreignId: null,
  prolongationIsAuto: false,
  exts: [],
  contractId: null,
  extContractNumber: null,
  extTypeCode: null,
  wagonParams: [],
  expeditionParams: [],
  extraServicesParams: [],
  rentParams: {
    catalogId: null,
    templateMetadataId: null,
    type: 'wagon',
    price: null,
    withExclusion: false,
    changeStationCode: null,
  },
  conditionDowntime: null,
  downtimeCalculation: null,
  appendixTypes: [],
  downtimeDataList: [],
  appendixes: [],
};

export interface TemplateOrgExtContractParamModelForm {
  wagonTypeCode: number;
  wagonTypeName: string;
  loadStation: { stationCode: string; fullName: string };
  destStation: { stationCode: string; fullName: string };
  etsngModel: { freightEtsngCode: string; fullName: string };
  gngModel: { gngCode: string; fullName: string };
  priceWithoutNds: number;
  nds: number;
  priceWithNds: number;
}

export function templateOrgFormDataToModel(e: TemplateOrgExtContractModelForm): TemplateOrgExtContractModel {
  return {
    foreignId: e.foreignId,
    prolongationIsAuto: e.prolongationIsAuto,
    templateMetadataId: e.templateMetadataId,
    organizationId: e.organizationId,
    statusId: e.statusId,
    exts: e.exts,
    contractNumber: e.contractNumber,
    name: e.name,
    beginDate: e.beginDate ? moment(e.beginDate).toISOString(true) : null,
    endDate: e.endDate ? moment(e.endDate).toISOString(true) : null,
    createDate: e.createDate,
    docTypeId: e.docTypeId,
    contractId: e.contractId,
    docTypeName: e.docTypeName,
    address: e.address,
    extContractNumber: e.extContractNumber,
    extTypeCode: e.extTypeCode,
    commercialOfferId: e.commercialOfferId,
    dealSide: e.extTypeCode === 'expedition' ? 'orgcompany' : 'companyorg',
    rentParams: e.rentParams,
    expeditionParams: e.expeditionParams
      ? e.expeditionParams.map((p) => {
          return {
            ...p,
            periodFrom: p.periodFrom ? moment(p.periodFrom).toISOString(true) : null,
            periodTo: p.periodTo ? moment(p.periodTo).toISOString(true) : null,
          };
        })
      : [],
    wagonParams: e.wagonParams
      ? e.wagonParams.map((p) => {
          return {
            templateMetadataId: null,
            wagonTypeCode: p.wagonTypeCode,
            wagonTypeName: p.wagonTypeName,
            loadStationCode: p.loadStation ? p.loadStation.stationCode : null,
            loadStationName: p.loadStation ? p.loadStation.fullName : null,
            destStationCode: p.destStation ? p.destStation.stationCode : null,
            destStationName: p.destStation ? p.destStation.fullName : null,
            etsng: p.etsngModel ? p.etsngModel.freightEtsngCode : null,
            etsngName: p.etsngModel ? p.etsngModel.fullName : null,
            gng: p.gngModel ? p.gngModel.gngCode : null,
            gngName: p.gngModel ? p.gngModel.fullName : null,
            priceWithoutNds: p.priceWithoutNds,
            nds: p.nds,
            priceWithNds: p.priceWithNds,
          };
        })
      : [],
    extraServicesParams: e.extraServicesParams ? e.extraServicesParams : [],
    conditionDowntime: e.conditionDowntime,
    downtimeCalculation: e.downtimeCalculation,
    appendixes: e.appendixTypes?.map((ap) => {
      if (ap === 'downtime') {
        return { templateMetadataId: null, type: 'downtime', data: JSON.stringify(e.downtimeDataList) };
      } else {
        return { templateMetadataId: null, type: ap, data: null };
      }
    }),
    downtimeParams: e.downtimeDataList,
  };
}

export function templateOrgModelToFormData(e: TemplateOrgExtContractModel): TemplateOrgExtContractModelForm {
  return {
    foreignId: e.foreignId,
    prolongationIsAuto: e.prolongationIsAuto,
    templateMetadataId: e.templateMetadataId,
    organizationId: e.organizationId,
    statusId: e.statusId,
    exts: e.exts,
    contractNumber: e.contractNumber,
    name: e.name,
    beginDate: e.beginDate ? moment(e.beginDate) : null,
    endDate: e.endDate ? moment(e.endDate) : null,
    createDate: e.createDate,
    docTypeId: e.docTypeId,
    contractId: e.contractId,
    docTypeName: e.docTypeName,
    address: e.address,
    extContractNumber: e.extContractNumber,
    extTypeCode: e.extTypeCode,
    rentParams: e.rentParams,
    commercialOfferId: e.commercialOfferId,
    expeditionParams: e.expeditionParams
      ? e.expeditionParams.map((p) => {
          return {
            ...p,
            periodFrom: p.periodFrom ? moment(p.periodFrom) : null,
            periodTo: p.periodTo ? moment(p.periodTo) : null,
          };
        })
      : [],

    wagonParams: e.wagonParams
      ? e.wagonParams.map((p) => {
          return {
            wagonTypeCode: p.wagonTypeCode,
            wagonTypeName: p.wagonTypeName,
            loadStation: { stationCode: p.loadStationCode, fullName: p.loadStationName },
            destStation: { stationCode: p.destStationCode, fullName: p.destStationName },
            etsngModel: { freightEtsngCode: p.etsng, fullName: p.etsngName },
            gngModel: p.gng ? { gngCode: p.gng, fullName: p.gngName } : null,
            priceWithoutNds: p.priceWithoutNds,
            nds: p.nds,
            priceWithNds: p.priceWithNds,
          };
        })
      : [],
    extraServicesParams: e.extraServicesParams ? e.extraServicesParams : [],
    conditionDowntime: e.conditionDowntime,
    downtimeCalculation: e.downtimeCalculation,
    appendixTypes: e.appendixes?.map((a) => a.type),
    downtimeDataList: e.downtimeParams ?? JSON.parse(e.appendixes?.find((ap) => ap.type === 'downtime')?.data ?? '[]'),
  };
}
