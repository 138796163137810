import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import React, { Component } from 'react';

import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';

import { CompanyBankAccountModel } from '@stores/bankStore/BankData';
import { CompanyBankService } from '@stores/bankStore/companyBank.service';
import { SupplierBankService } from '@stores/bankStore/supplierBank.service';
import designStore from '@stores/designStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

interface Props {
  data: CompanyBankAccountModel;
  service: CompanyBankService | SupplierBankService;
  parentId: string;
  rcbic: string;
  onChange(): void;
}

interface State {
  isBusy: boolean;
  visible: boolean;
  requiredBalance: boolean;
  requiredBalanceFactDate: boolean;
  data: CompanyBankAccountModel;
}

class AccountEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      requiredBalance: false,
      requiredBalanceFactDate: false,
      isBusy: false,
      visible: false,
      data: null,
    };
  }

  formRef: FormInstance;

  render() {
    const { isBusy, data, requiredBalance, requiredBalanceFactDate } = this.state;
    const { rcbic } = this.props;
    if (data) data.balanceFactDate = data.balanceFactDate ? moment(data.balanceFactDate) : null;
    const visible = this.state.visible;
    return (
      <>
        {this.isEditMode ? (
          <Button onClick={this.openDrawer} icon={<EditOutlined />} title={'Изменить'} size={'small'} type={'text'} />
        ) : (
          <Button onClick={this.openDrawer} title={'Создать'} type={'text'} size={'small'}>
            добавить счет
          </Button>
        )}
        <Drawer
          width={designStore.isMobile ? '100%' : '26%'}
          title={this.isEditMode ? 'Редактирование счета' : 'Создание счета'}
          onClose={this.handelClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}>
              <Button onClick={this.handelClose} style={{ marginRight: 8 }} size={'middle'}>
                Отмена
              </Button>
              <Button type="primary" loading={isBusy} htmlType={'submit'} form={'bank-account-editor'} size={'middle'}>
                {this.isEditMode ? 'Сохранить' : 'Добавить'}
              </Button>
            </div>
          }>
          {visible && (
            <Form
              layout="vertical"
              size={'large'}
              name={'bank-account-editor'}
              ref={(ref) => (this.formRef = ref)}
              onFinish={(e) => this.handleConfirm(e)}
              initialValues={data}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item
                    rules={[
                      { required: true, message: '' },
                      () => ({
                        validator(rule, value) {
                          let bic = Number(rcbic);
                          if (typeof value === 'number') {
                            value = value.toString();
                          } else if (typeof value !== 'string') {
                            value = '';
                          }
                          if (!value.length) {
                            return Promise.reject('Р/С пуст');
                          } else if (/[^0-9]/.test(value)) {
                            return Promise.reject('Р/С может состоять только из цифр');
                          } else if (value.length !== 20) {
                            return Promise.reject('Р/С может состоять только из 20 цифр');
                          } else {
                            let bicRs = bic.toString().slice(-3) + value;
                            let checksum = 0;
                            let coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
                            for (let i in coefficients) {
                              checksum += coefficients[i] * (Number(bicRs[i]) % 10);
                            }
                            if (checksum % 10 === 0) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject('Неправильное контрольное число');
                            }
                          }
                        },
                      }),
                    ]}
                    name={'account'}>
                    <FloatInput placeholder={'Расчетный счет'} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item name={'name'} rules={[{ required: true, message: 'Укажите наименование счета' }]}>
                    <FloatInput placeholder={'Наименование счета'} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name={'balance'}
                    rules={[
                      {
                        required: requiredBalance,
                        message: '',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!!getFieldValue('balanceFactDate') && !!value) {
                            return Promise.resolve();
                          } else if (!!getFieldValue('balanceFactDate') && value == null) {
                            return Promise.reject('Пожалуйста, введите баланс счета');
                          } else if (getFieldValue('balanceFactDate') == null && value == null) {
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}>
                    <FloatInputNumber
                      placeholder={'Баланс счета'}
                      max={99999999999999}
                      min={-99999999999999}
                      step={0.01}
                      onChange={(e) => this.setState({ requiredBalanceFactDate: !!e })}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name={'balanceFactDate'}
                    rules={[
                      {
                        required: requiredBalanceFactDate,
                        message: '',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!!getFieldValue('balance') && !!value) {
                            return Promise.resolve();
                          } else if (!!getFieldValue('balance') && value == null) {
                            return Promise.reject('Пожалуйста, введите баланс на дату');
                          } else if (getFieldValue('balance') == null && value == null) {
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}>
                    <FloatDatepicker
                      placeholder={'Баланс на дату'}
                      format={GLOBAL_DATE_FORMAT}
                      onChange={(e) => this.setState({ requiredBalance: !!e })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item valuePropName={'checked'} name={'isMain'}>
                    <FloatCheckbox disabled={this.props.data && this.props.data.isMain}>Основной</FloatCheckbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Drawer>
      </>
    );
  }

  get isEditMode(): boolean {
    return this.props.data && !!this.props.data.bankAccountId;
  }

  openDrawer = () => {
    this.setState(
      {
        visible: true,
        data: this.props.data ? this.props.data : { isMain: true, balanceFactDate: null, name: null, account: null, balance: null },
      },
      () => {
        if (this.formRef) this.formRef.resetFields();
      },
    );
  };

  handelClose = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: false });
  };

  async handleConfirm(e) {
    this.setState({ isBusy: true });
    if (e.balanceFactDate) e.balanceFactDate = moment(e.balanceFactDate).toISOString(true);
    else e.balanceFactDate = null;
    try {
      if (this.isEditMode) {
        e.bankAccountId = this.props.data.bankAccountId;
        await this.props.service.updateBankAccount(e);
      } else await this.props.service.addBankAccount(this.props.parentId, e);
      this.props.onChange();
      this.handelClose();
    } catch (e) {
    } finally {
      this.setState({ isBusy: false });
    }
  }
}

export default AccountEditor;
