import React from 'react';

import { Flex } from '@components/fw/Flex';
import ClaimTariffDetailEditor from '@components/routed/ClaimInfo/commercial/ClaimTariffDetailEditor';

import { BidRegistryCommercialStore } from '@stores/bidRegistryCommercialStore';
import { Tariff } from '@stores/bidRegistryCommercialStore/bidRegistryCommercialStore.types';

export const renderTitle = (tariff) => () => (
  <Flex justify="space-between" align="center">
    {tariff.pathDetails[0].stationName} - {tariff.pathDetails[tariff.pathDetails.length - 1].stationName}
    <ClaimTariffDetailEditor
      onChange={async (result) => {
        BidRegistryCommercialStore.changeTariffDetail(tariff.userTariffRequestId, result);
      }}
      tariffId={tariff.userTariffRequestId}
    />
  </Flex>
);
