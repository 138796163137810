import { Divider, Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import { Flex } from '@components/fw/Flex';

import { BidRegistryCommercialStore, WagonProvision } from '@stores/bidRegistryCommercialStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import { COLUMNS } from './lib/columns';

export const DirectionsTable = observer(() => {
  const { sortAllRollingStock } = BidRegistryCommercialStore;
  const { bidRegistry } = registerOfBetsStore;
  const Title = ({ freight }: { freight: string }) => {
    return (
      <Flex vertical justify="center">
        {bidRegistry.rps}
        <Divider style={{ margin: 0 }} />
        {freight}
      </Flex>
    );
  };

  return (
    <>
      {Object.entries(sortAllRollingStock).map(([freight, wagonProvision]) => {
        return (
          <AdaptiveCard hoverable key={freight} title={<Title freight={freight} />}>
            <Table
              rowKey={Math.random}
              bordered
              pagination={false}
              columns={COLUMNS}
              dataSource={wagonProvision as WagonProvision[]}
              scroll={{ x: true }}
            />
          </AdaptiveCard>
        );
      })}
    </>
  );
});
