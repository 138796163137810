import { CheckBox } from 'devextreme-react/check-box';
import React from 'react';

export default function CheckboxCell(options) {
  const data = options.value;
  if (data === undefined) {
    return;
  }

  return (
    <React.Fragment>
      <CheckBox readOnly={true} defaultValue={data}></CheckBox>
    </React.Fragment>
  );
}
