export const EMPTY_FILTER = 'Без фильтра';
export const DATA_NAME = 'Фильтры';
export const FILTER_NAME = 'Название';
export const FILTER_NAME_ALARM = 'Введите название';
export const ON_REMOVE_ALERT = 'Удалить?';
export const SAVE_TITLE = 'Сохранить';
export const CREATE_NEW_TITLE = 'Создать новый';
export const FLIGHT = 'Рейс';
export const FLIGHTS = 'Рейсы';
export const REPORT = 'Отчет';
export const REPORTS = 'Отчеты';
export const DISPATCHER = 'Диспетчер';
