import config from '@/config';
import { InboxOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Select, Spin, Upload } from 'antd';
import DataGrid, { Column, Export, FilterRow, GroupPanel, HeaderFilter } from 'devextreme-react/data-grid';
import iconv from 'iconv-lite';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import adminBillingStore from '@stores/adminBilling/adminBillingStore';
import { BankOperation } from '@stores/bankStore/BankData';
import companiesStore from '@stores/companiesStore/companiesStore';

import { getAuthority } from '@utils/authority';
import { showErrorNotify, showSuccessNotify } from '@utils/notify';

const { urlApi } = config;

interface Props {
  parentId?: string;
  controller: 'companyBanks' | 'supplierBanks';
}

interface State {
  loadingConfirm: boolean;
  isBusy: boolean;
  visible: boolean;
  parentId: string;
  data: BankOperation[];
}

class FilesLoadModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: true,
      visible: false,
      loadingConfirm: false,
      parentId: props.parentId,
      data: null,
    };
  }

  componentDidMount() {}

  onOpen = async () => {
    this.setState({ visible: true, isBusy: false, parentId: this.props.parentId, data: null });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  setResult = (str: string) => {
    const data: BankOperation[] = JSON.parse(str);
    if (data && data.length) {
      this.setState({ data: data, isBusy: false });
    } else {
      showSuccessNotify('Файл загружен полностью');
      this.onClose();
    }
  };

  render() {
    const parentId = this.state.parentId;
    const isBusy = this.state.isBusy;
    const data = this.state.data;
    let parentIdName = 'id';
    let target = [];
    if (this.props.controller === 'companyBanks') {
      parentIdName = 'companyId';
      target = companiesStore.companies.map((c) => {
        return { name: c.name, id: c.companyId };
      });
    }
    if (this.props.controller === 'supplierBanks') {
      parentIdName = 'supplierId';
      target = adminBillingStore.suppliers.map((s) => {
        return { name: s.name, id: s.supplierId };
      });
    }

    return (
      <>
        <Button size={'small'} onClick={this.onOpen} type={'text'}>
          Загрузить
        </Button>
        <Modal
          maskClosable={false}
          title={'Загрузка банковской выписки'}
          open={this.state.visible}
          okButtonProps={{ style: { display: 'none' } }}
          onCancel={this.onClose}
          width={data ? '80%' : '600px'}>
          {!data ? (
            <Spin spinning={isBusy}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col md={24}>
                  {!this.props.parentId && (
                    <Select
                      dropdownMatchSelectWidth={false}
                      placeholder="Компания"
                      style={{ width: '144px' }}
                      onSelect={(e: string) => this.setState({ parentId: e })}>
                      {target.map((c) => {
                        return (
                          <Select.Option key={c.id} value={c.id}>
                            {c.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                </Col>
                <Col md={24}>
                  {this.state.visible && (
                    <Upload.Dragger
                      accept={'text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                      disabled={!parentId}
                      name={'file'}
                      multiple={false}
                      showUploadList={false}
                      headers={{ Authorization: 'Bearer ' + getAuthority().access_token }}
                      action={(file) => {
                        const type: 'AlfaBankExcel' | 'Exchange1c' = file.type.includes('text') ? 'Exchange1c' : 'AlfaBankExcel';
                        return `${urlApi}/api/${this.props.controller}/accountstatement/upload?type=${type}&${parentIdName}=${parentId}`;
                      }}
                      beforeUpload={(file, files) => {
                        if (!file.type.includes('text')) return null;
                        return file.arrayBuffer().then((ans) => {
                          const str = iconv.decode(Buffer.from(ans), 'windows-1251');
                          return new File([str], file.name, { type: file.type + ';charset=utf-8' });
                        });
                      }}
                      onChange={(info) => {
                        const { status } = info.file;
                        if (status === 'uploading') {
                          this.setState({ isBusy: true });
                        }
                        if (status === 'done') {
                          this.setResult(info.file.xhr.response);
                          this.setState({ isBusy: false });
                        } else if (status === 'error') {
                          this.setState({ isBusy: false });
                          showErrorNotify('Не удалось загрузить файл', info.file.response.Message);
                        }
                      }}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Выберите файл или перетащите его в эту область</p>
                      <p className="ant-upload-hint">Поддерживаются эксель файлы банковских выписок и выписки 1С</p>
                    </Upload.Dragger>
                  )}
                </Col>
              </Row>
            </Spin>
          ) : (
            <div>
              <DataGrid
                rowAlternationEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                dataSource={data}
                showBorders={true}
                loadPanel={{ enabled: false }}>
                <GroupPanel visible={true} />
                <HeaderFilter visible={true} />
                <FilterRow visible={true} />
                <Export enabled={true} />

                <Column allowFixing={true} dataField="message" caption={'Ошибка'} />
                <Column allowFixing={true} dataField="operationDate" caption={'Дата'} dataType={'date'} />
                <Column allowFixing={true} dataField="documentNumber" caption={'Номер'} />
                <Column allowFixing={true} dataField="counterpartyName" caption={'Контрагент'} />
                <Column allowFixing={true} dataField="counterpartyAccount" caption={'Счет'} />
                <Column allowFixing={true} dataField="counterpartyRcbic" caption={'БИК'} />
                <Column allowFixing={true} dataField="credit" caption={'Кредит'} />
                <Column allowFixing={true} dataField="debit" caption={'Дебет'} />
                <Column allowFixing={true} dataField="paymentDetails" caption={'Детали'} />
                <Column allowFixing={true} dataField="operationType" caption={'Операция'} />
              </DataGrid>
            </div>
          )}
        </Modal>
      </>
    );
  }
}

export default observer(FilesLoadModal);
