import AbstractNsiStoreService from './abstractNsiStore.service';

abstract class NsiStoreBase<T, S extends AbstractNsiStoreService<T>> {
  tmpData: T[] = [];
  abstract service: S;
  async load(loadOptions): Promise<any> {
    if (loadOptions.filter) {
      if (Array.isArray(loadOptions.filter[0])) {
        loadOptions.filter.forEach((f) => {
          if (Array.isArray(f) && f[1] === 'contains') {
            f.push('ignorecase');
          }
        });
      } else {
        if (loadOptions.filter[1] === 'contains') {
          loadOptions.filter.push('ignorecase');
        }
      }
    }
    const data = await this.service.getList(loadOptions);
    this.tmpData = data.data;
    return data;
  }

  async update(key, data, keyField: string) {
    let find = this.tmpData.find((d) => d[keyField] === key);
    if (!find) throw 'Данные не нйдены';
    Object.keys(data).forEach((d) => {
      find[d] = data[d];
    });
    //если потребуется перколбас в из модели dx
    const model = find;
    return this.service.update(model);
  }
}

export default NsiStoreBase;
