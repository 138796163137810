import { action, makeObservable, observable } from 'mobx';

import { DistributionModel } from './dictributionData';
import service from './distributionStore.service';

class DistributionStore {
  @observable isBusy: boolean = false;
  @observable items: DistributionModel[] = [];
  @observable emails: string[] = [];

  constructor() {
    makeObservable(this);
  }

  @action async loadData() {
    this.isBusy = true;
    try {
      this.items = await service.getData();
      this.emails = [];
      this.items.forEach((i) => {
        i.emails.forEach((e) => {
          if (!this.emails.find((e2) => e2 == e)) {
            this.emails.push(e);
          }
        });
      });
    } finally {
      this.isBusy = false;
    }
  }

  @action async update(data: DistributionModel) {
    await service.update(data);
    await this.loadData();
  }

  @action async delete(data: DistributionModel) {
    if (!data.distributionId) return;
    await service.delete(data.distributionId);
    await this.loadData();
  }
}

export default new DistributionStore();
