import { Empty } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { RecursiveSvp } from '@stores/requestsStore/RequestsData';

import RecursiveSpvElement from './RecursiveSpvElement';

interface Props {
  content: RecursiveSvp;
}

interface State {
  data: RecursiveSvp;
}

@observer
class RecursiveSvpViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: this.props.content,
    };
  }

  render() {
    const data = this.state.data;
    if (!data || !data.items || !data.items.length) return <Empty />;
    else
      return this.state.data.items.map((svp, index) => (
        <table className={'tariffTable'} style={{ marginTop: index > 0 ? 40 : undefined }}>
          <tbody>
            <RecursiveSpvElement data={svp} />
          </tbody>
        </table>
      ));
  }
}

export default RecursiveSvpViewer;
