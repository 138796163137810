import { Form } from 'antd';
import React from 'react';

import FloatRangepicker from '@components/fw/FloatLabel/FloatRangepicker';

import styles from '../field.module.scss';
import { FieldRangePickerProps as Props } from './field-range-picker.types';

export const FieldRangePicker = ({ name, rules, ...rangePickerProps }: Props) => {
  return (
    <Form.Item className={styles.fieldFlexAuto} name={name} rules={rules}>
      <FloatRangepicker {...rangePickerProps} />
    </Form.Item>
  );
};
