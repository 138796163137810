import qs from 'qs';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ItineraryDataGrid from '@components/fw/Itinerary/ItineraryDataGrid';

interface State {}

class ItineraryWagons extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  render() {
    let filter = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).w;
    if (filter) {
      if (Array.isArray(filter)) {
        document.title = 'Рейсы ' + filter.join(' ');
      } else {
        document.title = 'Рейсы ' + filter;
      }
    } else {
      document.title = 'Рейсы ⬎ Вагоны';
    }
    return (
      <>
        <AdaptivePageHeader title={document.title} />
        <ItineraryDataGrid type={'wagons'} location={this.props.location} />
      </>
    );
  }
}

export default ItineraryWagons;
