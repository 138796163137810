import { DeleteOutlined, DeploymentUnitOutlined, EditOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Input, List, Popconfirm, Select, Spin, Tooltip } from 'antd';
import { Summary } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import UserEditor from '@components/fw/Drawers/UserEditor';
import UserActiveBadge from '@components/fw/UserActiveBadge';

import { UserModel } from '@stores/usersStore';
import usersStore from '@stores/usersStore/usersStore';

interface UsersListState {
  editedUser: UserModel;
  searchStr: string;
  statusFilter: string;
  editedReference: string;
}
const { Search } = Input;
const { Option } = Select;

export const IconText = ({ icon, text }) => (
  <span style={{ marginRight: 20 }}>
    {React.createElement(icon, { style: { marginRight: 4 } })}
    {text}
  </span>
);

class UsersListForUser extends Component<RouteComponentProps, UsersListState> {
  summary: Summary;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      editedUser: null,
      searchStr: '',
      statusFilter: null,
      editedReference: null,
    };
  }

  componentDidMount(): void {
    usersStore.getUsers();
    usersStore.getRoles();
    document.title = 'Пользователи';
  }

  render() {
    return this.userView();
  }

  filter(users: UserModel[], searchStr: string, activeType: string | null) {
    const usersByType = activeType ? users.filter((x) => x.activeType === activeType) : users;

    return searchStr
      ? usersByType.filter(
          (x) =>
            (x.name && x.name.toLowerCase().includes(searchStr.toLowerCase())) ||
            (x.companyName && x.companyName.toLowerCase().includes(searchStr.toLowerCase())) ||
            (x.login && x.login.toLowerCase().includes(searchStr.toLowerCase())) ||
            (x.roleName && x.roleName.toLowerCase().includes(searchStr.toLowerCase())),
        )
      : usersByType;
  }

  userName = (user: UserModel) => {
    if (user.companyName) {
      return `${user.name} (${user.companyName})`;
    } else {
      return user.name;
    }
  };

  handleCreate = () => {
    this.setState({
      editedUser: {
        userId: null,
        login: null,
        password: null,
        name: null,
        email: null,
        roleCode: null,
        roleName: null,
        userAccessList: [],
        roleAccessList: [],
        companyAccessList: [],
        companyId: null,
        companyName: null,
        organizationId: null,
      },
    });
  };

  handleEdit = (user: any) => {
    usersStore.loadingUsers = true;
    usersStore
      .getUser(user.userId)
      .finally(() => (usersStore.loadingUsers = false))
      .then((result) => {
        this.setState({
          editedUser: Object.assign({ ...result }, { companyAccessList: result.companyAccessList || [] }),
        });
      });
  };

  handleRemoveConfirm = (user: any) => {
    usersStore.removeUser(user.userId).then(() => {
      usersStore.getUsers();
      setTimeout(() => this.forceUpdate(), 1000);
    });
  };

  handleUserEditorCancel = () => {
    this.setState({ editedUser: null });
  };

  handleUserEditorConfirm = (user: UserModel) => {
    usersStore.getUsers();
    this.setState({ editedUser: null });
  };

  userView() {
    const editedUser = this.state.editedUser;
    const searchStr = this.state.searchStr;
    const statusFilter = this.state.statusFilter;
    const users = this.filter(usersStore.users, searchStr, statusFilter);
    const loadingUsers = usersStore.loadingUsers;

    return (
      <div style={{ height: 'calc(100vh - var(--header-height))' }}>
        <AdaptiveCard
          title={
            <Button icon={<PlusOutlined />} size={'small'} onClick={this.handleCreate} key={1}>
              Создать
            </Button>
          }
          extra={
            <CollapsedToolbar
              content={[
                <Select
                  onClick={(e) => e.stopPropagation()}
                  key={2}
                  bordered={false}
                  size={'small'}
                  placeholder={'Статус'}
                  allowClear={true}
                  style={{ width: 120 }}
                  onChange={(value: string) => this.setState({ statusFilter: value })}>
                  <Option value={null}>все</Option>
                  <Option value="active">активный</Option>
                  <Option value="walkedAway">отошёл</Option>
                  <Option value="notAvailable">недоступен</Option>
                  <Option value="unknown">неизвестно</Option>
                </Select>,
                <Search
                  onClick={(e) => e.stopPropagation()}
                  key={3}
                  size={'small'}
                  placeholder="Поиск"
                  onSearch={(value) => this.setState({ searchStr: value })}
                  allowClear={true}
                  style={{ width: 200 }}
                />,
              ]}
            />
          }>
          <Spin spinning={loadingUsers}>
            <List
              itemLayout="horizontal"
              dataSource={users}
              renderItem={(item) => (
                <List.Item
                  style={{ paddingRight: 50 }}
                  actions={[
                    <Tooltip title={'Редактировать'}>
                      <EditOutlined onClick={() => this.handleEdit(item)} />
                    </Tooltip>,
                    <Tooltip title={'Удалить'}>
                      <Popconfirm
                        title="Удалить пользователя？"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => this.handleRemoveConfirm(item)}>
                        <DeleteOutlined />
                      </Popconfirm>
                    </Tooltip>,
                  ]}>
                  <List.Item.Meta
                    avatar={
                      <UserActiveBadge type={item.activeType} activeDate={item.activeDate}>
                        <Avatar style={{ verticalAlign: 'middle' }}>{item.login}</Avatar>
                      </UserActiveBadge>
                    }
                    title={item.name}
                    description={
                      <>
                        <IconText icon={DeploymentUnitOutlined} text={item.roleName} key="role-icon" />
                        <IconText icon={UserOutlined} text={item.login} key="login-icon" />
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Spin>
          {editedUser ? (
            <UserEditor user={editedUser} onCancel={this.handleUserEditorCancel} onConfirm={this.handleUserEditorConfirm} />
          ) : null}
        </AdaptiveCard>
      </div>
    );
  }
}

export default observer(UsersListForUser);
