import { action, makeObservable, observable } from 'mobx';

import { MAX_REPORT_ROWS } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { checkIgnoreCase } from '../dispatcherStore/DispatcherData';
import { Container } from '../trackingStore/container/ContainerTypes';
import Wagon from '../trackingStore/wagon/WagonTypes';
import service from './itineraryStore.service';

class ItineraryStore {
  prevLoadOpts: any;

  @observable filteredWagons: Wagon[] = [];
  @observable filteredContainers: Container[] = [];
  @observable numbersFilterModalVisible: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action async itinerary(loadOptions, type) {
    if (this.filteredWagons.length) {
      let wagonFilter = ['it.wagon_number', 'in', '(' + this.filteredWagons.map((w) => w.number).join(',') + ')'];
      if (loadOptions.filter) {
        loadOptions.filter = [wagonFilter, 'and', loadOptions.filter];
      } else {
        loadOptions.filter = wagonFilter;
      }
    }

    if (this.filteredContainers.length) {
      let containerFilter = ['it.container_number', 'in', '(' + this.filteredContainers.map((c) => "'" + c.number + "'").join(',') + ')'];
      if (loadOptions.filter) {
        loadOptions.filter = [containerFilter, 'and', loadOptions.filter];
      } else {
        loadOptions.filter = containerFilter;
      }
    }

    checkIgnoreCase(loadOptions.filter);
    this.prevLoadOpts = loadOptions;
    return await service.itinerary(loadOptions, type);
  }

  @action async itineraryExport(type, columnOptions, progressFunc: ((e: ProgressEvent) => void) | undefined = undefined) {
    const lo = this.prevLoadOpts;
    lo.isLoadingAll = true;
    lo.take = MAX_REPORT_ROWS;
    return await service.reportToXlsx(lo, columnOptions, type, progressFunc);
  }

  @action async itineraryShort(loadOptions, type, withCatalogs: boolean) {
    return await service.itineraryShort(loadOptions, type, withCatalogs);
  }

  @action async hold(loadOptions, type) {
    return await service.hold(loadOptions, type);
  }

  @action async itineraryListById(id, type) {
    return await service.itineraryById(id, type);
  }

  @action async operationById(id, type) {
    return service.operationById(id, type);
  }
}

export default new ItineraryStore();
