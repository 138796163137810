import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import { CompanyTariffResponseModel } from '@stores/companyTariffStore/CompanyTariffData';
import designStore from '@stores/designStore/designStore';
import { TariffRequestModel } from '@stores/historyTariffInfo/historyTariffInfoStore';

interface Props {
  response: CompanyTariffResponseModel;
  request: TariffRequestModel;
}

class CompanyTariffViewer extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const isMobile = designStore.isMobile;
    const response = this.props.response;
    const request = this.props.request;
    return (
      <ScrolledContainer>
        <div className={'verticalTable'}>
          <div
            className={'tariffCalculationTable fgk'}
            style={{
              gridTemplateColumns: `${isMobile ? '14rem repeat(' + 1 + ', minmax(auto, 1fr))' : 'repeat(2, 14rem)'}`,
            }}>
            <div>
              <div>Единица расчета</div>
              <div>Стоимость с НДС</div>
              {request.calculateEmptyReturn && <div>Стоимость попрожнего возврат с НДС</div>}
              <div>НДС</div>
              <div>Срок</div>
              <div>Расстояние</div>
            </div>
            <div>
              <div>один вагон</div>
              <div>{response.plainAmount}</div>
              {request.calculateEmptyReturn && <div>{response.emptyReturnAmount}</div>}
              <div>{response.vat}%</div>
              <div>{response.days} дн.</div>
              <div>{response.distance} км.</div>
            </div>
          </div>
          <div>
            <span style={{ marginLeft: '15px' }}>
              <b>*</b> -информация носит справочный характер
            </span>
          </div>
        </div>
      </ScrolledContainer>
    );
  }
}

export default observer(CompanyTariffViewer);
