import { Checkbox, Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import optionsStore from '@stores/optionsStore';

import { countries } from '../ui/options-escort-editor';

interface Props {
  form: FormInstance;
}

const Kit = observer(({ form }: Props) => {
  const [visible, setVisible] = useState(false);
  const { kitNumber, kitCountryCode } = optionsStore.request;

  function onVisibleChange(value: boolean) {
    setVisible(value);
    form.setFieldsValue({ kitNumber: null, kitCountryCode: null });
  }

  useEffect(() => {
    setVisible(!!kitNumber || !!kitCountryCode);
  }, [kitNumber, kitCountryCode]);

  return (
    <div>
      <Checkbox style={{ marginTop: 14 }} checked={visible} onChange={({ target }) => onVisibleChange(target.checked)}>
        Код исключительного тарифа
      </Checkbox>
      {visible && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={12} style={{ paddingBottom: '6px' }}>
            <Form.Item name={'kitNumber'} rules={[{ required: true, message: 'Укажите номер тарифа' }]}>
              <FloatInput placeholder={'Номер тарифа'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} style={{ paddingBottom: '6px' }}>
            <Form.Item name={'kitCountryCode'} rules={[{ required: true, message: 'Укажите гос-во тарифа' }]}>
              <FloatSelect placeholder={'Гос-во тарифа'}>
                {countries.map((item, index) => {
                  return (
                    <Select.Option value={item.code} key={`kitCountryCode${index}`}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </FloatSelect>
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
});

export default Kit;
