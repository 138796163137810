import HttpClient from '@utils/httpClient';

import { TaskAdminDisplayModel } from './monitorData';

const service = {
  /// Получить задачи администратора
  async getAdminTasks(): Promise<TaskAdminDisplayModel[]> {
    return (await HttpClient.get(`/api/monitor/admin/tasks`)).data;
  },

  /// Получить задачи пользователей/менеджера
  async getUserTasks(): Promise<TaskAdminDisplayModel[]> {
    return (await HttpClient.get(`/api/monitor/user/tasks`)).data;
  },
};

export default service;
