import { Button, Form, Input, Spin } from 'antd';
import React from 'react';

import { FilterContentModel } from '@stores/FilterStore/filterStore';

import { FILTER_NAME, FILTER_NAME_ALARM, SAVE_TITLE } from './words';

interface ComponentProps {
  onFinish(values);
  filter?: FilterContentModel;
  isBusy: boolean;
}

const FilterCreator: React.FC<ComponentProps> = (props) => {
  const init = props.filter ? { name: props.filter.name } : null;
  return (
    <Spin spinning={props.isBusy}>
      <Form onFinish={props.onFinish} layout={'vertical'} initialValues={init} size={'small'}>
        <Form.Item label={FILTER_NAME} name="name" rules={[{ required: true, message: FILTER_NAME_ALARM }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={props.isBusy}>
            {SAVE_TITLE}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FilterCreator;
