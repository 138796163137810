import { List, Skeleton, Typography } from 'antd';
import React, { Component } from 'react';

import optionsService from '@stores/optionsStore/optionsStore.service';
import { TariffProducerResponse } from '@stores/optionsStore/optionsStoreData';

interface State {
  isBusy: boolean;
  data: TariffProducerResponse[];
  selected: number;
}

interface Props {
  loadStationCode: string;
  etsngCode: string;
}

class OptionsProducerRegular extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: true,
      data: [],
      selected: null,
    };
  }
  onClick = (index: number) => {
    this.setState({ selected: index });
    setTimeout(() => this.setState({ selected: null }), 3000);
  };

  render() {
    return (
      <List
        loading={this.state.isBusy}
        bordered
        size={'small'}
        dataSource={this.state.data}
        renderItem={(item, index) =>
          index == this.state.selected ? (
            <List.Item style={{ height: 75 }}>
              <Typography.Text mark>
                Компания не предоставила данные для расчёта ставок на перевозку, Вы можете связаться с ней самостоятельно
              </Typography.Text>
            </List.Item>
          ) : (
            <List.Item style={{ cursor: 'pointer', height: 75 }} onClick={() => this.onClick(index)}>
              <Skeleton title={false} active loading={false}>
                <List.Item.Meta
                  title={item.shortNameReq}
                  description={
                    <span>
                      ОКПО: {item.okpo}. {item.address}
                    </span>
                  }
                />
                {item.telephone}
              </Skeleton>
            </List.Item>
          )
        }
      />
    );
  }

  componentDidMount() {
    this.setState({ isBusy: true, selected: null, data: [] });
    const request = { loadStationCode: this.props.loadStationCode, etsngCode: this.props.etsngCode };
    optionsService
      .tariffProducer(request)
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => this.setState({ isBusy: false }));
  }
}

export default OptionsProducerRegular;
