import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import ViewTypeRadio from '@components/fw/ViewTypeRadio';

import { ClaimModel } from '@stores/claimsStore/ClaimData';
import claimsStore from '@stores/claimsStore/claimsStore';
import designStore from '@stores/designStore/designStore';
import { ViewType } from '@stores/dictStore';
import { SendModel } from '@stores/sendsStore/sendData';
import tagStore from '@stores/tagStore/tagStore';
import userStore from '@stores/userStore/userStore';

import BoardView from './BoardView';
import TableView from './TableView';

interface ClaimsPageState {
  viewType: ViewType;
  selectedClaim: ClaimModel;
  claimToDel: ClaimModel;
  isBusy: boolean;
}

class ClaimsPage extends Component<RouteComponentProps, ClaimsPageState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      viewType: 'table',
      selectedClaim: null,
      claimToDel: null,
      isBusy: false,
    };
  }

  componentDidMount(): void {
    claimsStore.getClaimGroups();
    tagStore.getTags();
    document.title = 'Заявки';
  }

  render() {
    const viewType = this.state.viewType;
    const isMobile = designStore.isMobile;
    return (
      <>
        <AdaptivePageHeader
          title={'Заявки'}
          subTitle={!isMobile && <ViewTypeRadio key={'table_board'} onChange={this.handleViewTypeChanged} type={viewType} size={'small'} />}
          className={'page_header'}
          extra={<CollapsedToolbar content={userStore.canAccess('claims/edit') ? this.renderToolbar(viewType) : null} />}
        />

        <AdaptiveCard>{this.renderSwitch(viewType)}</AdaptiveCard>
      </>
    );
  }

  renderSwitch(viewType: ViewType) {
    switch (viewType) {
      case 'table':
        return (
          <>
            <TableView
              location={this.props.location}
              onSelectionChanged={this.handleSelectionChanged}
              onRowDblClick={this.handleRowDblClick}
              onMasterRowDblClick={this.handleMasterRowDblClick}
              onDelete={this.handleDelete}
            />
          </>
        );
      case 'board':
        return (
          <>
            <BoardView {...this.props} onEdit={this.handleRowDblClick} onDelete={(item) => this.remove(item.claimId)} />
          </>
        );
    }
  }

  renderToolbar(viewType: ViewType) {
    const selectedClaim = this.state.selectedClaim;
    const claimToDel = this.state.claimToDel;
    switch (viewType) {
      case 'table':
        return [
          <Link to={'/claims/create-claim'} key={'create'}>
            <Button icon={<PlusOutlined />} size={'small'} type={'link'}>
              Создать
            </Button>
          </Link>,
          <Link to={selectedClaim ? `/claims/edit-claim/${selectedClaim.claimId}` : ''} key={'edit'}>
            <Button icon={<EditOutlined />} size={'small'} disabled={!selectedClaim} type={'link'}>
              Изменить
            </Button>
          </Link>,
          <Popconfirm
            key={'delete'}
            title={claimToDel ? `Удалить заявку "${claimToDel.claimName}"?` : null}
            visible={claimToDel !== null}
            onConfirm={this.handleRemoveConfirm}
            onCancel={() => this.setState({ claimToDel: null })}
            okText="Да"
            cancelText="Нет">
            <Button
              icon={<DeleteOutlined />}
              size={'small'}
              disabled={!selectedClaim}
              type={'link'}
              onClick={() => this.handleDelete(selectedClaim)}>
              Удалить
            </Button>
          </Popconfirm>,
        ];
      case 'board':
        return [
          <Link to={'/claims/create-claim'} key={'create'}>
            <Button icon={<PlusOutlined />} size={'small'} type={'link'}>
              Создать
            </Button>
          </Link>,
        ];
    }
  }

  handleViewTypeChanged = (value: ViewType) => {
    this.setState({ viewType: value });
  };

  handleSelectionChanged = (data: ClaimModel) => {
    this.setState({
      selectedClaim: data,
    });
  };

  handleRowDblClick = (item: ClaimModel) => {
    this.props.history.push(`/claims/edit-claim/${item.claimId}`);
  };

  handleMasterRowDblClick = (item: SendModel) => {
    this.props.history.push(`/sends/edit-send/${item.sendId}`);
  };

  handleDelete = (item: ClaimModel) => {
    this.setState({ claimToDel: item });
  };

  handleRemoveConfirm = () => {
    const claimToDelId = this.state.claimToDel.claimId;
    this.setState({
      isBusy: true,
      claimToDel: null,
      selectedClaim: null,
    });

    this.remove(claimToDelId);
  };

  remove(claimId) {
    claimsStore
      .delete(claimId)
      .finally(() => this.setState({ isBusy: false }))
      .then(() => {
        claimsStore.getClaims();
      });
  }
}

export default observer(ClaimsPage);
