const prod = window['buildConfig'];

const dev = window['devConfig'];

let config = dev;
switch (process.env.NODE_ENV) {
  case 'production':
    config = prod;
}

export default config;
