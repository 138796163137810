import Icon from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import DataGrid, { Column, GroupPanel, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import { ReactComponent as reys } from '@assets/icons/reys.svg';

import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore/optionsStore';

interface FwProps {}

interface ComponentState {
  visible: boolean;
}

class TariffDetailViewerTrack extends Component<FwProps, ComponentState> {
  dataGrid: DataGrid;
  constructor(props: FwProps) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
    this.gtagProcess('route');
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  gotoMap = () => {
    this.gtagProcess('route-map');
    const url = `/app#/map?tariffId=${optionsStore.result.userTariffRequestId}`;
    designStore.adaptiveGoto(url);
  };

  gtagProcess = (e) => {
    ReactGA.event({
      category: 'Tariff',
      action: e,
    });
  };

  render() {
    const result = optionsStore.result;
    const value = result ? result.pathDetails : null;
    return (
      <>
        <Button icon={<Icon component={reys} />} type={'link'} disabled={!value || optionsStore.isBusy} onClick={this.showModal}>
          Маршрут
        </Button>
        <Drawer
          title={
            <span>
              Детали маршрута{' '}
              <Button onClick={this.gotoMap} type={'dashed'}>
                Показать на карте
              </Button>
            </span>
          }
          //placement={designStore.isMobile ? 'bottom' : 'right'}
          width={designStore.isMobile ? '100vw' : '80%'}
          bodyStyle={{ paddingBottom: '0px', flexGrow: 0, padding: '0px' }}
          onClose={this.handleCancel}
          visible={this.state.visible}
          footer={
            <DataGrid
              allowColumnReordering={true}
              height={`calc(100vh - 6.4rem)`}
              dataSource={value}
              showBorders={true}
              ref={(ref) => (this.dataGrid = ref)}
              loadPanel={{ enabled: false }}
              selection={{ mode: 'single' }}
              columnAutoWidth={true}>
              <Paging enabled={true} defaultPageSize={25} />
              <Pager
                allowedPageSizes={[25, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
                infoText={'Всего: {2}'}
              />
              <GroupPanel visible={true} />
              <HeaderFilter visible={true} allowSearch={true} />
              <Column
                allowFixing={true}
                sortIndex={0}
                minWidth={80}
                width={80}
                dataType={'number'}
                allowResizing={true}
                dataField="rowSerialNumber"
                caption={'Номер'}
              />
              <Column allowFixing={true} minWidth={100} width={100} allowResizing={true} dataField="stationCode" caption={'Код станции'} />
              <Column allowFixing={true} allowResizing={true} dataField="stationName" caption={'Наименование'} />
              <Column
                allowFixing={true}
                allowResizing={true}
                dataField="countryOrder"
                caption={'Государство'}
                groupIndex={0}
                headerFilter={{
                  dataSource: optionsStore.countryPositions
                    ? optionsStore.countryPositions.map((c) => {
                        return { text: c, value: optionsStore.countryPositions.indexOf(c) };
                      })
                    : [],
                }}
                groupCellRender={(d) => {
                  return optionsStore.countryPositions ? optionsStore.countryPositions[d.value] : null;
                }}
                cellRender={(d) => {
                  return optionsStore.countryPositions ? optionsStore.countryPositions[d.value] : null;
                }}
              />
              <Column allowFixing={true} minWidth={120} width={120} allowResizing={true} dataField="distance" caption={'Расстояние, км'} />
            </DataGrid>
          }
        />
      </>
    );
  }
}

export default observer(TariffDetailViewerTrack);
