import React from 'react';

const ScrolledContainer: React.FC<any> = ({ children, height, className, ...rest }) => {
  return (
    <div style={{ height: height, overflow: 'auto', ...rest.style }} className={className}>
      {children}
    </div>
  );
};

export default ScrolledContainer;
