import { Button, Drawer } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import SwitchWithLabel from '@components/fw/FloatLabel/SwitchWithLabel';

import designStore from '@stores/designStore';

interface Props {}

interface State {
  visible: boolean;
}

class UserProfileWorkplaceSettingsDrawer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  onOpen = () => {
    this.setState({ visible: true });
  };

  handleClose = () => {
    this.setState({ visible: false });
  };

  onReportStyleChange = (value: boolean) => {
    designStore.setCompactReports(value);
  };

  onCellsStyleChange = (value: boolean) => {
    designStore.setFormattedNameCells(value);
  };

  render() {
    const { visible } = this.state;

    return (
      <>
        <Button type="primary" style={{ marginTop: 30 }} onClick={this.onOpen}>
          Настройка рабочего места
        </Button>

        <Drawer title="Настройка рабочего места" open={visible} onClose={this.handleClose} placement="right">
          <div>
            <SwitchWithLabel checked={designStore.isCompactReports} onChange={this.onReportStyleChange}>
              Компактный вид отчетов
            </SwitchWithLabel>
          </div>
          <div style={{ marginTop: 15 }}>
            <SwitchWithLabel checked={designStore.isFormattedNameCells} onChange={this.onCellsStyleChange}>
              Форматированные ячейки наименований
            </SwitchWithLabel>
          </div>
        </Drawer>
      </>
    );
  }
}

export default observer(UserProfileWorkplaceSettingsDrawer);
