import { Button } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import etranStore from '@stores/etranStore/etranStore';
import userStore from '@stores/userStore/userStore';

interface EtranInvoicePageState {
  isBusy: boolean;
  dataSource: any;
}

const fields = [
  { caption: 'Состояние накладной', dataField: 'invoicestate', dataType: 'string' },
  { caption: 'Идентификатор накладной', dataField: 'invoiceid', dataType: 'number' },
  {
    caption: 'Дата создания накладной',
    dataField: 'invdatecreate',
    dataType: 'date',
    //calculateValue: (e) => dateParser(e['invdatecreate']),
  },
  { caption: 'Номер накладной', dataField: 'invnumber', dataType: 'string' },
  { caption: '№ Вагона', dataField: 'carids', dataType: 'string' },
  { caption: 'ПУЖТ Наим. Грузополучатель', dataField: 'invrecipname', dataType: 'string' },
  { caption: 'ПУЖТ Наим. Грузоотправитель', dataField: 'invsendername', dataType: 'string' },
  { caption: 'ПУЖТ Наим. Плательщик', dataField: 'invpayername', dataType: 'string' },
  { caption: 'Наим. станции отправления', dataField: 'invfromstationname', dataType: 'string' },
  { caption: 'Наим. станции назначения', dataField: 'invtostationname', dataType: 'string' },
  {
    caption: 'Дата погрузки',
    dataField: 'invfactdatetoload',
    dataType: 'date',
  },
  {
    caption: 'Дата прибытия на станцию назначения',
    dataField: 'invdatearrive',
    dataType: 'date',
  },
  {
    caption: 'Дата раскредитовки',
    dataField: 'invdatedelivery',
    dataType: 'date',
  },
];

class EtranInvoicePage extends Component<RouteComponentProps, EtranInvoicePageState> {
  dataGrid: DataGrid;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      dataSource: this.dataSource,
    };
  }

  componentDidMount() {
    document.title = 'Накладные';
  }

  onAddClick = () => {
    this.props.history.push(`/etran/create-invoice`);
    this.setState({});
  };

  handleClickRow = (data) => {
    if (data.rowType == 'data') {
      this.props.history.push(`/etran/edit-invoice/${data.data.invoiceid}`);
    }
  };

  get dataSource() {
    return new DataSource({
      store: new CustomStore({
        key: 'invoiceid',
        load: function (loadOptions) {
          return etranStore
            .getEtranInvoiceList(loadOptions)
            .then((data) => {
              return data;
            })
            .catch(() => {
              throw 'Data Loading Error';
            });
        },
      }),
    });
  }

  render() {
    const dataSource = this.state.dataSource;

    return (
      <>
        <AdaptivePageHeader
          title={'Накладные'}
          style={{ background: 'white' }}
          extra={<Button onClick={this.onAddClick}>Создать</Button>}
        />
        <ScrolledContainer style={{ height: 'calc(100vh - var(--header-height))' }}>
          <AdaptiveCard>
            <DataGrid
              allowColumnReordering={true}
              ref={(ref) => (this.dataGrid = ref)}
              dataSource={dataSource}
              remoteOperations={true}
              //loadPanel={{ enabled: false }}
              allowColumnResizing={true}
              showBorders={true}
              height={'calc(100vh - var(--padding))'}
              columnResizingMode={'widget'}
              columnAutoWidth={true}
              showColumnLines={true}
              columnMinWidth={30}
              showRowLines={true}
              rowAlternationEnabled={true}
              onRowClick={(data) => this.handleClickRow(data)}
              selection={{ mode: 'single' }}>
              <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'EtranInvoicePage'} />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <GroupPanel visible={true} />
              <Grouping autoExpandAll={false} />
              <Paging enabled={true} defaultPageSize={25} />
              <Pager
                allowedPageSizes={[25, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
                infoText={'Всего: {2}'}
              />
              {/*<Editing allowDeleting={true} mode="row" texts={{ deleteRow: 'Удалить' }} />*/}
              {INDEX_COLUMN}
              {fields.map((item) => (
                <Column
                  allowFixing={true}
                  key={item.dataField}
                  allowResizing={true}
                  dataField={item.dataField}
                  caption={item.caption}
                  dataType={item.dataType}
                  //calculateCellValue={item.calculateValue}
                  //calculateGroupValue={item.calculateValue}
                />
              ))}
            </DataGrid>
          </AdaptiveCard>
        </ScrolledContainer>
      </>
    );
  }
}

export default observer(EtranInvoicePage);
