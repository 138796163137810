import { Button } from 'antd';
import React, { Component } from 'react';

import claimsDocumentsStoreService from '@stores/claimsStore/claimsDocumentsStore.service';

import PdfViewModal from './PdfViewModal';

interface CommercialOfferViewProps {
  commercialOfferId: string;
}

interface CommercialOfferViewState {
  isBusy: boolean;
  blob: Blob;
  previewVisible: boolean;
}

class CommercialOfferView extends Component<CommercialOfferViewProps, CommercialOfferViewState> {
  constructor(props: CommercialOfferViewProps) {
    super(props);
    this.state = {
      isBusy: false,
      blob: null,
      previewVisible: false,
    };
  }

  componentDidMount() {}

  loadFile = () => {
    const { commercialOfferId } = this.props;
    if (commercialOfferId) {
      this.setState({ isBusy: true });
      claimsDocumentsStoreService
        .getOfferByTemplateMetadataId(commercialOfferId)
        .then((doc) => {
          this.setState({ previewVisible: true, blob: doc });
        })
        .finally(() => this.setState({ isBusy: false }));
    }
  };

  render() {
    const { isBusy } = this.state;
    return (
      <>
        <Button size={'small'} type={'link'} key="1" loading={isBusy} onClick={this.loadFile}>
          КП
        </Button>
        <PdfViewModal
          data={this.state.blob}
          visible={this.state.previewVisible}
          onClose={() => {
            this.setState({ previewVisible: false });
          }}
        />
      </>
    );
  }
}

export default CommercialOfferView;
