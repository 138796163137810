export interface CompanyLoadRegistryModel {
  loadRegistryId?: string; //не для отчета
  wagonNumber: number;
  sendNumber: string;
  sendDate: Date | null;
  loadStationCode?: number; //не для отчета
  loadStationName: number;
  destStationCode?: number; // не для отчета
  destStationName: number;
  etsngCode?: string; //не для отчета
  etsngName: string;
  netto: number;
  companyName: string; //показывать только админу и технологу
  clientId?: string; //не для отчета
  clientName: string;
  supplierName: string; //наименование поставщика
  itineraryId: string; //ссылка на рейс
  notes3Json?: string; //JSON строка - массив меток3
  notes3?: string[]; // массив меток3 или Поставщик
  notes2Json?: string; //JSON строка - массив меток2
  notes2?: string[]; // массив меток2
  notes1Json?: string; //JSON строка - массив меток2
  notes1?: string[]; // массив меток2
  rateType?: RateType; // По чему должна считаться ставка
  rate?: number; //ставка
  vatRate?: Vat; //ставка НДС
  vatAmount?: number; //сумма НДС
  servicesAmount?: number; //цена услуг
  servicesAmountWithVat?: number; //цена услуг с НДС
  supplierRateType?: RateType; //(Поставщик) По чему должна считаться ставка
  supplierRate?: number; //(Поставщик) ставка
  supplierVatRate?: Vat; //(Поставщик) ставка НДС
  supplierVatAmount?: number; //(Поставщик) сумма НДС
  supplierServicesAmount?: number; //(Поставщик) цена услуг
  supplierServicesAmountWithVat?: number; //(Поставщик) цена услуг с НДС
  wagonModelKindName: string; //Наименование типа вагона
}

export interface LoadRegistryStatisticDto {
  count: number;
  netto: number;
}

export class CompanyLoadRegistryListUpdateModel {
  constructor(data: CompanyLoadRegistryModel[]) {
    if (!data || !Array.isArray(data) || !data.length) {
      throw new Error('Array of CompanyLoadRegistryModels is empty');
    }

    const first = data[0];

    if (data.length > 1) {
      Object.keys(first).forEach((key) => {
        if (!data.find((d) => d[key] !== first[key])) {
          this[key] = first[key];
          this['change' + this.capitalise(key)] = true;
        }
      });
    } else {
      Object.keys(first).forEach((key) => {
        this[key] = first[key];
        this['change' + this.capitalise(key)] = true;
      });
    }
    /*функционал временно отключен, т.к. ждем информации от заказчика
    if(data.some(i => i.wagonModelKindName !== 'Цистерна')){
      this['changeRate'] = false;
      this['changeRateType'] = false;
      this['changeSupplierRate'] = false;
      this['changeSupplierRateType'] = false;
    }
    if(data.some(i => i.wagonModelKindName === 'Цистерна' || i.wagonModelKindName === 'Полувагон')){
      this['changeServicesAmount'] = false;
      this['changeSupplierServicesAmount'] = false;
    }*/
    this.loadRegistryIds = data.map((it) => it.loadRegistryId);
  }

  private capitalise(str: string) {
    return str[0].toUpperCase() + str.slice(1);
  }

  loadRegistryIds: string[];
  changeSendNumber: boolean;
  sendNumber?: string;
  changeSendDate: boolean;
  sendDate?: Date;
  changeLoadStationCode: boolean;
  loadStationCode?: number;
  changeDestStationCode: boolean;
  destStationCode: number;
  changeEtsngCode: boolean;
  etsngCode?: string;
  changeNetto: boolean;
  netto?: number;
  changeClientId: boolean;
  clientId?: string;
  changeSupplierId: boolean;
  supplierId?: string; //не для отчета
  changeDontShowClientInReport: boolean;
  dontShowClientInReport?: boolean;
  changeNotes: boolean;
  notes3?: string[];
  notes1?: string[];
  notes2?: string[];

  changeRateType: boolean;
  rateType?: RateType;
  changeRate: boolean;
  rate?: number;
  changeVatRate: boolean;
  vatRate?: Vat;
  changeVatAmount: boolean;
  vatAmount?: number;
  changeServicesAmount: boolean;
  servicesAmount?: number;
  changeServicesAmountWithVat: boolean;
  servicesAmountWithVat?: number;

  changeSupplierRateType: boolean;
  supplierRateType?: RateType;
  changeSupplierRate: boolean;
  supplierRate?: number;
  changeSupplierVatRate: boolean;
  supplierVatRate?: Vat;
  changeSupplierVatAmount: boolean;
  supplierVatAmount?: number;
  changeSupplierServicesAmount: boolean;
  supplierServicesAmount?: number;
  changeSupplierServicesAmountWithVat: boolean;
  supplierServicesAmountWithVat?: number;
}

//#region НДС util

/** Типы НДС ставки. */
export enum Vat {
  Without = 'without',
  Vat0 = 'vat0',
  Vat20 = 'vat20',
}

type VatOptionsType = Array<{ value: Vat; label: string }>;

/**
 * Набор опций для Antd Select компонентов.
 */
export const vatOptions: VatOptionsType = [
  { value: Vat.Without, label: 'Без НДС' },
  { value: Vat.Vat0, label: '0%' },
  { value: Vat.Vat20, label: '20%' },
];

/**
 * Возвращает процентное(целочисленное) представление ставки НДС. Например, 0 или 20.
 * @param value Ставка НДС.
 */
export const vatToNumber = (value: Vat) => {
  switch (value) {
    case Vat.Without:
      return -1;
    case Vat.Vat0:
      return 0;
    case Vat.Vat20:
      return 20;

    default:
      return 0;
  }
};
//#endregion

//#region Ставка util

/** Варианты просчета ставки. */
export enum RateType {
  Ton = 'ton',
  Wagon = 'wagon',
}

type RatePerOptionsType = Array<{ value: RateType; label: string }>;

/** Набор опций для Antd Select компонентов. */
export const rateTypeOptions: RatePerOptionsType = [
  { value: RateType.Ton, label: 'За тонну' },
  { value: RateType.Wagon, label: 'За вагон' },
];
//#endregion
