import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Popover, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { TagModel } from '@stores/tagStore';
import tagStore from '@stores/tagStore/tagStore';

import TagCreator from './TagCreator';
import styles from './TagEditor.module.scss';
import TagList from './TagList';

interface TagEditorProps {
  disabled?: boolean;
  value?: TagModel[];
  onChange?: (value: any) => void;
  readonly?: boolean;
}

interface TagEditorState {
  name: string;
  stopItem: string;
  value: string[];
  tags: TagModel[];
  selected: TagModel[];
  editMode: boolean;
  editedTag: TagModel;
}

class TagEditor extends Component<TagEditorProps, TagEditorState> {
  constructor(props: TagEditorProps) {
    super(props);

    this.state = {
      name: '',
      stopItem: null,
      value: [],
      tags: [],
      selected: [],
      editMode: false,
      editedTag: null,
    };
  }

  componentDidMount(): void {
    tagStore.getTags();
  }

  render() {
    const isBusy = tagStore.isBusy;
    const value = this.state.value;
    const tags = tagStore.tags;
    const selected = this.props.value || [];
    const editMode = this.state.editMode;
    const editedTag = this.state.editedTag;
    const readonly = this.props.readonly;
    const disabled = this.props.disabled;

    return (
      <div>
        <Popover
          title={
            editMode ? (
              <>
                <LeftOutlined
                  style={{ cursor: 'pointer', marginRight: 5 }}
                  onClick={() => this.setState({ editMode: false, editedTag: null })}
                />{' '}
                Теги
              </>
            ) : (
              'Теги'
            )
          }
          content={
            editMode ? (
              <TagCreator onFinish={this.onFormFinish} tag={editedTag} isBusy={isBusy} />
            ) : (
              <TagList
                tags={tags}
                isBusy={isBusy}
                selected={selected}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                onAddNew={this.onAddNew}
                onClick={this.onTagClick}
              />
            )
          }
          trigger={disabled ? 'null' : 'click'}>
          <Tag
            className={styles.site_tag_plus}
            style={disabled ? { cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.25)', backgroundColor: 'rgba(0, 0, 0, 0.04)' } : {}}>
            <PlusOutlined /> Добавить
          </Tag>
        </Popover>
        {selected.map((tag, index) => {
          return (
            <Tag
              key={`tag_${index}`}
              color={tag.metadata.color}
              style={
                disabled
                  ? { cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.25)', backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                  : { cursor: 'pointer' }
              }>
              {tag.name}
            </Tag>
          );
        })}
      </div>
    );
  }

  onFormFinish = (e) => {
    const newTag = {
      tagId: null,
      name: e.name,
      metadata: { color: e.color },
    };
    if (this.state.editedTag) {
      newTag.tagId = this.state.editedTag.tagId;
      tagStore.update(newTag).then(() => {
        this.setState({
          editMode: false,
          editedTag: null,
        });
        const value = this.props.value || [];
        const selected = value.find((x) => x.tagId === newTag.tagId);
        if (selected) {
          selected.name = newTag.name;
          selected.metadata = newTag.metadata;
        }
      });
    } else {
      tagStore.create(newTag).then(() => {
        this.setState({
          editMode: false,
          editedTag: null,
        });
      });
    }
  };

  onDelete = (tag: TagModel) => {
    tagStore.remove(tag.tagId);
    const newList = this.props.value || [];
    this.props.onChange(newList.filter((x) => x.tagId !== tag.tagId));
  };

  onEdit = (tag: TagModel) => {
    this.setState({
      editedTag: tag,
      editMode: true,
    });
  };

  onAddNew = () => {
    this.setState({
      editMode: true,
    });
  };

  onTagClick = (tag: TagModel) => {
    const selected = [...this.state.selected];
    if (selected.map((x) => x.tagId).includes(tag.tagId)) {
      const newList = selected.filter((x) => x.tagId !== tag.tagId);
      this.setState({
        selected: newList,
      });
      this.props.onChange(newList);
    } else {
      selected.push(tag);
      this.props.onChange(selected);
      this.setState({
        selected: selected,
      });
    }
  };

  handleChange = (value: string[]) => {};
}

export default observer(TagEditor);
