import HttpClient from '@utils/httpClient';

abstract class AbstractNsiStoreService<T> {
  abstract path: string;
  async getList(loadOptions): Promise<any> {
    return (await HttpClient.post(`/api/nsi/${this.path}`, loadOptions)).data;
  }
  async update(data: T): Promise<void> {
    return (await HttpClient.put(`/api/nsi/${this.path}`, data)).data;
  }
}
export default AbstractNsiStoreService;
