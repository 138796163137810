import { CountryModel } from '@stores/companyStore/CompanyData';
import { ExtraServiceModel } from '@stores/dictStore/dictStore';
import { TemplateOrgContractModel } from '@stores/organizationsStore/organizationsStoreData';
import { UserModel } from '@stores/usersStore/usersStore';

export const getExecutorOptions = (users: UserModel[]) =>
  users.map(({ name: label, userId: value }) => ({
    label,
    value,
  }));

export const getContractOptions = (contractList: TemplateOrgContractModel[]) =>
  contractList
    .map(({ contractNumber: label, templateMetadataId: value, createDate }) => ({
      label: `${label}, ${getDateFromDateTimeString(createDate)}`,
      value,
    }))
    .concat([{ label: 'Без договора', value: null }]);

export const getExtraServicesOptions = (extraServices: ExtraServiceModel[]) =>
  extraServices.map((extraService) => ({
    value: extraService.serviceId,
    label: extraService.name,
  }));

export const getCountriesOptions = (countries: CountryModel[]) =>
  countries
    .filter((c) => c.iso3166)
    .map((country) => ({
      value: country.code,
      label: country.fullName,
    }));

const getDateFromDateTimeString = (dateString: string) => new Date(dateString).toLocaleDateString();
