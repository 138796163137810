import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { RequestResourceInfo, RequestTypeMetaElement } from '@stores/requestsStore/RequestsData';
import requestsService from '@stores/requestsStore/requestsStore.service';

interface Props {
  data: RequestTypeMetaElement;
}

interface State {
  isBusy: boolean;
  dict: RequestResourceInfo[];
}

class RequestFormRow extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: false,
      dict: [],
    };
  }

  componentDidMount(): void {
    if (['SingleListBox', 'MultiListBox'].includes(this.props.data.visualType)) {
      this.setState({ isBusy: true });
      requestsService
        .getResources(this.props.data.name)
        .then((ans) => {
          this.setState({ dict: ans });
        })
        .finally(() => this.setState({ isBusy: false }));
    }
  }

  render() {
    return (
      <Form.Item
        style={{ marginTop: 5 }}
        name={this.props.data.name}
        label={this.props.data.description}
        rules={[{ required: true, message: 'Необходимо заполнить' }]}>
        {this.renderInput()}
      </Form.Item>
    );
  }

  renderInput() {
    const type = this.props.data.visualType;
    switch (type) {
      case 'MultiListBox':
        return (
          <Select mode={'multiple'}>
            {this.state.dict.map((d) => (
              <Select.Option key={d.code} value={d.code}>
                {d.code}-{d.description}
              </Select.Option>
            ))}
          </Select>
        );
      case 'SingleListBox':
        return (
          <Select>
            {this.state.dict.map((d) => (
              <Select.Option key={d.code} value={d.code}>
                {d.code}-{d.description}
              </Select.Option>
            ))}
          </Select>
        );
      case 'TextArea':
        return <Input.TextArea size={'large'} autoSize />;
      case 'TextBox':
        return <Input />;
      default:
        return null;
    }
  }
}

export default observer(RequestFormRow);
