import { Col, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import styles from '@components/routed/EtranViewer/EtranViewer.module.scss';

interface Props {
  formRef: FormInstance;
  name: string;
}

interface State {}

@observer
class EtranAdress extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Col xs={24} sm={24} md={24} lg={4}>
          <label className={'etranFormLabel'}>Адрес:</label>
        </Col>
        <Col xs={17} sm={17} md={17} lg={14}>
          <Form.Item name={[this.props.name, 'addresses', 0, 'addressText']}>
            <Input className={'comboEditOther'} maxLength={255} />
          </Form.Item>
        </Col>
        <Col xs={7} sm={7} md={7} lg={6} style={{ display: 'flex' }}>
          <div className={styles.label}>ТГНЛ</div>
          <Form.Item name={[this.props.name, 'addresses', 0, 'addressTgnl']}>
            <Input className={'comboEditOther'} maxLength={255} />
          </Form.Item>
        </Col>
      </>
    );
  }
}

export default EtranAdress;
