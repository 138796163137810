import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, HeaderFilter, Paging, Summary } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';

import { EtranInvoiceFreight } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';

import EtranInvoceFreightsInfoModal from './EtranInvoceFreightsInfoModal';

interface Props {}

interface State {
  selected: EtranInvoiceFreight;
}

@observer
class EtranFreightsTable extends Component<Props, State> {
  dataGrid: DataGrid;
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  onAdd = (newData: EtranInvoiceFreight, oldData: EtranInvoiceFreight) => {
    etranStore.addFreight(newData, oldData);
    this.dataGrid.instance.refresh();
  };

  onRemove = () => {
    etranStore.removeFreight(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onCopy = () => {
    etranStore.copyFreight(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onUp = () => {
    etranStore.upFreight(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onDown = () => {
    etranStore.downFreight(this.state.selected);
    this.dataGrid.instance.refresh();
  };
  render() {
    const data = etranStore.invoceFormData.freights;
    const selected = this.state.selected;
    return (
      <div className={'etranTable'}>
        <EtranCaption title={'Грузы'} />
        <div className={'etranButtonsGroup'}>
          <EtranInvoceFreightsInfoModal onChange={(e) => this.onAdd(e, null)} />
          {selected ? (
            <EtranInvoceFreightsInfoModal data={selected} onChange={(e) => this.onAdd(e, selected)} />
          ) : (
            <Button disabled={true}>Изменить</Button>
          )}
          {/*<Button>Изменить</Button>*/}
          <Button disabled={!selected} onClick={this.onRemove}>
            Удалить
          </Button>
          <Button disabled={!selected} onClick={this.onCopy}>
            Дублировать
          </Button>
          {/*<Button>Удалить все</Button>
          <Button>Копировать</Button>
          <Button>Вставить</Button>*/}
          <Button disabled={!selected} onClick={this.onUp} icon={<ArrowUpOutlined />} />
          <Button disabled={!selected} onClick={this.onDown} icon={<ArrowDownOutlined />} />
        </div>
        <DataGrid
          onSelectionChanged={(e) => {
            const key = e.selectedRowsData[0];
            this.setState({ selected: key });
          }}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={false}
          dataSource={data}
          style={{ minHeight: '110px' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          selection={{ mode: 'single' }}>
          <Paging enabled={false} />
          <GroupPanel visible={false} />
          <HeaderFilter visible={false} allowSearch={false} />
          <FilterRow visible={false} />
          {INDEX_COLUMN}
          <Column allowFixing={true} dataField="freightCode" caption={'Код груза'} />
          <Column allowFixing={true} dataField="freightExactName" caption={'Груз'} />
          <Column allowFixing={true} dataField="freightPackTypeName" caption={'Упаковка'} />
          <Column allowFixing={true} dataField="freightPackageCount" caption={'Кол-во мест'} />
          <Column allowFixing={true} dataField="freightWeight" caption={'План. масса груза'} />
          <Column allowFixing={true} dataField="freightGngCode" caption={'Код груза ГНГ'} />
          <Column allowFixing={true} dataField="freightDangerSignName" caption={'Признак опасности'} />

          <Summary></Summary>
        </DataGrid>
      </div>
    );
  }
}

export default EtranFreightsTable;
