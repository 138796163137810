import { Badge, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';

const UserActiveBadge: React.FC<{ type: string; activeDate: string }> = (props) => {
  let color = 'red';
  switch (props.type) {
    case 'active':
      color = 'green';
      break;
    case 'walkedAway':
      color = 'yellow';
      break;
    case 'notAvailable':
      color = 'red';
      break;
    case 'unknown':
      color = 'gray';
      break;
  }
  const tooltip = props.activeDate
    ? `был ${moment(props.activeDate).format('DD MMM')} в ${moment(props.activeDate).format('HH:mm')}`
    : null;
  return (
    <Tooltip title={tooltip}>
      <Badge dot color={color}>
        <>{props.children}</>
      </Badge>
    </Tooltip>
  );
};

export default UserActiveBadge;
