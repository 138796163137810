import { Button, Form, Modal } from 'antd';
import React, { useEffect } from 'react';

import { FieldFloatSelect } from '@components/fw/Fields/float-select';
import { Flex } from '@components/fw/Flex';
import RequiredPlaceholder from '@components/fw/RequiredPlaceholder';
import OrganizationSelector from '@components/routed/ClaimInfo/documents/OrganizationSelector';

import { useContractOptions } from './wagon-supplier-modal.lib';
import { WagonSupplierModalProps as Props, WagonSupplierModalResult } from './wagon-supplier-modal.types';

export const WagonSupplierModal = ({ open, onClose, onBind }: Props) => {
  const [form] = Form.useForm();
  const organizationId = Form.useWatch('organizationId', form);
  const { isLoading, options } = useContractOptions(organizationId);

  const onFinish = () => onBind();

  const onFinishWithSupplier = (values: WagonSupplierModalResult) => onBind(values);

  useEffect(() => form.setFieldValue('contractId', null), [organizationId]);

  const footer = (
    <Flex align={'center'} justify={'flex-end'}>
      <Button onClick={form.submit}>Привязать</Button>
      <Button onClick={onFinish}>Привязать без поставщика</Button>
    </Flex>
  );

  return (
    <Modal open={open} title={'Выбор поставщика'} footer={footer} onCancel={onClose}>
      <Form form={form} layout={'vertical'} style={FORM_STYLE} onFinish={onFinishWithSupplier}>
        <Flex gap={'var(--gap) 32px'} vertical={true}>
          <Form.Item name={'organizationId'} rules={RULES}>
            <OrganizationSelector
              float={true}
              allowClear={false}
              type={'supplier'}
              placeholder={<RequiredPlaceholder value={'Поставщик'} />}
            />
          </Form.Item>
          <FieldFloatSelect
            disabled={!organizationId}
            loading={isLoading}
            options={options}
            name={'contractId'}
            placeholder={<RequiredPlaceholder value={'№ договора'} />}
            allowClear={false}
            rules={RULES}
          />
        </Flex>
      </Form>
    </Modal>
  );
};

const FORM_STYLE = { marginTop: 16 };
const RULES = [{ required: true, message: 'Пожалуйста, заполните это поле' }];
