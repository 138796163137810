import React, { Component } from 'react';

import designStore from '@stores/designStore/designStore';

interface State {}

interface Props {}

//используется при печати. в @media print классу print-logo переопределяется свойство display

class PrintingHeader extends Component<Props, State> {
  render() {
    return (
      <div className={'print-logo'} style={{ display: 'none' }}>
        <img height={50} src={designStore.logoMax} />
      </div>
    );
  }
}

export default PrintingHeader;
