import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ContractElement } from '@stores/contractTemplateStore/ContractTemplatesData';

interface ComponentProps {
  data: ContractElement;
  selected: string;
}

interface ComponentState {}

class DocumentTemplateRowView extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const data = this.props.data;
    const isSelected = data.relativePath === this.props.selected;
    const text = data.value.split('\n');
    return (
      <div style={{ marginTop: 4 }}>
        <div>
          {data.elementType == 'title' && (
            <div style={{ textAlign: 'center', color: isSelected ? 'orange' : null }}>
              <b>{data.value}</b>
            </div>
          )}
          {data.elementType == 'header' && (
            <div style={{ textAlign: 'center', color: isSelected ? 'orange' : null }}>
              <b>
                {data.relativePath}. {data.value}
              </b>
            </div>
          )}

          {data.elementType == 'subHeader' && (
            <div style={{ textAlign: 'left', color: isSelected ? 'orange' : null }}>
              <b>
                {data.relativePath}. {data.value}
              </b>
            </div>
          )}
          {data.elementType == 'paragraph' && (
            <div style={{ wordBreak: 'break-word', color: isSelected ? 'orange' : null }}>
              <b>{data.relativePath}.</b>
              {text.map((t, index) => (
                <span key={index}>
                  {t}
                  <br />
                </span>
              ))}
            </div>
          )}
        </div>
        {data.nestedElements &&
          data.nestedElements.map((c, index) => <DocumentTemplateRowView selected={this.props.selected} data={c} key={index} />)}
      </div>
    );
  }
}

export default observer(DocumentTemplateRowView);
