import { DownOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Empty, Menu, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import DataGrid, {
  Column,
  Export,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  StateStoring,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import StatisticExceptDxCell from '@components/fw/DxCellTemplates/StatisticExceptDxCell';

import companiesStore from '@stores/companiesStore/companiesStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import statisticStore from '@stores/statisticStore/statisticStore';
import userStore from '@stores/userStore/userStore';

import { showErrorNotify } from '@utils/notify';
import { getTitle } from '@utils/roleAccess';
import { filterOptionFn } from '@utils/sort';

import CreateButton from './CreateButton';
import './StatisticPage.less';

interface ComponentState {
  loaded: boolean;
  showSummary: boolean;
}

class StatisticPage extends Component<RouteComponentProps, ComponentState> {
  formRef: FormInstance;
  ExcelJs: any;

  summary: Summary;
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loaded: false,
      showSummary: true,
    };
  }

  get isAdmin() {
    return !!userStore.userData && ['admin', 'manager', 'tech', 'dev'].includes(userStore.userData.roleCode);
  }

  componentDidMount(): void {
    statisticStore.initData();
    if (this.isAdmin) companiesStore.getCompaniesSort();
    import('exceljs').then((e) => {
      this.ExcelJs = e;
    });
  }

  render() {
    const isBusy = statisticStore.isBusy;
    const data = statisticStore.data;
    const loaded = this.state.loaded;
    document.title = getTitle(this.props.match.path.slice(1));

    return (
      <>
        <AdaptivePageHeader className={'page_header'} title={'Статистика'} extra={this.renderForm()} />
        <Spin spinning={isBusy}>
          <AdaptiveCard>
            {loaded ? (
              <DataGrid
                allowColumnReordering={true}
                dataSource={data}
                height={'calc(100vh - var(--padding))'}
                loadPanel={{ enabled: false }}
                columnResizingMode={'widget'}
                columnAutoWidth={true}
                showColumnLines={true}
                columnMinWidth={30}
                showRowLines={true}
                showBorders={true}
                onExporting={this.onExporting}
                rowAlternationEnabled={true}>
                <FilterRow visible={true} />
                <Export enabled={true} excelWrapTextEnabled={true} />
                <GroupPanel visible={true} />
                <HeaderFilter visible={true} allowSearch={true} />
                {this.columns.map((c) => c)}
              </DataGrid>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 'calc(100vh - 8.2rem - var(--card-padding))',
                }}>
                <Empty description={<b>Необходимо выбрать период и отчет</b>} />
              </div>
            )}
          </AdaptiveCard>
        </Spin>
      </>
    );
  }

  get columns() {
    const isAdmin = this.isAdmin;
    const canChange = ['admin', 'manager', 'tech', 'dev'].includes(userStore.userData.roleCode);
    if (statisticStore.type == 2)
      return [
        <Column allowFixing={true} dataField={'typeCode'} caption={'Справка'} key={'typeCode'} />,
        <Column allowFixing={true} dataField={'typeName'} caption={'Наименование'} key={'typeName'} />,
        <Column allowFixing={true} dataField={'requestCount'} caption={'Запросов'} key={'requestCount'} />,
        <Column allowFixing={true} dataField={'totalRate'} caption={'Общая стоимость'} key={'totalRate'} />,
        <Column allowFixing={true} dataField={'currencyName'} caption={'Валюта'} key={'currencyName'} dataType={'string'} />,
        <Column allowFixing={true} dataField={'exchangeRate'} caption={'Курс'} key={'exchangeRate'} />,
        <Column
          allowFixing={true}
          dataField={'totalRateCurrency'}
          caption={'Общая стоимость в валюте договора'}
          key={'totalRateCurrency'}
        />,
        <Summary key={'summary'}>
          <TotalItem column={'requestCount'} summaryType={'sum'} skipEmptyValues={true} displayFormat={'итого: {0}'} />
          <TotalItem column={'totalRate'} summaryType={'sum'} skipEmptyValues={true} displayFormat={'стоимость: {0}'} />
        </Summary>,
      ];
    else if (statisticStore.type == 3)
      return [
        <Column allowFixing={true} dataField={'date'} key={'date'} caption={'Дата'} dataType={'date'} />,
        <Column allowFixing={true} dataField={'number'} key={'number'} caption={'Номер'} />,
        <Column allowFixing={true} dataField={'catalogsName'} key={'catalogsName'} caption={'Подразделения'} />,
        isAdmin ? <Column allowFixing={true} dataField={'sourceName'} key={'sourceName'} caption={'Источник'} /> : null,
        isAdmin ? <Column allowFixing={true} dataField={'billingProfileName'} key={'billingProfileName'} caption={'Профиль'} /> : null,
        <Column allowFixing={true} dataField={'dayCount'} key={'dayCount'} caption={'Дней'} />,
        <Column allowFixing={true} dataField={'techRate'} key={'techRate'} caption={'Ставка'} />,
        <Column allowFixing={true} dataField={'totalTechRate'} key={'totalTechRate'} caption={'Общая ставка'} />,
        <Column allowFixing={true} dataField={'currencyName'} caption={'Валюта'} key={'currencyName'} dataType={'string'} />,
        <Column allowFixing={true} dataField={'exchangeRate'} caption={'Курс'} key={'exchangeRate'} />,
        <Column
          allowFixing={true}
          dataField={'totalTechRateCurrency'}
          caption={'Общая стоимость в валюте договора'}
          key={'totalRateCurrency'}
        />,
      ];
    else
      return [
        statisticStore.periodType === 'month' ? (
          <Column
            allowFixing={true}
            dataField={'isExcept'}
            key={'isExcept'}
            caption={'Исключить'}
            dataType={'boolean'}
            cellRender={(props) => {
              if (canChange) return <StatisticExceptDxCell dxCell={props} onChange={this.exceptChange} />;
              else return null;
            }}
          />
        ) : null,
        <Column allowFixing={true} dataField={'date'} key={'date'} caption={'Дата'} dataType={'date'} />,
        <Column allowFixing={true} dataField={'number'} key={'number'} caption={'Номер'} />,
        <Column allowFixing={true} dataField={'catalogsName'} key={'catalogsName'} caption={'Подразделения'} />,
        <Column allowFixing={true} dataField={'countryName'} key={'countryName'} caption={'Государство'} />,
        isAdmin ? <Column allowFixing={true} dataField={'sourceName'} key={'sourceName'} caption={'Источник'} /> : null,
        isAdmin ? <Column allowFixing={true} dataField={'billingProfileName'} key={'billingProfileName'} caption={'Профиль'} /> : null,
        <Column allowFixing={true} dataField={'uniqOperationCount'} key={'uniqOperationCount'} caption={'Операций (уник.)'} />,
        <Column allowFixing={true} dataField={'operationCount'} key={'operationCount'} caption={'Операций (всего)'} />,
        <Column allowFixing={true} dataField={'dayCount'} key={'dayCount'} caption={'Дней'} />,
        <Column allowFixing={true} dataField={'repeats'} key={'repeats'} caption={'Повторы'} />,
        <Column allowFixing={true} dataField={'isValid'} key={'isValid'} caption={'Валидность'} />,
        <Column allowFixing={true} dataField={'beginInterval'} key={'beginInterval'} caption={'Начало интервала'} dataType={'date'} />,
        <Column allowFixing={true} dataField={'endInterval'} key={'endInterval'} caption={'Окончание интервала'} dataType={'date'} />,
        <Column allowFixing={true} dataField={'beginTrack'} key={'beginTrack'} caption={'Начало слежения'} dataType={'date'} />,
        <Column allowFixing={true} dataField={'endTrack'} key={'endTrack'} caption={'Окончание слежения'} dataType={'date'} />,
        <Column allowFixing={true} dataField={'wagonRate'} key={'wagonRate'} caption={'Ставка на страну'} />,
        <Column allowFixing={true} dataField={'totalWagonRate'} key={'totalWagonRate'} caption={'Общая ставка'} />,
        <Column allowFixing={true} dataField={'currencyName'} caption={'Валюта'} key={'currencyName'} dataType={'string'} />,
        <Column allowFixing={true} dataField={'exchangeRate'} caption={'Курс'} key={'exchangeRate'} />,
        <Column
          allowFixing={true}
          dataField={'totalWagonRateCurrency'}
          caption={'Общая стоимость в валюте договора'}
          key={'totalRateCurrency'}
        />,
        <Summary key={'summary'} calculateCustomSummary={this.calculateSelectedRow} ref={(r) => (this.summary = r)}>
          {this.state.showSummary ? (
            <TotalItem name="uniqOperationCount" summaryType="custom" showInColumn={'uniqOperationCount'} displayFormat={'итого: {0}'} />
          ) : null}
          {this.state.showSummary ? (
            <TotalItem
              name="operationCount"
              summaryType="custom"
              showInColumn="operationCount"
              skipEmptyValues={true}
              displayFormat={'итого: {0}'}
            />
          ) : null}
          {this.state.showSummary ? (
            <TotalItem name="dayCount" showInColumn="dayCount" summaryType="custom" displayFormat={'дней: {0}'} />
          ) : null}
          {this.state.showSummary ? (
            <TotalItem name="repeats" showInColumn="repeats" summaryType="custom" displayFormat={'повторов: {0}'} />
          ) : null}
          {this.state.showSummary ? (
            <TotalItem name="wagonRate" showInColumn="wagonRate" summaryType="custom" skipEmptyValues={true} displayFormat={'итого: {0}'} />
          ) : null}
          {this.state.showSummary ? (
            <TotalItem name="totalWagonRate" showInColumn="totalWagonRate" summaryType="custom" skipEmptyValues={true} />
          ) : null}

          {this.state.showSummary ? (
            <TotalItem name="techRate" showInColumn="techRate" summaryType="custom" skipEmptyValues={true} displayFormat={'итого: {0}'} />
          ) : null}
          {this.state.showSummary ? (
            <TotalItem name="totalTechRate" showInColumn="totalTechRate" summaryType="custom" skipEmptyValues={true} />
          ) : null}
        </Summary>,
      ];
  }

  exceptChange = () => {
    this.setState({ showSummary: false });
    setTimeout(() => this.setState({ showSummary: true }), 100);
  };

  calculateSelectedRow(options) {
    if (options.summaryProcess === 'start') {
      options.totalValue = 0;
    } else if (options.summaryProcess === 'calculate') {
      if (options.value.isExcept !== true) {
        options.totalValue = options.totalValue + options.value[options.name];
      }
    }
  }

  onExporting = (e) => {
    const workbook = new this.ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Лист1');
    const name = 'Статистика';

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: { row: 1, column: 1 },
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;
      },
    }).then(function () {
      const date = moment().format(GLOBAL_DATE_FORMAT).toString();
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), date + `-Отчёт-${name}.xlsx`);
      });
    });
    e.cancel = true;
  };

  renderForm() {
    const companies = companiesStore.companiesShort.slice().sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    const isAdmin = this.isAdmin;
    return [
      isAdmin ? (
        <Select
          showSearch
          key={'search'}
          filterOption={filterOptionFn}
          dropdownMatchSelectWidth={false}
          placeholder="Компания"
          style={{ width: '144px' }}
          onSelect={(e: string) => (statisticStore.companyId = e)}>
          {companies.map((c) => {
            return (
              <Select.Option key={c.companyId} value={c.companyId}>
                {c.name}
              </Select.Option>
            );
          })}
        </Select>
      ) : null,
      <Select
        key={'periodType'}
        dropdownMatchSelectWidth={false}
        placeholder="Период"
        style={{ width: '144px' }}
        onSelect={(e: 'date' | 'month') => {
          statisticStore.periodType = e;
        }}>
        <Select.Option value={'month'}>Месяц</Select.Option>
        <Select.Option value={'date'}>Интервал</Select.Option>
      </Select>,
      statisticStore.periodType === 'month' ? (
        <DatePicker
          key={'date'}
          defaultValue={moment()}
          picker={'month'}
          allowClear={false}
          format={'MMMM YYYY'}
          onChange={(e) => (statisticStore.date = e)}
        />
      ) : null,
      statisticStore.periodType === 'date' ? (
        <DatePicker.RangePicker
          key={'range'}
          defaultValue={[moment(), moment()]}
          picker={'date'}
          allowClear={true}
          format={GLOBAL_DATE_FORMAT}
          onChange={(e) => {
            if (e && e.length) {
              statisticStore.begin = e[0].startOf('day');
              statisticStore.end = e[1].endOf('day');
            }
          }}
        />
      ) : null,
      <Dropdown
        key={'report'}
        trigger={['click']}
        disabled={!statisticStore.companyId}
        overlay={
          <Menu onClick={this.handleMenuClick}>
            <Menu.Item key="0">Вагоны</Menu.Item>
            <Menu.Item key="3">Tex. информация</Menu.Item>
            <Menu.Item key="1">Контейнеры</Menu.Item>
            <Menu.Item key="2">Справки</Menu.Item>
          </Menu>
        }>
        <Button>
          {statisticStore.type == 0
            ? 'Вагоны'
            : statisticStore.type == 3
              ? 'Tex. информация'
              : statisticStore.type == 1
                ? 'Контейнеры'
                : statisticStore.type == 2
                  ? 'Справки'
                  : 'Отчеты'}{' '}
          <DownOutlined />
        </Button>
      </Dropdown>,
      isAdmin ? <CreateButton key={'create'} /> : null,
    ];
  }

  handleMenuClick = (e) => {
    if (statisticStore.periodType == 'date') {
      if (statisticStore.begin && statisticStore.end && moment(statisticStore.begin).month() != moment(statisticStore.end).month()) {
        showErrorNotify('Ошибка диапазона', 'Даты должны быть в одном календарном месяце');
        return;
      }
      statisticStore.custom(e.key);
    } else if (this.isAdmin) statisticStore.admin(e.key);
    else statisticStore.company(e.key);
    this.setState({ loaded: true });
  };
}

export default observer(StatisticPage);
