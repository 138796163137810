import { Tag } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';

import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import { ExtraHeader } from '../extra-header';

export const RegisterOfBetsCreateHeader = observer(() => {
  const { bidRegistry } = registerOfBetsStore;

  return (
    <AdaptivePageHeader
      title="Создание ставки"
      tags={
        bidRegistry && bidRegistry.state ? (
          <Tag color="blue" style={{ marginLeft: 4 }}>
            {BID_REGISTRY_STATUS_LIST[bidRegistry.state]}
          </Tag>
        ) : (
          []
        )
      }
      extra={<ExtraHeader />}
    />
  );
});

const BID_REGISTRY_STATUS_LIST = {
  onagr: 'На исполнении',
  draft: 'Черновик',
  agr: 'Согласована',
};
