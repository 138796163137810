import { Checkbox, List, Modal, Radio, Row, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import { TariffAttributeJsonModel, TariffCountryDetailModel } from '@stores/sendsStore/sendData';

interface State {
  plainData: { [key: number]: TariffAttributeJsonModel[] };
  emptyData: { [key: number]: TariffAttributeJsonModel[] };
  paromData: TariffAttributeJsonModel;
  countryData: TariffCountryDetailModel[];
  visible: boolean;
  stateToggler: boolean;
}

const paromGroupCodes = [1075];

interface Props {}

class OptionsAttributes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      stateToggler: false,
      plainData: this.plainData,
      emptyData: this.emptyData,
      paromData: this.paromData,
      countryData: optionsStore.result && optionsStore.result.countryDetails ? optionsStore.result.countryDetails : [],
    };
  }
  componentDidMount() {
    optionsStore.attributesPopoverVisible = false;
  }

  get paromData(): TariffAttributeJsonModel {
    if (!optionsStore.result || !optionsStore.result.tariffDetailData) return null;
    const find = optionsStore.result.tariffDetailData.attributes.find((a) => paromGroupCodes.includes(a.attributeGroupCode));
    if (find) {
      return JSON.parse(JSON.stringify(find));
    }
    return null;
  }

  get hasParomChange() {
    const saveData = this.state.paromData;
    if (!saveData) return false;
    const currenData = this.paromData;
    if (!currenData) return false;
    return saveData.isEnabled != currenData.isEnabled;
  }

  get plainData() {
    if (!optionsStore.result || !optionsStore.result.tariffDetailData) return {};
    let data: { [key: number]: TariffAttributeJsonModel[] } = {};
    optionsStore.result.tariffDetailData.attributes
      .filter((x) => !x.isEmpty)
      .forEach((a) => {
        let item = data[a.attributeGroupCode];
        if (!item) item = [];
        item.push(a);
        data[a.attributeGroupCode] = item;
      });
    return data;
  }

  get emptyData() {
    if (!optionsStore.result || !optionsStore.result.tariffDetailData) return {};
    let data: { [key: number]: TariffAttributeJsonModel[] } = {};
    optionsStore.result.tariffDetailData.attributes
      .filter((x) => x.isEmpty)
      .forEach((a) => {
        let item = data[a.attributeGroupCode];
        if (!item) item = [];
        item.push(a);
        data[a.attributeGroupCode] = item;
      });
    return data;
  }
  handleSubmit = () => {
    let data = [];
    Object.values(this.state.plainData).forEach((v) => {
      data = data.concat(v);
    });
    Object.values(this.state.emptyData).forEach((v) => {
      data = data.concat(v);
    });
    optionsStore.result.tariffDetailData.attributes = data;
    optionsStore.request.tariffData.attributes = [];
    optionsStore.request.tariffData.attributes = data.filter((it) => !!it.isChangeable);
    optionsStore.result.countryDetails = this.state.countryData;
    optionsStore.request.tariffData.questions = [];
    if (!this.hasParomChange) {
      this.state.countryData
        .filter((c) => c.countryDetailData && c.countryDetailData.questions && c.countryDetailData.questions.length)
        .forEach((c) => {
          optionsStore.request.tariffData.questions = optionsStore.request.tariffData.questions.concat(c.countryDetailData.questions);
        });
    }
    optionsStore.calcTariff().then(() => {
      this.setState({ visible: false });
      optionsStore.showAttributesModal = false;
    });
  };

  handleChange = (e, item) => {
    item.isEnabled = e.target.checked ? 1 : 0;
    this.forceUpdate();
  };

  handleOpen = () => {
    this.setState({
      visible: true,
      plainData: this.plainData,
      emptyData: this.emptyData,
    });
  };

  handleRadioChange = (item: TariffAttributeJsonModel, group: TariffAttributeJsonModel[]) => {
    group.forEach((it) => (it.isEnabled = 0));
    item.isEnabled = 1;
    this.setState({ stateToggler: !this.state.stateToggler });
  };

  renderGroup(items: TariffAttributeJsonModel[]) {
    const defaultSelected = items.find((it) => it.isEnabled);
    return (
      <Radio.Group defaultValue={defaultSelected ? defaultSelected.attributeCode : null}>
        {items.map((it) => (
          <Row key={it.attributeGroupCode + ':' + it.attributeCode}>
            <Radio
              disabled={!it.isChangeable}
              onClick={() => this.handleRadioChange(it, items)}
              checked={!!it.isEnabled}
              value={it.attributeCode}>
              {it.attributeName}
            </Radio>
          </Row>
        ))}
      </Radio.Group>
    );
  }

  renderSingle(items: TariffAttributeJsonModel[]) {
    const item = items[0];
    return (
      <>
        <Checkbox disabled={!item.isChangeable} checked={item.isEnabled == 1} onChange={(e) => this.handleChange(e, item)}>
          {item.attributeName}
        </Checkbox>
        {/*<span style={{ marginLeft: '4px' }}>{item.attributeName}</span>*/}
        {item.attributeName !== item.attributeDescription && <>{item.attributeDescription}</>}
      </>
    );
  }

  render() {
    const plainData = this.state.plainData;
    const emptyData = this.state.emptyData;
    const isBusy = optionsStore.isBusy;
    const questionData = this.state.countryData.filter(
      (c) => c.countryDetailData && c.countryDetailData.questions && c.countryDetailData.questions.length,
    );
    const isMobile = designStore.isMobile;
    const hasEmpty = !!Object.keys(emptyData).length;
    return (
      <>
        <Modal
          title={
            <div>
              <span>Дополнительные параметры перевозки</span>
            </div>
          }
          onOk={() => this.handleSubmit()}
          width={isMobile ? '100%' : '800px'}
          centered={isMobile}
          okText={'Пересчитать'}
          cancelText={'Закрыть'}
          okButtonProps={{ loading: isBusy }}
          open={true}
          onCancel={() => (optionsStore.showAttributesModal = false)}>
          <Tabs>
            <Tabs.TabPane tab="Общие" key="1">
              {hasEmpty && <h4>Груженый рейс</h4>}
              <List
                itemLayout="vertical"
                bordered
                size={'large'}
                dataSource={Object.values(plainData)}
                renderItem={(item) => <List.Item>{item.length == 1 ? this.renderSingle(item) : this.renderGroup(item)}</List.Item>}
              />
              {hasEmpty && <h4>Порожний возврат</h4>}
              {hasEmpty && (
                <List
                  itemLayout="vertical"
                  bordered
                  size={'large'}
                  dataSource={Object.values(emptyData)}
                  renderItem={(item) => <List.Item>{item.length == 1 ? this.renderSingle(item) : this.renderGroup(item)}</List.Item>}
                />
              )}
            </Tabs.TabPane>
            {questionData.length && !this.hasParomChange && (
              <Tabs.TabPane tab="Вопросный блок" key="2">
                <ScrolledContainer className={'scrolledContainer'} style={{ width: '100%' }}>
                  <table>
                    <thead className={'ant-table-thead'}>
                      <tr>
                        <th className="ant-table-cell">Страна</th>
                        <th className="ant-table-cell">
                          <Checkbox checked={false} />
                        </th>
                        <th className="ant-table-cell">Описание</th>
                        <th className="ant-table-cell">Номер документа</th>
                      </tr>
                    </thead>
                    {questionData.map((item) => (
                      <tbody key={item.countryCode} className={'ant-table-tbody'}>
                        {item.countryDetailData.questions.map((c, index) => (
                          <tr className="ant-table-row ant-table-row-level-0" key={'c' + index + item.countryCode}>
                            <td className="ant-table-cell">
                              {item.countryName} {item.isEmpty ? '(возврат)' : null}
                            </td>
                            <td className="ant-table-cell">
                              <Checkbox checked={c.isEnabled == 1} onChange={(e) => this.handleChange(e, c)} />
                            </td>
                            <td className="ant-table-cell">{c.description}</td>
                            <td className="ant-table-cell">{c.documentNumber}</td>
                          </tr>
                        ))}
                      </tbody>
                    ))}
                  </table>
                </ScrolledContainer>
              </Tabs.TabPane>
            )}
          </Tabs>
        </Modal>
        {/*<Button onClick={() => this.setState({ visible: true })} title={'Признаки'} size={'small'} type={'text'}>
          Признаки
        </Button>*/}
      </>
    );
  }
}

export default observer(OptionsAttributes);
