import { Layout } from 'antd';
import React from 'react';

import { dataSource } from './lib';
import { SupplierBidRegistryHeader as Header } from './ui/header';
import { SupplierBidRegistryTable as Table } from './ui/table';

export const SupplierBidRegistryPage = () => {
  return (
    <div data-test="supplier-list-of-rates-page">
      <Header dataSource={dataSource} />
      <Layout.Content style={LAYOUT_STYLE}>
        <Table dataSource={dataSource} />
      </Layout.Content>
    </div>
  );
};

const LAYOUT_STYLE = { height: 'calc(100vh - var(--header-height))' };
