import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ActiveContainer } from '@stores/trackingStore/container/ContainerTypes';
import trackingContainerStore from '@stores/trackingStore/container/trackingContainerStore';

import { showSuccessNotify } from '@utils/notify';

interface ContainerGroupRemoveProps {
  onDataReady?: () => void;
  data: ActiveContainer[] | null;
}

interface ContainerGroupRemoveState {}

@observer
class ContainerGroupRemove extends Component<ContainerGroupRemoveProps, ContainerGroupRemoveState> {
  constructor(props: ContainerGroupRemoveProps) {
    super(props);

    this.state = {};
  }

  postData() {
    trackingContainerStore
      .remove({
        numbers: this.props.data.map((w) => w.number.toString()),
      })
      .then(() => {
        this.setState({ visible: false });
        this.props.onDataReady();
        showSuccessNotify('Снятие контейнеров', 'Выбранные контейнеры сняты со слежения');
      });
  }

  render() {
    const hasData = !!this.props.data && this.props.data.length;
    return (
      <>
        <Popconfirm title={'Снять контейнеры?'} onConfirm={this.postData.bind(this)}>
          <Button size={'small'} type={'link'} icon={<DeleteOutlined />} disabled={!hasData}>
            cнять выбранные
          </Button>
        </Popconfirm>
      </>
    );
  }
}

export default ContainerGroupRemove;
