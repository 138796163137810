import HttpClient from '@utils/httpClient';

import {
  CompanyServiceModel,
  CompanyServiceSetModel,
  ServiceStrategyModel,
  SupplierModel,
  SupplierRequisitesModel,
} from './adminBillingData';

const getController = (type: string) => {
  if (type == 'contract') return 'serviceSetContract';
  if (type == 'ext') return 'serviceSetExtContract';
};

const service = {
  async getStrategies(): Promise<ServiceStrategyModel[]> {
    return (await HttpClient.get(`/api/companyservice/strategies`)).data;
  },

  async getServices(): Promise<CompanyServiceModel[]> {
    return (await HttpClient.get(`/api/companyservice/data`)).data;
  },

  async addService(data: CompanyServiceModel): Promise<void> {
    return (await HttpClient.post(`/api/companyservice`, data)).data;
  },

  async updateService(data: CompanyServiceModel): Promise<void> {
    return (await HttpClient.put(`/api/companyservice`, data)).data;
  },

  async removeService(serviceId: string): Promise<void> {
    return (await HttpClient.delete(`/api/companyservice/${serviceId}`)).data;
  },

  async getSuppliers(): Promise<SupplierModel[]> {
    return (await HttpClient.get(`/api/supplier/data`)).data;
  },
  async getSupplierInfo(supplierId: string): Promise<SupplierRequisitesModel> {
    return (await HttpClient.get(`/api/supplier/get/${supplierId}`)).data;
  },

  async addSupplier(data: SupplierRequisitesModel): Promise<void> {
    return (await HttpClient.post(`/api/supplier`, data)).data;
  },

  async updateSupplier(data: SupplierRequisitesModel): Promise<void> {
    return (await HttpClient.put(`/api/supplier`, data)).data;
  },

  async removeSupplier(supplierId: string): Promise<void> {
    return (await HttpClient.delete(`/api/supplier/delete/${supplierId}`)).data;
  },

  async getServiceSets(companyId: string): Promise<CompanyServiceSetModel[]> {
    return (await HttpClient.get(`/api/companyservice/set/data/${companyId}`)).data;
  },

  async addServiceSet(data: CompanyServiceSetModel): Promise<void> {
    return (await HttpClient.post(`/api/companyservice/set`, data)).data;
  },

  async updateServiceSet(data: CompanyServiceSetModel): Promise<void> {
    return (await HttpClient.put(`/api/companyservice/set`, data)).data;
  },

  async removeServiceSet(serviceSetId: string): Promise<void> {
    return (await HttpClient.delete(`/api/companyservice/set/${serviceSetId}`)).data;
  },

  async getAttachment(templateMetadataId: string, type: string): Promise<Blob> {
    return (
      await HttpClient.get(`/api/${getController(type)}/attachment/${templateMetadataId}`, {
        responseType: 'blob',
      })
    ).data;
  },

  async updateAttachment(data: any, templateMetadataId: string, type: string): Promise<void> {
    const formData = new FormData();
    formData.append('file', data);
    return (
      await HttpClient.post(`/api/${getController(type)}/attachment/${templateMetadataId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    ).data;
  },
  async deleteAttachment(templateMetadataId: string, type: string): Promise<void> {
    return (await HttpClient.delete(`/api/${getController(type)}/attachment/${templateMetadataId}`)).data;
  },

  async contractGetDemoFile(data: any, type: string, progressFunc: ((e: ProgressEvent) => void) | undefined = undefined): Promise<Blob> {
    return (
      await HttpClient.post(`/api/${getController(type)}/demoFile`, data, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
      })
    ).data;
  },
  async contractGetFile(
    templateMetadataId: string,
    type: string,
    progressFunc: ((e: ProgressEvent) => void) | undefined = undefined,
  ): Promise<Blob> {
    return (
      await HttpClient.get(`/api/${getController(type)}/contract`, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
        params: { templateMetadataId },
      })
    ).data;
  },
};

export default service;
