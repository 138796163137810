import { Button, Modal } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import usersStore from '@stores/usersStore/usersStore';

import { showSuccessNotify } from '@utils/notify';

interface Props {}

interface State {
  visible: boolean;
}

class UserProfileDeleteModal extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleConfirm = () => {
    this.setState({ visible: false }, () => {
      usersStore
        .removeRequest()
        .finally()
        .then(() => {
          showSuccessNotify('Запрос на удаление отправлен');
        });
    });
  };

  onOpen = () => {
    this.setState({ visible: true }, () => {});
  };

  handleClose = () => {
    this.setState({ visible: false }, () => {});
  };

  render() {
    const { visible } = this.state;

    return (
      <>
        <Button onClick={this.onOpen} type="primary" danger style={{ float: 'right' }}>
          Удалить профиль
        </Button>
        <Modal
          title="Удалить профиль"
          destroyOnClose={true}
          open={visible}
          onCancel={this.handleClose}
          footer={
            <>
              <Button onClick={this.handleConfirm} type="primary" danger>
                Да
              </Button>
              <Button onClick={this.handleClose} type="primary">
                Нет
              </Button>
            </>
          }
        />
      </>
    );
  }
}

export default observer(UserProfileDeleteModal);
