import { Button, Form, FormInstance, Input, Layout, Result } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import styles from '@components/routed/LoginPage/LoginPage.module.scss';

import userStoreService from '@stores/userStore/userStore.service';

const { Content } = Layout;

interface State {
  isBusy: boolean;
  isSuccess: boolean;
}

class PasswordRestorePage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      isSuccess: false,
    };
  }

  form: FormInstance;
  render() {
    return (
      <Layout className={styles.container}>
        <Content className={styles.content}>
          <AdaptiveCard title={'Смена пароля'} className={styles.card} style={{ background: 'white' }}>
            {this.state.isSuccess ? (
              <Result
                status={'success'}
                title={'Новый пароль установлен'}
                extra={
                  <Button type={'primary'} onClick={() => this.props.history.push('/sign-in')}>
                    Перейти на страницу авторизации
                  </Button>
                }
              />
            ) : (
              <Form
                ref={(ref) => (this.form = ref)}
                layout={'vertical'}
                //labelCol={{ span: 5 }}
                onFinish={this.handleSubmit}
                onFinishFailed={this.onFinishFailed}>
                <Form.Item
                  style={{ margin: 10 }}
                  label="Новый пароль"
                  name="newPassword"
                  hasFeedback
                  rules={[{ required: true, message: '' }]}>
                  <Input.Password maxLength={15} size={'large'} />
                </Form.Item>
                <Form.Item
                  style={{ margin: 10 }}
                  label="Новый пароль еще раз"
                  name="newPassword2"
                  hasFeedback
                  rules={[
                    { required: true, message: '' },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.reject(' ');
                        }
                        if (getFieldValue('newPassword') != value) {
                          return Promise.reject('Пароли должны быть одинаковые');
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}>
                  <Input.Password maxLength={15} size={'large'} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 5, span: 18 }} style={{ margin: 10 }}>
                  <Button type="primary" htmlType="submit" loading={this.state.isBusy}>
                    Установить пароль
                  </Button>
                  <Button onClick={() => this.props.history.push('/sign-in')}>Отменить</Button>
                </Form.Item>
              </Form>
            )}
          </AdaptiveCard>
        </Content>
      </Layout>
    );
  }

  handleSubmit = (event) => {
    this.setState({ isBusy: true });
    userStoreService
      .restore({ newPassword: event.newPassword, token: this.token })
      .then(() => {
        this.setState({ isSuccess: true });
      })
      .finally(() => this.setState({ isBusy: false }));
  };

  get token() {
    const { params } = this.props.match;
    return params['token'];
  }

  onFinishFailed = (error) => {
    console.log('Failed:', error);
  };
}

export default observer(PasswordRestorePage);
