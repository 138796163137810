import Icon from '@ant-design/icons';
import { Checkbox, Col, Form, Popover, Row, Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';

import organizationsStore from '@stores/organizationsStore/organizationsStore';

import { showErrorNotify, showSuccessNotify } from '@utils/notify';

const { Option } = Select;
const FULL_NAME_HELP_TEXT = 'Укажите в именительном падеже';

interface ComponentProps {
  onDataReady(data): void;
  address: string;
  reason: string;
}

interface ComponentState {
  isBusy: boolean;
  address: any;
  isMatchesMailAddress: boolean;
  isMatchesFactAddress: boolean;
  reason: string;
}

@observer
class RequisitsForm extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isBusy: false,
      isMatchesMailAddress: false,
      isMatchesFactAddress: false,
      address: this.props.address,
      reason: this.props.reason,
    };
  }

  handleIsMatchesAddressMailToggle = () => {
    this.setState({ isMatchesMailAddress: !this.state.isMatchesMailAddress });
    this.props.onDataReady({ addressMail: this.state.address });
  };

  handleIsMatchesAddressFactToggle = () => {
    this.setState({ isMatchesFactAddress: !this.state.isMatchesFactAddress });
    this.props.onDataReady({ addressFact: this.state.address });
  };

  handleInnChange = (e) => {
    this.setState({ isBusy: true });
    organizationsStore
      .getRequisitesByInn(e)
      .then((ans) => {
        if (ans.shortNameReq) {
          this.props.onDataReady(ans);
          showSuccessNotify(ans.shortNameReq, 'Данные подставлены в форму');
        } else {
          showErrorNotify('Компания не найдена', 'Не удалось найти копанию с инн ' + e);
        }
      })
      .finally(() => this.setState({ isBusy: false }));
  };

  handleAddressChange = (event) => {
    this.setState({ address: event.target.value });
  };

  handleReasonChange = (item) => {
    this.setState({ reason: item });
  };

  innTemplate = (item) => {
    return (
      <>
        <p style={{ margin: 0 }}>{item.inn}</p>
        <p style={{ fontSize: '8px', margin: 0 }}>{item.name}</p>
      </>
    );
  };

  render() {
    const isBusy = this.state.isBusy;
    const isMatchesMailAddress = this.state.isMatchesMailAddress;
    const isMatchesFactAddress = this.state.isMatchesFactAddress;

    return (
      <Spin spinning={isBusy}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item label="Автоматическое заполнение реквизитов по ИНН или названию" wrapperCol={{ style: { width: '100%' } }}>
              <RemoteSelect
                float={true}
                placeholder={'для поиска используйте ИНН или название организации'}
                dataSource={organizationsStore.findRequisites}
                autoClearSearchValue={false}
                valueExpr={'inn'}
                displayExpr={'name'}
                primitiveResult={true}
                onChange={this.handleInnChange}
                itemTemplate={this.innTemplate}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item name={'fullNameReq'}>
              <FloatInput placeholder={'Полное наименование'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <Form.Item name={'telephone'}>
              <FloatInput placeholder={'Телефон'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <Form.Item name={'email'}>
              <FloatInput placeholder={'Email'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item name={'shortNameReq'}>
              <FloatInput placeholder={'Наименование сокращенное'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={3}>
            <Form.Item name="inn">
              <FloatInput placeholder="ИНН" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={3}>
            <Form.Item name={'okpo'}>
              <FloatInput placeholder={'ОКПО'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <Form.Item name={'view'}>
              <FloatSelect placeholder={'Вид'}>
                <Option value="ul">Юридическое лицо</Option>
                <Option value="fl">Физическое лицо</Option>
              </FloatSelect>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item name={'address'}>
              <FloatInput placeholder={'Юридический адрес'} onChange={(event) => this.handleAddressChange(event)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={3}>
            <Form.Item name={'kpp'}>
              <FloatInput placeholder={'КПП'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={3}>
            <Form.Item name={'ogrn'}>
              <FloatInput placeholder={'ОГРН'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <Form.Item name={'country'}>
              <FloatInput placeholder={'Страна'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              //labelCol={{ span: 24 }}
              extra={
                <>
                  <Checkbox checked={isMatchesFactAddress} onChange={this.handleIsMatchesAddressFactToggle}>
                    Совпадает с юридическим
                  </Checkbox>
                </>
              }
              name={'addressFact'}>
              <FloatInput placeholder={'Фактический адрес'} disabled={isMatchesFactAddress} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              //labelCol={{ span: 24 }}
              extra={
                <>
                  <Checkbox checked={isMatchesMailAddress} onChange={this.handleIsMatchesAddressMailToggle}>
                    Совпадает юридическим
                  </Checkbox>
                </>
              }
              name={'addressMail'}>
              <FloatInput placeholder={'Почтовый адрес'} disabled={isMatchesMailAddress} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item name={'owner'}>
              <FloatInput
                placeholder={
                  <span>
                    Владелец&nbsp;
                    <Popover
                      style={{ width: '200px' }}
                      trigger="hover"
                      content={<div style={{ width: '200px' }}>{FULL_NAME_HELP_TEXT}</div>}>
                      <Icon component={iconQuestion} style={{ cursor: 'pointer' }} />
                    </Popover>
                  </span>
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item name={'glbuh'}>
              <FloatInput
                placeholder={
                  <span>
                    Главный бухгалтер ФИО&nbsp;
                    <Popover
                      style={{ width: '200px' }}
                      trigger="hover"
                      content={<div style={{ width: '200px' }}>{FULL_NAME_HELP_TEXT}</div>}>
                      <Icon component={iconQuestion} style={{ cursor: 'pointer' }} />
                    </Popover>
                  </span>
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item name={'signer'}>
              <FloatInput
                placeholder={
                  <span>
                    ФИО подписанта&nbsp;
                    <Popover
                      style={{ width: '200px' }}
                      trigger="hover"
                      content={<div style={{ width: '200px' }}>{FULL_NAME_HELP_TEXT}</div>}>
                      <Icon component={iconQuestion} style={{ cursor: 'pointer' }} />
                    </Popover>
                  </span>
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item name={'post'}>
              <FloatInput
                placeholder={
                  <span>
                    Должность&nbsp;
                    <Popover
                      style={{ width: '200px' }}
                      trigger="hover"
                      content={<div style={{ width: '200px' }}>{FULL_NAME_HELP_TEXT}</div>}>
                      <Icon component={iconQuestion} style={{ cursor: 'pointer' }} />
                    </Popover>
                  </span>
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <Form.Item name={'reason'}>
              <FloatSelect placeholder={'Действует на основании'} onSelect={this.handleReasonChange}>
                <Option value="ul">Устава</Option>
                <Option value="fl">Доверенности</Option>
              </FloatSelect>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={18}>
            <Form.Item name={'attorney'}>
              <FloatInput placeholder={'Доверенность №, дата, срок действия'} disabled={this.state.reason === 'ul'} />
            </Form.Item>
          </Col>

          {/*<Col xs={24} sm={24} md={12} lg={6}>
            <Form.Item label={'ОКФС'} name={'okfs'}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <Form.Item label={'ОКТМО'} name={'oktmo'}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item label={'ОКОПФ'} name={'okopf'}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item label={'ОКОГУ'} name={'okogu'}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item label={'ОКАТО'} name={'okato'}>
              <Input />
            </Form.Item>
          </Col>*/}
          {/*<Col xs={24} sm={24} md={8} lg={8}>
            <Form.Item label={'Группа'} name={'group'}>
              <Input/>
            </Form.Item>
          </Col>*/}
        </Row>
      </Spin>
    );
  }
}

export default RequisitsForm;
