import { DownloadOutlined, ReloadOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { Component } from 'react';

import { RecursiveSvp, recursiveSpvCodes } from '@stores/requestsStore/RequestsData';
import requestsStore from '@stores/requestsStore/requestsStore';
import requestsService from '@stores/requestsStore/requestsStore.service';

import RequestDetail from './RequestDetail';

interface Props {
  dxCell: any;
}

interface State {
  value: string;
  isBusyDownload: boolean;
  isBusyReload: boolean;
  showDetail: boolean;
}

class RequestActionDxCell extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: this.props.dxCell.value,
      isBusyDownload: false,
      isBusyReload: false,
      showDetail: false,
    };
  }

  render() {
    return (
      <>
        <Button size={'small'} icon={<SnippetsOutlined />} title={'открыть'} onClick={this.onOpen} />
        <Button
          size={'small'}
          icon={<DownloadOutlined />}
          loading={this.state.isBusyDownload}
          title={'скачать'}
          onClick={this.onDownload}
        />
        <Button
          size={'small'}
          icon={<ReloadOutlined />}
          loading={this.state.isBusyReload}
          title={'запросить заново'}
          onClick={this.onReload}
        />
        {this.state.showDetail ? <RequestDetail requestId={this.state.value} onClose={this.onClose} /> : null}
      </>
    );
  }

  onOpen = () => {
    this.setState({ showDetail: true });
  };
  onClose = () => {
    this.setState({ showDetail: false });
  };
  onDownload = () => {
    this.setState({ isBusyDownload: true });
    let promise;
    if (recursiveSpvCodes.includes(this.props.dxCell.data.requesttypecode)) {
      promise = this.makeTextFile(this.props.dxCell.value);
    } else {
      promise = requestsService.download(this.props.dxCell.value);
    }
    promise
      .then((ans) => {
        let mockEvent = document.createEvent('MouseEvents');
        let mockElement = document.createElement('a');
        mockElement.download = this.props.dxCell.value + '.txt';
        mockElement.href = window.URL.createObjectURL(new Blob([ans]));
        mockElement.dataset.downloadurl = ['blob', mockElement.download, mockElement.href].join(':');
        mockEvent.initEvent('click');
        mockElement.dispatchEvent(mockEvent);
        requestsStore.onWatch(this.props.dxCell.value);
      })
      .finally(() => {
        this.setState({ isBusyDownload: false });
      });
  };
  makeTextFile = async (id: string) => {
    return requestsService.getInfo(id).then((ans) => {
      const parsed: RecursiveSvp = JSON.parse(ans.responseContent);
      return new Blob([this.makeRecursiveSpv(parsed.items)], { type: 'text/plain' });
    });
  };

  makeRecursiveSpv = (data: RecursiveSvp[]): string => {
    let result = '';

    data.forEach((svp, index) => {
      if (svp.type.name == 'Request') {
        if (index !== 0) {
          result += '\n\n\n';
        }
        result += '          ' + svp.value + '\n' + '--------------------------------------------------------------------------------\n';
      }
      if (svp.type.name == 'Group' || svp.type.name == 'MergedGroup') {
        result += '\n     ' + svp.value + '\n\n';
      }
      if (svp.type.name == 'Field') {
        result += (svp.name + ': ').padEnd(30, ' ') + svp.value + '\n';
      }
      if (svp.items && svp.items.length) {
        svp.items.forEach((i) => (result += this.makeRecursiveSpv([i])));
      }
    });

    /*if (data.type.name == "MergedGroup") {
      const item0 = data.items.find(i => i.order == 0)
      result += '\n     ' + data.value.padEnd(20, " ") + '  ' + data.items.map(it => it.value.padEnd(20, " ")).join("  ") + "\n";
      if (item0 && item0.items) {
        item0.items.forEach(item_name => {
          result += item_name.name.padEnd(20, " ") + "  "
          if (data.items) {
            data.items.forEach((item_value, index) => {
              const find = item_value.items && item_value.items.length ? item_value.items.find(i => i.order == item_name.order) : null
              const val = find?find.value:""
              result += val.padEnd(20, "")
            })
          }
          result+= "\n"
        })
      }
    }*/
    return result;
  };

  onReload = () => {
    this.setState({ isBusyReload: true });
    requestsStore
      .onReload(this.props.dxCell.value)
      .then(() => {})
      .finally(() => this.setState({ isBusyReload: false }));
  };
}

export default RequestActionDxCell;
