import HttpClient from '@utils/httpClient';

import { CompanyModel } from './companyData';

const service = {
  getCompanies(): Promise<CompanyModel[]> {
    return HttpClient.get(`/api/company/list`).then((resp) => {
      return resp.data;
    });
  },

  getCompaniesShort(): Promise<CompanyModel[]> {
    return HttpClient.get(`/api/company/listShort`).then((resp) => {
      return resp.data;
    });
  },

  getCompany(companyId: string): Promise<CompanyModel> {
    return HttpClient.get(`/api/company/list/${companyId}`).then((resp) => {
      return resp.data;
    });
  },

  create(data: any) {
    return HttpClient.post(`/api/company/create`, data).then((resp) => {
      return resp.data;
    });
  },

  update(data: any) {
    return HttpClient.post(`/api/company/update`, data).then((resp) => {
      return resp.data;
    });
  },

  remove(companyId: string) {
    return HttpClient.delete(`/api/company/remove/${companyId}`).then((resp) => {
      return resp.data;
    });
  },

  async getLogo(companyId: string, progressFunc: ((e: ProgressEvent) => void) | undefined = undefined): Promise<Blob> {
    return (
      await HttpClient.get('/api/company/logo/' + companyId, {
        onDownloadProgress: progressFunc,
        responseType: 'blob',
      })
    ).data;
  },

  async deleteLogo(companyId: string): Promise<void> {
    return (await HttpClient.delete('/api/company/logo/' + companyId)).data;
  },

  async getFacsimile(companyId: string, type: string): Promise<Blob> {
    return (
      await HttpClient.get(`/api/company${type}/` + companyId, {
        responseType: 'blob',
      })
    ).data;
  },

  async addFacsimile(image: File, companyId: string, type: string): Promise<void> {
    let fm = new FormData();
    fm.append('file', image);
    return (
      await HttpClient.post(`/api/company${type}/${companyId}`, fm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    ).data;
  },

  async delFacsimile(companyId: string, type: string): Promise<void> {
    return (await HttpClient.delete(`/api/company${type}/${companyId}`)).data;
  },

  async getReportTypes(companyId: string): Promise<string[]> {
    return (await HttpClient.get('/api/company/report-types/' + companyId)).data;
  },
};

export default service;
