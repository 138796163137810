import { Form, Input } from 'antd';
import React from 'react';

import styles from '@components/fw/Fields/field.module.scss';

import { FieldInputProps as Props } from './field-input.types';

export const FieldInput = ({ name, ...inputNUmberProps }: Props) => {
  return (
    <Form.Item className={styles.fieldFlexAuto} name={name}>
      <Input {...inputNUmberProps} />
    </Form.Item>
  );
};
