import { CheckOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';

import styles from './TagEditor.module.scss';

interface ColorSelectorProps {
  value?: any;
  onChange?: (value: any) => void;
}

const ColorSelector: React.FC<ColorSelectorProps> = (props) => {
  const colors = [
    { color: 'magenta', name: 'пурпурно-красный' },
    { color: 'red', name: 'красный' },
    { color: 'volcano', name: 'лавовый' },
    { color: 'orange', name: 'оранжевый' },
    { color: 'gold', name: 'золотой' },
    { color: 'lime', name: 'салатовый' },
    { color: 'green', name: 'зеленый' },
    { color: 'cyan', name: 'циановый' },
    { color: 'blue', name: 'синий' },
    { color: 'geekblue', name: 'темно-синий' },
    { color: 'purple', name: 'фиолетовый' },
  ];
  return (
    <div className={styles.color_selector}>
      {colors.map((item) => {
        const isChecked = props.value === item.color;
        return (
          <Tag color={item.color} key={item.color} onClick={() => props.onChange(item.color)} className={styles.color_selector_item}>
            {isChecked ? <CheckOutlined style={{ marginRight: 5 }} /> : null}
            {item.name}
          </Tag>
        );
      })}
    </div>
  );
};

export default ColorSelector;
