import { Button, Col, Form, Input, Row, Select, Spin, Tabs, Tag } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import CommentsBlock from '@components/fw/CommentsBlock';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import TagEditor from '@components/fw/TagEditor';
import TariffDetail from '@components/fw/TarifDetail';
import SendBoardDetail from '@components/routed/SendsPage/TableView/SendBoardDetail';

import { CommentModel } from '@stores/claimCommentsStore';
import claimCommentsStore from '@stores/claimCommentsStore/claimCommentsStore';
import { ClaimLimitationsModel } from '@stores/claimsStore/ClaimData';
import claimsStore from '@stores/claimsStore/claimsStore';
import designStore from '@stores/designStore/designStore';
import dictStore from '@stores/dictStore/dictStore';
import organizationsStore from '@stores/organizationsStore';
import { SendModel, emptySend } from '@stores/sendsStore/sendData';
import sendsStore from '@stores/sendsStore/sendsStore';
import userStore from '@stores/userStore/userStore';
import usersStore from '@stores/usersStore/usersStore';

import { showSuccessNotify } from '@utils/notify';

interface SendInfoState {
  isBusy: boolean;
  isBusySetDone: boolean;
  isBusyOnPerformance: boolean;
  isConfirmBusy: boolean;
  currentSend: SendModel;
  sendForTariffSend: SendModel | null;
  limitations: ClaimLimitationsModel;
  validateOnly: boolean;
  selectedShippingType: string | null;
}

interface SendInfoProps extends RouteComponentProps {
  sendId?: string;
  claimId?: string;
  onChange?(): void;
}

const { TextArea } = Input;

class SendInfo extends Component<SendInfoProps, SendInfoState> {
  formRef: FormInstance;

  constructor(props: SendInfoProps) {
    super(props);

    this.state = {
      isBusy: false,
      isBusySetDone: false,
      isBusyOnPerformance: false,
      isConfirmBusy: false,
      currentSend: emptySend,
      limitations: null,
      sendForTariffSend: null,
      validateOnly: false,
      selectedShippingType: null,
    };
  }

  componentDidMount(): void {
    organizationsStore.getUserOrganizations();
    if (!this.isEmbedded) {
      claimsStore.getClaims({ statusCodes: ['onper', 'agr', 'done'], claimType: 'wagon_provision' });
    }
    usersStore.getUsers({ notRoles: ['org'] });
    const sendId = this.sendId;
    if (sendId) {
      claimCommentsStore.getSendComments(sendId);
      sendsStore.getSendSchedules(sendId);
      this.loadSend(sendId);
    } else if (this.props.claimId) {
      this.loadLimitations(this.props.claimId);
    }
  }

  async loadSend(sendId) {
    this.setState({ isBusy: true });
    try {
      const result: SendModel = await sendsStore.getSend(sendId);
      this.setState({ currentSend: result });
      if (!this.isEmbedded) {
        document.title = `Отправка ${result.sendName}`;
      }
      this.setState({ sendForTariffSend: result });
      if (result.shippingTypeId) {
        this.setState({ selectedShippingType: result.shippingTypeId });
      }
      if (result.claimId) {
        this.loadLimitations(result.claimId);
      }
      if (this.formRef) {
        this.formRef.resetFields();
      }
    } finally {
      this.setState({ isBusy: false });
    }
  }

  loadLimitations(claimId) {
    claimsStore
      .getLimitation(claimId, this.sendId)
      .finally(() => this.setState({ isBusy: false }))
      .then((limitations: ClaimLimitationsModel) => {
        this.setState({ limitations: limitations });
      });
  }

  emptyReceiverDs = async (search: string) => {
    return Promise.resolve(organizationsStore.getContragentArray(search));
  };

  renderSendActions = (send, isConfirmBusy) => {
    const canStatus = userStore.canAccess('sends/status') && send && this.sendId;
    return userStore.canAccess('sends/edit') ? (
      <CollapsedToolbar
        content={[
          canStatus ? (
            <Button onClick={this.setOnPerformance} disabled={send.statusId === 'onper'} key={'setOnPerformance'}>
              На исполнении
            </Button>
          ) : null,
          canStatus ? (
            <Button onClick={this.setDone} disabled={send.statusId === 'done'} key={'setDone'}>
              Выполнить
            </Button>
          ) : null,
          <Button key={'reset_btn'} form={'claim-editor'} onClick={() => this.formRef.resetFields()}>
            Сброс
          </Button>,
          <Button key={'submit_btn'} type="primary" htmlType="submit" form={'send-editor'} loading={isConfirmBusy}>
            Сохранить
          </Button>,
        ]}
      />
    ) : null;
  };

  render() {
    const isBusy = this.state.isBusy;
    const isBusyComments = claimCommentsStore.isBusy;
    const isBusyConfirmComments = claimCommentsStore.isBusyConfirm;
    const isBusySendSchedules = sendsStore.isBusySendSchedules;
    const isConfirmBusy = this.state.isConfirmBusy;
    const limitations = this.state.limitations;
    const comments = claimCommentsStore.sendComments;
    const claims = this.isEmbedded ? [claimsStore.currentClaim] : claimsStore.claims;
    const users = usersStore.users;
    const tariffIcDictionary = dictStore.tariffIcDictionary;
    const shippingTypeDictionary = dictStore.shippingTypeDictionary;
    const scaleShippingTypeDictionary = dictStore.scaleShippingTypeDictionary;
    const wagonAffiliationDictionary = dictStore.wagonAffiliationDictionary;
    const sendSchedules = sendsStore.sendSchedules;

    const freightSenders = limitations && limitations.freightSenders ? limitations.freightSenders : [];
    const freightReceivers = limitations && limitations.freightReceivers ? limitations.freightReceivers : [];
    const freightCodes = limitations && limitations.freightCodes ? limitations.freightCodes : [];
    const wagonTypes = limitations && limitations.wagonTypes ? limitations.wagonTypes : [];
    const loadStations = limitations && limitations.loadStations ? limitations.loadStations : [];
    const destStations = limitations && limitations.destStations ? limitations.destStations : [];

    let send = this.state.currentSend;
    Object.assign(send, {
      emptyReceiver: send && send.emptyReceiverId ? { organizationId: send.emptyReceiverId, name: send.emptyReceiverName } : null,
    });
    if (this.props.claimId) {
      send.claimId = this.props.claimId;
    }
    const sendName = send ? send.sendName : '';
    const selectedShippingType = this.state.selectedShippingType;
    const selectedClaimId = this.formRef ? this.formRef.getFieldValue('claimId') : send.claimId;
    const freightEtsngCode = this.formRef ? this.formRef.getFieldValue('freightEtsngCode') : null;
    const wagonTypeCode = this.formRef ? this.formRef.getFieldValue('wagonTypeCode') : null;

    const wagonTypeObj = wagonTypeCode && limitations ? limitations.wagonTypes.find((w) => w.wagonTypeCode == wagonTypeCode) : null;

    const height = this.isEmbedded ? 'calc(100vh - 135px)' : 'calc(100vh - 65px)';

    const onEtsngChange = (state: string) => {
      this.handleFormChange();
      if (state) {
        const find = limitations.freightCodes.find((c) => c.etsng.freightEtsngCode == state);
        if (find) {
          this.formRef.setFieldsValue({ freightGngCode: find.gng.gngCode });
        }
      }
    };

    if (!this.isEditMode && !this.isEmbedded) {
      document.title = 'Создание Отправки';
    }

    const isMobile = designStore.isMobile;

    return (
      <>
        {this.isEmbedded ? (
          <AdaptivePageHeader
            title={'Отправка'}
            subTitle={!isMobile && <span style={{ marginLeft: 10, color: 'black' }}>{sendName}</span>}
            tags={send && send.statusName ? <Tag color="blue">{send.statusName}</Tag> : null}
            extra={<span style={{ marginRight: 40 }}>{this.renderSendActions(send, isConfirmBusy)}</span>}
          />
        ) : (
          <AdaptivePageHeader
            title={'Отправка'}
            subTitle={!isMobile && <span style={{ marginLeft: 10, color: 'black' }}>{sendName}</span>}
            tags={send && send.statusName ? <Tag color="blue">{send.statusName}</Tag> : null}
            className={'page_header'}
            extra={this.renderSendActions(send, isConfirmBusy)}
          />
        )}
        <ScrolledContainer style={{ height: height }}>
          <AdaptiveCard>
            <Spin spinning={isBusy}>
              <Form
                layout={'vertical'}
                ref={(ref) => (this.formRef = ref)}
                size={'large'}
                name={'send-editor'}
                initialValues={send}
                onFinish={this.handleConfirm}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="sendName" rules={[{ required: true, message: 'Необходимо заполнить поле Название' }]}>
                      <FloatInput placeholder="Название" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="claimId" rules={[{ required: true, message: 'Необходимо выбрать заявку' }]}>
                      <FloatSelect
                        disabled={this.isEmbedded}
                        placeholder="Заявка"
                        onChange={this.handleClaimChange}
                        getPopupContainer={(trigger) => trigger.parentElement}>
                        {claims.map((item, index) => {
                          return (
                            <Select.Option value={item.claimId} key={`claim_${index}`}>
                              {item.claimName}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="executorId">
                      <FloatSelect placeholder="Исполнитель" allowClear getPopupContainer={(trigger) => trigger.parentElement}>
                        {users.map((item, index) => {
                          return (
                            <Select.Option value={item.userId} key={`executor_${index}`}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="tags" label="Тэги" className="sends-tag-editor">
                      <TagEditor readonly={true} />
                    </Form.Item>
                  </Col>
                </Row>

                {selectedClaimId ? (
                  <>
                    <Tabs defaultActiveKey="1" style={{ padding: 1 }}>
                      <Tabs.TabPane tab="Параметры отправки" key="1">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item name="freightSenderOkpoId">
                              <FloatSelect placeholder="Отправитель" allowClear getPopupContainer={(trigger) => trigger.parentElement}>
                                {freightSenders.map((item, index) => {
                                  return (
                                    <Select.Option value={item.organizationId} key={`freightSender_${index}`}>
                                      {item.name}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item name="freightReceiverOkpoId">
                              <FloatSelect placeholder="Получатель" allowClear getPopupContainer={(trigger) => trigger.parentElement}>
                                {freightReceivers.map((item, index) => {
                                  return (
                                    <Select.Option value={item.organizationId} key={`freightReceiver_${index}`}>
                                      {item.name}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item name="loadStationCode" rules={[{ required: true, message: 'Необходимо заполнить' }]}>
                              <FloatSelect placeholder="Станция отправления" allowClear onChange={this.handleFormChange}>
                                {loadStations.map((item, index) => {
                                  return (
                                    <Select.Option value={item.stationCode} key={`freightSender_${index}`}>
                                      {item.fullName}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item name="destStationCode" rules={[{ required: true, message: 'Необходимо заполнить' }]}>
                              <FloatSelect placeholder="Станция назначения" allowClear onChange={this.handleFormChange}>
                                {destStations.map((item, index) => {
                                  return (
                                    <Select.Option value={item.stationCode} key={`freightReceiver_${index}`}>
                                      {item.fullName}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          {/*<Col xs={24} sm={24} md={8}>
                                <Form.Item name="qq" label="Станции погранпереходов">
                                  <RemoteSelect
                                    //disabled={true}
                                    mode={'multiple'}
                                    placeholder={'Наименование или ж.д. код'}
                                    valueExpr={'stationCode'}
                                    displayExpr={'stationName'}

                                    dataSourceLib={dictService.borderStations}
                                    onChange={this.handleFormChange}
                                  />
                                </Form.Item>
                              </Col>*/}
                          <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                            <Form.Item name="costFact">
                              <FloatInputNumber step={0.1} min={0} placeholder={'Ставка факт,руб'} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={8} xl={8} style={{ paddingTop: 'calc(var(--gap) / 2)' }}>
                            <FloatInput
                              readOnly
                              value={
                                this.state.sendForTariffSend && this.state.sendForTariffSend.tariffTotalPrice
                                  ? this.state.sendForTariffSend.tariffTotalPrice.toLocaleString('ru', {
                                      maximumFractionDigits: 0,
                                    }) + ' ₽ '
                                  : 'не расчитана'
                              }
                              placeholder={'Ставка расчетная'}
                            />
                            <TariffDetail
                              value={this.state.sendForTariffSend}
                              onOpen={this.handleTariffOpen}
                              onDataReady={this.handleTarifCalc}
                            />
                          </Col>
                        </Row>
                        <h4>Наименование груза</h4>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item name="freightEtsngCode" rules={[{ required: true, message: 'Необходимо заполнить поле' }]}>
                              <FloatSelect placeholder="Наименование ЕТСНГ" allowClear onChange={onEtsngChange}>
                                {freightCodes.map((item, index) => {
                                  return (
                                    <Select.Option value={item.etsng.freightEtsngCode} key={`freightEtsng_${index}`}>
                                      {item.etsng.fullName}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item name="freightGngCode">
                              <FloatSelect placeholder={'Наименование ГНГ'} disabled>
                                {freightCodes
                                  .filter((f) => f.etsng.freightEtsngCode == freightEtsngCode)
                                  .map((item, index) => {
                                    return (
                                      <Select.Option value={item.gng.gngCode} key={`freightEtsng_${index}`}>
                                        {item.gng.fullName}
                                      </Select.Option>
                                    );
                                  })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                        </Row>

                        <h4>Группа подвижного состава</h4>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                            <Form.Item name="wagonTypeCode">
                              <FloatSelect
                                placeholder="Тип вагона"
                                onChange={(e) => {
                                  const find = limitations.wagonTypes.find((w) => w.wagonTypeCode == e);
                                  if (find) {
                                    this.formRef.setFieldsValue({ wagonAffiliationCode: find.affiliationCode });
                                    this.forceUpdate();
                                  }
                                }}>
                                {wagonTypes.map((item, index) => {
                                  return (
                                    <Select.Option value={item.wagonTypeCode} key={`wagonType_${index}`}>
                                      <div>{item.wagonTypeName}</div>
                                      <span style={{ color: 'darkgrey', fontSize: 9, marginRight: 4 }}>
                                        Всего: {item.wagonCount}/{item.freightWeight}
                                      </span>
                                      <span style={{ color: 'darkgrey', fontSize: 9 }}>
                                        Размечено: {item.wagonCountMapped}/{item.freightWeightMapped}
                                      </span>
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item name="wagonAffiliationCode">
                              <FloatSelect placeholder="Принадлежность вагона" disabled>
                                {wagonAffiliationDictionary.map((item, index) => {
                                  return (
                                    <Select.Option value={item.affiliationCode} key={`wagonAffiliation_${index}`}>
                                      {item.affiliationName}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                            <Form.Item name="shippingTypeId" rules={[{ required: true, message: 'Необходимо выбрать Вид отправки' }]}>
                              <FloatSelect placeholder="Вид отправки" onChange={(value: string) => this.handleShippingTypeChange(value)}>
                                {shippingTypeDictionary.map((item, index) => {
                                  return (
                                    <Select.Option value={item.shippingTypeId} key={`shippingType_${index}`}>
                                      {item.shippingTypeName}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                            <Form.Item name="scaleShippingTypeId">
                              <FloatSelect placeholder="Вид отправки" allowClear>
                                {scaleShippingTypeDictionary.map((item, index) => {
                                  return (
                                    <Select.Option value={item.shippingTypeId} key={`shippingType_${index}`}>
                                      {item.shippingTypeName}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                            <Form.Item
                              name="freightWeight"
                              required={selectedShippingType != null}
                              rules={[
                                () => ({
                                  validator(rule, value) {
                                    if (!wagonTypeObj) {
                                      return Promise.reject(`Выберите тип вагона`);
                                    }
                                    if (!wagonTypeObj.freightWeight) {
                                      return Promise.reject(`Невозможно указать вес, проверьте заявку `);
                                    }
                                    if (value > wagonTypeObj.freightWeight - wagonTypeObj.freightWeightMapped) {
                                      return Promise.reject(`Не более ${wagonTypeObj.freightWeight - wagonTypeObj.freightWeightMapped}`);
                                    }
                                    if (selectedShippingType == 'single' && value > 140) {
                                      return Promise.reject(`Одиночная отправка не более 140т`);
                                    } else if ((selectedShippingType == 'group' || selectedShippingType == 'route') && value > 1000) {
                                      return Promise.reject(`Групповая или Маршрутная отправка не более 1000т`);
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}>
                              <FloatInputNumber
                                step={0.001}
                                min={0.001}
                                onInput={this.handleFormChange}
                                decimalSeparator={'.'}
                                placeholder="Вес груза в отправке (т)"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                            <Form.Item
                              name="wagonCount"
                              rules={[
                                () => ({
                                  validator(rule, value) {
                                    if (!wagonTypeObj) {
                                      return Promise.reject(`Выберите тип вагона`);
                                    }
                                    if (!wagonTypeObj.wagonCount) {
                                      return Promise.reject(`Невозможно указать число вагонов, проверьте заявку`);
                                    }
                                    if (value > wagonTypeObj.wagonCount - wagonTypeObj.wagonCountMapped) {
                                      return Promise.reject(`Не более ${wagonTypeObj.wagonCount - wagonTypeObj.wagonCountMapped}`);
                                    }
                                    if (selectedShippingType == 'single' && value > 1) {
                                      return Promise.reject(`При одиночной отправке максимум 1`);
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}>
                              <FloatInputNumber min={0} precision={0} onInput={this.handleFormChange} placeholder="Количество вагонов" />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                            <Form.Item name="loadTariffIcId">
                              <FloatSelect placeholder="Груженый тариф" allowClear getPopupContainer={(trigger) => trigger.parentElement}>
                                {tariffIcDictionary.map((item, index) => {
                                  return (
                                    <Select.Option value={item.tariffIcId} key={`tariffIc_${index}`}>
                                      {item.tariffIcName}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                            <Form.Item name="emptyTariffIcId">
                              <FloatSelect
                                placeholder="Порожний тариф (подсыл)"
                                allowClear
                                getPopupContainer={(trigger) => trigger.parentElement}>
                                {tariffIcDictionary.map((item, index) => {
                                  return (
                                    <Select.Option value={item.tariffIcId} key={`tariffIc_${index}`}>
                                      {item.tariffIcName}
                                    </Select.Option>
                                  );
                                })}
                              </FloatSelect>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item name="emptyReceiver">
                              <RemoteSelect
                                float={true}
                                placeholder={'Получатель порожняка'}
                                valueExpr={'organizationId'}
                                displayExpr={'name'}
                                dataSource={this.emptyReceiverDs}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          {/*                              <Col xs={24} sm={24} md={12}>
                                <Form.Item name="qwq" label="Договор">
                                  <RemoteSelect
                                    disabled={true}
                                    mode={'multiple'}
                                    placeholder={'Договор'}
                                    valueExpr={'stationCode'}
                                    displayExpr={'fullName'}
                                    dataSourceLib={dictService.stationDict}
                                  />
                                </Form.Item>
                              </Col>*/}
                        </Row>
                        <h4>Дополнительно</h4>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={8} md={4}>
                            <Form.Item name="gu12" label="Номер ГУ-12">
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item name="specialConditions" label="Особые условия">
                              <TextArea rows={3} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Tabs.TabPane>
                      {this.sendId && send ? (
                        <Tabs.TabPane tab="График подач" key="2">
                          {!isConfirmBusy && <SendBoardDetail send={send} />}
                        </Tabs.TabPane>
                      ) : null}
                    </Tabs>
                  </>
                ) : null}
              </Form>
            </Spin>

            {this.sendId ? (
              <Spin spinning={isBusyComments}>
                <CommentsBlock
                  comments={comments}
                  currentUser={userStore.userData}
                  onAdd={this.onAddComment}
                  onDelete={this.onDeleteComment}
                  isBusyConfirm={isBusyConfirmComments}
                />
              </Spin>
            ) : null}
          </AdaptiveCard>
        </ScrolledContainer>
      </>
    );
  }

  get isEditMode(): boolean {
    return this.props.match && this.props.match.path !== '/sends/create-send' && !this.isEmbedded;
  }

  get sendId(): string {
    if (this.isEmbedded) {
      return this.props.sendId;
    } else {
      const { params } = this.props.match;
      const fromParams = params['sendId'];
      const fromProps = this.props.sendId;
      return fromParams ? fromParams : fromProps;
    }
  }

  setDone = () => {
    this.setState({ isBusySetDone: true });
    sendsStore
      .setStatus(this.sendId, 'done')
      .finally(() => this.setState({ isBusySetDone: false }))
      .then(() => {
        this.loadSend(this.sendId);
        showSuccessNotify('Отправка выполнена');
      });
  };

  setOnPerformance = () => {
    this.setState({ isBusyOnPerformance: true });
    sendsStore
      .setStatus(this.sendId, 'onper')
      .finally(() => this.setState({ isBusyOnPerformance: false }))
      .then(() => {
        this.loadSend(this.sendId);
        showSuccessNotify('Отправка на исполнении');
      });
  };

  onAddComment = (text: string) => {
    const comment = {
      sendId: this.sendId,
      comment: text,
    };

    claimCommentsStore.addSendComment(comment);
  };

  onDeleteComment = (comment: CommentModel) => {
    claimCommentsStore.deleteSendComment(comment, this.sendId);
  };

  handleShippingTypeChange = (shippingTypeId: string) => {
    if (this.formRef) {
      const send = this.state.currentSend;
      send.wagonCount = this.formRef.getFieldValue('wagonCount');
      this.setState({ currentSend: send, selectedShippingType: shippingTypeId });
      this.formRef.resetFields(['wagonCount']);
    }
    this.handleFormChange();
    this.setState({ selectedShippingType: shippingTypeId });
  };

  handleClaimChange = (claimId: string) => {
    this.handleFormChange();
    if (claimId) {
      this.setState({ isBusy: true });
      claimsStore
        .getLimitation(claimId, this.sendId)
        .finally(() => this.setState({ isBusy: false }))
        .then((limitations: ClaimLimitationsModel) => {
          this.setState({ limitations: limitations });
          if (this.formRef) {
            const freightSenders = limitations && limitations.freightSenders ? limitations.freightSenders : [];
            const freightReceivers = limitations && limitations.freightReceivers ? limitations.freightReceivers : [];
            const freightEtsngCodes = limitations && limitations.freightCodes ? limitations.freightCodes : [];
            const wagonTypes = limitations && limitations.wagonTypes ? limitations.wagonTypes : [];
            const loadStations = limitations && limitations.loadStations ? limitations.loadStations : [];
            const destStations = limitations && limitations.destStations ? limitations.destStations : [];
            this.formRef.setFields([
              {
                name: 'freightSenderOkpoId',
                value: freightSenders && freightSenders.length === 1 ? freightSenders[0].okpo : null,
              },
              {
                name: 'freightReceiverOkpoId',
                value: freightReceivers && freightReceivers.length === 1 ? freightReceivers[0].okpo : null,
              },
              {
                name: 'freightEtsngCode',
                value: freightEtsngCodes && freightEtsngCodes.length === 1 ? freightEtsngCodes[0].etsng.freightEtsngCode : null,
              },
              {
                name: 'freightGngCode',
                value: freightEtsngCodes && freightEtsngCodes.length === 1 ? freightEtsngCodes[0].gng.gngCode : null,
              },
              {
                name: 'destStationCode',
                value: freightEtsngCodes && destStations.length === 1 ? destStations[0].stationCode : null,
              },
              {
                name: 'loadStationCode',
                value: freightEtsngCodes && loadStations.length === 1 ? loadStations[0].stationCode : null,
              },
              {
                name: 'wagonTypeCode',
                value: wagonTypes && wagonTypes.length === 1 ? wagonTypes[0].wagonTypeCode : null,
              },
              {
                name: 'wagonAffiliationCode',
                value: wagonTypes && wagonTypes.length === 1 ? wagonTypes[0].affiliationCode : null,
              },
            ]);
            setTimeout(() => this.forceUpdate(), 100);
          }
        });
    }
  };

  get isEmbedded() {
    return (!!this.props.claimId || this.props.sendId) && !!this.props.onChange;
  }

  handleBack = () => {
    if (this.props.history) {
      this.props.history.goBack();
    }
  };
  handleTariffOpen = () => {
    this.setState({ validateOnly: true });
    this.formRef.submit();
  };

  handleTarifCalc = (price: number, distance: number) => {
    if (!this.state.sendForTariffSend) return;
    const result: SendModel = Object.create(this.state.sendForTariffSend);
    result.tariffTotalPrice = price;
    result.tariffTotalDistance = distance;
    this.setState({ sendForTariffSend: result });
  };

  handleFormChange = () => {
    this.setState({ sendForTariffSend: null });
  };

  handleConfirm = (event) => {
    const send = Object.assign(
      {
        ...event,
      },
      {
        sendId: this.sendId,
        emptyReceiverId: event.emptyReceiver ? event.emptyReceiver.organizationId : null,
        emptyReceiverName: event.emptyReceiver ? event.emptyReceiver.name : null,
        scaleShippingTypeCode: event.scaleShippingTypeId
          ? dictStore.scaleShippingTypeDictionary.find((d) => d.shippingTypeId == event.scaleShippingTypeId).shippingTypeCode
          : null,
        shippingTypeCode: event.shippingTypeId
          ? dictStore.shippingTypeDictionary.find((d) => d.shippingTypeId == event.shippingTypeId).shippingTypeCode
          : null,
      },
    );
    if (this.state.validateOnly) {
      this.setState({ sendForTariffSend: send });
      this.setState({ validateOnly: false });
      return;
    }
    this.setState({
      isConfirmBusy: true,
    });

    if (this.isEditMode) {
      this.setState({ isConfirmBusy: true });
      sendsStore
        .updateSend(send)
        .finally(() => this.setState({ isConfirmBusy: false }))
        .then(() => {
          this.setState({
            currentSend: Object.assign({ ...this.state.currentSend }, { sendName: send.sendName }),
          });
          showSuccessNotify('Отправка сохранена');
          if (this.isEmbedded) {
            this.props.onChange();
          }
        });
    } else {
      document.title = 'Отправка';
      sendsStore
        .createSend(send)
        .finally(() => this.setState({ isConfirmBusy: false }))
        .then((result) => {
          showSuccessNotify('Отправка создана');
          if (this.isEmbedded) {
            this.props.onChange();
          } else {
            this.props.history.goBack();
          }
        });
    }
  };
}

export default observer(SendInfo);
