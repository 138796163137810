import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import optionsStore, { currencyNameCropper } from '@stores/optionsStore/optionsStore';
import { TARIFF_FIELDS } from '@stores/sendsStore/sendData';

interface FwProps {}

interface ComponentState {}

class WegthStepprtResult extends Component<FwProps, ComponentState> {
  constructor(props: FwProps) {
    super(props);

    this.state = {};
  }

  render() {
    const data = optionsStore.result.weightStepperList;
    if (!data || !data.length) {
      return null;
    }
    const fields = TARIFF_FIELDS.filter((t) => t.calc);
    const currency = optionsStore.selectedCurrency;

    return (
      <div>
        <ScrolledContainer style={{ padding: '1rem' }}>
          <div className="weight">
            <table>
              <thead>
                <th>Вес</th>
                {fields.map((value, index) => {
                  return <th key={'th' + index}>{value.name}</th>;
                })}
                <th>НДС (за 1 тонну)</th>
                <th>c НДС (за 1 тонну)</th>
                <th style={{ borderRadius: '0 0 0 5px' }}>Валюта</th>
              </thead>
              <tbody>
                {data.map((v, index) => {
                  const value = v.aggTariffCountryDetail;
                  return (
                    <tr key={index}>
                      <td>{v.weight}</td>
                      {fields.map((field, index) => {
                        return (
                          <td key={'td' + index} style={{ textAlign: 'right' }}>
                            {field.calc
                              ? value[field.fieldPrefix + currency.filedPostfix + field.fieldLastChars]?.toLocaleString('ru', {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })
                              : value[field.fieldPrefix]}
                          </td>
                        );
                      })}
                      <td>
                        {(value['freightCharge' + currency.filedPostfix + 'Vat'] / v.weight)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td>
                        {(value['freightCharge' + currency.filedPostfix + 'WithVat'] / v.weight)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td style={{ borderRadius: '0 0 5px 0' }}>{currencyNameCropper(currency.name)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </ScrolledContainer>
      </div>
    );
  }
}

export default observer(WegthStepprtResult);
