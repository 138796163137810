import { Button, Drawer, Form, Input, Popconfirm, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import designStore from '@stores/designStore';
import messengerStore from '@stores/messengerStore/messengerStore';
import userStore from '@stores/userStore/userStore';
import usersStore from '@stores/usersStore/usersStore';

interface MessageDialogEditorProps {}

interface MessageDialogEditorState {}

@observer
class MessageDialogEditor extends Component<MessageDialogEditorProps, MessageDialogEditorState> {
  render() {
    const userData = userStore.userData;
    const loadingConfirm = messengerStore.isBusyConfirm;
    const isBusyDeleteConfirm = messengerStore.isBusyDeleteConfirm;
    const dialog = messengerStore.selectedDialog;
    const usersFromDialog = dialog ? dialog.users.filter((x) => x.userId !== userData.userId) : [];
    let users = usersStore.users;
    usersFromDialog.forEach((u1) => {
      if (!users.find((u2) => u1.userId == u2.userId)) {
        users = usersStore.users.concat(usersFromDialog);
      }
    });

    const title = this.isEditMode ? dialog.name : 'Новый диалог';
    return (
      <Drawer
        title={title}
        width={designStore.isMobile ? '100%' : 700}
        bodyStyle={{ paddingBottom: 80 }}
        visible={true}
        onClose={this.handelClose}
        footer={
          <div>
            {dialog ? (
              <Popconfirm
                title="Диалог будет удалён. Продолжить?"
                onConfirm={this.handleDeleteConfirm}
                placement="topLeft"
                okText="Да"
                cancelText="Нет">
                <Button danger style={{ float: 'left' }} loading={isBusyDeleteConfirm}>
                  Удалить
                </Button>
              </Popconfirm>
            ) : null}

            <span style={{ float: 'right' }}>
              <Button onClick={this.handelClose} style={{ marginRight: 8 }}>
                Отмена
              </Button>
              <Button type="primary" loading={loadingConfirm} htmlType={'submit'} form={'dialog-editor'}>
                {this.isEditMode ? 'Сохранить' : 'Добавить'}
              </Button>
            </span>
          </div>
        }>
        <Form
          layout="vertical"
          hideRequiredMark
          size={'large'}
          name={'dialog-editor'}
          initialValues={
            dialog
              ? {
                  name: dialog.name,
                  users: usersFromDialog.map((x) => x.userId),
                }
              : { name: messengerStore.newDialogName, users: [] }
          }
          onFinish={this.handleConfirm}>
          <Form.Item name="name" label="Название" rules={[{ required: true, message: 'Необходимо заполнить название' }]}>
            <Input placeholder="Название" />
          </Form.Item>
          <Form.Item name="users" label="Пользователи" rules={[{ required: true, message: 'Необходимо выбрать пользователей' }]}>
            <Select placeholder="Пользователи" mode="multiple" allowClear getPopupContainer={(trigger) => trigger.parentElement}>
              {users.map((item, index) => {
                return (
                  <Select.Option value={item.userId} key={`user_${index}`}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }

  get isEditMode() {
    return messengerStore.selectedDialog && messengerStore.selectedDialog.dialogId;
  }

  handelClose = () => {
    messengerStore.editDialog = false;
    messengerStore.newDialogName = '';
  };

  handleDeleteConfirm = () => {
    messengerStore.removeDialog();
  };

  handleConfirm = (event) => {
    messengerStore.newDialogName = '';
    if (this.isEditMode) {
      messengerStore.updateDialog(event.name, event.users);
    } else {
      messengerStore.createDialog(event.name, event.users);
    }
  };
}

export default MessageDialogEditor;
