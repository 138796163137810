import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { Template } from 'devextreme-react';
import DataGrid, {
  Column,
  Export,
  FilterPanel,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  StateStoring,
} from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import SwitchWithLabel from '@components/fw/FloatLabel/SwitchWithLabel';

import { ClaimModel } from '@stores/claimsStore/ClaimData';
import { CompanyCatalogModel } from '@stores/companyCatalogStore/companyCatalogStore';
import { DocTypeModel } from '@stores/contractTemplateStore/ContractTemplatesData';
import companyTemplateService from '@stores/contractTemplateStore/companyTemplates.service';
import designStore from '@stores/designStore/designStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import organizationsStoreService from '@stores/organizationsStore/organizationsStore.service';
import { TemplateOrgContractModel, TemplateOrgExtContractModel } from '@stores/organizationsStore/organizationsStoreData';
import userStore from '@stores/userStore/userStore';

import ContractActionMenu from './ContractActionMenu';
import ContractEditor from './ContractEditor';
import Exts from './Exts';

interface ComponentProps {
  companyId?: string;
  organizationId: string;
  onChange?(): void;
  selectedContractId?: string;
  claim?: ClaimModel;
}

interface ComponentState {
  data: TemplateOrgContractModel[];
  loading: boolean;
  contractTemplateList: DocTypeModel[];
  extTemplateList: DocTypeModel[];
  showEditor: boolean;
  catalogs?: CompanyCatalogModel[];
  showSupportingOnly: boolean;
}

@observer
class ContractList extends Component<ComponentProps, ComponentState> {
  dataGrid: DataGrid;
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      data: [],
      contractTemplateList: [],
      extTemplateList: [],
      loading: true,
      showEditor: false,
      catalogs: [],
      showSupportingOnly: false,
    };
  }

  componentDidMount() {
    this.getData(this.props.organizationId);
    if (this.props.companyId) {
      companyTemplateService.getContractList(this.props.companyId, 'contract').then((ans) => {
        this.setState({ contractTemplateList: ans });
      });
      companyTemplateService.getContractList(this.props.companyId, 'ext').then((ans) => {
        this.setState({ extTemplateList: ans });
      });
    }
  }

  handleDelete = (item: TemplateOrgContractModel, type: string) => {
    this.setState({ loading: true });
    organizationsStoreService
      .deleteContract(item.templateMetadataId, type)
      .then(() => {})
      .finally(() => this.setState({ loading: false }));
  };

  getData = async (organizationId: string) => {
    this.setState({ loading: true });
    return organizationsStoreService
      .getContractList(organizationId, 'contract')
      .then((ans) => {
        this.setState({ data: ans });
        return ans;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onChange = () => {
    if (this.props.onChange) {
      this.props.onChange();
    }
    this.getData(this.props.organizationId);
  };

  onEdit = (e) => {
    this.setState({ showEditor: true });
  };

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'create',
    });
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'switch',
    });
  };

  createTemplate = () => {
    return (
      <Button icon={<PlusOutlined />} size={'small'} type={'link'} onClick={this.onEdit}>
        Добавить договор
      </Button>
    );
  };

  switchModeTemplate = () => {
    if (!this.props.claim) return null;
    return (
      <SwitchWithLabel
        size={'small'}
        checked={this.state.showSupportingOnly}
        onChange={(e) => {
          this.setState({ showSupportingOnly: e });
        }}>
        Только подходящие
      </SwitchWithLabel>
    );
  };

  filterSupported = (contracts: TemplateOrgContractModel[]): TemplateOrgContractModel[] => {
    let result: TemplateOrgContractModel[] = [];
    const claim = this.props.claim;
    const claimLoadStations = claim.loadStations.map((it) => it.stationCode);
    const claimDestStations = claim.destStations.map((it) => it.stationCode);
    contracts.forEach((c) => {
      let resultExts: TemplateOrgExtContractModel[] = [];
      c.exts.forEach((ext) => {
        if (ext.wagonParams && ext.wagonParams.length) {
          const supportedParams = ext.wagonParams.filter(
            (wp) => claimLoadStations.includes(wp.loadStationCode) && claimDestStations.includes(wp.destStationCode),
          );
          if (supportedParams.length == ext.wagonParams.length) {
            resultExts.push(ext);
          }
        }
      });
      if (resultExts.length) {
        const contract: TemplateOrgContractModel = JSON.parse(JSON.stringify(c));
        contract.exts = resultExts;
        result.push(contract);
      }
    });
    return result;
  };

  render() {
    const { selectedContractId, companyId } = this.props;
    let data = selectedContractId ? this.state.data.filter((c) => c.templateMetadataId === selectedContractId) : this.state.data;
    if (this.state.showSupportingOnly) {
      data = this.filterSupported(data);
    }
    const isMobile = designStore.isMobile;
    const span = isMobile ? 8 : 4;
    return (
      <div>
        {this.state.showEditor && (
          <ContractEditor
            type={'contract'}
            contract={null}
            templateList={this.state.contractTemplateList}
            organizationId={this.props.organizationId}
            id={null}
            onClose={() => this.setState({ showEditor: false })}
            onFinish={this.onChange}
          />
        )}
        <DataGrid
          allowColumnReordering={true}
          dataSource={data}
          ref={(ref) => (this.dataGrid = ref)}
          height={'100%'}
          rowAlternationEnabled={true}
          remoteOperations={false}
          showRowLines={true}
          showBorders={true}
          allowColumnResizing={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          columnMinWidth={20}
          wordWrapEnabled={true}
          onToolbarPreparing={this.onToolbarPreparing}>
          <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'expeditions'} />
          <Paging enabled={true} defaultPageSize={25} />
          <Pager
            allowedPageSizes={[25, 50, 100]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            infoText={'Всего: {2}'}
          />
          <GroupPanel visible={true} emptyPanelText={'Поле группировки'} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Export enabled={true} excelWrapTextEnabled={true} />
          <FilterRow visible={true} />
          <FilterPanel visible={true} />
          <Column allowFixing={true} dataField="contractNumber" caption={'Номер договора'} />
          <Column allowFixing={true} dataField="name" caption={'Наименование'} />
          <Column
            allowFixing={true}
            dataField="beginDate"
            caption={'Дата'}
            cellRender={(e) => moment(e.data.beginDate).format(GLOBAL_DATE_FORMAT)}
          />
          <Column
            allowFixing={true}
            dataField="endDate"
            caption={'Действует до'}
            cellRender={(e) => moment(e.data.endDate).format(GLOBAL_DATE_FORMAT)}
          />
          <Column
            allowFixing={true}
            dataField="signers"
            caption={'Подписали'}
            cellRender={(e) => {
              return (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  {!isMobile && <Col span={16}>{e.data.signers ? e.data.signers.map((s) => <div key={'signer' + s}>{s}</div>) : ''}</Col>}
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <ContractActionMenu
                      type={'contract'}
                      contract={e.data}
                      contractTemplateList={this.state.contractTemplateList}
                      extTemplateList={this.state.extTemplateList}
                      organizationId={e.data.organizationId}
                      id={e.data.templateMetadataId}
                      getData={this.onChange}
                      hasSigners={!!(e.data.signers && e.data.signers.length)}
                    />
                  </Col>
                </Row>
              );
            }}
          />
          <Template name={'create'} render={() => this.createTemplate()} />
          <Template name={'switch'} render={() => this.switchModeTemplate()} />
          <MasterDetail
            //autoExpandAll={true}
            enabled={true}
            component={(e) => (
              <AdaptiveCard>
                <Exts onChange={this.onChange} companyId={companyId} {...e} extTemplateList={this.state.extTemplateList} />
              </AdaptiveCard>
            )}
          />
        </DataGrid>
        {/*<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={1}></Col>
          <Col span={span - 1}>Номер договора</Col>
          <Col span={span}>Наименование</Col>
          {!isMobile && <Col span={span}>Дата</Col>}
          {!isMobile && <Col span={span}>Действует до</Col>}
          {!isMobile && <Col span={span}>Подписали</Col>}
          <Col span={span}></Col>
        </Row>

        {data.map((d) => (
          <Collapse
            defaultActiveKey={!!d.exts.length ? ['1'] : null}
            expandIcon={({ isActive }) => (d.exts.length ? <CaretRightOutlined rotate={isActive ? 90 : 0} /> : null)}
            key={d.templateMetadataId}>
            <Collapse.Panel
              key={'1'}
              header={
                <Row key={d.templateMetadataId} style={{ width: '100%' }}>
                  <Col span={span - 1}>{d.contractNumber}</Col>
                  <Col span={span + 1}>{d.name}</Col>
                  {!isMobile && <Col span={span}>{moment(d.beginDate).format(GLOBAL_DATE_FORMAT)}</Col>}
                  {!isMobile && <Col span={span}>{moment(d.endDate).format(GLOBAL_DATE_FORMAT)}</Col>}
                  {!isMobile && <Col span={span}>{d.signers ? d.signers.map((s) => <div key={s}>{s}</div>) : ''}</Col>}
                  <Col span={span} style={{ textAlign: 'right' }}>
                    <ContractActionMenu
                      catalogs={catalogs}
                      type={'contract'}
                      contract={d}
                      contractTemplateList={this.state.contractTemplateList}
                      extTemplateList={this.state.extTemplateList}
                      organizationId={this.props.organizationId}
                      id={d.templateMetadataId}
                      getData={this.onChange}
                      hasSigners={!!(d.signers && d.signers.length)}
                    />
                  </Col>
                </Row>
              }>
              {d.exts.map((e) => (
                <Row key={e.templateMetadataId}>
                  <Col span={1}> </Col>
                  <Col span={span - 1}>{e.extContractNumber}</Col>
                  <Col span={span}>{e.name}</Col>
                  {!isMobile && <Col span={span}>{moment(e.beginDate).format(GLOBAL_DATE_FORMAT)}</Col>}
                  {!isMobile && <Col span={span}>{moment(e.endDate).format(GLOBAL_DATE_FORMAT)}</Col>}
                  {!isMobile && <Col span={span}>{e.signers ? e.signers.map((s) => <div>{s}</div>) : ''}</Col>}
                  <Col span={span} style={{ textAlign: 'right' }}>
                    <ContractActionMenu
                      catalogs={catalogs}
                      type={'ext'}
                      contract={d}
                      contractTemplateList={this.state.contractTemplateList}
                      extTemplateList={this.state.extTemplateList}
                      organizationId={this.props.organizationId}
                      id={e.templateMetadataId}
                      getData={this.onChange}
                      hasSigners={!!(e.signers && e.signers.length)}
                    />
                  </Col>
                </Row>
              ))}
              {!d.exts.length ? <span>Нет доплнительеных соглашений. добавьте новое</span> : null}
            </Collapse.Panel>
          </Collapse>
        ))}*/}
      </div>
    );
  }
}

export default ContractList;
