import { LoadOptions } from 'devextreme/data/load_options';

import HttpClient from '@utils/httpClient';

import { CompanyLoadRegistryListUpdateModel, CompanyLoadRegistryModel, LoadRegistryStatisticDto } from './loadRegistryData';

const service = {
  async companyLoadRegistry(wagons: number[], clientId: string): Promise<void> {
    return (await HttpClient.post(`/api/companyLoadRegistry`, { wagons, clientId })).data;
  },

  async getStatistic(started: string, ended: string): Promise<LoadRegistryStatisticDto> {
    return (await HttpClient.get(`/api/companyLoadRegistry/statistic?started=${started}&ended=${ended}`)).data;
  },

  async getList(ids: string[]): Promise<CompanyLoadRegistryModel[]> {
    return (await HttpClient.post(`/api/companyLoadRegistry/getList`, ids)).data;
  },

  async getReport(loadOption: LoadOptions, started: string, ended: string): Promise<any> {
    return (await HttpClient.post(`/api/companyLoadRegistry/report?started=${started}&ended=${ended}`, loadOption)).data;
  },

  async deleteCompanyLoadRegistry(id: string): Promise<any> {
    return (await HttpClient.delete(`/api/companyLoadRegistry/${id}`)).data;
  },
  async deleteListCompanyLoadRegistry(data: string[]): Promise<any> {
    return (await HttpClient.post(`/api/companyLoadRegistry/deleteList`, data)).data;
  },
  async updateListCompanyLoadRegistry(data: CompanyLoadRegistryListUpdateModel): Promise<any> {
    return (await HttpClient.put(`/api/companyLoadRegistry`, data)).data;
  },
};

export default service;
