import { action, makeObservable, observable } from 'mobx';

import { TariffCompanyEnabledModel } from './CompanyTariffData';
import service from './companyTariffStore.service';

class CompanySummaryStore {
  @observable tariffCompanies: TariffCompanyEnabledModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action loadCompanies() {
    service.getCompaniesWithTariffEnable().then((ans) => {
      this.tariffCompanies = ans;
    });
  }
}

export default new CompanySummaryStore();
