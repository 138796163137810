import { Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { PageType } from '@components/routed/RegisterOfBetsCreatePage/register-of-bets-create-page.types';
import { TabsProps } from '@components/routed/RegisterOfBetsCreatePage/ui/tabs/tabs.types';

import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import { getExtraTabActions, getTabsItem } from './tab.lib';

export const RegisterOfBetsCreateTabs = observer(({ pageType }: TabsProps) => {
  const [activeKey, setActiveKey] = useState<string>('basic');
  const [form] = useForm();
  const isEdit = pageType === PageType.EDIT;
  const isLoading = registerOfBetsStore.isLoading;

  const onSuccess = () => {
    form.submit();
  };

  const onCancel = () => {
    form.resetFields();
  };
  return (
    <Tabs
      onChange={setActiveKey}
      items={getTabsItem(form, isEdit)}
      tabBarExtraContent={getExtraTabActions(onCancel, onSuccess, activeKey, isLoading)}
      destroyInactiveTabPane
    />
  );
});
