import { CopyOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, FormInstance, Popover } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';

import dispatcherStore from '@stores/dispatcherStore/dispatcherStore';
import dispatcherService from '@stores/dispatcherStore/dispatcherStore.service';
import userStore from '@stores/userStore/userStore';
import usersStore from '@stores/usersStore/usersStore';

import { showErrorNotify, showSuccessNotify } from '@utils/notify';

interface Props {
  reportId: number;
}

interface State {
  showEditor: boolean;
  initData: any;
  loading: boolean;
}

class ReportUsers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showEditor: false,
      initData: {},
      loading: false,
    };
  }

  form: FormInstance;

  componentDidMount(): void {}

  onFinish = async (e) => {
    const selected = Object.keys(e).filter((userId) => !!e[userId]);
    if (!selected.length) {
      showErrorNotify('Укажите пользователей', '');
      return;
    }
    this.setState({ loading: true });
    try {
      await dispatcherService.copyReport({ reportId: this.props.reportId, userIds: selected });
      showSuccessNotify('Отчет скопирован');
      dispatcherStore.getReportList();
      this.setState({ showEditor: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  onOpen = () => {
    usersStore.getUsers().then();
    this.setState({ showEditor: true, initData: {} }, () => {
      if (this.form) {
        this.form.resetFields();
      }
    });
  };
  onClose = () => {
    this.setState({ showEditor: false });
  };

  onSelectAll = (e: boolean) => {
    const initData: any = {};
    usersStore.users.forEach((u) => {
      initData[u.userId] = e;
    });
    this.setState({ initData }, () => {
      this.form.resetFields();
    });
  };

  render() {
    const { showEditor } = this.state;
    const users = usersStore.users;

    return (
      <Popover
        content={
          <Form ref={(ref) => (this.form = ref)} initialValues={this.state.initData} onFinish={this.onFinish}>
            <ScrolledContainer style={{ height: 500 }}>
              {showEditor && <Checkbox onChange={(e) => this.onSelectAll(e.target.checked)}>Выбрать всех</Checkbox>}
              {users.map((u) => {
                return (
                  <Form.Item key={u.userId} name={u.userId} valuePropName={'checked'}>
                    <Checkbox>{u.userId === userStore.userData.userId ? 'Себе' : u.name}</Checkbox>
                  </Form.Item>
                );
              })}
            </ScrolledContainer>

            <Button
              loading={this.state.loading}
              title={'Копировать'}
              size={'small'}
              type="primary"
              htmlType={'submit'}
              className={'dx-button-mode-contained dx-button'}>
              Копировать
            </Button>
            <Button title={'Отмена'} size={'small'} type="primary" onClick={this.onClose} className={'dx-button-mode-contained dx-button'}>
              Отмена
            </Button>
          </Form>
        }
        title="Копировать отчет пользователю"
        trigger="click"
        visible={showEditor}>
        <Button
          onClick={this.onOpen}
          icon={<CopyOutlined className={'dispatcher-icon-gray'} />}
          title={'Копировать отчет пользователю'}
          size={'small'}
          type="text"
          className={'dx-button-mode-contained dx-button'}
        />
      </Popover>
    );
  }
}

export default observer(ReportUsers);
