import { Switch, SwitchProps } from 'antd';
import React, { Component } from 'react';

interface State {}

class SwitchWithLabel extends Component<SwitchProps, State> {
  render() {
    return (
      <span>
        <Switch {...this.props} />
        <span style={{ marginLeft: 10 }}>{this.props.children}</span>
      </span>
    );
  }
}
export default SwitchWithLabel;
