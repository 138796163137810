import { DefaultOptionType } from 'antd/lib/select';
import { useEffect, useMemo, useState } from 'react';

import organizationsStoreService from '@stores/organizationsStore/organizationsStore.service';

export const useContractOptions = (organizationId: string | undefined) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const result = useMemo(() => ({ isLoading, options }), [isLoading, options]);

  useEffect(() => {
    if (!organizationId) return;

    setIsLoading(true);
    organizationsStoreService
      .getContractList(organizationId, 'contract')
      .then((data) => {
        data.map(({ templateMetadataId, contractNumber }) => ({
          value: templateMetadataId,
          label: contractNumber,
        }));
      })
      .finally(() => setIsLoading(false));
  }, [organizationId]);

  return result;
};
