import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';

const defaultLocation = {
  pathname: '/',
  search: '',
  hash: '',
};

class RoutingStore {
  @observable _location = defaultLocation;

  constructor() {
    makeObservable(this);
  }

  @computed
  get location() {
    return this._location;
  }

  @action
  interceptLocation(newLocation: any) {
    if (!_.isEqual(this._location, newLocation)) {
      this._location = newLocation;
    }
  }
}

export default new RoutingStore();
