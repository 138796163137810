import { Collapse } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { CountryInteractionsTable } from '@components/routed/RegisterOfBetsCreatePage/ui/country-interactions-table';
import { ExtraServices } from '@components/routed/RegisterOfBetsCreatePage/ui/extra-services';
import { TariffTable } from '@components/routed/RegisterOfBetsCreatePage/ui/tariff-table';

import styles from './expedition-service.module.scss';

export const ExpeditionService = observer(() => {
  return (
    <Collapse defaultActiveKey={['tariff', 'services']}>
      <Collapse.Panel className={styles.infologCollapse} key="tariff" header="Тариф">
        <CountryInteractionsTable />
        <TariffTable />
      </Collapse.Panel>
      <Collapse.Panel className={styles.infologCollapse} key="services" header="Доп Услуги">
        <ExtraServices />
      </Collapse.Panel>
    </Collapse>
  );
});
