import { Button, Form, FormInstance, Input, Layout, Modal, Result } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { RestoreResponse } from '@stores/userStore/userStore';
import userStoreService from '@stores/userStore/userStore.service';

const { Content } = Layout;

interface Props {
  showPasswordChange: boolean;
  loginForPasswordChange: string;
  onClose(): void;
}

interface State {
  isBusy: boolean;
  restoreResult: RestoreResponse;
}

class PasswordResetModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: false,
      restoreResult: null,
    };
  }
  form: FormInstance;
  render() {
    const result = this.state.restoreResult;
    return (
      <Modal
        open={this.props.showPasswordChange}
        afterClose={() => {
          this.setState({ restoreResult: null });
        }}
        title={'Запросить письмо со ссылкой для смены пароля'}
        okButtonProps={{ loading: this.state.isBusy, style: { display: result ? 'none' : null } }}
        okText={'Запросить'}
        cancelText={'Закрыть'}
        onOk={() => this.form.submit()}
        onCancel={() => this.onClose()}>
        {this.props.showPasswordChange && !result && (
          <Form
            size={'large'}
            ref={(ref) => (this.form = ref)}
            initialValues={{ login: this.props.loginForPasswordChange }}
            onFinish={this.onResetPassword}>
            <br />
            <Form.Item name={'login'} label={'Имя пользователя'}>
              <Input />
            </Form.Item>
          </Form>
        )}
        {result && (
          <div>
            <Result
              status={result.isSuccess ? 'success' : 'warning'}
              title={result.message}
              //subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
              extra={[
                !result.isSuccess ? (
                  <Button type="primary" key="console" onClick={() => this.setState({ restoreResult: null })}>
                    Попробовать еще раз
                  </Button>
                ) : null,
              ]}
            />
          </div>
        )}
      </Modal>
    );
  }

  onResetPassword = (e: { login: string; url: string }) => {
    this.setState({ isBusy: true, restoreResult: null });
    e.url = window.location.href.replace('sign-in', 'reset-password');
    userStoreService
      .restoreRequest(e)
      .then((ans) => {
        this.setState({ restoreResult: ans });
      })
      .finally(() => this.setState({ isBusy: false }));
  };

  onClose = () => {
    this.props.onClose();
  };
}

export default observer(PasswordResetModal);
