import { DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Tooltip } from 'antd';
import { Template } from 'devextreme-react/core/template';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { DocTypeTemplateModel } from '@stores/contractTemplateStore/ContractTemplatesData';
import contractTemplateStore from '@stores/contractTemplateStore/contractTemplateStore';

import { ContractTemplatesEditorDrawer } from './ContractTemplatesEditor.drawer';
import { ContractTemplatesEditorState } from './ContractTemplatesEditor.types';
import { DxCellDocContextRender, onToolbarPreparing } from './ContractTemplatesEditor.utils';

export const ContractTemplatesEditor = observer(
  class ContractTemplatesEditor extends Component<any, ContractTemplatesEditorState> {
    constructor(props: any) {
      super(props);

      this.state = {
        isOpenDrawer: false,
        selectedTemplate: null,
        selectedRowKeys: null,
      };
    }

    onDeleteTemplate = () => {
      if (!this.state.selectedTemplate) return;

      contractTemplateStore.deleteTemplate(this.state.selectedTemplate.docTemplateId).then(this.loadTemplates);
    };

    onSaveTemplate = (template: DocTypeTemplateModel) => {
      contractTemplateStore.createOrUpdateTemplate(template).then(() => contractTemplateStore.loadTemplates());
    };

    onChangeSelectedTemplate = (params: any) => {
      const template = contractTemplateStore.templates.find((template) => template.docTemplateId === params.data.docTemplateId) ?? null;
      this.setState({ selectedTemplate: template, selectedRowKeys: [template] });
    };

    onShowCreator = () => {
      this.setState({
        isOpenDrawer: true,
        selectedRowKeys: null,
        selectedTemplate: { docTemplateId: null, name: '', dataContext: 'contract', data: [] },
      });
    };

    onShowEditor = () => {
      this.setState({ isOpenDrawer: true });
    };

    onCloseEditor = () => {
      this.setState({ isOpenDrawer: false, selectedTemplate: this.state.selectedRowKeys ? this.state.selectedTemplate : null });
    };

    loadTemplates = () => {
      contractTemplateStore.loadTemplates().then(() => {
        this.setState({ selectedTemplate: null, selectedRowKeys: null });
      });
    };

    componentDidMount() {
      this.loadTemplates();
    }

    render() {
      const { isBusyTemplates, templates } = contractTemplateStore;
      const { isOpenDrawer, selectedTemplate } = this.state;

      return (
        <Spin spinning={isBusyTemplates}>
          <DataGrid
            dataSource={templates}
            height={`calc(100vh - 12.2rem)`}
            allowColumnResizing={true}
            showBorders={true}
            columnResizingMode={'widget'}
            onToolbarPreparing={onToolbarPreparing}
            sorting={{ mode: 'multiple' }}
            selection={{ mode: 'single' }}
            selectedRowKeys={this.state.selectedRowKeys}
            onRowClick={this.onChangeSelectedTemplate}>
            <Template
              name={'refresh'}
              render={() => (
                <Tooltip placement={'bottom'} title={'Обновить'}>
                  <Button type={'link'} onClick={this.loadTemplates} icon={<ReloadOutlined />} />
                </Tooltip>
              )}
            />
            <Template
              name={'create'}
              render={() => (
                <Tooltip placement={'bottom'} title={'Создать'}>
                  <Button type={'link'} onClick={this.onShowCreator} icon={<PlusOutlined />} />
                </Tooltip>
              )}
            />
            <Template
              name={'edit'}
              render={() => (
                <Tooltip placement={'bottom'} title={'Редактировать'}>
                  <Button disabled={!selectedTemplate} type={'link'} onClick={this.onShowEditor} icon={<EditOutlined />} />
                </Tooltip>
              )}
            />
            <Template
              name={'delete'}
              render={() => (
                <Popconfirm title={`Удалить шаблон ${selectedTemplate?.name}?`} placement={'leftBottom'} onConfirm={this.onDeleteTemplate}>
                  <Tooltip placement={'bottom'} title={'Удалить'}>
                    <Button disabled={!selectedTemplate} type={'link'} onClick={() => {}} icon={<DeleteOutlined />} />
                  </Tooltip>
                </Popconfirm>
              )}
            />

            <Column allowFixing={true} dataField={'name'} caption={'Наименование'} dataType={'string'} />
            <Column
              allowFixing={true}
              dataField={'dataContext'}
              caption={'Тип шаблона'}
              dataType={'string'}
              headerFilter={{ allowSearch: true }}
              cellRender={DxCellDocContextRender}
            />
          </DataGrid>
          <ContractTemplatesEditorDrawer
            isOpen={isOpenDrawer}
            template={selectedTemplate}
            onSave={this.onSaveTemplate}
            onClose={this.onCloseEditor}
          />
        </Spin>
      );
    }
  },
);
