import { CopyOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DataGrid, { Column, HeaderFilter, Selection, Sorting } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';

import requestIlsStore from '@stores/requestIlsStore/requestIlsStore';
import { CreateRequest } from '@stores/requestIlsStore/requestIlsStoreData';
import userStore from '@stores/userStore';

import RequestIlsClaimForm from './IlsClaimForm/IlsClaimForm';
import './IlsClaimPage.less';

interface ComponentState {
  dataSource: DataSource;
  isShowModal: boolean;
  copyData: CreateRequest | null;
  isLoading: boolean;
}

class IlsClaimPage extends Component<RouteComponentProps, ComponentState> {
  dataGrid: DataGrid;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      dataSource: this.dataSource,
      isShowModal: false,
      copyData: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    document.title = this.title;
  }

  get title() {
    return 'Заявки на перевозки';
  }
  get dataSource() {
    const pKey = 'ilsclaimid';
    return new DataSource({
      store: new CustomStore({
        key: pKey,
        load: function (loadOptions: any) {
          return requestIlsStore
            .load(loadOptions)
            .then((data) => {
              return data;
            })
            .catch(() => {
              throw 'Data Loading Error';
            });
        },
        update: (key, values) => {
          return Promise.all([]);
        },
      }),
    });
  }

  openModalHandler = () => {
    this.setState({ isShowModal: true });
  };

  closeModalHandler = () => {
    this.setState({ isShowModal: false, copyData: null });
    this.dataGrid.instance.refresh();
  };

  render() {
    return (
      <div data-test="ils-page">
        <AdaptivePageHeader title={this.title} extra={null} />

        <AdaptiveCard
          className={'requestIlsPage'}
          title={<h3>Реестр заявок</h3>}
          extra={
            <div className={'wrapperIlsButtons'}>
              <Button
                type={'primary'}
                htmlType={'button'}
                onClick={this.openModalHandler}
                disabled={!userStore.canAccess('ils_claim/edit')}>
                Подать заявку
              </Button>
              <Button
                htmlType={'button'}
                onClick={() => {
                  this.dataGrid.instance.exportToExcel(false);
                }}>
                Скачать в Excel
              </Button>
            </div>
          }>
          <DataGrid
            ref={(r) => (this.dataGrid = r)}
            dataSource={this.state.dataSource}
            showBorders={true}
            allowColumnReordering={true}
            height={'calc(100vh - 183px)'}
            width={'100%'}
            rowAlternationEnabled={true}
            remoteOperations={false}
            showRowLines={true}
            allowColumnResizing={true}
            columnResizingMode={'nextColumn'}
            columnAutoWidth={true}
            columnMinWidth={20}
            wordWrapEnabled={true}>
            <HeaderFilter visible={true} allowSearch={true} />
            <Sorting mode="multiple" />
            <Selection mode="multiple" selectAllMode={'allPages'} showCheckBoxesMode={'always'} />
            <Column
              fixed={true}
              caption={''}
              allowSorting={false}
              allowGrouping={false}
              allowSearch={false}
              allowFiltering={false}
              allowReordering={false}
              cellRender={({ data }) => {
                return (
                  <div className={'ils-action-buttons'}>
                    <Button
                      type={'link'}
                      onClick={() => {
                        requestIlsStore.copy(data.ilsclaimid).then((res) => {
                          this.props.history.replace(`/view-ils-send/${res}`);
                        });
                      }}>
                      <CopyOutlined />
                    </Button>
                    <Button
                      type={'link'}
                      onClick={() => {
                        // this.setState({isLoading: true})
                        requestIlsStore
                          .exportToPdf(data.ilsclaimid)
                          .then((res) => {
                            const url = window.URL.createObjectURL(new Blob([res]));

                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${data.number}.pdf`);
                            const b = document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(b);
                          })
                          .catch(console.log);
                        // .finally(()=>this.setState({isLoading: false}))
                      }}>
                      <FilePdfOutlined />
                    </Button>
                  </div>
                );
              }}
            />

            <Column
              dataField={'number'}
              dataType={'string'}
              caption={'Номер заявки'}
              cellRender={({ data }) => {
                return <Link to={`/view-ils-send/${data.ilsclaimid}`}>{data.number}</Link>;
              }}
            />
            <Column dataField={'createdate'} dataType={'date'} caption={'Дата подачи'} defaultSortIndex={1} defaultSortOrder="desc" />
            <Column dataField={'state'} dataType={'string'} caption={'Статус'} />
            <Column dataField={'begindate'} dataType={'date'} caption={'Дата начала'} />
            <Column dataField={'enddate'} dataType={'date'} caption={'Дата окончания'} />
            <Column dataField={'rps'} dataType={'string'} caption={'РПС'} />
            <Column dataField={'etsng'} dataType={'string'} caption={'Груз ЕТСНГ'} />
            <Column dataField={'loadstationname'} dataType={'string'} caption={'Станция отправления'} />
            <Column dataField={'wagoncount'} dataType={'number'} caption={'Кол-во вагонов'} />
            <Column dataField={'tonnage'} dataType={'number'} caption={'Кол-во тонн'} />
            <Column dataField={'markedwagonscount'} dataType={'number'} caption={'Заадресовано'} />
          </DataGrid>
          {this.state.isShowModal && (
            <RequestIlsClaimForm history={this.props.history} open={this.state.isShowModal} closeModal={this.closeModalHandler} />
          )}
        </AdaptiveCard>
      </div>
    );
  }
}

export default observer(IlsClaimPage);
