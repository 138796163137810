import { Avatar, Button, Comment, Form, Input, List, Popconfirm, Tooltip } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';

import { CommentModel } from '@stores/claimCommentsStore';
import { GLOBAL_DATETIME_FORMAT } from '@stores/optionsStore/optionsStoreData';
import userStore, { UserData } from '@stores/userStore/userStore';

import styles from './CommentsBlock.module.scss';

interface CommentsBlockProps {
  currentUser?: UserData;
  comments: CommentModel[];
  onAdd(text: string): void;
  onDelete(comment: CommentModel): void;
  isBusyConfirm: boolean;
}

const { TextArea } = Input;

const CommentList = ({ comments, onDelete, userData }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${
      comments.length > 1 ? ([2, 3, 4].includes(comments.length) ? 'комментария' : 'комментариев') : 'комментарий'
    }`}
    itemLayout="horizontal"
    renderItem={(props: CommentModel) => {
      return (
        <Comment
          content={<p>{props.comment}</p>}
          author={<a>{props.position === 'right' ? 'Вы' : props.userName}</a>}
          className={props.position !== 'right' ? styles.comment : classNames(styles.comment, styles.my_comment)}
          // style={{borderLeft: props.position === 'right' ? '2px solid blue' : 0}}
          datetime={
            <Tooltip title={moment(props.createDate).format(GLOBAL_DATETIME_FORMAT)}>
              <span>{moment(props.createDate).fromNow()}</span>
            </Tooltip>
          }
          actions={
            userData && userData.userId === props.userId
              ? [
                  <Popconfirm title="Удалить комментарий？" okText="Да" cancelText="Нет" onConfirm={() => onDelete(props)}>
                    <span>Удалить</span>
                  </Popconfirm>,
                ]
              : []
          }
          avatar={
            <Avatar style={{ verticalAlign: 'middle' }} alt={props.userName}>
              {props.userName}
            </Avatar>
          }
        />
      );
    }}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <Form size={'large'}>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item style={{ paddingTop: '24px' }}>
      <Button size={'large'} htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Добавить комментарий
      </Button>
    </Form.Item>
  </Form>
);

class CommentsBlock extends Component<CommentsBlockProps> {
  state = {
    comments: [],
    value: '',
  };
  render() {
    const comments = this.props.comments;
    const isBusyConfirm = this.props.isBusyConfirm;
    const userData = this.props.currentUser;

    const { value } = this.state;
    return (
      <div>
        <h4>Комментарии</h4>
        {comments.length > 0 && <CommentList userData={userData} comments={comments} onDelete={this.props.onDelete} />}
        <Comment
          avatar={<Avatar style={{ verticalAlign: 'middle' }}>{userStore.userData.userName}</Avatar>}
          content={<Editor onChange={this.handleChange} onSubmit={this.handleSubmit} submitting={isBusyConfirm} value={value} />}
        />
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleSubmit = () => {
    if (!this.state.value) {
      return;
    }

    this.props.onAdd(this.state.value);

    this.setState({
      value: '',
    });
  };
}

export default CommentsBlock;
