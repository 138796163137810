import { Col, Row } from 'antd';
import React, { Component } from 'react';

interface EtranCaptionProps {
  title: string;
}

class EtranCaption extends Component<EtranCaptionProps> {
  constructor(props: EtranCaptionProps) {
    super(props);
  }

  render() {
    const title = this.props.title;

    return (
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <span className={'etranCaption'}>{title}</span>
        </Col>
      </Row>
    );
  }
}

export default EtranCaption;
