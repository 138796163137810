import { observer } from 'mobx-react';
import React from 'react';
// @ts-ignore
import { __RouterContext as OriginalRouterContext } from 'react-router';

import routingStore from '@stores/fw/routingStore';

@observer
class RoutingContext extends React.Component<any> {
  render() {
    return (
      <OriginalRouterContext.Consumer>
        {(context: any) => {
          const location = this.props.location || context.location;
          if (routingStore && location) {
            routingStore.interceptLocation(location);
          }
          return this.props.children;
        }}
      </OriginalRouterContext.Consumer>
    );
  }
}

export default RoutingContext;
