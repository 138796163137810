import { Spin } from 'antd';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import LoadingRegisterSummary from '@components/routed/LoadingRegister/LoadingRegisterSummary';

import loadRegistryStore from '@stores/loadRegistryStore/loadRegistryStore';
import organizationsStore from '@stores/organizationsStore';

import './LoadingRegister.scss';
import LoadingRegisterCalendarWrapper from './LoadingRegisterCalendarWrapper';
import LoadingRegisterFormWrapper from './LoadingRegisterForm/LoadingRegisterFormWrapper';
import LoadingRegisterTable from './LoadingRegisterTable/LoadingRegisterTable';

interface LoadingRegisterState {
  dataSource: DataSource;
}

class LoadingRegister extends Component<RouteComponentProps, LoadingRegisterState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      dataSource: this.dataSource,
    };
  }

  componentDidMount() {
    document.title = 'Реестр погрузки';
    organizationsStore.getOrganizations();
    loadRegistryStore.refreshData();
    loadRegistryStore.selectedRowData = [];
  }

  get dataSource() {
    return new DataSource({
      store: new CustomStore({
        key: 'loadregistryid',
        load: function (loadOptions) {
          return loadRegistryStore
            .getReport(loadOptions)
            .then((data) => {
              return data;
            })
            .catch(() => {
              throw 'Data Loading Error';
            });
        },
      }),
    });
  }

  render() {
    return (
      <>
        <AdaptivePageHeader
          title={'Реестр погрузки'}
          extra={
            <LoadingRegisterCalendarWrapper
              onChange={() => {
                this.state.dataSource.reload();
              }}
            />
          }
        />
        <Spin spinning={loadRegistryStore.isBusy}>
          <AdaptiveCard className={'loadingRegister'}>
            <LoadingRegisterTable dataSource={this.dataSource} />
            <LoadingRegisterSummary />
            <LoadingRegisterFormWrapper
              onFinish={() => {
                this.state.dataSource.reload();
              }}
            />
          </AdaptiveCard>
        </Spin>
      </>
    );
  }
}

export default observer(LoadingRegister);
