import { Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AdaptiveHeaderTabs from '@components/fw/AdaptiveElements/AdaptiveHeaderTabs';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import AccessRoute from '@components/fw/RoutingContext/AccessRoute';

import routingStore from '@stores/fw/routingStore/routingStore';
import userStore from '@stores/userStore/userStore';

import CompaniesList from './CompaniesList';
import HistoryList from './HistoryList';
import OrganizationsList from './OrganizationsList';
import ReportsList from './ReportsList/ReportsList';
import UsersList from './UsersList';

const { TabPane } = Tabs;

const menus = [
  <Tabs.TabPane tab={'Пользователи'} key="/administration/users"></Tabs.TabPane>,
  <Tabs.TabPane tab={'Транспортные компании'} key="/administration/companies"></Tabs.TabPane>,
  <Tabs.TabPane tab={'Организации'} key="/administration/organizations"></Tabs.TabPane>,
  <Tabs.TabPane tab={'История'} key="/administration/history"></Tabs.TabPane>,
];

class Administration extends Component<RouteComponentProps> {
  render() {
    const path = routingStore.location.pathname;
    const tabsAvailableItems = userStore.tabsAvailableItems;
    const isAdmin = ['admin', 'tech', 'manager'].includes(userStore.userData.roleCode);
    let defaultRedirect = '/';
    if (tabsAvailableItems.includes('administration/users')) defaultRedirect = 'administration/users';
    else if (tabsAvailableItems.includes('administration/companies')) defaultRedirect = 'administration/companies';
    else if (tabsAvailableItems.includes('administration/organizations')) defaultRedirect = 'administration/organizations';
    else if (tabsAvailableItems.includes('administration/history')) defaultRedirect = 'administration/history';
    document.title = 'Администрирование';
    return (
      <>
        <AdaptivePageHeader
          subTitle={
            <AdaptiveHeaderTabs activeKey={path} onTabClick={(e) => this.props.history.push(e)}>
              {this.renderMenu(tabsAvailableItems)}
              {isAdmin && <TabPane tab={'Отчеты'} key="/administration/reports" />}
            </AdaptiveHeaderTabs>
            //</Menu>
          }
          className={'page_header'}
        />
        {/*<div className="title">
          <span>Администрирование</span>
        </div>*/}
        <Switch>
          <Route exact path="/administration">
            <Redirect to={defaultRedirect} />
          </Route>
          <AccessRoute path="/administration/users" component={UsersList} canAccess={tabsAvailableItems.includes('administration/users')} />
          <AccessRoute
            path="/administration/companies"
            component={CompaniesList}
            canAccess={tabsAvailableItems.includes('administration/companies')}
          />
          <AccessRoute
            path="/administration/organizations"
            component={OrganizationsList}
            canAccess={tabsAvailableItems.includes('administration/organizations')}
          />
          <AccessRoute
            path="/administration/history"
            component={HistoryList}
            canAccess={tabsAvailableItems.includes('administration/history')}
          />
          <AccessRoute path="/administration/reports" component={ReportsList} canAccess={isAdmin} />
        </Switch>
      </>
    );
  }

  private renderMenu(availableMenus: string[]) {
    return menus.map((item, index) => {
      if (availableMenus.map((x) => `/${x}`).includes(`${item.key}`)) {
        return item;
      } else {
        return null;
      }
    });
  }
}

export default observer(Administration);
