import config from '@/config';
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import companiesService from '@stores/companiesStore/companiesStore.service';

import { getAuthority } from '@utils/authority';
import { showErrorNotify } from '@utils/notify';

const { urlApi } = config;

interface ComponentState {
  isBusy: boolean;
  imageUrl: any;
}

interface ComponentProps {
  companyId?: string;
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

@observer
class SinglePictureUpload extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      imageUrl: null,
    };
  }

  componentDidMount() {
    const { companyId } = this.props;
    if (companyId) {
      this.setState({ isBusy: true });
      companiesService
        .getLogo(companyId)
        .then((file) => {
          if (file && file.size) {
            getBase64(file, (imageUrl) => this.setState({ imageUrl }));
          }
        })
        .finally(() => this.setState({ isBusy: false }));
    }
  }

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ isBusy: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          isBusy: false,
        }),
      );
    }
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      showErrorNotify('Формат не поддерживается', 'Загрузите JPG или PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      showErrorNotify('Файл слишком большой', 'Загрузите файл размером до 2 МБ');
    }
    return isJpgOrPng && isLt2M;
  };

  render() {
    const isBusy = this.state.isBusy;
    const imageUrl = this.state.imageUrl;
    const uploadButton = (
      <div>
        {isBusy ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Загрузить</div>
      </div>
    );
    return (
      <>
        <Upload
          name="file"
          action={urlApi + '/api/company/logo/' + this.props.companyId}
          //data={{entityName: this.props.uploadSessionEntityName,entityId: this.props.uploadSessionEntityId}}
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          headers={{ Authorization: 'Bearer ' + getAuthority().access_token }}
          onChange={this.handleChange}
          beforeUpload={this.beforeUpload}>
          {imageUrl ? (
            <>
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            </>
          ) : (
            uploadButton
          )}
        </Upload>
        {imageUrl ? (
          <Button size={'small'} icon={<DeleteOutlined />} onClick={this.onDelete} style={{ position: 'absolute', bottom: 9, left: 17 }} />
        ) : null}
      </>
    );
  }

  onDelete = () => {
    companiesService.deleteLogo(this.props.companyId).then(() => {
      this.setState({ imageUrl: null });
    });
  };
}

export default SinglePictureUpload;
