import { DeleteOutlined, DollarOutlined } from '@ant-design/icons';
import { Button, List, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import { CompanyBankModel } from '@stores/bankStore/BankData';
import { CompanyBankService } from '@stores/bankStore/companyBank.service';
import { SupplierBankService } from '@stores/bankStore/supplierBank.service';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

import AccountEditor from './AccountEditor';
import BankEditor from './BankEditor';

interface ComponentProps {
  service: CompanyBankService | SupplierBankService;
  parentId: string;
}

interface ComponentState {
  isBusy: boolean;
  banks: CompanyBankModel[];
}

class BanksList extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isBusy: false,
      banks: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({ isBusy: true });
    this.props.service
      .getBanks(this.props.parentId)
      .then((ans) => {
        this.setState({ banks: ans });
      })
      .finally(() => this.setState({ isBusy: false }));
  };
  handleDelete = (id: string) => {
    this.props.service.deleteBank(id).then(() => {
      this.loadData();
    });
  };

  setMainBank = (bank: CompanyBankModel) => {
    this.props.service.setMainBank(this.props.parentId, bank).then(() => {
      this.loadData();
    });
  };

  setMainAccount = (id: string) => {
    this.props.service.setMainAccount(id).then(() => {
      this.loadData();
    });
  };

  handleDeleteAccount = (id: string) => {
    this.props.service.deleteBankAccount(id).then(() => {
      this.loadData();
    });
  };

  render() {
    const isBusy = this.state.isBusy;
    const banks = this.state.banks;
    return (
      <List
        header={<BankEditor data={null} service={this.props.service} parentId={this.props.parentId} onChange={() => this.loadData()} />}
        loading={isBusy}
        itemLayout={'vertical'}
        dataSource={banks}
        locale={{ emptyText: 'Добавьте банк и счет в банке чтобы загружать выписки' }}
        renderItem={(bank) => (
          <List.Item actions={[]}>
            <List.Item.Meta
              //avatar={<DollarOutlined />}
              title={
                <div>
                  <span>{bank.name}</span>
                  <span style={{ marginLeft: 30 }}>
                    <BankEditor
                      data={bank}
                      key={'edit' + bank.companyBankReferenceId}
                      service={this.props.service}
                      parentId={this.props.parentId}
                      onChange={() => this.loadData()}
                    />
                    <Popconfirm
                      title="Удалить？"
                      okText="Да"
                      cancelText="Нет"
                      onConfirm={() => this.handleDelete(bank.companyBankReferenceId)}>
                      <Button size={'small'} type={'text'} icon={<DeleteOutlined />} />
                    </Popconfirm>
                    <span style={{ marginLeft: 4 }}>
                      <AccountEditor
                        data={null}
                        key={'add-account' + bank.companyBankReferenceId}
                        service={this.props.service}
                        parentId={bank.companyBankReferenceId}
                        rcbic={bank.rcbic}
                        onChange={() => this.loadData()}
                      />
                    </span>
                  </span>
                </div>
              }
              description={
                <div>
                  {bank.isMain ? (
                    <p style={{ fontSize: 9 }}>Основной</p>
                  ) : (
                    <a style={{ fontSize: 9 }} onClick={() => this.setMainBank(bank)}>
                      Сделать этот банк основным
                    </a>
                  )}
                  <List
                    style={{ marginLeft: 20 }}
                    locale={{ emptyText: 'Добавьте счет в банке' }}
                    itemLayout="horizontal"
                    dataSource={bank.accounts}
                    renderItem={(account) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<DollarOutlined />}
                          title={
                            <span>
                              {account.name}
                              <span style={{ marginLeft: 10 }}>
                                <AccountEditor
                                  data={account}
                                  key={'edit' + account.bankAccountId}
                                  service={this.props.service}
                                  parentId={bank.companyBankReferenceId}
                                  rcbic={bank.rcbic}
                                  onChange={() => this.loadData()}
                                />
                              </span>
                              <Popconfirm
                                title="Удалить？"
                                okText="Да"
                                cancelText="Нет"
                                onConfirm={() => this.handleDeleteAccount(account.bankAccountId)}>
                                <Button size={'small'} type={'text'} icon={<DeleteOutlined />} />
                              </Popconfirm>
                            </span>
                          }
                          description={
                            <div>
                              {account.isMain ? (
                                <p style={{ fontSize: 9 }}>Основной</p>
                              ) : (
                                <a style={{ fontSize: 9 }} onClick={() => this.setMainAccount(account.bankAccountId)}>
                                  Сделать этот счет основным
                                </a>
                              )}
                              <p>
                                Баланс:{' '}
                                {account.balance == null
                                  ? 'не задан'
                                  : account.balance.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) +
                                    ' на ' +
                                    (account.balanceFactDate ? moment(account.balanceFactDate).format(GLOBAL_DATE_FORMAT) : '')}
                              </p>
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              }
            />
          </List.Item>
        )}
      />
    );
  }
}

export default observer(BanksList);
