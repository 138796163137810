import { action, makeObservable, observable } from 'mobx';

import { ExchangeRateModel } from './exchangeRateData';
import service from './exchangeRateStore.service';

class ExchangeRateStore {
  @observable isBusy: boolean = false;
  @observable exchanges: ExchangeRateModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action load(date: any) {
    this.isBusy = true;
    service
      .getExchangeRateList(date)
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.exchanges = result;
      });
  }
}

export default new ExchangeRateStore();
