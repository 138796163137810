import { Button } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import OptionsForm from '@components/routed/OptionsPage/TariffForm/OptionsForm';

import claimsTariffStore from '@stores/claimsStore/claimsTariffStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import { TariffCalcResponse } from '@stores/optionsStore/optionsStoreData';

interface Props {
  tariffId: string;
  onChange(result: TariffCalcResponse): Promise<void>;
}

interface State {
  isBusy: boolean;
  visible: boolean;
}

@observer
class ClaimTariffDetailEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: false,
      visible: false,
    };
  }

  form: OptionsForm;

  onSave = () => {
    this.setState({ isBusy: true });
    this.props.onChange(optionsStore.result).then(() => {
      this.setState({ visible: false, isBusy: false });
    });
  };

  render() {
    const visible = this.state.visible;
    return (
      <>
        <Button type={'link'} onClick={this.openDrawer}>
          Детали тарифа
        </Button>
        <AdaptiveDrawer
          closeIcon={null}
          bodyStyle={{ padding: 0 }}
          visible={visible}
          width={'95%'}
          footer={[
            <Button onClick={() => this.setState({ visible: false })} key={'reject'}>
              Отмена
            </Button>,
            <Button
              loading={this.state.isBusy}
              type={'primary'}
              onClick={() => this.onSave()}
              disabled={optionsStore.isBusy || !optionsStore.isTariffCalculated}
              key={'save'}>
              Сохранить
            </Button>,
          ]}>
          {this.state.visible && <OptionsForm tariffId={this.props.tariffId} ref={(ref) => (this.form = ref)} />}
        </AdaptiveDrawer>
      </>
    );
  }

  openDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  handelClose = () => {
    this.setState({ visible: false });
  };
}

export default ClaimTariffDetailEditor;
