import { DeleteOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, Menu, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ContractElement } from '@stores/contractTemplateStore/ContractTemplatesData';

interface ComponentProps {
  data: ContractElement;
  onDelete(): void;
  onChange(data: ContractElement): void;
  onFocus(number: string): void;
  level: number;
}

interface ComponentState {
  data: ContractElement;
}

class DocumentTemplateRow extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      data: this.props.data,
    };
  }

  componentDidMount() {}

  onAdd = (type: string) => {
    let number = 0;
    if (!this.props.data.nestedElements) {
      this.props.data.nestedElements = [];
    }
    const children = this.props.data.nestedElements;
    if (children && children.length) {
      const last = children[children.length - 1];
      const split = last.relativePath.split('.');
      const lNumber = split[split.length - 1];
      number = Number(lNumber);
    }
    this.props.data.nestedElements.push({
      elementType: type,
      value: '',
      nestedElements: [],
      relativePath: (this.props.data.relativePath ? this.props.data.relativePath + '.' : '') + (number && !isNaN(number) ? number + 1 : 1),
    });
    this.onChange(this.props.data);
  };

  onDelete = (item: ContractElement) => {
    this.props.data.nestedElements.splice(this.props.data.nestedElements.indexOf(item), 1);
    this.onChange(this.props.data);
  };

  onInput = (e) => {
    this.props.data.value = e.target.value;
    this.props.onChange(this.props.data);
  };

  onInputNumber = (e) => {
    this.props.data.relativePath = e.target.value;
    this.props.onChange(this.props.data);
    this.onFocus(e.target.value);
  };

  onFocus = (e) => {
    this.props.onFocus(e);
  };

  onChange = (data: ContractElement) => {
    this.props.data.nestedElements[this.props.data.nestedElements.indexOf(data)] = data;
    this.props.onChange(this.props.data);
    this.forceUpdate();
  };

  onChangeType = (type: string) => {
    this.props.data.elementType = type;
    this.props.onChange(this.props.data);
    this.forceUpdate();
  };

  render() {
    const data = this.props.data;
    return (
      <div style={{ marginTop: 4, marginLeft: this.props.level * 2 }}>
        <Row>
          <Col span={data.elementType == 'title' ? 4 : 2}>
            {data.elementType == 'title' ? (
              <b>заголовок:</b>
            ) : (
              <Input
                size={'small'}
                style={{ width: '100%' }}
                value={data.relativePath}
                onFocus={() => this.onFocus(data.relativePath)}
                onInput={this.onInputNumber}
              />
            )}
          </Col>
          <Col span={data.relativePath ? 20 : 18}>
            {data.elementType == 'paragraph' ? (
              <Input.TextArea
                autoSize={true}
                size={'small'}
                style={{ width: '100%', fontSize: '14px' }}
                value={data.value}
                onFocus={() => this.onFocus(data.relativePath)}
                onInput={this.onInput}
              />
            ) : (
              <Input
                size={'small'}
                style={{ width: '100%' }}
                value={data.value}
                onFocus={() => this.onFocus(data.relativePath)}
                onInput={this.onInput}
              />
            )}
          </Col>
          <Col span={2}>
            <Dropdown
              overlay={
                <Menu>
                  {data.elementType !== 'title' && (
                    <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => this.props.onDelete()}>
                      Удалить
                    </Menu.Item>
                  )}
                  <Menu.Item key="2" icon={<PlusOutlined />} onClick={() => this.onAdd('header')}>
                    Добавить заголовок
                  </Menu.Item>
                  <Menu.Item key="10" icon={<PlusOutlined />} onClick={() => this.onAdd('subHeader')}>
                    Добавить подзаголовок
                  </Menu.Item>
                  <Menu.Item key="3" icon={<PlusOutlined />} onClick={() => this.onAdd('paragraph')}>
                    Добавить пункт
                  </Menu.Item>
                  {data.elementType != 'title' && (
                    <Menu.SubMenu title="Изменить тип">
                      <Menu.Item key="4" onClick={() => this.onChangeType('header')}>
                        заголовок
                      </Menu.Item>
                      <Menu.Item key="5" onClick={() => this.onChangeType('subHeader')}>
                        подзагололвок
                      </Menu.Item>
                      <Menu.Item key="6" onClick={() => this.onChangeType('paragraph')}>
                        пункт
                      </Menu.Item>
                    </Menu.SubMenu>
                  )}
                </Menu>
              }>
              <Button size={'small'} icon={<MoreOutlined />} />
            </Dropdown>
          </Col>
        </Row>
        {data.nestedElements &&
          data.nestedElements.map((c, index) => (
            <DocumentTemplateRow
              key={index}
              onFocus={this.props.onFocus}
              onChange={this.onChange}
              data={c}
              onDelete={() => this.onDelete(c)}
              level={this.props.level + 1}
            />
          ))}
      </div>
    );
  }
}
export default observer(DocumentTemplateRow);
