export interface FinanceDocModel {
  /// Идентификатор документа
  docId?: string;
  /// Идентификатор финансов
  financeId: string;
  /// Номер счета
  accountNumber: string;
  /// Название документа
  name: string;

  contentType: string;
  /// Дата создания
  createDate?: any;
  /// Идентификатор создателя
  createUserId?: string;
  /// Название создателя
  createUserName?: string;
}

export interface FinanceOperationModel {
  /// Идентификатор операции
  operationId?: string;
  /// Идентификатор финансов
  financeId: string;
  /// Контент
  content: string;
  /// Дата операции
  operationDate: any;
  /// Идентификатор пользователя
  userId: string;
  /// Название пользователя
  userName: string;
}

export interface InfologFinanceModel {
  /// Идентификатор финансов
  financeId?: string;
  /// Месяц учета
  month: number;
  /// Год учета
  year: number;

  /// Доходы или расходы
  /// -> Доход
  /// -> Расход
  profit: string;

  /// Статья расходов/доходов
  /// -> доходы - инфолог (тариф1),
  /// -> доходы - инфолог (тариф2)
  /// -> расходы - разработка
  /// -> расходы - сопровождение
  /// -> расходы - маркетинг
  /// -> расходы - реклама
  article: string;

  /// Идентификатор контрагента
  companyId?: string;
  /// Название контрагента
  companyName?: string;

  /// Номер счета
  accountNumber: string;

  /// Описание работ из cчёта
  workDescription: string;

  /// Сумма без НДС
  sumWithoutNds: number;

  /// НДС
  nds: number;

  /// Итого с НДС
  sumWithNds: number;

  /// Комментарий
  note: string;

  /// Дата обновления
  updateDate?: any;

  /// Идентификатор пользователя, внесшего изменения
  updateUserId?: string;

  /// Название пользователя, внесшего изменения
  updateUserName?: string;

  /// Дата создания
  createDate?: any;

  /// Идентификатор создателя записи
  createUserId?: string;

  /// Название создателя записи
  createUserName?: string;

  customCompanyName: string;

  monthYear?: any;
  articleName?: string;
}

export const articlelist = [
  { value: 'profitTariff1', name: 'Доходы - инфолог (тариф1)', type: 'profit' },
  { value: 'profitTariff2', name: 'Доходы - инфолог (тариф2)', type: 'profit' },
  { value: 'consumptionDev', name: 'Расходы - разработка', type: 'consumption' },
  { value: 'consumptionSupport', name: 'Расходы - сопровождение', type: 'consumption' },
  { value: 'consumptionMarketing', name: 'Расходы - маркетинг', type: 'consumption' },
  { value: 'consumptionАd', name: 'Расходы - реклама', type: 'consumption' },
];

export const mimes: { [key: string]: string } = {
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'image/bmp': 'bmp',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',
  'image/tiff': 'tiff',
  'text/csv': 'csv',
  'text/plain': 'txt',
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'application/rtf': 'rtf',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/zip': 'zip',
  'application/gzip': 'gzip',
  'application/x-rar-compressed': 'rar',
};
