import { action, makeObservable, observable } from 'mobx';

import { VersionContentInfo, VersionInfo } from './systemInfoData';
import service from './systemInfoStore.service';

class SystemInfoStore {
  @observable isBusy: boolean = true;
  @observable items: VersionContentInfo[] = [];
  @observable currentVersion: VersionInfo | null = null;

  constructor() {
    makeObservable(this);
  }

  @action getVersionList() {
    this.isBusy = true;
    service
      .getVersionList()
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.items = result;
      });
  }

  @action getCurrentVersion() {
    service
      .getVersion()
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.currentVersion = result;
      });
  }

  @action async add(data: VersionContentInfo): Promise<void> {
    return await service.addVersion(data);
  }
  @action async update(data: VersionContentInfo): Promise<void> {
    return await service.updateVersion(data);
  }
  @action async delete(code: string): Promise<void> {
    return await service.deleteVersion(code);
  }
}

export default new SystemInfoStore();
