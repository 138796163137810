import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, HeaderFilter, Paging, Summary } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';
import EtranCaption from '@components/routed/EtranPage/EtranCaption';

import { EtranInvoiceDev } from '@stores/etranStore/EtranData';
import etranStore from '@stores/etranStore/etranStore';

import EtranInvoceDevsInfoModal from './EtranInvoceDevsInfoModal';

interface Props {}

interface State {
  selected: EtranInvoiceDev;
}

@observer
class EtranDevsTable extends Component<Props, State> {
  dataGrid: DataGrid;
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  onAdd = (newData: EtranInvoiceDev, oldData: EtranInvoiceDev) => {
    etranStore.addDev(newData, oldData);
    this.dataGrid.instance.refresh();
  };

  onRemove = () => {
    etranStore.removeDev(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onCopy = () => {
    etranStore.copyDev(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onUp = () => {
    etranStore.upDev(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  onDown = () => {
    etranStore.downDev(this.state.selected);
    this.dataGrid.instance.refresh();
  };

  render() {
    const data = etranStore.invoceFormData.devs;
    const selected = this.state.selected;
    return (
      <div className={'etranTable'}>
        <EtranCaption title={'Перевозочные приспособления'} />
        <div className={'etranButtonsGroup'}>
          <EtranInvoceDevsInfoModal onChange={(e) => this.onAdd(e, null)} />
          {selected ? (
            <EtranInvoceDevsInfoModal data={selected} onChange={(e) => this.onAdd(e, selected)} />
          ) : (
            <Button disabled={true}>Изменить</Button>
          )}
          <Button disabled={!selected} onClick={this.onRemove}>
            Удалить
          </Button>
          <Button disabled={!selected} onClick={this.onCopy}>
            Дублировать
          </Button>
          <Button disabled={!selected} onClick={this.onUp} icon={<ArrowUpOutlined />} />
          <Button disabled={!selected} onClick={this.onDown} icon={<ArrowDownOutlined />} />
        </div>
        <DataGrid
          onSelectionChanged={(e) => {
            const key = e.selectedRowsData[0];
            this.setState({ selected: key });
          }}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={false}
          dataSource={data}
          style={{ minHeight: '110px' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          selection={{ mode: 'single' }}>
          <Paging enabled={false} />
          <GroupPanel visible={false} />
          <HeaderFilter visible={false} allowSearch={false} />
          <FilterRow visible={false} />
          {INDEX_COLUMN}
          <Column allowFixing={true} dataField="devTypeId" caption={'Тип перевозочного приспособления'} />
          <Column allowFixing={true} dataField="devTypeName" caption={'Наименование'} />
          <Column allowFixing={true} dataField="devOwnerTypeName" caption={'Тип собственности'} />
          <Column allowFixing={true} dataField="devOwnerOkpo" caption={'ОКПО Собственника'} />
          <Column allowFixing={true} dataField="devOwnerName" caption={'Наименование собственника'} />
          <Column allowFixing={true} dataField="devQuantity" caption={'Количество'} />
          <Column allowFixing={true} dataField="devMass" caption={'Масса'} />

          <Summary></Summary>
        </DataGrid>
      </div>
    );
  }
}

export default EtranDevsTable;
