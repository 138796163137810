import { Form, InputNumber } from 'antd';
import React from 'react';

import styles from '@components/fw/Fields/field.module.scss';

import { FieldInputNumberProps as Props } from './field-input-number.types';

export const FieldInputNumber = ({ name, rules, ...inputNUmberProps }: Props) => {
  return (
    <Form.Item className={styles.fieldFlexAuto} name={name} rules={rules}>
      <InputNumber {...inputNUmberProps} />
    </Form.Item>
  );
};
