import HttpClient from '@utils/httpClient';

import { DocTypeModel } from './ContractTemplatesData';

const service = {
  async createOrUpdateContract(data: DocTypeModel): Promise<void> {
    return (await HttpClient.post(`/api/supplierDocType`, data)).data;
  },

  async getContract(docTypeId: string): Promise<DocTypeModel> {
    return (await HttpClient.get(`/api/supplierDocType`, { params: { docTypeId } })).data;
  },

  async getContractList(supplierId: string, dataContext: string): Promise<DocTypeModel[]> {
    return (await HttpClient.get(`/api/supplierDocType/bySupplier`, { params: { supplierId, dataContext } })).data;
  },

  /// Сохранить контент и получить демо файл
  async saveAndGetFile(data: DocTypeModel): Promise<any> {
    return (await HttpClient.post(`/api/supplierDocType/saveAndGet`, data)).data;
  },

  /// Сохранить контент и получить демо файл
  async demoContract(data: DocTypeModel): Promise<Blob> {
    return (
      await HttpClient.post(`/api/supplierDocType/demo`, data, {
        responseType: 'blob',
      })
    ).data;
  },

  async deleteContract(docTypeId: string): Promise<void> {
    return (await HttpClient.delete(`/api/supplierDocType`, { params: { docTypeId } })).data;
  },

  async createBaseContract(data: DocTypeModel): Promise<DocTypeModel> {
    return (await HttpClient.post(`/api/supplierDocType/base-contract`, data)).data;
  },
};

export default service;
