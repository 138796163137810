import { Form, notification } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '@components/fw/Flex';
import FreightList from '@components/routed/ClaimInfo/main/FreightList';
import {
  BidRegistryKind,
  BidRegistryServiceType,
  BidRegistryTeuType,
} from '@components/routed/RegisterOfBetsCreatePage/ui/basic-tab-basics-fields/basic-tab-basics-fields.types';

import { BidRegistryDto, registerOfBetsStore } from '@stores/registerOfBetsStore';
import userStore from '@stores/userStore';
import usersStore from '@stores/usersStore/usersStore';

import { showErrorNotify } from '@utils/notify';

import { BasicTabBasicsFields as BasicFields } from '../basic-tab-basics-fields';
import { BasicTabRollingStockFields as RollingStockFields } from '../basic-tab-rolling-stock-fields';
import { BasicTabTariffFields as TariffFields } from '../basic-tab-tariff-fields';
import { BasicTabTransportationParametersFields as TransportationParametersFields } from '../basic-tab-transportation-parameters-fields';
import { BasicTabWagonGroupFields as WagonGroupFields } from '../basic-tab-wagon-group-fields';
import { BasicTabProps as Props } from './basic-tab.types';

export const RegisterOfBetsCreateBasicTab = observer(({ form }: Props) => {
  const history = useHistory();
  const [isShowRollingStockFields, setIsShowRollingStockFields] = useState<boolean>(false);
  const [isShowTariffFields, setIsShowTariffFields] = useState<boolean>(false);

  const { bidRegistry } = registerOfBetsStore;

  const serviceType = useWatch('serviceType', form);

  useEffect(() => {
    if (serviceType === BidRegistryServiceType.expedition) {
      setIsShowTariffFields(true);
      setIsShowRollingStockFields(false);
    }

    if (serviceType === BidRegistryServiceType.wagons_expedition) {
      setIsShowTariffFields(true);
      setIsShowRollingStockFields(true);
    }

    if (serviceType === BidRegistryServiceType.wagons) {
      setIsShowTariffFields(false);
      setIsShowRollingStockFields(true);
    }

    if (!serviceType) {
      setIsShowTariffFields(false);
      setIsShowRollingStockFields(false);
    }
  }, [serviceType]);

  const onFinish = async (values: BidRegistryDto) => {
    try {
      const body = { ...values, dateBegin: values.validity[0], dateEnd: values.validity[1] };
      delete body.validity;

      if (!isEmpty(bidRegistry)) {
        await registerOfBetsStore.updateBidRegistry({ ...body, bidRegistryId: bidRegistry.bidRegistryId });
        return notification.success({ message: 'Ставка успешно обновлена' });
      }

      const id = await registerOfBetsStore.createBidRegistry(body);
      history.push(`/list-of-rates/edit/${id}`);
      await registerOfBetsStore.getBidRegistryById(id);
      notification.success({ message: 'Ставка успешно создана' });
    } catch (e: any) {
      showErrorNotify('Ошибка создания ставки', e.message);
    }
  };

  const initialValues = {
    freightList: [{ gng: '', etsng: '' }],
    bidKind: BidRegistryKind.itinerary,
    bidTeuType: BidRegistryTeuType.ps,
    ...bidRegistry,
    wagonProps: bidRegistry.wagonProps ?? [],
    wagonModels: bidRegistry.wagonModels ?? [],
    validity: [moment(bidRegistry.dateBegin), moment(bidRegistry.dateEnd)],
    executorId: userStore.userData.companyId ? userStore.userData.userId : null,
  };

  return (
    <Form form={form} initialValues={initialValues} scrollToFirstError={true} onFinish={onFinish}>
      <Flex gap="var(--gap)" wrap="wrap" vertical>
        <BasicFields form={form} users={usersStore.users} isEdit={!isEmpty(bidRegistry)} />
        <RollingStockFields form={form} isShow={isShowRollingStockFields} />
        <TransportationParametersFields disabled={!isEmpty(bidRegistry)} />
        <h4>Груз</h4>
        <FreightList
          primitiveResult={true}
          arrayName="freightList"
          gngName="gng"
          etsngName="etsng"
          form={form}
          disabled={!isEmpty(bidRegistry)}
        />
        <WagonGroupFields form={form} isShow={isShowTariffFields} />
        <TariffFields isShow={isShowTariffFields} />
      </Flex>
    </Form>
  );
});
