import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Spin, Tag } from 'antd';
import React from 'react';

import { TagModel } from '@stores/tagStore';

import styles from './TagEditor.module.scss';

interface TagListProps {
  tags: TagModel[];
  selected: TagModel[];
  onClick(tag: TagModel): void;
  onAddNew(): void;
  onEdit(tag: TagModel): void;
  onDelete(tag: TagModel): void;
  isBusy: boolean;
}

const TagList: React.FC<TagListProps> = (props) => {
  return (
    <div>
      <Spin spinning={props.isBusy}>
        {props.tags.map((tag, index) => {
          const isContains = props.selected.map((x) => x.tagId).includes(tag.tagId);
          return (
            <div key={`tag_all_${index}`}>
              <Tag color={tag.metadata.color} style={{ cursor: 'pointer' }} onClick={() => props.onClick(tag)}>
                {isContains ? <CheckOutlined style={{ marginRight: 5 }} /> : null}
                {tag.name}
              </Tag>
              <span className={styles.tag_list_icons_block}>
                <EditOutlined className={styles.tag_list_icon} onClick={() => props.onEdit(tag)} />
                <Popconfirm title={'Тэг будет удалён. Продолжить?'} onConfirm={() => props.onDelete(tag)}>
                  <DeleteOutlined className={styles.tag_list_icon} />
                </Popconfirm>
              </span>
            </div>
          );
        })}
      </Spin>

      <Divider />
      <div>
        <Button type="dashed" block onClick={props.onAddNew}>
          Создать новый
        </Button>
      </div>
    </div>
  );
};

export default TagList;
