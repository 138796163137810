import { Checkbox, Col, Form, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import dictStore from '@stores/dictStore/dictStore';

import { countries, escortTypes, getInitialEscortModel, useValueMap } from './options-escort-editor.lib';
import { OptionsEscortProps as Props } from './options-escort-editor.types';

export const OptionsEscortEditor = ({ value = EMPTY_VALUE, onChange }: Props) => {
  const [visible, setVisible] = useState(!!value?.length);
  const valueMap = useValueMap(value);
  const wagonAffiliationDictionary = dictStore.wagonAffiliationDictionary;

  function onVisibleChange() {
    setVisible(!visible);
    onChange([]);
  }

  function onCheckType(type: string, index: number | undefined) {
    const slice = value.slice();

    if (index !== undefined) {
      slice.splice(index, 1);
    } else {
      slice.push(getInitialEscortModel(type));
    }

    onChange?.(slice);
  }

  useEffect(() => {
    if (!!value.length) setVisible(true);
  }, [value]);

  return (
    <>
      <Checkbox style={{ marginTop: 14 }} checked={visible} onClick={onVisibleChange}>
        Сопровождение
      </Checkbox>
      {visible && (
        <div>
          {escortTypes.map((escortType) => {
            const index = valueMap[escortType.type]?.index;
            const isSelected = index !== undefined;
            const isShort = escortType.type === 'conductor';

            return (
              <Row key={escortType.type} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
                  <Checkbox checked={isSelected} onClick={() => onCheckType(escortType.type, index)}>
                    {escortType.name}
                  </Checkbox>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
                  {!isShort && (
                    <Form.Item name={['tariffData', 'escort', index, 'affiliationCode']} rules={[{ required: isSelected, message: ' ' }]}>
                      <FloatSelect placeholder={'Принадлежность'} disabled={!isSelected}>
                        {wagonAffiliationDictionary.map((item, index) => {
                          return (
                            <Select.Option value={item.affiliationCode} key={`wagonAffiliation_${index}`}>
                              {item.affiliationName}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
                  {!isShort && (
                    <Form.Item name={['tariffData', 'escort', index, 'countryCode']} rules={[{ required: isSelected, message: ' ' }]}>
                      <FloatSelect placeholder={'Страна'} disabled={!isSelected}>
                        {countries.map((item, index) => {
                          return (
                            <Select.Option value={item.code} key={`countryCode${index}`}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
                  {!isShort && (
                    <Form.Item name={['tariffData', 'escort', index, 'axelCount']} rules={[{ required: isSelected, message: ' ' }]}>
                      <FloatInputNumber placeholder={'Оси'} disabled={!isSelected} max={10} min={2} step={1} />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            );
          })}
        </div>
      )}
    </>
  );
};

const EMPTY_VALUE = [];
