import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const SendDxCellReport: React.FC<any> = (props) => {
  const isGroup = props.rowType === 'group';
  let value;
  try {
    value = JSON.parse(props.value);
  } catch (e) {}
  return value ? (
    <span>
      {isGroup && <span>{props.column.caption}: </span>}
      <Link to={`/sends/edit-send/${value.id}`} style={{ marginLeft: '2px' }}>
        <Button type={'link'} size={'small'}>
          {value.name}
        </Button>
      </Link>
    </span>
  ) : null;
};

export default SendDxCellReport;
