import { Button, Col, Form, InputNumber, Row, Spin } from 'antd';
import DataGrid, { Column, GroupPanel, HeaderFilter, Scrolling } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import WagonAutoselectionTariffDxCell from '@components/fw/DxCellTemplates/WagonAutoselectionTariffDxCell';

import organizationsStore from '@stores/organizationsStore';
import requestIlsStoreService from '@stores/requestIlsStore/requestIlsStore.service';
import { ClaimReport } from '@stores/requestIlsStore/requestIlsStoreData';
import { SendGeoInfo } from '@stores/sendsStore/sendData';
import sendsStore from '@stores/sendsStore/sendsStore';

import { showSuccessNotify } from '@utils/notify';

import { WagonSupplierModal } from '../ui/wagon-supplier-modal';
import { WagonSupplierModalResult } from '../ui/wagon-supplier-modal/wagon-supplier-modal.types';

interface State {
  loading: boolean;
  data: any[];
  geoInfo: SendGeoInfo;
  selected: number[];
  isOpenSupplierModal: boolean;
}

interface Props {
  ilsClaim: ClaimReport;
  onChange(): void;
  disabled: boolean;
}

@observer
class IlsClaimWagonAdd extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      geoInfo: null,
      selected: [],
      isOpenSupplierModal: false,
    };
  }

  componentDidMount() {
    sendsStore.loadGeoInfo(this.props.ilsClaim.loadStationCode).then((data) => {
      this.setState({ geoInfo: data });
    });
    organizationsStore.getUserOrganizations();
  }

  getData = (e: SendGeoInfo) => {
    this.setState({ loading: true });
    sendsStore
      .wagonsAutoSelectionByRps(this.props.ilsClaim.rps, this.props.ilsClaim.loadStationCode, e.radius, e.daysBeforeRepair)
      .then((data) => {
        showSuccessNotify('Операция выполнена', `Найдено вагонов: ${data.length}`);
        this.setState({ data: data });
      })
      .finally(() => this.setState({ loading: false }));
  };

  onSelectionChanged = (e) => {
    this.setState({ selected: e.selectedRowKeys.map((key) => Number(key.wagonNumber)) });
  };

  onSetWagons = (values: WagonSupplierModalResult) => {
    this.setState({ loading: true });
    requestIlsStoreService
      .addWagons(this.props.ilsClaim.ilsClaimId, {
        wagons: this.state.selected,
        supplierOrganizationId: values?.organizationId,
        supplierContractId: values?.contractId,
      })
      .then(() => {
        showSuccessNotify('Вагоны привязаны', '');
        this.props.onChange();
      })
      .finally(() => this.setState({ loading: false }));
  };

  onOpenSupplierModal = () => this.setState({ isOpenSupplierModal: true });

  onCloseSupplierModal = () => this.setState({ isOpenSupplierModal: false });

  render() {
    const geoInfo = this.state.geoInfo;
    const data = this.state.data;
    return (
      <>
        {geoInfo && (
          <Form initialValues={geoInfo} onFinish={this.getData} layout={'horizontal'}>
            <Row gutter={16}>
              <Col xs={12} sm={10} xxl={5}>
                <Form.Item label={'Радиус подбора, км'} rules={[{ required: true }]} name={'radius'}>
                  <InputNumber placeholder={'Радиус'} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={10} xxl={5}>
                <Form.Item label={'Дней до ремонта'} rules={[{ required: true }]} name={'daysBeforeRepair'}>
                  <InputNumber placeholder={'Дней до ремонта'} />
                </Form.Item>
              </Col>
              <Col xs={0} sm={0} xxl={1}></Col>
              <Col xs={12} sm={4} xxl={4}>
                <Button disabled={this.state.loading} htmlType={'submit'} type={'primary'}>
                  Подобрать
                </Button>
              </Col>
            </Row>
          </Form>
        )}
        <Button style={{ marginTop: 20 }} disabled={!this.state.selected.length} onClick={this.onOpenSupplierModal}>
          Привязать вагоны к заявке
        </Button>

        <Spin spinning={this.state.loading}>
          <DataGrid
            allowColumnReordering={true}
            //ref={(r) => (this.dataGrid = r)}
            dataSource={data}
            allowColumnResizing={true}
            showBorders={true}
            columnResizingMode={'widget'}
            columnAutoWidth={true}
            showColumnLines={true}
            columnMinWidth={10}
            showRowLines={true}
            remoteOperations={false}
            onSelectionChanged={(e) => this.onSelectionChanged(e)}
            selection={{ mode: 'multiple' }}>
            <GroupPanel visible={true} />
            <HeaderFilter visible={true} allowSearch={true} />
            <Scrolling mode="virtual" />
            <Column allowFixing={true} dataField="wagonNumber" caption={'Вагон'} />
            <Column allowFixing={true} dataField="model" caption={'Модель'} />
            <Column allowFixing={true} dataField="volume" caption={'Объем кузова (м3)'} />
            <Column allowFixing={true} dataField="lifting" caption={'Грузоподъемность (т)'} />
            <Column allowFixing={true} dataField="nextPlanRepairDays" caption={'Срок до планового ремонта (сут)'} />
            <Column allowFixing={true} dataField="planRepairName" caption={'Тип планового ремонта'} />
            <Column allowFixing={true} dataField="dislStationName" caption={'Станция дислокации'} />
            <Column allowFixing={true} dataField="destStationName" caption={'Станция назначения'} />
            <Column allowFixing={true} dataField="needWash" caption={'Требует промывки'} />
            <Column allowFixing={true} dataField="currentToTargetDistance" caption={'Расстояние подсыла (км)'} />
            <Column allowFixing={true} dataField="receivePlanDate" caption={'Дата планируемого прибытия'} dataType={'date'} />
            <Column allowFixing={true} dataField="commonTariff" caption={'Тариф (руб)'} cellRender={WagonAutoselectionTariffDxCell} />
          </DataGrid>
        </Spin>
        <WagonSupplierModal open={this.state.isOpenSupplierModal} onClose={this.onCloseSupplierModal} onBind={this.onSetWagons} />
      </>
    );
  }
}

export default IlsClaimWagonAdd;
