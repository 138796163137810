import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Empty, Layout, Popconfirm, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import styles from '@components/routed/SendInfo/SendInfo.module.scss';

import { NotificationModel } from '@stores/notification';
import notificationStore from '@stores/notification/notificationStore';
import { GLOBAL_DATETIME_FORMAT } from '@stores/optionsStore/optionsStoreData';
import usersStore from '@stores/usersStore/usersStore';

import NotificationsEditor from './NotificationsEditor';

interface NotificationsProps {
  companyId: string;
}

interface NotificationsState {}

@observer
class Notifications extends Component<NotificationsProps, NotificationsState> {
  constructor(props: NotificationsProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    const companyId = this.props.companyId;
    notificationStore.getNotificationTemplates().then();
    notificationStore.getNotificationByCompanyId(companyId).then();
    usersStore.getUsers();
  }

  render() {
    const isBusy = notificationStore.isBusy;
    const notification = JSON.parse(JSON.stringify(notificationStore.notification));
    const templates = JSON.parse(JSON.stringify(notificationStore.notificationTemplates));
    const templateGroups: { [key: string]: NotificationModel[] } = {};

    notification.forEach((n) => {
      const t = templates.find((t) => t.code == n.templateCode);
      if (t) {
        n.template = t;
        if (templateGroups[t.groupName]) {
          templateGroups[t.groupName].push(n);
        } else {
          templateGroups[t.groupName] = [n];
        }
      }
    });
    return (
      <Layout.Content className={styles.content}>
        <Spin spinning={isBusy}>
          <NotificationsEditor companyId={this.props.companyId} />
          {Object.keys(templateGroups).length ? (
            Object.keys(templateGroups).map((g) => {
              return (
                <Row key={g}>
                  <Divider>{g}</Divider>
                  {templateGroups[g].map((r: NotificationModel, index) => {
                    return (
                      <Col xs={24} sm={24} md={12} xl={8} xxl={6} key={index + 'n'}>
                        <AdaptiveCard
                          extra={[
                            <NotificationsEditor companyId={this.props.companyId} data={r} key={'editor'} />,
                            <Popconfirm
                              key={'delete'}
                              title="Удалить？"
                              okText="Да"
                              cancelText="Нет"
                              onConfirm={() => notificationStore.deleteNotification(this.props.companyId, r.templateCode)}>
                              <Button
                                icon={<DeleteOutlined />}
                                title={'Удалить отчет'}
                                size={'small'}
                                type="text"
                                className={'dx-button-mode-contained dx-button'}
                              />
                            </Popconfirm>,
                          ]}
                          title={r.template?.name}>
                          <p>
                            Отправка: {r.content.actions.find((a) => a.type == 'chat') ? ' чат' : null}{' '}
                            {r.content.actions.find((a) => a.type == 'mail') ? ' почта' : null}
                          </p>
                          <p>Создан: {moment(r.updateDate).format(GLOBAL_DATETIME_FORMAT)}</p>
                        </AdaptiveCard>
                      </Col>
                    );
                  })}
                </Row>
              );
            })
          ) : (
            <Empty />
          )}
        </Spin>
      </Layout.Content>
    );
  }
}

export default Notifications;
