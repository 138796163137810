import moment from 'moment/moment';
import React from 'react';

import { FieldFloatSelect } from '@components/fw/Fields/float-select';
import { FieldRemoteSelect } from '@components/fw/Fields/remote-select';
import { Flex } from '@components/fw/Flex';
import { stationWithParagraphsTemplate } from '@components/routed/OptionsPage/TariffForm/OptionsStationSelectorBtn';

import dictService from '@stores/dictStore/dictStore.service';
import { BidRegistry } from '@stores/registerOfBetsStore/registerOfBetsStore.namespace';
import { supplierBidRegistryStore } from '@stores/supplierBidRegistryStore';

import { BasicTabTransportationParametersFieldsProps } from './basic-tab-transportation-parameters-fields.types';

export const BasicTabTransportationParametersFields = ({ disabled }: BasicTabTransportationParametersFieldsProps) => {
  return (
    <>
      <h4>Параметры перевозки</h4>
      <Flex gap="var(--gap) 32px" wrap="wrap" justify="space-between">
        <FieldFloatSelect
          name={'wayKind'}
          rules={[{ required: true, message: 'Укажите тип перевозки' }]}
          placeholder={'Тип перевозки'}
          options={BidRegistry.wayKindOptions}
          disabled={!!supplierBidRegistryStore.supplierBidRegistryId}
        />
        <FieldRemoteSelect
          float={true}
          aValueExpr={moment().toISOString(true)}
          placeholder={'Станция отправления'}
          valueExpr={'stationCode'}
          displayExpr={'fullName'}
          dataSource={dictService.stationWithParagraphDict}
          itemTemplate={stationWithParagraphsTemplate}
          name={'loadStation'}
          rules={[{ required: true, message: 'Укажите станцию отправления' }]}
          disabled={disabled}
        />
        <FieldRemoteSelect
          float={true}
          aValueExpr={moment().toISOString(true)}
          mode={'multiple'}
          placeholder={'Станции назначения'}
          valueExpr={'stationCode'}
          displayExpr={'fullName'}
          dataSource={dictService.stationWithParagraphDict}
          itemTemplate={stationWithParagraphsTemplate}
          name={'destStations'}
          rules={[{ required: true, message: 'Укажите станцию назначения' }]}
          disabled={disabled}
        />
      </Flex>
    </>
  );
};
