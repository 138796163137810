import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DataGrid, { Column, FilterRow, GroupPanel, HeaderFilter, Paging } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { EtranCarGuideModel } from '@stores/etranStore/EtranInvoiceCarData';

import EtranInvoceCarGuideModal from './EtranInvoceCarGuideModal';

interface Props {
  data: EtranCarGuideModel[];
  onChange(data: EtranCarGuideModel[]): void;
}

interface State {
  selected: EtranCarGuideModel;
}

@observer
class EtranGuidesTable extends Component<Props, State> {
  dataGrid: DataGrid;
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  onAdd = (newData: EtranCarGuideModel, oldData: EtranCarGuideModel) => {
    const data = this.props.data;
    if (oldData) {
      const index = data.indexOf(oldData);
      if (index != -1) {
        data.splice(index, 1, newData);
      } else {
        data.push(newData);
      }
    } else {
      data.push(newData);
    }
    this.props.onChange(data);
    this.dataGrid.instance.refresh();
  };

  onRemove = () => {
    const data = this.props.data;
    const index = data.indexOf(this.state.selected);
    if (index != -1) {
      data.splice(index, 1);
      this.props.onChange(data);
      this.dataGrid.instance.refresh();
    }
  };

  onCopy = () => {
    const data = this.props.data;
    const newData = JSON.parse(JSON.stringify(this.state.selected));
    newData.mockId = Math.random();
    data.push(newData);
    this.props.onChange(data);
    this.dataGrid.instance.refresh();
  };

  onUp = () => {
    const data = this.props.data;
    const index = data.indexOf(this.state.selected);
    if (index > 0) {
      data.splice(index, 1);
      data.splice(index - 1, 0, this.state.selected);
      this.props.onChange(data);
      this.dataGrid.instance.refresh();
    }
  };

  onDown = () => {
    const data = this.props.data;
    const index = data.indexOf(this.state.selected);

    if (index != -1) {
      if (index < data.length - 1) {
        data.splice(index, 1);
        data.splice(index + 1, 0, this.state.selected);
        this.props.onChange(data);
        this.dataGrid.instance.refresh();
      }
    }
  };
  render() {
    const data = this.props.data;
    const selected = this.state.selected;
    return (
      <div className={'etranTable'}>
        <div className={'etranButtonsGroup'}>
          <EtranInvoceCarGuideModal
            onChange={(e) => {
              this.onAdd(e, null);
            }}
          />
          {selected ? (
            <EtranInvoceCarGuideModal data={selected} onChange={(e) => this.onAdd(e, selected)} />
          ) : (
            <Button disabled={true}>Изменить</Button>
          )}
          <Button disabled={!selected} onClick={this.onRemove}>
            Удалить
          </Button>
          <Button disabled={!selected} onClick={this.onCopy}>
            Дублировать
          </Button>
          <Button disabled={!selected} onClick={this.onUp} icon={<ArrowUpOutlined />} />
          <Button disabled={!selected} onClick={this.onDown} icon={<ArrowDownOutlined />} />
        </div>
        <DataGrid
          onSelectionChanged={(e) => {
            const key = e.selectedRowsData[0];
            this.setState({ selected: key });
          }}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={false}
          dataSource={data}
          style={{ minHeight: '110px' }}
          remoteOperations={true}
          allowColumnResizing={true}
          showBorders={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          selection={{ mode: 'single' }}>
          <Paging enabled={false} />
          <GroupPanel visible={false} />
          <HeaderFilter visible={false} allowSearch={false} />
          <FilterRow visible={false} />
          {INDEX_COLUMN}
          <Column allowFixing={true} dataField="guideFio" caption={'ФИО'} />
          <Column allowFixing={true} dataField="guidePassportSer" caption={'Серия паспорта'} />
          <Column allowFixing={true} dataField="guidePassportNum" caption={'№ паспорта'} />
          <Column allowFixing={true} dataField="guideWarrantNum" caption={'№ командировочного удостоверения'} />
        </DataGrid>
      </div>
    );
  }
}

export default EtranGuidesTable;
