import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Spin, Tabs } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { Component } from 'react';

import AdaptiveDrawer from '@components/fw/AdaptiveElements/AdaptiveDrawer';
import BanksList from '@components/fw/BankList/BankList';
import ContractTemplateList from '@components/fw/Drawers/CompanyEditor/ContractTemplate/ContractTemplateList';
import RequisitsForm from '@components/fw/Drawers/CompanyEditor/RequisitsForm';

import { SupplierModel, SupplierRequisitesModel, newSupplierRequisitesModel } from '@stores/adminBilling/adminBillingData';
import adminBillingStore from '@stores/adminBilling/adminBillingStore';
import adminBillingStoreService from '@stores/adminBilling/adminBillingStore.service';
import { SupplierBankService } from '@stores/bankStore/supplierBank.service';

interface Props {
  data: SupplierModel;
}

interface State {
  isBusy: boolean;
  isBusyInit: boolean;
  visible: boolean;
  data: SupplierRequisitesModel;
}

class AdminBillingSuppliersEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: false,
      isBusyInit: true,
      visible: false,
      data: null,
    };
  }

  formRef: FormInstance;
  render() {
    const isBusy = this.state.isBusy;
    const data = this.state.data;
    const visible = this.state.visible;
    const id = data ? data.supplierId : null;
    return (
      <>
        {this.isEditMode ? (
          <Button onClick={this.openDrawer} icon={<EditOutlined />} title={'Изменить'} size={'small'} type={'text'} />
        ) : (
          <Button onClick={this.openDrawer} icon={<PlusOutlined />} title={'Создать'} type={'text'} size={'small'}>
            создать
          </Button>
        )}
        <AdaptiveDrawer
          width={'84%'}
          title={this.isEditMode ? 'Редактирование' : 'Создание '}
          onClose={this.handelClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}>
              <Button onClick={this.handelClose} style={{ marginRight: 8 }}>
                Отмена
              </Button>
              <Button type="primary" loading={isBusy} htmlType={'submit'} form={'supplier-editor'}>
                {this.isEditMode ? 'Сохранить' : 'Добавить'}
              </Button>
            </div>
          }>
          <Spin spinning={this.state.isBusyInit}>
            <Tabs>
              <Tabs.TabPane tab={'Основное'} key={'main'}>
                <Form
                  layout="vertical"
                  size={'large'}
                  labelCol={{ span: 8 }}
                  name={'supplier-editor'}
                  ref={(ref) => (this.formRef = ref)}
                  onFinish={(e) => this.handleConfirm(e)}
                  initialValues={data}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={18}>
                      <Form.Item
                        name={'name'}
                        label={'Название'}
                        rules={[
                          {
                            required: true,
                            message: 'Введите название',
                          },
                        ]}>
                        <Input maxLength={20} />
                      </Form.Item>
                    </Col>
                    <Col xs={6}>
                      <Form.Item name={'docPrefix'} label={'Код документов'}>
                        <Input maxLength={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {!this.state.isBusyInit && (
                    <RequisitsForm onDataReady={this.handleReqChange} reason={data.reason} address={data.address} />
                  )}
                </Form>
              </Tabs.TabPane>
              {id && (
                <Tabs.TabPane tab={'Банк'} key={'bank'}>
                  <BanksList parentId={id} service={new SupplierBankService()} />
                </Tabs.TabPane>
              )}
              {id && (
                <Tabs.TabPane tab={'Шаблоны договоров'} key={'offers'}>
                  <ContractTemplateList parentId={id} dataContext={'contract'} type={'supplier'} />
                </Tabs.TabPane>
              )}
              {id && (
                <Tabs.TabPane tab={'Шаблоны доп соглашений'} key={'ext'}>
                  <ContractTemplateList parentId={id} dataContext={'ext'} type={'supplier'} />
                </Tabs.TabPane>
              )}
            </Tabs>
          </Spin>
        </AdaptiveDrawer>
      </>
    );
  }

  get isEditMode(): boolean {
    return this.props.data && this.props.data.supplierId !== null;
  }

  openDrawer = () => {
    this.setState({ isBusyInit: true, visible: true });
    if (this.props.data.supplierId) {
      adminBillingStoreService
        .getSupplierInfo(this.props.data.supplierId)
        .then((ans) => {
          this.setState({ data: ans });
          if (this.formRef) this.formRef.resetFields();
        })
        .finally(() => this.setState({ isBusyInit: false }));
    } else {
      this.setState({ data: newSupplierRequisitesModel, isBusyInit: false });
      if (this.formRef) this.formRef.resetFields();
    }
  };

  handelClose = () => {
    if (this.formRef) this.formRef.resetFields();
    this.setState({ visible: false });
  };

  handleReqChange = (data: any) => {
    let organization = this.state.data;
    Object.keys(data).forEach((k) => {
      if (data[k] && data[k].length) {
        organization[k] = data[k];
      }
    });

    this.setState({ data: organization });
    this.formRef.resetFields(Object.keys(data));
  };

  async handleConfirm(e) {
    e.supplierId = this.props.data.supplierId;
    this.setState({ isBusy: true });
    try {
      await adminBillingStore.editSupplier(e);
      this.handelClose();
    } catch (e) {
    } finally {
      this.setState({ isBusy: false });
    }
  }
}

export default AdminBillingSuppliersEditor;
