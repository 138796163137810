import DataSource from 'devextreme/data/data_source';
import { action, makeObservable, observable } from 'mobx';

import { RequestTypeInfo, getDs } from './RequestsData';
import service from './requestsStore.service';
import requestsService from './requestsStore.service';

class RequestsStore {
  @observable isBusy: boolean = false;
  @observable types: RequestTypeInfo[] = [];
  @observable dataSource: DataSource;
  private tmpData: any;
  disableUpdateOnNextCall = false;

  constructor() {
    makeObservable(this);
  }

  @action async loadTypes() {
    this.isBusy = true;
    service
      .getTypes()
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.types = result;
      });
  }
  @action createDataSource() {
    if (!this.dataSource) {
      this.dataSource = getDs;
    }
  }

  @action async loadData(loadOptions): Promise<any> {
    if (!this.disableUpdateOnNextCall) {
      this.tmpData = await requestsService.getData(loadOptions);
    }
    this.disableUpdateOnNextCall = false;
    return this.tmpData;
  }

  @action onWatch(requestid: string) {
    const find = this.tmpData.data.find((d) => d.requestid === requestid && !d.iswatched);
    if (find) {
      find.iswatched = true;
      this.disableUpdateOnNextCall = true;
      this.dataSource.reload();
    }
  }

  @action async onReload(id: string) {
    await service.reCreate(id);
    this.dataSource.reload();
  }
}

export default new RequestsStore();
